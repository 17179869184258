import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { faCalendar, faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { NationalIdentificationTypeEnum } from '../../openapi/wallet';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import {
  getIdValidations,
  getMaskTypeForIdentificationType,
} from '../../utils/AgentHelper';
import { StepByStepComponent } from '../StepByStep/StepByStepContainer';
import ZenControlledDatePickerInput from '../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledMaskTextInput from '../Zen/Input/ZenControlledMaskTextInput';
import ZenButton from '../Zen/ZenButton';
import {
  WalletApplicationFormState,
  WalletApplicationStepName,
} from './WalletApplicationSteps';
import withWalletApplicationProgress from './withWalletApplicationProgress';

const WalletSsnStep: StepByStepComponent<
  WalletApplicationFormState,
  WalletApplicationStepName
> = ({ form: { control, setValue }, onNext, onPrevious }) => {
  const [showSSN, setShowSSN] = useState(false);

  return (
    <div className='flex flex-col flex-grow mt-10'>
      <div className='max-w-2xl mx-auto flex-grow'>
        <div className='flex items-center justify-center'>
          <div className='w-18 rounded-full bg-seaglass'>
            <FontAwesomeIcon
              icon={faLock}
              className='w-full h-8 my-4 text-primary-dark'
            />
          </div>
        </div>

        <div className='w-full flex justify-center'>
          <p className='w-[420px] my-4 font-inter font-light text-base text-primary-dark text-center'>
            To make sure it’s you, we need your Social Security Number and Date
            of Birth.
          </p>
        </div>

        <div className='mt-7'>
          <div className='mt-4 w-52'>
            <ZenControlledDatePickerInput<
              WalletApplicationFormState,
              'birthDate'
            >
              control={control}
              label='Date of Birth'
              name='birthDate'
              shouldUnregister={false}
              placeholder='MM/DD/YYYY'
              labelClassName='!font-inter font-light'
              rules={{
                required: 'Please enter your date of birth',
              }}
              isRequired
              datePickerConfig={{
                maxDate: DateTime.local().minus({ years: 18 }).toJSDate(),
              }}
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  titleId='calendar'
                  className='text-primary-blue text-xl -mb-0.5'
                />
              }
            />
          </div>

          <div className='mt-4 w-44'>
            <ZenControlledMaskTextInput<WalletApplicationFormState, 'ssn'>
              control={control}
              label='SSN'
              placeholder='Enter SSN'
              name='ssn'
              labelClassName='!font-inter font-light'
              rules={{
                required: 'Please enter SSN',
                ...getIdValidations(NationalIdentificationTypeEnum.Ssn),
              }}
              maskType={getMaskTypeForIdentificationType(
                NationalIdentificationTypeEnum.Ssn,
              )}
              type={showSSN ? 'text' : 'password'}
              endAdornment={
                <span className='h-full mr-3 flex items-center'>
                  <FontAwesomeIcon
                    icon={showSSN ? faEyeSlash : faEye}
                    className='cursor-pointer'
                    onClick={() => setShowSSN(!showSSN)}
                  />
                </span>
              }
              shouldUnregister={false}
              isRequired
              setValue={setValue}
            />
          </div>
        </div>

        <div className='font-inter font-light text-base text-grey-600 text-start my-6 space-y-5'>
          <p>
            Important Information About Opening a New Card Account:
            <br />
            To assist the government in combating the funding of terrorism and
            money laundering activities, federal law requires all financial
            institutions to obtain, verify, and record information that
            identifies each person who opens an account. This applies to
            investments, safe deposit boxes, loans, and deposit accounts.
          </p>

          <p>
            What this means for you:
            <br />
            When you apply for a new card account, we will request your name,
            address, date of birth, Social Security number, and other
            information necessary to verify your identity. Additionally, you may
            be asked to provide further identifying documentation.
          </p>

          <p>
            These measures ensure compliance with the U.S. PATRIOT Act and other
            regulations aimed at preventing illicit financial activities.
          </p>
        </div>
      </div>

      <div className='sticky w-full bottom-0 z-0 bg-primary-light'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-outline'
              label='Back'
              onClick={onPrevious}
              className='!font-inter font-medium text-lg border-primary-dark'
            />
            <ZenButton
              isFullWidth
              label='Next'
              onClick={() => {
                onNext();
                AnalyticsService.instance().logEvent(
                  AnalyticsEventEnum.WALLET_SIGN_UP_STEP_2_NEXT_CLICKED,
                );
              }}
              className='!font-inter font-medium text-lg bg-rezen-blue-600 border-rezen-blue-600'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withWalletApplicationProgress(WalletSsnStep);
