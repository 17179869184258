import { EnumMap } from '../../types';
import { cn } from '../../utils/classUtils';

export type ZenPropertyTypePillSizeType = 'xs' | 'sm' | 'md';
export type ZenPillBackgroundType = 'rezen-blue' | 'rezen-midnight';

export interface ZenPropertyTypePillProps {
  name: string;
  size?: ZenPropertyTypePillSizeType;
  imageUrl?: string;
  backgroundVariant?: ZenPillBackgroundType;
  fontWeight?: string;
  noWrap?: boolean;
}

const ZenPropertyTypePill: React.FC<ZenPropertyTypePillProps> = ({
  size = 'xs',
  backgroundVariant = 'rezen-blue',
  name,
  fontWeight = 'font-semibold',
  noWrap = false,
}) => {
  const zenPropertyTextToClassNameMap: EnumMap<
    ZenPropertyTypePillSizeType,
    string
  > = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
  };

  const zenPropertyTypeBackgroundToClassNameMap: EnumMap<
    ZenPillBackgroundType,
    string
  > = {
    'rezen-blue': 'rounded-full bg-rezen-light-blue-100 px-3 py-1 font-inter',
    'rezen-midnight': 'rounded-full bg-midnight-green-300 px-3 py-1 font-inter',
  };

  return (
    <div
      className={cn(
        zenPropertyTypeBackgroundToClassNameMap[backgroundVariant],
        fontWeight,
        'font-zen-body inline-block',
      )}
    >
      <div
        className={cn('flex flex-row content-center space-x-2 items-center', {
          'whitespace-nowrap': noWrap,
        })}
      >
        <p
          className={cn(
            zenPropertyTextToClassNameMap[size],
            'text-primary-navy capitalize',
          )}
        >
          {name}
        </p>
      </div>
    </div>
  );
};

export default ZenPropertyTypePill;
