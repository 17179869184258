import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useHistory } from 'react-router-dom';
import AnalyticsService from '../../../services/AnalyticsService';
import { AnalyticsEventEnum, YesNoType } from '../../../types';
import { WalletAgreements } from '../../../utils/WalletAgreementUtils';
import ZenControlledCheckboxInput from '../../Zen/Input/ZenControlledCheckboxInput';
import ZenButton from '../../Zen/ZenButton';
import WalletAccordion from './WalletAccordion';

export interface AgreementFormData {
  electronicConsent: YesNoType[];
  termsOfService: YesNoType[];
  privacyPolicy: YesNoType[];
  agreeToAll: YesNoType[];
}

interface WalletAgreementModalProps {
  offerId: string;
  onClose?(): void;
}

const WalletAgreementModal: React.FC<WalletAgreementModalProps> = ({
  offerId,
  onClose,
}) => {
  const history = useHistory();
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  const { control, watch, setValue } = useForm<AgreementFormData>({
    defaultValues: {
      electronicConsent: [],
      termsOfService: [],
      privacyPolicy: [],
      agreeToAll: [],
    },
  });

  const [electronicConsent, termsOfService, privacyPolicy, agreeToAll] = watch([
    'electronicConsent',
    'termsOfService',
    'privacyPolicy',
    'agreeToAll',
  ]);

  const areAllSelected =
    electronicConsent.length > 0 &&
    termsOfService.length > 0 &&
    privacyPolicy.length > 0;

  const handleToggleAccordion = (accordionId: string) => {
    setOpenAccordion((prev) => (prev === accordionId ? null : accordionId));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logAnalyticsEvent = useCallback(
    debounce((event: string, eventData?: Record<string, unknown>) => {
      AnalyticsService.instance().logEvent(event, eventData);
    }, 500),
    [],
  );

  useEffect(() => {
    const agreeToAllSelected = agreeToAll.length > 0;

    if (areAllSelected && !agreeToAllSelected) {
      setValue('agreeToAll', [YesNoType.YES]);
    } else if (!areAllSelected && agreeToAllSelected) {
      setValue('agreeToAll', []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllSelected]);

  if (!offerId) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-primary-dark bg-opacity-40'
        role='button'
        onClick={onClose}
      />

      <div className='flex items-center justify-center w-full h-full lg:p-0'>
        <div className='max-h-[90%] w-11/12 md:w-4/5 lg:w-6/12 bg-primary-light border border-transparent rounded-lg z-10 grid grid-rows-[3.5rem_1fr_6rem]'>
          <div className='px-4 flex justify-between items-center bg-rezen-blue-100 rounded-t-lg border-rezen-blue-100'>
            <p className='font-inter font-medium text-base ml-2'>
              Legal Privacy
            </p>

            <FontAwesomeIcon
              icon={faCircleXmark}
              className='text-xl text-primary-light cursor-pointer'
              // @ts-ignore
              style={{ '--fa-secondary-color': '#7B7A7A' }}
              onClick={onClose}
            />
          </div>

          <div className='overflow-auto p-6 blackCheckbox'>
            <div className='h-12 p-6 flex justify-start items-center bg-yellow-100 rounded-xl'>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className='text-lg text-primary-dark mr-3'
              />

              <p className='font-inter font-medium text-base text-primary-dark'>
                Before you can complete Sign Up, you must accept the following
                terms.
              </p>
            </div>

            <WalletAccordion
              agreement={WalletAgreements.electronicConsent}
              isOpen={openAccordion === 'electronicConsent'}
              onToggle={() => handleToggleAccordion('electronicConsent')}
              checkbox={
                <ZenControlledCheckboxInput<
                  AgreementFormData,
                  'electronicConsent'
                >
                  control={control}
                  name='electronicConsent'
                  shouldUnregister={false}
                  size='sm'
                  style={{
                    backgroundColor: '#BFDDDB',
                  }}
                  options={[
                    {
                      label: (
                        <span className='font-inter font-normal text-base text-primary-dark block'>
                          I agree to the Electronic Communication
                        </span>
                      ),
                      value: YesNoType.YES,
                    },
                  ]}
                  reverse
                  onChangeSpy={(value) => {
                    if (value.includes(YesNoType.YES)) {
                      logAnalyticsEvent(
                        AnalyticsEventEnum.WALLET_AGREED_TO_PRIVACY_POLICY,
                        {
                          title: 'Electronic Communications and Consent',
                        },
                      );
                    }
                  }}
                />
              }
            />

            <WalletAccordion
              agreement={WalletAgreements.termsOfServices}
              isOpen={openAccordion === 'termsOfService'}
              onToggle={() => handleToggleAccordion('termsOfService')}
              checkbox={
                <ZenControlledCheckboxInput<AgreementFormData, 'termsOfService'>
                  control={control}
                  name='termsOfService'
                  shouldUnregister={false}
                  size='sm'
                  style={{
                    backgroundColor: '#BFDDDB',
                  }}
                  options={[
                    {
                      label: (
                        <span className='font-inter font-normal text-base text-primary-dark block'>
                          I agree to the Terms of Service
                        </span>
                      ),
                      value: YesNoType.YES,
                    },
                  ]}
                  reverse
                  onChangeSpy={(value) => {
                    if (value.includes(YesNoType.YES)) {
                      logAnalyticsEvent(
                        AnalyticsEventEnum.WALLET_AGREED_TO_PRIVACY_POLICY,
                        {
                          title: 'Terms of Service',
                        },
                      );
                    }
                  }}
                />
              }
            />

            <WalletAccordion
              agreement={WalletAgreements.privacyPolicy}
              isOpen={openAccordion === 'privacyPolicy'}
              onToggle={() => handleToggleAccordion('privacyPolicy')}
              checkbox={
                <ZenControlledCheckboxInput<AgreementFormData, 'privacyPolicy'>
                  control={control}
                  name='privacyPolicy'
                  shouldUnregister={false}
                  size='sm'
                  style={{
                    backgroundColor: '#BFDDDB',
                  }}
                  options={[
                    {
                      label: (
                        <span className='font-inter font-normal text-base text-primary-dark block'>
                          I agree to the Privacy Policy
                        </span>
                      ),
                      value: YesNoType.YES,
                    },
                  ]}
                  reverse
                  onChangeSpy={(value) => {
                    if (value.includes(YesNoType.YES)) {
                      logAnalyticsEvent(
                        AnalyticsEventEnum.WALLET_AGREED_TO_PRIVACY_POLICY,
                        {
                          title: 'Privacy Policy',
                        },
                      );
                    }
                  }}
                />
              }
            />
          </div>

          <div className='px-4.5 pl-10 flex justify-between items-center border-t border-regent-300 blackCheckbox'>
            <ZenControlledCheckboxInput<AgreementFormData, 'agreeToAll'>
              control={control}
              name='agreeToAll'
              shouldUnregister={false}
              size='sm'
              style={{
                backgroundColor: '#BFDDDB',
              }}
              options={[
                {
                  label: (
                    <span className='font-inter font-normal text-base text-primary-dark block'>
                      I agree to all
                    </span>
                  ),
                  value: YesNoType.YES,
                },
              ]}
              onChangeSpy={(value: YesNoType[]) => {
                setValue('electronicConsent', value);
                setValue('termsOfService', value);
                setValue('privacyPolicy', value);
                if (value.includes(YesNoType.YES)) {
                  logAnalyticsEvent(
                    AnalyticsEventEnum.WALLET_AGREED_TO_PRIVACY_POLICY,
                    { title: 'Agreed to all' },
                  );
                }
              }}
              reverse
            />

            <ZenButton
              label='Continue'
              isDisabled={!areAllSelected}
              className='w-24 h-11 border-0 bg-rezen-blue-600 rounded-lg font-inter font-medium text-base leading-5 mr-1'
              onClick={() => {
                history.push(`/wallet/signup/debit/${offerId}`);
                logAnalyticsEvent(
                  AnalyticsEventEnum.WALLET_CONTINUE_IN_LEGAL_PRIVACY_CLICKED,
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletAgreementModal;
