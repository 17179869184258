import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTicket } from '../../../slices/LeoSlice';
import { RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import {
  VoteType,
  ListingPosterItem,
  MEDIA_ENUM,
  SharranMediaItem,
} from '../../../utils/LeoUtils';
import { Button } from '../../commons/Button';
import Markdown from '../../MarkdownRender/Markdown';
import { FeedbackNote } from '../../Zen/Leo/Chat/FeedbackNote';
import { CurrentChat } from '../../Zen/Leo/Chat/LeoChatIndex';
import NeoLeoEmbedVideoResponses from '../../Zen/Leo/Chat/NeoLeoEmbedVideoResponses';
import NeoLeoSocialShareables from '../../Zen/Leo/Chat/Shareables/NeoLeoSocialShareables';
import './NeoLeoChat.css';
import { NeoLeoChatBubble } from './NeoLeoChatBubble';
import NeoLeoContextItems from './NeoLeoContextItems';
import NeoLeoCourses from './NeoLeoCourses';
import NeoLeoLearningLinks from './NeoLeoLearningLinks';
import NeoLeoChatResponseActions from './NeoLeoResponseActions';

interface NeoLeoResponseProps extends CurrentChat {
  isShowingHistory: boolean;
  isDisclaimer: boolean;
}

enum ContextEnum {
  TEXT_RESPONSE = 'TEXT_RESPONSE',
  TRANSACTION_ITEMS = 'TRANSACTION_ITEMS',
  COURSES = 'COURSES',
  LEARNING_LINKS = 'LEARNING_LINKS',
  SHARRAN_VIDEOS = 'SHARRAN_VIDEOS',
  SHAREABLE_POSTERS = 'SHAREABLE_POSTERS',
  NOTE = 'NOTE',
}

const NeoLeoResponse: React.FC<NeoLeoResponseProps> = ({
  isShowingHistory,
  isDisclaimer,
  ...msg
}) => {
  const dispatch = useDispatch();
  const {
    msgContext: context,
    isCreatingTicket,
    isTicketCreated,
  } = useSelector((state: RootState) => state.leo);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const isLatestResponse = msg.id === context?.bot_message_id;

  const ContextOrder = {
    First: ContextEnum.TEXT_RESPONSE,
    Last: ContextEnum.TEXT_RESPONSE,
  };

  const msgContext = msg?.id === context?.bot_message_id ? context : undefined;
  const showCreateTicket =
    isLatestResponse &&
    !isTicketCreated &&
    (!!msgContext?.create_ticket || msg.vote_type === VoteType.Downvote);

  let shareablePosters: ListingPosterItem[] = [];
  let hasShareablePosters = false;
  if (msgContext?.media) {
    // shareablePosters = shareables
    shareablePosters =
      msgContext.media
        ?.filter(
          (el) => el.type === 'PROPERTY_LISTING_POSTER' && !!el.media_url,
        )
        .map((el) => ({
          posterLink: el.media_url!,
          listingDetails: el.details!,
        })) ?? [];
  }

  let sharranVideos: SharranMediaItem[] = [];
  let hasSharranVideos = false;
  if (msgContext?.media) {
    // sharranVideos = videos.filter(
    sharranVideos = msgContext.media.filter(
      (el) => el.type === MEDIA_ENUM.SHARRAN,
    ) as SharranMediaItem[];
  }

  if (shareablePosters.length > 0) {
    ContextOrder.Last = ContextEnum.SHAREABLE_POSTERS;
    hasShareablePosters = true;
  }

  if (sharranVideos.length > 0) {
    ContextOrder.Last = ContextEnum.SHARRAN_VIDEOS;
    hasSharranVideos = true;
  }

  const hasTransactionItems = !!msgContext?.details[0]?.items?.length;
  if (hasTransactionItems) {
    ContextOrder.Last = ContextEnum.TRANSACTION_ITEMS;
  }

  const hasCourses = !!msgContext?.references?.courses?.length;
  if (hasCourses) {
    ContextOrder.Last = ContextEnum.COURSES;
  }

  const hasLearningLinks = !!msgContext?.references?.documents?.length;
  if (hasLearningLinks) {
    ContextOrder.Last = ContextEnum.LEARNING_LINKS;
  }

  if (msgContext?.note) {
    ContextOrder.Last = ContextEnum.NOTE;
  }

  return (
    <div className='pl-2 my-6'>
      <div
        className='mb-2'
        onMouseEnter={() => debounce(() => setIsHovering(true), 100)()}
        onMouseLeave={() => debounce(() => setIsHovering(false), 100)()}
      >
        <NeoLeoChatBubble isFirst>
          <div
            className={cn(
              'neo-leo flex flex-row whitespace-pre-wrap word-break',
            )}
          >
            <Markdown content={msg.message!} />
          </div>
          {showCreateTicket && (
            <div className='flex flex-row items-center mt-3'>
              <Button
                leftIcon={
                  !isCreatingTicket ? (
                    <FontAwesomeIcon
                      icon={faPlus}
                      className='text-white'
                      size='sm'
                    />
                  ) : undefined
                }
                onClick={() =>
                  dispatch(
                    createTicket({
                      session_id: msg.session_id,
                      message_id: msg.id!,
                    }),
                  )
                }
                loading={isCreatingTicket}
                disabled={isCreatingTicket}
              >
                Create Support Ticket
              </Button>
            </div>
          )}
        </NeoLeoChatBubble>
        {isHovering && !isDisclaimer && <NeoLeoChatResponseActions {...msg} />}
      </div>
      {hasSharranVideos && (
        <NeoLeoChatBubble
          isLast={ContextOrder.Last === ContextEnum.SHARRAN_VIDEOS}
        >
          <NeoLeoEmbedVideoResponses sharranVideos={sharranVideos} />
        </NeoLeoChatBubble>
      )}
      {hasShareablePosters && (
        <NeoLeoChatBubble
          isLast={ContextOrder.Last === ContextEnum.SHAREABLE_POSTERS}
        >
          <NeoLeoSocialShareables shareables={shareablePosters} />
        </NeoLeoChatBubble>
      )}
      {hasTransactionItems && (
        <NeoLeoChatBubble
          isLast={ContextOrder.Last === ContextEnum.TRANSACTION_ITEMS}
        >
          <NeoLeoContextItems msgContext={msgContext} />
        </NeoLeoChatBubble>
      )}
      {hasCourses && (
        <NeoLeoChatBubble isLast={ContextOrder.Last === ContextEnum.COURSES}>
          <NeoLeoCourses msgContext={msgContext} />
        </NeoLeoChatBubble>
      )}
      {hasLearningLinks && (
        <NeoLeoChatBubble
          isLast={ContextOrder.Last === ContextEnum.LEARNING_LINKS}
        >
          <NeoLeoLearningLinks msgContext={msgContext} />
        </NeoLeoChatBubble>
      )}
      <FeedbackNote feedbackNote={msgContext?.note} hasAdditionalInfo={false} />
    </div>
  );
};

export default NeoLeoResponse;
