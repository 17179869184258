import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ContextualPrompt } from '../../../utils/LeoUtils';
import { NeoLeoSearchSuggestion } from '../Hooks/useNeoLeoSearchSuggestions';

interface NeoLeoSuggestionsProps {
  suggestions: NeoLeoSearchSuggestion[];
  onSubmit: (prompt: ContextualPrompt) => void;
}

const NeoLeoSuggestions: React.FC<NeoLeoSuggestionsProps> = ({
  suggestions,
  onSubmit,
}) => {
  if (suggestions.length === 0) {
    return null;
  }

  return (
    <div className='pb-4'>
      <p className='font-inter-medium text-regent-600 mb-2'>SUGGESTIONS</p>
      {suggestions.map((suggestion) => (
        <div
          key={suggestion.title}
          className='flex items-center justify-between cursor-pointer mb-2'
          onClick={() => {
            const prompt = suggestion.prompt ?? '';
            onSubmit({
              prompt: prompt,
              title: suggestion.title ?? prompt,
              category: '',
            });
          }}
        >
          <div className='flex items-center gap-x-3'>
            <div className='flex justify-center items-center w-8 h-6 bg-white bg-opacity-10 rounded-sm'>
              <FontAwesomeIcon
                icon={faSparkles}
                className='text-aqua'
                fontSize={14}
              />
            </div>
            <div>
              <p className='font-inter-light text-sm text-white pt-0.5'>
                {suggestion.title}
              </p>
            </div>
            <div />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NeoLeoSuggestions;
