import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch } from 'react-redux';
import useModalForm from '../../../../hooks/useModalForm';
import {
  AgentResponse,
  UserAvailabilityResponse,
} from '../../../../openapi/yenta';
import { useUpdateDoNotDisturb } from '../../../../query/roar/useRoar';
import { saveLoggedInAgentDetails } from '../../../../slices/AgentSlice';
import { AppDispatch } from '../../../../types';
import { Button } from '../../../commons/Button';
import { HookFormDatePickerInput } from '../../../commons/hookFormInputs/HookFormDatePickerInput';
import { Modal } from '../../../commons/Modal';

type IDate = Date | undefined;

interface IFormData {
  date: [IDate, IDate];
}

type OutOfOfficeFormProps = {
  agentId: string;
  agentDetail?: AgentResponse | null;
  opened: boolean;
  onClose(): void;
};

const OutOfOfficeForm: React.FC<OutOfOfficeFormProps> = ({
  agentId,
  agentDetail,
  opened,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const userAvailability = (agentDetail?.availability ||
    {}) as UserAvailabilityResponse;
  const outOfOffice = userAvailability.outOfOffice?.[0];
  const timeZone =
    agentDetail?.availability?.timeZone || DateTime.local().zoneName;

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useModalForm<IFormData>(
    {
      defaultValues: {
        date: [
          outOfOffice?.startDate ? new Date(outOfOffice?.startDate) : undefined,
          outOfOffice?.endDate ? new Date(outOfOffice?.endDate) : undefined,
        ],
      },
    },
    opened,
  );

  const [startDate, endDate] = watch('date');

  const { mutate, isLoading } = useUpdateDoNotDisturb(agentId);

  function getFormattedDate(date: Date) {
    return DateTime.fromJSDate(date).setZone(timeZone).toFormat('yyyy-LL-dd');
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size='605px'
      aria-label='ooo-form-modal'
    >
      <Modal.Header className='text-xl'>
        <div className='text-center'>
          {isEmpty(outOfOffice)
            ? 'Schedule Time Off'
            : 'Edit Scheduled Time Off'}
        </div>
      </Modal.Header>
      <form
        onSubmit={handleSubmit(() =>
          mutate(
            {
              ...userAvailability,
              timeZone,
              outOfOffice: [
                {
                  startDate: getFormattedDate(startDate!),
                  endDate: getFormattedDate(endDate!),
                },
              ],
            },
            {
              onSuccess: (data) => {
                dispatch(
                  saveLoggedInAgentDetails({
                    ...agentDetail,
                    availability: data.availability,
                  }),
                );
                onClose();
              },
            },
          ),
        )}
      >
        <Modal.Content className='p-6'>
          {isEmpty(outOfOffice) && (
            <p className='mb-2'>
              Planning to be away for a couple of days? Set up your
              out-of-office!
            </p>
          )}
          <p className='mb-1 font-medium'>Select start and end date</p>
          <HookFormDatePickerInput
            control={control}
            name='date'
            type='range'
            placeholder='Start date - End date'
            minDate={new Date()}
            allowSingleDateInRange
            className='border border-regent-400 rounded-md'
            rightSection={
              <FontAwesomeIcon icon={faChevronDown} className='mr-2' />
            }
          />
        </Modal.Content>
        <Modal.Footer className='justify-end'>
          <Button
            size='lg'
            variant='outline'
            onClick={onClose}
            classNames={{ root: 'flex-1 md:flex-initial md:px-12' }}
            type='button'
          >
            Cancel
          </Button>
          <Button
            size='xs'
            classNames={{ root: 'flex-1 md:flex-initial md:px-12' }}
            loading={isSubmitting || isLoading}
            disabled={isSubmitting || isLoading || !(startDate && endDate)}
            type='submit'
          >
            {isEmpty(outOfOffice) ? 'Schedule' : 'Update'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default OutOfOfficeForm;
