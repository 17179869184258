import { DatePickerType } from '@mantine/dates';
import { FieldError } from 'react-hook-form';
import { MaskedInputProps } from '../MaskedInput';

/**
 * per react-input-mask documentation,
 *   Mask string. Default format characters are:
 *     9: 0-9
 *     a: A-Z, a-z
 *     *: A-Z, a-z, 0-9
 * */
const getIsValueCharacter = (mask: MaskedInputProps['mask'], index: number) =>
  mask[index] === '9' || mask[index] === 'a' || mask[index] === '*';

export const getUnmaskValue = (
  value: string,
  mask: MaskedInputProps['mask'],
  maskChar: string,
) => {
  let unmaskedValue = '';
  for (let i = 0; i < value.length; i++) {
    const isValueChar = getIsValueCharacter(mask, i);
    const isMaskChar = value[i] === maskChar;

    if (isValueChar && !isMaskChar) {
      unmaskedValue += value[i];
    }
  }

  return unmaskedValue;
};

export const getDatePickerInputErrors = (
  error: FieldError | undefined,
  type: DatePickerType | undefined = 'default',
): FieldError | undefined => {
  if (!error) {
    return undefined;
  }

  if (type !== 'range' || error.ref) {
    return error;
  }

  const rangeErrors = Object.values(error) as FieldError[];

  return {
    type: 'validate',
    types: {
      ...rangeErrors[0]?.types,
      ...rangeErrors[1]?.types,
    },
  };
};
