import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { TemplatesSearchControllerApi } from '../../../openapi/signature-api';
import { AgreementDefinitionWithAmendmentsResponse } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { createAgreementAmendmentDefinition } from '../../../slices/AgentSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AsyncSelectOption, ISelectOption } from '../../../types';
import { getSignatureApiConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import GeminiButton from '../../Gemini/GeminiButton';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenControlledDatePickerInput from '../Input/ZenControlledDatePickerInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import IcaSimpleModal from './IcaSimpleModal';

interface AddAmendmentProps {
  isOpen: boolean;
  onClose(): void;
  agreementResponse: AgreementDefinitionWithAmendmentsResponse | undefined;
}

interface FormData {
  name: string;
  requiredAt: string;
  description: string;
  documentTemplateId: ISelectOption;
}

const AddAmendmentModal: React.FC<AddAmendmentProps> = ({
  isOpen,
  onClose,
  agreementResponse,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const getAgentDocumentTemplates = async (search: string, page: number) => {
    try {
      const { data } = await new TemplatesSearchControllerApi(
        getSignatureApiConfiguration(),
      ).searchTemplates(
        'DOCUMENT_TEMPLATE',
        'PUBLISHED',
        undefined,
        search,
        undefined,
        undefined,
        undefined,
        undefined,
        page,
        10,
        undefined,
        undefined,
        undefined,
      );

      const options: AsyncSelectOption[] =
        data.data?.map((resp) => ({
          value: `${resp.id}`,
          label: `${resp.name}`,
        })) ?? [];

      return options;
    } catch (e) {
      ErrorService.notify('Unable to search for agents document templates', e, {
        search: {
          term: search,
          target: 'Document Template',
          page,
        },
      });
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          'We were unable to search for documents. Please try again in a few moments or contact support.',
        ),
      );
    }

    return [];
  };

  const onSubmit = async ({
    name,
    requiredAt,
    description,
    documentTemplateId,
  }: FormData) => {
    await dispatch(
      createAgreementAmendmentDefinition(
        agreementResponse?.agreementDefinitionId!,
        {
          name,
          description: description || undefined,
          documentTemplateId: documentTemplateId.value,
          version: agreementResponse?.agreementVersion || undefined,
          requiredAt: DateTime.fromISO(requiredAt).toMillis(),
        },
      ),
    );
    onClose();
  };

  return (
    <IcaSimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title='Add Amendment'
      size='xl'
    >
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <div className='space-y-4 px-4 py-6'>
          <div className='flex space-x-4'>
            <ZenControlledTextInput<FormData, 'name'>
              control={control}
              name='name'
              label='Amendment Name'
              placeholder='Enter Amendment Name'
              rules={{
                required: 'Please enter a name',
              }}
              isRequired
            />
            <ZenControlledDatePickerInput<FormData, 'requiredAt'>
              name='requiredAt'
              control={control}
              label='Required By'
              placeholder='Enter Date'
              datePickerConfig={{
                minDate: DateTime.local().plus({ day: 1 }).toJSDate(),
              }}
              icon={
                <div className='flex justify-center items-center px-1 cursor-pointer'>
                  <FontAwesomeIcon
                    icon={regular('calendar')}
                    title='calendar'
                    className='text-primary-blue'
                    fontSize={20}
                  />
                </div>
              }
              rules={{
                required: 'Please enter a date',
              }}
              isRequired
            />
          </div>
          <div>
            <ZenControlledTextInput<FormData, 'description'>
              control={control}
              name='description'
              label='Description'
              placeholder='Enter Description'
            />
          </div>
          <div>
            <ZenControlledAsyncSelectInput<FormData, 'documentTemplateId'>
              name='documentTemplateId'
              fetchData={getAgentDocumentTemplates}
              label='Search Document Template'
              placeholder='Search document template'
              control={control}
              rules={{
                required: 'Please select a document template',
                validate: async (value) => {
                  const isDuplicate = agreementResponse?.amendmentDefinitions?.some(
                    (amendment) => amendment.documentTemplateId === value.value,
                  );
                  return isDuplicate
                    ? 'Please enter a new document template'
                    : undefined;
                },
              }}
              isRequired
            />
          </div>
        </div>
        <div className='space-x-4 py-[18px] flex justify-center border-t border-grey-300'>
          <div className='w-40'>
            <GeminiButton
              onClick={() => onClose()}
              variant='primary-outline'
              label='Cancel'
              isFullWidth
            />
          </div>
          <div className='w-40'>
            <GeminiButton
              type='submit'
              variant='primary'
              label='Add'
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              isFullWidth
            />
          </div>
        </div>
      </form>
    </IcaSimpleModal>
  );
};

export default AddAmendmentModal;
