import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import Select, {
  components,
  GroupTypeBase,
  MenuPosition,
  OptionProps,
  Styles,
} from 'react-select';
import ZenFormErrorMessage from '../../Zen/Input/ZenFormErrorMessage';

export interface CustomSelectOptions {
  LabelComponent?: React.ReactNode;
  value: string;
  label: string | React.ReactElement;
  disabled?: boolean;
}

interface GeminiControlledSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  subLabel?: string;
  options: CustomSelectOptions[];
  styles?: Partial<Styles<any, boolean, GroupTypeBase<any>>>;
  disabled?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  readOnly?: boolean;
  customClassName?: string;
  isSearchable?: boolean;
  closeMenuOnSelect?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  menuPosition?: MenuPosition;
}

const Option = (
  props: OptionProps<
    CustomSelectOptions,
    false,
    GroupTypeBase<CustomSelectOptions>
  >,
) => {
  return (
    <components.Option {...props}>
      {props.data.LabelComponent ?? props.data.label}
    </components.Option>
  );
};

const GeminiControlledSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  placeholder = 'Select',
  disabled = false,
  readOnly,
  options,
  styles,
  startAdornment,
  endAdornment,
  customClassName = '',
  closeMenuOnSelect = true,
  isSearchable = false,
  shouldUnregister = true,
  isRequired = false,
  isClearable = false,
  menuPosition,
  ...rest
}: GeminiControlledSelectInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <div className='space-y-1 w-full'>
      <Controller
        shouldUnregister={shouldUnregister}
        {...rest}
        render={({
          field: { onChange, value, name, onBlur },
          fieldState: { error, invalid },
        }) => (
          <div className='space-y-1 w-full'>
            {label && (
              <label
                className={classNames(
                  'inline-block',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                )}
                htmlFor={name}
              >
                <span className='font-zen-body font-semibold'>{label}</span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-6 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            <div
              className={classNames(
                'flex flex-row flex-grow items-center border rounded-lg font-zen-body font-normal mr-2 text-ellipsis whitespace-nowrap',
                value?.value && 'text-zen-dark-9',
                invalid && '!border-zen-danger',
                focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                customClassName,
                readOnly && 'bg-gray-50',
              )}
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
            >
              <div className='flex flex-row items-center flex-grow'>
                {startAdornment && <div>{startAdornment}</div>}
                <div className='w-full'>
                  <Select
                    isOptionDisabled={(option) => option.disabled!}
                    styles={{
                      ...styles,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem',
                      }),
                    }}
                    placeholder={placeholder}
                    value={value}
                    onFocus={() => setFocus(true)}
                    onBlur={() => {
                      setFocus(false);
                      onBlur();
                    }}
                    isSearchable={isSearchable}
                    name={name}
                    isDisabled={disabled || readOnly}
                    defaultValue={value}
                    options={options}
                    menuPosition={menuPosition}
                    onChange={onChange}
                    closeMenuOnSelect={closeMenuOnSelect}
                    className='react-select-container font-zen-body font-normal'
                    classNamePrefix='react-select-noborder'
                    inputId={name}
                    isClearable={isClearable}
                    components={{
                      Option,
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      IndicatorsContainer: (props) =>
                        endAdornment ? (
                          <div className='flex fle-row items-center h-10'>
                            {isClearable && (
                              //@ts-ignore
                              <components.ClearIndicator {...props} />
                            )}
                            <div
                              className={classNames(
                                'h-10 border-l pt-2',
                                value && 'border-zen-dark-9',
                                invalid && 'border-zen-danger',
                              )}
                            >
                              {endAdornment}
                            </div>
                          </div>
                        ) : (
                          <div className='flex fle-row items-center h-10'>
                            {isClearable && (
                              //@ts-ignore
                              <components.ClearIndicator {...props} />
                            )}
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className='text-primary-dark mx-3'
                              size='sm'
                            />
                          </div>
                        ),
                    }}
                  />
                </div>
              </div>
            </div>
            {error && <ZenFormErrorMessage message={error.message} />}
          </div>
        )}
      />
    </div>
  );
};
export default GeminiControlledSelectInput;
