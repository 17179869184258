import { faArrowUpRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  ApplicationDtoStatusEnum as StatusEnum,
  WalletDtoTerminalApplicationsInner,
} from '../../openapi/wallet';
import { PartialEnumMap } from '../../types';
import { cn } from '../../utils/classUtils';
import { safePartialEnumMapGet } from '../../utils/EnumHelper';

const statusToClassNameMap: PartialEnumMap<StatusEnum, string> = {
  [StatusEnum.AppFormSent]: 'bg-rezen-light-blue-100 text-primary-navy',
  [StatusEnum.Submitted]: 'bg-seaglass text-midnight-green-900',
  [StatusEnum.PartnershipAwaitingDocuments]: 'bg-orange-100 text-orange-600',
  [StatusEnum.PartnershipPendingReview]: 'bg-yellow-100 text-yellow-900',
};

const statusToDisplayNameMap: PartialEnumMap<StatusEnum, string> = {
  [StatusEnum.AppFormSent]: 'In Progress',
  [StatusEnum.Submitted]: 'In Progress',
  [StatusEnum.PartnershipAwaitingDocuments]: 'Awaiting Documents',
  [StatusEnum.PartnershipPendingReview]: 'Pending Review',
};

interface WalletCardStatusPillProps {
  application?: WalletDtoTerminalApplicationsInner;
}

const WalletCardStatusPill: React.FC<WalletCardStatusPillProps> = ({
  application,
}) => {
  const status = application?.status || StatusEnum.AppFormSent;

  const showCTA = [
    StatusEnum.AppFormSent,
    StatusEnum.PartnershipAwaitingDocuments,
  ].includes(status);

  const style = safePartialEnumMapGet(
    statusToClassNameMap,
    status,
    statusToClassNameMap[StatusEnum.AppFormSent],
  );

  const displayName = safePartialEnumMapGet(
    statusToDisplayNameMap,
    status,
    statusToDisplayNameMap[StatusEnum.AppFormSent],
  );

  return (
    <>
      <div
        className={cn(
          'w-fit inline-block items-center px-3 py-1.5 space-x-2 border-0 rounded-[28px]',
          style,
        )}
      >
        <p className='font-inter text-sm font-medium'>{displayName}</p>
      </div>

      {showCTA && (
        <a
          className='font-inter font-medium text-base leading-5 text-rezen-blue-600'
          href={application?.formUrl}
        >
          Complete The Application
          <FontAwesomeIcon
            icon={faArrowUpRight}
            size='xs'
            className='text-rezen-blue-600 ml-2'
          />
        </a>
      )}
    </>
  );
};

export default WalletCardStatusPill;
