import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { last } from 'lodash';
import React, { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import useRedirectAwayOnboardingEffect from '../../hooks/useRedirectAwayOnboardingEffect';
import {
  AgreementResponseAgreementTypeEnum,
  ApplicationControllerApi,
  ApplicationResponseNextStatusEnum,
} from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { fetchAgreementsByUserAndAgreementTypeAndVersionDesc } from '../../slices/AgentSlice';
import { saveApplication } from '../../slices/AuthSlice';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { RootState, SignatureWidgetModeEnum } from '../../types';
import { getYentaConfiguration } from '../../utils/OpenapiConfigurationUtils';
import ApplicationLayout from '../ApplicationLayout';
import IconButton from '../IconButton';
import SignatureWidget from '../SignatureWidget/SignatureWidget';
import SupportWidget from '../support/SupportWidget';

const ApplicationICASigningV2: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = useLogout();
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const application = last(userDetail?.applications);
  const applicationId = application?.id;
  const token = application?.icaAgreement?.signeeToken;
  const agreementId = application?.icaAgreement?.agreementId;

  useRedirectAwayOnboardingEffect(ApplicationResponseNextStatusEnum.SignIca);

  const handleSignICA = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).signICAUsingAgreement({
        userId: userDetail?.id!,
        agreementId: agreementId!,
        signeeToken: token!,
        applicationId: applicationId!,
      });
      await dispatch(saveApplication(data));
      await dispatch(
        fetchAgreementsByUserAndAgreementTypeAndVersionDesc(
          userDetail?.id!,
          AgreementResponseAgreementTypeEnum.Ica,
          true,
        ),
      );

      dispatch(showSuccessToast('You have successfully signed the agreement.'));
      history.push('/onboarding/application-ica-signing/success');
    } catch (e) {
      dispatch(showApiErrorModal(e));
      dispatch(
        showErrorToast('Unable to sign the agreement. Please try again later.'),
      );
      ErrorService.notifyIgnoreHandled('Error signing the agreement', e, {
        user: { id: userDetail?.id! },
      });
    } finally {
      setIsLoading(false);
    }
  }, [agreementId, applicationId, dispatch, history, token, userDetail?.id]);

  const onDocumentSigned = useCallback(async () => {
    handleSignICA();
  }, [handleSignICA]);

  const onDocumentAlreadySigned = useCallback(() => {
    handleSignICA();
  }, [handleSignICA]);

  return (
    <ApplicationLayout showFooter={false}>
      <div className='text-center my-8 mx-2 lg:mx-0 font-zen-body text-zen-dark-9'>
        <p className='text-[22px] leading-[22px] font-medium mb-4'>
          INDEPENDENT CONTRACTOR AGREEMENT
        </p>
        <p className='text-base'>
          We kindly ask you to review and put your digital signature on the ICA
          agreement.
        </p>
      </div>
      <div className='bg-gray-100 w-full h-[calc(100vh-266px)] lg:h-[calc(100vh-240px)] justify-center relative'>
        <SignatureWidget
          token={token!}
          mode={SignatureWidgetModeEnum.SIGN}
          onDocumentSigned={onDocumentSigned}
          onDocumentAlreadySigned={onDocumentAlreadySigned}
          showDownload
          showWebHelperButtons={isMobile}
          isLoading={isLoading}
        />
      </div>
      {!isMobile && !isLoading && (
        <div className='absolute bottom-0 left-9'>
          <div className='my-4'>
            <IconButton
              label='Logout'
              variant='outline'
              leftIcon={
                <FontAwesomeIcon icon={faArrowRightFromBracket} size='lg' />
              }
              buttonType='button'
              onClick={async () => await logout()}
            />
          </div>
        </div>
      )}
      {!isMobile && (
        <div className='absolute bottom-20 right-9'>
          <SupportWidget />
        </div>
      )}
      {isMobile && !isLoading && (
        <div className='flex justify-between items-center p-4'>
          <div>
            <IconButton
              label='Logout'
              variant='outline'
              leftIcon={
                <FontAwesomeIcon icon={faArrowRightFromBracket} size='lg' />
              }
              buttonType='button'
              onClick={async () => await logout()}
            />
          </div>
          <SupportWidget />
        </div>
      )}
    </ApplicationLayout>
  );
};

export default ApplicationICASigningV2;
