import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { UserAvailabilityResponse } from '../../../../openapi/yenta';
import { saveLoggedInAgentDetails } from '../../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../../types';
import ZenCustomSwitch from '../../../Zen/Input/ZenCustomSwitch';
import { useUpdateDoNotDisturb } from '../../../../query/roar/useRoar';

const ZenDoNotDisturbMenuItem: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userDetails = useSelector((state: RootState) => state.auth.userDetail);
  const agentId = userDetails?.id!;
  const userAvailability = (userDetails?.availability ||
    {}) as UserAvailabilityResponse;
  const timeZone =
    userDetails?.availability?.timeZone || DateTime.local().zoneName;

  const { mutate, isLoading } = useUpdateDoNotDisturb(agentId);

  const toggle = !!userAvailability.doNotDisturb;

  return (
    <div className='flex flex-row items-center text-white group z-0 rounded-xl justify-start px-2 py-2 my-1 hover:bg-zen-dark-22'>
      <div className='px-1 flex flex-row items-center font-zen-body space-x-2 font-semibold'>
        <span className='items-center text-sm'>Do Not Disturb</span>
        <ZenCustomSwitch
          disabled={isLoading}
          toggle={toggle}
          onClick={() =>
            mutate(
              {
                ...userAvailability,
                timeZone,
                doNotDisturb: !toggle,
              },
              {
                onSuccess: (data) => {
                  dispatch(
                    saveLoggedInAgentDetails({
                      ...userDetails,
                      availability: data.availability,
                    }),
                  );
                },
              },
            )
          }
          testId='do-not-disturb'
        />
      </div>
    </div>
  );
};

export default ZenDoNotDisturbMenuItem;
