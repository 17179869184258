import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandHoldingDollar,
  faTableColumns,
} from '@fortawesome/pro-regular-svg-icons';
import { ParticipantValue } from '../../../openapi/arrakis';
import { participantRoleDisplayName } from '../../../utils/TransactionHelper';
import PhoneNumberCell from '../../table/Cells/PhoneNumberCell';
import ZenFixedDataTable from '../../Zen/ZenFixedDataTable';
import ZenTransactionSummaryCard from './ZenTransactionSummaryCard';

interface TransactionCommissionPayerProps {
  cdPayer: ParticipantValue[];
}
const TransactionCommissionPayerSummary: React.FC<TransactionCommissionPayerProps> = ({
  cdPayer = [],
}) => {
  const columns: Array<Column<ParticipantValue>> = [
    {
      Header: 'Name',
      accessor: 'fullName',

      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-8 font-normal'>
          {value || 'N/A'}
        </div>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <p className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {participantRoleDisplayName(value!)}
        </p>
      ),
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          <PhoneNumberCell phoneNumber={value} />
        </div>
      ),
    },
    {
      Header: 'Company',
      accessor: 'company',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {value || 'N/A'}
        </div>
      ),
    },
    {
      Header: 'Email',
      accessor: 'emailAddress',
      headerContentClassName: '!text-xs !text-zen-dark-15 ',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal '>
          {value || 'N/A'}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ZenTransactionSummaryCard
        titleIconPlacement='left'
        titleAdjacentComponent={
          <FontAwesomeIcon
            icon={faHandHoldingDollar}
            className='mr-2 mb-1 h-3'
          />
        }
        title='Commission Payer'
      >
        <div className='!bg-white'>
          <ZenFixedDataTable<ParticipantValue>
            columns={columns}
            headerVariant='light'
            data={cdPayer || []}
            resourceName='Commission Payer'
            hidePagination
            hidePageSize
            hideFilters
            customEmptyComponent={
              <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
                <div>
                  <FontAwesomeIcon
                    icon={faTableColumns}
                    className='p-2'
                    size='2xl'
                  />
                </div>
                <div className='text-xs !font-zen-body !text-primary-dark !font-primary text-center'>
                  There are no commission payer to display.
                </div>
              </div>
            }
          />
        </div>
      </ZenTransactionSummaryCard>
    </div>
  );
};

export default TransactionCommissionPayerSummary;
