import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKBar } from 'kbar';
import { useEffect } from 'react';
import { useCreateToken } from '../../../query/wallet/useWallet';
import { scopes, upgradableScopes } from '../../../utils/WalletUtils';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';

interface WalletSendMoneyModalProps {
  isOpen: boolean;
  onClose(): void;
  bankingPartnershipId: string;
  accountId: string;
}

const WalletSendMoneyModal: React.FC<WalletSendMoneyModalProps> = ({
  isOpen,
  accountId,
  bankingPartnershipId,
  onClose,
}) => {
  const { query } = useKBar();

  const {
    data: unitToken,
    isLoading: tokenLoading,
    errorCode: tokenError,
  } = useCreateToken({
    bankingPartnershipId,
    scopes,
    upgradableScopes,
  });

  // Note: Disabling KBar hotkeys while the component is mounted
  useEffect(() => {
    query.disable(true);

    return () => {
      query.disable(false);
    };
  }, [query]);

  if (!isOpen || !bankingPartnershipId) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 h-full-window'>
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-60'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full overflow-hidden'>
        <div className='flex flex-col max-h-[90%] justify-center items-center w-full max-w-[90%] sm:max-w-[85%] md:w-auto z-10'>
          <div className='overflow-auto shadow-xl bg-white rounded-xl w-full'>
            <ZenResourceContainer
              loading={tokenLoading}
              isEmpty={!unitToken?.token}
              resourceName='payment'
              errorCode={tokenError}
            >
              <div className='h-14 px-6 flex justify-between items-center bg-rezen-blue-100 rounded-t-lg'>
                <p className='font-inter font-medium text-base'>Send Payment</p>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className='cursor-pointer text-regent-400'
                  size='xl'
                  onClick={onClose}
                />
              </div>
              <div>
                {/* @ts-ignore */}
                <unit-elements-ach-credit-payment
                  account-id={accountId}
                  customer-token={unitToken?.token}
                  is-auto-focus='true'
                  with-plaid='false'
                  style={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                  }}
                  language='https://ui.s.unit.sh/resources/4127/languages/0fae9a95-752f-477b-b367-dc74417ec351.json'
                  theme='https://ui.s.unit.sh/resources/4127/themes/6e0ef26a-a0c7-4334-bd3b-ee1637ffdb7f.json'
                />
              </div>
            </ZenResourceContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletSendMoneyModal;
