import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useGemini } from '../../../hooks/useGemini';
import {
  TransactionResponse,
  TransactionResponseCountryEnum,
} from '../../../openapi/arrakis';
import { CardItem, FeatureFlagTypeEnum } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { isSaleTransaction } from '../../../utils/TransactionHelper';
import { cn } from '../../../utils/classUtils';
import ZenTransactionDealTypeItem from '../Transaction/ZenTransactionDealTypeItem';
import ZenTransactionStatus from '../Transaction/ZenTransactionStatus';
import ZenCard from '../ZenCard';
import ZenPropertyTypePill from '../ZenPropertyTypePill';
import ZenUserPill from '../ZenUserPill';

interface ZenListingInformationCardProps {
  transaction: TransactionResponse;
}

const ZenListingInformationCard: React.FC<ZenListingInformationCardProps> = ({
  transaction,
}) => {
  const isChecklistDynamicsPropertyTypesEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.CHECKLIST_DYNAMICS_PROPERTY_TYPES,
  );
  const isGeminiEnabled = useGemini();
  const items: CardItem[] = [
    {
      name: 'List Price',
      value: displayAmount(transaction.price),
    },
    {
      name: 'Gross Commission',
      value: transaction.grossCommission
        ? displayAmount(transaction.grossCommission)
        : 'N/A',
    },
    {
      name: 'Status',
      value: (
        <div className='-mr-3'>
          <ZenTransactionStatus
            status={transaction.lifecycleState!.state!}
            country={
              (transaction.country! as unknown) as TransactionResponseCountryEnum
            }
          />
        </div>
      ),
    },
    {
      name: 'Deal Type',
      value: (
        <ZenTransactionDealTypeItem dealType={transaction.transactionType!} />
      ),
    },
    {
      name: 'Listing Owner',
      value: transaction.transactionOwner?.name ? (
        <Link to={`/people/${transaction.transactionOwner?.id}`}>
          <div className='-mr-3'>
            <ZenUserPill
              name={transaction.transactionOwner?.name}
              backgroundVariant='background'
            />
          </div>
        </Link>
      ) : (
        'N/A'
      ),
    },
    {
      name: 'Representing',
      value: (
        <div className='text-right'>
          {transaction.transactionOwner?.represents || 'N/A'}
        </div>
      ),
    },
    {
      name: 'MLS #',
      value: transaction.mlsNum || 'N/A',
    },
  ];

  if (
    transaction.grossCommissionPercentage &&
    isSaleTransaction(transaction.transactionType!)
  ) {
    items.splice(2, 0, {
      name: 'Gross Commission Percentage',
      value: transaction.grossCommissionPercentage,
    });
  }

  if (isChecklistDynamicsPropertyTypesEnabled) {
    items.splice(5, 0, {
      name: 'Property Type',
      value: (
        <div className='-mr-3'>
          <ZenPropertyTypePill
            name={capitalizeEnum(transaction.propertyType!)}
            backgroundVariant='rezen-midnight'
            size='sm'
            fontWeight='font-medium'
          />
        </div>
      ),
    });
  }

  const renderCardItem = (
    item: CardItem,
    index: number,
    showRightBorder: boolean = false,
  ) => {
    return (
      <div
        key={item.name}
        className={classNames(
          'px-0 lg:px-5',
          showRightBorder && 'border-r-0 lg:border-r',
        )}
      >
        {items.length + 1 !== index && index > 0 && (
          <div
            className={classNames('border-b', index === 4 && 'lg:border-b-0')}
          />
        )}

        <div className='px-4 h-12 justify-between flex-row flex items-center'>
          <div className='flex flex-col'>
            {item.url ? (
              <Link to={item.url}>
                <span className='text-primary-blue font-zen-body text-base font-semibold hover:underline'>
                  {item.name}
                </span>
              </Link>
            ) : (
              <span
                className={cn(
                  'leading-5',
                  isGeminiEnabled
                    ? 'font-zen-body-2 font-light text-sm text-primary-dark'
                    : 'font-zen-body font-normal text-base text-zen-dark-7',
                )}
              >
                {item.name}
              </span>
            )}
            <span
              className={cn(
                'text-sm ',
                isGeminiEnabled
                  ? 'font-zen-body-2 font-light text-primary-dark'
                  : 'font-zen-body text-zen-dark-5',
              )}
            >
              {item.subtitle}
            </span>
          </div>
          <div className='flex flex-row items-center space-x-2 justify-between'>
            <span
              className={cn(
                'leading-4 text-sm',
                isGeminiEnabled
                  ? 'font-zen-body-2 font-light text-primary-dark'
                  : 'font-zen-body text-zen-dark-8 font-semibold',
              )}
            >
              {item.value}
            </span>
            <div>{item?.rightActionComponent}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='pt-4 px-4'>
      <ZenCard title='Listing Information'>
        <div className='lg:py-4 flex flex-col lg:flex-row'>
          <div className='w-full lg:w-1/2'>
            {items.slice(0, 4).map((item, index) => {
              return renderCardItem(item, index, true);
            })}
          </div>

          <div className='w-full lg:w-1/2'>
            {items.slice(4).map((item, index) => {
              return renderCardItem(item, index + 4);
            })}
          </div>
        </div>
      </ZenCard>
    </div>
  );
};

export default ZenListingInformationCard;
