import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router-dom';
import ZenResourceLinkCard from '../../ZenResourceLinkCard';

interface ZenAgreementsProps {}

const ZenAgreements: React.FC<ZenAgreementsProps> = () => {
  const history = useHistory();

  return (
    <div>
      <ZenResourceLinkCard
        title='Agreements'
        onClick={() => history.push('/agreements')}
        icon={solid('file-contract')}
        iconSize='2x'
      />
    </div>
  );
};

export default ZenAgreements;
