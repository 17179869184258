import ZenAgreements from './ZenAgreements';

interface LegalComponentProps {}

const LegalComponent: React.FC<LegalComponentProps> = () => (
  <div className='grid grid-cols-1 grid-flow-row md:grid-cols-6 gap-4 p-4'>
    <ZenAgreements />
  </div>
);

export default LegalComponent;
