import {
  faCalendar,
  faCircleUser,
  faLocationDot,
  faPhoneArrowDownLeft,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CirclePlexusGif from '../../../../assets/img/roar/circle-plexus.gif';
import EllipseSvg from '../../../../assets/img/roar/ellipse.svg';
import useAgentsInfo from '../../../../hooks/useAgentsInfo';
import { Speaker } from '../../../../openapi/yada';
import { showSuccessToast } from '../../../../slices/ToastNotificationSlice';
import { setRecordingDataById } from '../../../../slices/TransactionSlice';
import { AppDispatch, RootState } from '../../../../types';
import { getFullName } from '../../../../utils/AgentHelper';
import { cn } from '../../../../utils/classUtils';
import { formatTime } from '../../../../utils/DateUtils';
import ZenSimpleModal from '../../../Zen/Modal/ZenSimpleModal';
import ZenAvatar from '../../../Zen/ZenAvatar';
import ZenButton from '../../../Zen/ZenButton';
import { useUpdateSpeakers } from '../../../../query/roar/useRoar';

interface EditSpeakersModalProps {
  isOpen: boolean;
  onClose(): void;
  recordingId: string;
}

const DEFAULT_SPEAKERS: Speaker[] = [
  { label: 'SPEAKER_00', name: undefined },
  { label: 'SPEAKER_01', name: undefined },
];

const EditSpeakersModal: React.FC<EditSpeakersModalProps> = ({
  isOpen,
  onClose,
  recordingId,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    userIds: { agentById },
    auth: { userDetail },
    transaction: { transactionDetailResponse, recordingDataById },
  } = useSelector((state: RootState) => state);

  const recordingBlock = recordingDataById[recordingId]?.recordingBlock || {};
  const mentionBlock = recordingDataById[recordingId]?.mentionBlock || {};

  const { transcript, speakers: initialSpeakers } =
    recordingBlock?.recordingTranscription || {};
  const transaction = transactionDetailResponse.data;

  const [selectedSpeakerLabel, setSelectedSpeakerLabel] = useState<string>();
  const [speakers, setSpeakers] = useState<Speaker[]>(
    initialSpeakers || DEFAULT_SPEAKERS,
  );

  const uniqueSegments = useMemo(() => {
    return uniqBy(
      transcript?.segments || [],
      (speaker) => speaker.speaker,
    ).slice(0, 2);
  }, [transcript?.segments]);

  const { mutate: updateSpeakers, isLoading } = useUpdateSpeakers();

  const onSubmit = () => {
    if (!selectedSpeakerLabel) {
      return;
    }
    updateSpeakers(
      {
        // @ts-expect-error
        id: recordingBlock.recordingId,
        speakers: speakers || DEFAULT_SPEAKERS,
      },
      {
        onSuccess: (data) => {
          dispatch(
            setRecordingDataById({
              id: recordingId,
              recordingBlock: {
                ...recordingBlock,
                recordingTranscription: {
                  ...recordingBlock.recordingTranscription,
                  speakers: data,
                },
              },
              mentionBlock,
            }),
          );
          dispatch(showSuccessToast('Speakers updated successfully'));
          onClose();
        },
      },
    );
  };

  const userIds = useMemo(() => {
    const arr: string[] = [];
    if (recordingBlock.callerId) {
      arr.push(recordingBlock.callerId);
    }
    if (recordingBlock.calleeId) {
      arr.push(recordingBlock.calleeId);
    }
    const speakers = recordingBlock.recordingTranscription?.speakers || [];
    for (const speaker of speakers) {
      if (speaker.userId) {
        arr.push(speaker.userId);
      }
    }
    return arr;
  }, [
    recordingBlock.calleeId,
    recordingBlock.callerId,
    recordingBlock.recordingTranscription?.speakers,
  ]);

  useAgentsInfo(userIds);

  const calleeInfo = agentById[recordingBlock.calleeId!];
  const calledAt =
    recordingBlock.callerCalledAt &&
    DateTime.fromMillis(recordingBlock.callerCalledAt, { locale: 'en-US' });
  const callerId = recordingBlock.callerId;
  const calleeId = recordingBlock.calleeId;
  const isCurrentUserTheCaller = callerId === userDetail?.id;
  const otherUserId = isCurrentUserTheCaller ? calleeId : callerId;
  const otherUserName = getFullName(agentById[otherUserId!]);
  const otherUser = agentById[otherUserId!];

  const handleUpdateSpeakers = (speakerLabel: string) => {
    setSelectedSpeakerLabel(speakerLabel);

    const updatedSpeakers = (speakers ?? DEFAULT_SPEAKERS).map((speaker) => {
      const newSpeaker = { ...speaker };
      if (newSpeaker.label === speakerLabel) {
        newSpeaker.userId = userDetail?.id;
        newSpeaker.name = userDetail?.fullName;
      } else {
        newSpeaker.userId = otherUserId;
        newSpeaker.name = otherUserName ?? undefined;
      }
      return newSpeaker;
    });

    setSpeakers(updatedSpeakers);
  };

  return (
    <ZenSimpleModal
      title='Identify yourself in the transcript below'
      isOpen={isOpen}
      size='extraLarge'
      onClose={onClose}
      containerClassNames='!w-full !max-w-5xl'
    >
      <div className='py-4 px-4 md:py-8 md:px-12 font-inter text-sm text-black flex w-full flex-col justify-center items-center'>
        {/* titles */}
        <div className='w-full md:w-auto flex flex-col md:flex-row md:justify-center md:items-center border border-regent-300 rounded-lg py-1.5 px-4 md:space-x-3 space-y-3 md:space-y-0'>
          <div className='flex md:items-center space-x-2'>
            <FontAwesomeIcon
              icon={faPhoneArrowDownLeft}
              className='mt-1 md:mt-0 text-rezen-blue-600'
            />
            <div className='flex items-center space-x-1.5'>
              {otherUser ? (
                <ZenAvatar
                  name={getFullName(otherUser)!}
                  avatar={otherUser.avatar}
                  size='xxs'
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className='text-rezen-blue-600 ml-1'
                  fontSize={20}
                />
              )}
              <p className='text-grey-600 font-medium whitespace-nowrap'>
                {getFullName(calleeInfo) || 'Unknown'}
              </p>
            </div>
          </div>
          {!!calledAt && (
            <>
              <hr className='h-6 bg-regent-300 w-px hidden md:block' />
              <div className='flex md:items-center space-x-2'>
                <FontAwesomeIcon
                  icon={faCalendar}
                  className='mt-1 md:mt-0 text-grey-500'
                />
                <div className='flex items-center space-x-1.5 whitespace-nowrap'>
                  <p className='text-grey-600 font-medium'>
                    {calledAt.toFormat('cccc') +
                      ' ' +
                      calledAt.toFormat('DD').split(',')[0]}
                  </p>
                  <span className='w-1 h-1 bg-grey-400 rounded-full' />
                  <p className='text-grey-600 font-medium'>
                    {calledAt.toFormat('t')}
                  </p>
                </div>
              </div>
            </>
          )}
          <hr className='h-6 bg-regent-300 w-px hidden md:block' />
          <div className='flex md:items-center space-x-2'>
            <FontAwesomeIcon
              icon={faLocationDot}
              className='mt-1 md:mt-0 text-grey-500'
            />
            <p className='text-grey-600 font-medium line-clamp-1'>
              {transaction?.address?.oneLine || 'N/A'}
            </p>
          </div>
        </div>

        {/* containers */}
        <div className='mt-8 w-full grid grid-cols-1 md:grid-cols-2 gap-6 text-sm'>
          {uniqueSegments.map((seg, index) => {
            const speaker = speakers?.find(
              (speaker) => speaker?.label === seg?.speaker,
            );
            const isSelected = selectedSpeakerLabel === seg.speaker;
            const user = isSelected ? userDetail : agentById[speaker?.userId!];
            const speakerName = speaker?.name || getFullName(user!);

            return (
              <div
                key={seg.speaker}
                onClick={() => handleUpdateSpeakers(seg.speaker!)}
                className={cn(
                  'col-span-1 p-6 border border-gray-100 rounded-xl transition duration-500 md:min-h-[220px]',
                  selectedSpeakerLabel && 'opacity-60',
                  isSelected &&
                    'border-rezen-blue-600 bg-regent-100 opacity-100',
                  !selectedSpeakerLabel &&
                    'hover:border-rezen-blue-600 hover:bg-regent-100 hover:opacity-100',
                )}
                aria-label='speaker'
              >
                <div className='flex items-center space-x-2'>
                  {isSelected || user ? (
                    <ZenAvatar
                      name={speakerName!}
                      imageUrl={user?.avatar}
                      size='xs'
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      className='text-rezen-blue-600'
                      fontSize={24}
                    />
                  )}
                  <div className='text-regent-900'>
                    {speakerName || `Speaker ${index + 1}`}
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='text-zen-dark-6'>{formatTime(seg.start!)}</p>
                  <p className='mt-2 text-primary-dark font-light'>
                    {seg.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* footer */}
      <div className='border-t border-grey-300 font-inter flex flex-col md:flex-row md:justify-between md:items-center space-y-4 md:space-y-0 w-full px-6 py-3 bg-regent-100'>
        <div className='flex items-center space-x-2'>
          <div className='relative'>
            <img src={EllipseSvg} alt='ellipse' className='w-4 h-4' />
            <div className='w-2.5 h-2.5 absolute mt-px top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '>
              <img
                src={CirclePlexusGif}
                alt='circle-plexus'
                className='w-full h-auto'
              />
            </div>
          </div>
          <p className='text-base font-light text-grey-500'>
            Thank you for your patience as Leo learns to identify speakers
          </p>
        </div>
        <ZenButton
          label='Done'
          className='!px-14 !py-2 !font-normal'
          isDisabled={!selectedSpeakerLabel || isLoading}
          isSubmitting={isLoading}
          onClick={onSubmit}
          fontstyle
        />
      </div>
    </ZenSimpleModal>
  );
};
export default EditSpeakersModal;
