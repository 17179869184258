import {
  createQueryKeys,
  createQueryKeyStore,
} from '@lukemorales/query-key-factory';
import { PromptLibraryRequest } from '../../openapi/leo';
import { InvitationEnum } from '../../routes/JoinByInvitationRoute';

export interface PageQueryParams {
  pageIndex: number;
  pageSize: number;
  params?: Record<string, any>;
}

// common keys needs to be in this format to work with query-key-factory
// feel free to update this if needed
export const commonQueryKeys = createQueryKeys('common', {
  list: (params: PageQueryParams) => [
    `pageIndex=${params.pageIndex || ''}`,
    `pageSize=${params.pageSize || ''}`,
    `data=${JSON.stringify(params.params || '')}`,
  ],
});

/**
 * For detail usage, please see https://github.com/lukemorales/query-key-factory
 */
export const queryKeys = createQueryKeyStore({
  directory: {
    list: commonQueryKeys.list,
    detail: (type: string, id: string) => [type, id],
    w9: null,
  },
  directoryInfinite: {
    list: (params) => [`data=${JSON.stringify(params || '')}`],
  },
  transaction: {
    detail: (id: string) => [id],
    transactionsList: (params) => [`data=${JSON.stringify(params || '')}`],
    summaryPdf: (id: string) => [id],
  },
  user: {
    agentInfoByIds: (ids: string[]) => [ids],
  },
  roar: {
    officeHours: (agentId: string) => ['office-hours', agentId],
    voiceCall: (groupId: string) => ['voice-call', groupId],
    sendSms: (userId: string) => ['send-sms', userId],
    updateSpeakers: null,
    speakers: (pathname: string) => [pathname],
  },
  keymaker: {
    signin: ['signin'],
  },
  leoPrompts: {
    list: (promptReq: PromptLibraryRequest) => ['fetchPrompts', promptReq],
  },
  invite: {
    get: (id: string, type: InvitationEnum) => [id, type],
    coupon: (
      id: string,
      type: InvitationEnum,
      applicationId: string,
      amount: number,
    ) => [id, type, applicationId, amount],
  },
  checklist: {
    detail: (id: string) => [id],
    getChecklistProgressByIds: (ids: string[]) => [ids],
  },
  wallet: {
    details: null,
    token: null,
  },
  agent: {
    keymaker: (id: string) => [id],
  },
  login: {
    googleSSO: (usernameOrEmail: string) => [usernameOrEmail],
    twoFactor: null,
    resendCode: null,
  },
  boardAndMls: {
    detail: (id: string) => [id],
    secrets: (id: string) => [id],
    getPropertyTypeMapping: (id: string) => [id],
    getPropertyTypesWanderer: (id: string) => [id],
    updateBoardOrMls: (id: string) => [id],
  },
});
