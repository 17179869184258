import { faArrowDownToSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useLogout } from '../../hooks/useLogout';
import IconButton from '../IconButton';

interface LogoutButtonProps {}

const LogoutButton: React.FC<LogoutButtonProps> = () => {
  const logout = useLogout();
  return (
    <div
      className={classNames(
        isMobile
          ? 'flex justify-start items-center p-4'
          : 'absolute bottom-0 left-9',
      )}
    >
      <div className={classNames(isMobile ? 'my-0' : 'my-5')}>
        <IconButton
          label='Logout'
          variant='outline'
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowDownToSquare}
              className='-rotate-90 mx-0.5'
            />
          }
          buttonType='button'
          onClick={async () => await logout()}
        />
      </div>
    </div>
  );
};

export default LogoutButton;
