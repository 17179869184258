import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightToBracket,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import { Alert } from '../components/commons/Alert';
import { JwtAuthenticationResponseMfaTypeEnum } from '../openapi/keymaker';
import { getAuthCookie } from '../utils/AuthUtils';
import useQueryParams from '../hooks/useQueryParams';
import { constructLink } from '../utils/UrlUtils';
import { RootState } from '../types';
import { Button } from '../components/commons/Button';
import { PinInput } from '../components/commons/PinInput';
import { use2FAResendCode, useSignInWithMfa } from '../query/login/useLogin';

interface Query {
  redirectTo?: string;
}

const ZenLoginTwoFactorAuthenticationRoute: React.FC = () => {
  const {
    auth: { currentUserJwtResponse },
  } = useSelector((state: RootState) => state);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const { redirectTo }: Query = useQueryParams<Query>();
  const history = useHistory();

  const isSmsTypeMFA =
    currentUserJwtResponse?.mfaType ===
    JwtAuthenticationResponseMfaTypeEnum.Sms;

  const {
    mutateAsync: resendCode,
    isLoading: resendCodeLoading,
    enableResendCode,
  } = use2FAResendCode();

  const {
    mutateAsync: submitCode,
    isLoading,
    invalidCode,
    serverError,
    code,
    setCode,
    setInvalidCode,
  } = useSignInWithMfa();

  useEffect(() => {
    if (!getAuthCookie()) {
      history.push(constructLink('/login', { redirectTo }));
    }
  }, [history, redirectTo]);

  return (
    <form>
      <div className='bg-gray-100'>
        <div className='flex items-center justify-center h-screen mx-auto'>
          <div className='p-10 bg-white border-2 border-gray-100 min-w-1/3'>
            <div className='mb-5 -mt-20 text-center'>
              <div className='inline-block p-10 text-primary-light rounded-full bg-primary-dark relative'>
                <FontAwesomeIcon
                  icon={faArrowRightToBracket}
                  className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-1/3 w-1/3'
                />
              </div>
            </div>
            <p className='text-3xl text-center font-inter'>
              Two-Factor Authentication
            </p>
            {serverError && (
              <div className='mt-3'>
                <Alert
                  title={serverError}
                  color='red'
                  icon={<FontAwesomeIcon icon={faInfoCircle} />}
                />
              </div>
            )}
            <div className='py-5 font-inter text-sm font-light text-center'>
              <p>Two-factor authentication is enabled for your account.</p>
              <p>
                {isSmsTypeMFA
                  ? `Enter the code we texted to (***) *** ${currentUserJwtResponse?.phoneNumber!.slice(
                      -4,
                    )}.`
                  : `Enter the code from the authenticator app on your mobile device.`}
              </p>
            </div>
            <div>
              <PinInput
                length={6}
                onComplete={(code: string) => {
                  // See comment below
                  hiddenInputRef.current?.focus();
                  submitCode(code);
                }}
                value={code}
                onChange={(code: string) => {
                  setCode(code);
                  setInvalidCode(false);
                }}
                oneTimeCode
                disabled={isLoading}
                error={invalidCode}
                data-testid='code-input'
              />
            </div>
            {/**
             * When an incorrect pin is enter, the code is being cleared
             * Because of the component library, the last input still thinks that it has focus and will not display the placeholder (or the cursor)
             * This input is being used to take the focus once a code has been entered so all placeholders will appear
             */}
            <input ref={hiddenInputRef} className='h-0 leading-0 w-0' />
            <div className='mt-1 flex flex-col gap-3 items-center justify-center'>
              {isSmsTypeMFA && enableResendCode && (
                <p className='text-md font-primary-regular text-dark'>
                  Text message sent! Enter code above
                </p>
              )}
              {isSmsTypeMFA && (
                <Button
                  type='button'
                  onClick={resendCode}
                  disabled={resendCodeLoading || enableResendCode}
                  loading={resendCodeLoading}
                  variant='filled'
                  color='blue'
                  fullWidth
                >
                  Resend Code
                </Button>
              )}
              <Button
                type='button'
                onClick={() =>
                  history.push(constructLink('/login', { redirectTo }))
                }
                fullWidth
                disabled={isLoading}
                variant='outline'
                color='blue'
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ZenLoginTwoFactorAuthenticationRoute;
