import { createAction, Priority } from 'kbar';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigateIfNotCurrentRoute } from '../../../hooks/useNavigateIfNotCurrentRoute';
import {
  AttachedFeeValueFeeTypeEnum,
  TransactionLifecycleStateValueStateEnum,
} from '../../../openapi/arrakis';
import { openSidebar } from '../../../slices/KBarSlice';
import {
  recalculateCommissionModal,
  toggleAddCommissionParticipant,
  toggleAdditionalFee,
  toggleCommissionSplit,
} from '../../../slices/QuickActionSlice';
import { KBarActionEnum, ParticipantType, RootState } from '../../../types';
import {
  getActionableTransitions,
  isTransactionReadOnly,
} from '../../../utils/TransactionHelper';
import useKbarRedirect from '../useKbarRedirect';

const useTransactionActions = () => {
  const dispatch = useDispatch();
  const navigateIfNotCurrentRoute = useNavigateIfNotCurrentRoute();
  const location = useLocation();
  const { redirectTo } = useKbarRedirect();
  const {
    auth: { isAdmin, userDetail, isBroker },
    transaction: {
      transactionDetailResponse: { data },
    },
  } = useSelector((state: RootState) => state);

  const isTransactionDetailRoute = useMemo(
    () => location.pathname.startsWith(`/transactions/${data?.id}`),
    [data, location.pathname],
  );

  const preActions = () => {
    navigateIfNotCurrentRoute(`/transactions/${data!.id}/detail`);
  };

  const addParticipant = () => {
    preActions();
    dispatch(
      toggleAddCommissionParticipant({
        agentType: ParticipantType.EXTERNAL_ENTITY,
        isOpen: true,
      }),
    );
    dispatch(openSidebar(KBarActionEnum.EDIT_TRANSACTION));
  };

  const addAdditionalFeeOrRebate = (
    preselectedFeeType: AttachedFeeValueFeeTypeEnum,
  ) => {
    preActions();
    dispatch(
      toggleAdditionalFee({
        isOpen: true,
        preselectedFeeType,
      }),
    );
  };

  const canTransactionBeEdited = useMemo(() => {
    if (!data) {
      return false;
    }
    const isTerminated =
      data?.lifecycleState?.state ===
        TransactionLifecycleStateValueStateEnum.Terminated || data?.terminated;
    return (
      !isTerminated && (!isTransactionReadOnly(data!, userDetail!) || isAdmin)
    );
  }, [data, isAdmin, userDetail]);

  const canTransactionBeTerminated = useMemo(() => {
    if (!data) {
      return false;
    }
    const isTerminationValidState = getActionableTransitions(
      data,
      isAdmin,
    )?.secondaryTransitions.find(
      (item) =>
        item.state === TransactionLifecycleStateValueStateEnum.Terminated,
    );
    if (isBroker) {
      return isTerminationValidState;
    } else {
      return canTransactionBeEdited && isTerminationValidState;
    }
  }, [canTransactionBeEdited, data, isAdmin, isBroker]);

  const terminateTransactionAction = canTransactionBeTerminated
    ? [
        {
          id: 'terminateTransaction',
          name: 'Terminate Transaction',
          shortcut: ['t', 'x'],
          keywords: 'Terminate Transaction',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () =>
            dispatch(openSidebar(KBarActionEnum.TERMINATE_TRANSACTION)),
        },
      ]
    : [];

  const editTransactionActions = canTransactionBeEdited
    ? [
        {
          id: 'editTransaction',
          name: 'Edit Transaction',
          shortcut: ['t', 'e'],
          keywords: 'Edit Transaction',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => {
            preActions();
            dispatch(openSidebar(KBarActionEnum.EDIT_TRANSACTION));
          },
        },
        {
          id: 'addAgent',
          name: 'Add Agent',
          shortcut: ['t', 'q'],
          keywords: 'Add Agent',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: addParticipant,
        },
        {
          id: 'addParticipant',
          name: 'Add Participant',
          shortcut: ['t', 'q'],
          keywords: 'Add Participant',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: addParticipant,
        },
        {
          id: 'addOpcity',
          name: 'Add Opcity',
          shortcut: ['t', 'q'],
          keywords: 'Add Opcity',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: addParticipant,
        },
        {
          id: 'addTransactionCoordinator',
          name: 'Add Transaction Coordinator',
          shortcut: ['t', 'n'],
          keywords: 'Add Transaction Coordinator',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () =>
            addAdditionalFeeOrRebate(
              AttachedFeeValueFeeTypeEnum.TransactionCoordinator,
            ),
        },
        {
          id: 'addAdditionalCommission',
          name: 'Add Additional Commission',
          shortcut: ['t', 's'],
          keywords: 'Add Additional Commission',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () =>
            addAdditionalFeeOrRebate(
              AttachedFeeValueFeeTypeEnum.AdditionalCommission,
            ),
        },
        {
          id: 'addRebate',
          name: 'Add Rebate',
          shortcut: ['t', 'b'], // problem
          keywords: 'Add Rebate',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () =>
            addAdditionalFeeOrRebate(AttachedFeeValueFeeTypeEnum.Rebate),
        },
        {
          id: 'adjustCommission',
          name: 'Adjust Commission',
          shortcut: ['t', 'j'],
          keywords: 'Adjust Commission',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => {
            preActions();
            dispatch(toggleCommissionSplit(true));
          },
        },
        {
          id: 'recalculateCommission',
          name: 'Recalculate Commission',
          shortcut: ['t', 'g'],
          keywords: 'Recalculate Commission',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => {
            preActions();
            dispatch(recalculateCommissionModal(true));
          },
        },
      ]
    : [];

  const transactionActions = isTransactionDetailRoute
    ? [
        ...terminateTransactionAction,
        ...editTransactionActions,
        {
          id: 'commissionDocuments',
          name: 'Commission Documents',
          shortcut: ['t', 'u'],
          keywords: 'Commission Documents',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/cda`),
        },
        {
          id: 'details',
          name: 'Details',
          shortcut: ['t', 'o'],
          keywords: 'details',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/detail`),
        },
        {
          id: 'deposits',
          name: 'Deposits',
          shortcut: ['t', 'd'],
          keywords: 'deposits',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/view-deposits`),
        },
        {
          id: 'checklist',
          name: 'Checklist',
          shortcut: ['t', 'h'],
          keywords: 'checklist',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/checklist`),
        },
        {
          id: 'comments',
          name: 'Comments',
          shortcut: ['t', 'm'],
          keywords: 'comments',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/comments`),
        },
        {
          id: 'fileCabinet',
          name: 'File Cabinet',
          shortcut: ['t', 'f'],
          keywords: 'file cabinet',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/file-cabinet`),
        },
        {
          id: 'uploadTransactionDocument',
          name: 'Upload Transaction Document',
          shortcut: ['t', 'f'],
          keywords: 'Upload Transaction Document',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/file-cabinet`),
        },
        {
          id: 'uploadTransactionFile',
          name: 'Upload Transaction File',
          shortcut: ['t', 'f'],
          keywords: 'Upload Transaction File',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/file-cabinet`),
        },
        {
          id: 'activity',
          name: 'Activity',
          shortcut: ['t', 'a'],
          keywords: 'Activity',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/transactions/${data?.id}/activity`),
        },
        {
          id: 'paymentInvoices',
          name: 'Payment Invoices',
          shortcut: ['t', 'i'],
          keywords: 'Payment Invoices',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () =>
            redirectTo(`/transactions/${data?.id}/payment-invoices`),
        },
        {
          id: 'payments',
          name: 'Payments',
          shortcut: ['t', 'p'],
          keywords: 'Payments',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () =>
            redirectTo(`/transactions/${data?.id}/outgoing-payments`),
        },
        {
          id: 'outgoingPayments',
          name: 'Outgoing Payments',
          shortcut: ['t', 'p'],
          keywords: 'Outgoing Payments',
          section: `Transaction: ${data?.address?.oneLine}`,
          perform: () =>
            redirectTo(`/transactions/${data?.id}/outgoing-payments`),
        },
      ]
    : [];

  const transactionKBarActions = transactionActions.map((action) =>
    createAction({
      ...action,
      section: {
        name: action.section,
        priority: isTransactionDetailRoute ? Priority.HIGH : Priority.LOW,
      },
    }),
  );

  return { transactionActions, transactionKBarActions };
};

export default useTransactionActions;
