import ErrorService from '../services/ErrorService';

/**
 * Performs a fetch request and handles errors.
 * @param input - The URL or Request object to fetch.
 * @param init - The optional RequestInit object.
 * @returns A Promise that resolves to the Response object if the request is successful.
 * @throws An Error if the request fails.
 */
export const performFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  try {
    // eslint-disable-next-line bolt/no-native-fetch
    const response = await fetch(input, init);

    if (!response.ok) {
      const errMsg = `Failed to fetch with status code: ${response.status}`;
      const resBody = await response.text();
      const error = new Error(errMsg);

      ErrorService.notify(errMsg, error, {
        data: { input, response: resBody },
      });

      throw error;
    }

    return response;
  } catch (error) {
    ErrorService.notify(error.message, error, {
      data: { input },
    });

    throw error;
  }
};
