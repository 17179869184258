import { Column } from 'react-table';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns, faUser } from '@fortawesome/pro-regular-svg-icons';
import { ParticipantValue } from '../../../openapi/arrakis';
import PhoneNumberCell from '../../table/Cells/PhoneNumberCell';
import { participantRoleDisplayName } from '../../../utils/TransactionHelper';
import ZenFixedDataTable from '../../Zen/ZenFixedDataTable';
import ZenTransactionSummaryCard from './ZenTransactionSummaryCard';

interface TransactionOtherParticipantsProps {
  otherParticipants: ParticipantValue[] | undefined;
}
const TransactionOtherParticipantsSummary: React.FC<TransactionOtherParticipantsProps> = ({
  otherParticipants = [],
}) => {
  const columns: Array<Column<ParticipantValue>> = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
        headerContentClassName: '!text-xs !text-zen-dark-15',
        Cell: ({ value }) => (
          <div className='word-break font-zen-body text-xs text-zen-dark-8 font-normal'>
            {value || 'N/A'}
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Role',
        accessor: 'role',
        headerContentClassName: '!text-xs !text-zen-dark-15',
        Cell: ({ value }) => (
          <p className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
            {participantRoleDisplayName(value!)}
          </p>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        headerContentClassName: '!text-xs !text-zen-dark-15',
        Cell: ({ value }) => (
          <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
            <PhoneNumberCell phoneNumber={value} />
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Company',
        accessor: 'company',
        headerContentClassName: '!text-xs !text-zen-dark-15',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
            {value || 'N/A'}
          </div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'emailAddress',
        headerContentClassName: '!text-xs !text-zen-dark-15',
        Cell: ({ value }) => (
          <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
            {value || 'N/A'}
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  return (
    <div>
      <ZenTransactionSummaryCard
        titleIconPlacement='left'
        titleAdjacentComponent={
          <FontAwesomeIcon icon={faUser} className='mr-2 mb-1 h-3' />
        }
        title='Other Participants'
      >
        <ZenFixedDataTable<ParticipantValue>
          columns={columns}
          headerVariant='light'
          data={otherParticipants || []}
          resourceName='Other Participants'
          hidePagination
          hidePageSize
          hideFilters
          pageSize={otherParticipants.length}
          customEmptyComponent={
            <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
              <div>
                <FontAwesomeIcon
                  icon={faTableColumns}
                  className='p-2'
                  size='2xl'
                />
              </div>
              <div className='text-xs !font-zen-body !text-primary-dark !font-primary text-center'>
                There are no other participants to display.
              </div>
            </div>
          }
        />
      </ZenTransactionSummaryCard>
    </div>
  );
};

export default TransactionOtherParticipantsSummary;
