import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LeoResponseMessageContext } from '../../../utils/LeoUtils';

export type LearningLink = {
  title: string;
  url: string;
};

type NeoLeoLearningLinksProps = {
  msgContext: LeoResponseMessageContext;
};

const NeoLeoLearningLinks: React.FC<NeoLeoLearningLinksProps> = ({
  msgContext,
}) => {
  let learningLinks: LearningLink[] = [];
  if (msgContext && msgContext?.references?.documents.length > 0) {
    learningLinks = msgContext.references.documents
      .map(({ title, url }) => ({ title, url }))
      .filter((el) => !!el.url);
  }

  if (!learningLinks.length) {
    return null;
  }

  return (
    <div className='text-white font-inter'>
      <p className='font-medium text-sm'>Click on these links to learn more</p>
      {learningLinks.map((link) => (
        <a key={link.url} href={link.url} target='_blank' rel='noreferrer'>
          <div className='mt-2 flex flex-col gap-2'>
            <div className='p-2 bg-white bg-opacity-10 text-aqua rounded-md flex justify-between items-center gap-1'>
              <p className='text-sm font-medium line-clamp-1 mr-1'>
                {link.title}
              </p>
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className='text-sm'
              />
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default NeoLeoLearningLinks;
