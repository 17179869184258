import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SaveFile } from '../../../assets/img/save_file_transaction.svg';
import { useUpdateAgentIdsForTransactionBuilder } from '../../../hooks/useUpdateAgentIdsForTransactionBuilder';
import {
  AgentParticipantInfoRoleEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import { getFullName, isCanadianUser } from '../../../utils/AgentHelper';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { capitalizeEnum, formatAddress } from '../../../utils/StringUtils';
import { isSaleTransaction } from '../../../utils/TransactionHelper';
import AgentWebsiteConfirmationModal from '../../agentWebsiteOnboarding/AgentWebsiteConfirmationModal';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenSimpleConfirmationModal from '../../Zen/Modal/ZenSimpleConfirmationModal';
import ZenButton from '../../Zen/ZenButton';
import withCreateListingProgress from '../CreateListing/withCreateListingProgress';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  ZenCreateListingFormState,
  ZenCreateListingStepName,
} from './ZenCreateListingSteps';

const ZenListingStepsReview: StepByStepComponent<
  ZenCreateListingFormState,
  ZenCreateListingStepName
> = ({
  navigateTo,
  form: {
    formState: { isSubmitting },
  },
  onSubmit,
}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();
  const {
    userIds: { agentById },
    auth: { userDetail },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);

  const isChecklistDynamicsPropertyTypesEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.CHECKLIST_DYNAMICS_PROPERTY_TYPES,
  );

  useUpdateAgentIdsForTransactionBuilder(transactionBuilder!);

  const oneLineAddress = `${formatAddress(
    transactionBuilder?.address?.street!,
    transactionBuilder?.address?.street2,
    transactionBuilder?.address?.city!,
    capitalizeEnum(transactionBuilder?.address?.state! || ''),
    transactionBuilder?.address?.zip!,
  )} ${capitalizeEnum(userDetail?.accountCountry!)}`;

  const teamLeaders = transactionBuilder?.agentsInfo?.coAgents?.filter(
    (p) => p.role === AgentParticipantInfoRoleEnum.TeamLeader,
  );

  const coAgents = transactionBuilder?.agentsInfo?.coAgents?.filter(
    (p) => p.role !== AgentParticipantInfoRoleEnum.TeamLeader,
  );

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-3xl mx-auto flex-grow'>
        <h4 className='mb-2 text-2xl font-bold'>Review listing information</h4>
        {/* STEP ONE */}
        <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
          <div className='flex flex-row items-center font-zen-body'>
            <div className='w-7 h-7 shrink-0 flex justify-center items-center rounded-full bg-gray-200'>
              <span className='print-review-counter' />
            </div>
            <div className='py-2 grid grid-flow-row grid-cols-2 gap-4 mx-5 border-coolGray-100'>
              <div className='px-3'>
                <p className='leading-5 text-gray-500'>Address:</p>
                <p className='leading-5'>{oneLineAddress}</p>
              </div>
              {!isCanadianUser(userDetail?.accountCountry!) && (
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Year Built:</p>
                  <p className='leading-5'>{transactionBuilder?.yearBuilt}</p>
                </div>
              )}
              <div className='px-3'>
                <p className='leading-5 text-gray-500'>MLS Number:</p>
                <p className='leading-5'>{transactionBuilder?.mlsNumber}</p>
              </div>
              <div className='px-3'>
                <p className='leading-5 text-gray-500'>Unit Number:</p>
                <p className='leading-5'>{transactionBuilder?.address?.unit}</p>
              </div>
            </div>
          </div>
          <ZenButton
            label='Edit'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPencil} className='text-base' />
            }
            onClick={() => navigateTo(ZenCreateListingStepName.ADDRESS_STEP)}
            variant='primary-link'
          />
        </div>
        {/* STEP TWO */}
        <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
          <div className='flex flex-row items-center font-zen-body'>
            <div className='w-7 h-7 shrink-0 flex justify-center items-center rounded-full bg-gray-200'>
              <span className='print-review-counter' />
            </div>
            <div className='py-2 grid grid-flow-row grid-cols-2 gap-4 mx-5 border-coolGray-100'>
              <div className='px-3'>
                <p className='leading-5 text-gray-500'>Deal Type:</p>
                <p className='leading-5'>
                  {capitalizeEnum(transactionBuilder?.dealType!)}
                </p>
              </div>
              <div className='px-3'>
                <p className='leading-5 text-gray-500'>List Price:</p>
                <p className='leading-5'>
                  {displayAmount(transactionBuilder?.salePrice! as MoneyValue, {
                    hideCurrency: true,
                  })}
                </p>
              </div>
              {transactionBuilder?.saleCommission?.percentEnabled ? (
                <>
                  <div className='px-3'>
                    <p className='leading-5 text-gray-500'>
                      Sales Commission Percentage:
                    </p>
                    <p className='leading-5'>
                      {transactionBuilder?.saleCommission?.commissionPercent ||
                        0}{' '}
                      %
                    </p>
                  </div>
                  <div className='px-3'>
                    <p className='leading-5 text-gray-500'>
                      Sales Gross Commission (
                      {transactionBuilder?.salePrice?.currency!}):
                    </p>
                    <p className='leading-5'>
                      {displayAmount(
                        {
                          amount: +(
                            (transactionBuilder?.saleCommission
                              ?.commissionPercent! *
                              transactionBuilder?.salePrice?.amount!) /
                            100
                          ).toFixed(2),
                          currency: transactionBuilder?.salePrice
                            ?.currency! as MoneyValueCurrencyEnum,
                        },
                        { hideCurrency: true },
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>
                    Sales Gross Commission (
                    {
                      transactionBuilder?.saleCommission?.commissionAmount
                        ?.currency!
                    }
                    ):
                  </p>
                  <p className='leading-5'>
                    {displayAmount(
                      transactionBuilder?.saleCommission
                        ?.commissionAmount as MoneyValue,
                      { hideCurrency: true },
                    )}
                  </p>
                </div>
              )}

              {transactionBuilder?.listingCommission?.percentEnabled ? (
                <>
                  <div className='px-3'>
                    <p className='leading-5 text-gray-500'>
                      Listing Commission Percentage:
                    </p>
                    <p className='leading-5'>
                      {transactionBuilder?.listingCommission
                        ?.commissionPercent || 0}{' '}
                      %
                    </p>
                  </div>
                  <div className='px-3'>
                    <p className='leading-5 text-gray-500'>
                      Listing Gross Commission (
                      {transactionBuilder?.salePrice?.currency}):
                    </p>
                    <p className='leading-5'>
                      {displayAmount(
                        {
                          amount: +(
                            (transactionBuilder?.listingCommission
                              ?.commissionPercent! *
                              transactionBuilder?.salePrice?.amount!) /
                            100
                          ).toFixed(2),
                          currency: transactionBuilder?.salePrice
                            ?.currency! as MoneyValueCurrencyEnum,
                        },
                        { hideCurrency: true },
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>
                    Listing Gross Commission (
                    {
                      transactionBuilder?.listingCommission?.commissionAmount
                        ?.currency
                    }
                    ):
                  </p>
                  <p className='leading-5'>
                    {displayAmount(
                      transactionBuilder?.listingCommission
                        ?.commissionAmount as MoneyValue,
                      { hideCurrency: true },
                    )}
                  </p>
                </div>
              )}

              <div className='px-3'>
                <p className='leading-5 text-gray-500'>Listing Start Date:</p>
                <p className='leading-5'>{transactionBuilder?.listingDate}</p>
              </div>

              <div className='px-3'>
                <p className='leading-5 text-gray-500'>Expiration Date:</p>
                <p className='leading-5'>
                  {transactionBuilder?.listingExpirationDate}
                </p>
              </div>

              {isChecklistDynamicsPropertyTypesEnabled && (
                <div className='px-3'>
                  <p className='leading-5 text-gray-500'>Property Type:</p>
                  <p className='leading-5'>
                    {capitalizeEnum(transactionBuilder?.propertyType!)}
                  </p>
                </div>
              )}
            </div>
          </div>
          <ZenButton
            label='Edit'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPencil} className='text-base' />
            }
            onClick={() => navigateTo(ZenCreateListingStepName.TYPE_PRICE_STEP)}
            variant='primary-link'
          />
        </div>
        {/* STEP THREE */}
        <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
          <div className='flex flex-row items-center font-zen-body'>
            <div className='w-7 h-7 shrink-0 flex justify-center items-center rounded-full bg-gray-200'>
              <span className='print-review-counter' />
            </div>
            <div className='py-2 grid grid-flow-row grid-cols-2 gap-4 mx-5 border-coolGray-100'>
              {transactionBuilder?.sellers?.map((item) => {
                return (
                  <div className='px-3' key={item.email}>
                    <p className='leading-5 text-gray-500'>
                      {isSaleTransaction(
                        (transactionBuilder?.dealType! as unknown) as TransactionResponseTransactionTypeEnum,
                      )
                        ? 'Seller'
                        : 'Landlord'}
                      :
                    </p>
                    {item.companyName ? (
                      <p>{item.companyName}</p>
                    ) : (
                      <p className='leading-5 break-words'>
                        {item.firstName} {item.lastName}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <ZenButton
            label='Edit'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPencil} className='text-base' />
            }
            onClick={() =>
              navigateTo(ZenCreateListingStepName.BUYER_AND_SELLER_STEP)
            }
            variant='primary-link'
          />
        </div>
        {/* STEP FOUR  */}
        <div className='py-2 flex flex-row items-center border-b justify-between border-coolGray-300'>
          <div className='flex flex-row items-center'>
            <div className='w-7 pt-1 h-7 rounded-full bg-gray-200 flex justify-center items-center'>
              <span className='print-review-counter' />
            </div>
            <div className='py-2 grid grid-flow-row grid-cols-2 gap-4 mx-5 border-coolGray-100'>
              <div className='px-3'>
                <p className='leading-5 text-gray-500'>Agent (listing owner)</p>
                <p className='leading-5'>
                  {getFullName(
                    agentById[
                      transactionBuilder?.agentsInfo?.ownerAgent[0]?.agentId!
                    ],
                  )}
                </p>
              </div>
              <div className='px-3'>
                <p className='leading-5 text-gray-500'>Representation Type:</p>
                <p className='leading-5'>
                  {capitalizeEnum(
                    transactionBuilder?.agentsInfo?.representationType!,
                  )}
                </p>
              </div>
              {teamLeaders?.map((item) => {
                return (
                  <div className='px-3' key={item.id}>
                    <p className='leading-5 text-gray-500'>Team Leader:</p>
                    <p className='leading-5'>
                      {getFullName(agentById[item?.agentId!])}
                    </p>
                  </div>
                );
              })}
              {coAgents?.map((item) => {
                return (
                  <div className='px-3' key={item.id}>
                    <p className='leading-5 text-gray-500'>Co-agent:</p>
                    <p className='leading-5'>
                      {getFullName(agentById[item?.agentId!])}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <ZenButton
            label='Edit'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPencil} className='text-base' />
            }
            onClick={() => navigateTo(ZenCreateListingStepName.OWNER_STEP)}
            variant='primary-link'
          />
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-3xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-gray-outline'
              type='button'
              label='Cancel'
              onClick={() => setCancel(true)}
            />
            <ZenButton
              isFullWidth
              type='submit'
              variant='primary'
              label='Create Listing'
              onClick={() => setConfirm(true)}
            />
          </div>
        </div>
      </div>
      <ZenSimpleConfirmationModal
        isOpen={confirm}
        variant='info'
        cancelButtonText='No'
        hideIcon
        customIcon={<SaveFile fontSize={18} className='text-primary-blue' />}
        title='Create listing?'
        subtitle='You’ll be able to edit any information once this listing is created.'
        confirmButtonText='Yes'
        onConfirm={async () => {
          await onSubmit();
          setConfirm(false);
        }}
        onClose={() => setConfirm(false)}
        isSubmitting={isSubmitting}
      />
      <AgentWebsiteConfirmationModal
        variant='cancel'
        size='large'
        title='Delete listing?'
        subtitle='The information won’t be saved and you’ll go back to the listing list'
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          <ZenButton
            isFullWidth
            variant='secondary-gray-outline'
            type='button'
            label='No'
            onClick={() => setCancel(false)}
          />
          <ZenButton
            isFullWidth
            type='button'
            variant='danger'
            label='Delete'
            onClick={() => history.push('/listings')}
          />
        </div>
      </AgentWebsiteConfirmationModal>
    </div>
  );
};

export default withCreateListingProgress(ZenListingStepsReview);
