import { faCircleCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgentResponseAgentStatusEnum } from '../../openapi/yenta';
import { EnumMap } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';

import GeminiStatusPill, { PillVariantType } from './GeminiStatusPill';

export interface GeminiAgentStatusCellProps {
  status: AgentResponseAgentStatusEnum;
  showIcon?: boolean;
  pillContainerStyle?: string;
}

const statusToPillVariantMap: EnumMap<
  AgentResponseAgentStatusEnum,
  PillVariantType
> = {
  [AgentResponseAgentStatusEnum.Inactive]: 'danger',
  [AgentResponseAgentStatusEnum.Rejected]: 'danger',
  [AgentResponseAgentStatusEnum.Active]: 'success',
  [AgentResponseAgentStatusEnum.Candidate]: 'warning',
};

const statusToPillIconMap: EnumMap<
  AgentResponseAgentStatusEnum,
  React.ReactElement
> = {
  [AgentResponseAgentStatusEnum.Inactive]: (
    <FontAwesomeIcon icon={faXmark} className='text-sm' />
  ),
  [AgentResponseAgentStatusEnum.Rejected]: (
    <FontAwesomeIcon icon={faXmark} className='text-sm' />
  ),
  [AgentResponseAgentStatusEnum.Active]: (
    <FontAwesomeIcon icon={faCircleCheck} className='text-sm' />
  ),
  [AgentResponseAgentStatusEnum.Candidate]: (
    <FontAwesomeIcon icon={faCircleCheck} className='text-sm' />
  ),
};

const GeminiAgentStatusCell: React.FC<GeminiAgentStatusCellProps> = ({
  status,
  showIcon = false,
  pillContainerStyle,
}) => {
  return (
    <GeminiStatusPill
      text={capitalizeEnum(status)}
      variant={statusToPillVariantMap[status]}
      icon={showIcon ? statusToPillIconMap[status] : undefined}
      pillContainerStyle={pillContainerStyle}
    />
  );
};

export default GeminiAgentStatusCell;
