import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faTableColumns,
} from '@fortawesome/pro-regular-svg-icons';
import { EscrowResponse } from '../../../openapi/arrakis';
import { CardItem } from '../../../types';
import { displayFormattedAmountWithCurrency } from '../../../utils/CurrencyUtils';
import ZenTransactionSummaryCardWithItems from './TransactionSummaryCardWithItems';

interface ConfirmedTrustDepositsProps {
  trustDeposit: EscrowResponse | undefined;
  commission: EscrowResponse | undefined;
}
const ConfirmedTrustDepositsSummary: React.FC<ConfirmedTrustDepositsProps> = ({
  trustDeposit,
  commission,
}) => {
  let trustDepositCard: CardItem[] = [
    {
      name: 'Total Amount',
      value: displayFormattedAmountWithCurrency(trustDeposit?.amount),
    },
    {
      name: 'Due Date',
      value: trustDeposit?.requiredByDate,
    },
  ];

  trustDeposit?.deposits?.forEach((deposit, index) => {
    trustDepositCard.push({
      name: `Installment ${index + 1}`,
      value: displayFormattedAmountWithCurrency({
        amount: deposit?.amount?.amount || 0,
        currency: 'USD',
      }),
    });
    trustDepositCard.push({
      name: `Date Received`,
      value: deposit?.dateReceived || 'N/A',
    });
  });

  trustDepositCard.push({
    name: 'Held By',
    value: trustDeposit?.heldByReal ? 'Real Brokerage' : 'Other Brokerage',
  });

  let commissionDepositCard: CardItem[] = [];

  commission?.deposits?.forEach((deposit, index) => {
    commissionDepositCard.push({
      name: `Installment ${index + 1}`,
      value: displayFormattedAmountWithCurrency({
        amount: deposit?.amount?.amount || 0,
        currency: 'USD',
      }),
    });
    commissionDepositCard.push({
      name: `Date Received`,
      value: deposit?.dateReceived || 'N/A',
    });
    commissionDepositCard.push({
      name: `Amount`,
      value: displayFormattedAmountWithCurrency({
        amount: commission?.amount?.amount || 0,
        currency: 'USD',
      }),
    });
  });

  return (
    <div className='grid grid-cols-2 gap-4'>
      <ZenTransactionSummaryCardWithItems
        titleIconPlacement='left'
        titleAdjacentComponent={
          <FontAwesomeIcon icon={faDollarSign} className='mr-2 mb-1 h-3' />
        }
        title='Confirmed Trust Deposit'
        items={trustDepositCard}
        variant='regular'
        EmptyComponent={
          <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
            <div>
              <FontAwesomeIcon
                icon={faTableColumns}
                className='p-2'
                size='2xl'
              />
            </div>
            <div className='text-xs !font-zen-body !text-primary-dark !font-primary text-center'>
              There are no confirmed trust deposit to display.
            </div>
          </div>
        }
      />
      <ZenTransactionSummaryCardWithItems
        titleIconPlacement='left'
        titleAdjacentComponent={
          <FontAwesomeIcon icon={faDollarSign} className='mr-2 mb-1 h-3' />
        }
        title='Confirmed Commission Deposit'
        items={commissionDepositCard}
        variant='regular'
        EmptyComponent={
          <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
            <div>
              <FontAwesomeIcon
                icon={faTableColumns}
                className='p-2'
                size='2xl'
              />
            </div>
            <div className='text-xs !font-zen-body !text-primary-dark !font-primary text-center'>
              There are no confirmed commission deposit to display.
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ConfirmedTrustDepositsSummary;
