import { omit, values } from 'lodash';
import {
  GenericSearchResponseTransactionBuilderResponse,
  GenericSearchResponseTransactionResponse,
  PagedTransactionResponse,
  TransactionBuilderControllerApi,
  TransactionControllerApi,
  TransactionResponse,
} from '../../openapi/arrakis';
import { LifecycleGroupEnum, TransactionsSubTabType } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import {
  getTransactionStatusByLifecycleGroupEnum,
  isLifeCycleAndTabMatch,
} from '../../utils/TransactionUtils';
import { queryKeys } from '../base/queryKeys';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';

interface UseNonAgentTransactionsProps {
  fnArgs: Parameters<TransactionControllerApi['getTransactions']>;
  options?: Partial<QueryOptions<GenericSearchResponseTransactionResponse>>;
  activeTab?: TransactionsSubTabType;
}

interface UseDraftTransactionsByAgentIdProps {
  fnArgs: Parameters<
    TransactionBuilderControllerApi['getTransactionBuildersPaginated']
  >;
  options?: Partial<
    QueryOptions<GenericSearchResponseTransactionBuilderResponse>
  >;
}

interface UseTransactionsByAgentIdProps {
  isListing?: boolean;
  fnArgs: Parameters<
    TransactionControllerApi['getTransactionsByStateGroupPaginated']
  >;
  options?: Partial<QueryOptions<PagedTransactionResponse>>;
  activeTab?: TransactionsSubTabType;
}

interface UseAgentTransactionsCountProps {
  isListing: boolean;
  fnArgs: Parameters<
    TransactionControllerApi['getTransactionsByStateGroupPaginated']
  >;
  options?: Partial<
    QueryOptions<Partial<Record<keyof typeof LifecycleGroupEnum, number>>>
  >;
}

/**
 * Fetches transactions count by lifecycleGroup map (OPEN | CLOSED | TERMINATED)
 *
 * @param {UseNonAgentTransactionsProps} props
 * @returns {Partial<Record<keyof typeof LifecycleGroupEnum, number>>} listCountByLifeCycleGroup
 *
 * - for queryKey pass only filters params
 */
export const useFetchNonAgentTransactionsCount = ({
  options,
  fnArgs,
  activeTab,
}: UseNonAgentTransactionsProps) => {
  const NonDraftLifecycleGroupEnum = omit(LifecycleGroupEnum, 'DRAFT');

  const getAllCount = async () => {
    const promises = values(NonDraftLifecycleGroupEnum).map(async (value) => {
      const isActive = isLifeCycleAndTabMatch(value, activeTab!);
      const params = { ...fnArgs };
      params['29'] = isActive
        ? fnArgs[29]
        : getTransactionStatusByLifecycleGroupEnum(value);

      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransactions(
        ...(Object.values(params) as Parameters<
          TransactionControllerApi['getTransactions']
        >),
      );

      return { [value]: data.totalCount };
    });

    const results = await Promise.all(promises);
    return Object.assign({}, ...results);
  };

  const queryResult = useSimpleQuery<
    Partial<Record<keyof typeof NonDraftLifecycleGroupEnum, number>>
  >({
    queryKey: queryKeys.transaction.transactionsList(
      fnArgs.filter((_, i) => ![1, 2, 3, 4].includes(i)),
    ).queryKey,
    queryFn: () => getAllCount(),
    options: {
      logErrorMessage: 'Error retrieving transactions count',
      toastErrorMessage: 'Error retrieving transactions count',
      ...options,
    } as QueryOptions<
      Partial<Record<keyof typeof NonDraftLifecycleGroupEnum, number>>
    >,
  });

  return { isLoading: queryResult?.isLoading, data: queryResult?.data };
};

export const useFetchNonAgentTransactions = ({
  options,
  fnArgs,
}: UseNonAgentTransactionsProps) => {
  const getTransactions = async () => {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getTransactions(...fnArgs);

    return data;
  };

  const queryResult = useSimpleQuery<GenericSearchResponseTransactionResponse>({
    queryKey: queryKeys.transaction.transactionsList(fnArgs).queryKey,
    queryFn: () => getTransactions(),
    options: {
      toastErrorMessage: 'Error retrieving transactions',
      logErrorMessage: 'Error retrieving transactions',
      ...options,
    },
  });

  return {
    isLoading: queryResult?.isLoading,
    data: queryResult?.data,
    error: queryResult?.error,
  };
};

export const useFetchAgentNonDraftTransactions = ({
  options,
  fnArgs,
  isListing = false,
}: UseTransactionsByAgentIdProps) => {
  const method = isListing
    ? 'getListingTransactionsByStateGroupPaginated'
    : 'getTransactionsByStateGroupPaginated';
  const getTransactions = async () => {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    )[method](...fnArgs);
    return data;
  };

  const queryResult = useSimpleQuery<PagedTransactionResponse>({
    queryKey: queryKeys.transaction.transactionsList([...fnArgs, isListing])
      .queryKey,
    queryFn: () => getTransactions(),
    options: {
      toastErrorMessage: 'Error retrieving transactions',
      logErrorMessage: 'Error retrieving transactions',
      ...options,
    },
  });

  return {
    isLoading: queryResult?.isLoading,
    data: queryResult?.data,
    error: queryResult?.error,
  };
};

export const useFetchAgentDraftTransactions = ({
  fnArgs,
  options,
}: UseDraftTransactionsByAgentIdProps) => {
  const getDraftTransactions = async () => {
    const { data } = await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).getTransactionBuildersPaginated(...fnArgs);
    return data;
  };

  const queryResult = useSimpleQuery<GenericSearchResponseTransactionBuilderResponse>(
    {
      queryKey: queryKeys.transaction.transactionsList(fnArgs).queryKey,
      queryFn: () => getDraftTransactions(),
      options: {
        logErrorMessage: 'Error retrieving agent draft transactions',
        toastErrorMessage: 'Error retrieving agent draft transactions',
        ...options,
      },
    },
  );

  return {
    isLoading: queryResult?.isLoading,
    data: queryResult.data,
    error: queryResult.error,
    refetch: queryResult.refetch,
  };
};

export const useFetchAgentTransactionsCount = ({
  isListing,
  fnArgs,
  options,
}: UseAgentTransactionsCountProps) => {
  const NonDraftLifecycleGroupEnum = omit(LifecycleGroupEnum, ['DRAFT']);

  const getAllCount = async () => {
    const listCountByLifeCycleGroup: Partial<
      Record<keyof typeof LifecycleGroupEnum, number>
    > = {};

    values(NonDraftLifecycleGroupEnum).forEach(async (value) => {
      const method = isListing
        ? 'getListingTransactionsByStateGroupPaginated'
        : 'getTransactionsByStateGroupPaginated';

      const [yentaId, , ...rest] = fnArgs;

      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      )[method](yentaId, value as any, ...rest);

      listCountByLifeCycleGroup[value] = data.totalCount;
    });

    return listCountByLifeCycleGroup;
  };

  const [yentaId, , , , searchText, , , transactionType] = fnArgs;

  const queryResult = useSimpleQuery<
    Partial<Record<keyof typeof NonDraftLifecycleGroupEnum, number>>
  >({
    queryKey: queryKeys.transaction.transactionsList([
      isListing,
      yentaId,
      searchText,
      transactionType,
    ]).queryKey,
    queryFn: () => getAllCount(),
    options: {
      logErrorMessage: 'Error retrieving agent transactions count',
      toastErrorMessage: 'Error retrieving agent transactions count',
      ...options,
    },
  });

  return { isLoading: queryResult?.isLoading, data: queryResult?.data };
};

export const useFetchTransactionById = (
  transactionId: string,
  options?: QueryOptions<TransactionResponse>,
) => {
  return useSimpleQuery({
    queryKey: queryKeys.transaction.detail(transactionId).queryKey,
    queryFn: async () => {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransaction(transactionId);
      return data;
    },
    options: {
      enabled: !!transactionId,
      ...options,
    },
  });
};

export const useFetchTransactionSummaryPdf = (
  transactionId: string,
  options?: QueryOptions<File>,
) => {
  return useSimpleQuery({
    queryKey: queryKeys.transaction.summaryPdf(transactionId).queryKey,
    queryFn: async () => {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).downloadTransactionSummaryPDF(transactionId, {
        responseType: 'blob',
      });
      return data;
    },
    options: {
      enabled: !!transactionId,
      cacheTime: 0,
      staleTime: 0,
      ...options,
    },
  });
};
