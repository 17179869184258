import { faCircleInfo, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useAgreements } from '../../../hooks/useAgreement';
import { useSignAgreementAmendment } from '../../../hooks/useSignAgreementAmendment';
import { UserAgreementControllerApi } from '../../../openapi/yenta';
import { RootState, SignatureWidgetModeEnum } from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import Hover from '../../Hover';
import SignatureWidget from '../../SignatureWidget/SignatureWidget';

interface AgentAgreementSignatureFormModalProps {
  onClose(): void;
}

const AgentAgreementSignatureFormModal: React.FC<AgentAgreementSignatureFormModalProps> = ({
  onClose,
}) => {
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const { allLatestAgreementsNotSigned } = useSelector(
    (state: RootState) => state.agentDetail,
  );
  const { agreement, amendment, signedAgreement } = useAgreements();
  const { handleSign, isLoading } = useSignAgreementAmendment(userDetail?.id!);

  const [showMore, setShowMore] = useState<boolean>(false);

  const handleSignAgreement = useCallback(
    () =>
      handleSign(() =>
        new UserAgreementControllerApi(getYentaConfiguration()).signAgreement({
          userId: userDetail?.id!,
          agreementId: agreement?.agreementId!,
        }),
      ),
    [agreement, userDetail?.id, handleSign],
  );

  const handleSignAmendment = useCallback(
    () =>
      handleSign(() =>
        new UserAgreementControllerApi(
          getYentaConfiguration(),
        ).signAgreementAmendment(
          signedAgreement?.agreementId!,
          amendment?.agreementAmendmentId!,
        ),
      ),
    [signedAgreement, amendment, handleSign],
  );

  if (allLatestAgreementsNotSigned?.length === 0) {
    onClose();
    return null;
  }

  const renderModalContent = (doc: any, token: string, onSign: () => void) => (
    <div className='w-full lg:min-w-[65rem] relative shadow-zen-web bg-white rounded-2xl'>
      <div className='flex flex-row items-center bg-zen-light-blue rounded-t-2xl px-4 py-4'>
        <div className='flex-grow flex-col items-center justify-center'>
          <p className='font-zen-body font-semibold text-zen-dark-9 text-lg text-center'>
            We&apos;ve made enhancements to our {doc?.name}
          </p>
        </div>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className='text-zen-dark-5 text-2xl cursor-pointer'
          onClick={onClose}
        />
      </div>
      <div className='flex flex-row items-center bg-zen-light-gray-2 px-4 md:px-8 py-4'>
        <div className='flex flex-grow flex-col items-center justify-center'>
          <p className='font-zen-body font-normal text-zen-dark-9 text-base text-center flex flex-row items-center justify-center'>
            We kindly ask you to review and put your digital signature on the
            updated version.
            <Hover
              hoverComponent={
                <div className='text-zen-dark-9 font-semibold text-sm font-zen-body p-2'>
                  You can return to sign later by clicking the Sign button under
                  Contracts in your Dashboard.
                </div>
              }
              config={{
                trigger: 'hover',
                placement: isMobile ? 'top-end' : 'right',
              }}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                className='ml-2 text-zen-dark-9 cursor-pointer'
              />
            </Hover>
          </p>
          <p className='font-zen-body font-normal text-zen-dark-9 text-base text-center'>
            This ensures uninterrupted access to all the amazing features reZEN
            has to offer!
          </p>
          {!!showMore && (
            <p className='visible lg:hidden font-semibold'>
              Note: Starting{' '}
              {DateTime.fromMillis(doc?.requiredAt || 0).toFormat(
                'LLL dd yyyy',
              )}
              , signing the updated {doc?.name} will be mandatory to continue
              accessing your reZEN account.
            </p>
          )}
          <p className='hidden lg:block font-semibold'>
            Note: Starting{' '}
            {DateTime.fromMillis(doc?.requiredAt || 0).toFormat('LLL dd yyyy')},
            signing the updated {doc?.name} will be mandatory to continue
            accessing your reZEN account.
          </p>
          <div
            className='visible lg:hidden mt-1 cursor-pointer text-rezen-blue-600 font-semibold text-sm font-zen-body'
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show Less' : 'Show More'}
          </div>
        </div>
      </div>
      <div
        className='w-full flex flex-col bg-grey-100 h-[400px] lg:h-[550px] overflow-y-hidden rounded-b-2xl'
        aria-label='sign-ica'
      >
        <SignatureWidget
          token={token}
          mode={SignatureWidgetModeEnum.SIGN}
          onDocumentSigned={onSign}
          onDocumentAlreadySigned={onSign}
          showDownload
          showMobileTooltip
          showWebHelperButtons={isMobile}
          isLoading={isLoading}
        />
      </div>
    </div>
  );

  return agreement
    ? renderModalContent(
        agreement,
        agreement?.signeeToken!,
        handleSignAgreement,
      )
    : renderModalContent(
        amendment,
        amendment?.signeeToken!,
        handleSignAmendment,
      );
};

export default AgentAgreementSignatureFormModal;
