import React from 'react';
import { ContextItemStatusPill } from '../../../utils/LeoUtils';
import { Checkbox } from '../../commons/Checkbox';
import { ContextItem } from './NeoLeoContextItems';

type NeoLeoContextItemProps = {
  item: ContextItem;
  onItemSelect: (id: string) => void;
};

const NeoLeoContextItem: React.FC<NeoLeoContextItemProps> = ({
  item,
  onItemSelect,
}) => {
  return (
    <div
      className='border border-white min-h-[148px] rounded-md p-2'
      key={item.id}
    >
      <div className='flex justify-between items-start'>
        <div>
          <Checkbox onChange={() => onItemSelect(item.id)} />
        </div>
        <p className='text-sm font-semibold line-clamp-2 mr-2'>{item.title}</p>
        <div className='flex items-center justify-center h-6 w-7 px-1 rounded-full bg-green-100'>
          <ContextItemStatusPill status={item.status} />
        </div>
      </div>
      <div className='mt-2.5'>
        <div className='flex justify-between gap-1'>
          <div className='w-full'>
            <p className='text-xs text-white text-opacity-70 font-medium mb-1'>
              {item.firstLabel}
            </p>
            <p className='text-xs text-white font-medium line-clamp-1'>
              {item.firstValue}
            </p>
          </div>
          <div className='w-full'>
            <p className='text-xs text-white text-opacity-70 font-medium mb-1'>
              {item.secondLabel}
            </p>
            <p className='text-xs text-white font-medium line-clamp-1'>
              {item.secondValue}
            </p>
          </div>
        </div>
        <div className='flex justify-between gap-1 mt-2.5'>
          <div className='w-full'>
            <p className='text-xs text-white text-opacity-70 font-medium mb-1'>
              {item.thirdLabel}
            </p>
            <p className='text-xs text-white font-medium line-clamp-1'>
              {item.thirdValue}
            </p>
          </div>
          <div className='w-full'>
            <p className='text-xs text-white text-opacity-70 font-medium mb-1'>
              {item.fourthLabel}
            </p>
            <p className='text-xs text-white font-medium line-clamp-1'>
              {item.fourthValue}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeoLeoContextItem;
