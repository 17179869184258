import { formatPhoneNumber } from '../../../utils/StringUtils';

interface PhoneNumberCellProps {
  phoneNumber: string | undefined;
}

const PhoneNumberCell: React.FC<PhoneNumberCellProps> = ({ phoneNumber }) => {
  return <span>{formatPhoneNumber(phoneNumber) || 'N/A'}</span>;
};

export default PhoneNumberCell;
