import React from 'react';
import { ReactComponent as ViewIcon } from '../../../assets/img/eye.svg';
import { useGemini } from '../../../hooks/useGemini';
import { cn } from '../../../utils/classUtils';
import IconButton from '../../IconButton';

interface ZenViewActionButtonCellProps {
  onClick?(): void;
  className?: string;
  iconClassName?: string;
}

const ZenViewActionButtonCell: React.FC<ZenViewActionButtonCellProps> = ({
  onClick,
  className,
  iconClassName,
}) => {
  const isGeminiEnabled = useGemini();
  return (
    <IconButton
      buttonStyle={cn(
        'border  rounded-lg',
        isGeminiEnabled
          ? 'border-rezen-blue-600 bg-rezen-blue-600'
          : 'border-primary-blue bg-primary-blue',
        className,
      )}
      variant='none'
      leftIcon={
        <ViewIcon
          className={cn('h-6 w-6 text-white', iconClassName)}
          aria-label='view'
          title='View'
        />
      }
      onClick={onClick}
    />
  );
};

export default ZenViewActionButtonCell;
