import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowDownToBracket,
  faFilePdf,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import {
  getSignatureApiConfiguration,
  getYentaConfiguration,
} from '../../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../../services/ErrorService';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import ZenCardWithItems from '../../Zen/ZenCardWithItems';
import ZenAction from '../../Zen/ZenAction';
import ResourceContainer from '../../ResourceContainer';
import { SigneeDocumentControllerApi } from '../../../openapi/signature-api';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { AsyncResponse, RootState } from '../../../types';
import { saveSignAgentAgreement } from '../../../slices/AgentSlice';
import {
  AgentResponse,
  AgreementResponse,
  ApplicationControllerApi,
  ApplicationResponse,
} from '../../../openapi/yenta';

interface ZenAgentContractCardV2Props {
  detailResponse: AsyncResponse<AgentResponse>;
}

interface AgreementData extends AgreementResponse, ApplicationResponse {}

const ZenAgentContractCardV2: React.FC<ZenAgentContractCardV2Props> = ({
  detailResponse,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { isAdmin, isBroker },
    agentDetail: { agentAgreementsResponse, agentNonSignedAgreementsResponse },
  } = useSelector((state: RootState) => state);
  const [agreements, setAgreements] = useState<AgreementData[]>([{}]);

  useEffect(() => {
    let agentAgreements: AgreementResponse[] = [];
    if (agentNonSignedAgreementsResponse && !isAdmin && !isBroker) {
      agentAgreements.push(agentNonSignedAgreementsResponse);
    }
    if (agentAgreementsResponse?.length) {
      agentAgreements = [...agentAgreements, ...agentAgreementsResponse];
    }
    const applications =
      detailResponse.data?.applications?.filter(
        (application) => !!application.icaSignedAt,
      ) || [];
    if (applications.length) {
      const applicationAgreements = applications
        .filter((ele) => !ele.icaAgreement?.signedAt)
        .map((application) => {
          return {
            ...application,
            signedAt: application.icaSignedAt,
            signeeToken: (null as unknown) as string,
            version: (null as unknown) as number,
          };
        });
      agentAgreements = [...agentAgreements, ...applicationAgreements];
    }

    setAgreements(agentAgreements);
  }, [
    agentAgreementsResponse,
    agentNonSignedAgreementsResponse,
    detailResponse.data?.applications,
    isAdmin,
    isBroker,
  ]);

  const handleNewVersionDownload = async (documentToken: string) => {
    try {
      const { data } = await new SigneeDocumentControllerApi(
        getSignatureApiConfiguration(),
      ).getSignedDocumentUrl(documentToken!);
      const url = data?.attachmentUrl!;

      if (url) {
        window.open(url, '_blank');
      } else {
        dispatch(
          showErrorToast(
            'Signed ICA document is being generated.',
            'Please try again after a few minutes.',
          ),
        );
      }
    } catch (e: any) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('unable to download signed ICA document', e, {
        data: { documentToken },
      });
    }
  };

  const handleOldVersionDownload = async (applicationId: string) => {
    try {
      const { data } = await new ApplicationControllerApi(
        getYentaConfiguration(),
      ).getICAUrl(applicationId);

      window.open(data, '_blank');
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreAuthErrors('unable to download ICA', e, {
        meta: { applicationId },
      });
    }
  };

  const handleSignDocument = async () => {
    dispatch(saveSignAgentAgreement(true));
  };

  return (
    <ResourceContainer
      loading={!agreements}
      isEmpty={!agreements.length}
      resourceName='agreement'
      emptyIcon={<FontAwesomeIcon icon={faFilePdf} size='2xl' />}
      emptyMessage='You do not have any documents.'
    >
      <ZenCardWithItems
        items={(agreements || [])?.map((application) => {
          return {
            name: `ICA${
              application?.version ? ` (v${application?.version})` : ''
            }`,
            value: '',
            rightActionComponent: !application?.signedAt ? (
              <ZenAction
                text='Sign Now'
                onClick={() => {
                  handleSignDocument();
                }}
              />
            ) : (
              <div className='flex flex-row items-center'>
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-green-600 mr-2'
                />
                <p className='font-zen-body text-zen-dark-9 font-semibold'>
                  {DateTime.fromMillis(application?.signedAt!).toFormat(
                    'LL/dd/yyyy',
                  )}
                </p>
                <ZenAction
                  text=''
                  onClick={() => {
                    if (application.signeeToken) {
                      handleNewVersionDownload(application?.signeeToken!);
                    } else {
                      handleOldVersionDownload(application.id!);
                    }
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faArrowDownToBracket}
                      data-testid='download-ica-contract'
                      className='text-primary-blue ml-2'
                    />
                  }
                />
              </div>
            ),
          };
        })}
        title='Contract'
      />
    </ResourceContainer>
  );
};

export default ZenAgentContractCardV2;
