import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../../../services/AnalyticsService';
import { chatReply, openLeo, saveShowIntro } from '../../../../slices/LeoSlice';
import { AnalyticsEventEnum, RootState } from '../../../../types';
import { ContextualPrompt, FollowUp } from '../../../../utils/LeoUtils';
import useShowLeoTrigger from '../useShowLeoTrigger';
import Prompt, { PrompSizeVariantType } from './Prompt';

interface LeoChatPromptsProps {
  promptSuggestions?: ContextualPrompt[];
  isIntro?: boolean;
  isSimba?: boolean;
  promptSizeVariant?: PrompSizeVariantType;
  contextType?: string | null;
  onSelect?(): void;
}

const LeoChatPrompts: React.FC<LeoChatPromptsProps> = ({
  promptSuggestions,
  isIntro = false,
  isSimba = false,
  promptSizeVariant = 'sm',
  contextType,
  onSelect,
}) => {
  const dispatch = useDispatch();
  const {
    leo: { session, isLeoThinking, currentChat, selectedItems },
  } = useSelector((state: RootState) => state);
  const {
    simbaFollowUpItem,
    agentId,
    prompts: defaultPrompts,
  } = useShowLeoTrigger();

  const getFollowUps = (handlerType: string | null): FollowUp[] | undefined => {
    // for prompts that come from leo chat response
    if (selectedItems.length) {
      return [
        {
          type: contextType || '',
          items: selectedItems,
        },
      ];
    } else if (!!handlerType && handlerType !== 'none') {
      // for default prompts i.e. simba
      return [
        {
          type: handlerType,
          items: simbaFollowUpItem ? [simbaFollowUpItem] : [],
        },
      ];
    }
    return undefined;
  };

  const onPromptSelect = async (
    selectedPrompt: ContextualPrompt,
    handlerType: string | null = null,
  ) => {
    if (onSelect) {
      onSelect();
    }
    dispatch(saveShowIntro(false));
    const updateSessionTitle =
      currentChat.length === 0 ||
      (currentChat.length === 1 &&
        currentChat[0].message === session?.disclaimer);

    await dispatch(
      chatReply(
        session?.id!,
        selectedPrompt,
        updateSessionTitle,
        getFollowUps(handlerType),
      ),
    );
    AnalyticsService.instance().logEvent(AnalyticsEventEnum.LEO_PROMPT_CLICK, {
      prompt,
    });
  };

  const prompts = promptSuggestions || defaultPrompts;
  const PromptWrapThreshold = 5;

  if (isSimba) {
    return (
      <div
        aria-label='leo-simba-prompts'
        className='overflow-x-auto scrollbar-hide flex flex-row justify-start mx-4 py-0.5'
      >
        {prompts.map((prompt) => (
          <Prompt
            key={prompt.prompt}
            prompt={prompt.title}
            promptSizeVariant='xs'
            onSelect={() =>
              dispatch(openLeo(agentId!, prompt, getFollowUps(prompt.category)))
            }
            isNudge
          />
        ))}
      </div>
    );
  }

  if (isIntro) {
    return (
      <div className='justify-center flex flex-row flex-wrap'>
        {prompts.map((prompt) => (
          <Prompt
            key={prompt.prompt}
            prompt={prompt.title}
            promptSizeVariant={promptSizeVariant}
            disabled={!session?.id || isLeoThinking}
            onSelect={() => onPromptSelect(prompt, prompt.category)}
          />
        ))}
      </div>
    );
  }

  return (
    <div className='max-h-20 overflow-hidden overflow-x-auto scrollbar-hide'>
      <div className='flex flex-row w-max'>
        {prompts.slice(0, PromptWrapThreshold).map((prompt) => (
          <Prompt
            key={prompt.prompt}
            prompt={prompt.title}
            promptSizeVariant={promptSizeVariant}
            disabled={!session?.id || isLeoThinking}
            onSelect={() => onPromptSelect(prompt, prompt.category)}
          />
        ))}
      </div>
      {prompts.length > 5 && <div className='basis-full' />}
      <div className='flex flex-row w-max'>
        {prompts.slice(PromptWrapThreshold, 10).map((prompt) => (
          <Prompt
            key={prompt.title}
            prompt={prompt.prompt}
            promptSizeVariant={promptSizeVariant}
            disabled={!session?.id || isLeoThinking}
            onSelect={() => onPromptSelect(prompt, prompt.category)}
          />
        ))}
      </div>
    </div>
  );
};

export default LeoChatPrompts;
