import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import ErrorService from '../../services/ErrorService';
import { DEFAULT_CACHE_TIME, DEFAULT_STALE_TIME } from './config';

export interface QueryOptions<TData> extends UseQueryOptions<TData, Error> {
  logErrorMessage?: string;
  metadata?: any;
  showToastOnSuccess?: boolean;
  skipLogError?: boolean;
  skipShowToastOnError?: boolean;
  toastErrorMessage?: string;
  toastSuccessMessage?: string;
}

/**
 * Custom hook for performing asynchronous queries with error handling and optional toast notifications.
 * This hook extends the useQuery hook from react-query, allowing for the fetching of data and handling of
 * states such as loading, error, and success with additional options for toasts and logging.
 *
 * @template TData The type of data expected from the query function, defaults to `unknown`.
 * @param {Object} params Parameters for configuring the query.
 * @param {QueryKey} params.queryKey Unique key(s) for the query to manage cache and re-fetching.
 * @param {() => Promise<TData>} params.queryFn A function returning a promise that resolves to the data.
 * @param {QueryOptions<TData>} [params.options] Optional configurations for the query, including react-query options and custom behaviors like toast notifications and error logging.
 *
 * @returns The query object returned by useQuery, including properties like `data`, `isLoading`, `error`, and functions like `refetch`.
 *
 * ### QueryOptions
 * Extends UseQueryOptions from react-query with additional properties:
 * - skipShowToastOnError: Defaults to false, always show toast on error unless overridden.
 * - showToastOnSuccess: Whether to show a toast notification on query success.
 * - toastErrorMessage: Custom error message for the toast notification.
 * - toastSuccessMessage: Custom success message for the toast notification.
 * - skipLogError: Defaults to false, always log error unless overridden.
 * - logErrorMessage: Custom message to log alongside the error.
 * - metadata: Additional metadata to log with the error.
 *
 * Usage: See [useSimpleQuery](src/query/useSimpleQuery.md)
 *
 */
export const useSimpleQuery = <TData = unknown>({
  queryKey,
  queryFn,
  options,
}: {
  queryKey: QueryKey;
  queryFn: () => Promise<TData>;
  options: QueryOptions<TData>;
}) => {
  const dispatch = useDispatch();

  return useQuery<TData, Error>({
    queryKey,
    queryFn,
    // select: (data) => data?.data as TData,
    onSuccess: () => {
      if (options.showToastOnSuccess) {
        dispatch(showSuccessToast(options.toastSuccessMessage!));
      }
    },
    onError: (error) => {
      if (!options.skipShowToastOnError) {
        dispatch(
          showErrorToast(options.toastErrorMessage ?? 'Error fetching data.'),
        );
      }
      if (!options.skipLogError) {
        ErrorService.notify(
          options.logErrorMessage ?? 'Error fetching data.',
          error,
          options.metadata,
        );
      }
    },
    cacheTime: DEFAULT_CACHE_TIME,
    staleTime: DEFAULT_STALE_TIME,
    ...options,
  });
};
