import { faCalendar, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatePickerInput,
  DatePickerInputProps as MDatePickerInputProps,
  DatesRangeValue,
  DateValue,
} from '@mantine/dates';
import { useState } from 'react';
import { DatePickerType } from '@mantine/dates/lib/types';
import { cn } from '../../utils/classUtils';
import {
  applyImportant,
  combineDefaultClassnamesWithCustomClassnames,
} from './utils';

export type DatePickerProps<Type extends DatePickerType = 'default'> = Omit<
  MDatePickerInputProps<Type>,
  'size'
> & {
  icon?: IconDefinition;
  showIcon?: boolean;
};

const defaultStyles: MDatePickerInputProps['classNames'] = {
  day: cn(
    'font-inter font-light text-[13px] text-center w-9 h-9',
    'data-[selected=true]:!bg-rezen-blue-600 data-[selected=true]:hover:!bg-rezen-blue-600/80',
  ),
  weekday: 'font-inter font-light text-[13px] text-primary-dark w-9 h-9',
  calendarHeaderLevel: 'font-inter text-sm font-normal text-primary-dark',
  error: 'text-red-600 font-inter text-sm',
  icon: 'relative flex items-center ml-1.5 mr-6',
  input: cn(
    'w-full border-none font-inter font-light text-base leading-6',
    'disabled:text-grey-500 disabled:bg-grey-100',
    'focus:!border-primary-dark',
    'data-[invalid]:!border-red-600 data-[dates-input]:py-2.5',
  ),
  pickerControl: 'font-inter font-light text-sm text-primary-dark',
  wrapper: 'flex',
} as const;

export const DatePicker = <Type extends DatePickerType = 'default'>({
  valueFormat = 'ddd, MMM DD',
  icon = faCalendar,
  weekendDays = [],
  showIcon = true,
  ...props
}: DatePickerProps<Type>) => {
  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[] | null
  >(null);
  return (
    <DatePickerInput<Type>
      firstDayOfWeek={0}
      placeholder={props.placeholder || 'Select date'}
      {...props}
      onChange={(date) => {
        setSelectedDate(date);
        props.onChange?.(date);
      }}
      icon={
        showIcon ? (
          <FontAwesomeIcon
            icon={icon}
            className={cn('text-primary-blue', props.classNames?.icon, {
              'text-regent-400': props.disabled,
              'text-red-600': !!props.error,
            })}
          />
        ) : undefined
      }
      labelProps={{
        className:
          '!mb-1 !font-inter !font-light !text-[15px] !leading-6 !text-primary-dark',
      }}
      classNames={applyImportant(
        combineDefaultClassnamesWithCustomClassnames(
          defaultStyles,
          {
            input: cn(
              showIcon ? 'px-10' : 'px-4',
              selectedDate ? 'text-primary-dark' : 'text-grey-500',
            ),
          },
          props.classNames,
        ),
      )}
      weekendDays={weekendDays}
    />
  );
};
