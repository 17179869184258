import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
  DefinedConditionPairEventTypeEnum,
  TriggerDefinitionDto,
} from '../../../openapi/sherlock';
import { RootState } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';

interface TriggerSubscriptionConditionsProps {
  trigger: TriggerDefinitionDto;
}

const TriggerSubscriptionConditions: React.FC<TriggerSubscriptionConditionsProps> = ({
  trigger,
}) => {
  const {
    checkListDefinition: { checklistDefinitionById, itemDefinitionById },
  } = useSelector((state: RootState) => state);

  return (
    <div className='w-full'>
      {trigger?.conditionSets?.definedConditionPairs!.map((item, idx) => {
        return (
          <div key={String(item?.eventSubscription?.event) + String(idx)}>
            {idx !== 0 && (
              <div className='my-5 text-center font-zen-body font-bold text-base text-primary'>
                OR
              </div>
            )}

            <div className='w-full px-6 py-5 flex flex-col justify-center items-center border rounded-lg border-primary font-zen-body'>
              {item?.eventType === DefinedConditionPairEventTypeEnum.System && (
                <div className='w-full flex flex-wrap sm:flex-nowrap'>
                  <div className='w-full text-xs font-semibold'>
                    <p className='mb-1 sm:mb-3 text-zen-dark-7 text-center'>
                      Event
                    </p>
                    <p className='text-zen-dark-9 mb-6 sm:mb-0 text-center'>
                      {capitalizeEnum(item?.eventSubscription?.event!)}
                    </p>
                  </div>

                  {item?.eventSubscription?.checklistDefinitionId! && (
                    <div className='w-full text-xs font-semibold text-center'>
                      <p className='mb-1 sm:mb-3 text-zen-dark-7'>
                        Checklist Definition
                      </p>
                      <p className='text-zen-dark-9 mb-6 sm:mb-0'>
                        {
                          checklistDefinitionById[
                            item?.eventSubscription?.checklistDefinitionId!
                          ]?.data?.name
                        }
                      </p>
                    </div>
                  )}

                  {item?.eventSubscription?.itemDefinitionId! && (
                    <div className='w-full text-xs font-semibold text-center'>
                      <p className='mb-1 sm:mb-3 text-zen-dark-7'>
                        Item Definition
                      </p>
                      <p className='text-zen-dark-9 mb-6 sm:mb-0'>
                        {
                          itemDefinitionById[
                            item?.eventSubscription?.itemDefinitionId!
                          ]?.data?.name
                        }
                      </p>
                    </div>
                  )}
                </div>
              )}

              {item?.eventType === DefinedConditionPairEventTypeEnum.System && (
                <div className='flex items-center justify-center my-10 w-full h-[1px] bg-zen-dark-5'>
                  <div className='w-7 h-7 shrink-0 mr-2 flex justify-center items-center rounded-full border border-zen-dark-10 bg-white overflow-hidden'>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size='lg'
                      className='text-zen-dark-5'
                    />
                  </div>
                </div>
              )}

              <div className='w-full flex flex-col justify-center items-center text-xs font-semibold'>
                <p className='mb-1 text-zen-dark-7'>Condition</p>
                <p className='text-zen-dark-9'>
                  {capitalizeEnum(item?.conditionName!) || 'NA'}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TriggerSubscriptionConditions;
