import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import WalletLandingPage from '../components/Wallet/WalletLandingPage';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenResourceContainer from '../components/Zen/ZenResourceContainer';
import ZenRoute from '../components/Zen/ZenRoute';
import {
  BankAccountDtoTypeEnum,
  BankingPartnershipDtoPartnerEnum,
} from '../openapi/wallet';
import { useWallet } from '../query/wallet/useWallet';

interface WalletRouteProps {}

const WalletRoute: React.FC<WalletRouteProps> = () => {
  const history = useHistory();
  const { data: wallet, isLoading, errorCode } = useWallet();

  const isPartneredWithUnit = useMemo(() => {
    return wallet?.bankingPartnerships?.some(
      (banks) =>
        banks.partner === BankingPartnershipDtoPartnerEnum.Unit &&
        banks.accounts?.some((account) => !isEmpty(account.cards)),
    );
  }, [wallet]);

  useEffect(() => {
    if (isPartneredWithUnit) {
      const debitAccount = wallet?.bankingPartnerships
        ?.flatMap((partner) => partner.accounts || [])
        .find((acc) => acc?.id && acc?.type === BankAccountDtoTypeEnum.Debit);

      if (debitAccount) {
        history.push(`/wallet/${debitAccount.id}`);
      }
    }
  }, [wallet, isPartneredWithUnit, history]);

  return (
    <ZenRoute title='Wallet'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          {
            title: 'Real Wallet',
            url: '/wallet',
          },
        ]}
        textClassNames='font-inter'
      >
        <ZenResourceContainer
          loading={isLoading}
          isEmpty={!!isPartneredWithUnit}
          resourceName='wallet'
          errorCode={errorCode}
        >
          <WalletLandingPage />
        </ZenResourceContainer>
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default WalletRoute;
