import { EnumMap, FeatureFlagRow, FeatureFlagTypeEnum } from '../types';

export const FEATURE_FLAG_CONFIG: EnumMap<
  FeatureFlagTypeEnum,
  FeatureFlagRow
> = {
  [FeatureFlagTypeEnum.EXAMPLE_BASIC_FEATURE]: {
    name: 'Basic Feature',
    subtitle: 'This enables basic feature',
  },
  [FeatureFlagTypeEnum.EXAMPLE_ADMIN_FEATURE]: {
    name: 'Admin Feature',
    subtitle: 'This enables admin features',
  },
  [FeatureFlagTypeEnum.INSTANT_PAYMENTS_TOGGLE]: {
    name: 'Instant Payments Toggle',
    subtitle:
      'This enables the eligible for instant payments toggle in transactions',
  },
  [FeatureFlagTypeEnum.LOGIN_AS_AGENT]: {
    name: 'Login As Agent through Agent Details',
    subtitle: 'This enables login as agent through agent details',
  },
  [FeatureFlagTypeEnum.UNDO_TRANSACTION_CLOSING]: {
    name: 'Undo Closing Transaction',
    subtitle: 'Enables undo the closing transaction',
  },
  [FeatureFlagTypeEnum.REVENUE_SHARE_REPORT]: {
    name: 'Revenue Share Report',
    subtitle: 'Enables downloading revenue share report',
  },
  [FeatureFlagTypeEnum.APPROVE_ZERO_COMMISSION_DEAL]: {
    name: 'Approve zero commission deal',
    subtitle: 'Enable zero commission deal for transaction',
  },
  [FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING]: {
    name: 'MS Dynamics Onboarding',
    subtitle: 'Enable Microsoft Dynamics onboarding',
  },
  [FeatureFlagTypeEnum.REAL_TITLE]: {
    name: 'Real Title',
    subtitle: 'Enables Real Title features',
  },
  [FeatureFlagTypeEnum.COPY_EVENT_LINK]: {
    name: 'Copy Event Link',
    subtitle: 'Enables Copy Event Link feature',
  },
  [FeatureFlagTypeEnum.DOUBLE_ENDER]: {
    name: 'Double Ender',
    subtitle: 'Enables the Double Ender Feature',
  },
  [FeatureFlagTypeEnum.WEBHOOKS]: {
    name: 'Webhooks',
    subtitle: 'Enables Webhooks Feature',
  },
  [FeatureFlagTypeEnum.WALLET]: {
    name: 'Wallet',
    subtitle: 'Enables Wallet Feature',
  },
  [FeatureFlagTypeEnum.ARCHIVE_CHECKLIST_DROPBOX_FILES]: {
    name: 'Archive Checklist Dropbox Files',
    subtitle: 'Enables Archiving Dropbox Files Feature',
  },
  [FeatureFlagTypeEnum.INTERNAL_REFERRAL]: {
    name: 'Internal Referral',
    subtitle: 'Enables Internal Referral Feature',
  },
  [FeatureFlagTypeEnum.LEO_QUESTION_MANAGER]: {
    name: 'Leo Question Manager',
    subtitle: 'Enables Leo Question Manager',
  },
  [FeatureFlagTypeEnum.SELF_SERVICE_TEAM]: {
    name: 'Self Service Team',
    subtitle: 'Enables Self Service Team Feature',
  },
  [FeatureFlagTypeEnum.GEMINI_REDESIGN]: {
    name: 'Gemini Redesign',
    subtitle: 'Enables Gemini Redesign',
  },
  [FeatureFlagTypeEnum.ROAR]: {
    name: 'Real Office Assisted Routing',
    subtitle: 'Enables Real Office Assisted Routing Feature',
  },
  [FeatureFlagTypeEnum.REAL_TITLE_PAGE_IMPROVEMENTS]: {
    name: 'Real Title Page Improvements',
    subtitle: 'Enables Real Title Page Improvements',
  },
  [FeatureFlagTypeEnum.CHECKLIST_DYNAMICS_PROPERTY_TYPES]: {
    name: 'Checklist Dynamics Property Types',
    subtitle: 'Enables Checklist Dynamics Property Types',
  },
  [FeatureFlagTypeEnum.REFERRAL_CENTRAL]: {
    name: 'Referral Central',
    subtitle: 'Enables Referral Central Feature',
  },
  [FeatureFlagTypeEnum.NEW_POWER_AUDIT_IMPROVEMENTS]: {
    name: 'New Power Audit Improvements',
    subtitle: 'Enables New Power Audit Improvements',
  },
  [FeatureFlagTypeEnum.TEMPLATE_COMMENTS]: {
    name: 'Template Comments',
    subtitle: 'Enables Template Comments Feature',
  },
  [FeatureFlagTypeEnum.AGENT_TASK_MANAGEMENT]: {
    name: 'Agent Task Management',
    subtitle: 'Enables Agent Task Management',
  },
  [FeatureFlagTypeEnum.ROAR_VOICE_MEMO]: {
    name: 'ROAR Voice Memo',
    subtitle: 'Enables voice memo for ROAR',
  },
  [FeatureFlagTypeEnum.BOARD_AND_MLS_IMPROVEMENTS]: {
    name: 'Board and MLS Improvements',
    subtitle: 'Enables Board and MLS Improvements',
  },
  [FeatureFlagTypeEnum.ROAR_DELETE_RECORDING_BOLT]: {
    name: 'ROAR Delete Recording',
    subtitle: 'Enables deletion of recording for ROAR',
  },
  [FeatureFlagTypeEnum.ICA_AGREEMENTS]: {
    name: 'ICA Agreements',
    subtitle: 'Enables ICA Agreements',
  },
  [FeatureFlagTypeEnum.PAYOUT_EXPLANATION]: {
    name: 'Payout Explanation',
    subtitle: 'Enables the payout explanation for transaction participants',
  },
  [FeatureFlagTypeEnum.NEO_LEO]: {
    name: 'Neo Leo',
    subtitle: 'Enables new leo design',
  },
};
