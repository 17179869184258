import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DEFAULT_CHAT_PROMPTS } from '../../../constants/LeoConstants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { PromptLibraryRequest } from '../../../openapi/leo';
import { FeatureFlagTypeEnum, RootState } from '../../../types';
import { ContextualPrompt, FollowUpItem } from '../../../utils/LeoUtils';
import { UUID_REGEX } from '../../../utils/StringUtils';
import { useLeoTrigger } from '../../../query/leo/useLeo';

interface useShowLeoTriggerReturn {
  agentId?: string;
  isAgent: boolean;
  showLeoTrigger: boolean;
  showNeoLeoPanel: boolean;
  simbaFollowUpItem: FollowUpItem | undefined;
  prompts: ContextualPrompt[];
}

const useShowLeoTrigger = (): useShowLeoTriggerReturn => {
  const location = useLocation();
  const {
    auth: { userDetail, isSuperAdmin, isRealTitleOfficer, isAdmin, isBroker },
    agentDetail: {
      detailResponse: { data },
    },
  } = useSelector((state: RootState) => state);
  const isNeoLeoEnabled = useFeatureFlag(FeatureFlagTypeEnum.NEO_LEO);

  const isAgent = useMemo(
    () => !isSuperAdmin && !isAdmin && !isBroker && !isRealTitleOfficer,
    [isAdmin, isBroker, isSuperAdmin, isRealTitleOfficer],
  );
  const isTransactionDetailRouteRegex = new RegExp(
    `/transactions/${UUID_REGEX}`,
  );
  const isTransactionDetailRoute = isTransactionDetailRouteRegex.test(
    location.pathname,
  );
  const transactionId = isTransactionDetailRoute
    ? location.pathname.split('/')[2]
    : '';
  const isProfileRouteRegex = new RegExp(`/people/${UUID_REGEX}`);
  const isProfileRoute = isProfileRouteRegex.test(location.pathname);

  const agentData = useMemo(() => (isAgent ? userDetail : data), [
    data,
    isAgent,
    userDetail,
  ]);
  const showLeoTrigger = useMemo(
    () => !isNeoLeoEnabled && (isAgent || (isAdmin && isProfileRoute)),
    [isAdmin, isAgent, isNeoLeoEnabled, isProfileRoute],
  );
  const showNeoLeoPanel = useMemo(
    () => isNeoLeoEnabled && (isAgent || (isAdmin && isProfileRoute)),
    [isAdmin, isAgent, isNeoLeoEnabled, isProfileRoute],
  );

  const promptReq: PromptLibraryRequest = useMemo(
    () => ({
      source: location.pathname.slice(1),
      limit: 10,
      offset: 0,
    }),
    [location.pathname],
  );

  const simbaFollowUpItem = useMemo(() => {
    if (transactionId) {
      return { id: transactionId! };
    }
    return undefined;
  }, [transactionId]);

  const { data: prompts = DEFAULT_CHAT_PROMPTS } = useLeoTrigger(promptReq, {
    enabled: showLeoTrigger || showNeoLeoPanel,
  });

  return {
    agentId: agentData?.id,
    isAgent,
    showLeoTrigger,
    showNeoLeoPanel,
    simbaFollowUpItem,
    prompts,
  };
};

export default useShowLeoTrigger;
