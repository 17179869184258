import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import { ContextualPrompt } from '../../utils/LeoUtils';
import NeoLeoChatInput from './Chat/NeoLeoChatInput';
import NeoLeoChatPrompts from './NeoLeoChatPrompts';

interface NeoLeoChatFooterProps {
  isIntro?: boolean;
}

const NeoLeoChatFooter: React.FC<NeoLeoChatFooterProps> = ({
  isIntro = false,
}) => {
  const { msgContext, selectedItems } = useSelector(
    (state: RootState) => state.leo,
  );
  const [showPrompts, setShowPrompts] = useState<boolean>(false);
  const [promptSuggestions, setPromptSuggestions] = useState<
    ContextualPrompt[]
  >();
  const showingPrompts = !isIntro && showPrompts;

  useEffect(() => {
    const prompts = msgContext?.details[0]?.prompts;
    const singleSelectionPrompts =
      !!prompts && !!prompts?.single_selection
        ? prompts?.single_selection?.filter((el) => !!el)
        : [];
    const multiSelectionPrompts =
      !!prompts && !!prompts?.multi_selection
        ? prompts?.multi_selection?.filter((el) => !!el)!
        : [];
    const responseContext = msgContext?.details[0]?.type || '';
    if (selectedItems.length > 1 && multiSelectionPrompts.length) {
      setPromptSuggestions(
        multiSelectionPrompts.map((p) => ({
          prompt: p!,
          category: responseContext,
          title: p!,
        })),
      );
      return;
    }
    if (selectedItems.length === 1 && singleSelectionPrompts.length) {
      setPromptSuggestions(
        singleSelectionPrompts.map((p) => ({
          prompt: p!,
          category: responseContext,
          title: p!,
        })),
      );
      return;
    }
    setPromptSuggestions(undefined);
  }, [msgContext, selectedItems.length]);

  return (
    <div
      className={cn(
        'bg-regent-900 px-3 pb-3 rounded-b-2xl pt-2',
        showingPrompts ? 'pt-2' : 'pt-0',
      )}
    >
      {showingPrompts && (
        <NeoLeoChatPrompts
          onSelect={() => setShowPrompts(false)}
          promptSuggestions={promptSuggestions}
          contextType={msgContext?.details[0]?.type}
        />
      )}
      <NeoLeoChatInput
        closePrompts={() => setShowPrompts(false)}
        isIntro={isIntro}
        showPrompts={showPrompts}
        setShowPrompts={setShowPrompts}
      />
    </div>
  );
};

export default NeoLeoChatFooter;
