import { faEnvelope, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '../../assets/img/deleteIconDanger.svg';
import { AgentInfo } from '../../openapi/yenta';
import { getFullName } from '../../utils/AgentHelper';
import ZenConfirmationModal from '../Zen/Modal/ZenConfirmationModal';
import ZenIconCircleWithTooltipCell from '../Zen/Transaction/ZenIconCircleWithTooltipCell';
import ZenAvatar from '../Zen/ZenAvatar';
import ZenIconText from '../Zen/ZenIconText';
import ZenUserPill from '../Zen/ZenUserPill';

interface ZenExperimentAgentsTableProps {
  agents: AgentInfo[];
  onDeleteActionClick(addOverrideAgentsIds: AgentInfo | undefined): void;
}

const ZenExperimentAgentsTable: React.FC<ZenExperimentAgentsTableProps> = ({
  agents,
  onDeleteActionClick,
}) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [removeAgent, setRemoveAgent] = useState<AgentInfo | undefined>();

  // TODO: We should use something like ZenFixedDataTable here, or make it into a shared component.
  return (
    <div className='p-4 md:overflow-hidden scrollbar overflow-x-scroll'>
      <table
        className='w-full break-inside-avoid font-zen-body text-sm text-zen-dark-9 md:table hidden'
        data-testid='medium-screen'
      >
        <thead>
          <tr className='border-b'>
            <th className='font-semibold p-2 text-left'>Name</th>
            <th className='font-semibold p-2 text-left'>Email Address</th>
            <th className='font-semibold p-2 text-left'>Actions</th>
          </tr>
        </thead>
        {agents.map((agent) => (
          <tbody key={agent?.id}>
            <tr className='font-zen-body text-sm text-zen-dark-9'>
              <td className='p-2 text-left'>
                <Link to={`/people/${agent?.id}`}>
                  <ZenUserPill
                    name={`${agent?.firstName} ${agent?.lastName}`}
                    imageUrl={agent?.avatar}
                    backgroundVariant='background'
                  />
                </Link>
              </td>
              <td className='p-2 text-left'>
                <Link to={`/people/${agent?.id}`}>{agent?.emailAddress}</Link>
              </td>
              <td className='p-2 text-left'>
                <ZenIconCircleWithTooltipCell
                  tooltipText='Delete'
                  icon={<img src={DeleteIcon} alt='delete' width={14} />}
                  onClick={() => {
                    setRemoveAgent(agent);
                  }}
                />
              </td>
            </tr>
          </tbody>
        ))}
      </table>
      <div className='md:hidden block' data-testid='small-screen'>
        {agents.map((agent) => (
          <div
            key={agent?.id}
            className='flex flex-row space-x-2 py-3 border-b border-gray-300'
          >
            <div>
              <Link to={`/people/${agent?.id}`}>
                <ZenAvatar
                  name={`${agent?.firstName} ${agent?.lastName}`}
                  size='md'
                  imageUrl={agent?.avatar!}
                />
              </Link>
            </div>
            <div className='flex flex-col space-y-1'>
              <p className='font-zen-body font-semibold text-sm'>{`${agent?.firstName} ${agent?.lastName}`}</p>
              <ZenIconText
                text={agent?.emailAddress || 'N/A'}
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                variant='small'
              />
            </div>
          </div>
        ))}
      </div>
      <ZenConfirmationModal
        variant='danger'
        subtitle={
          <span className='text-zen-dark-9'>
            Are you sure you would like to remove {getFullName(removeAgent)}{' '}
            from the experiment?
          </span>
        }
        title='Delete Override Agent'
        onClose={() => {
          setRemoveAgent(undefined);
        }}
        onConfirm={async () => {
          setIsDeleting(true);
          await onDeleteActionClick(removeAgent);
          setRemoveAgent(undefined);
          setIsDeleting(false);
        }}
        isOpen={!!removeAgent}
        isSubmitting={isDeleting}
        confirmButtonText='Delete'
        confirmButtonLeftIcon={
          <FontAwesomeIcon icon={faTrashCan} className='mr-1' />
        }
        size='default'
      />
    </div>
  );
};

export default ZenExperimentAgentsTable;
