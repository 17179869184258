import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '../../commons/Skeleton';

interface NeoLeoResponseLoadingSkeletonProps {
  className?: string;
}

export const NeoLeoResponseLoadingSkeleton: React.FC<NeoLeoResponseLoadingSkeletonProps> = ({
  className,
}) => {
  return (
    <div
      className={`w-[293px] flex flex-row rounded-t-2xl rounded-br-2xl p-3 bg-leo-chat-bubble ${className}`}
    >
      <div className='flex flex-row w-5 mr-2.5'>
        <FontAwesomeIcon icon={faSparkles} className='text-white' />
      </div>
      <div className='flex-grow flex flex-col rounded-t-2xl rounded-br-2xl gap-[7px]'>
        <div className='h-[14px] rounded-3xl'>
          <Skeleton
            height={12}
            className='!rounded-3xl !border-none before:!bg-leo-chat-skeleton-before after:!bg-leo-chat-skeleton-after'
          />
        </div>
        <div className='h-[14px] rounded-3xl'>
          <Skeleton
            height={12}
            className='!rounded-3xl !border-none before:!bg-leo-chat-skeleton-before after:!bg-leo-chat-skeleton-after'
          />
        </div>
        <div className='h-[14px] rounded-3xl'>
          <Skeleton
            height={12}
            className='!rounded-3xl !border-none before:!bg-leo-chat-skeleton-before after:!bg-leo-chat-skeleton-after'
          />
        </div>
        <div className='h-[14px] rounded-3xl'>
          <Skeleton
            height={12}
            className='!rounded-3xl !border-none before:!bg-leo-chat-skeleton-before after:!bg-leo-chat-skeleton-after'
          />
        </div>
      </div>
    </div>
  );
};
