import { useRegisterActions } from 'kbar';
import useDefaultActions from './useDefaultActions';
import useLeoActions from './useLeoActions';
import useListingActions from './useListingActions';
import useSearchTransactionsListingsActions from './useSearchTransactionsListingsActions';
import useThemeActions from './useThemeActions';
import useTransactionActions from './useTransactionActions';

export default function useActions() {
  const { defaultKBarActions } = useDefaultActions();
  const { transactionKBarActions } = useTransactionActions();
  const { listingKBarActions } = useListingActions();
  const searchResultsKBarActions = useSearchTransactionsListingsActions();
  const leoKBarActions = useLeoActions();
  const { themeKBarActions } = useThemeActions();

  let actions = [
    ...defaultKBarActions,
    ...transactionKBarActions,
    ...listingKBarActions,
    ...searchResultsKBarActions,
    ...leoKBarActions,
    ...themeKBarActions,
  ];

  useRegisterActions(actions, [actions]);
}
