import {
  faDollarSign,
  faTableColumns,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from 'react-table';
import {
  AttachedFeeValue,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { getParticipantName } from '../../../utils/ParticipantHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { getTransactionParticipantById } from '../../../utils/TransactionHelper';
import ZenFixedDataTable from '../../Zen/Table/ZenFixedDataTable';
import ZenTransactionSummaryCard from './ZenTransactionSummaryCard';

interface AdditionalFeesAndRebatesComponentProps {
  attachedFees: AttachedFeeValue[] | undefined;
  transaction: TransactionResponse;
}
const AdditionalFeesAndRebatesComponentSummary: React.FC<AdditionalFeesAndRebatesComponentProps> = ({
  attachedFees = [],
  transaction,
}) => {
  const getName = (value?: string) =>
    getParticipantName(getTransactionParticipantById(transaction, value || ''));

  const columns: Array<Column<AttachedFeeValue>> = [
    {
      Header: 'Participant',
      accessor: 'counterpartyId',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      Cell: ({ value }) => (
        <p className='word-break font-zen-body text-xs text-zen-dark-8 font-normal'>
          {getName(value)}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      Cell: ({ value }) => (
        <p className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {displayAmount(value)}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Fee Type',
      accessor: 'feeType',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      Cell: ({ value }) => (
        <p className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {capitalizeEnum(value ?? '')}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      Cell: ({ value }) => (
        <p className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {value}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return (
    <div>
      <ZenTransactionSummaryCard
        titleIconPlacement='left'
        titleAdjacentComponent={
          <FontAwesomeIcon icon={faDollarSign} className='mr-2 mb-1 h-3' />
        }
        title='Additional Fees & Rebates'
      >
        <ZenFixedDataTable<AttachedFeeValue>
          columns={columns}
          headerVariant='light'
          data={attachedFees || []}
          resourceName='Additional Fees & Rebates'
          hidePagination
          hidePageSize
          hideFilters
          pageSize={attachedFees.length}
          customEmptyComponent={
            <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
              <div>
                <FontAwesomeIcon
                  icon={faTableColumns}
                  className='p-2'
                  size='2xl'
                />
              </div>
              <div className='text-xs !font-zen-body !text-primary-dark !font-primary text-center'>
                There are no additional fees & rebates to display.
              </div>
            </div>
          }
        />
      </ZenTransactionSummaryCard>
    </div>
  );
};

export default AdditionalFeesAndRebatesComponentSummary;
