import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const SortableItem: React.FC<{ id: string | number }> = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export type DnDInputProps = {
  label?: string;
  name?: string;
  value: string[];
  onChange(value: string[]): void;
  formatValue?(value: string): string;
};

const DnDInput: React.FC<DnDInputProps> = ({
  label,
  value,
  name,
  onChange,
  formatValue,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = value.indexOf(active?.id as string);
      const newIndex = value.indexOf(over?.id as string);
      onChange(arrayMove(value, oldIndex, newIndex));
    }
  };

  return (
    <div className='space-y-1'>
      {!!label && (
        <label
          className='inline-block font-inter font-light text-[15px] text-primary-dark leading-6'
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input name={name} className='hidden' value={value} readOnly />
      <div className='space-y-3'>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          collisionDetection={closestCenter}
        >
          <SortableContext items={value} strategy={verticalListSortingStrategy}>
            {value.map((item) => (
              <SortableItem key={item} id={item}>
                <div
                  aria-label='sortable-item'
                  className='font-zen-body bg-white flex rounded-md gap-2 text-primary-dark p-2 border border-grey-300 items-center'
                >
                  <FontAwesomeIcon
                    icon={faGripDotsVertical}
                    className='mb-0.5'
                  />
                  <p>{formatValue?.(item) ?? item}</p>
                </div>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default DnDInput;
