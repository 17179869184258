import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BrokerQueueMenu from '../components/Menu/BrokerQueue/BrokerQueueMenu';
import NeoMainMenu from '../components/NeoMainMenu';
import useShowLeoTrigger from '../components/Zen/Leo/useShowLeoTrigger';
import ZenMainMenu from '../components/ZenMainMenu';
import UseBrokerQueue from '../hooks/useBrokerQueue';
import { ActivityTrackerControllerApi } from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import MenuService from '../services/MenuService';
import { setIsCollapsed } from '../slices/MainMenuSlice';
import { AppDispatch, RootState } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';

interface MainSidebarProps {}

const MainSidebar: React.FC<MainSidebarProps> = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { isCollapsed } = useSelector((state: RootState) => state.mainMenu);
  const { isBrokerQueueActive } = UseBrokerQueue();
  const { showNeoLeoPanel } = useShowLeoTrigger();

  useEffect(() => {
    // This will prevent the background from being scrolled through the menu on mobile
    document.body.style.overflow = isCollapsed ? 'auto' : 'hidden';
  }, [isCollapsed]);

  // hide sidebar on mobile of the location path changes
  useEffect(() => {
    if (isSmScreen()) {
      dispatch(setIsCollapsed(true));
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    const isMenuCollapsed = MenuService.isMenuCollapsed();
    dispatch(setIsCollapsed(isMenuCollapsed));
  }, [dispatch]);

  const toggleCollapse = (value: boolean) => {
    dispatch(setIsCollapsed(value));
  };

  //call the activity tracker API
  const fetchActivityTracker = useCallback(async () => {
    try {
      await new ActivityTrackerControllerApi(
        getYentaConfiguration(),
      ).recordActivity();
    } catch (err) {
      ErrorService.notifyIgnoreAuthErrors(
        'Error while fetching activity tracker API',
        err,
      );
    }
  }, []);

  useEffect(() => {
    fetchActivityTracker();
  }, [fetchActivityTracker]);

  if (isBrokerQueueActive) {
    return <BrokerQueueMenu />;
  }

  if (showNeoLeoPanel) {
    return <NeoMainMenu />;
  }

  return (
    <ZenMainMenu isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
  );
};

export default MainSidebar;
