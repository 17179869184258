import {
  faArrowRotateLeft,
  faCloudArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import {
  ChecklistApi,
  DocumentDto,
  ItemDtoRequiredForEnum,
  ItemDtoStatusEnum,
  ItemResponse,
  VersionDto,
} from '../../../openapi/sherlock';
import ErrorService from '../../../services/ErrorService';
import {
  downloadChecklistDocument,
  fetchChecklistItemById,
  updateChecklistItem,
} from '../../../slices/CheckListSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import { Mapping, RootState } from '../../../types';
import { getSherlockConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { MAX_FILE_VALIDATIONS } from '../../../utils/Validations';
import DefaultLoader from '../../DefaultLoader';
import ZenDocumentDateCell from '../../dropbox/cell/ZenDocumentDateCell';
import ResourceContainer from '../../ResourceContainer';
import ZenControlledFileUploadInput from '../Input/ZenControlledFileUploadInput';
import ZenControlledTextAreaInput from '../Input/ZenControlledTextAreaInput';
import ZenControlledTextInput from '../Input/ZenControlledTextInput';
import ZenButton from '../ZenButton';
import ZenFixedDataTable from '../ZenFixedDataTable';
import ZenSidebarModal from '../ZenSidebarModal';

interface ZenCheckListDocumentVersionsProps {
  isOpen: boolean;
  onClose(): void;
  index: number;
  checklistDocument: DocumentDto;
  checklistItem: ItemResponse;
  checklistId: string;
}

interface FormData {
  name: string;
  description: string;
  docFile: any;
}

const ZenCheckListDocumentVersions: React.FC<ZenCheckListDocumentVersionsProps> = ({
  isOpen,
  onClose,
  index,
  checklistDocument,
  checklistItem,
  checklistId,
}) => {
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    transaction: {
      transactionDetailResponse: { data: transactionDetail },
    },
  } = useSelector((state: RootState) => state);
  const [isDownloadingDocument, setIsDownloadingDocument] = useState<
    Mapping<boolean>
  >({});

  const columns: Array<Column<VersionDto>> = [
    {
      Header: 'Name / Description',
      accessor: 'name',
      Cell: ({ value, row: { original } }) => (
        <div className='flex'>
          <div className='mt-1'>
            <div className='border border-zen-dark-5 rounded p-1'>
              <FontAwesomeIcon icon={faFile} className='text-zen-dark-5' />
            </div>
          </div>
          <div className='inline-block ml-2'>
            <div className='text-sm font-zen-body font-normal text-zen-dark-9 flex items-start gap-2'>
              {value}
            </div>
            <div className='text-xs text-zen-dark-6 leading-tight pt-1 contents'>
              {original.description}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: 'Version',
      accessor: 'number',
      Cell: ({ value }) => (
        <div className='flex items-center md:justify-center gap-1'>
          <FontAwesomeIcon icon={faArrowRotateLeft} />
          {value}
        </div>
      ),
    },
    {
      Header: 'Uploaded Date',
      accessor: 'createdAt',
      Cell: ({ value }) => <ZenDocumentDateCell date={value!} />,
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ value }) => (
        <ResourceContainer
          loading={isDownloadingDocument[value!]!}
          isEmpty={false}
          resourceName='download-item'
          LoaderComponent={
            <div className='pr-3'>
              <DefaultLoader noPadding iconSize='small' />
            </div>
          }
        >
          <button
            onClick={() => handleDownload(value!)}
            className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              className='text-base text-zen-dark-8'
            />
          </button>
        </ResourceContainer>
      ),
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      name: checklistDocument.name,
      description: checklistDocument.description,
    },
  });

  const handleDownload = async (versionId: string) => {
    setIsDownloadingDocument((map) => ({ ...map, [versionId!]: true }));
    await dispatch(downloadChecklistDocument(versionId));
    setIsDownloadingDocument((map) => ({ ...map, [versionId!]: false }));
  };

  const onSubmit = async (data: FormData) => {
    try {
      if (data.docFile) {
        await new ChecklistApi(getSherlockConfiguration()).uploadNewVersion(
          checklistDocument?.id!,
          data.name,
          data.description,
          userDetail?.id,
          data.docFile[0],
          transactionDetail?.id,
        );
      } else {
        await new ChecklistApi(getSherlockConfiguration()).updateDocumentMeta(
          checklistDocument?.id!,
          {
            name: data.name,
            description: data.description,
          },
        );
      }

      await dispatch(
        updateChecklistItem(
          checklistId,
          checklistItem.id!,
          {
            item: {
              ...checklistItem,
              name: checklistItem.name!,
              status: ItemDtoStatusEnum.Pending,
              requiredFor: (checklistItem.requiredFor as unknown) as ItemDtoRequiredForEnum,
            },
          },
          false,
        ),
      );
      await dispatch(fetchChecklistItemById(checklistId, checklistItem.id!));
      onClose();
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Cannot edit checklist document', e, {
        checklistDocument: { ...checklistDocument },
      });
    }
  };

  return (
    <ZenSidebarModal
      title={`Document: ${index + 1}. ${checklistDocument.name}`}
      subtitle={`Version ${
        checklistDocument.currentVersion?.number
      } | Uploaded ${
        checklistDocument.currentVersion?.createdAt
          ? DateTime.fromMillis(
              checklistDocument.currentVersion?.createdAt,
            ).toLocaleString(DateTime.DATETIME_SHORT)
          : 'N/A'
      }`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='p-4 space-y-5'>
        <div className='flex flex-col h-full space-y-5 pb-20'>
          <div className='flex-grow space-y-5'>
            <div>
              <ZenControlledTextInput<FormData, 'name'>
                control={control}
                label='Document Name'
                name='name'
                placeholder='Ex. Tax form 1099'
                rules={{ required: 'Please enter file name.' }}
                defaultValue={checklistDocument.name}
              />
            </div>
            <div>
              <div className='pt-1'>
                <ZenControlledTextAreaInput<FormData, 'description'>
                  control={control}
                  placeholder='Description'
                  name='description'
                  rows={3}
                  label='Description'
                  defaultValue={checklistDocument.description}
                />
              </div>
            </div>
            <div>
              <div className='pt-1'>
                <ZenControlledFileUploadInput<FormData, 'docFile'>
                  name='docFile'
                  control={control}
                  label='Replace with a new version'
                  placeholder='.pdf, doc, docx, xls, xlsx'
                  rules={{
                    ...MAX_FILE_VALIDATIONS,
                  }}
                />
              </div>
            </div>
          </div>
          <div className='mt-5 space-y-1'>
            <div className='text-base font-zen-body font-semibold text-zen-dark-9'>
              Previous Versions
            </div>
            <ZenFixedDataTable<VersionDto>
              key={checklistDocument.versions?.length}
              columns={columns}
              resourceName='Previous Versions'
              data={checklistDocument.versions}
              hideFilters
              hidePagination
            />
          </div>
          <div className='py-3 px-3 md:py-4 md:pr-3 md:pl-[40%] absolute w-full bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0 z-40'>
            <ZenButton
              type='button'
              onClick={onClose}
              label='Cancel'
              variant='primary-outline'
              isFullWidth
            />
            <ZenButton
              isSubmitting={isSubmitting}
              type='button'
              label='Save'
              isFullWidth
              onClick={handleSubmit(onSubmit)}
              isDisabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </ZenSidebarModal>
  );
};

export default ZenCheckListDocumentVersions;
