import { isArray } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuService from '../services/MenuService';
import SessionStorageService from '../services/SessionStorageService';
import { resetApp } from '../slices/actions/authActions';
import { verifyJWT } from '../slices/AuthSlice';
import { closeApiErrorModal } from '../slices/ErrorSlice';
import { AppDispatch, RootState } from '../types';
import { deleteAuthCookie, getAuthCookie } from '../utils/AuthUtils';
import {
  createSubErrorString,
  getErrorModalMessage,
  getErrorModalTitleForStatus,
} from '../utils/ErrorUtils';
import AdminOrCIOnly from './AdminOrCIOnly';
import Button from './Button';
import ConfirmationModal from './ConfirmationModal';
import CopyToClipboard from './CopyToClipboard';

interface ApiErrorModalProps {}

const ApiErrorModal: React.FC<ApiErrorModalProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isCheckingToken, setIsCheckingToken] = useState<boolean>(false);
  const {
    errorDetails: { visible, errorData },
    auth: { isAdmin, keymakerCurrentUser },
  } = useSelector((state: RootState) => state);
  const onClose = () => dispatch(closeApiErrorModal());

  const checkJWTValidity = useCallback(async () => {
    const jwtToken = getAuthCookie();

    if (!jwtToken) {
      return false;
    }

    const data = await dispatch(verifyJWT(jwtToken));

    return data?.id === keymakerCurrentUser?.id;
  }, [dispatch, keymakerCurrentUser?.id]);

  const authCheck = useCallback(async () => {
    setIsCheckingToken(true);

    const isValid = await checkJWTValidity();

    if (!isValid) {
      deleteAuthCookie();
      dispatch(resetApp());
      MenuService.clear();
      SessionStorageService.clear();
    }

    setIsCheckingToken(false);
  }, [checkJWTValidity, dispatch]);

  useEffect(() => {
    if (errorData?.status === 401 || errorData?.status === 403) {
      authCheck();
    }
  }, [authCheck, errorData?.status]);

  if (!visible || !errorData || isCheckingToken) {
    return null;
  }

  return (
    <ConfirmationModal
      title={getErrorModalTitleForStatus(isAdmin, errorData)}
      subtitle={getErrorModalMessage(isAdmin, errorData)}
      isOpen={visible}
      onClose={onClose}
      variant='error'
      size='large'
    >
      <AdminOrCIOnly>
        <div className='flex justify-between'>
          <p className='text-gray-500 font-primary-light'>Error</p>
          <CopyToClipboard
            value={JSON.stringify(errorData)}
            label='Copy Error Data'
          />
        </div>
        <div className='flex flex-col space-y-2'>
          <div>
            <p className='text-gray-500 font-primary-light'>Message</p>
            <p className='scrollbar overflow-y-scroll h-40 break-all'>
              {errorData.message || 'No Message'}
            </p>
          </div>
          {isArray(errorData.subErrors) && errorData.subErrors.length > 0 && (
            <div>
              <p className='text-gray-500 font-primary-light'>Sub Errors</p>
              <p>{createSubErrorString(errorData?.subErrors)}</p>
            </div>
          )}
          <div>
            <p className='text-gray-500 font-primary-light'>TimeStamp</p>
            <p>{errorData.timestamp}</p>
          </div>
          <div>
            <p className='text-gray-500 font-primary-light'>Trace</p>
            <p className='scrollbar overflow-y-scroll h-52 break-all'>
              {errorData.debugMessage}
            </p>
          </div>
        </div>
      </AdminOrCIOnly>
      <div className='mt-4 flex justify-end'>
        <Button label='Close' type='secondary' onClick={onClose} />
      </div>
    </ConfirmationModal>
  );
};

export default ApiErrorModal;
