import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import qs from 'qs';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightToBracket,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import ZenRoute from '../components/Zen/ZenRoute';
import useQueryParams from '../hooks/useQueryParams';
import { JwtAuthenticationResponse } from '../openapi/keymaker';
import { useGoogleSSO, useLoginWithGoogle } from '../query/login/useGoogleSSO';
import ErrorService from '../services/ErrorService';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import { AppDispatch, ErrorCode } from '../types';
import { setAuthCookie } from '../utils/AuthUtils';
import { getErrorMessage } from '../utils/ErrorUtils';
import { HookFormTextInput } from '../components/commons/hookFormInputs/HookFormTextInput';
import { HookFormPasswordInput } from '../components/commons/hookFormInputs/HookFormPasswordInput';
import { Button } from '../components/commons/Button';
import { Alert } from '../components/commons/Alert';
import { useLogin } from '../query/login/useLogin';

const loginFormSchema = z.object({
  usernameOrEmail: z
    .string({ message: 'Email or username required' })
    .trim()
    .min(1, { message: 'Email or username required' }),
  password: z
    .string({ message: 'Password required' })
    .trim()
    .min(1, { message: 'Password required' }),
});

type LoginForm = z.infer<typeof loginFormSchema>;

interface Query {
  redirectTo?: string;
  email?: string;
}

const ZenLoginRoute: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch<AppDispatch>();
  const { redirectTo, email } = useQueryParams<Query>();
  const [serverError, setServerError] = useState<string>();

  const { control, handleSubmit, clearErrors, watch } = useForm<LoginForm>({
    defaultValues: {
      usernameOrEmail: email || '',
      password: '',
    },
    resolver: zodResolver(loginFormSchema),
  });

  const usernameOrEmail = watch('usernameOrEmail');
  const [debouncedUsernameOrEmail] = useDebounceValue(usernameOrEmail, 500);
  const { data } = useGoogleSSO({
    fnArgs: [debouncedUsernameOrEmail],
  });
  const { mutate: loginWithGoogle } = useLoginWithGoogle();

  const handleLoginRedirect = async (data: JwtAuthenticationResponse) => {
    setAuthCookie(data.accessToken!);

    if (data?.forceMfa && !data?.mfaType) {
      history.push(`/enable-2fa?${qs.stringify({ redirectTo })}`);
    } else if (data?.mfaType) {
      history.push(`${match.path}/2fa?${qs.stringify({ redirectTo })}`);
    } else {
      await dispatch(fetchAuthUserDetail());
    }
  };

  const handleGoogleLoginSuccess = async (response: CredentialResponse) => {
    const { credential } = response;

    if (credential) {
      const req = { googleToken: credential };
      loginWithGoogle(req, {
        onSuccess: async (data) => {
          await handleLoginRedirect(data!);
        },
      });
    } else {
      ErrorService.notify(
        'Unable to login with Google SSO',
        new Error('Failed google login'),
        { response },
      );
    }
  };

  const { isLoading, mutate: loginMutation } = useLogin();

  const handleLogin = (login: LoginForm) => {
    loginMutation(login, {
      onSuccess: (data) => {
        setAuthCookie(data!.accessToken!);
        handleLoginRedirect(data!);
      },
      onError: (e: any) => {
        setServerError(
          e.response?.data?.errorMessage ??
            getErrorMessage(ErrorService.getErrorCode(e), 'login'),
        );
        const errorCode = ErrorService.getErrorCode(e);
        if (errorCode !== ErrorCode.UNAUTHORIZED) {
          ErrorService.notify('unable to login', e, { meta: {} });
        }
      },
    });
  };

  return (
    <ZenRoute title='Login'>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className='bg-gray-100'>
          <div className='container flex items-center justify-center h-screen mx-auto'>
            <div className='w-full max-w-md p-10 bg-white border-2 border-gray-100 lg:w-1/3 mx-4 md:mx-0'>
              <div className='mb-5 -mt-20 text-center'>
                <div className='inline-block p-10 text-primary-light rounded-full bg-primary-dark relative'>
                  <FontAwesomeIcon
                    icon={faArrowRightToBracket}
                    className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-1/3 w-1/3'
                  />
                </div>
              </div>
              <p className='text-3xl text-center font-inter'>Login</p>
              {serverError && (
                <div className='mt-3'>
                  <Alert
                    icon={<FontAwesomeIcon icon={faInfoCircle} />}
                    color='red'
                    title={serverError}
                  />
                </div>
              )}
              <div className='mt-5'>
                <HookFormTextInput
                  control={control}
                  name='usernameOrEmail'
                  label='Email / Username'
                  placeholder='Enter username or email'
                />
              </div>
              {!data?.forceGoogleSso && (
                <Fragment>
                  <div className='mt-5'>
                    <HookFormPasswordInput
                      control={control}
                      name='password'
                      label='Password'
                      placeholder='Password'
                    />
                  </div>
                  <div className='mt-5'>
                    <Button
                      fullWidth
                      loading={isLoading}
                      disabled={isLoading}
                      type='submit'
                      onClick={() => {
                        if (serverError) {
                          clearErrors();
                        }
                      }}
                    >
                      Login
                    </Button>
                  </div>
                </Fragment>
              )}

              {(data?.googleSsoEnabled || data?.forceGoogleSso) && (
                <div className='mt-5 flex justify-center w-full'>
                  <GoogleLogin
                    size='large'
                    onSuccess={handleGoogleLoginSuccess}
                    onError={() => {
                      setServerError('Unable to login with Google SSO');
                    }}
                  />
                </div>
              )}

              {!data?.forceGoogleSso && (
                <div className='flex justify-between'>
                  <Link to='/register' className='hover:underline'>
                    <p className='mt-3 text-sm text-right font-zen-body'>
                      Join Real
                    </p>
                  </Link>
                  <Link to='/reset-password' className='hover:underline'>
                    <p className='mt-3 text-sm text-right font-zen-body'>
                      Forgot Password?
                    </p>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </ZenRoute>
  );
};

export default ZenLoginRoute;
