import React, { useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAgreements } from '../../../hooks/useAgreement';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useSignAgreementAmendment } from '../../../hooks/useSignAgreementAmendment';
import { UserAgreementControllerApi } from '../../../openapi/yenta';
import { fetchAllLatestAgreementsNotSigned } from '../../../slices/AgentSlice';
import {
  FeatureFlagTypeEnum,
  RootState,
  SignatureWidgetModeEnum,
} from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import SignatureWidget from '../../SignatureWidget/SignatureWidget';
import LogoutButton from '../LogoutButton';
import AgreementHeader from './AgreementHeader';

const ApplicationAgreementsSigning: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { userDetail },
    agentDetail: { allLatestAgreementsNotSigned },
  } = useSelector((state: RootState) => state);

  const { agreement, amendment, signedAgreement } = useAgreements();
  const { handleSign, isLoading } = useSignAgreementAmendment(userDetail?.id!);

  const isIcaAgreementFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ICA_AGREEMENTS,
  );

  useEffect(() => {
    if (isIcaAgreementFeatureFlagEnabled) {
      dispatch(fetchAllLatestAgreementsNotSigned());
    }
  }, [dispatch, isIcaAgreementFeatureFlagEnabled]);

  const handleSignAgreementICA = useCallback(() => {
    handleSign(() =>
      new UserAgreementControllerApi(getYentaConfiguration()).signAgreement({
        userId: userDetail?.id!,
        agreementId: agreement?.agreementId!,
      }),
    );
  }, [agreement, userDetail?.id, handleSign]);

  const handleSignAmendment = useCallback(() => {
    handleSign(() =>
      new UserAgreementControllerApi(
        getYentaConfiguration(),
      ).signAgreementAmendment(
        signedAgreement?.agreementId!,
        amendment?.agreementAmendmentId!,
      ),
    );
  }, [signedAgreement, amendment, handleSign]);

  const renderSignatureWidget = (token: string, onSign: () => void) => (
    <div className='bg-gray-100 w-full h-[calc(100vh-266px)] lg:h-[calc(100vh-140px)] justify-center relative'>
      <SignatureWidget
        token={token}
        mode={SignatureWidgetModeEnum.SIGN}
        onDocumentSigned={onSign}
        onDocumentAlreadySigned={onSign}
        showDownload
        showMobileTooltip
        showWebHelperButtons={isMobile}
        isLoading={isLoading}
      />
    </div>
  );

  useEffect(() => {
    if (allLatestAgreementsNotSigned?.length === 0) {
      history.push('/');
    }
  }, [allLatestAgreementsNotSigned, history]);

  if (amendment) {
    return (
      <>
        <AgreementHeader unsignedDoc={amendment} />
        {renderSignatureWidget(amendment?.signeeToken!, handleSignAmendment)}
        <LogoutButton />
      </>
    );
  }

  return (
    <>
      <AgreementHeader unsignedDoc={agreement} />
      {renderSignatureWidget(agreement?.signeeToken!, handleSignAgreementICA)}
      <LogoutButton />
    </>
  );
};
export default ApplicationAgreementsSigning;
