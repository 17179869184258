import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import config from '../../../config';
import { fetchAgentAgreementDefinitionWithAmendmentsResponse } from '../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../types';
import DefaultLoader from '../../DefaultLoader';
import PageLayout from '../../PageLayout';
import ZenCopyToClipboard from '../ZenCopyToClipBoard';
import ZenRoute from '../ZenRoute';
import AgreementDetailsStatusButton from './AgreementDetailsStatusButton';

type Match = {
  id: string;
  amendmentId: string;
};

const ZenAmendmentsDetailsRoute = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id: agreementId, amendmentId } = useParams<Match>();
  const history = useHistory();

  const {
    agentDetail: {
      agentAgreementDefinitionWithAmendmentsResponse: agreementResponse,
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(fetchAgentAgreementDefinitionWithAmendmentsResponse(agreementId));
  }, [dispatch, agreementId, amendmentId]);

  const amendment = agreementResponse?.data?.amendmentDefinitions?.find(
    (amendment) => amendment.agreementDefinitionAmendmentId === amendmentId,
  );

  return (
    <ZenRoute title='Agreement Details'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Agreements', url: '/agreements' },
          {
            title: agreementResponse?.data?.name!,
            url: `/agreement/${agreementId}`,
          },
          {
            title: amendment?.name!,
            url: `/agreements/${agreementId}/amendment/${amendmentId}`,
          },
        ]}
      >
        <div className='px-8 lg:pt-5'>
          <div className='my-4'>
            <button
              onClick={() => history.push(`/agreement/${agreementId}`)}
              className='border-2 border-grey-300 flex items-center justify-center space-x-1 w-[164px] py-2 font-zen-body-2 font-medium rounded-lg'
            >
              <FontAwesomeIcon
                icon={faArrowLeftLong}
                className='text-primary-dark'
                size='sm'
              />
              <p>Go Back</p>
            </button>
          </div>
          {!agreementResponse.loading ? (
            <header
              title='header'
              className='w-full bg-primary-navy rounded-2xl md:px-8 px-4 py-5 flex md:flex-row md:justify-between flex-col md:space-x-8 font-zen-body-2 text-white'
            >
              <div className='space-y-3.5'>
                <div className='space-x-1 flex items-start'>
                  <h1 className='text-xl font-semibold text-white'>
                    {amendment?.name}
                  </h1>

                  <a
                    href={`${config.realSignatureBaseUrl}/view/document/${amendment?.documentTemplateId}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    data-testid='view-amendment'
                  >
                    <FiExternalLink
                      title='View Document'
                      className=' text-aqua text-2xl ml-1'
                    />
                  </a>
                </div>
                <AgreementDetailsStatusButton status={amendment?.state!} />
              </div>
              <div className='bg-regent-900 text-sm font-semibold rounded-lg px-4 pb-4 pt-2 space-y-1 font-zen-body-2'>
                <div className='flex items-center space-x-2'>
                  <p>
                    Document :{' '}
                    <span className='inline-block font-light'>
                      {amendment?.documentTemplateId}
                    </span>
                  </p>
                  <p className='inline-block font-light'>
                    <ZenCopyToClipboard
                      label='Copy'
                      value={amendment?.documentTemplateId!}
                      iconStyle='text-zen-blue-2'
                      variant='primaryTwo'
                    />
                  </p>
                </div>
                <div className='space-y-2.5'>
                  <div className=''>
                    Version:{' '}
                    <span className='inline-block font-light'>
                      {amendment?.agreementDefinitionAmendmentVersion}
                    </span>
                  </div>
                  <div className=''>
                    Required At:{' '}
                    <span className='inline-block font-light'>
                      {amendment?.requiredAt
                        ? DateTime.fromMillis(amendment?.requiredAt).toFormat(
                            'LL/dd/yyyy',
                          )
                        : 'NA'}
                    </span>
                  </div>
                </div>
              </div>
            </header>
          ) : (
            <DefaultLoader />
          )}
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default ZenAmendmentsDetailsRoute;
