import {
  Skeleton as MSkeleton,
  SkeletonProps as MSkeletonProps,
} from '@mantine/core';

export const Skeleton: React.FC<MSkeletonProps> = ({
  visible,
  radius,
  height,
  ...props
}) => {
  return (
    <MSkeleton height={height} visible={visible} radius={radius} {...props} />
  );
};
