import { useHistory } from 'react-router-dom';
import AccountDetails from '../../assets/img/wallet/landing-account-details.svg';
import CardActivity from '../../assets/img/wallet/landing-card-activity.svg';
import LandingDashboard from '../../assets/img/wallet/landing-dashboard.svg';
import DebitCard from '../../assets/img/wallet/landing-debit-card.svg';
import TotalWalletValue from '../../assets/img/wallet/landing-total-wallet-value.svg';
import ZenButton from '../Zen/ZenButton';

interface WalletLandingPageProps {}

const WalletLandingPage: React.FC<WalletLandingPageProps> = () => {
  const history = useHistory();

  return (
    <div className='bg-regent-100'>
      <div className='grid grid-cols-1 gap-y-16 max-w-[61.25rem] mx-auto px-4 py-10'>
        <div className='col-span-1'>
          <p className='font-poppins text-4xl leading-10 font-semibold text-primary-dark mb-4 text-center'>
            Real Wallet
          </p>
          <p className='font-inter text-lg font-light text-primary-dark mb-9 text-center'>
            With all your Real finances in one place, managing your money has
            never been easier.
          </p>
          <img src={LandingDashboard} alt='banner' className='mx-auto' />
        </div>

        {/* Section 02 */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 p-10 bg-rezen-blue-100 rounded-xl sm:place-items-center md:place-items-start'>
          <div className='max-w-[430px] flex flex-col items-start'>
            <img
              src={TotalWalletValue}
              alt='stock-value'
              className='max-h-64'
            />
            <p className='font-poppins font-medium text-lg text-primary-dark pt-6'>
              Unified Financial Insights
            </p>
            <p className='font-inter font-light text-base text-primary-dark pt-2'>
              Stay on top of your finances with ease. Real Wallet provides a
              unified view of your Real earnings and expenses, allowing you to
              manage your money effortlessly.
            </p>
          </div>
          <div className='max-w-[430px] flex flex-col items-start'>
            <img src={CardActivity} alt='card-activity' className='max-h-64' />
            <p className='font-poppins font-medium text-lg text-primary-dark pt-6'>
              Instant Access to Your Money
            </p>
            <p className='font-inter font-light text-base text-primary-dark pt-2'>
              Access your earnings instantly with transfers to your Real bank
              account. Say goodbye to waiting periods and enjoy your hard-earned
              money right when you need it.
            </p>
          </div>
        </div>

        {/* Section 3 */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 px-10 place-items-center'>
          <img
            src={DebitCard}
            alt='real-debit-card'
            className='md:order-2 order-1 mb-7'
          />
          <div className='max-w-[430px] flex flex-col items-start md:order-1 order-2'>
            <p className='font-poppins font-semibold text-2xl text-primary-dark mb-4'>
              Seamless Spending with Real Debit Card
            </p>
            <p className='font-inter font-light text-base text-primary-dark'>
              Love the feel of cold-hard cash? Use your Real Debit Card to
              withdraw funds whenever you need.
            </p>
          </div>
        </div>

        {/* Section 4 */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 px-10 pb-7 place-items-center'>
          <img src={AccountDetails} alt='real-charge-card' />
          <div className='max-w-[430px] flex flex-col items-start gap-4'>
            <p className='font-poppins font-semibold text-2xl text-primary-dark'>
              Hassle-Free Credit
            </p>
            <p className='font-inter font-light text-base text-primary-dark'>
              Upcoming open house? Launching a new ad? Payments have never been
              smoother than with the new Real Charge Card!
            </p>
            <p className='font-inter font-light text-base text-primary-dark'>
              Get all the benefits of a charge card minus the hassles of a
              credit score.
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className='fixed lg:sticky w-full bottom-0 z-0 bg-primary-light shadow-top-sm'>
        <div className='flex gap-8 py-5 justify-center px-5 md:px-0'>
          <ZenButton
            isFullWidth
            variant='secondary-light-outline'
            label='View Your Wallet'
            className='!font-inter font-medium text-xs sm:text-lg border-rezen-blue-600 w-60'
            isDisabled
          />
          <ZenButton
            isFullWidth
            label='Get Your Card Today!'
            className='!font-inter font-medium text-xs sm:text-lg bg-rezen-blue-600 border-rezen-blue-600 w-60'
            onClick={() => history.push('/wallet/offerings')}
          />
        </div>
      </div>
    </div>
  );
};

export default WalletLandingPage;
