import { useDispatch, useSelector } from 'react-redux';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import { LeoResponseMessageContext } from '../../../utils/LeoUtils';
import { getTransactionPriceLabel } from '../../../utils/TransactionHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { RootState } from '../../../types';
import { saveSelectedItems } from '../../../slices/LeoSlice';
import NeoLeoContextItem from './NeoLeoContextItem';

export type ContextItem = {
  showCheckbox: boolean;
  id: string;
  title: string;
  firstLabel: string;
  firstValue: string;
  secondLabel: string;
  secondValue: string;
  thirdLabel: string;
  thirdValue: string;
  fourthLabel: string;
  fourthValue: string;
  status: TransactionLifecycleStateValueStateEnum;
};

interface NeoLeoContextItemsProps {
  msgContext: LeoResponseMessageContext;
}

const NeoLeoContextItems: React.FC<NeoLeoContextItemsProps> = ({
  msgContext,
}) => {
  const dispatch = useDispatch();
  const { selectedItems } = useSelector((state: RootState) => state.leo);
  const msgContextType = msgContext.details?.[0]?.type ?? 'transaction';
  const context = msgContext.details?.[0];
  const showSelectionCheckbox = [
    'transaction_story',
    'transaction_wmm',
    'listings',
  ].includes(msgContextType!);
  // const showingCount = 1;
  // const [currentPage, setCurrentPage] = useState<number>(0);
  // const [showingCount, setShowingCount] = useState<number>(1);

  const getLifecycleState = (state: string | null | undefined) => {
    const isLifecycleStateValid =
      !!state &&
      Object.values(TransactionLifecycleStateValueStateEnum).includes(
        (state as any) as TransactionLifecycleStateValueStateEnum,
      );
    if (isLifecycleStateValid) {
      return (state as any) as TransactionLifecycleStateValueStateEnum;
    }
    return TransactionLifecycleStateValueStateEnum.New;
  };
  const contextItems = msgContext.details?.[0]?.items ?? [];
  const items: ContextItem[] = contextItems?.map((item) => ({
    id: item.id!,
    title: item.address!,
    firstLabel: 'TYPE',
    firstValue: capitalizeEnum(item.type!),
    secondLabel: msgContextType === 'listings' ? 'CLOSING DATE' : 'ESCROW DATE',
    secondValue: item.escrow_date ?? '',
    thirdLabel: 'COMMISSION',
    thirdValue: item.commission ?? '',
    fourthLabel: !!item.type
      ? getTransactionPriceLabel(
          (item.type.toUpperCase() as any) as TransactionResponseTransactionTypeEnum,
        )
      : 'Sale Price',
    fourthValue: item.price ?? '',
    status: getLifecycleState(item.lifecycleState?.state),
    showCheckbox: showSelectionCheckbox,
  }));

  // const pageSize = 1;
  // const totalCount = sharranVideos.length || 0;
  // const lastPage = Math.ceil(totalCount / pageSize);

  // const goToPage = (page: number) => {
  //   if (page >= 0 && page < lastPage) {
  //     setCurrentPage(page);
  //   }
  // };

  // const onPrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   e.preventDefault();
  //   if (currentPage > 0) goToPage(currentPage - 1);
  // };

  // const onNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   e.preventDefault();
  //   if (currentPage < lastPage - 1) goToPage(currentPage + 1);
  // };

  const onItemSelect = (id: string) => {
    const itemFoundInSelectedItems = selectedItems.find((el) => el.id === id);
    if (itemFoundInSelectedItems) {
      const filteredItems = selectedItems.filter((el) => el.id !== id);
      dispatch(saveSelectedItems(filteredItems));
    } else {
      dispatch(saveSelectedItems([...selectedItems, { id }]));
    }
  };

  return (
    <div className='text-white font-inter flex flex-col gap-3'>
      <p className='font-normal text-base'>{context.message}</p>
      {/* <div className='flex justify-between items-center'>
        <p className='font-medium text-sm'>
          Transactions ({showingCount}/{totalCount})
        </p>
        <div className='flex justify-between items-center gap-2'>
          <p className='text-sm font-normal'>
            (<span className='font-medium'>{showingCount}</span>/
            <span className='font-light'>{totalCount}</span>)
          </p>
          {totalCount > 1 && (
            <div className='flex justify-between items-center gap-2.5'>
              <button
                className={cn(
                  currentPage === 0 ? 'text-grey-900' : 'text-white',
                  'disabled:cursor-not-allowed w-6 h-6 flex justify-center items-center cursor-pointer',
                )}
                onClick={(e) => {
                  e.preventDefault();
                  onPrevious(e);
                }}
                disabled={currentPage === 0}
                aria-label='go-previous'
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                className={cn(
                  currentPage === lastPage - 1 ? 'text-grey-900' : 'text-white',
                  'disabled:cursor-not-allowed w-6 h-6 flex justify-center items-center cursor-pointer',
                )}
                disabled={currentPage === lastPage - 1}
                onClick={(e) => {
                  e.preventDefault();
                  onNext(e);
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}
        </div>
      </div> */}
      <div className='flex justify-between items-center'>
        <p className='font-medium text-sm'>Transactions</p>
      </div>
      {items.map((item) => (
        <NeoLeoContextItem
          key={item.id}
          item={item}
          onItemSelect={onItemSelect}
        />
      ))}
    </div>
  );
};

export default NeoLeoContextItems;
