import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { values } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChecklistDefinitionDto,
  ChecklistDefinitionDtoDealTypeEnum,
  ChecklistDefinitionDtoPropertyTypesEnum,
  ChecklistDefinitionDtoRepresenteeEnum,
  ChecklistDefinitionDtoTargetTypeEnum,
  ChecklistDefinitionDtoTypeEnum,
} from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { saveOfficeTemplate } from '../../../slices/ChecklistDefinitionSlice';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  ISelectOption,
  RootState,
} from '../../../types';
import { getValidChecklistTargetTypesForOffice } from '../../../utils/ChecklistTemplates/ChecklistTemplateHelper';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { fetchSearchableChecklistItems } from '../../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledMultiSelectInput from '../../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import ZenSimpleModal from '../../Zen/Modal/ZenSimpleModal';
import ZenButton from '../../Zen/ZenButton';

interface AddOfficeTemplateProps {
  isOpen: boolean;
  onClose(): void;
  officeId: string;
  checkList: ChecklistDefinitionDto | undefined;
}

interface FormData {
  checklistDefinitionId: ISelectOption;
  type: ISelectOption;
  targetType: ChecklistDefinitionDtoTargetTypeEnum;
  dealType: ISelectOption;
  representee: ISelectOption;
  propertyTypes: ISelectOption[];
}

const AddOfficeTemplate: React.FC<AddOfficeTemplateProps> = ({
  isOpen,
  onClose,
  officeId,
  checkList,
}) => {
  const {
    checkListDefinition: { checklistDefinitionById },
  } = useSelector((state: RootState) => state);

  const checklistDefinition = checklistDefinitionById[
    checkList?.checklistDefinitionId!
  ]?.data!;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      type: checkList?.type
        ? {
            label: capitalizeEnum(checkList?.type),
            value: checkList?.type,
          }
        : undefined,
      checklistDefinitionId: checkList?.checklistDefinitionId
        ? {
            label: checklistDefinition?.name,
            value: checkList?.checklistDefinitionId,
          }
        : undefined,
      dealType: checkList?.dealType
        ? {
            label: capitalizeEnum(checkList?.dealType),
            value: checkList?.dealType,
          }
        : undefined,
      propertyTypes: checkList?.propertyTypes
        ? checkList?.propertyTypes?.map((type) => ({
            label: capitalizeEnum(type),
            value: type,
          }))
        : undefined,
      representee: checkList?.representee
        ? {
            label: capitalizeEnum(checkList?.representee),
            value: checkList?.representee,
          }
        : undefined,
      targetType: checkList?.targetType,
    },
  });

  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async (formData: FormData) => {
    await dispatch(
      saveOfficeTemplate(officeId, {
        checklistDefinitionId: formData?.checklistDefinitionId?.value,
        type: formData.type?.value as ChecklistDefinitionDtoTypeEnum,
        dealType: formData?.dealType
          ?.value as ChecklistDefinitionDtoDealTypeEnum,
        representee: formData?.representee
          ?.value as ChecklistDefinitionDtoRepresenteeEnum,
        targetType: formData?.targetType,
        propertyTypes:
          (formData?.propertyTypes?.map(
            (type) => type?.value,
          ) as ChecklistDefinitionDtoPropertyTypesEnum[]) || [],
      }),
    );
    onClose();
  };

  return (
    <ZenSimpleModal
      isOpen={isOpen}
      onClose={onClose}
      title={checkList ? 'Edit Template' : 'Add Template'}
      size='extraLarge'
    >
      <form className='w-full p-8' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ZenControlledAsyncSelectInput<FormData, 'checklistDefinitionId'>
            name='checklistDefinitionId'
            control={control}
            label='Search Template'
            placeholder='Type checklist or road to success name'
            isRequired
            startAdornment={
              <div className='flex justify-center items-center px-2'>
                <FontAwesomeIcon
                  icon={faSearch}
                  className='text-primary-blue ml-1 mr-0.5'
                />
              </div>
            }
            fetchData={async (search, page) => {
              try {
                const { data } = await fetchSearchableChecklistItems({
                  page: page || 0,
                  pageSize: 20,
                  search,
                  sortBy: {
                    name: 'asc',
                  },
                });

                const options: AsyncSelectOptionReactElement[] = data?.map(
                  (template) => ({
                    value: template?.id!,
                    label: template?.name!,
                  }),
                );

                return options;
              } catch (e) {
                ErrorService.notify('Unable to search for the templates', e, {
                  search: {
                    term: search,
                    page,
                  },
                });
                dispatch(
                  showErrorToast(
                    'An unexpected error occurred.',
                    'We were unable to search templates. Please try again in a few moments or contact support.',
                  ),
                );
              }

              return [];
            }}
            rules={{
              required: 'Template is required',
              validate: (selected?: ISelectOption) =>
                !selected?.value ? 'Template is required' : undefined,
            }}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'type'>
            name='type'
            control={control}
            label='Is this a Road to Success or a Checklist?'
            placeholder='Select template type'
            isRequired
            options={[
              {
                label: capitalizeEnum(ChecklistDefinitionDtoTypeEnum.Checklist),
                value: ChecklistDefinitionDtoTypeEnum.Checklist,
              },
              {
                label: capitalizeEnum(ChecklistDefinitionDtoTypeEnum.Journey),
                value: ChecklistDefinitionDtoTypeEnum.Journey,
              },
            ]}
            rules={{ required: 'Template type is required' }}
          />
        </div>
        <div className='mt-5'>
          <ZenControlledRadioInput<FormData, 'targetType'>
            name='targetType'
            control={control}
            label='Contract Type'
            inlineOptions
            variant='outline'
            options={getValidChecklistTargetTypesForOffice().map((type) => ({
              label: capitalizeEnum(type),
              value: type,
            }))}
            rules={{ required: 'Contract type is required' }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'dealType'>
            name='dealType'
            control={control}
            label='Deal Type'
            placeholder='Select deal type'
            options={values(ChecklistDefinitionDtoDealTypeEnum).map((type) => ({
              label: capitalizeEnum(type),
              value: type,
            }))}
            rules={{ required: 'Deal type is required' }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledSelectInput<FormData, 'representee'>
            name='representee'
            control={control}
            label='Representation'
            placeholder='Select client'
            options={values(ChecklistDefinitionDtoRepresenteeEnum).map(
              (type) => ({
                label: capitalizeEnum(type),
                value: type,
              }),
            )}
            rules={{ required: 'Deal type is required' }}
            isRequired
          />
        </div>
        <div className='mt-5'>
          <ZenControlledMultiSelectInput<FormData, 'propertyTypes'>
            name='propertyTypes'
            control={control}
            label='Property Type'
            placeholder='Select property type'
            options={values(ChecklistDefinitionDtoPropertyTypesEnum).map(
              (type) => ({
                label: capitalizeEnum(type),
                value: type,
              }),
            )}
            menuPlacement='top'
          />
        </div>
        <div className='mt-10 mb-5'>
          <ZenButton
            label={
              checkList
                ? 'Update Template to This Office'
                : 'Add Template to This Office'
            }
            isFullWidth
            variant='primary'
            type='submit'
            isSubmitting={isSubmitting}
          />
        </div>
      </form>
    </ZenSimpleModal>
  );
};

export default AddOfficeTemplate;
