import { DEFAULT_CHAT_PROMPTS } from '../../constants/LeoConstants';
import { ChatControllerApi, PromptLibraryRequest } from '../../openapi/leo';
import { ContextualPrompt } from '../../utils/LeoUtils';
import { getLeoConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';

export const useLeoTrigger = (
  promptReq: PromptLibraryRequest,
  options?: QueryOptions<ContextualPrompt[]>,
) => {
  return useSimpleQuery({
    queryKey: queryKeys.leoPrompts.list(promptReq).queryKey,
    queryFn: async () => {
      const { data } = await new ChatControllerApi(
        await getLeoConfiguration(),
      ).getPromptSuggestionsChatPromptLibraryPost(promptReq);
      return data.items?.length ? data.items : DEFAULT_CHAT_PROMPTS;
    },
    options: {
      logErrorMessage: '[LEO] - Failed to fetch leo prompts.',
      toastErrorMessage: 'Failed to fetch leo prompts.',
      ...options,
    },
  });
};
