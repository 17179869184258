import { WarningRounded } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SvgIcon } from '@material-ui/core';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import { ReactComponent as CompliantIcon } from '../../../../assets/icons/compliant.svg';
import ZenButton from '../../ZenButton';
import { markAsCompliant } from '../../../../slices/TransactionSlice';
import { getTransactionStatusVariant } from '../../../Gemini/Transaction/Header/GeminiTransactionStatusInfo';
import { safeEnumMapGet } from '../../../../utils/EnumHelper';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';

interface ZenTransactionPowerAuditStatusInfoProps {
  title: string;
  description: string;
  transaction: TransactionResponse;
}

export type ZenTransactionHeaderVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'dark'
  | 'alert';

export const ZenTransactionStatusVariantTypeToClassNameMap: {
  [type in ZenTransactionHeaderVariantType]: string;
} = {
  default: 'bg-gray-200 text-dark',
  primary: 'bg-zen-light-blue text-zen-primary',
  success: 'bg-zen-success-light text-zen-success',
  warning: 'bg-zen-warning-1 text-zen-warning-dark',
  danger: 'bg-zen-danger-light text-zen-danger',
  dark: 'bg-zen-dark-9 text-white',
  alert: 'bg-zen-purple-light text-zen-purple-dark',
};

const ZenTransactionPowerAuditStatusInfo: React.FC<ZenTransactionPowerAuditStatusInfoProps> = ({
  title,
  description,
  transaction,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const setAsCompliant = async () => {
    setIsSubmitting(true);
    if (transaction?.id) {
      await dispatch(markAsCompliant(transaction.id));
    }
    if (transaction?.listingTransaction?.id) {
      await dispatch(markAsCompliant(transaction.listingTransaction.id, false));
    }
    if (transaction?.inContractTransactionId) {
      await dispatch(
        markAsCompliant(transaction.inContractTransactionId, false),
      );
    }
    setIsSubmitting(false);
    setOpen(false);
  };

  return (
    <div
      id='transaction-status'
      data-testid='transaction-status'
      className='bg-white rounded-[10px] overflow-hidden border border-black'
    >
      <div
        className={classNames(
          safeEnumMapGet(
            ZenTransactionStatusVariantTypeToClassNameMap,
            getTransactionStatusVariant(
              TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation,
            ),
            '',
          ),
          'px-6 py-3 space-y-1',
        )}
      >
        <div className='flex flex-row items-start justify-between'>
          <div className='flex flex-row items-center space-x-2'>
            <div className='flex flex-col space-y-0.5'>
              <div className='flex flex-row items-center '>
                <WarningRounded fontSize='small' />
                <p className='font-zen-body font-semibold text-xs'>{title}</p>
              </div>

              <p className='font-zen-body font-normal text-xs'>{description}</p>
              <div className='mt-2 pt-2'>
                <ZenButton
                  LeftIconComponent={
                    <SvgIcon
                      component={CompliantIcon}
                      viewBox='0 0 18 18'
                      className='ml-1'
                      fill='#05C3F9'
                    />
                  }
                  onClick={() => setOpen(true)}
                  label='Mark As Compliant'
                  variant='primary'
                  isFullWidth
                />
              </div>
              <ZenSimpleConfirmationModal
                isOpen={isOpen}
                variant='info'
                cancelButtonText='No'
                size='medium'
                hideIcon
                customIcon={
                  <SvgIcon
                    component={CompliantIcon}
                    viewBox='0 0 19 19'
                    className='ml-1 mt-1'
                    fill='#05C3F9'
                  />
                }
                title='Mark Transaction as Compliant ?'
                subtitle='You can always mark this transaction as Non-Compliant in the future.'
                confirmButtonText='Yes'
                onConfirm={setAsCompliant}
                onClose={() => setOpen(false)}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZenTransactionPowerAuditStatusInfo;
