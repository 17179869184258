import { cn } from '../../utils/classUtils';

export type PillVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'purple'
  | 'warningTwo'
  | 'disabled'
  | 'created'
  | 'active'
  | 'archived';

export type FontSize = 'text-xs' | 'text-sm' | 'text-lg';

export interface ZenStatusPillProps {
  text: string;
  variant?: PillVariantType;
  fontSize?: FontSize;
  icon?: JSX.Element;
  pillContainerStyle?: string;
}

export const pillVariantTypeToClassNameMap: {
  [type in PillVariantType]: string;
} = {
  default: 'bg-gray-200 text-dark',
  primary: 'bg-midnight-green-300 text-midnight-green-900',
  success: 'bg-seaglass text-midnight-green-900',
  warning: 'bg-yellow-100 text-midnight-900',
  danger: 'bg-zen-danger-light text-zen-danger',
  purple: 'bg-zen-purple text-zen-purple',
  warningTwo: 'bg-zen-warning-3 text-zen-warning-3',
  disabled: 'bg-gray-100 text-slate-600',
  created: 'bg-rezen-blue-100 text-rezen-blue-600',
  active: 'bg-green-100 text-green-600',
  archived: 'bg-grey-200 text-grey-600',
};

const GeminiStatusPill: React.FC<ZenStatusPillProps> = ({
  text,
  variant = 'default',
  icon,
  fontSize = 'text-sm',
  pillContainerStyle,
}) => {
  return (
    <div
      className={cn(
        'inline-block py-1.5 px-2.5 rounded-full whitespace-nowrap',
        pillVariantTypeToClassNameMap[variant],
        pillContainerStyle,
      )}
    >
      <div className='flex items-center justify-center'>
        {icon && <div className='mr-1 flex items-center'>{icon}</div>}
        <p className={`font-inter ${fontSize}`}>{text}</p>
      </div>
    </div>
  );
};

export default GeminiStatusPill;
