import { useDisclosure } from '@mantine/hooks';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import ZenResourceContainer from '../../../Zen/ZenResourceContainer';
import OutOfOfficeEmpty from './OutOfOfficeEmpty';
import OutOfOfficeForm from './OutOfOfficeForm';
import OutOfOfficeStatus from './OutOfOfficeStatus';
import RemoveOutOfOffice from './RemoveOutOfOffice';

type ZenOutOfOfficeProps = {
  agentId: string;
};

const ZenOutOfOffice: React.FC<ZenOutOfOfficeProps> = ({ agentId }) => {
  const [showScheduleModal, scheduleModalActions] = useDisclosure(false);
  const [showDeleteModal, setShowDeleteModal] = useDisclosure(false);

  const agentDetail = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data,
  );
  const outOfOffice = agentDetail?.availability?.outOfOffice?.[0];

  return (
    <>
      <ZenResourceContainer
        loading={false}
        isEmpty={isEmpty(outOfOffice)}
        resourceName='out of office'
        EmptyComponent={
          <OutOfOfficeEmpty onClick={scheduleModalActions.open} />
        }
      >
        <OutOfOfficeStatus
          agentDetail={agentDetail}
          onClickEdit={scheduleModalActions.open}
          onClickDelete={setShowDeleteModal.open}
        />
      </ZenResourceContainer>
      <OutOfOfficeForm
        agentId={agentId}
        agentDetail={agentDetail}
        opened={showScheduleModal}
        onClose={scheduleModalActions.close}
      />
      <RemoveOutOfOffice
        agentId={agentId}
        onClose={setShowDeleteModal.close}
        opened={showDeleteModal}
      />
    </>
  );
};

export default ZenOutOfOffice;
