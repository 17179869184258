import { regular, light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, values } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import AnalyticsService from '../../../services/AnalyticsService';
import {
  MAX_DOC_SIZE,
  REFUNDS_ALLOWED_FILE_TYPES,
} from '../../../constants/FilesConstants';
import {
  Address,
  AddressCountryEnum,
  AddressStateEnum,
  CreateRefundRequest,
  CreateRefundRequestPaymentTypeEnum,
  CreateRefundResponse,
  MoneyValueCurrencyEnum,
  RefundControllerApi,
  RefundResponse,
  TransactionResponse,
  UpdateRefundRequest,
  UpdateRefundRequestPaymentTypeEnum,
} from '../../../openapi/arrakis';
import {
  AddressRequestCountryEnum,
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaResponseCountryEnum,
  MoneyValue,
} from '../../../openapi/yenta';
import {
  ModifiedRefundAttachmentResponse,
  createRefundRequest,
  fetchAllRefundAttachments,
  saveAttachments,
  updateAttachment,
  updateRefundRequest,
  uploadRefundAttachment,
} from '../../../slices/ReleaseFundsSlice';
import {
  AnalyticsEventEnum,
  AppDispatch,
  ISelectOption,
  RootState,
} from '../../../types';
import {
  ALPHA_NUMERIC_REGEX_NO_SPACE,
  capitalizeEnum,
  getRoutingNumberForCountry,
} from '../../../utils/StringUtils';
import {
  EMAIL_VALIDATIONS,
  GOOGLE_AUTO_COMPLETE_VALIDATIONS,
  MONEY_NON_ZERO_VALUE_VALIDATIONS,
  PHONE_NUMBER_VALIDATIONS,
} from '../../../utils/Validations';
import IconButton from '../../IconButton';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledDraggableHorizontalInput from '../../Zen/Input/ZenControlledDraggableHorizontalInput';
import ZenControlledFormattedMoneyInput from '../../Zen/Input/ZenControlledFormattedMoneyInput';
import ZenControlledGoogleAutocompleteSearchInput, {
  GooglePlaceLocationType,
} from '../../Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenControlledHTMLSelectInput from '../../Zen/Input/ZenControlledHTMLSelectInput';
import ZenControlledAsyncSelectInput from '../../Zen/Input/ZenControlledAsyncSelectInput';
import ZenControlledPhoneNumberInput from '../../Zen/Input/ZenControlledPhoneNumberInput';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledStateOrProvinceInput from '../../Zen/Input/ZenControlledStateOrProvinceInput';
import ZenControlledTextInput from '../../Zen/Input/ZenControlledTextInput';
import ZenSidebarModalForm from '../../Zen/ZenSidebarModalForm';
import {
  getAddressFromAddressComponent,
  isCanadaTransaction,
} from '../../../utils/TransactionHelper';
import ResourceContainer from '../../ResourceContainer';
import ZenControlledTextAreaInput from '../../Zen/Input/ZenControlledTextAreaInput';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import {
  getPayableToFromParticipants,
  isRefundRequestEditable,
} from '../../../utils/RefundsUtils';
import ZenControlledEmailInput from '../../Zen/Input/ZenControlledEmailInput';
import { performFetch } from '../../../utils/FetchUtil';
import ParticipantLabelComponent from './ParticipantLabelComponent';
import AttachmentRow from './AttachmentRow';

interface FormData {
  paymentType:
    | CreateRefundRequestPaymentTypeEnum
    | UpdateRefundRequestPaymentTypeEnum;
  businessName: string;
  amount: MoneyValue;
  company: string;
  date: string;
  participants?: ISelectOption[];
  assignee: ISelectOption;
  accountHolderName: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  phoneNumber: string;
  refundPayeeEmail?: string;
  address: Address;
  location: GooglePlaceLocationType;
  files: File[];
  isManualAddress: boolean;
  note: string;
}

interface AddTrustfundProps {
  isOpen: boolean;
  onClose(): void;
  onEdit(request: RefundResponse): void;
  transaction: TransactionResponse;
  readonly?: boolean;
  refundRequest?: RefundResponse;
}

const AddTrustfund: React.FC<AddTrustfundProps> = ({
  isOpen,
  onEdit,
  onClose,
  transaction,
  readonly,
  refundRequest,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, dirtyFields },
  } = useForm<FormData>({
    defaultValues: {
      amount: {
        amount: refundRequest?.refundAmount?.amount!,
        currency: (transaction?.currency as unknown) as MoneyValueCurrencyEnum,
      },
      paymentType: (refundRequest?.paymentType! as unknown) as CreateRefundRequestPaymentTypeEnum,
      date: refundRequest?.fundsReleasedDate!,
      businessName: refundRequest?.payableTo!,
      participants: !!refundRequest?.participantResponses?.length
        ? refundRequest?.participantResponses?.map((participant) => ({
            value: participant?.id!,
            label: ((
              <ParticipantLabelComponent participant={participant} />
            ) as unknown) as string,
          }))
        : undefined,
      accountHolderName: refundRequest?.accountHolderName!,
      bankName: refundRequest?.bankName!,
      routingNumber: refundRequest?.routingNumber!,
      accountNumber: refundRequest?.bankAccountNumber!,
      phoneNumber: refundRequest?.refundPayeePhoneNumber!,
      refundPayeeEmail: refundRequest?.refundPayeeEmail,
      address: refundRequest?.refundPayeeAddress!,
      location: {
        address_components: [],
        formatted_address: refundRequest?.refundPayeeAddress?.oneLine ?? '',
        geometry: {
          location: {
            lat: undefined,
            lng: undefined,
          },
        },
        place_id: '',
      },
      note: refundRequest?.note,
    },
  });

  const {
    auth: { userDetail, isAdmin, isBroker },
    releaseFunds: { refundAttachments },
  } = useSelector((state: RootState) => state);

  const dispatch: AppDispatch = useDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [loadingAttachments, setLoadingAttachments] = useState<boolean>(false);

  const fetchParticipants = async () => {
    try {
      const { data: participants } = await new RefundControllerApi(
        await getArrakisConfiguration(),
      ).getParticipantsForTransaction(transaction?.id!);

      const options = (participants || [])?.map((participant) => ({
        label: <ParticipantLabelComponent participant={participant} />,
        value: participant?.id!,
      }));

      return options;
    } catch (e) {
      dispatch(
        showErrorToast(
          'We had a problem fetching participants for refunds.',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to fetch the participants for refunds.', e, {
        transaction: { id: transaction?.id! },
      });

      return [];
    }
  };

  const [
    selectedPaymentType,
    isManualAddress,
    files,
    accountNumber,
    businessName,
    note,
    participants,
  ] = watch([
    'paymentType',
    'isManualAddress',
    'files',
    'accountNumber',
    'businessName',
    'note',
    'participants',
  ]);

  const modalTitle = !!refundRequest
    ? 'Edit Release of Funds Request'
    : 'Add Release of Funds Request';

  const routingNumberPattern: RegExp = getRoutingNumberForCountry(
    (transaction?.country as unknown) as AddressRequestCountryEnum,
  );

  const paymentTypeOptions = [
    {
      label: isCanadaTransaction(transaction) ? 'EFT' : 'ACH',
      value: CreateRefundRequestPaymentTypeEnum.Ach,
    },
    {
      label: 'Wire',
      value: CreateRefundRequestPaymentTypeEnum.Wire,
    },
    {
      label: isCanadaTransaction(transaction) ? 'Cheque' : 'Check',
      value: CreateRefundRequestPaymentTypeEnum.Check,
    },
  ];

  const getAllAttachmentsForRefund = useCallback(async () => {
    if (refundRequest?.id) {
      setLoadingAttachments(true);
      await dispatch(fetchAllRefundAttachments(refundRequest?.id));
      setLoadingAttachments(false);
    }
  }, [dispatch, refundRequest?.id]);

  useEffect(() => {
    if (dirtyFields?.paymentType) {
      if (selectedPaymentType === CreateRefundRequestPaymentTypeEnum.Check) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_CHECK,
          {
            requestId: refundRequest?.id,
            paymentType: selectedPaymentType,
          },
        );
      } else if (
        selectedPaymentType === CreateRefundRequestPaymentTypeEnum.Wire
      ) {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_WIRE,
          {
            requestId: refundRequest?.id,
            paymentType: selectedPaymentType,
          },
        );
      } else {
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_ACH_EFT,
          {
            requestId: refundRequest?.id,
            paymentType: selectedPaymentType,
          },
        );
      }
    }
  }, [selectedPaymentType, dirtyFields?.paymentType, refundRequest?.id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAccountInfoEntered = useCallback(
    debounce(
      () =>
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_ENTERED_ACCOUNT_INFO,
          {
            requestId: refundRequest?.id,
          },
        ),
      1000,
    ),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePayableToEntered = useCallback(
    debounce(
      () =>
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_ENTERED_PAYABLE_TO,
          {
            requestId: refundRequest?.id,
          },
        ),
      1000,
    ),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceNoteInfoEntered = useCallback(
    debounce(
      () =>
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_ENTERED_NOTE,
          {
            requestId: refundRequest?.id,
          },
        ),
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (dirtyFields?.accountNumber) {
      debounceAccountInfoEntered();
    }
  }, [debounceAccountInfoEntered, dirtyFields?.accountNumber, accountNumber]);

  useEffect(() => {
    if (dirtyFields?.businessName) {
      debouncePayableToEntered();
    }
  }, [debouncePayableToEntered, dirtyFields?.businessName, businessName]);

  useEffect(() => {
    if (dirtyFields?.note) {
      debounceNoteInfoEntered();
    }
  }, [debounceNoteInfoEntered, dirtyFields?.note, note]);

  useEffect(() => {
    getAllAttachmentsForRefund();

    return () => {
      dispatch(saveAttachments([]));
    };
  }, [dispatch, getAllAttachmentsForRefund]);

  const uploadFiles = useCallback(
    async (filesToUpload: File[]) => {
      setIsUploading(true);
      const FilesTobeUploaded: ModifiedRefundAttachmentResponse[] = filesToUpload?.map(
        (fileToUpload) => ({
          createdAt: DateTime.local().toMillis(),
          updatedAt: DateTime.local().toMillis(),
          refundId: refundRequest?.id,
          name: fileToUpload?.name,
          path: URL.createObjectURL(fileToUpload),
          uploaderId: userDetail?.id!,
          isLocal: true,
          id: uuid(),
        }),
      );
      dispatch(updateAttachment(FilesTobeUploaded));
      setIsUploading(false);
      setValue('files', []);
      AnalyticsService.instance().logEvent(
        AnalyticsEventEnum.EXCESS_FUNDS_UPLOADED_DOC,
        {
          requestId: refundRequest?.id,
          paymentType: selectedPaymentType,
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, refundRequest?.id, setValue],
  );

  useEffect(() => {
    if (files?.length) {
      uploadFiles(files);
    }
  }, [files, uploadFiles]);

  useEffect(() => {
    if (dirtyFields?.participants) {
      if (!!participants?.length) {
        const addedParticipants = participants?.map(
          //@ts-ignore
          (p) => p?.label?.props?.participant,
        );
        setValue(
          'businessName',
          getPayableToFromParticipants(addedParticipants),
        );
        setValue('phoneNumber', addedParticipants[0]?.phoneNumber || '');
        setValue('refundPayeeEmail', addedParticipants[0]?.emailAddress || '');
      } else {
        setValue('businessName', '');
        setValue('phoneNumber', '');
        setValue('refundPayeeEmail', '');
      }
    }
  }, [dirtyFields?.participants, participants, setValue]);

  const onSubmit = async (formData: FormData) => {
    if (readonly) {
      onClose();
      onEdit(refundRequest!);
    } else {
      const autocompleteAddress = getAddressFromAddressComponent(
        formData?.location?.address_components!,
      );

      const request = {
        participantIds: !!formData.participants?.length
          ? formData.participants?.map((participant) => participant?.value)
          : [],
        payableTo: formData.businessName,
        amount: ({
          amount: formData.amount?.amount!,
          currency: transaction?.currency!,
        } as unknown) as MoneyValue,
        fundsReleaseDate: formData.date,
        paymentType: formData.paymentType,
        accountHolderName: formData.accountHolderName,
        bankName: formData.bankName,
        routingNumber: formData.routingNumber,
        bankAccountNumber: formData.accountNumber,
        accountHolderPhoneNumber: formData.phoneNumber,
        refundPayeePhoneNumber: formData.phoneNumber,
        refundPayeeEmail: formData.refundPayeeEmail,
        refundPayeeAddress: {
          street:
            isManualAddress || !formData?.location?.place_id
              ? formData?.address?.street!
              : autocompleteAddress?.streetAddress1,
          street2:
            isManualAddress || !formData?.location?.place_id
              ? formData?.address?.street2!
              : autocompleteAddress?.streetAddress2,
          city:
            isManualAddress || !formData?.location?.place_id
              ? formData?.address?.city!
              : autocompleteAddress?.city!,
          state:
            isManualAddress || !formData?.location?.place_id
              ? ((formData?.address?.state! as unknown) as AddressStateEnum)
              : ((autocompleteAddress?.stateOrProvince! as unknown) as AddressStateEnum),
          zip:
            isManualAddress || !formData?.location?.place_id
              ? formData?.address?.zip!
              : autocompleteAddress?.zipOrPostalCode!,
          country:
            isManualAddress || !formData?.location?.place_id
              ? formData?.address?.country!
              : ((autocompleteAddress?.country! as unknown) as AddressCountryEnum),
        },
        requestingUser: userDetail?.id!,
        note: formData.note,
      };
      let data: RefundResponse | CreateRefundResponse | undefined = undefined;

      if (!!refundRequest) {
        data = await dispatch(
          updateRefundRequest(
            refundRequest?.id!,
            request as UpdateRefundRequest,
            transaction?.id!,
          ),
        );

        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_EDITED_REQUEST,
          {
            requestId: refundRequest?.id,
            paymentType: formData?.paymentType,
          },
        );
      } else {
        data = await dispatch(
          createRefundRequest({
            ...request,
            transactionId: transaction?.id!,
          } as CreateRefundRequest),
        );
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.EXCESS_FUNDS_CLICKED_SAVE_REQUEST,
          {
            paymentType: formData?.paymentType,
          },
        );
      }

      const filesToUpload = refundAttachments?.filter(
        (attachement) => !!attachement?.isLocal,
      );

      if (filesToUpload?.length && data) {
        await Promise.all(
          [...filesToUpload]?.map(async (fileToUpload) => {
            const blob = await performFetch(fileToUpload?.path!).then((f) =>
              f?.blob(),
            );
            const file = new File([blob], fileToUpload?.name!);
            dispatch(
              uploadRefundAttachment(
                !!refundRequest
                  ? refundRequest?.id!
                  : (data as CreateRefundResponse)?.refundId!,
                fileToUpload?.name!,
                file,
                '',
              ),
            );
          }),
        );
        setValue('files', []);
      }

      if (data) {
        onClose();
      }
    }
  };

  return (
    <ZenSidebarModalForm
      title={readonly ? 'View Refund Request' : modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle={readonly ? 'Edit' : 'Save'}
      isSubmitting={isSubmitting}
      isDisabled={
        readonly && !!refundRequest
          ? !isRefundRequestEditable(refundRequest?.status!, isAdmin, isBroker)
          : undefined
      }
    >
      <div>
        <ZenControlledAsyncSelectInput<FormData, 'participants'>
          control={control}
          name='participants'
          label='Transaction participants to receive excess funds'
          placeholder='Add Participant'
          fetchData={async (_, page) => {
            if (page === 0) {
              return await fetchParticipants();
            }
            return [];
          }}
          isMulti
          readOnly={readonly}
          rules={{
            required: 'Please select participants',
          }}
          isRequired
        />
      </div>
      <div className='mt-5'>
        <ZenControlledTextInput<FormData, 'businessName'>
          name='businessName'
          label='Refunds should be payable to'
          control={control}
          placeholder='Make Payable To'
          readOnly={readonly}
          rules={{ required: 'Please enter the payable to' }}
          isRequired
        />
      </div>
      <div className='flex flex-row justify-between items-center space-x-4 mt-5'>
        <div className='w-1/2'>
          <ZenControlledEmailInput<FormData, 'refundPayeeEmail'>
            name='refundPayeeEmail'
            label='Email Address'
            control={control}
            placeholder='E.g. John@example.com'
            readOnly={readonly}
            rules={{
              required: 'Please enter the email',
              ...EMAIL_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='w-1/2'>
          <ZenControlledPhoneNumberInput<FormData, 'phoneNumber'>
            control={control}
            label='Phone Number'
            name='phoneNumber'
            placeholder='+1 (702) 123-4567'
            rules={{
              required: 'Please enter the phone number',
              ...PHONE_NUMBER_VALIDATIONS,
            }}
            isRequired
            readOnly={readonly}
          />
        </div>
      </div>
      <div className='flex flex-row justify-between items-center space-x-4 mt-5'>
        <div className='w-1/2'>
          <ZenControlledFormattedMoneyInput<FormData, 'amount'>
            name='amount'
            label='Amount to be released'
            control={control}
            placeholder='0.00'
            currencyReadOnly
            readOnly={readonly}
            rules={{
              ...MONEY_NON_ZERO_VALUE_VALIDATIONS,
            }}
            isRequired
          />
        </div>
        <div className='w-1/2'>
          <ZenControlledDatePickerInput<FormData, 'date'>
            name='date'
            label='Funds to be released on'
            control={control}
            placeholder='MM/DD/YYYY'
            datePickerConfig={{
              minDate: DateTime.local().toJSDate(),
            }}
            icon={
              <FontAwesomeIcon
                icon={light('calendar')}
                className='text-primary-blue flex justify-center items-center'
                fontSize={18}
              />
            }
            readOnly={readonly}
            rules={{
              required: 'Date is required',
              validate: (value) => {
                if (!!value && DateTime.fromISO(value)?.isValid) {
                  return value < DateTime.local().toISODate()
                    ? 'Please enter a date in the present or in the future'
                    : undefined;
                }
                return undefined;
              },
            }}
            isRequired
          />
        </div>
      </div>
      <div className='mt-5'>
        <ZenControlledTextAreaInput<FormData, 'note'>
          name='note'
          label='Reason / Notes'
          control={control}
          placeholder='Reason / Notes'
          isRequired
          rules={{
            required: 'Note is required',
          }}
          readOnly={readonly}
          rows={2}
        />
      </div>
      <div className='mt-5'>
        <ZenControlledRadioInput<FormData, 'paymentType'>
          label='Payment type'
          options={
            !isCanadaTransaction(transaction)
              ? paymentTypeOptions
              : paymentTypeOptions?.filter((option) => option?.label !== 'Wire')
          }
          name='paymentType'
          control={control}
          rules={{
            required: 'Payment type is required',
          }}
          inlineOptions
          isRequired
          disabled={readonly}
        />
      </div>
      {!!selectedPaymentType && (
        <React.Fragment>
          {selectedPaymentType !== CreateRefundRequestPaymentTypeEnum.Check && (
            <div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'accountHolderName'>
                  name='accountHolderName'
                  label='Account Holder Name'
                  control={control}
                  placeholder='Account holder name'
                  isRequired={!isCanadaTransaction(transaction!)}
                  rules={{
                    required: !isCanadaTransaction(transaction!)
                      ? 'Account Holder Name is required'
                      : undefined,
                  }}
                  readOnly={readonly}
                  shouldUnregister={false}
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'bankName'>
                  name='bankName'
                  label={
                    isCanadaTransaction(transaction!)
                      ? 'Bank Name / Institution Number'
                      : 'Bank Name'
                  }
                  control={control}
                  placeholder='Bank Name'
                  isRequired={!isCanadaTransaction(transaction!)}
                  rules={{
                    required: !isCanadaTransaction(transaction!)
                      ? 'Bank Name is required'
                      : undefined,
                  }}
                  readOnly={readonly}
                  shouldUnregister={false}
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'accountNumber'>
                  name='accountNumber'
                  label='Account Number'
                  control={control}
                  placeholder='Account Number'
                  isRequired={!isCanadaTransaction(transaction!)}
                  rules={{
                    required: !isCanadaTransaction(transaction!)
                      ? 'Account Number is required'
                      : undefined,
                    pattern: {
                      value: ALPHA_NUMERIC_REGEX_NO_SPACE,
                      message: 'Please enter a valid account number',
                    },
                  }}
                  readOnly={readonly}
                  shouldUnregister={false}
                />
              </div>
              <div className='mt-5'>
                <ZenControlledTextInput<FormData, 'routingNumber'>
                  name='routingNumber'
                  label='Routing Number'
                  control={control}
                  placeholder='Routing Number'
                  rules={{
                    required: !isCanadaTransaction(transaction!)
                      ? 'Routing Number is required'
                      : undefined,
                    pattern: {
                      value: routingNumberPattern,
                      message: 'Please enter a valid routing number',
                    },
                  }}
                  isRequired={!isCanadaTransaction(transaction!)}
                  readOnly={readonly}
                  shouldUnregister={false}
                />
              </div>
              <div>
                {!readonly && (
                  <div className='w-full my-5 md:mb-0'>
                    <ZenControlledDraggableHorizontalInput<FormData, 'files'>
                      name='files'
                      label={
                        isCanadaTransaction(transaction!)
                          ? 'Upload EFT Instructions'
                          : 'Upload Wire/ACH Instructions'
                      }
                      control={control}
                      accept={REFUNDS_ALLOWED_FILE_TYPES.join(',')}
                      isUploading={isUploading}
                      maxUploadSize={MAX_DOC_SIZE}
                    />
                  </div>
                )}

                <ResourceContainer
                  loading={loadingAttachments}
                  errorCode={null}
                  isEmpty={false}
                  resourceName='attachments'
                >
                  {!!refundAttachments?.length && (
                    <div className='mt-6'>
                      <p className='font-zen-body font-medium text-base text-zen-dark-9'>
                        Documents Uploded
                      </p>
                      {refundAttachments?.map((file) => (
                        <AttachmentRow
                          key={file?.name}
                          attachment={file}
                          readonly={readonly}
                          refundId={refundRequest?.id}
                        />
                      ))}
                    </div>
                  )}
                </ResourceContainer>
              </div>
            </div>
          )}
          {selectedPaymentType === CreateRefundRequestPaymentTypeEnum.Check && (
            <div>
              {isManualAddress && (
                <div className='mt-5 space-y-4'>
                  <div>
                    <ZenControlledTextInput<FormData, 'address.street'>
                      control={control}
                      label='Street'
                      name='address.street'
                      placeholder='E.g. 270 1st Ave.'
                      rules={{
                        required: 'Street is required',
                      }}
                      isRequired
                      readOnly={readonly}
                      shouldUnregister={false}
                    />
                  </div>
                  <div className='flex flex-row space-x-4'>
                    <ZenControlledTextInput<FormData, 'address.street2'>
                      control={control}
                      label='Street Line 2'
                      name='address.street2'
                      placeholder='E.g. 270 1st Ave.'
                      readOnly={readonly}
                      shouldUnregister={false}
                    />
                    <ZenControlledHTMLSelectInput<FormData, 'address.country'>
                      name='address.country'
                      control={control}
                      label='Country'
                      placeholder='Country'
                      options={[
                        {
                          label: 'Select Country',
                          value: '',
                        },
                        ...values(AdministrativeAreaResponseCountryEnum).map(
                          (country) => ({
                            value: country,
                            label: capitalizeEnum(country),
                          }),
                        ),
                      ]}
                      rules={{
                        required: 'Please select country',
                      }}
                      isRequired
                      readOnly={readonly}
                      shouldUnregister={false}
                    />
                  </div>
                  <div className='flex flex-row space-x-4'>
                    <ZenControlledTextInput<FormData, 'address.city'>
                      control={control}
                      label='City'
                      name='address.city'
                      placeholder='Eg. New York'
                      rules={{
                        required: 'City is required',
                      }}
                      isRequired
                      readOnly={readonly}
                      shouldUnregister={false}
                    />
                    <ZenControlledStateOrProvinceInput<
                      FormData,
                      'address.state'
                    >
                      name='address.state'
                      control={control}
                      setValue={setValue}
                      selectedCountry={
                        (watch(
                          'address.country',
                        ) as unknown) as AdministrativeAreaRequestCountryEnum
                      }
                      rules={{
                        required: 'Please select state',
                      }}
                      isRequired
                      readOnly={readonly}
                      shouldUnregister={false}
                    />
                  </div>
                  <div className='flex flex-row space-x-4'>
                    <ZenControlledTextInput<FormData, 'address.zip'>
                      control={control}
                      label='ZIP/Postal Code'
                      name='address.zip'
                      placeholder='E.g. 10001'
                      rules={{
                        required: 'ZIP/Postal Code is required',
                      }}
                      isRequired
                      readOnly={readonly}
                      shouldUnregister={false}
                    />
                  </div>
                </div>
              )}

              {!isManualAddress && (
                <div className='mt-5'>
                  <ZenControlledGoogleAutocompleteSearchInput<
                    FormData,
                    'location'
                  >
                    control={control}
                    name='location'
                    label='Recipient Address'
                    placeholder='Select Location'
                    rules={{
                      ...GOOGLE_AUTO_COMPLETE_VALIDATIONS,
                    }}
                    shouldUnregister={false}
                    isRequired
                    disabled={readonly}
                  />
                </div>
              )}

              <div className='mt-5'>
                <IconButton
                  label={
                    isManualAddress
                      ? 'Search Address'
                      : 'Enter address manually'
                  }
                  variant='none'
                  buttonStyle='text-primary-blue rounded-full border-2 border-primary-blue'
                  leftIcon={
                    <FontAwesomeIcon
                      icon={regular('arrow-up-arrow-down')}
                      fontSize={16}
                      className='mx-0.5'
                    />
                  }
                  onClick={() => {
                    setValue('isManualAddress', !isManualAddress);
                  }}
                  disabled={readonly}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </ZenSidebarModalForm>
  );
};

export default AddTrustfund;
