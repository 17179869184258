import { last } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import {
  Address,
  AttachedFeeValue,
  AttachedFeeValueFeeTypeEnum,
  AttachedFeeValueRecipientTypeEnum,
  BusinessEntityValue,
  FeaturesResponseEligibleEnum,
  MoneyValue,
  PercentageValue,
  PriceAndDateInfoRequestRepresentationTypeEnum,
  TransactionBuilderResponseBuilderTypeEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../../openapi/arrakis';
import { AddressRequestCountryEnum } from '../../../../openapi/yenta';
import {
  fetchTransactionBuilder,
  saveTransaction,
  saveTransactionBuilderDetail,
  saveTransactionBuilderFeatures,
} from '../../../../slices/TransactionBuilderSlice';
import {
  AppDispatch,
  CreateTransactionParticipantType,
  FeatureFlagTypeEnum,
  ISelectOption,
  RootState,
  YesNoType,
} from '../../../../types';
import { showTxBuilderDoubleEnderCommissionPayerStep } from '../../../../utils/DoubleEnderUtils';
import { isCommercialAgent } from '../../../../utils/FormUtils';
import {
  getTransactionCountry,
  getcreateTransactionDefaultFormValues,
  isSaleTransaction,
} from '../../../../utils/TransactionHelper';
import DefaultLoader from '../../../DefaultLoader';
import StepByStepContainer, {
  StepConfig,
} from '../../../StepByStep/StepByStepContainer';
import ZenAgentOnboardingLayout from '../../../ZenAgentOnboardingLayout';
import { GooglePlaceLocationType } from '../../Input/ZenControlledGoogleAutocompleteSearchInput';
import ZenRoute from '../../ZenRoute';
import ZenBuyerLawyerInformationStep from './ZenBuyerLawyerInformationStep';
import ZenCommissionPayerStep from './ZenCommissionPayerStep';
import ZenSellerLawyerInformationStep from './ZenSellerLawyerInformationStep';
import ZenTitleCompanyStep from './ZenTitleCompanyStep';
import ZenTransactionAdditionalFeeStep from './ZenTransactionAdditionalFeeStep';
import ZenTransactionAddressStep from './ZenTransactionAddressStep';
import ZenTransactionBuyerAndSellerStep from './ZenTransactionBuyerAndSellerStep';
import ZenTransactionCommissionStep from './ZenTransactionCommissionStep';
import ZenTransactionFMLSFeeStep from './ZenTransactionFMLSFeeStep';
import ZenTransactionFirmDateStep from './ZenTransactionFirmDateStep';
import ZenTransactionOtherBrokerageInfoStep from './ZenTransactionOtherBrokerageInfoStep';
import ZenTransactionOwnerStep from './ZenTransactionOwnerStep';
import ZenTransactionPersonalDealStep from './ZenTransactionPersonalDealStep';
import ZenTransactionReferralFeeStep from './ZenTransactionReferralFeeStep';
import ZenTransactionStepReview from './ZenTransactionStepReview';
import ZenTransactionTypeAndPriceStep from './ZenTransactionTypeAndPriceStep';
import ZenTransactionUseRealTitle from './ZenTransactionUseRealTitle';
import ZenTransactionZeroCommissionDealStep from './ZenTransactionZeroCommissionDealStep';

export type Match = {
  transactionBuilderId: string;
};

export enum CreateTransactionStepName {
  ADDRESS_STEP = 'ADDRESS_STEP',
  FMLS_FEE = 'FMLS_FEE',
  TYPE_PRICE_STEP = 'TYPE_PRICE_STEP',
  FIRM_DATE_STEP = 'FIRM_DATE_STEP',
  ZERO_COMMISSION_DEAL_STEP = 'ZERO_COMMISSION_DEAL_STEP',
  BUYER_AND_SELLER_STEP = 'BUYER_AND_SELLER_STEP',
  OWNER_STEP = 'OWNER_STEP',
  OTHER_BROKERAGE_STEP = 'OTHER_BROKERAGE_STEP',
  REFERRAL_STEP = 'REFERRAL_STEP',
  COMMISSION_STEP = 'COMMISSION_STEP',
  PERSONAL_DEAL_STEP = 'PERSONAL_DEAL_STEP',
  ADDITIONAL_FEE_STEP = 'ADDITIONAL_FEE_STEP',
  USE_REAL_TITLE_STEP = 'USE_REAL_TITLE_STEP',
  TITLE_COMPANY_STEP = 'TITLE_COMPANY_STEP',
  SELLER_LAWYER = 'SELLER_LAWYER',
  BUYER_LAWYER = 'BUYER_LAWYER',
  COMMISSION_PAYER_STEP = 'COMMISSION_PAYER_STEP',
  STEP_REVIEW = 'STEP_REVIEW',
}

export interface Participant {
  participantType: CreateTransactionParticipantType;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  address: string;
  location?: GooglePlaceLocationType;
  isSearchAddress?: boolean;
  usePropertyAddress?: YesNoType[];
}

export enum OtherAgentTypeEnum {
  EXTERNAL_AGENT = 'EXTERNAL_AGENT',
  REAL_AGENT = 'REAL_AGENT',
  UNREPRESENTED = 'UNREPRESENTED',
}
export interface OtherBrokerageInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  companyName?: string;
  address?: string;
  otherAgentType?: OtherAgentTypeEnum;
  realAgent?: ISelectOption;
  isSearchAddress?: boolean;
  location?: GooglePlaceLocationType;
}

export interface CommissionPayerInfo {
  role?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  isInfoUnknown: string[];
  participant: string | undefined;
}

interface Commission {
  percent?: PercentageValue;
  money?: MoneyValue;
  isDollar?: boolean;
}

interface CreateParticipantRequest {
  id?: string;
  participantRole?: any;
  firstName?: string;
  lastName?: string;
  paidViaBusinessEntity?: BusinessEntityValue;
  email?: string;
  phoneNumber?: string;
  address?: string;
  autoAddress?: GooglePlaceLocationType;
  agentId?: string;
  file?: any;
  receivesInvoice?: boolean;
  type?: string;
  ein?: string;
  percent?: PercentageValue;
  money?: MoneyValue;
  isDollar?: boolean;
  // additional fields
  participant?: ISelectOption;
}

export interface ExtendedAttachedFeeValue
  extends Omit<AttachedFeeValue, 'feeType' | 'recipientType'> {
  name?: string;
  feeType?: AttachedFeeValueFeeTypeEnum | '';
  recipientType?: AttachedFeeValueRecipientTypeEnum | '';
  addedBySystem?: boolean;
}

export interface RealCoAgents {
  id?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  agentId?: string;
}

export interface LawyerInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  companyName?: string;
  address?: string;
  autoCompleteAddress?: GooglePlaceLocationType;
}

export interface CreateTransactionFormState {
  //ADDRESS_STEP
  location?: GooglePlaceLocationType;
  unitNo: string;
  yearBuilt: number;
  mlsNumber: string;
  escrowNumber: string;
  address: Address;
  isManualAddress: boolean;
  manualAddressUnitNo: string;
  manualAddressYearBuilt: number;
  isFMLSProperty?: YesNoType;

  //TYPE_PRICE_STEP
  acceptanceDate: Date;
  closingDate: Date;
  firmDate: Date;
  transactionType: TransactionResponseTransactionTypeEnum;
  price: MoneyValue;
  commission: Commission;
  listingCommission: Commission;
  propertyType?: ISelectOption;

  //Firm_Date
  conditionalDates?: YesNoType;
  financingConditionsExpirationDate?: Date;
  propertyInspectionExpirationDate?: Date;
  saleOfBuyersPropertyExpirationDate?: Date;
  condoDocumentsExpirationDate?: Date;
  otherConditionsExpirationDate?: Date;

  //ZERO_COMMISSION_DEAL_STEP
  zeroCommissionDeal: boolean;

  //BUYER_AND_SELLER_STEP
  sellerOrLandlord: Participant[];
  buyerOrTenant: Participant[];

  //OWNER_STEP
  transactionOwnerName: ISelectOption;
  transactionOwnerRepresent: PriceAndDateInfoRequestRepresentationTypeEnum;
  realCoAgent: RealCoAgents;
  realCoAgents: RealCoAgents[];
  officeId: string;
  teamId?: ISelectOption;

  //OTHER_BROKERAGE_STEP
  isOtherSideUnrepresented: YesNoType;
  otherBrokerageInfo: OtherBrokerageInfo;

  //REFERRAL_STEP
  referralFee: YesNoType;
  commissionParticipant: CreateParticipantRequest[];
  externalParticipant: CreateParticipantRequest;
  realParticipant: CreateParticipantRequest;

  //PERSONAL_DEAL_STEP
  personalDeal: YesNoType;
  representedByRealEstateAgent: YesNoType;

  //ADDITIONAL_FEE_STEP
  hasRebate: YesNoType;
  additionalFeesAndRebate: ExtendedAttachedFeeValue;
  additionalFeesAndRebates: ExtendedAttachedFeeValue[];

  //USE_REAL_TITLE_STEP
  hasRealTitle?: YesNoType;

  //TITLE_COMPANY_STEP
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  companyName: string | undefined;

  //SELLER_LAWYER
  sellerLawyerInfo: LawyerInfo;

  //BUYER_LAWYER
  buyerLawyerInfo: LawyerInfo;

  //COMMISSION_PAYER_STEP
  commissionPayerInfo: CommissionPayerInfo;
}

const ZenCreateTransactionSteps: React.FC = () => {
  const history = useHistory();
  const { transactionBuilderId } = useParams() as Match;
  const {
    userIds: { agentById },
    auth: { userDetail },
    transactionBuilder: {
      transactionBuilder,
      transactionBuilderLoading,
      isErrorModalVisible,
      commissionPayerRoles,
      loadingCommissionPayerRoles,
      builderFeatures,
    },
  } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const application = last(userDetail?.applications);

  const isRealTitleFeatureAvailable = !!builderFeatures?.eligible?.includes(
    FeaturesResponseEligibleEnum.Title,
  );

  const isFMLSFeeApplicable = !!builderFeatures?.eligible?.includes(
    FeaturesResponseEligibleEnum.Fmls,
  );

  const isDoubleEnderEnabled = useFeatureFlag(FeatureFlagTypeEnum.DOUBLE_ENDER);

  const steps: StepConfig<
    CreateTransactionFormState,
    CreateTransactionStepName
  >[] = useMemo(
    () => [
      {
        name: CreateTransactionStepName.ADDRESS_STEP,
        Component: ZenTransactionAddressStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.TYPE_PRICE_STEP,
        Component: ZenTransactionTypeAndPriceStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.FIRM_DATE_STEP,
        showStepIf: ({ address, location }) =>
          getTransactionCountry(address, location) ===
          AddressRequestCountryEnum.Canada,
        Component: ZenTransactionFirmDateStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.FMLS_FEE,
        Component: ZenTransactionFMLSFeeStep,
        showStepIf: () => isFMLSFeeApplicable,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.ZERO_COMMISSION_DEAL_STEP,
        Component: ZenTransactionZeroCommissionDealStep,
        showStepIf: ({ zeroCommissionDeal }) => zeroCommissionDeal,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.BUYER_AND_SELLER_STEP,
        Component: ZenTransactionBuyerAndSellerStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.OWNER_STEP,
        Component: ZenTransactionOwnerStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.OTHER_BROKERAGE_STEP,
        Component: ZenTransactionOtherBrokerageInfoStep,
        showStepIf: ({ transactionOwnerRepresent }) =>
          transactionOwnerRepresent !==
          PriceAndDateInfoRequestRepresentationTypeEnum.Dual,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.REFERRAL_STEP,
        Component: ZenTransactionReferralFeeStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.COMMISSION_STEP,
        Component: ZenTransactionCommissionStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.PERSONAL_DEAL_STEP,
        Component: ZenTransactionPersonalDealStep,
        showStepIf: () => !isCommercialAgent(application, userDetail),
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.ADDITIONAL_FEE_STEP,
        Component: ZenTransactionAdditionalFeeStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.TITLE_COMPANY_STEP,
        showStepIf: () => false,
        Component: ZenTitleCompanyStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.SELLER_LAWYER,
        showStepIf: ({ transactionType, address, location }) =>
          getTransactionCountry(address, location) ===
            AddressRequestCountryEnum.Canada &&
          isSaleTransaction(transactionType),
        Component: ZenSellerLawyerInformationStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.BUYER_LAWYER,
        showStepIf: ({ transactionType, address, location }) =>
          getTransactionCountry(address, location) ===
            AddressRequestCountryEnum.Canada &&
          isSaleTransaction(transactionType),
        Component: ZenBuyerLawyerInformationStep,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.USE_REAL_TITLE_STEP,
        Component: ZenTransactionUseRealTitle,
        showStepIf: () => isRealTitleFeatureAvailable,
        hidePagination: true,
      },
      {
        name: CreateTransactionStepName.COMMISSION_PAYER_STEP,
        Component: ZenCommissionPayerStep,
        hidePagination: true,
        showStepIf: (values) =>
          showTxBuilderDoubleEnderCommissionPayerStep({
            isDoubleEnderEnabled,
            userDetail,
            formValues: values,
          }),
      },
      {
        name: CreateTransactionStepName.STEP_REVIEW,
        Component: ZenTransactionStepReview,
        hidePagination: true,
      },
    ],
    [
      application,
      isDoubleEnderEnabled,
      isFMLSFeeApplicable,
      isRealTitleFeatureAvailable,
      userDetail,
    ],
  );

  const defaultValue = getcreateTransactionDefaultFormValues(
    agentById,
    userDetail,
    commissionPayerRoles || [],
    transactionBuilder,
    { [FeatureFlagTypeEnum.DOUBLE_ENDER]: isDoubleEnderEnabled },
  );

  useEffect(() => {
    if (transactionBuilderId) {
      dispatch(fetchTransactionBuilder(transactionBuilderId, true));
    }
  }, [dispatch, transactionBuilderId]);

  const onSubmit = async () => {
    const transactionResponse = await dispatch(
      saveTransaction(
        transactionBuilderId!,
        TransactionBuilderResponseBuilderTypeEnum.Transaction,
      ),
    );

    if (transactionResponse && !isErrorModalVisible) {
      history.push(`/transactions/${transactionResponse?.id}/detail`);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(saveTransactionBuilderDetail(undefined));
      dispatch(saveTransactionBuilderFeatures(undefined));
    };
  }, [dispatch]);

  if (transactionBuilderLoading || loadingCommissionPayerRoles) {
    return <DefaultLoader />;
  }

  return (
    <ZenRoute title='Create Transaction'>
      <ZenAgentOnboardingLayout
        variant='info'
        title='New Transaction'
        onClose={() => history.push('/transactions/draft')}
        modalTitle='Transaction Saved!'
        modalSubtitle='This transaction will be saved in your drafts and will be available for future references.'
        modalSubmitText='OK'
        hideCancelButton
        hideLogout
      >
        <StepByStepContainer<
          CreateTransactionFormState,
          CreateTransactionStepName
        >
          steps={steps}
          onSubmit={onSubmit}
          defaultValues={defaultValue}
          reValidateMode='onChange'
          mode='onChange'
        />
      </ZenAgentOnboardingLayout>
    </ZenRoute>
  );
};

export default ZenCreateTransactionSteps;
