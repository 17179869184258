import { EnumMap } from '../../../types';
import { capitalizeEnum } from '../../../utils/StringUtils';
import GeminiStatusPill, {
  PillVariantType,
} from '../../Gemini/GeminiStatusPill';

export interface AmendmentStatusCellProps {
  status: string;
}

const statusToPillVariantMap: EnumMap<string, PillVariantType> = {
  ['ARCHIVED']: 'archived',
  ['CREATED']: 'created',
  ['ACTIVE']: 'active',
};

const AmendmentStatusCell: React.FC<AmendmentStatusCellProps> = ({
  status,
}) => {
  return (
    <GeminiStatusPill
      text={capitalizeEnum(status)}
      variant={statusToPillVariantMap[status]}
      pillContainerStyle='font-medium'
    />
  );
};

export default AmendmentStatusCell;
