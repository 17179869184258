import {
  faBuildings,
  faCalendarCheck,
  faCalendarClock,
  faDollarSign,
  faHelmetSafety,
} from '@fortawesome/pro-regular-svg-icons';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import {
  TransactionResponse,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import { cn } from '../../../utils/classUtils';
import { displayAmount } from '../../../utils/CurrencyUtils';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { renderTransactionStatusByCountry } from '../../../utils/TransactionHelper';
import { isPersonalDealTransaction } from '../../../utils/TransactionUtils';
import { getTransactionStatusIcon } from '../../Gemini/Transaction/Header/GeminiTransactionStatusInfo';
import IconButton from '../../IconButton';
import TransactionTypeCell from '../../table/Cells/TransactionTypeCell';
import ZenTransactionPersonalDealLabel from '../../Zen/Transaction/Header/ZenTransactionPersonalDealLabel';
import {
  statusToVariant,
  variantToClassNameMap,
} from '../../Zen/Transaction/ZenTransactionStatus';
import ZenIconText from '../../Zen/ZenIconText';
import MarkAsCompliantStatus from '../MarkAsCompliant/MarkAsCompliantStatus';

interface PowerAuditTransactionHeaderProps {
  transaction: TransactionResponse;
  checklistName: string;
}
const PowerAuditTransactionHeader: React.FC<PowerAuditTransactionHeaderProps> = ({
  transaction,
  checklistName,
}) => {
  const lifecycleState = transaction.lifecycleState?.state;

  return (
    <div className='space-y-2 p-4'>
      <div className='flex flex-col'>
        <p className='font-zen-title text-xl'>{transaction.address?.oneLine}</p>
        <p className='font-zen-body text-sm'>{checklistName}</p>
      </div>
      <p className='font-zen-body'>{displayAmount(transaction.price)}</p>

      <div className='flex flex-row flex-wrap items-center'>
        <div className='pr-2'>
          <TransactionTypeCell
            type={TransactionResponseTransactionTypeEnum.Sale}
          />
        </div>
        <div className='py-1 pr-2'>
          <IconButton
            leftIcon={
              <FontAwesomeIcon icon={faUserTie} className='text-purple-400' />
            }
            variant='none'
            buttonStyle='bg-purple-600 bg-opacity-10 rounded-xl cursor-text'
            textStyle='text-purple-400 text-sm'
            label={`Rep: ${transaction.transactionOwner?.represents || 'N/A'}`}
          />
        </div>
        {isPersonalDealTransaction(transaction) && (
          <div className='pr-2'>
            <ZenTransactionPersonalDealLabel />
          </div>
        )}
      </div>
      {!!lifecycleState && (
        <div id='transaction-status' className='bg-primary-light rounded'>
          <div
            className={cn(
              variantToClassNameMap[
                safeEnumMapGet(statusToVariant, lifecycleState, 'default')
              ],
              'p-2',
              'rounded-md',
            )}
          >
            <div className='flex flex-row space-x-2 font-primary-regular text-sm'>
              <FontAwesomeIcon
                icon={getTransactionStatusIcon(lifecycleState)}
                className='text-base leading-5'
              />
              <span>
                {renderTransactionStatusByCountry(
                  lifecycleState,
                  transaction.country!,
                )}
              </span>
            </div>
          </div>
        </div>
      )}
      <MarkAsCompliantStatus transaction={transaction} />
      <ZenIconText
        icon={
          <FontAwesomeIcon
            icon={faBuildings}
            size='sm'
            className='bg-grey-200 p-2 rounded-full'
          />
        }
        text={`${transaction?.office?.name!}`}
      />
      <ZenIconText
        icon={
          <FontAwesomeIcon
            icon={faDollarSign}
            size='sm'
            className='bg-grey-200 p-2 w-3 rounded-full'
          />
        }
        text={
          <p>
            <span className='font-primary-medium text-lg'>List Price:</span>{' '}
            {displayAmount(transaction?.listingTransaction?.price, {
              hideCurrency: true,
            })}
          </p>
        }
      />
      <ZenIconText
        icon={
          <FontAwesomeIcon
            icon={faDollarSign}
            size='sm'
            className='bg-grey-200 p-2 w-3 rounded-full'
          />
        }
        text={
          <p>
            <span className='font-primary-medium text-lg'>Commission: </span>
            <span>{transaction?.grossCommissionPercentage!}</span> |
            <span>
              {displayAmount(transaction.grossCommission, {
                hideCurrency: true,
              })}
            </span>
          </p>
        }
      />
      {transaction.listing ? (
        <ZenIconText
          icon={
            <FontAwesomeIcon
              icon={faCalendarClock}
              size='sm'
              className='bg-grey-200 p-2 w-3.5 rounded-full'
            />
          }
          text={
            <p>
              <span className='font-primary-medium text-lg'>
                Listing Agreement Expiration Date:
              </span>{' '}
              {!!transaction?.listingExpirationDate
                ? DateTime.fromISO(transaction?.listingExpirationDate).toFormat(
                    'LL/dd/yyyy',
                  )
                : 'N/A'}
            </p>
          }
        />
      ) : (
        <>
          <ZenIconText
            icon={
              <FontAwesomeIcon
                icon={faCalendarCheck}
                size='sm'
                className='bg-grey-200 p-2 w-3 rounded-full'
              />
            }
            text={
              <p>
                <span className='font-primary-medium text-lg'>
                  Acceptance Date:
                </span>{' '}
                {DateTime.fromISO(
                  transaction?.contractAcceptanceDate!,
                ).toFormat('LL/dd/yyyy')}
              </p>
            }
          />
          <ZenIconText
            icon={
              <FontAwesomeIcon
                icon={faCalendarClock}
                size='sm'
                className='bg-grey-200 p-2 w-3.5 rounded-full'
              />
            }
            text={
              <p>
                <span className='font-primary-medium text-lg'>
                  Escrow Closing Date:
                </span>{' '}
                {DateTime.fromISO(
                  transaction?.skySlopeEscrowClosingDate!,
                ).toFormat('LL/dd/yyyy')}
              </p>
            }
          />
        </>
      )}
      <ZenIconText
        icon={
          <FontAwesomeIcon
            icon={faHelmetSafety}
            size='sm'
            className='bg-grey-200 p-2 rounded-full'
          />
        }
        text={
          <p>
            <span className='font-primary-medium text-lg'>Built Year:</span>{' '}
            {transaction?.yearBuilt!}
          </p>
        }
      />
    </div>
  );
};
export default PowerAuditTransactionHeader;
