import { PatchAgreementAmendmentDefinitionRequestStateEnum } from '../openapi/yenta';

export const fetchSubtitle = (
  state: PatchAgreementAmendmentDefinitionRequestStateEnum,
) => {
  switch (state) {
    case PatchAgreementAmendmentDefinitionRequestStateEnum.Active:
      return 'Are you sure you want to activate this amendment?';
    case PatchAgreementAmendmentDefinitionRequestStateEnum.Archived:
      return 'Are you sure you want to archive this amendment?';
  }
  return 'Are you sure you want to restore this amendment?';
};
