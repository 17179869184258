import { faCheck, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox as MCheckBox,
  CheckboxProps as MCheckBoxProps,
} from '@mantine/core';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

export type CheckboxSizeKeys = 'sm' | 'md' | 'lg';

export type CheckboxProps = Omit<MCheckBoxProps, 'size' | 'icon'> & {
  size?: CheckboxSizeKeys;
  icon?: IconDefinition;
};

type LabelStyles = {
  textSize: string;
  [key: string]: string;
};

const labelStyles: Record<CheckboxSizeKeys, LabelStyles> = {
  sm: {
    textSize: 'text-sm',
  },
  md: {
    textSize: 'text-base',
  },
  lg: {
    textSize: 'text-lg',
    paddingTop: 'pt-0.5',
  },
} as const;

type InnerStyles = {
  height: string;
  [key: string]: string;
};

const innerStyles: Record<CheckboxSizeKeys, InnerStyles> = {
  sm: {
    height: 'h-5',
  },
  md: {
    height: 'h-6',
  },
  lg: {
    height: 'h-8',
  },
} as const;

type CheckboxStyles = {
  width: string;
  height: string;
  [key: string]: string;
};

const checkboxStyles: Record<CheckboxSizeKeys, CheckboxStyles> = {
  sm: {
    width: 'w-4',
    height: 'h-4',
  },
  md: {
    width: 'w-5',
    height: 'h-5',
  },
  lg: {
    width: 'w-6',
    height: 'h-6',
  },
} as const;

const defaultStyles: MCheckBoxProps['classNames'] = {
  root: 'w-full',
  body: 'flex items-start',
  inner: 'relative flex justify-center items-center mr-2 h-6',
  input: cn(
    'cursor-pointer border ring-0 focus:ring-primary-light border-regent-400 rounded',
    'disabled:cursor-not-allowed disabled:!border-regent-400 disabled:!bg-grey-200',
  ),
  icon: 'absolute border rounded-md overflow-hidden',
  label: 'font-inter font-light text-primary-dark h-full',
  labelWrapper: 'flex flex-col justify-center h-full',
  error: '!text-red-600 !text-sm',
} as const;

const getSizeStyles = (
  size: CheckboxSizeKeys,
): MCheckBoxProps['classNames'] => {
  return {
    label: cn(Object.values(labelStyles[size])),
    inner: cn(Object.values(innerStyles[size])),
    input: cn(Object.values(checkboxStyles[size])),
    icon: cn(Object.values(checkboxStyles[size])),
  };
};

export const Checkbox: React.FC<CheckboxProps> = ({
  size = 'md',
  ...props
}) => {
  return (
    <MCheckBox
      unstyled
      {...props}
      icon={({ indeterminate, className }) => {
        return indeterminate ? null : (
          <span
            className={cn(
              'bg-primary-blue text-primary-light',
              className,
              props.classNames?.icon,
              'pointer-events-none',
              !props.checked && 'hidden',
            )}
          >
            <FontAwesomeIcon
              icon={props.icon ?? faCheck}
              className=' w-3/5 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2'
            />
          </span>
        );
      }}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        defaultStyles,
        {
          input: cn(props.error && '!border-coral-red'),
        },
        getSizeStyles(size),
        props.classNames,
      )}
    />
  );
};
