import { FieldValues, FieldPath, useController } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '../Checkbox';
import { HookFormControllerProps } from './types';

type HookFormCheckboxInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = HookFormControllerProps<TFieldValues, TName> & CheckboxProps;

export const HookFormCheckboxInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  onChange,
  ...props
}: HookFormCheckboxInputProps<TFieldValues, TName>) => {
  const {
    formState: { defaultValues },
    field,
    fieldState,
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Checkbox
      {...props}
      defaultChecked={defaultValues?.[props.name]}
      onChange={(e) => {
        field.onChange(e);
        onChange?.(e.target.value);
      }}
      checked={field.value}
      onBlur={field.onBlur}
      classNames={props.classNames}
      error={fieldState.error?.message}
    />
  );
};
