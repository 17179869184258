import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import NeoLeoAvatar from '../../assets/img/leo/neo-leo.png';

interface NeoLeoHeaderProps {
  toggleHistory(show: boolean): void;
  startNewSession(): void;
}

const NeoLeoHeader: React.FC<NeoLeoHeaderProps> = ({
  toggleHistory,
  startNewSession,
}) => {
  return (
    <div
      className='flex flex-row items-center justify-between py-4 px-5 bg-primary-navy'
      style={{
        boxShadow: '0px 4px 24px 0px rgba(2, 7, 32, 0.47)',
      }}
    >
      <div className='flex flex-row items-center'>
        <img
          className='w-10 h-10 mr-2 cursor-pointer'
          src={NeoLeoAvatar}
          onClick={startNewSession}
          alt='neo-leo-avatar'
        />
        <p className='font-poppins font-medium text-leo-header-title text-lg leading-5'>
          Leo
        </p>
      </div>
      <div>
        <button
          type='button'
          className='appearance-none'
          onClick={() => {
            toggleHistory(true);
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.LEO_MESSAGE_HISTORY_CLICK,
            );
          }}
        >
          <FontAwesomeIcon
            icon={faClockRotateLeft}
            className='text-base text-leo-response-action hover:text-white'
          />
        </button>
      </div>
    </div>
  );
};

export default NeoLeoHeader;
