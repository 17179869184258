import './global-setup';
import { datadogRum } from '@datadog/browser-rum';
import { MantineProvider } from '@mantine/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'rc-slider/assets/index.css';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import AmplitudeService from '../src/services/AmplitudeService';
import App from './App';
import './assets/css/tooltip.css';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import config from './config';
import reportWebVitals from './reportWebVitals';
import Route500 from './routes/Route500';
import AnalyticsService from './services/AnalyticsService';
import store from './slices/store';
import { isDatadogErrorEventValid } from './utils/DatadogHelper';
import Logger from './utils/Logger';
import { getTracingUrls } from './utils/datadog/datadogUtils';
import { errorReload } from './utils/ErrorUtils';

// Vite loading error handling
// See: https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  // Set to 3 retries to avoid infinite reloads
  errorReload(3);
});

pdfjs.GlobalWorkerOptions.workerSrc = `${config.reactAppHostUrl}/js/pdf.worker.min.js`;

AmplitudeService.initialize();
AnalyticsService.instance().initialize();

const enabledReleaseStages = ['production', 'stage', 'play'];
const isErrorTrackingEnabled = enabledReleaseStages.includes(
  config.datadog.releaseStage,
);

Logger.debug(
  'Running version ',
  config.datadog.deployVersion,
  ' in ',
  config.datadog.releaseStage,
  ' environment.',
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true, // explicit default value, refetch on mount if stale
    },
  },
});

datadogRum.init({
  applicationId: config.datadog.applicationId,
  clientToken: config.datadog.clientToken,
  site: 'datadoghq.com',
  service: 'bolt',
  env: config.datadog.releaseStage,
  version: config.datadog.deployVersion,
  sessionSampleRate: config.datadog.sessionSampleRate,
  sessionReplaySampleRate: config.datadog.sessionReplaySampleRate,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  enableExperimentalFeatures: ['feature_flags'],
  allowedTracingUrls: getTracingUrls(),

  beforeSend: (event) => {
    const canTriggerError =
      isErrorTrackingEnabled && isDatadogErrorEventValid(event);

    if (canTriggerError) {
      return true;
    }

    return false;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Route500}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId={config.googleSSOClientId}>
            <MantineProvider>
              <App />
              {!window.Cypress && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position='bottom-right'
                />
              )}
            </MantineProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
