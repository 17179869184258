import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface ZenCollapsibleTableRowProps {
  name: string;
  NameComponent?: React.ReactElement;
  value?: string;
  ValueComponent?: React.ReactElement;
  defaultCollapsed?: boolean;
  showBorder?: boolean;
  payoutExplanation?: React.ReactElement | undefined;
}

const ZenCollapsibleTableRow: React.FC<ZenCollapsibleTableRowProps> = ({
  name,
  NameComponent,
  value,
  ValueComponent,
  defaultCollapsed = true,
  showBorder = true,
  children,
  payoutExplanation,
}) => {
  const [isCollapsed, setCollapsed] = useState(defaultCollapsed);

  return (
    <div>
      {showBorder && <div className='border-b' />}
      <div className='flex justify-between items-center p-2 font-zen-body text-zen-dark-7 text-sm'>
        <span className='flex'>
          {NameComponent ?? name}
          {payoutExplanation ?? payoutExplanation}
          {children ? (
            isCollapsed ? (
              <FontAwesomeIcon
                icon={regular('angle-up')}
                className='mt-1 ml-1 cursor-pointer'
                onClick={() => setCollapsed(false)}
                aria-label='real-arrow-up'
                fontSize={15}
              />
            ) : (
              <FontAwesomeIcon
                icon={regular('angle-down')}
                className='mt-1 ml-1 cursor-pointer'
                onClick={() => setCollapsed(true)}
                aria-label='real-arrow-down'
                fontSize={15}
              />
            )
          ) : null}
        </span>
        <span className='font-semibold'>{ValueComponent ?? value}</span>
      </div>
      {isCollapsed && children}
    </div>
  );
};

export default ZenCollapsibleTableRow;
