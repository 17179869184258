import {
  faArrowRight,
  faCircleXmark,
  faRotateLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { BsCalendar4, BsFileEarmark, BsPeople } from 'react-icons/bs';
import { FiUserPlus } from 'react-icons/fi';
import { IoIosRefresh } from 'react-icons/io';
import { TbDiscount2 } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as HandShake } from '../../../assets/icons/zen/handshake-simple.svg';
import { ReactComponent as House } from '../../../assets/icons/zen/house.svg';
import { ReactComponent as Chat } from '../../../assets/icons/zen/transaction/chatReferral.svg';
import { ReactComponent as Delete } from '../../../assets/icons/zen/transaction/delete2.svg';
import { ReactComponent as HandMoney } from '../../../assets/icons/zen/transaction/hand-give-money.svg';
import { ReactComponent as MoneyGraph } from '../../../assets/icons/zen/transaction/money-graph.svg';
import { ReactComponent as ProjectDiagram } from '../../../assets/icons/zen/transaction/project-diagram.svg';
import { ReactComponent as AddAgent } from '../../../assets/icons/zen/transaction/user-coat-add.svg';
import { useNavigateIfNotCurrentRoute } from '../../../hooks/useNavigateIfNotCurrentRoute';
import {
  AttachedFeeValueFeeTypeEnum,
  ParticipantValueRoleEnum,
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import { OfficeResponseAdditionalFeesAndRebatesEnum } from '../../../openapi/yenta';
import {
  recalculateCommissionModal,
  setActiveTransition,
  toggleAddCommissionParticipant,
  toggleAdditionalFee,
  toggleCommissionSplit,
  toggleEditTransactionAddressMlsEscrow,
  toggleShowChangeFirmDateModal,
} from '../../../slices/QuickActionSlice';
import {
  approveZeroCommissionDealForTransaction,
  clearTransactionTeamId,
  undoClosingTransaction,
  undoTerminationRequestTransaction,
  updateListingStatus,
} from '../../../slices/TransactionSlice';
import {
  FeatureFlagTypeEnum,
  ParticipantType,
  RootState,
} from '../../../types';
import {
  getCommissionDocumentsName,
  isAgentUSInternalReferralTransaction,
  isCanadaTransaction,
} from '../../../utils/TransactionHelper';
import {
  isFeeTypeEnabled,
  isListingNotActionable,
} from '../../../utils/TransactionUtils';
import AdminOnly from '../../auth/AdminOnly';
import AdminOrBrokerOnly from '../../auth/AdminOrBrokerOnly';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import ZenSimpleConfirmationModal from '../Modal/ZenSimpleConfirmationModal';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenEditDealInformationForm from './ZenEditDealInformationForm';
import ZenEditTransactionAddressMlsEscrow from './ZenEditTransactionAddressMlsEscrow';
import ZenEditTransactionButton from './ZenEditTransactionButton';
import ZenSetTeamIdModal from './ZenSetTeamIdModal';

interface ZenTransactionQuickActionSidebarModalProps {
  transaction: TransactionResponse;
  isOpen: boolean;
  onClose(): void;
  secondaryTransitions: TransactionLifecycleStateValue[];
  setShowTerminationTransactionModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const ZenTransactionQuickActionSidebarModal: React.FC<ZenTransactionQuickActionSidebarModalProps> = ({
  transaction,
  isOpen,
  onClose,
  secondaryTransitions,
  setShowTerminationTransactionModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const navigateIfNotCurrentRoute = useNavigateIfNotCurrentRoute();
  const [openDealInfoSidebar, setOpenDealInfoSidebar] = useState<boolean>(
    false,
  );
  const isListing = transaction.listing;
  const {
    auth: { isAdmin, isBroker },
    quickAction: { showEditTransactionAddressMlsEscrow },
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);

  const isAgent = !(isAdmin || isBroker);

  const officeDetails = officeDetailById[transaction?.office?.id!];

  const [
    showUndoClosingTransactionModal,
    setShowUndoClosingTransactionModal,
  ] = useState(false);
  const [reverting, setReverting] = useState<boolean>(false);

  const [
    showZeroCommissionDealModal,
    setShowZeroCommissionDealModal,
  ] = useState<boolean>(false);
  const [approvingCommission, setApprovingCommission] = useState<boolean>(
    false,
  );

  const [
    showUndoTerminationRequestedModal,
    setShowUndoTerminationRequestedModal,
  ] = useState<boolean>(false);
  const [
    undoTerminateRequestedTransactionLoading,
    setUndoTerminateRequestedTransactionLoading,
  ] = useState<boolean>(false);

  const [
    showReactivatingTransactionModal,
    setShowReactivatingTransactionModal,
  ] = useState(false);
  const [reactivating, setReactivating] = useState(false);

  const [
    showResetTeamConfirmationModal,
    setShowResetTeamConfirmationModal,
  ] = useState(false);

  const [resettingTeam, setResettingTeam] = useState(false);

  const [openSetTeamIdModal, setOpenSetTeamIdModal] = useState(false);

  const preActions = () => {
    navigateIfNotCurrentRoute(
      isListing
        ? `/listings/${transaction.id}/detail`
        : `/transactions/${transaction.id}/detail`,
    );
  };

  const terminatedTransaction =
    secondaryTransitions.find(
      (item) =>
        item.state === TransactionLifecycleStateValueStateEnum.Terminated,
    ) || undefined;

  const terminationRequestedTransition = secondaryTransitions.find((item) => {
    return (
      item.state ===
      TransactionLifecycleStateValueStateEnum.TerminationRequested
    );
  });

  const terminatedTransition = secondaryTransitions.find((item) => {
    return item.state === TransactionLifecycleStateValueStateEnum.Terminated;
  });

  const isTransactionOnOrAfterClosingState = (
    state: TransactionLifecycleStateValueStateEnum,
  ) =>
    [
      TransactionLifecycleStateValueStateEnum.PaymentAccepted,
      TransactionLifecycleStateValueStateEnum.PaymentScheduled,
      TransactionLifecycleStateValueStateEnum.Settled,
      TransactionLifecycleStateValueStateEnum.WaitingOnPayment,
      TransactionLifecycleStateValueStateEnum.Closed,
    ].includes(state);

  const isListingTerminated = transaction.listing && transaction.terminated;
  const isTerminateDisabled = isListingNotActionable(transaction);

  const isTerminationRequestedTransaction =
    transaction.lifecycleState?.state ===
    TransactionLifecycleStateValueStateEnum.TerminationRequested;

  const isInternalReferralTransaction =
    transaction?.transactionType ===
    TransactionResponseTransactionTypeEnum.InternalReferral;

  return (
    <>
      <ZenSidebarModal
        title={isListing ? 'Edit Listing' : 'Edit Transaction'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className='p-4 space-y-2'>
          {!isInternalReferralTransaction && (
            <div className='flex flex-col space-y-2'>
              <div className='font-zen-body text-base text-zen-dark-9 font-medium'>
                Basic Information
              </div>

              <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-1'>
                <ZenEditTransactionButton
                  leftIcon={<House />}
                  onClick={() => {
                    preActions();
                    dispatch(toggleEditTransactionAddressMlsEscrow(true));
                  }}
                  label='Edit Address, MLS &amp; Escrow'
                />
                <ZenEditTransactionButton
                  leftIcon={<HandShake />}
                  label='Edit Deal Information'
                  onClick={() => {
                    preActions();
                    setOpenDealInfoSidebar(true);
                  }}
                />
              </div>
            </div>
          )}

          <div className='flex flex-col space-y-2'>
            <div className='font-zen-body text-base text-zen-dark-9 font-medium'>
              Participants
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-1'>
              {!isListing && (
                <ZenEditTransactionButton
                  leftIcon={<AddAgent />}
                  label='Add Agent'
                  onClick={() => {
                    preActions();
                    dispatch(
                      toggleAddCommissionParticipant({
                        agentType: ParticipantType.EXTERNAL_ENTITY,
                        isOpen: true,
                      }),
                    );
                  }}
                />
              )}

              <ZenEditTransactionButton
                leftIcon={<FiUserPlus />}
                label='Add Participant'
                onClick={() => {
                  preActions();
                  dispatch(
                    toggleAddCommissionParticipant({
                      agentType: ParticipantType.EXTERNAL_ENTITY,
                      isOpen: true,
                    }),
                  );
                }}
              />

              {!isListing && (
                <>
                  <ZenEditTransactionButton
                    leftIcon={<Chat />}
                    label='Add Referral'
                    onClick={() => {
                      preActions();
                      dispatch(
                        toggleAddCommissionParticipant({
                          agentType: ParticipantType.EXTERNAL_ENTITY,
                          role: ParticipantValueRoleEnum.ReferringAgent,
                          isOpen: true,
                        }),
                      );
                    }}
                  />

                  {!isCanadaTransaction(transaction) && (
                    <ZenEditTransactionButton
                      leftIcon={<ProjectDiagram />}
                      label='Add Opcity'
                      onClick={() => {
                        preActions();
                        dispatch(
                          toggleAddCommissionParticipant({
                            agentType: ParticipantType.OP_CITY,
                            isOpen: true,
                          }),
                        );
                      }}
                    />
                  )}

                  {isFeeTypeEnabled(
                    officeDetails!,
                    OfficeResponseAdditionalFeesAndRebatesEnum.TransactionCoordinatorFee,
                  ) && (
                    <ZenEditTransactionButton
                      leftIcon={<BsPeople />}
                      label='Add Transaction Coordinator'
                      onClick={() => {
                        preActions();
                        dispatch(
                          toggleAdditionalFee({
                            isOpen: true,
                            preselectedFeeType:
                              AttachedFeeValueFeeTypeEnum.TransactionCoordinator,
                          }),
                        );
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {!isListing && (
            <>
              <div className='flex flex-col space-y-2'>
                <div className='font-zen-body text-base text-zen-dark-9 font-medium'>
                  Commissions and Rebates
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-1'>
                  <ZenEditTransactionButton
                    leftIcon={<HandMoney />}
                    label='Adjust Commission (%) / splits'
                    onClick={() => {
                      preActions();
                      dispatch(toggleCommissionSplit(true));
                    }}
                  />

                  {isFeeTypeEnabled(
                    officeDetails!,
                    OfficeResponseAdditionalFeesAndRebatesEnum.AdditionalCommission,
                  ) && (
                    <ZenEditTransactionButton
                      leftIcon={<MoneyGraph />}
                      label='Add Additional Commission'
                      onClick={() => {
                        preActions();
                        dispatch(
                          toggleAdditionalFee({
                            isOpen: true,
                            preselectedFeeType:
                              AttachedFeeValueFeeTypeEnum.AdditionalCommission,
                          }),
                        );
                      }}
                    />
                  )}

                  {isFeeTypeEnabled(
                    officeDetails!,
                    OfficeResponseAdditionalFeesAndRebatesEnum.Rebate,
                  ) && (
                    <ZenEditTransactionButton
                      leftIcon={<TbDiscount2 />}
                      label='Add Rebate'
                      onClick={() => {
                        preActions();
                        dispatch(
                          toggleAdditionalFee({
                            isOpen: true,
                            preselectedFeeType:
                              AttachedFeeValueFeeTypeEnum.Rebate,
                          }),
                        );
                      }}
                    />
                  )}

                  <ZenEditTransactionButton
                    leftIcon={<IoIosRefresh />}
                    label='Recalculate Commission'
                    onClick={() => {
                      onClose();
                      preActions();
                      dispatch(recalculateCommissionModal(true));
                    }}
                  />
                </div>
              </div>
              {!isAgentUSInternalReferralTransaction(transaction, isAgent) && (
                <div className='flex flex-col space-y-2'>
                  <div className='font-zen-body text-base text-zen-dark-9 font-medium'>
                    Other Tasks
                  </div>

                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-1'>
                    <ZenEditTransactionButton
                      leftIcon={<BsFileEarmark />}
                      label={`View / Share ${getCommissionDocumentsName(
                        transaction.country!,
                      )}`}
                      onClick={() => {
                        onClose();
                        history.push(`/transactions/${transaction.id}/cda`);
                      }}
                    />

                    {isCanadaTransaction(transaction) && (
                      <ZenEditTransactionButton
                        leftIcon={<BsCalendar4 />}
                        label='Change Firm Date'
                        onClick={() => {
                          preActions();
                          dispatch(toggleShowChangeFirmDateModal(true));
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              <AdminOnly>
                <div className='flex flex-col space-y-2'>
                  <div className='font-zen-body text-base text-zen-dark-9 font-medium'>
                    Teams
                  </div>

                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-1'>
                    <ZenEditTransactionButton
                      leftIcon={<BsPeople />}
                      label='Reset Team'
                      onClick={() => {
                        setShowResetTeamConfirmationModal(true);
                      }}
                    />
                    <ZenEditTransactionButton
                      leftIcon={<BsPeople />}
                      label='Set Team ID'
                      onClick={() => {
                        setOpenSetTeamIdModal(true);
                        onClose();
                      }}
                    />
                  </div>
                </div>
              </AdminOnly>
            </>
          )}
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 p-4 gap-5'>
          {isListingTerminated && (
            <ZenEditTransactionButton
              type='primary_outline'
              iconVariant='primary'
              leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
              label='Reactivate Listing'
              onClick={() => {
                setShowReactivatingTransactionModal(true);
              }}
            />
          )}

          {isTransactionOnOrAfterClosingState(
            transaction.lifecycleState?.state!,
          ) && (
            <FeatureFlagEnabledOnly
              flag={FeatureFlagTypeEnum.UNDO_TRANSACTION_CLOSING}
            >
              <AdminOnly>
                <ZenEditTransactionButton
                  type='primary_outline'
                  iconVariant='primary'
                  leftIcon={<FontAwesomeIcon icon={faCircleXmark} />}
                  label='Undo Closing'
                  onClick={() => {
                    setShowUndoClosingTransactionModal(true);
                  }}
                />
              </AdminOnly>
            </FeatureFlagEnabledOnly>
          )}

          {transaction.zeroCommissionDeal && (
            <FeatureFlagEnabledOnly
              flag={FeatureFlagTypeEnum.APPROVE_ZERO_COMMISSION_DEAL}
            >
              <AdminOnly>
                <ZenEditTransactionButton
                  type='primary_outline'
                  iconVariant='primary'
                  leftIcon={<FontAwesomeIcon icon={faCircleXmark} />}
                  label='Approve Zero Commission Deal'
                  onClick={() => {
                    setShowZeroCommissionDealModal(true);
                  }}
                />
              </AdminOnly>
            </FeatureFlagEnabledOnly>
          )}

          {terminatedTransition && (
            <AdminOrBrokerOnly>
              <ZenEditTransactionButton
                type='danger_no_outline'
                iconVariant='danger'
                disabled={isTerminateDisabled}
                leftIcon={<Delete className='text-zen-danger' />}
                label={
                  transaction?.listing
                    ? 'Terminate Listing'
                    : 'Terminate Transaction'
                }
                onClick={() => {
                  onClose();
                  preActions();
                  dispatch(setActiveTransition(terminatedTransaction));
                }}
              />
            </AdminOrBrokerOnly>
          )}

          {terminationRequestedTransition && (
            <ZenEditTransactionButton
              type='danger_no_outline'
              iconVariant='danger'
              disabled={isTerminateDisabled}
              leftIcon={<Delete className='text-zen-danger' />}
              label='Request Termination'
              onClick={() => {
                setShowTerminationTransactionModal(true);
              }}
            />
          )}

          {isTerminationRequestedTransaction && (
            <ZenEditTransactionButton
              type='danger_no_outline'
              iconVariant='danger'
              leftIcon={
                <FontAwesomeIcon
                  icon={faRotateLeft}
                  className='text-zen-danger'
                />
              }
              label='Undo Request Termination'
              onClick={() => {
                setShowUndoTerminationRequestedModal(true);
              }}
            />
          )}
        </div>

        <ZenSimpleConfirmationModal
          isOpen={showReactivatingTransactionModal}
          variant='warning'
          title='Reactivate listing'
          customIcon={<FontAwesomeIcon icon={faRotateLeft} />}
          isSubmitting={reactivating}
          subtitle='Are you sure you would like to reactivate the listing?'
          onClose={() => setShowReactivatingTransactionModal(false)}
          onConfirm={async () => {
            setReactivating(true);
            onClose();
            preActions();
            await dispatch(
              updateListingStatus(
                transaction.id!,
                TransactionLifecycleStateValueStateEnum.ListingActive,
              ),
            );
            setReactivating(false);
            setShowReactivatingTransactionModal(false);
          }}
        />

        <ZenSimpleConfirmationModal
          isOpen={showZeroCommissionDealModal}
          variant='warning'
          title='Approve Zero Commission Deal?'
          customIcon={<FontAwesomeIcon icon={faRotateLeft} />}
          isSubmitting={approvingCommission}
          subtitle='Are you sure you would like to approve this transaction as a zero-commission deal?'
          onClose={() => setShowZeroCommissionDealModal(false)}
          onConfirm={async () => {
            setApprovingCommission(true);
            await dispatch(
              approveZeroCommissionDealForTransaction(transaction.id!),
            );
            setApprovingCommission(false);
            setShowZeroCommissionDealModal(false);
          }}
        />

        <ZenSimpleConfirmationModal
          isOpen={showUndoTerminationRequestedModal}
          variant='warning'
          customIcon={<FontAwesomeIcon icon={faRotateLeft} />}
          title={`Undo Termination Requested ${
            !!isListing ? 'Listing' : 'Transaction'
          }`}
          subtitle={`Are you sure you would like to undo the termination requested ${
            !!isListing ? 'listing' : 'transaction'
          }?`}
          onClose={() => setShowUndoTerminationRequestedModal(false)}
          isSubmitting={undoTerminateRequestedTransactionLoading}
          onConfirm={async () => {
            setUndoTerminateRequestedTransactionLoading(true);
            await dispatch(
              undoTerminationRequestTransaction(transaction?.id!, !!isListing),
            );
            setUndoTerminateRequestedTransactionLoading(false);
            setShowTerminationTransactionModal(false);
          }}
        />

        <ZenSimpleConfirmationModal
          isOpen={showUndoClosingTransactionModal}
          variant='warning'
          title='Undo Closing'
          customIcon={<FontAwesomeIcon icon={faRotateLeft} />}
          isSubmitting={reverting}
          subtitle='Are you sure you would like to undo the closing? This will delete all pending payments and equity contributions.'
          onClose={() => setShowUndoClosingTransactionModal(false)}
          onConfirm={async () => {
            setReverting(true);
            await dispatch(undoClosingTransaction(transaction.id!));
            setReverting(false);
            setShowUndoClosingTransactionModal(false);
          }}
        />

        <ZenSimpleConfirmationModal
          isOpen={showResetTeamConfirmationModal}
          variant='warning'
          title='Reset Team'
          customIcon={<FontAwesomeIcon icon={faRotateLeft} />}
          subtitle='Are you sure you would like to reset the team?'
          onClose={() => setShowResetTeamConfirmationModal(false)}
          onConfirm={async () => {
            setResettingTeam(true);
            await dispatch(clearTransactionTeamId(transaction.id!));
            setResettingTeam(false);
            setShowResetTeamConfirmationModal(false);
            onClose();
          }}
          isSubmitting={resettingTeam}
        />
      </ZenSidebarModal>
      {openDealInfoSidebar && (
        <ZenEditDealInformationForm
          isOpen
          onClose={() => setOpenDealInfoSidebar(false)}
          transaction={transaction}
        />
      )}
      {showEditTransactionAddressMlsEscrow && (
        <ZenEditTransactionAddressMlsEscrow
          isOpen
          onClose={() => dispatch(toggleEditTransactionAddressMlsEscrow(false))}
          transaction={transaction}
        />
      )}
      {openSetTeamIdModal && (
        <ZenSetTeamIdModal
          isOpen
          onClose={() => setOpenSetTeamIdModal(false)}
          transaction={transaction}
        />
      )}
    </>
  );
};

export default ZenTransactionQuickActionSidebarModal;
