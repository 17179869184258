import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  fetchAuthUserDetail,
  handleLogout,
  loginUserOrThrow,
} from '../../slices/AuthSlice';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { AppDispatch, ErrorCode } from '../../types';
import { LoginRequest, MfaControllerApi } from '../../openapi/keymaker';
import ErrorService from '../../services/ErrorService';
import { getKeymakerConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { setAuthCookie } from '../../utils/AuthUtils';
import { getApiErrorMessage } from '../../utils/ErrorUtils';
import { constructLink } from '../../utils/UrlUtils';
import useQueryParams from '../../hooks/useQueryParams';

export const useLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useMutation({
    mutationFn: async (values: LoginRequest) =>
      await dispatch(loginUserOrThrow(values)),
  });
};

export const use2FAResendCode = () => {
  const [enableResendCode, setEnableResendCode] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const mutationResult = useBaseMutation({
    queryKey: queryKeys.login.resendCode.queryKey,
    mutationFn: async () => {
      try {
        setEnableResendCode(true);
        setTimeout(() => {
          setEnableResendCode(false);
        }, 10000);
        await new MfaControllerApi(getKeymakerConfiguration()).sendMfaSms();
      } catch (e) {
        const errorCode = ErrorService.getErrorCode(e);
        if (
          errorCode === ErrorCode.UNAUTHORIZED ||
          errorCode === ErrorCode.FORBIDDEN
        ) {
          dispatch(handleLogout());
        }
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to resend authentication code to phone number during 2fa sign in',
          e,
        );
      }
    },
  });

  return {
    mutateAsync: mutationResult.mutateAsync,
    isLoading: mutationResult.isLoading,
    enableResendCode,
    setEnableResendCode,
  };
};

interface Query {
  redirectTo?: string;
}

export const useSignInWithMfa = () => {
  const [code, setCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const [serverError, setServerError] = useState<string>();
  const { redirectTo }: Query = useQueryParams<Query>();
  const history = useHistory();

  const dispatch = useDispatch<AppDispatch>();
  const mutationResult = useBaseMutation({
    queryKey: queryKeys.login.twoFactor.queryKey,
    mutationFn: async (code: string) => {
      try {
        const { data } = await new MfaControllerApi(
          getKeymakerConfiguration(),
        ).signInWithMfa({
          code: +code,
        });
        setAuthCookie(data.accessToken!);
        await dispatch(fetchAuthUserDetail());
      } catch (e) {
        setCode('');
        setInvalidCode(true);
        if (
          e?.response?.data['com.real.commons.apierror.ApiError']?.message ===
          undefined
        ) {
          setServerError('You session has expired. Please log back in.');
          setTimeout(() => {
            history.replace(constructLink('/login', { redirectTo }));
          }, 2000);
        } else {
          setServerError(getApiErrorMessage(e));
        }
        ErrorService.notifyIgnoreAuthErrors(
          'Unable to signing with two factor authentication',
          e,
          {
            data: {
              code,
            },
          },
        );
      }
    },
  });

  return {
    code,
    invalidCode,
    isLoading: mutationResult.isLoading,
    mutateAsync: mutationResult.mutateAsync,
    serverError,
    setCode,
    setInvalidCode,
  };
};
