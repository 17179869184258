import {
  faArrowDownToBracket,
  faArrowRightFromBracket,
  faPen,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { CommissionAdvanceResponse } from '../../../openapi/arrakis';
import {
  downloadCommissionAdvancesDoc,
  fetchAgentCommissionAdvances,
} from '../../../slices/AgentSlice';
import { AppDispatch, IPaginateReq } from '../../../types';
import { displayAmount } from '../../../utils/CurrencyUtils';
import AdminOnly from '../../auth/AdminOnly';
import Hover from '../../Hover';
import IconButton from '../../IconButton';
import ZenAgentCommissionAdvancesStatusCell from '../../table/Cells/ZenAgentCommissionAdvancesStatusCell';
import TextStrictCaseColumnFilter from '../../table/Filters/TextStrictCaseColumnFilter';
import ZenResourceIndexContainer from '../../Zen/Containers/ZenResourceIndexContainer';

interface ZenAgentCommissionAdvancesTableProps {
  agentId: string;
  setEditingCommissionAdvances: (data: CommissionAdvanceResponse) => void;
  key: number;
}

export const columns: Column<CommissionAdvanceResponse>[] = [
  {
    Header: 'Status',
    accessor: 'advanceStatus',
    disableFilters: true,
    Cell: ({ value }) => (
      <ZenAgentCommissionAdvancesStatusCell status={value!} />
    ),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    disableFilters: true,
    Cell: ({ value }) => displayAmount(value),
  },
  {
    Header: 'Company Name',
    accessor: 'companyName',
    disableFilters: true,
  },
  {
    Header: 'Transaction Address',
    id: 'transactionAddress',
    accessor: 'transaction',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => row.original.transaction?.address?.oneLine,
  },
  {
    Header: 'Transaction Code',
    accessor: 'transaction',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value: transaction }) =>
      transaction ? (
        <Link to={`/transactions/${transaction.id}`}>
          <IconButton
            leftIcon={
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                size='lg'
                className='mr-0.5 -my-1'
              />
            }
            variant='outline'
            buttonStyle='border-gray-100 rounded-lg'
            label={transaction.code}
          />
        </Link>
      ) : (
        'N/A'
      ),
  },
  {
    Header: 'Reference Number',
    accessor: 'referenceNumber',
    Cell: ({ value }) => value || 'N/A',
    disableFilters: true,
  },
  {
    Header: 'Notes',
    id: 'notes',
    accessor: 'notes',
    disableFilters: true,
    disableSortBy: true,
  },
];

const ZenAgentCommissionAdvancesTable: React.FC<ZenAgentCommissionAdvancesTableProps> = ({
  agentId,
  setEditingCommissionAdvances,
  key,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const getDownloadUrl = useCallback(
    async (id: string) => {
      let url = await dispatch(downloadCommissionAdvancesDoc(id!));
      if (url) window.open(url, '_blank');
    },
    [dispatch],
  );

  const columnsWithAction: Array<
    Column<CommissionAdvanceResponse>
  > = useMemo(() => {
    const commissionAdvancesColumns: Column<CommissionAdvanceResponse>[] = [
      ...columns,
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'id',
        disableSortBy: true,
        Filter: TextStrictCaseColumnFilter,
        Cell: ({ row }: { row: Row<CommissionAdvanceResponse> }) => (
          <div className='flex flex-row items-center space-x-2'>
            <Hover
              hoverComponent={
                <div className='text-dark px-2'>Download Agreement</div>
              }
              config={{ trigger: 'hover', placement: 'top' }}
            >
              <IconButton
                buttonStyle='bg-zen-dark-4 rounded-full h-8'
                variant='none'
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowDownToBracket}
                    className='text-black'
                    size='sm'
                    title='Download Agreement'
                  />
                }
                onClick={() => getDownloadUrl(row.original.id!)}
              />
            </Hover>
            <AdminOnly>
              <Hover
                hoverComponent={<div className='text-dark px-2'>Edit</div>}
                config={{ trigger: 'hover', placement: 'top' }}
              >
                <IconButton
                  buttonStyle='bg-zen-dark-4 rounded-full h-8'
                  variant='none'
                  leftIcon={
                    <FontAwesomeIcon icon={faPen} size='xs' title='Edit' />
                  }
                  onClick={() => setEditingCommissionAdvances(row.original)}
                />
              </Hover>
            </AdminOnly>
          </div>
        ),
      },
    ];
    return commissionAdvancesColumns;
  }, [getDownloadUrl, setEditingCommissionAdvances]);

  const fetchAdvance = useCallback(
    async (req: IPaginateReq<CommissionAdvanceResponse>) => {
      const res = await dispatch(fetchAgentCommissionAdvances(agentId, req));

      return {
        data: res?.commissionAdvances || [],
        total: res?.totalCount || 0,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agentId, dispatch, key],
  );

  return (
    <ZenResourceIndexContainer<CommissionAdvanceResponse>
      resourceName='commission advance'
      columns={columnsWithAction}
      initialSort={{ createdAt: 'desc' }}
      pageSize={10}
      standalone={false}
      fetchData={fetchAdvance}
      headerVariant='light'
      hideFilters
      paginationContainerClassNames='px-4'
    />
  );
};

export default ZenAgentCommissionAdvancesTable;
