import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  allTimezones,
  ITimezoneOption,
  useTimezoneSelect,
} from 'react-timezone-select';
import { useDebounceValue } from 'usehooks-ts';
import { DateTime } from 'luxon';
import {
  DayHourRange,
  DayHourRangeDayOfWeekEnum,
  HourRange,
  UserAvailabilityResponse,
} from '../../../openapi/yenta';
import { saveLoggedInAgentDetails } from '../../../slices/AgentSlice';
import { showSuccessToast } from '../../../slices/ToastNotificationSlice';
import { AppDispatch, ISelectOption, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { getISelectOptionDefaultValue } from '../../../utils/FormUtils';
import ZenControlledCheckboxV2 from '../../Zen/Input/ZenControlledCheckboxV2';
import ZenControlledTimeSelect, {
  getTimeLabel,
} from '../../Zen/Input/ZenControlledTimeSelect';
import ZenControlledTimezoneSelect from '../../Zen/Input/ZenControlledTimezoneSelect';
import { useUpdateDoNotDisturb } from '../../../query/roar/useRoar';

const weekdayMap: DayHourRangeDayOfWeekEnum[] = [
  DayHourRangeDayOfWeekEnum.Monday,
  DayHourRangeDayOfWeekEnum.Tuesday,
  DayHourRangeDayOfWeekEnum.Wednesday,
  DayHourRangeDayOfWeekEnum.Thursday,
  DayHourRangeDayOfWeekEnum.Friday,
  DayHourRangeDayOfWeekEnum.Saturday,
  DayHourRangeDayOfWeekEnum.Sunday,
];

const weekDayLabelMap: string[] = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
];

interface IFormData {
  timezone: ITimezoneOption;
  availability: Array<{
    on?: boolean;
    startTime?: ISelectOption;
    endTime?: ISelectOption;
  }>;
}

interface ZenWorkingHoursFormProps {
  agentId: string;
}

const ZenWorkingHoursForm: React.FC<ZenWorkingHoursFormProps> = ({
  agentId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const agentDetail = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data,
  );
  const userAvailability = useMemo(
    () => (agentDetail?.availability || {}) as UserAvailabilityResponse,
    [agentDetail?.availability],
  );

  const { parseTimezone } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: allTimezones,
  });

  const scheduleMap = useMemo(() => {
    const map = {} as Record<DayHourRangeDayOfWeekEnum, HourRange | undefined>;
    agentDetail?.availability?.officeSchedule?.forEach((schedule) => {
      map[schedule.dayOfWeek] = schedule.hourRange;
    });
    return map;
  }, [agentDetail?.availability?.officeSchedule]);

  const {
    control,
    watch,
    handleSubmit,
    clearErrors,
    formState,
    getValues,
    setError,
  } = useForm<IFormData>({
    defaultValues: {
      timezone: parseTimezone(
        agentDetail?.availability?.timeZone || DateTime.local().zoneName,
      ),
      availability: weekdayMap.map((day) => {
        const val = scheduleMap[day];
        return {
          on: !!val,
          startTime: getISelectOptionDefaultValue(
            val?.startTime,
            getTimeLabel(val?.startTime),
          ),
          endTime: getISelectOptionDefaultValue(
            val?.endTime,
            getTimeLabel(val?.endTime),
          ),
        };
      }),
    },
  });

  const { fields, replace } = useFieldArray({
    control,
    name: 'availability',
  });

  const watchFormData = watch();
  const ref = useRef<IFormData>(watchFormData);

  const [debouncedFormData] = useDebounceValue(watchFormData, 2000);

  const { mutate } = useUpdateDoNotDisturb(agentId);

  const handleClickCopy = () => {
    const availability = watch('availability');
    const mondayField = availability[0];
    replace(
      fields.map((_, index) => {
        // skip sunday and saturday
        if (
          index === availability.length - 2 ||
          index === availability.length - 1
        ) {
          return availability[index];
        }
        return {
          startTime: mondayField.startTime,
          endTime: mondayField.endTime,
          on: mondayField.on,
        };
      }),
    );
  };

  useEffect(() => {
    // for some reason error object is not getting cleared automatically
    // when form is valid and there are no validation errors
    if (formState.isValid) {
      clearErrors();
    }
  }, [clearErrors, formState.isValid]);

  const validateData = useCallback(() => {
    for (let index = 0; index < ref.current.availability.length; index++) {
      let currentItem = watch(`availability.${index}`);
      if (currentItem.on && !currentItem.startTime) {
        setError(`availability.${index}.startTime`, { message: '' });
      } else {
        clearErrors(`availability.${index}.startTime`);
      }
      if (currentItem.on && !currentItem.endTime) {
        setError(`availability.${index}.endTime`, { message: '' });
      } else {
        clearErrors(`availability.${index}.endTime`);
      }
    }
  }, [clearErrors, setError, watch]);

  useEffect(() => {
    if (!isEqual(ref.current, debouncedFormData)) {
      ref.current = {
        timezone: debouncedFormData.timezone,
        availability: debouncedFormData.availability.map((el) => ({
          on: el.on,
          startTime: el.startTime,
          endTime: el.endTime,
        })),
      };

      const officeSchedule: DayHourRange[] = debouncedFormData.availability
        .map((day, index) => {
          if (!day.on || !day.startTime || !day.endTime) {
            return (undefined as unknown) as DayHourRange;
          }
          return {
            dayOfWeek: weekdayMap[index],
            hourRange: {
              startTime: day.startTime.value,
              endTime: day.endTime.value,
            },
          };
        })
        .filter(Boolean);

      mutate(
        {
          ...userAvailability,
          timeZone: debouncedFormData.timezone.value,
          officeSchedule,
        },
        {
          onSuccess: (data) => {
            dispatch(
              saveLoggedInAgentDetails({
                ...agentDetail,
                availability: data.availability,
              }),
            );
            if (isEmpty(formState.errors)) {
              dispatch(showSuccessToast('Settings Saved'));
            }
          },
        },
      );
      validateData();
    }
  }, [
    agentDetail,
    debouncedFormData,
    dispatch,
    formState.errors,
    handleSubmit,
    mutate,
    userAvailability,
    validateData,
    watch,
  ]);

  const disabled = agentDetail?.availability?.doNotDisturb;

  return (
    <form className='p-4'>
      <ZenControlledTimezoneSelect<IFormData, 'timezone'>
        control={control}
        name='timezone'
        label='Time Zone:'
        disabled={disabled}
      />
      <p className='mt-5 font-zen-body font-semibold text-zen-dark-9'>
        Availability
      </p>
      <div className='mt-2 flex flex-col space-y-5'>
        {fields.map((field, index) => {
          const isActive = watch(`availability.${index}.on`);
          const isMondayField =
            weekdayMap[index] === DayHourRangeDayOfWeekEnum.Monday;
          const mondayField = watch(`availability.0`);

          return (
            <div
              key={field.id}
              className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:items-center md:space-x-2.5 lg:space-x-4'
            >
              <div className='w-full md:w-18 lg:w-20 flex justify-between items-center'>
                <ZenControlledCheckboxV2
                  control={control}
                  name={`availability.${index}.on`}
                  label={weekDayLabelMap[index]}
                  labelStyles='text-sm font-medium ml-2 md:ml-4'
                  disabled={disabled}
                />
                <button
                  aria-label='copy'
                  type='button'
                  disabled={
                    disabled || !mondayField.startTime || !mondayField.endTime
                  }
                  className={cn(
                    'p-2 visible md:hidden rounded-full bg-regent-200 text-rezen-blue-600 active:opacity-60 disabled:cursor-not-allowed disabled:text-grey-400',
                    isMondayField ? 'visible' : 'hidden',
                  )}
                  onClick={handleClickCopy}
                >
                  <FontAwesomeIcon icon={faCopy} width={24} height={24} />
                </button>
              </div>
              {isActive ? (
                <div className='flex-1 space-x-2 w-full flex items-center md:space-x-1.5 lg:space-x-3'>
                  <ZenControlledTimeSelect
                    control={control}
                    shouldUnregister={false}
                    name={`availability.${index}.startTime`}
                    placeholder='09:00 AM'
                    disabled={disabled}
                    max={getValues(`availability.${index}.endTime`)?.value}
                    menuPlacement='auto'
                  />
                  <FontAwesomeIcon
                    icon={faHorizontalRule}
                    className='hidden md:inline md:w-3 text-grey-300'
                  />
                  <ZenControlledTimeSelect
                    control={control}
                    shouldUnregister={false}
                    name={`availability.${index}.endTime`}
                    disabled={disabled}
                    placeholder='05:00 PM'
                    min={getValues(`availability.${index}.startTime`)?.value}
                    menuPlacement='auto'
                  />
                  <div className='lg:pl-14 xl:pl-20 md:flex justify-end hidden'>
                    <button
                      aria-label='copy'
                      type='button'
                      disabled={
                        disabled ||
                        !mondayField.startTime ||
                        !mondayField.endTime
                      }
                      className={cn(
                        'p-2 invisible rounded-full bg-regent-200 text-rezen-blue-600 active:opacity-60 disabled:cursor-not-allowed disabled:text-grey-400',
                        isMondayField ? 'md:visible' : 'invisible',
                      )}
                      onClick={handleClickCopy}
                    >
                      <FontAwesomeIcon icon={faCopy} width={24} height={24} />
                    </button>
                  </div>
                </div>
              ) : (
                <p className='p-2 border border-white text-grey-500 font-inter font-light'>
                  Unavailable
                </p>
              )}
            </div>
          );
        })}
      </div>
    </form>
  );
};

export default ZenWorkingHoursForm;
