import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import store from '../slices/store';
import ErrorService from '../services/ErrorService';
import { AsyncResponse, ErrorCode } from '../types';
import { showAndReportErrors } from '../utils/ErrorUtils';

const useAsyncRequest = <T>(
  name: string,
  fetchAPI: () => Promise<AxiosResponse<T>>,
  errorMetadata: { [key: string]: object } = {},
): AsyncResponse<T> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorCode | null>(null);
  const [data, setData] = useState<T>();

  useEffect(() => {
    const handleFetch = async () => {
      setLoading(true);

      try {
        const res = await fetchAPI();
        setData(res.data);
      } catch (e) {
        if (showAndReportErrors(e?.response)) {
          store.dispatch(showApiErrorModal(e));
          store.dispatch(
            showErrorToast(
              `We had a problem fetching ${name}`,
              'Please try again in a few moments.',
            ),
          );
        }

        setError(ErrorService.getErrorCode(e));
        ErrorService.notify(`Error fetching ${name}`, e, errorMetadata);
      } finally {
        setLoading(false);
      }
    };

    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, fetch]);

  return { data, loading, name, error };
};

export default useAsyncRequest;
