import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  openLeo,
  callNotificationAction,
  fetchNotifications,
  dismissNotification,
} from '../../../slices/LeoSlice';
import { RootState } from '../../../types';
import {
  LeoNotification,
  LeoNotificationAction,
  LeoNotificationActionTypeEnum,
} from '../../../utils/LeoUtils';
import NeoLeoNotification from './NeoLeoNotification';

interface NeoLeoNotificationsContainerProps {
  showAllNotifications: boolean;
  setShowAllNotifications: (show: boolean) => void;
}

const NeoLeoNotificationsContainer: FC<NeoLeoNotificationsContainerProps> = ({
  setShowAllNotifications,
  showAllNotifications,
}) => {
  const dispatch = useDispatch();
  const INTRO_NOTIFICATIONS_LENGTH = 3;
  const { notifications } = useSelector((state: RootState) => state.leo);
  const { userDetail } = useSelector((state: RootState) => state.auth);
  const notificationsLength = notifications.length;

  const handleNotificationCardPress = (notification: LeoNotification) => {
    dispatch(
      openLeo(
        userDetail?.id!,
        {
          title: notification.title,
          prompt: notification.title,
          category: notification.type,
        },
        [
          {
            type: '',
            items: [
              {
                id: notification?.id!,
              },
            ],
          },
        ],
      ),
    );
  };

  const notificationActionHandler = (
    notification: LeoNotification,
    action: LeoNotificationAction,
  ) => {
    if (action?.url) {
      window.open(action.url, '_blank');
      return;
    }
    dispatch(callNotificationAction(notification.id, action.text));
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  if (!notifications.length) {
    return null;
  }

  return (
    <div className='h-full overflow-y-scroll scrollbar-hide flex flex-col'>
      {showAllNotifications && (
        <>
          <div
            className='flex items-center gap-2 my-6 cursor-pointer'
            onClick={() => setShowAllNotifications(false)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className='text-aqua' />
            <p className='text-aqua'>Go Back</p>
          </div>
          <p className='font-medium text-sm text-regent-600 mb-2'>
            Attention Required (3/{notificationsLength})
          </p>
        </>
      )}
      <div className='flex flex-col gap-2'>
        {notifications
          .slice(
            0,
            showAllNotifications ? undefined : INTRO_NOTIFICATIONS_LENGTH,
          )
          .map((notification) => {
            const primaryAction = notification.actions.find(
              (el) => el.type === LeoNotificationActionTypeEnum.PRIMARY,
            );
            const secondaryAction = notification.actions.find(
              (el) => el.type === LeoNotificationActionTypeEnum.SECONDARY,
            );
            return (
              <NeoLeoNotification
                key={notification.id}
                showActionButtons={!!notification.actions.length}
                showPrimaryAction={!!primaryAction}
                showSecondaryAction={!!secondaryAction}
                primaryActionText={primaryAction?.text}
                primaryActionHandler={() =>
                  notificationActionHandler(notification, primaryAction!)
                }
                secondaryActionHandler={() =>
                  notificationActionHandler(notification, secondaryAction!)
                }
                onNotificationCardPress={() =>
                  handleNotificationCardPress(notification)
                }
                onDismissNotification={() =>
                  dispatch(dismissNotification(notification.id))
                }
                isUrgent={notification.priority === 1}
                {...notification}
              />
            );
          })}
        {!showAllNotifications && (
          <p
            className='text-aqua text-sm font-medium mt-3.5 cursor-pointer w-fit ml-auto'
            onClick={() => setShowAllNotifications(true)}
          >
            See All{' '}
            {INTRO_NOTIFICATIONS_LENGTH > notificationsLength
              ? ''
              : `(${INTRO_NOTIFICATIONS_LENGTH}/${notificationsLength})`}
          </p>
        )}
      </div>
    </div>
  );
};

export default NeoLeoNotificationsContainer;
