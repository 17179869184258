import {
  BankAccountApplicationsApi,
  CreateCreditAccountApplication,
  CreateCustomerToken,
  CreateDebitAccountApplication,
  CustomerTokenApi,
  CustomerTokenDto,
  EligibleOfferingsDto,
  OfferingApi,
  WalletApi,
  WalletDto,
} from '../../openapi/wallet';
import ErrorService from '../../services/ErrorService';
import { getWalletConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { useSimpleQuery } from '../base/useSimpleQuery';

export const useWallet = () => {
  const fetchWalletDetails = async () => {
    const { data } = await new WalletApi(
      getWalletConfiguration(),
    ).getMyWallet();

    return data;
  };

  const queryResult = useSimpleQuery<WalletDto>({
    queryKey: queryKeys.wallet.details.queryKey,
    queryFn: fetchWalletDetails,
    options: {
      toastErrorMessage: 'Failed to fetch wallet!',
      logErrorMessage: 'Failed to fetch wallet!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useFetchEligibleOfferings = () => {
  const fetchCreditDetails = async () => {
    const { data } = await new OfferingApi(
      getWalletConfiguration(),
    ).getEligibleOfferings();

    return data;
  };

  const queryResult = useSimpleQuery<EligibleOfferingsDto>({
    queryKey: queryKeys.wallet._def,
    queryFn: fetchCreditDetails,
    options: {
      toastErrorMessage: 'Failed to fetch offerings!',
      logErrorMessage: 'Failed to fetch offerings!',
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};

export const useDebitApplication = () => {
  const createDebitApplication = async (
    application: CreateDebitAccountApplication,
  ) => {
    const { data } = await new BankAccountApplicationsApi(
      getWalletConfiguration(),
    ).createDebitAccountApplication(application);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet._def,
    mutationFn: createDebitApplication,
  });
};

export const useCreditApplication = () => {
  const createCreditApplication = async (
    application: CreateCreditAccountApplication,
  ) => {
    const { data } = await new BankAccountApplicationsApi(
      getWalletConfiguration(),
    ).createCreditAccountApplication(application);

    return data;
  };

  return useBaseMutation({
    queryKey: queryKeys.wallet._def,
    mutationFn: createCreditApplication,
  });
};

export const useCreateToken = (createCustomerToken: CreateCustomerToken) => {
  const createToken = async () => {
    const { data } = await new CustomerTokenApi(
      getWalletConfiguration(),
    ).createReadOnlyToken(createCustomerToken);

    return data;
  };

  const queryResult = useSimpleQuery<CustomerTokenDto>({
    queryKey: queryKeys.wallet.token.queryKey,
    queryFn: createToken,
    options: {
      toastErrorMessage: 'Failed to create customer token!',
      logErrorMessage: 'Failed to create customer token!',
      enabled: !!createCustomerToken.bankingPartnershipId,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  });

  return {
    data: queryResult.data,
    isLoading: queryResult.isLoading || queryResult.isFetching,
    errorCode: queryResult.error
      ? ErrorService.getErrorCode(queryResult.error)
      : undefined,
  };
};
