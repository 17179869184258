import classNames from 'classnames';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { FormFieldTooltipIndexProps, RootState } from '../../../types';
import { getCountryCode } from '../../../utils/StringUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export interface ZenControlledPhoneNumberInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  labelClassName?: string;
  subLabel?: string;
  readOnly?: boolean;
  placeholder?: string;
  isRequired?: boolean;
  disableDropdown?: boolean;
  onChangeSpy?: (value: string) => void;
  onBlurSpy?: () => void;
}

const ZenControlledPhoneNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  labelClassName,
  subLabel,
  readOnly,
  placeholder,
  tooltipIndex,
  shouldUnregister = true,
  isRequired = false,
  disableDropdown = false,
  onChangeSpy,
  onBlurSpy,
  ...rest
}: ZenControlledPhoneNumberInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState(false);
  const {
    auth: { userDetail, isAdmin },
  } = useSelector((state: RootState) => state);

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur },
        fieldState: { error, invalid },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label htmlFor={name}>
              <span
                className={classNames(
                  'inline-block font-zen-body font-semibold',
                  invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  labelClassName,
                )}
              >
                {label}
              </span>
              {!!subLabel && (
                <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                  {subLabel}
                </span>
              )}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}

          <PhoneInput
            onlyCountries={['us', 'ca']}
            country={getCountryCode(isAdmin, userDetail?.accountCountry!)}
            disableDropdown={disableDropdown}
            value={value}
            onChange={(value) => {
              onChange(value);
              if (onChangeSpy) {
                onChangeSpy(value);
              }
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setFocus(false);
              onBlur();
              if (onBlurSpy) {
                onBlurSpy();
              }
            }}
            placeholder={placeholder}
            containerClass='group'
            inputClass={classNames(
              '!appearance-none w-full !h-10 focus:!outline-none focus:!ring-0 !font-zen-body !font-normal !rounded-lg',
              {
                '!bg-gray-50': readOnly,
              },
              focus ? '!border-zen-dark-9' : '!border-zen-dark-5',
              value && '!text-zen-dark-9',
              invalid && '!border-zen-danger',
            )}
            buttonClass={classNames(
              '!appearance-none !border !rounded-l-lg focus:!outline-none focus:!ring-0 !font-zen-body !font-normal',
              {
                '!bg-gray-50': readOnly,
              },
              focus ? '!border-zen-dark-9' : '!border-zen-dark-5',
              invalid && '!border-zen-danger',
            )}
            inputProps={{
              readOnly,
              name,
              type: 'text',
              'data-tooltip-index': tooltipIndex,
              autoFocus: true,
            }}
            inputStyle={{
              width: '100%',
              backgroundColor: readOnly ? 'rgb(250 250 250)' : '',
            }}
            jumpCursorToEnd={false}
          />

          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledPhoneNumberInput;
