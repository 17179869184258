import { useState } from 'react';
import { useSelector } from 'react-redux';
import RealTitle from '../../../assets/img/gemini/realtitle.svg';
import stocks from '../../../assets/img/stocks_18px_two_tone.svg';
import tipalti from '../../../assets/img/gemini/tipalti-logo.svg';
import { InviteResponse } from '../../../openapi/avalon';
import { AgentResponse } from '../../../openapi/yenta';
import { FeatureFlagTypeEnum, RootState, YesNoType } from '../../../types';
import {
  getMSDynamicsStatus,
  MSDynamicStatusEnum,
} from '../../../utils/MSDynamicHelper';
import EditAgentJoinRealEmailSidebarModal from '../../Agent/EditAgentJoinRealEmailSidebarModal';
import AdminOnly from '../../auth/AdminOnly';
import AdminOrSuperAdmin from '../../auth/AdminOrSuperAdmin';
import FeatureFlagEnabledOnly from '../../FeatureFlagEnabledOnly';
import InviteJointVentureModalForm from '../../JointVenture/InviteJointVentureModalForm';
import ZenJointVentureSideBarModel from '../../JointVenture/ZenJointVentureSideBarModel';
import GeminiPill from '../GeminiPill';
import GeminiYesOrNoPill from '../GeminiYesOrNoPill';
import GeminiJVInvitationStatusCard from './GeminiJVInvitationStatusCard';
import GeminiProfileHeaderRowCell from './GeminiProfileHeaderRowCell';

interface GeminiStatusHeaderInfoProps {
  detail: AgentResponse | undefined;
  invitedJointVentures?: InviteResponse[];
}
const MSDynamicsStatusToClassNameMap: Record<MSDynamicStatusEnum, string> = {
  'Not Started': 'text-yellow-300',
  Connected: 'text-green-400',
  Invalid: 'text-red-500',
};

const Divider = () => (
  <div className='h-4.5 w-[1px] bg-grey-200 mx-1.5 sm:block hidden' />
);

const GeminiStatusHeaderInfo: React.FC<GeminiStatusHeaderInfoProps> = ({
  detail,
  invitedJointVentures,
}) => {
  const { agentPayableInfo } = useSelector(
    (state: RootState) => state.agentDetail,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [
    isEditJoinRealEmailOpen,
    setIsEditJoinRealEmailOpen,
  ] = useState<boolean>(false);
  const [
    isJoinVentureInvitationModalOpen,
    setIsJoinVentureInvitationModalOpen,
  ] = useState<boolean>(false);
  const agentMSDynamicsStatus = getMSDynamicsStatus(agentPayableInfo.data);

  const getJointVentureText = (
    invitedJointVentures: InviteResponse[] | undefined,
  ) => {
    if (invitedJointVentures && invitedJointVentures.length) {
      const jointVentureList = invitedJointVentures.map(
        (item) => item.jointVenture,
      );
      if (jointVentureList && jointVentureList.length) {
        const tierList = invitedJointVentures.map((item) => item.tier);

        return `JVS : ${jointVentureList.length} | TIERS:  ${tierList.length}`;
      }
    }
    return 'JVS : 0 | TIERS:  0';
  };

  return (
    <div className='px-5 py-2.5 rounded-lg bg-regent-900 font-zen-body-2 text-white space-y-1.5'>
      <GeminiProfileHeaderRowCell
        label='Shareworks ID'
        value={`#${detail?.shareworksId}`}
        dataTestId='shareworks-id'
      />
      <div className='flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-1'>
        <GeminiProfileHeaderRowCell
          leftComponentIcon={stocks}
          label='Stock Purchase Plan Opted In:'
          rightComponent={
            <GeminiYesOrNoPill
              value={
                !!detail?.planMembership?.optInToEquityPlan
                  ? YesNoType.YES
                  : YesNoType.NO
              }
              noBackground
            />
          }
          dataTestId='stock-purchase-plan-opted-in'
        />
        <AdminOnly>
          <Divider />
          <GeminiProfileHeaderRowCell
            leftComponentIcon={tipalti}
            label='Tipalti Integrated?'
            rightComponent={
              <GeminiYesOrNoPill
                value={detail?.tipaltiConfirmed ? YesNoType.YES : YesNoType.NO}
                noBackground
              />
            }
            dataTestId='tipalti-integrated'
          />
        </AdminOnly>
      </div>
      <AdminOnly>
        <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.REAL_TITLE}>
          <div className='flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-1'>
            <GeminiProfileHeaderRowCell
              leftComponentIcon={RealTitle}
              label='Joint Venture:'
              rightComponent={
                <button onClick={() => setIsOpen(true)}>
                  <GeminiPill
                    label={getJointVentureText(invitedJointVentures)}
                    containerClassNames='bg-grey-200 text-grey-600 ml-1'
                  />
                </button>
              }
            />
            {!invitedJointVentures?.length && (
              <GeminiJVInvitationStatusCard
                status='NOT_INVITED'
                jointVentureTitle=''
                onClick={() => setIsJoinVentureInvitationModalOpen(true)}
              />
            )}
            {isOpen && (
              <ZenJointVentureSideBarModel
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                jointVentures={invitedJointVentures}
                detail={detail}
              />
            )}
          </div>
        </FeatureFlagEnabledOnly>
      </AdminOnly>
      <AdminOrSuperAdmin>
        <GeminiProfileHeaderRowCell
          label='Payment System:'
          rightComponent={
            <GeminiPill
              label={agentMSDynamicsStatus}
              containerClassNames={
                MSDynamicsStatusToClassNameMap[agentMSDynamicsStatus]
              }
            />
          }
          dataTestId='payment-system'
        />
      </AdminOrSuperAdmin>
      {isEditJoinRealEmailOpen && (
        <EditAgentJoinRealEmailSidebarModal
          agentDetail={detail!}
          isOpen={isEditJoinRealEmailOpen}
          onClose={() => setIsEditJoinRealEmailOpen(false)}
        />
      )}
      {isJoinVentureInvitationModalOpen && (
        <InviteJointVentureModalForm
          title={`Invite ${detail?.fullName} to Join`}
          agentId={detail?.id!}
          onClose={() => setIsJoinVentureInvitationModalOpen(false)}
        />
      )}
    </div>
  );
};
export default GeminiStatusHeaderInfo;
