import { useState } from 'react';
import LegalComponent from '../components/Zen/Legal/LegalComponent';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import ZenPasswordGuard from './ZenPasswordGuard';

export const LEGAL_PASSWORD = 'playbold';

interface ZenLegalIndexRouteProps {}

const ZenLegalIndexRoute: React.FC<ZenLegalIndexRouteProps> = () => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <ZenRoute title='Legal'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Legal', url: '/legal' },
        ]}
      >
        {!authenticated ? (
          <ZenPasswordGuard
            password={LEGAL_PASSWORD}
            setAuthenticated={setAuthenticated}
          />
        ) : (
          <LegalComponent />
        )}
      </ZenPageLayout>
    </ZenRoute>
  );
};

export default ZenLegalIndexRoute;
