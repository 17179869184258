import { EventSourcePolyfill } from 'event-source-polyfill';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CallConnectedSvg from '../../assets/img/roar/call-connected.svg';
import config from '../../config';
import { useFetchTransactionById } from '../../query/transaction/useTransaction';
import { RootState } from '../../types';
import { getAuthCookie } from '../../utils/AuthUtils';
import { Button } from '../commons/Button';
import { Modal } from '../commons/Modal';

type SSECallEvent = {
  container?: { id?: string; type?: 'TRANSACTION' };
  callee?: {
    name: string;
    userId: string;
    phone: string;
    email: string;
  };
  caller?: {
    name: string;
    userId: string;
    phone: string;
    email: string;
  };
};

const VoiceCallConnectedModal: React.FC = () => {
  const userId = useSelector((state: RootState) => state.auth.userDetail?.id)!;

  const [eventDetails, setEventDetails] = useState<SSECallEvent | undefined>();

  const transactionId = eventDetails?.container?.id;

  const { isLoading, data } = useFetchTransactionById(transactionId!, {
    logErrorMessage: 'Failed to fetch transaction for auto open transaction',
    toastErrorMessage: 'Failed to fetch transaction',
    skipShowToastOnError: true,
    enabled: eventDetails?.container?.type === 'TRANSACTION' && !!transactionId,
  });

  useEffect(() => {
    let eventSource: EventSourcePolyfill | undefined;

    if (userId) {
      const sseUrl = `${config.apiBasePath.yada}/api/v1/voice/calls/sse-subscribe?calleeUserId=${userId}`;
      eventSource = new EventSourcePolyfill(sseUrl, {
        headers: { Authorization: `Bearer ${getAuthCookie()}` },
        heartbeatTimeout: 300000, // 5 min - keep alive
      });
      eventSource.onmessage = (event) => {
        if (event.data.length) {
          const data = JSON.parse(event.data) as SSECallEvent;
          if (data) {
            setEventDetails(data as SSECallEvent);
          }
        }
      };
    }

    return () => {
      eventSource?.close();
    };
  }, [userId]);

  const txAddr = data?.address?.oneLine;

  if (!eventDetails || !txAddr || isLoading) {
    return null;
  }

  return (
    <Modal
      opened={!!eventDetails?.container?.id}
      onClose={() => setEventDetails(undefined)}
      size='536px'
      classNames={{ content: 'rounded-lg' }}
    >
      <Modal.Header color='white' className='border-b border-gray-300'>
        <p className='font-inter font-medium text-primary-dark text-center text-lg'>
          Would you like to navigate to this transaction?
        </p>
      </Modal.Header>
      <Modal.Content>
        <div className='flex flex-col justify-center items-center space-y-4 text-center'>
          <img src={CallConnectedSvg} alt='call-icon' />
          <p className='text-grey-600'>
            You are now ROARing with{' '}
            <span className='font-semibold text-primary-dark'>
              {eventDetails?.caller?.name}
            </span>{' '}
            about:
          </p>
          <p className='font-semibold text-primary-dark'>{txAddr}</p>
        </div>
      </Modal.Content>
      <Modal.Footer className='border-t-0 p-6'>
        <Button
          onClick={() => setEventDetails(undefined)}
          variant='outline'
          color='black'
          className='font-medium w-full max-w-[172px]'
        >
          No
        </Button>
        <Button
          onClick={() => {
            window.open(`/transactions/${transactionId}/detail`, '_blank');
            setEventDetails(undefined);
          }}
          className='font-medium w-full max-w-[172px]'
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VoiceCallConnectedModal;
