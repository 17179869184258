import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
  faCloudArrowDown,
  faMagnifyingGlass,
  faPaperclip,
} from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form-v7';
import { AppDispatch, Mapping, RootState } from '../../types';
import ResourceContainer from '../ResourceContainer';
import DropboxEmptyFilesImg from '../../assets/img/dropbox/EmptyTray.png';
import {
  FileMetadataDtoOriginTypeEnum,
  FileResponse,
} from '../../openapi/dropbox';
import {
  deleteFile,
  downloadDropboxFileByVersion,
  fetchDropbox,
  renameFile,
  uploadFile,
} from '../../slices/DropboxSlice';
import Button from '../Button';
import ConfirmationModal from '../ConfirmationModal';
import ControlledDraggableDocumentUploadInput from '../ControlledDraggableDocumentUploadInput';
import DefaultEmpty from '../DefaultEmpty';
import DefaultLoader from '../DefaultLoader';
import ZenControlledTextInput from '../Zen/Input/ZenControlledTextInput';
import ZenButton from '../Zen/ZenButton';
import ZenFixedDataTable from '../Zen/ZenFixedDataTable';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenDocumentDateCell from './cell/ZenDocumentDateCell';
import ZenFileRowCell from './cell/ZenFileRowCell';
import ZenFileSelectionOrAction from './cell/ZenFileSelectionOrAction';
import EmptyDropbox from './EmptyDropbox';
import RenameFileModal from './RenameFileModal';

interface FormData {
  files: File[];
  search: string | undefined;
  fileIdToAttach: string | undefined;
}

interface ZenManageFileCabinetSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  dropboxId: string;
  secondaryDropboxId?: string;
  attach?(files: FileResponse[], uploadAndAttach?: boolean): void;
  isMultiple?: boolean;
}

interface DropboxFileResponse extends FileResponse {
  dropboxId: string;
}

const ZenManageFileCabinetSidebarModal: React.FC<ZenManageFileCabinetSidebarModalProps> = ({
  isOpen,
  onClose,
  dropboxId,
  secondaryDropboxId,
  attach,
  isMultiple,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isDownloadingDocument, setIsDownloadingDocument] = useState<
    Mapping<boolean>
  >({});
  const {
    dropbox: { dropboxById, loadingById, deletingById, updatingFileById },
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const dropbox = dropboxById[dropboxId];
  const secondaryDropbox = secondaryDropboxId
    ? dropboxById[secondaryDropboxId]
    : undefined;
  const isPrimaryDropboxLoading = loadingById[dropboxId];
  const isSecondaryDropboxLoading = secondaryDropboxId
    ? loadingById[secondaryDropboxId]
    : false;
  const isLoading = isPrimaryDropboxLoading || isSecondaryDropboxLoading;
  const primaryDropboxFiles = dropbox?.files?.filter(
    (file) =>
      file.metadata?.originType !== FileMetadataDtoOriginTypeEnum.VoiceS3,
  );
  const secondaryDropboxFiles = secondaryDropbox?.files?.filter(
    (file) =>
      file.metadata?.originType! !== FileMetadataDtoOriginTypeEnum.VoiceS3,
  );

  const {
    control,
    watch,
    setValue,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormData>();
  const [files, search] = watch(['files', 'search']);

  const [
    attachingPrimaryDropboxFiles,
    setAttachingPrimaryDropboxFiles,
  ] = useState<FileResponse[]>([]);
  const [
    attachingSecondaryDropboxFiles,
    setAttachingSecondaryDropboxFiles,
  ] = useState<FileResponse[]>([]);
  const [deletingFile, setDeletingFile] = useState<DropboxFileResponse>();
  const [renamingFile, setRenamingFile] = useState<DropboxFileResponse>();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleViewFile = useCallback(
    async (file: FileResponse) => {
      setIsDownloadingDocument((map) => ({ ...map, [file.id!]: true }));
      await dispatch(
        downloadDropboxFileByVersion(file?.id!, file?.currentVersion?.id!),
      );
      setIsDownloadingDocument((map) => ({ ...map, [file.id!]: false }));
    },
    [dispatch],
  );

  const primaryTablecolumns = useMemo<Column<FileResponse>[]>(
    () => [
      {
        Header: '',
        id: 'id',
        accessor: 'id',
        Cell: ({ value: fileId, row: { original } }) => (
          <ZenFileSelectionOrAction
            isSelectionEnabled={!!attach}
            isSelected={
              !!attachingPrimaryDropboxFiles.find(
                (selectedFile) => selectedFile.id === fileId,
              )
            }
            onRename={() =>
              setRenamingFile({ ...original, dropboxId: dropboxId })
            }
            onDelete={() =>
              setDeletingFile({ ...original, dropboxId: dropboxId })
            }
            onSelectionChange={() =>
              setAttachingPrimaryDropboxFiles((files) => {
                if (!isMultiple) {
                  return [original];
                }

                const isFileSelected = files.find(
                  (selectedFile) => selectedFile.id === fileId,
                );

                if (isFileSelected) {
                  return files.filter(
                    (selectedFile) => selectedFile.id !== fileId,
                  );
                }

                return [...files, original];
              })
            }
            disabled={attachingSecondaryDropboxFiles.length > 0 || isSubmitting}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Name / Description',
        Cell: ({ row: { original } }) => <ZenFileRowCell file={original} />,
        accessor: 'filename',
      },
      {
        Header: 'Date Uploaded',
        accessor: 'createdAt',
        Cell: ({ value }) => <ZenDocumentDateCell date={value!} />,
      },
      {
        Header: '',
        id: 'path',
        accessor: 'path',
        Cell: ({ row: { original } }) => (
          <ResourceContainer
            loading={isDownloadingDocument[original.id!]!}
            isEmpty={false}
            resourceName='download-item'
            LoaderComponent={
              <div className='pr-4'>
                <DefaultLoader noPadding iconSize='small' />
              </div>
            }
          >
            <button
              onClick={() => {
                handleViewFile(original);
              }}
              className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                className='text-zen-dark-8'
                aria-label={original.id}
              />
            </button>
          </ResourceContainer>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [
      attach,
      attachingPrimaryDropboxFiles,
      attachingSecondaryDropboxFiles.length,
      dropboxId,
      isMultiple,
      isDownloadingDocument,
      handleViewFile,
      isSubmitting,
    ],
  );

  const secondaryTablecolumns = useMemo<Column<FileResponse>[]>(
    () => [
      {
        Header: '',
        id: 'id',
        accessor: 'id',
        Cell: ({ value: fileId, row: { original } }) => (
          <ZenFileSelectionOrAction
            isSelectionEnabled={!!attach}
            isSelected={
              !!attachingSecondaryDropboxFiles.find(
                (selectedFile) => selectedFile.id === fileId,
              )
            }
            onRename={() =>
              setRenamingFile({ ...original, dropboxId: secondaryDropboxId! })
            }
            onDelete={() =>
              setDeletingFile({ ...original, dropboxId: secondaryDropboxId! })
            }
            onSelectionChange={() =>
              setAttachingSecondaryDropboxFiles((files) => {
                if (!isMultiple) {
                  return [original];
                }

                const isFileSelected = files.find(
                  (selectedFile) => selectedFile.id === fileId,
                );

                if (isFileSelected) {
                  return files.filter(
                    (selectedFile) => selectedFile.id !== fileId,
                  );
                }

                return [...files, original];
              })
            }
            disabled={attachingPrimaryDropboxFiles.length > 0 || isSubmitting}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Name / Description',
        Cell: ({ row: { original } }) => <ZenFileRowCell file={original} />,
        accessor: 'filename',
      },
      {
        Header: 'Date Uploaded',
        accessor: 'createdAt',
        Cell: ({ value }) => <ZenDocumentDateCell date={value!} />,
      },
      {
        Header: '',
        id: 'path',
        accessor: 'path',
        Cell: ({ row: { original } }) => (
          <ResourceContainer
            loading={isDownloadingDocument[original.id!]!}
            isEmpty={false}
            resourceName='download-item'
            LoaderComponent={
              <div className='pr-4'>
                <DefaultLoader noPadding iconSize='small' />
              </div>
            }
          >
            <button
              onClick={() => {
                handleViewFile(original);
              }}
              className='bg-zen-light-gray-2 hover:bg-zen-dark-4 rounded-full min-w-[35px] min-h-[35px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer'
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                className='text-zen-dark-8'
                aria-label={original.id}
              />
            </button>
          </ResourceContainer>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [
      attach,
      attachingPrimaryDropboxFiles.length,
      attachingSecondaryDropboxFiles,
      handleViewFile,
      isDownloadingDocument,
      isMultiple,
      secondaryDropboxId,
      isSubmitting,
    ],
  );

  const uploadFiles = useCallback(
    async (filesToUpload: File[]) => {
      setIsUploading(true);

      await Promise.all(
        filesToUpload.map(async (fileToUpload) =>
          dispatch(uploadFile(dropboxId, userDetail?.id!, fileToUpload)),
        ),
      );

      setIsUploading(false);
      setValue('files', []);
    },
    [dispatch, dropboxId, setValue, userDetail?.id],
  );

  const handleAttach = () => {
    if (attachingSecondaryDropboxFiles.length > 0) {
      return attach?.(attachingSecondaryDropboxFiles, true);
    } else {
      return attach?.(attachingPrimaryDropboxFiles, false);
    }
  };

  const handleDeleteFile = async () => {
    const isDeleted = await dispatch(
      deleteFile(deletingFile?.dropboxId!, deletingFile?.id!),
    );

    if (isDeleted) {
      setDeletingFile(undefined);
    }
  };

  const handleRenameFile = async (name: string) => {
    const isUpdated = await dispatch(
      renameFile(renamingFile?.dropboxId!, renamingFile?.id!, name),
    );

    if (isUpdated) {
      setRenamingFile(undefined);
    }
  };

  useEffect(() => {
    if (dropboxId) {
      dispatch(fetchDropbox(dropboxId));
    }
    if (secondaryDropboxId) {
      dispatch(fetchDropbox(secondaryDropboxId));
    }
  }, [dispatch, dropboxId, secondaryDropboxId]);

  useEffect(() => {
    if (files?.length) {
      uploadFiles(files);
    }
  }, [files, uploadFiles]);

  return (
    <ZenSidebarModal title='File Cabinet' isOpen={isOpen} onClose={onClose}>
      <div className='mx-4 my-6'>
        <ZenButton
          label='Back'
          type='button'
          variant='secondary-light-outline'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              className='text-zen-dark-9'
            />
          }
          onClick={() => onClose()}
        />
      </div>
      <div className='px-4 pt-2 pb-4'>
        {!!attach ? (
          <p className='text-base font-zen-body font-medium text-zen-dark-9'>
            Select one or more documents to attach to this checklist
          </p>
        ) : (
          <ControlledDraggableDocumentUploadInput<FormData, 'files'>
            name='files'
            control={control}
            placeholder={`Drag & Drop \nor\n Click here to upload`}
            accept='*/*'
            isUploading={isUploading}
            multiple
          />
        )}
        <ResourceContainer
          loading={isLoading}
          isEmpty={
            !primaryDropboxFiles?.length && !secondaryDropboxFiles?.length
          }
          resourceName='Dropbox'
          EmptyComponent={<EmptyDropbox />}
        >
          <div className='mt-4 mb-10'>
            <ZenControlledTextInput<FormData, 'search'>
              name='search'
              control={control}
              placeholder='Search by keywords or document name'
              startAdornment={
                <div className='flex w-full h-full items-center justify-center pl-2'>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    size='sm'
                    className='text-primary-blue'
                  />
                </div>
              }
            />
            <div className='mt-6 pb-16'>
              {!!secondaryDropboxId && (
                <div className='font-zen-body text-base font-medium text-zen-dark-9 mb-2'>
                  Listing File Cabinet
                </div>
              )}
              <ZenFixedDataTable<FileResponse>
                columns={primaryTablecolumns}
                data={primaryDropboxFiles}
                resourceName='Listing File Cabinet'
                hidePagination
                hideFilters
                search={search}
                customEmptyComponent={
                  <DefaultEmpty
                    icon={
                      <img
                        src={DropboxEmptyFilesImg}
                        className='my-3'
                        alt='not found'
                      />
                    }
                    message='Could not find matching files'
                  />
                }
              />
            </div>
            {!!secondaryDropboxId && (
              <div className='mt-6 pb-16'>
                <div className='font-zen-body text-base font-medium text-zen-dark-9 mb-2'>
                  Transaction File Cabinet
                </div>
                <ZenFixedDataTable<FileResponse>
                  columns={secondaryTablecolumns}
                  data={secondaryDropboxFiles}
                  resourceName='Transaction File Cabinet'
                  hidePagination
                  hideFilters
                  search={search}
                  customEmptyComponent={
                    <DefaultEmpty
                      icon={
                        <img
                          src={DropboxEmptyFilesImg}
                          className='my-3'
                          alt='not found'
                        />
                      }
                      message='Could not find matching files'
                    />
                  }
                />
              </div>
            )}
          </div>
        </ResourceContainer>
        <div className='py-3 px-3 md:py-4 md:pr-3 md:pl-[60%] absolute w-full bg-white border-t border-gray-200 bottom-0 space-x-5 flex flex-row justify-start items-center left-0 right-0'>
          {!!attach && (
            <ZenButton
              isSubmitting={isSubmitting}
              type='button'
              isFullWidth
              onClick={handleSubmit(handleAttach)}
              label={isSubmitting ? 'Attaching...' : 'Attach File(s)'}
              isDisabled={
                (!attachingPrimaryDropboxFiles.length &&
                  !attachingSecondaryDropboxFiles.length) ||
                isSubmitting
              }
              LeftIconComponent={<FontAwesomeIcon icon={faPaperclip} />}
            />
          )}
        </div>
        <ConfirmationModal
          variant='error'
          title='Delete File?'
          isOpen={!!deletingFile}
          onClose={() => setDeletingFile(undefined)}
        >
          <p className='text-zen-dark-6'>
            This will delete permanently the file: &quot;
            <span className='text-zen-dark-9'>{deletingFile?.filename}</span>
            &quot; from the File Cabinet for this transaction.
          </p>
          <div className='grid grid-cols-2 gap-4 mt-4'>
            <Button
              label='No'
              type='secondary'
              onClick={() => setDeletingFile(undefined)}
              fullWidth
              size='lg'
            />
            <Button
              label='Yes'
              type='danger'
              isSubmitting={deletingById[deletingFile?.id!]}
              onClick={() => handleDeleteFile()}
              fullWidth
              size='lg'
            />
          </div>
        </ConfirmationModal>
      </div>
      {renamingFile && (
        <RenameFileModal
          file={renamingFile}
          onClose={() => setRenamingFile(undefined)}
          isRenaming={updatingFileById[renamingFile.id!]}
          onRename={({ name }) => handleRenameFile(name)}
        />
      )}
    </ZenSidebarModal>
  );
};

export default ZenManageFileCabinetSidebarModal;
