import { faGear, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faEllipsis, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { ReactComponent as EmptyState } from '../../assets/img/emptyState.svg';
import { ChecklistDefinitionDto } from '../../openapi/yenta';
import {
  deleteOfficeChecklistDefinition,
  fetchChecklistDefinition,
} from '../../slices/ChecklistDefinitionSlice';
import { AppDispatch, RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import ResourceContainer from '../ResourceContainer';
import OfficeSetDefaultTemplatesSidebarModal from '../transactions/CheckListAndRoadToSuccessManage/OfficeSetDefaultTemplatesSidebarModal';
import ZenDropdown from '../Zen/ZenDropdown';
import GeminiAddOfficeTemplate from './GeminiAddOfficeTemplate';
import GeminiButton from './GeminiButton';
import GeminiFixedDataTable from './GeminiFixedData';
import GeminiOfficeDealTypeCell from './GeminiOfficeDealTypeCell';
import GeminiConfirmationModal from './Modal/GeminiConfirmationModal';

interface GeminiOfficeTemplatesProps {
  officeId: string;
}

const columns: Array<Column<ChecklistDefinitionDto>> = [
  {
    Header: 'Template Type',
    accessor: 'type',
    Cell: ({ value }) => (
      <p className='font-inter text-zen-dark-9 text-sm px-5 py-1'>
        {capitalizeEnum(value)}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Contract',
    accessor: 'targetType',
    Cell: ({ value }) => (
      <p className='font-inter text-zen-dark-9 text-sm px-5 py-1'>
        {capitalizeEnum(value)}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Representation',
    accessor: 'representee',
    Cell: ({ value }) => (
      <p className='font-inter text-zen-dark-9 text-sm px-5 py-1'>
        {capitalizeEnum(value)}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Deal Type',
    accessor: 'dealType',
    Cell: ({ value }) => (
      <div className='px-4 py-1'>
        <GeminiOfficeDealTypeCell dealType={value} />
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Property Type',
    accessor: 'propertyTypes',
    Cell: ({ value }) => (
      <p className='font-inter text-zen-dark-9 text-sm px-5 py-1'>
        {value?.map((v) => capitalizeEnum(v)).join(', ')}
      </p>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];

const GeminiOfficeTemplates: React.FC<GeminiOfficeTemplatesProps> = ({
  officeId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    checkListDefinition: {
      checklistDefinitionByOfficeId,
      checklistDefinitionById,
    },
    office: { officeDetailById },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isDeletingOfficeChecklist,
    setIsDeletingOfficeChecklist,
  ] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const officeCheckList = checklistDefinitionByOfficeId[officeId];
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState<boolean>(
    false,
  );
  const [isSetDefaults, setIsSetDefaults] = useState<boolean>(false);
  const [checkList, setCheckList] = useState<
    ChecklistDefinitionDto | undefined
  >(undefined);

  const columnsWithActions: Array<Column<ChecklistDefinitionDto>> = [
    {
      Header: 'Item Name',
      accessor: 'checklistDefinitionId',
      Cell: ({ value }) => (
        <p className='font-inter text-zen-dark-9 text-sm px-5 py-1 md:py-5'>
          {checklistDefinitionById[value!]?.data?.name}
        </p>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    ...columns,
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div className='flex items-center px-2'>
          <ZenDropdown
            items={[
              {
                text: 'Delete',
                onClick: () => {
                  setCheckList(original);
                  setIsDeleteModalOpen(true);
                },
                colorType: 'danger',
                icon: <FontAwesomeIcon icon={faTrashCan} />,
              },
            ]}
            icon={
              <FontAwesomeIcon
                icon={faEllipsis}
                className='text-xl'
                aria-label='action-row'
              />
            }
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const checkListDefinitionIds = useMemo(
    () =>
      officeCheckList?.data
        ?.filter((checkList) => !!checkList?.checklistDefinitionId)
        .map((checklist) => checklist?.checklistDefinitionId!),
    [officeCheckList],
  );

  useEffect(() => {
    const checkListDefinitionIdsToFetch = uniq(checkListDefinitionIds)?.filter(
      (id) => !checklistDefinitionById[id],
    );

    const fetchChecklistDefinitionForIds = async () => {
      if (checkListDefinitionIdsToFetch.length > 0) {
        setLoading(true);
        await Promise.all(
          checkListDefinitionIdsToFetch?.map((id) =>
            dispatch(fetchChecklistDefinition(id)),
          ),
        );
        setLoading(false);
      }
    };

    fetchChecklistDefinitionForIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkListDefinitionIds, dispatch]);

  return (
    <div className='mb-20 border border-grey-200 rounded-xl overflow-hidden font-inter bg-white'>
      <div className='flex flex-col md:flex-row items-start md:items-center md:justify-between bg-grey-100 px-3 md:py-[7px] pt-1.5'>
        <p className='md:text-base text-sm leading-5 text-primary-dark font-medium '>
          Checklists / Road To Success
        </p>
        <div className='flex flex-row items-center justify-between font-inter md:space-x-4 space-x-8'>
          <GeminiButton
            label='Set Defaults'
            type='button'
            variant='primary-link'
            leftIcon={faGear}
            onClick={() => setIsSetDefaults(true)}
          />
          <GeminiButton
            label='Add Template'
            type='button'
            variant='primary-link'
            leftIcon={faPlus}
            onClick={() => setIsTemplateModalOpen(true)}
          />
        </div>
      </div>
      <ResourceContainer
        isEmpty={false}
        loading={officeCheckList?.loading || loading}
        resourceName='Template'
      >
        <GeminiFixedDataTable<ChecklistDefinitionDto>
          columns={columnsWithActions}
          resourceName='Template'
          data={officeCheckList?.data || []}
          hideFilters
          hidePageSize
          hidePagination
          customEmptyComponent={
            <div className='flex justify-center items-center h-96'>
              <div className='flex flex-col items-center gap-y-6'>
                <EmptyState className='w-full h-32' />
                <p className='font-inter font-medium text-base text-zen-dark-7'>
                  Add template(s) to this office.
                </p>
              </div>
            </div>
          }
        />
      </ResourceContainer>

      {isTemplateModalOpen && (
        <GeminiAddOfficeTemplate
          isOpen={isTemplateModalOpen}
          onClose={() => {
            setIsTemplateModalOpen(false);
            setCheckList(undefined);
          }}
          officeId={officeId}
          checkList={checkList}
        />
      )}
      {isSetDefaults && (
        <OfficeSetDefaultTemplatesSidebarModal
          isOpen={isSetDefaults}
          onClose={() => setIsSetDefaults(false)}
          office={officeDetailById[officeId]!}
        />
      )}
      <GeminiConfirmationModal
        variant='danger'
        size='large'
        title={`Delete ${
          checklistDefinitionById[checkList?.checklistDefinitionId!]?.data?.name
        } template?`}
        subtitle='Are you sure you would like to remove this template? This action will delete the template permanently'
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setCheckList(undefined);
        }}
        onConfirm={async () => {
          setIsDeletingOfficeChecklist(true);
          await dispatch(
            deleteOfficeChecklistDefinition(officeId, checkList?.id!),
          );
          setIsDeletingOfficeChecklist(false);
          setIsDeleteModalOpen(false);
          setCheckList(undefined);
        }}
        isSubmitting={isDeletingOfficeChecklist}
      />
    </div>
  );
};

export default GeminiOfficeTemplates;
