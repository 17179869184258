import { WalletApplicationFormState } from '../components/Wallet/WalletApplicationSteps';
import {
  AddressCountryEnum,
  AddressStateOrProvinceEnum,
  ApplicantDetails,
  BankingPartnershipDto,
  CreateCreditAccountApplication,
  CreateCreditAccountApplicationBankingPartnerEnum,
  CreateCreditAccountApplicationCardDesignEnum,
  CreateCreditAccountApplicationUserApplicationTypeEnum,
  CreateDebitAccountApplication,
  CreateDebitAccountApplicationBankingPartnerEnum,
  CreateDebitAccountApplicationCardDesignEnum,
  CreateDebitAccountApplicationUserApplicationTypeEnum,
  NationalIdentificationTypeEnum,
} from '../openapi/wallet';
import { AddressResponseCountryEnum } from '../openapi/yenta';
import { YesNoType } from '../types';

export const scopes = [
  'customer-token-write',
  'customers',
  'accounts',
  'payments',
  'counterparties',
  'cards',
  'transactions',
  'authorizations',
  'check-deposits',
  'statements',
];

export const upgradableScopes = [
  'cards-write',
  'cards-sensitive-write',
  'cards-sensitive',
  'payments-write',
  'counterparties-write',
];

export const getCreateApplicationPayload = (
  type: 'debit' | 'credit',
  offeringId: string,
  country: string = AddressResponseCountryEnum.UnitedStates,
  formData: WalletApplicationFormState,
): CreateDebitAccountApplication | CreateCreditAccountApplication => {
  const isSameAddress =
    formData.isShippingAddressSameAsBilling?.[0] === YesNoType.YES;

  const applicantDetails: ApplicantDetails = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    dateOfBirth: formData.birthDate,
    billingAddress: {
      country: country as AddressCountryEnum,
      streetAddress1: formData.street,
      streetAddress2: formData.apartmentOrUnitOrSuit,
      city: formData.city,
      stateOrProvince: formData.state.value as AddressStateOrProvinceEnum,
      zipOrPostalCode: formData.zipCode,
    },
    shippingAddress: {
      country: country as AddressCountryEnum,
      streetAddress1: isSameAddress ? formData.street : formData.shippingStreet,
      streetAddress2: isSameAddress
        ? formData.apartmentOrUnitOrSuit
        : formData.shippingApartmentOrUnitOrSuit,
      city: isSameAddress ? formData.city : formData.shippingCity,
      stateOrProvince: isSameAddress
        ? (formData.state.value as AddressStateOrProvinceEnum)
        : (formData.shippingState.value as AddressStateOrProvinceEnum),
      zipOrPostalCode: isSameAddress
        ? formData.zipCode
        : formData.shippingZipCode,
    },
    phoneNumber: {
      value: formData.phoneNumber,
    },
    emailAddress: {
      value: formData.emailAddress,
    },
    nationalIdentifications: [
      {
        type: NationalIdentificationTypeEnum.Ssn,
        id: formData.ssn,
      },
    ],
  };

  return type === 'debit'
    ? {
        bankingPartner: CreateDebitAccountApplicationBankingPartnerEnum.Unit,
        userApplicationType:
          CreateDebitAccountApplicationUserApplicationTypeEnum.SoleProprietor,
        signatureDocumentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        applicantDetails: applicantDetails,
        cardDesign: CreateDebitAccountApplicationCardDesignEnum.Default,
        debitOfferingId: offeringId,
      }
    : {
        bankingPartner: CreateCreditAccountApplicationBankingPartnerEnum.Unit,
        userApplicationType:
          CreateCreditAccountApplicationUserApplicationTypeEnum.SoleProprietor,
        signatureDocumentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        applicantDetails: applicantDetails,
        cardDesign: CreateCreditAccountApplicationCardDesignEnum.Default,
        creditOfferingId: offeringId,
      };
};

export const findAccountWithBankingPartner = (
  accountId: string,
  partners?: BankingPartnershipDto[],
) => {
  if (!partners) {
    return {};
  }

  for (const partner of partners) {
    const account = partner?.accounts?.find((acc) => acc.id === accountId);
    if (account) {
      return { account, partner };
    }
  }

  return {};
};

declare var window: any;

export const clearUnitTokens = () => {
  window.localStorage.removeItem('unitVerifiedCustomerToken');
  // todo: we want to eventually remove this
  // why: unit components read previous user's token if not cleared
  if (window?.UnitStore?.unitVerifiedCustomerToken) {
    window.UnitStore.unitVerifiedCustomerToken = undefined;
  }
};
