import { PropsWithChildren } from 'react';
import { cn } from '../../../utils/classUtils';

interface NeoLeoChatBubbleProps extends PropsWithChildren<{}> {
  isFirst?: boolean;
  isLast?: boolean;
}

export const NeoLeoChatBubble: React.FC<NeoLeoChatBubbleProps> = ({
  isFirst,
  isLast,
  children,
}) => {
  return (
    <div
      className={cn(
        'w-[293px] py-3 px-4 text-white text-base font-inter font-normal rounded-3xl',
        isFirst ? 'rounded-bl' : isLast ? 'rounded-tl' : 'rounded-l',
        !isFirst && 'mb-2',
      )}
      style={{
        background:
          'linear-gradient(0deg, #19214C 70%, rgba(17, 107, 131, 0.30) 110%, rgba(0, 251, 240, 0.00) 110%)',
      }}
    >
      {children}
    </div>
  );
};
