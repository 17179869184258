import { faBoxArchive } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowLeftLong,
  faArrowRotateLeft,
  faArrowUpRightFromSquare,
  faGripDotsVertical,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import config from '../../../config';
import {
  AgreementDefinitionAmendmentResponse,
  AgreementDefinitionAmendmentResponseStateEnum,
  PatchAgreementAmendmentDefinitionRequest,
  PatchAgreementAmendmentDefinitionRequestStateEnum,
} from '../../../openapi/yenta';
import { ActionButton } from '../../../routes/ZenAgreementIndexRoute';
import {
  fetchAgentAgreementDefinitionWithAmendmentsResponse,
  updateAgreementDefinitionWithAmendment,
} from '../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../types';
import { fetchSubtitle } from '../../../utils/IcaUtils';
import {} from '../../../utils/classUtils';
import DetailPageLoader from '../../DetailPageLoader';
import GeminiButton from '../../Gemini/GeminiButton';
import PageLayout from '../../PageLayout';
import ZenResourceIndexContainer from '../Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenCopyToClipboard from '../ZenCopyToClipBoard';
import ZenResourceContainer from '../ZenResourceContainer';
import ZenRoute from '../ZenRoute';
import AddAmendmentModal from './AddAmendmentModal';
import AgreementDetailsStatusButton from './AgreementDetailsStatusButton';
import AmendmentStatusCell from './AmendmentStatusCell';

type Match = {
  id: string;
};

const ZenAgreementDetailsRoute = () => {
  const { id: agreementId } = useParams() as Match;
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [addAmendment, setAddAmendment] = useState(false);
  const [openAgreementModal, setOpenAgreementModal] = useState<boolean>(false);
  const [agreementUpdateState, setAgreementUpdateState] = useState<
    PatchAgreementAmendmentDefinitionRequest['state']
  >(PatchAgreementAmendmentDefinitionRequestStateEnum.Active);
  const [isSubmittingUpdate, setIsSubmittingUpdate] = useState<boolean>(false);
  const [
    selectedAgreement,
    setSelectedAgreement,
  ] = useState<AgreementDefinitionAmendmentResponse>();

  const { data: agreementResponse, loading, error } = useSelector(
    (state: RootState) =>
      state.agentDetail.agentAgreementDefinitionWithAmendmentsResponse,
  );

  const handleAction = (
    agreement: AgreementDefinitionAmendmentResponse,
    state: PatchAgreementAmendmentDefinitionRequestStateEnum,
  ) => {
    setSelectedAgreement(agreement);
    setOpenAgreementModal(true);
    setAgreementUpdateState(state);
  };

  const updateAmendment = async () => {
    setIsSubmittingUpdate(true);
    await dispatch(
      updateAgreementDefinitionWithAmendment({
        agreementDefinitionId: selectedAgreement?.agreementDefinitionId!,
        agreementDefinitionAmendmentId: selectedAgreement?.agreementDefinitionAmendmentId!,
        req: { state: agreementUpdateState },
      }),
    );
    fetchAgentAgreementDefinitionWithAmendmentsResponse(agreementId);
    setIsSubmittingUpdate(false);
    setOpenAgreementModal(false);
  };

  const columns: Array<Column<AgreementDefinitionAmendmentResponse>> = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({
        row: {
          original: { documentTemplateId },
        },
        value,
      }) => (
        <div className='space-x-5 flex items-center font-zen-body-2'>
          <div>
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </div>
          <div className='flex space-x-2'>
            <h6 className='text-sm font-medium text-primary-dark'>{value}</h6>

            <a
              href={`${config.realSignatureBaseUrl}/view/document/${documentTemplateId}`}
              target='_blank'
              rel='noopener noreferrer'
              data-testid='amendment-document-link'
            >
              <FontAwesomeIcon
                className='text-rezen-blue-600'
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </div>
      ),
    },
    {
      Header: 'Document Id',
      accessor: 'documentTemplateId',
      Cell: ({ value }) => (
        <p className='text-grey-500 text-sm font-normal font-inter'>{value}</p>
      ),
    },
    {
      Header: 'Required By',
      accessor: 'requiredAt',
      id: 'required',
      Cell: ({ value }) => (
        <div className='text-zen-dark-9 text-sm font-zen-body-2'>
          {value ? DateTime.fromMillis(value).toFormat('LL/dd/yyyy') : 'NA'}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'state',
      Cell: ({ value }) => <AmendmentStatusCell status={value!} />,
      filter: 'multiSelectFilter',
    },
    {
      Header: 'Action',
      accessor: 'agreementDefinitionId',
      id: 'actions',
      Cell: ({ row: { original } }) => {
        return (
          <div className='flex space-x-4'>
            {original.state ===
              AgreementDefinitionAmendmentResponseStateEnum.Archived && (
              <ActionButton
                className='bg-grey-200 text-primary-dark hover:bg-regent-400'
                hoverText='Restore'
                icon={faArrowRotateLeft}
                onClick={() =>
                  handleAction(
                    original,
                    PatchAgreementAmendmentDefinitionRequestStateEnum.Created,
                  )
                }
              />
            )}

            {original.state ===
              AgreementDefinitionAmendmentResponseStateEnum.Created && (
              <>
                <ActionButton
                  className='bg-green-100 text-green-600 hover:bg-green-600 hover:text-white'
                  hoverText='Activate'
                  icon={faCheck}
                  onClick={() =>
                    handleAction(
                      original,
                      PatchAgreementAmendmentDefinitionRequestStateEnum.Active,
                    )
                  }
                />
                <ActionButton
                  className='bg-red-100 text-red-600 hover:bg-red-600 hover:text-white'
                  hoverText='Archive'
                  icon={faBoxArchive}
                  onClick={() =>
                    handleAction(
                      original,
                      PatchAgreementAmendmentDefinitionRequestStateEnum.Archived,
                    )
                  }
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchAgentAgreementDefinitionWithAmendmentsResponse(agreementId));
  }, [dispatch, agreementId]);

  return (
    <ZenRoute title='Agreement Details'>
      <PageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Legal', url: '/legal' },
          { title: 'Agreements', url: '/agreements' },
          {
            title: agreementResponse?.name!,
            url: `/agreements/${agreementId}`,
          },
        ]}
      >
        <div className='px-8 lg:pt-5'>
          <div className='my-4'>
            <button
              onClick={() => history.go(-1)}
              className='border-2 border-grey-300 flex items-center justify-center space-x-1 w-[164px] py-2 font-zen-body-2 font-medium rounded-lg'
            >
              <FontAwesomeIcon
                icon={faArrowLeftLong}
                className='text-primary-dark'
                size='sm'
              />
              <p>Go Back</p>
            </button>
          </div>
          <header
            title='header'
            className='w-full bg-primary-navy rounded-2xl md:px-8 px-4 py-5 flex md:flex-row md:justify-between flex-col md:space-x-8 font-zen-body-2 text-white'
          >
            <div className='space-y-3.5'>
              <div className='space-x-1 flex items-start'>
                <h1 className='text-xl font-semibold text-white'>
                  {agreementResponse?.name}
                </h1>

                <a
                  href={`${config.realSignatureBaseUrl}/view/document/${agreementResponse?.documentTemplateId}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  data-testid='agreement-details-link'
                >
                  <FiExternalLink
                    title='View Document'
                    className=' text-aqua text-2xl ml-1'
                  />
                </a>
              </div>
              <AgreementDetailsStatusButton
                status={agreementResponse?.state!}
              />
            </div>
            <div className='bg-regent-900 text-sm font-semibold rounded-lg px-4 pb-4 pt-2 space-y-1 font-zen-body-2'>
              <div className='flex items-center space-x-2'>
                <p>
                  Document:{' '}
                  <span className='inline-block font-light'>
                    {agreementResponse?.documentTemplateId}
                  </span>
                </p>
                <p className='inline-block font-light'>
                  <ZenCopyToClipboard
                    label='Copy'
                    value={agreementResponse?.documentTemplateId!}
                    iconStyle='text-zen-blue-2'
                    variant='primaryTwo'
                  />
                </p>
              </div>
              <div className='space-y-2.5'>
                <div>
                  Version:{' '}
                  <span className='inline-block font-light'>
                    {agreementResponse?.agreementVersion}
                  </span>
                </div>
                <div>
                  Required At:{' '}
                  <span className='inline-block font-light'>
                    {agreementResponse?.requiredAt
                      ? DateTime.fromMillis(
                          agreementResponse?.requiredAt,
                        ).toFormat('LL/dd/yyyy')
                      : 'NA'}
                  </span>
                </div>
                <div>
                  Amendments:{' '}
                  <span className='inline-block font-light'>
                    {agreementResponse?.amendmentDefinitions?.length}
                  </span>
                </div>
              </div>
            </div>
          </header>
          <div className='flex justify-between mb-5 mt-7'>
            <div className='flex items-center space-x-3'>
              <h1 className='font-poppins font-medium text-primary-dark text-xl'>
                Amendments
              </h1>
            </div>
            <div>
              <GeminiButton
                leftIcon={faPlus}
                label='Add Amendment'
                variant='primary-outline'
                onClick={() => setAddAmendment(true)}
              />
            </div>
          </div>
          <ZenResourceContainer
            loading={loading}
            isEmpty={!agreementResponse?.amendmentDefinitions?.length}
            errorCode={error}
            LoaderComponent={<DetailPageLoader />}
            resourceName='Amendment'
          >
            <ZenResourceIndexContainer<AgreementDefinitionAmendmentResponse>
              columns={columns}
              initialSort={{ name: 'asc' }}
              data={agreementResponse?.amendmentDefinitions! || []}
              resourceName='amendment'
              hideFilters
              hidePageSize
            />
          </ZenResourceContainer>
          <AddAmendmentModal
            isOpen={addAmendment}
            onClose={() => setAddAmendment(false)}
            agreementResponse={agreementResponse}
          />
          <ZenConfirmationModal
            isOpen={openAgreementModal}
            isSubmitting={isSubmittingUpdate}
            onClose={() => setOpenAgreementModal(false)}
            title='Update Amendment'
            subtitle={fetchSubtitle(agreementUpdateState)}
            onConfirm={updateAmendment}
          />
        </div>
      </PageLayout>
    </ZenRoute>
  );
};

export default ZenAgreementDetailsRoute;
