import { UseControllerProps } from 'react-hook-form-v7';
import {
  AnswerApplicationQuestionRequest,
  ApplicationSectionDto,
  ApplicationStep,
  FieldObject,
  FieldObjectTypeEnum,
  Question,
} from '../../openapi/atlantis';
import { ISelectOption } from '../../types';
import { capitalizeEnum } from '../StringUtils';
import { getAddressFromAddressComponent } from '../TransactionHelper';
import { isApplicationQuestion } from '../TypeUtils';

export type Validation = UseControllerProps['rules'];

export enum DisplayTypeInputEnum {
  SINGLE_SELECT_IMAGE = 'SINGLE_SELECT_IMAGE',
}

export interface ApplicationStepWithNewContent<T>
  extends Omit<ApplicationStep, 'content'> {
  content: T;
}

export const getBooleanParse = (value: string | boolean): boolean => {
  const lowerCaseValue = value?.toString().toLowerCase();

  if (lowerCaseValue === 'true') {
    return true;
  }

  return false;
};

// this function returns the answer for a address field
const getAddressFieldAnswer = (answer: Record<string, any>) => {
  if (!answer) {
    return;
  }

  if (answer?.isManualAddress) {
    return {
      type: answer?.type || null,
      country: 'UNITED_STATES',
      streetAddress1: answer?.streetAddress1 || null,
      streetAddress2: answer?.streetAddress2 || null,
      city: answer?.city || null,
      county: answer?.county || null,
      stateOrProvince: answer?.stateOrProvince?.value,
      zipOrPostalCode: answer?.zipOrPostalCode,
    };
  }

  if (answer?.place_id) {
    return getAddressFromAddressComponent(answer?.address_components, [
      'streetAddress2',
    ]);
  }

  return answer;
};

const getFieldAnswer = (field: FieldObject, answer: any) => {
  switch (field.type) {
    case FieldObjectTypeEnum.Address:
      return getAddressFieldAnswer(answer);

    case FieldObjectTypeEnum.FileRequest:
      return getBooleanParse(answer);

    case FieldObjectTypeEnum.Number:
      return Number(answer);

    case FieldObjectTypeEnum.Money:
      return { amount: answer || 0, currency: 'USD' };

    case FieldObjectTypeEnum.Button:
      return true;

    case FieldObjectTypeEnum.Binary:
    case FieldObjectTypeEnum.Toggle:
    case FieldObjectTypeEnum.SingleSelect:
      return answer?.value;

    case FieldObjectTypeEnum.MultiSelect:
      return answer?.map((a: any) => a.value);

    default:
      return answer;
  }
};

// this function returns the answer for a field array
export const getFieldArrayAnswer = (field: FieldObject, fieldAnswer: any) => {
  const fieldArrayAnswer = (fieldAnswer || []).map((fieldValue: any) => {
    //@ts-ignore
    return (field.fields || []).map((childField: FieldObject) => {
      const value = fieldValue[childField.id!];

      return {
        fieldId: childField.id,
        answerType: childField.answerType,
        answer: getFieldAnswer(childField, value),
      };
    });
  });

  const answer = {
    ...field,
    answer: fieldArrayAnswer,
  };

  return [answer];
};

// this function returns the answers for fields in a form.
export const getFieldsAnswers = (
  fields: FieldObject[],
  formData: Record<string, any>,
) => {
  return (fields || []).flatMap((field: FieldObject) => {
    if (!formData.hasOwnProperty(field.id!)) {
      return [];
    }

    const fieldAnswer = formData[field.id!];

    if (field.type === FieldObjectTypeEnum.FieldArray) {
      return getFieldArrayAnswer(field, fieldAnswer);
    }

    const answer = {
      ...field,
      answer: getFieldAnswer(field, fieldAnswer),
    };

    return [answer];
  });
};

//this function returns the answer for a question form
export const getFormQuestionAnswers = (
  config: ApplicationStepWithNewContent<Question>,
  formData: Record<string, any>,
): AnswerApplicationQuestionRequest => {
  const fields = [
    ...(config.content.fields || []),
    ...(config.content.segments?.flatMap((s) => s.fields) || []),
  ];

  const processedFields = getFieldsAnswers(fields, formData);

  const answers = (processedFields || []).map((field) => ({
    fieldId: field.id,
    answer: field.answer,
    answerType: field.answerType,
  }));

  return {
    questionId: config.content.id!,
    //@ts-ignore
    answers,
  };
};

// This function returns the default values for an address field.
const getAddressFieldDefaultValue = (
  address: any,
): Record<string, any> | undefined => {
  if (!address) {
    return undefined;
  }

  const formatAddress = (address: any) => {
    if (!address?.country) {
      return '';
    }

    const formattedAddress = [
      address?.streetAddress1,
      address?.streetAddress2,
      address?.city,
      `${capitalizeEnum(address?.stateOrProvince || '')} ${
        address?.zipOrPostalCode
      }`,
      capitalizeEnum(address?.country || ''),
    ]
      .filter(Boolean)
      .join(', ');

    return formattedAddress;
  };

  return {
    formatted_address: formatAddress(address),
    country: address?.country,
    streetAddress1: address?.streetAddress1,
    streetAddress2: address?.streetAddress2,
    city: address?.city,
    county: address?.county,
    stateOrProvince: address?.stateOrProvince,
    zipOrPostalCode: address?.zipOrPostalCode,
  };
};

// This function returns the default values for a specified field based on its type.
export const getFieldDefaultValues = (field: FieldObject, answer: any): any => {
  switch (field.type) {
    case FieldObjectTypeEnum.Address:
      return getAddressFieldDefaultValue(answer);

    case FieldObjectTypeEnum.Number:
      return answer?.toString();

    case FieldObjectTypeEnum.Money:
      return answer?.amount?.toString();

    case FieldObjectTypeEnum.Binary:
    case FieldObjectTypeEnum.Toggle: {
      // @ts-ignore
      const options = [field.trueOption, field.falseOption];

      const selectedOption = options.find(
        (option: ISelectOption) => option.value === answer,
      );

      return selectedOption;
    }

    case FieldObjectTypeEnum.SingleSelect: {
      // @ts-ignore
      const options = (field.options || []).map((option) => ({
        label: option.label,
        value: option.value,
      }));

      const selectedOptions = options.find(
        (option: ISelectOption) => option.value === answer,
      );

      return selectedOptions;
    }

    case FieldObjectTypeEnum.MultiSelect: {
      // @ts-ignore
      const options = (field.options || []).map((option) => ({
        label: option.label,
        value: option.value,
      }));

      const selectedOptions = options.filter((option: ISelectOption) =>
        answer?.includes(option.value),
      );

      return selectedOptions;
    }

    default:
      return answer;
  }
};

// This function returns an default values for a field array.
export const getFieldArrayDefaultValues = (
  fields: FieldObject[],
  fieldAnswer: any[],
): Record<string, any> => {
  const fieldArrayDefaultValues: Record<string, any> = {};

  (fieldAnswer || []).forEach(({ fieldId, answer }) => {
    const field = (fields || []).find((f) => f.id === fieldId);
    if (field) {
      const defaultValue = getFieldDefaultValues(field, answer);
      fieldArrayDefaultValues[fieldId] = defaultValue;
    }
  });

  return fieldArrayDefaultValues;
};

// This function sets the default values for fields in a form or segment.
export const getFieldsDefaultValues = (fields: FieldObject[] = []): any => {
  const fieldsDefaultValues: Record<string, any> = {};

  (fields || []).forEach((field) => {
    const { id, type, answer } = field;
    const fieldAnswer = answer;

    switch (type) {
      case FieldObjectTypeEnum.FieldArray:
        const fieldArrayAnswers = fieldAnswer?.map((ans: any) => {
          //@ts-ignore
          return getFieldArrayDefaultValues(field.fields, ans);
        });
        fieldsDefaultValues[id!] = fieldArrayAnswers;
        break;
      default:
        const defaultAnswer = getFieldDefaultValues(field, fieldAnswer);
        fieldsDefaultValues[id!] = defaultAnswer;
        break;
    }
  });

  return fieldsDefaultValues;
};

// This function returns the default values for a form, given its configuration.
export const getFormDefaultValues = (
  config: ApplicationStepWithNewContent<Question>,
): Record<string, any> => {
  const formDefaultValues = Object.assign(
    {},
    getFieldsDefaultValues(config.content.fields),
    ...(config.content.segments || []).map((segment) =>
      getFieldsDefaultValues(segment.fields),
    ),
  );

  return formDefaultValues;
};

export const hasOnlyButtonFieldsInQuestion = (step: ApplicationStep) => {
  if (isApplicationQuestion(step)) {
    const allFields = [
      ...(step?.content?.fields || []),
      ...(step?.content?.segments || []).flatMap((s) => s.fields),
    ];

    return allFields.every((field) =>
      [FieldObjectTypeEnum.Button, FieldObjectTypeEnum.UrlRequest].includes(
        field.type!,
      ),
    );
  }

  return false;
};

export const hasOnlyButtonFieldsInSection = (
  section: ApplicationSectionDto,
): boolean => {
  return (section.questionSteps || []).every((question) =>
    hasOnlyButtonFieldsInQuestion(question),
  );
};

export const isReadyToSubmit = (
  formValidityById?: Record<string, boolean>,
  sections?: ApplicationSectionDto[],
): boolean => {
  if (!sections || !formValidityById) {
    return false;
  }

  const validIds = new Set<string>();

  // Iterate through each section to collect valid question IDs
  sections.forEach((section) => {
    if (hasOnlyButtonFieldsInSection(section)) {
      return;
    }

    section.questionSteps.forEach((question) => {
      if (hasOnlyButtonFieldsInQuestion(question)) {
        return;
      }

      if (question.content && question.content.id) {
        validIds.add(question.content.id);
      }
    });
  });

  // Check if all valid IDs are present and true in formValidityById
  return Array.from(validIds).every((id) => formValidityById[id]);
};
