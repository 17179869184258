import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSignAgentAgreement } from '../../../slices/AgentSlice';
import { RootState } from '../../../types';
import { hasPastAgreementRequiredDate } from '../../../utils/AgreementUtils';
import AgentAgreementSigningModal from '../../Zen/Modal/AgentAgreementSigningModal';

interface AgentAgreementSignatureModalIndexProps {}

const AgentAgreementSignatureModalIndex: React.FC<AgentAgreementSignatureModalIndexProps> = () => {
  const dispatch = useDispatch();
  const {
    auth: { isSuperAdmin, isAdmin, isBroker },
    agentDetail: { signAgentAgreement, allLatestAgreementsNotSigned },
  } = useSelector((state: RootState) => state);
  const isAgent = !isSuperAdmin && !isAdmin && !isBroker;

  const hasPastRequiredDate =
    allLatestAgreementsNotSigned?.length &&
    hasPastAgreementRequiredDate(allLatestAgreementsNotSigned[0].requiredAt!);

  if (hasPastRequiredDate || !isAgent || !signAgentAgreement) {
    return null;
  }

  return (
    <AgentAgreementSigningModal
      isOpen
      onClose={async () => {
        await dispatch(saveSignAgentAgreement(false));
      }}
    />
  );
};

export default AgentAgreementSignatureModalIndex;
