import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import {
  IconDefinition,
  faArrowRotateLeft,
  faBoxArchive,
  faCheck,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
import GeminiButton from '../components/Gemini/GeminiButton';
import GeminiHover from '../components/Gemini/GeminiHover';
import ZenResourceIndexContainer from '../components/Zen/Containers/ZenResourceIndexContainer';
import ZenConfirmationModal from '../components/Zen/Modal/ZenConfirmationModal';
import ZenRouterTabs, { ZenTab } from '../components/Zen/Tab/ZenRouterTabs';
import ZenPageLayout from '../components/Zen/ZenPageLayout';
import ZenRoute from '../components/Zen/ZenRoute';
import config from '../config';
import {
  AgentPlanControllerApi,
  AgreementDefinitionResponse,
  PatchAgreementAmendmentDefinitionRequest,
  PatchAgreementAmendmentDefinitionRequestStateEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  getActiveAgreementDefinitions,
  getArchivedAgreementDefinitions,
  getCreatedAgreementDefinitions,
  updateAgreementDefinition,
} from '../slices/AgentSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { AppDispatch, EnumMap, ISelectOption, RootState } from '../types';
import { isSmScreen } from '../utils/BrowserUtils';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import ZenAddAgreementModal from './AddAgreementModal';

interface ZenAgreementIndexRouteProps {}

export const columns: Column<AgreementDefinitionResponse>[] = [
  {
    Header: 'Version',
    accessor: 'agreementVersion',
    Cell: ({ value }) => (
      <div className='font-inter text-zen-dark-9 font-medium text-sm leading-5'>
        V{value}
      </div>
    ),
    disableFilters: true,
  },
  {
    Header: 'Amendments',
    accessor: 'definitionAmendmentCount',
    Cell: ({ value }) => (
      <div className='font-inter text-zen-dark-9 font-normal text-sm leading-5'>
        {value}
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Required By',
    accessor: 'requiredAt',
    Cell: ({ value }) => (
      <div className='font-inter text-zen-dark-9 font-normal text-sm leading-5'>
        {value ? DateTime.fromMillis(value).toFormat('LL/dd/yyyy') : 'NA'}
      </div>
    ),
    disableFilters: true,
  },
];

export const ActionButton = ({
  className,
  hoverText,
  icon,
  onClick,
}: {
  className: string;
  hoverText: string;
  icon: IconDefinition;
  onClick: () => void;
}) => (
  <GeminiHover
    config={{ trigger: 'hover', placement: 'top' }}
    hoverComponent={
      <p className='font-zen-body font-normal text-sm text-primary-dark px-1.5 py-0.5'>
        {hoverText}
      </p>
    }
  >
    <div
      className={`${className} rounded-full px-3 py-2 cursor-pointer`}
      onClick={onClick}
      data-testid={hoverText}
    >
      <FontAwesomeIcon fontSize={14} icon={icon} />
    </div>
  </GeminiHover>
);

const ZenAgreementIndexRoute: React.FC<ZenAgreementIndexRouteProps> = () => {
  const [
    selectedAgreement,
    setSelectedAgreement,
  ] = useState<AgreementDefinitionResponse>();
  const [openAgreementModal, setOpenAgreementModal] = useState<boolean>(false);
  const [agreementUpdateState, setAgreementUpdateState] = useState<
    PatchAgreementAmendmentDefinitionRequest['state']
  >(PatchAgreementAmendmentDefinitionRequestStateEnum.Active);
  const [isSubmittingUpdate, setIsSubmittingUpdate] = useState<boolean>(false);
  const [commissionPlans, setCommissionPlans] = useState<ISelectOption[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const activeAgreementDefinitions = useSelector(
    (state: RootState) => state.agentDetail.activeAgreementDefinitionsResponse,
  );
  const createdAgreementDefinitions = useSelector(
    (state: RootState) => state.agentDetail.createdAgreementDefinitionsResponse,
  );
  const [
    openCreateICAAgreementModal,
    setOpenCreateICAAgreementModal,
  ] = useState<boolean>(false);
  const currentTab = window.location.pathname;

  const getAgreementDefinitions = useCallback((): void => {
    dispatch(getActiveAgreementDefinitions(0, 10));
    dispatch(getArchivedAgreementDefinitions(0, 10));
    dispatch(getCreatedAgreementDefinitions(0, 10));
  }, [dispatch]);

  useEffect(() => {
    if (commissionPlans.length === 0) {
      getAllCommissionPlansBasicInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAgreement = async () => {
    setIsSubmittingUpdate(true);
    await dispatch(
      updateAgreementDefinition({
        agreementDefinitionId: selectedAgreement?.agreementDefinitionId!,
        req: { state: agreementUpdateState },
      }),
    );
    getAgreementDefinitions();
    setIsSubmittingUpdate(false);
    setOpenAgreementModal(false);
  };

  const AgreementSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
    asc: 'ASC',
    desc: 'DESC',
  };

  const AgreementSortByTypeEnum: EnumMap<
    string,
    'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'
  > = {
    name: 'NAME',
    requiredAt: 'CREATED_AT',
    agreementVersion: 'AGREEMENT_VERSION',
  };

  const columnsWithAction: Array<
    Column<AgreementDefinitionResponse>
  > = useMemo(() => {
    const col = [...columns];
    if (currentTab === '/agreements' || currentTab === '/agreements/created') {
      col.push({
        Header: 'Actions',
        id: 'actions',
        Cell: ({ row }: { row: Row<AgreementDefinitionResponse> }) => (
          <div className='flex justify-start space-x-4'>
            <ActionButton
              className='bg-green-100 text-green-600 hover:bg-green-600 hover:text-white'
              hoverText='Activate'
              icon={faCheck}
              onClick={() =>
                handleAction(
                  row.original,
                  PatchAgreementAmendmentDefinitionRequestStateEnum.Active,
                )
              }
            />
            <ActionButton
              className='bg-red-100 text-red-600 hover:bg-red-600 hover:text-white'
              hoverText='Archive'
              icon={faBoxArchive}
              onClick={() =>
                handleAction(
                  row.original,
                  PatchAgreementAmendmentDefinitionRequestStateEnum.Archived,
                )
              }
            />
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      });
    } else if (currentTab === '/agreements/archived') {
      col.push({
        Header: 'Actions',
        id: 'actions',
        Cell: ({ row }: { row: Row<AgreementDefinitionResponse> }) => (
          <div className='flex justify-start gap-2'>
            <ActionButton
              className='bg-regent-300 text-primary-dark hover:bg-regent-400'
              hoverText='Restore'
              icon={faArrowRotateLeft}
              onClick={() =>
                handleAction(
                  row.original,
                  PatchAgreementAmendmentDefinitionRequestStateEnum.Created,
                )
              }
            />
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      });
    }

    col.unshift({
      Header: 'Name & ID',
      accessor: 'name',
      Cell: ({ value, row }: CellProps<AgreementDefinitionResponse>) => {
        return (
          <div className='flex my-2.5 gap-3 items-center'>
            <Link to={`/agreement/${row.original.agreementDefinitionId}`}>
              <div className='w-8 h-8 bg-rezen-blue-600 rounded-md flex items-center justify-center'>
                <FontAwesomeIcon
                  icon={faEye}
                  className='text-white'
                  title='View'
                />
              </div>
            </Link>
            <div className='flex flex-col space-y-0.5'>
              <div className='flex gap-1.5'>
                <div className='font-inter font-normal text-sm leading-5 text-primary-dark'>
                  {value}
                </div>
                <a
                  href={`${config.realSignatureBaseUrl}/view/document/${row.original.documentTemplateId}`}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className='text-rezen-blue-600 text-sm'
                    title='View Document'
                  />
                </a>
              </div>
              <div className='font-inter font-normal text-sm leading-5 text-grey-500'>
                {row.original.agreementDefinitionId}
              </div>
            </div>
          </div>
        );
      },

      disableFilters: true,
    });
    return col;
  }, [currentTab]);

  const getAllCommissionPlansBasicInfo = async (): Promise<void> => {
    try {
      const { data } = await new AgentPlanControllerApi(
        getYentaConfiguration(),
      ).getAllCommissionPlansBasicInfo();

      const options: ISelectOption[] =
        data?.commissionPlans?.map((resp) => ({
          value: `${resp.commissionPlanId}`,
          label: `${capitalizeEnum(resp.name!)}`,
        })) ?? [];

      setCommissionPlans(options);
    } catch (e) {
      ErrorService.notify('Unable to get for commission plans', e, {
        search: {
          target: 'CommissionPlanId',
        },
      });
      dispatch(
        showErrorToast(
          'An unexpected error occurred.',
          'We were unable to search for commission plans. Please try again in a few moments or contact support.',
        ),
      );
    }
  };

  const handleAction = (
    agreement: AgreementDefinitionResponse,
    state: PatchAgreementAmendmentDefinitionRequestStateEnum,
  ) => {
    setSelectedAgreement(agreement);
    setOpenAgreementModal(true);
    setAgreementUpdateState(state);
  };

  const createdTab: ZenTab = useMemo(
    () => ({
      label: 'Created',
      path: `/agreements`,
      exact: true,
      TabComponent: () => (
        <ZenRoute title='Created Agreements'>
          <div className='mt-4'>
            <ZenResourceIndexContainer<AgreementDefinitionResponse>
              columns={columnsWithAction}
              hideFilters
              resourceName='Agreements'
              showSearch={isSmScreen()}
              pageSize={10}
              fetchData={async (req) => {
                const sortType = Object.values(req.sortBy || {})[0];
                const sortBy = Object.keys(req.sortBy || {})[0];
                const data = await dispatch(
                  getCreatedAgreementDefinitions(
                    req.page,
                    req.pageSize,
                    AgreementSortDirectionTypeEnum[sortType],
                    [AgreementSortByTypeEnum[sortBy!]],
                  ),
                );
                return {
                  data: data?.results ?? [],
                  total: data?.totalCount ?? 0,
                };
              }}
              initialSort={{ createdAt: 'desc' }}
            />
          </div>
        </ZenRoute>
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsWithAction, dispatch, activeAgreementDefinitions.data],
  );

  const activeTab: ZenTab = useMemo(
    () => ({
      label: 'Active',
      path: `/agreements/active`,
      exact: true,
      TabComponent: () => (
        <ZenRoute title='Active Agreements'>
          <div className='mt-4'>
            <ZenResourceIndexContainer<AgreementDefinitionResponse>
              columns={columnsWithAction}
              hideFilters
              resourceName='Agreements'
              showSearch={isSmScreen()}
              pageSize={10}
              fetchData={async (req) => {
                const sortType = Object.values(req.sortBy || {})[0];
                const sortBy = Object.keys(req.sortBy || {})[0];
                const data = await dispatch(
                  getActiveAgreementDefinitions(
                    req.page,
                    req.pageSize,
                    AgreementSortDirectionTypeEnum[sortType],
                    [AgreementSortByTypeEnum[sortBy!]],
                  ),
                );
                return {
                  data: data?.results ?? [],
                  total: data?.totalCount ?? 0,
                };
              }}
              initialSort={{ createdAt: 'desc' }}
            />
          </div>
        </ZenRoute>
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsWithAction, dispatch, createdAgreementDefinitions.data],
  );

  const archiveTab: ZenTab = useMemo(
    () => ({
      label: 'Archive',
      path: `/agreements/archived`,
      exact: true,
      TabComponent: () => (
        <ZenRoute title='Archived Agreements'>
          <div className='mt-4'>
            <ZenResourceIndexContainer<AgreementDefinitionResponse>
              columns={columnsWithAction}
              hideFilters
              resourceName='Agreements'
              showSearch={isSmScreen()}
              pageSize={10}
              fetchData={async (req) => {
                const sortType = Object.values(req.sortBy || {})[0];
                const sortBy = Object.keys(req.sortBy || {})[0];
                const data = await dispatch(
                  getArchivedAgreementDefinitions(
                    req.page,
                    req.pageSize,
                    AgreementSortDirectionTypeEnum[sortType],
                    [AgreementSortByTypeEnum[sortBy!]],
                  ),
                );
                return {
                  data: data?.results ?? [],
                  total: data?.totalCount ?? 0,
                };
              }}
              initialSort={{ createdAt: 'desc' }}
            />
          </div>
        </ZenRoute>
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsWithAction, dispatch, activeAgreementDefinitions.data],
  );

  return (
    <ZenRoute title='Agreements'>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          { title: 'Legal', url: '/legal' },
          { title: 'Agreements', url: '/agreements' },
        ]}
      >
        <div className='px-4 lg:pt-5'>
          <div className='flex justify-between mb-5'>
            <div className='flex items-center justify-between space-x-3 w-full'>
              <h1 className='font-poppins font-medium text-xl'>Agreements</h1>
              <div className='md:space-x-4'>
                <GeminiButton
                  variant='primary-outline'
                  label='Create Agreement'
                  leftIcon={faPlus}
                  onClick={() => setOpenCreateICAAgreementModal(true)}
                />
              </div>
            </div>
          </div>
          <ZenRouterTabs tabs={[createdTab, activeTab, archiveTab]} />
        </div>
      </ZenPageLayout>
      <ZenAddAgreementModal
        isOpen={openCreateICAAgreementModal}
        onClose={() => setOpenCreateICAAgreementModal(false)}
        getAgreementDefinitions={getAgreementDefinitions}
      />
      <ZenConfirmationModal
        isOpen={openAgreementModal}
        isSubmitting={isSubmittingUpdate}
        isDisabled={isSubmittingUpdate}
        onClose={() => setOpenAgreementModal(false)}
        title='Update Agreement'
        subtitle={`Are you sure you want to ${
          agreementUpdateState === 'ACTIVE'
            ? 'activate'
            : agreementUpdateState === 'ARCHIVED'
            ? 'archive'
            : 'restore'
        } this agreement?`}
        onConfirm={updateAgreement}
      />
    </ZenRoute>
  );
};

export default ZenAgreementIndexRoute;
