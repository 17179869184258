import React from 'react';

interface NeoLeoUserChatBubbleProps {
  text: string;
}

const NeoLeoUserChatBubble: React.FC<NeoLeoUserChatBubbleProps> = ({
  text,
}) => (
  <div className='flex flex-row justify-end my-6'>
    <div
      className='w-[293px] rounded-t-2xl p-4 text-base font-inter font-normal rounded-bl-2xl'
      style={{
        background:
          'linear-gradient(180deg, #4758A9 -27.47%, #ACB9E8 -0.35%, #C7DFFF 11.54%)',
      }}
    >
      <div>
        <p className='text-primary-navy font-inter font-normal text-base word-break'>
          {text}
        </p>
      </div>
    </div>
  </div>
);

export default NeoLeoUserChatBubble;
