import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../services/AnalyticsService';
import { saveShowIntro, chatReply, openLeo } from '../../slices/LeoSlice';
import { RootState, AnalyticsEventEnum } from '../../types';
import { ContextualPrompt, FollowUp } from '../../utils/LeoUtils';
import NeoLeoPrompt from '../Zen/Leo/Chat/NeoLeoPrompt';
import { PrompSizeVariantType } from '../Zen/Leo/Chat/Prompt';
import useShowLeoTrigger from '../Zen/Leo/useShowLeoTrigger';

interface NeoLeoChatPromptsProps {
  promptSuggestions?: ContextualPrompt[];
  isSimba?: boolean;
  promptSizeVariant?: PrompSizeVariantType;
  contextType?: string | null;
  onSelect?(): void;
}

const NeoLeoChatPrompts: React.FC<NeoLeoChatPromptsProps> = ({
  promptSuggestions,
  isSimba = false,
  contextType,
  onSelect,
}) => {
  const dispatch = useDispatch();
  const {
    leo: { session, isLeoThinking, currentChat, selectedItems },
  } = useSelector((state: RootState) => state);
  const {
    simbaFollowUpItem,
    agentId,
    prompts: defaultPrompts,
  } = useShowLeoTrigger();

  const getFollowUps = (category: string | null): FollowUp[] | undefined => {
    // for prompts that come from leo chat response
    if (selectedItems.length) {
      return [
        {
          type: contextType || '',
          items: selectedItems,
        },
      ];
    } else if (!!category && category !== 'none') {
      // for default prompts i.e. simba
      return [
        {
          type: category,
          items: simbaFollowUpItem ? [simbaFollowUpItem] : [],
        },
      ];
    }
    return undefined;
  };

  const onPromptSelect = async (
    selectedPrompt: ContextualPrompt,
    handlerType: string | null = null,
  ) => {
    if (onSelect) {
      onSelect();
    }
    dispatch(saveShowIntro(false));
    const updateSessionTitle =
      currentChat.length === 0 ||
      (currentChat.length === 1 &&
        currentChat[0].message === session?.disclaimer);

    await dispatch(
      chatReply(
        session?.id!,
        selectedPrompt,
        updateSessionTitle,
        getFollowUps(handlerType),
      ),
    );
    AnalyticsService.instance().logEvent(AnalyticsEventEnum.LEO_PROMPT_CLICK, {
      prompt,
    });
  };

  const prompts = promptSuggestions || defaultPrompts;
  const PromptWrapThreshold = 5;

  if (isSimba) {
    return (
      <div
        aria-label='leo-simba-prompts'
        className='overflow-x-auto scrollbar-hide flex flex-row justify-start mx-4 py-0.5'
      >
        {prompts.map((prompt) => (
          <NeoLeoPrompt
            key={prompt.prompt}
            prompt={prompt.title}
            onSelect={() =>
              dispatch(openLeo(agentId!, prompt, getFollowUps(prompt.category)))
            }
          />
        ))}
      </div>
    );
  }

  return (
    <div className='overflow-hidden overflow-x-auto scrollbar-hide'>
      <div className='flex flex-row w-max'>
        {prompts.slice(0, PromptWrapThreshold).map((prompt) => (
          <NeoLeoPrompt
            key={prompt.prompt}
            prompt={prompt.title}
            disabled={!session?.id || isLeoThinking}
            onSelect={() => onPromptSelect(prompt, prompt.category)}
          />
        ))}
      </div>
      {prompts.length > 5 && <div className='basis-full' />}
      <div className='flex flex-row w-max'>
        {prompts.slice(PromptWrapThreshold, 10).map((prompt) => (
          <NeoLeoPrompt
            key={prompt.title}
            prompt={prompt.prompt}
            disabled={!session?.id || isLeoThinking}
            onSelect={() => onPromptSelect(prompt, prompt.category)}
          />
        ))}
      </div>
    </div>
  );
};

export default NeoLeoChatPrompts;
