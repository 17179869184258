import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateToken, useWallet } from '../../../query/wallet/useWallet';
import {
  clearUnitTokens,
  findAccountWithBankingPartner,
  scopes,
  upgradableScopes,
} from '../../../utils/WalletUtils';
import ZenButton from '../../Zen/ZenButton';
import ZenPageLayout from '../../Zen/ZenPageLayout';
import ZenResourceContainer from '../../Zen/ZenResourceContainer';
import WalletSendMoneyModal from './WalletSendMoneyModal';

interface Params {
  accountId: string;
}

interface WalletDashboardRouteProps {}

const WalletDashboardRoute: React.FC<WalletDashboardRouteProps> = () => {
  const { accountId } = useParams<Params>();
  const [sendMoneyModalState, setSendMoneyModalState] = useState<boolean>();

  const {
    data: wallet,
    isLoading: walletLoading,
    errorCode: walletError,
  } = useWallet();

  const { account, partner } = findAccountWithBankingPartner(
    accountId,
    wallet?.bankingPartnerships,
  );

  const {
    data: unitToken,
    isLoading: tokenLoading,
    errorCode: tokenError,
  } = useCreateToken({
    bankingPartnershipId: partner?.id!,
    scopes,
    upgradableScopes,
  });

  const isLoading = walletLoading || tokenLoading;
  const errorCode = walletError || tokenError;

  return (
    <ZenPageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Real Wallet', url: '/wallet' },
      ]}
      textClassNames='font-inter'
    >
      <ZenResourceContainer
        loading={isLoading}
        isEmpty={!unitToken?.token}
        resourceName='wallet dashboard'
        errorCode={errorCode}
      >
        <div className='flex justify-between items-center mx-10 mt-5'>
          <p className='font-medium text-lg font-poppins'>My Cards</p>
          <ZenButton
            label='Send Money'
            LeftIconComponent={
              <FontAwesomeIcon icon={faPaperPlane} className='text-sm mr-1' />
            }
            className='font-inter font-medium text-base text-primary-dark bg-primary-light border-2 border-grey-300'
            onClick={() => {
              clearUnitTokens();
              setSendMoneyModalState(true);
            }}
          />
        </div>

        <div className='h-auto flex flex-col justify-between items-center lg:flex-row m-5 gap-5 mx-9'>
          <div className='h-72 order-2 sm:order-2 md:order-2 lg:order-1 w-full shadow-pop-over rounded-xl overflow-hidden'>
            {/* @ts-ignore */}
            <unit-elements-account
              customer-token={unitToken?.token}
              account-id={account?.externalId}
              hide-selection-menu-button
              theme='https://ui.s.unit.sh/resources/4127/themes/3fe66162-2350-43b8-9b27-10a726862308.json'
            />
          </div>

          {account?.cards?.[0]?.externalId && (
            <div className='order-1 sm:order-1 md:order-1 w-full lg:w-1/3'>
              {/* @ts-ignore */}
              <unit-elements-card
                customer-token={unitToken?.token}
                card-id={account.cards[0].externalId}
                theme='https://ui.s.unit.sh/resources/4127/themes/8d18e5b2-3d67-4054-9dfb-0aa5e9f12ac7.json'
              />
            </div>
          )}
        </div>

        <div className='mb-10 w-auto mx-9 my-5 p-5 shadow-pop-over rounded-xl'>
          {/* @ts-ignore */}
          <unit-elements-activity
            customer-token={unitToken?.token}
            account-id={account?.externalId}
            pagination-type='infiniteScroll'
            hide-back-to-top='false'
            transactions-per-page='4'
            style={{
              height: '440px',
              overflow: 'hidden',
              display: 'block',
              width: '100%',
            }}
            theme='https://ui.s.unit.sh/resources/4127/themes/9522d4dd-2722-4c22-ba28-cdeb4756e6ed.json'
            language='https://ui.s.unit.sh/resources/4127/languages/a0389dc1-dcc1-4447-b2c1-47f0895441f4.json'
          />
        </div>

        {partner?.id && account?.externalId && sendMoneyModalState && (
          <WalletSendMoneyModal
            isOpen={sendMoneyModalState}
            onClose={() => setSendMoneyModalState(false)}
            bankingPartnershipId={partner?.id}
            accountId={account?.externalId}
          />
        )}
      </ZenResourceContainer>
    </ZenPageLayout>
  );
};

export default WalletDashboardRoute;
