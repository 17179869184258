import { SvgIcon } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CompliantIcon } from '../../../../assets/icons/compliant.svg';
import { TransactionResponse } from '../../../../openapi/arrakis';
import {
  markAsNonCompliant,
  markAsCompliant,
} from '../../../../slices/TransactionSlice';
import { AppDispatch, RootState } from '../../../../types';
import ExcludeAgent from '../../../auth/ExcludeAgent';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';
import ZenButton from '../../ZenButton';
import { useFetchAsyncMappingResponse } from '../../../../hooks/useFetchAsyncMappingResponse';
import { fetchCheckLists } from '../../../../slices/CheckListSlice';
import { ChecklistResponse } from '../../../../openapi/sherlock';
import { isCompliantForCommissionDocGeneration } from '../../../../utils/TransactionHelper';

interface NewZenMarkAsCompliantButtonProps {
  transaction: TransactionResponse;
}
const NewZenMarkAsCompliantButton: React.FC<NewZenMarkAsCompliantButtonProps> = ({
  transaction,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    transaction: { transactionDetailResponse },
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);
  const [isLoading, setLoading] = useState(false);
  const [markAsCompliantModal, setMarkAsCompliantModal] = useState(false);
  const [markAsNotCompliantModal, setMarkAsNotCompliantModal] = useState(false);

  const checklistId = transactionDetailResponse.data?.checklistId!;

  const listingChecklistId =
    transactionDetailResponse.data?.listingTransaction?.checklistId;

  const getCheckList = useCallback(() => {
    if (checklistId) {
      dispatch(fetchCheckLists(checklistId));
    }
  }, [checklistId, dispatch]);

  const checklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getCheckList,
      response: checklistsById[checklistId]!,
    },
  );

  const getListingCheckList = useCallback(() => {
    if (listingChecklistId) {
      dispatch(fetchCheckLists(listingChecklistId));
    }
  }, [listingChecklistId, dispatch]);

  const listingChecklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getListingCheckList,
      response: checklistsById[listingChecklistId!]!,
    },
  );

  const checklistDetails = checklistAsyncResponse?.data;

  const listingChecklistDetails = listingChecklistAsyncResponse?.data;

  const isCompliant = useMemo(() => {
    return isCompliantForCommissionDocGeneration(checklistDetails!);
  }, [checklistDetails]);

  const isListingChecklistCompliant = useMemo(() => {
    return isCompliantForCommissionDocGeneration(listingChecklistDetails!);
  }, [listingChecklistDetails]);

  const areAllChecklistsCompliant = !!listingChecklistId
    ? isCompliant && isListingChecklistCompliant
    : isCompliant;

  const forceCompliant = useMemo(
    () => areAllChecklistsCompliant && transaction.compliantAt === null,
    [areAllChecklistsCompliant, transaction.compliantAt],
  );

  useEffect(() => {
    if (forceCompliant) {
      setMarkAsCompliantModal(true);
    }
  }, [forceCompliant]);

  const setTransactionAsCompliant = async () => {
    setLoading(true);
    if (!!transaction.compliantAt) {
      await dispatch(markAsNonCompliant(transaction?.id!));
      if (transaction?.listingTransaction?.id) {
        await dispatch(
          markAsNonCompliant(transaction?.listingTransaction?.id, false),
        );
      }
      if (transaction?.inContractTransactionId) {
        await dispatch(
          markAsNonCompliant(transaction?.inContractTransactionId, false),
        );
      }
    } else {
      await dispatch(markAsCompliant(transaction?.id!));
      if (transaction?.listingTransaction?.id) {
        await dispatch(
          markAsCompliant(transaction?.listingTransaction?.id, false),
        );
      }
      if (transaction?.inContractTransactionId) {
        await dispatch(
          markAsCompliant(transaction?.inContractTransactionId, false),
        );
      }
    }
    setLoading(false);
    setMarkAsNotCompliantModal(false);
    setMarkAsCompliantModal(false);
  };

  const LeftIconComponent = (
    <SvgIcon
      component={CompliantIcon}
      viewBox='0 0 18 18'
      className='ml-1'
      fill='#05C3F9'
    />
  );

  if (checklistAsyncResponse.loading) return null;
  return (
    <ExcludeAgent>
      <ZenButton
        label={`Mark As ${
          !!transaction.compliantAt ? 'Non-Compliant' : 'Compliant'
        }`}
        variant={forceCompliant ? 'primary' : 'secondary-gray-outline'}
        onClick={() =>
          !!transaction.compliantAt
            ? setMarkAsNotCompliantModal(true)
            : setMarkAsCompliantModal(true)
        }
        LeftIconComponent={LeftIconComponent}
      />
      <ZenSimpleConfirmationModal
        isOpen={markAsCompliantModal}
        variant='info'
        cancelButtonText={
          !!(transaction.compliantAt || areAllChecklistsCompliant)
            ? 'No'
            : 'Cancel'
        }
        size='largeOnSmallDevice'
        hideIcon
        customIcon={LeftIconComponent}
        title={
          areAllChecklistsCompliant
            ? `Do you want to mark this transaction as compliant?`
            : 'Not All Required Checklist Items Accepted'
        }
        subtitle={
          areAllChecklistsCompliant
            ? 'All required documents have been reviewed and accepted by the broker'
            : 'Not all transaction checklist required items have been accepted. Are you sure you want to mark this transaction as compliant?'
        }
        onClose={() => setMarkAsCompliantModal(false)}
        isSubmitting={isLoading}
        hideBottomButtons
      >
        <div className='flex flex-row mt-3 space-x-3'>
          <ZenButton
            label='No'
            isFullWidth
            variant='secondary-gray-outline'
            onClick={() => setMarkAsCompliantModal(false)}
          />
          <ZenButton
            LeftIconComponent={LeftIconComponent}
            label='Mark As Compliant'
            isFullWidth
            variant='primary'
            onClick={setTransactionAsCompliant}
            isDisabled={isLoading}
            isSubmitting={isLoading}
          />
        </div>
      </ZenSimpleConfirmationModal>
      <ZenSimpleConfirmationModal
        isOpen={markAsNotCompliantModal}
        variant='info'
        cancelButtonText='No'
        size='medium'
        hideIcon
        customIcon={
          <SvgIcon
            component={CompliantIcon}
            viewBox='0 0 19 19'
            className='ml-1 mt-1'
            fill='#05C3F9'
          />
        }
        title='Mark Transaction as Non-Compliant?'
        subtitle='You can always mark this transaction as compliant in the future.'
        confirmButtonText='Yes'
        onConfirm={setTransactionAsCompliant}
        onClose={() => setMarkAsNotCompliantModal(false)}
        isSubmitting={isLoading}
      />
    </ExcludeAgent>
  );
};

export default NewZenMarkAsCompliantButton;
