import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RealLogo from '../../../assets/img/new-rezen-black-logo.svg';
import { useLogout } from '../../../hooks/useLogout';
import AgentWebsiteConfirmationModal, {
  ModalVariantType,
} from '../../agentWebsiteOnboarding/AgentWebsiteConfirmationModal';
import SupportWidget from '../../support/SupportWidget';
import ZenButton from '../../Zen/ZenButton';
import { cn } from '../../../utils/classUtils';

interface WelcomeOnboardingLayoutProps {
  variant?: ModalVariantType;
  title?: string;
  hideLogout?: boolean;
  hideClose?: boolean;
  onClose?(): void;
  modalTitle?: string;
  modalSubtitle?: string;
  modalSubmitText?: string;
  modalCancelText?: string;
  hideCancelButton?: boolean;
  showStickyBottomOptions?: boolean;
}

const WelcomeOnboardingLayout: React.FC<WelcomeOnboardingLayoutProps> = ({
  variant = 'cancel',
  title,
  hideLogout = false,
  hideClose = false,
  hideCancelButton = false,
  onClose,
  children,
  modalTitle = 'Close and discard all edits?',
  modalSubtitle = "Changes won't be saved and you'll go back to the start.",
  modalSubmitText = 'Yes',
  modalCancelText = 'No',
  showStickyBottomOptions = false,
}) => {
  const logout = useLogout();
  const [cancel, setCancel] = useState<boolean>(false);
  const history = useHistory();

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white z-10'>
      <div className='flex flex-col w-full h-full scrollbar overflow-auto'>
        <header
          className={cn(
            'p-4 border-b border-coolGray-300 flex items-center',
            title ? 'justify-between' : 'justify-center',
          )}
        >
          <div className='flex items-center'>
            <img src={RealLogo} className='lg:h-7 h-5' alt='Logo' />
            {title && (
              <>
                <div className='h-7 border-l border-coolGray-300 mx-6' />
                <h1 className='font-primary-medium text-xl text-dark'>
                  {title}
                </h1>
              </>
            )}
          </div>
          {!hideClose && (
            <ZenButton
              label='Close'
              variant='secondary-outline'
              LeftIconComponent={
                <FontAwesomeIcon icon={faX} className='pr-1' />
              }
              type='button'
              onClick={() => setCancel(true)}
            />
          )}
        </header>
        <div className='flex-grow flex flex-col'>
          <div className='flex flex-col flex-1'>
            {children}
            {showStickyBottomOptions && (
              <div className='hidden lg:block'>
                {!hideLogout && (
                  <div className='md:grid grid-cols-1 gap-8 absolute bottom-0 left-8 px-4 py-8'>
                    <ZenButton
                      label='Logout'
                      variant='secondary-outline'
                      LeftIconComponent={
                        <FontAwesomeIcon icon={faArrowRightFromBracket} />
                      }
                      type='button'
                      onClick={async () => {
                        await logout();
                        history.push('/');
                      }}
                    />
                  </div>
                )}
                <div className='md:grid grid-cols-1 gap-8 absolute bottom-0 right-8 px-4 py-8'>
                  <SupportWidget />
                </div>
              </div>
            )}
          </div>
          {showStickyBottomOptions && (
            <div className='flex flex-row justify-between items-center p-4 lg:hidden'>
              <ZenButton
                label='Logout'
                variant='secondary-outline'
                LeftIconComponent={
                  <FontAwesomeIcon icon={faArrowRightFromBracket} size='lg' />
                }
                type='button'
                onClick={async () => {
                  await logout();
                  history.push('/');
                }}
              />
              <SupportWidget />
            </div>
          )}
        </div>
      </div>
      <AgentWebsiteConfirmationModal
        variant={variant}
        title={modalTitle}
        subtitle={modalSubtitle}
        isOpen={cancel}
        onClose={() => setCancel(false)}
      >
        <div className='flex flex-row justify-between space-x-5 mt-10'>
          {!hideCancelButton && (
            <ZenButton
              label={modalCancelText}
              variant='secondary-gray-outline'
              isFullWidth
              onClick={() => setCancel(false)}
            />
          )}
          <ZenButton
            label={modalSubmitText}
            variant='danger'
            type='submit'
            isFullWidth
            onClick={onClose}
          />
        </div>
      </AgentWebsiteConfirmationModal>
    </div>
  );
};

export default WelcomeOnboardingLayout;
