import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserAvailabilityResponse } from '../../../openapi/yenta';
import { saveLoggedInAgentDetails } from '../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../types';
import { cn } from '../../../utils/classUtils';
import ZenCustomSwitch from '../../Zen/Input/ZenCustomSwitch';
import { useUpdateDoNotDisturb } from '../../../query/roar/useRoar';

type DoNotDisturbProps = { agentId: string };

const DoNotDisturb: React.FC<DoNotDisturbProps> = ({ agentId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const agentDetail = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data,
  );

  const userAvailability = (agentDetail?.availability ||
    {}) as UserAvailabilityResponse;
  const timeZone =
    agentDetail?.availability?.timeZone || DateTime.local().zoneName;

  const toggle = !!userAvailability.doNotDisturb;

  const { mutate, isLoading } = useUpdateDoNotDisturb(agentId);
  return (
    <div
      className={cn(
        'p-4 border border-grey-300 rounded-lg flex items-center justify-between space-x-3 transition duration-300',
        toggle && 'bg-yellow-100 border-yellow-100',
      )}
    >
      <div>
        <p className='font-inter font-medium text-primary-dark'>
          Do Not Disturb
        </p>
        <p className='mt-1 font-inter text-grey-600'>
          You will not receive roars when this is toggled on. You can toggle off
          to resume roaring.
        </p>
      </div>
      <ZenCustomSwitch
        toggle={toggle}
        disabled={isLoading}
        onClick={() =>
          mutate(
            {
              ...userAvailability,
              timeZone,
              doNotDisturb: !toggle,
            },
            {
              onSuccess: (data) => {
                dispatch(
                  saveLoggedInAgentDetails({
                    ...agentDetail,
                    availability: data.availability,
                  }),
                );
              },
            },
          )
        }
        testId='do-not-disturb'
      />
    </div>
  );
};

export default DoNotDisturb;
