import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import {
  getTransactionTransitionToReadableName,
  isUSInternalReferralTransaction,
} from '../../../../utils/TransactionHelper';
import AdminOnly from '../../../auth/AdminOnly';
import GeminiTransactionStatusInfo from '../../../Gemini/Transaction/Header/GeminiTransactionStatusInfo';
import ZenTransactionHelpSidebar from '../../../Zen/Transaction/ZenTransactionHelpSidebar';
import ZenButton from '../../../Zen/ZenButton';

interface GeminiTransactionHeaderStatusProps {
  transaction: TransactionResponse;
  setRetryTransition(state: TransactionLifecycleStateValueStateEnum): void;
}

const GeminiTransactionHeaderStatus: React.FC<GeminiTransactionHeaderStatusProps> = ({
  transaction,
  setRetryTransition,
}) => {
  const [isHelpSidebarOpen, setIsHelpSidebarOpen] = useState<boolean>(false);

  return (
    <div className='flex-grow mt-4 lg:mt-0'>
      <div className='space-y-4'>
        <GeminiTransactionStatusInfo
          transaction={transaction}
          setIsHelpSidebarOpen={setIsHelpSidebarOpen}
        />
        {!isUSInternalReferralTransaction(
          transaction?.country!,
          transaction?.transactionType!,
        ) && (
          <AdminOnly>
            <div className='space-y-4 flex flex-col items-start justify-start'>
              {transaction.currentStateFailed && (
                <ZenButton
                  LeftIconComponent={<FontAwesomeIcon icon={faRotateRight} />}
                  type='button'
                  variant='primary-outline'
                  label={`Retry ${getTransactionTransitionToReadableName(
                    transaction,
                    transaction.lifecycleState!.state!,
                  )}`}
                  onClick={() =>
                    setRetryTransition(transaction.lifecycleState!.state!)
                  }
                />
              )}
            </div>
          </AdminOnly>
        )}
      </div>
      <ZenTransactionHelpSidebar
        isOpen={isHelpSidebarOpen}
        onClose={() => setIsHelpSidebarOpen(false)}
        transaction={transaction}
      />
    </div>
  );
};

export default GeminiTransactionHeaderStatus;
