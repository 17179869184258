import {
  faCheck,
  faFaceConfused,
  faXmark,
  faArrowDownToSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  BankActivityMatchPropertyDtoNameEnum,
  BankActivityMatchPropertyDtoRankEnum,
} from '../../../openapi/arrakis';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { cn } from '../../../utils/classUtils';

export type MatchInfoPillVariantType = BankActivityMatchPropertyDtoRankEnum;

export interface ZenMatchInfoPillProps {
  label: BankActivityMatchPropertyDtoNameEnum;
  value: string;
  variant: MatchInfoPillVariantType;
  transactionId: string;
}

const ZenMatchInfoPill: React.FC<ZenMatchInfoPillProps> = ({
  label,
  value,
  variant,
  transactionId,
}) => {
  const matchInfoPillVariantTypeToClassNameMap: {
    [type in MatchInfoPillVariantType]: string;
  } = {
    FULL: 'border border-green-600',
    PARTIAL: 'border border-zen-warning',
    MISSING: 'border border-zen-danger',
  };
  const matchInfoPillVariantTypeIcon: {
    [type in MatchInfoPillVariantType]: React.ReactElement;
  } = {
    FULL: (
      <FontAwesomeIcon
        icon={faCheck}
        className='text-green-600 text-base -mb-0.5'
      />
    ),
    PARTIAL: (
      <FontAwesomeIcon
        icon={faFaceConfused}
        className='text-yellow-600 text-base -mb-0.5'
      />
    ),
    MISSING: (
      <FontAwesomeIcon
        icon={faXmark}
        className='text-red-600 text-base -mb-0.5'
      />
    ),
  };

  const isTransactionCode =
    label === BankActivityMatchPropertyDtoNameEnum.TransactionCode;

  const Container = ({ children }: { children: React.ReactNode }) => {
    return isTransactionCode ? (
      <Link to={`/transactions/${transactionId}`} target='_blank'>
        {children}
      </Link>
    ) : (
      <div>{children}</div>
    );
  };

  return (
    <Container>
      <p className='font-zen-body font-semibold text-zen-dark-7 text-sm mb-1'>
        {capitalizeEnum(label)}
      </p>
      <div
        className={cn(
          'inline-block py-1.5 px-3 rounded-full',
          matchInfoPillVariantTypeToClassNameMap[variant],
          isTransactionCode && 'cursor-pointer',
        )}
      >
        <div className='flex items-center'>
          <div className='mr-1'>{matchInfoPillVariantTypeIcon[variant]}</div>
          {isTransactionCode && (
            <FontAwesomeIcon
              icon={faArrowDownToSquare}
              className='-rotate-90 mr-l'
            />
          )}
          <span className='font-zen-body text-zen-dark-8 text-sm font-semibold'>
            {value}
          </span>
        </div>
      </div>
    </Container>
  );
};

export default ZenMatchInfoPill;
