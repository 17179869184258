import { ArrowForward, Check, Close, History } from '@material-ui/icons';
import { findLastIndex, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Column,
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from 'react-table';
import heic2any from 'heic2any';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';
import AuthorizationContainer from '../components/auth/AuthorizationContainer';
import ChecklistItemCommentSection from '../components/Checklist/ChecklistItemCommentSection';
import EmptyTray from '../assets/img/EmptyTray.png';
import Hover from '../components/Hover';
import IconButton from '../components/IconButton';
import ResourceContainer from '../components/ResourceContainer';
import Contacts from '../components/transactions/PowerAudit/Contacts';
import PDFsPreview from '../components/transactions/PowerAudit/PDFsPreview';
import PowerAuditTransactionHeader from '../components/transactions/PowerAudit/PowerAuditTransactionHeader';
import BrokerPowerAuditConfirmationModal from '../components/Zen/Modal/BrokerPowerAuditConfirmationModal';
import ZenButton from '../components/Zen/ZenButton';
import ZenRoute from '../components/Zen/ZenRoute';
import { useFetchAsyncMappingResponse } from '../hooks/useFetchAsyncMappingResponse';
import useTransactionMentions from '../hooks/useTransactionMentions';
import { FileApi } from '../openapi/dropbox';
import {
  ChecklistApi,
  ChecklistResponse,
  ItemDtoRequiredForEnum,
  ItemDtoStatusEnum,
  ItemRequest,
  ItemResponse,
  ItemResponseStatusEnum,
} from '../openapi/sherlock';
import { RezenObjectTypeEnum } from '../openapi/yada';
import ErrorService from '../services/ErrorService';
import { fetchCheckLists, updateChecklistItem } from '../slices/CheckListSlice';
import { fetchDropbox } from '../slices/DropboxSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import {
  fetchTransactionDetails,
  markAsNonCompliant,
} from '../slices/TransactionSlice';
import { Mapping, RootState } from '../types';
import { isV2Checklist } from '../utils/ChecklistUtils';
import { getDropboxFilesFromFileReferences } from '../utils/DropboxUtil';
import {
  getArrakisConfiguration,
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import ZenChecklistItemRequiredToggle from '../components/Zen/Checklist/ZenChecklistItemRequiredToggle';
import ZenChecklistFileNameCell from '../components/Zen/Checklist/ZenChecklistFileNameCell';
import ZenResourceTable from '../components/Zen/Table/ZenResourceTable';
import ZenChecklistLabel from '../components/Zen/Transaction/ZenChecklistLabel';
import ZenSearchBar from '../components/Zen/ZenSearchBar';
import ZenTabs from '../components/Zen/ZenTabs';
import DefaultLoader from '../components/DefaultLoader';
import {
  isHeic,
  isPdfOrImg,
  isCompliantForCommissionDocGeneration,
} from '../utils/TransactionHelper';
import { getPriorityWiseSortedData } from '../utils/TableUtils';
import { setIsCollapsed } from '../slices/MainMenuSlice';
import { capitalizeEnum, getFileNameFromUrl } from '../utils/StringUtils';
import ZenSimpleConfirmationModal from '../components/Zen/Modal/ZenSimpleConfirmationModal';
import ZenTransactionPowerAuditStatusInfo from '../components/Zen/Transaction/Header/ZenTransactionPowerAuditStatusInfo';
import { ReactComponent as CompliantIcon } from '../assets/icons/compliant.svg';
import { getFileUrl } from '../utils/FileUtils';
import { performFetch } from '../utils/FetchUtil';
import { TransactionControllerApi } from '../openapi/arrakis';

interface Match {
  transactionId: string;
}
interface BrokerPowerAuditProps extends RouteComponentProps<Match> {}

export interface ChecklistData {
  checklistItemId?: string;
  checklistItemName?: string;
  id?: string;
  documentName?: string;
  url?: string;
  createdAt?: number;
}

const ChatWrapper = styled.div`
  .zen-prevent-overflow .ql-mention-list-container {
    left: 0 !important;
  }
`;

const BrokerPowerAudit: React.FC<BrokerPowerAuditProps> = ({ match }) => {
  const dispatch = useDispatch();
  const transactionId = match.params.transactionId;
  const [loadingPDF, setLoadingPDF] = useState(false);
  const history = useHistory();
  const {
    transaction: { transactionDetailResponse },
    checklist: { checklistsById },
    dropbox: { dropboxById },
    auth: { isBroker, isAdmin },
  } = useSelector((state: RootState) => state);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pdfUrls, setPdfUrls] = useState<ChecklistData[]>([]);
  const [initializeFirstPdf, setInitializeFirstPdf] = useState<boolean>(false);
  const [isRevisionRequested, setIsRevisionRequested] = useState(false);
  const [currentDoc, setCurrentDoc] = useState<ChecklistData | undefined>();
  const [currentChecklist, setCurrentChecklist] = useState<ItemResponse>();
  const [checklistFilteredDocuments, setChecklistFilteredDocuments] = useState<
    (ChecklistData[] | undefined)[]
  >([]);
  const [activeTab, setActiveTab] = useState('Details');
  const checklistId = transactionDetailResponse.data?.checklistId!;
  const transactionListingChecklistId =
    transactionDetailResponse.data?.listingTransaction?.checklistId;
  const { getMentions, callerGroupId } = useTransactionMentions();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [isItemUrgent, setIsItemUrgent] = useState<Mapping<boolean>>({});
  const [isLoading, setLoading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [
    hasAssociatedDropboxFetched,
    setHasAssociatedDropboxFetched,
  ] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [
    inContractTransactionChecklistId,
    setInContractTransactionChecklistId,
  ] = useState<string | undefined>();

  const getCheckList = useCallback(() => {
    if (checklistId) {
      dispatch(fetchCheckLists(checklistId));
    }
  }, [checklistId, dispatch]);

  const getTransactionListingCheckList = useCallback(() => {
    if (transactionListingChecklistId) {
      dispatch(fetchCheckLists(transactionListingChecklistId));
    }
  }, [transactionListingChecklistId, dispatch]);

  const getInContractTransactionCheckList = useCallback(() => {
    if (inContractTransactionChecklistId) {
      dispatch(fetchCheckLists(inContractTransactionChecklistId));
    }
  }, [inContractTransactionChecklistId, dispatch]);

  const fetchInContractTransaction = async (
    inContractTransactionId: string,
  ) => {
    try {
      const { data } = await new TransactionControllerApi(
        getArrakisConfiguration(),
      ).getTransaction(inContractTransactionId);

      setInContractTransactionChecklistId(data?.checklistId);
    } catch (e) {
      ErrorService.notify('Unable to fetch in contract transaction by id', e, {
        agent: { inContractTransactionId: inContractTransactionId },
      });
    }
  };

  useEffect(() => {
    const listingInContractTransactionId =
      transactionDetailResponse.data?.inContractTransactionId;

    if (!!listingInContractTransactionId) {
      fetchInContractTransaction(listingInContractTransactionId);
    }
  }, [transactionDetailResponse.data]);

  const checklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getCheckList,
      response: checklistsById[checklistId]!,
    },
  );

  const listingChecklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getTransactionListingCheckList,
      response: checklistsById[transactionListingChecklistId!]!,
    },
  );

  const inContractTransactionChecklistAsyncResponse = useFetchAsyncMappingResponse<ChecklistResponse>(
    {
      name: 'checklist',
      fetch: getInContractTransactionCheckList,
      response: checklistsById[inContractTransactionChecklistId!]!,
    },
  );

  const checklistDetails = checklistAsyncResponse?.data;
  const listingChecklistDetails = listingChecklistAsyncResponse?.data;
  const inContractTransactionChecklistDetails =
    inContractTransactionChecklistAsyncResponse?.data;

  // Checklist File Upload V2 Changes
  const isV2ChecklistMemo = useMemo(() => isV2Checklist(checklistDetails), [
    checklistDetails,
  ]);
  const checklistItems = useMemo(
    () => checklistsById[checklistId]?.data?.items || [],
    [checklistId, checklistsById],
  );
  const dropboxId = transactionDetailResponse?.data?.dropboxId!;
  const setAsNonCompliant = async () => {
    setIsSubmitting(true);
    if (transactionDetailResponse.data?.id) {
      await dispatch(markAsNonCompliant(transactionDetailResponse.data?.id));
    }
    if (transactionDetailResponse?.data?.listingTransaction?.id) {
      await dispatch(
        markAsNonCompliant(
          transactionDetailResponse?.data?.listingTransaction?.id,
          false,
        ),
      );
    }
    if (transactionDetailResponse?.data?.inContractTransactionId) {
      await dispatch(
        markAsNonCompliant(
          transactionDetailResponse?.data?.inContractTransactionId,
          false,
        ),
      );
    }
    setIsSubmitting(false);
    setShowConfirmation(false);
  };
  const showNonCompliantButton = useMemo(() => {
    return !!transactionDetailResponse?.data?.compliantAt!;
  }, [transactionDetailResponse?.data?.compliantAt]);

  const checklistItemIdMemo = useMemo(() => checklistDetails?.id, [
    checklistDetails?.id,
  ]);

  useEffect(() => {
    // find first checklist item with status as pending
    if (checklistItemIdMemo && checklistDetails && !initializeFirstPdf) {
      const firstPendingItem =
        checklistDetails?.items?.find(
          (item) => item.status === ItemResponseStatusEnum.Pending,
        )?.position ?? 1;
      if (firstPendingItem !== 0) {
        setCurrentIndex(firstPendingItem - 1);
      }
    }
  }, [checklistDetails, checklistItemIdMemo, initializeFirstPdf]);

  useEffect(() => {
    dispatch(setIsCollapsed(true));
    dispatch(fetchTransactionDetails(transactionId));
  }, [dispatch, transactionId]);

  const isChecklistCompliant = useMemo(() => {
    return isCompliantForCommissionDocGeneration(checklistDetails!);
  }, [checklistDetails]);

  const isListingChecklistCompliant = useMemo(() => {
    return isCompliantForCommissionDocGeneration(listingChecklistDetails!);
  }, [listingChecklistDetails]);

  const isInContractTransactionChecklistCompliant = useMemo(() => {
    return isCompliantForCommissionDocGeneration(
      inContractTransactionChecklistDetails!,
    );
  }, [inContractTransactionChecklistDetails]);

  const areAllChecklistsCompliant = inContractTransactionChecklistId // If listing has in contract transaction
    ? isInContractTransactionChecklistCompliant && isChecklistCompliant
    : transactionListingChecklistId // If transaction has listing
    ? isListingChecklistCompliant && isChecklistCompliant
    : isChecklistCompliant; // If only transaction or listing

  const bulkFetchDropboxDocuments = useCallback(async () => {
    setLoadingPDF(true);
    const checklistDocuments = (checklistDetails?.items || [])?.map((item) => {
      const checklistItemName = item?.name!;
      const checklistItemId = item?.id!;

      if (item?.fileReferences?.references?.length !== 0)
        return getDropboxFilesFromFileReferences(
          item?.fileReferences?.references!,
          dropboxById!,
          dropboxId!,
        )
          ?.filter((document) => isPdfOrImg(document?.filename!))
          ?.map((item) => {
            const file = (dropboxById[dropboxId]?.files || [])?.find(
              (f) => f?.id === item?.id,
            );
            return {
              checklistItemId,
              checklistItemName,
              id: item?.id,
              fileVersionId: item?.currentVersion?.id!,
              documentName: item?.filename!,
              createdAt: file?.createdAt,
            };
          });

      return [];
    });

    setChecklistFilteredDocuments(checklistDocuments);

    const documentUrls = [];

    for (let i = 0; i < checklistDocuments[currentIndex]?.length!; i++) {
      try {
        const documentInfo = checklistDocuments[currentIndex]?.[i]!;
        const { data } = await new FileApi(
          getDropboxConfiguration(),
        ).getFileVersionUrl(documentInfo?.id!, documentInfo?.fileVersionId!);

        const fileName = getFileNameFromUrl(data) || documentInfo.documentName!;

        if (isHeic(fileName)) {
          const fileURL = await getFileUrl(data);

          documentUrls.push({
            url: fileURL,
            ...documentInfo,
          });
        } else {
          documentUrls.push({
            url: data,
            ...documentInfo,
          });
        }
      } catch (e) {
        dispatch(showErrorToast('Unable to download the file'));
        ErrorService.notify('Unable to download the checklist document', e, {
          checklistDocument: { id: checklistDocuments[currentIndex]?.[i]?.id! },
        });
      }
    }
    setLoadingPDF(false);

    setPdfUrls(documentUrls);
  }, [checklistDetails?.items, dropboxById, dropboxId, currentIndex, dispatch]);

  const bulkFetchDocuments = useCallback(async () => {
    setLoadingPDF(true);
    const checklistDocuments = (checklistDetails?.items || [])
      ?.map((item) => {
        const checklistItemName = item?.name!;
        const checklistItemId = item?.id!;

        if ((item?.documents || [])?.length !== 0)
          return item?.documents
            ?.filter((document) => isPdfOrImg(document?.name!))
            ?.map((item) => {
              return {
                checklistItemId,
                checklistItemName,
                id: item?.currentVersion?.id!,
                documentName: item?.name!,
                createdAt: item?.createdAt,
              };
            });

        return [];
      })
      .filter((item) => (item || [])?.length !== 0);
    setChecklistFilteredDocuments(checklistDocuments);

    const documentUrls = [];

    for (let i = 0; i < checklistDocuments[currentIndex]?.length!; i++) {
      try {
        const documentInfo = checklistDocuments[currentIndex]?.[i]!;
        const { data } = await new ChecklistApi(
          getSherlockConfiguration(),
        ).getVersionUrl(documentInfo?.id!);

        const fileName = getFileNameFromUrl(data) || documentInfo.documentName!;

        if (isHeic(fileName)) {
          const response = await performFetch(data);
          const heicBlob = await response.blob();
          // Convert HEIC to JPEG
          const jpegBuffer = await heic2any({ blob: heicBlob });
          const val = jpegBuffer instanceof Blob ? jpegBuffer : jpegBuffer[0];
          const jpegUrl = URL.createObjectURL(val);

          documentUrls.push({
            url: jpegUrl,
            ...documentInfo,
          });
        } else {
          documentUrls.push({
            url: data,
            ...documentInfo,
          });
        }
      } catch (e) {
        dispatch(showErrorToast('Unable to download the file'));
        ErrorService.notify('Unable to download the checklist document', e, {
          checklistDocument: { id: checklistDocuments[currentIndex]?.[i]?.id! },
        });
      }
    }
    setLoadingPDF(false);
    setPdfUrls(documentUrls);
  }, [checklistDetails?.items, currentIndex, dispatch]);

  const fetchDocuments = useCallback(() => {
    if (isV2ChecklistMemo) {
      bulkFetchDropboxDocuments();
    } else {
      bulkFetchDocuments();
    }
  }, [bulkFetchDocuments, bulkFetchDropboxDocuments, isV2ChecklistMemo]);

  useEffect(() => {
    // Check if currentIndex is 0 else useEffect below will handle it

    // isDownloaded checks if useEffect hasn't executed earlier and will fetch the documents and set this to true. This is to avoid multiple fetches

    // Check if Checklist is V2 and Associated Dropbox files are already fetched (fetchAllAssociatedDropbox) this is not needed for V1 as we fetch the documents directly

    if (
      checklistItemIdMemo &&
      !initializeFirstPdf &&
      !isDownloaded &&
      ((isV2ChecklistMemo && hasAssociatedDropboxFetched) || !isV2ChecklistMemo)
    ) {
      fetchDocuments();
      setIsDownloaded(true);
    }
  }, [
    checklistItemIdMemo,
    initializeFirstPdf,
    isDownloaded,
    isV2ChecklistMemo,
    hasAssociatedDropboxFetched,
    fetchDocuments,
  ]);

  useEffect(() => {
    if (checklistItemIdMemo && (currentIndex !== 0 || isDownloaded)) {
      fetchDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const fetchAllAssociatedDropbox = useCallback(async () => {
    const checklistTemplateReferences = checklistItems
      ?.map((ele) => ele?.templateReferences?.references!)
      .flat();
    const templateReferenceDropboxIds = checklistTemplateReferences?.map(
      (ele) => ele.dropboxId!,
    );
    await Promise.all(
      uniq([...templateReferenceDropboxIds, dropboxId]).map((fileCabinetId) =>
        dispatch(fetchDropbox(fileCabinetId!)),
      ),
    );
    setHasAssociatedDropboxFetched(true);
  }, [checklistItems, dropboxId, dispatch]);

  useEffect(() => {
    if (isV2ChecklistMemo) {
      fetchAllAssociatedDropbox();
    }
  }, [fetchAllAssociatedDropbox, isV2ChecklistMemo]);

  const updateDoc = (
    checklistItemId: string,
    checklistItemName: string,
    documentName: string,
    id: string,
  ) => {
    if (currentDoc?.id !== id) {
      setCurrentDoc({
        checklistItemId,
        checklistItemName,
        documentName,
        id,
      });
      setCurrentChecklist(
        (checklistDetails?.items || [])?.find(
          (item) => item?.id === checklistItemId,
        ),
      );
    }
  };

  const initialize = useCallback(() => {
    if (
      currentDoc?.id === pdfUrls[pdfUrls.length - 1]?.id &&
      !initializeFirstPdf &&
      !!checklistDetails
    ) {
      setCurrentDoc({ ...pdfUrls[0] });
      setCurrentChecklist(
        (checklistDetails?.items || [])?.find(
          (item) => item?.id === pdfUrls[0].checklistItemId,
        ),
      );
      setInitializeFirstPdf(true);
    }
  }, [checklistDetails, currentDoc, initializeFirstPdf, pdfUrls]);

  const toggleIsUrgent = useCallback(
    async (item: ItemResponse) => {
      if (!item.id) return;

      setIsItemUrgent((map) => ({
        ...map,
        [item.id!]: true,
      }));
      const checklistItemReq: ItemRequest = {
        item: {
          ...(item || {}),
          position: item?.position,
          name: item?.name!,
          status: (item?.status as unknown) as ItemDtoStatusEnum,
          urgent: !item?.urgent,
          assignee: item?.assignee,
          required: item?.required,
          dueDate: item?.dueDate,
          labels: item?.labels,
          requiredFor: (item?.requiredFor as unknown) as ItemDtoRequiredForEnum,
        },
      };
      await dispatch(
        updateChecklistItem(checklistId, item.id!, checklistItemReq, false),
      );
      setTimeout(() => {
        setIsItemUrgent((map) => ({
          ...map,
          [item.id!]: false,
        }));
      }, 1000);
    },
    [checklistId, dispatch],
  );

  useEffect(() => {
    if (pdfUrls.length !== 0 && !!currentDoc) initialize();
  }, [currentDoc, initialize, pdfUrls]);

  const getNextAvailableIndex = () => {
    for (
      let i = currentIndex + 1;
      i < checklistFilteredDocuments.length!;
      i++
    ) {
      if (checklistFilteredDocuments[i]?.length! > 0) {
        return i;
      }
    }
    return -1;
  };
  const getPreviousAvailableIndex = () => {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (checklistFilteredDocuments[i]?.length! > 0) {
        return i;
      }
    }
    return -1;
  };

  const moveToNextDocument = () => {
    if (currentIndex < checklistFilteredDocuments.length! - 1) {
      const nextIndex = getNextAvailableIndex();
      setCurrentIndex(nextIndex);
    } else {
      setCurrentIndex(-1);
    }
  };
  const moveToPreviousDocument = () => {
    if (currentIndex > 0) {
      const previousIndex = getPreviousAvailableIndex();
      setCurrentIndex(previousIndex);
    } else {
      setCurrentIndex(-1);
    }
  };
  const hasNextDocument = () => {
    const lastAvailableIndex = findLastIndex(
      checklistFilteredDocuments,
      (item) => item?.length! > 0,
    );
    return currentIndex < lastAvailableIndex;
  };

  const onSubmit = async (accepted: boolean) => {
    if (!currentDoc?.checklistItemId) return;

    setLoadingPDF(true);
    const checklistData = (checklistDetails?.items || [])?.find(
      (item) => item?.id === currentDoc?.checklistItemId,
    );

    const checklistItemReq: ItemRequest = {
      item: {
        ...(checklistData || {}),
        position: checklistData?.position,
        name: checklistData?.name!,
        status: accepted
          ? ItemDtoStatusEnum.Accepted
          : ItemDtoStatusEnum.RevisionRequested,
        urgent: checklistData?.urgent,
        assignee: checklistData?.assignee,
        required: checklistData?.required,
        dueDate: checklistData?.dueDate,
        labels: checklistData?.labels,
        requiredFor: (checklistData?.requiredFor as unknown) as ItemDtoRequiredForEnum,
      },
    };

    await dispatch(
      updateChecklistItem(
        checklistDetails?.id!,
        currentDoc?.checklistItemId!,
        checklistItemReq,
      ),
    );
    moveToNextDocument();
    setLoadingPDF(false);
  };

  useEffect(() => {
    if (checklistFilteredDocuments.length === 0) {
      setCurrentDoc(undefined);
    }
  }, [checklistFilteredDocuments]);

  useEffect(() => {
    setLoading(false);
  }, [currentChecklist, currentIndex]);

  const handleChecklistItemClicked = useCallback(
    (item: ItemResponse) => {
      const itemPosition = item.position! - 1;
      if (currentIndex !== itemPosition) {
        setLoading(true);
        setCurrentIndex(item.position! - 1);
        setCurrentChecklist(item);
      }
    },
    [currentIndex],
  );

  const columns: Array<Column<ItemResponse>> = useMemo(() => {
    const checkListItems: Array<Column<ItemResponse>> = [
      {
        Header: 'Checklist Item',
        accessor: 'name',
        Cell: ({ value, row: { original } }) => {
          const isV2ChecklistItemDocVisible =
            isV2ChecklistMemo &&
            original?.status === ItemResponseStatusEnum.Pending;
          const isNoOfDocsCountVisible =
            !isV2ChecklistMemo || isV2ChecklistItemDocVisible;

          return (
            <div
              className='cursor-pointer flex w-full gap-1'
              onClick={() => {
                handleChecklistItemClicked(original);
              }}
            >
              <div className='w-1/2'>
                <ZenChecklistFileNameCell
                  name={original.position + '. ' + value!}
                  labels={original.labels?.map((l) => l.text!) || []}
                  urgent={original.urgent || false}
                  onFlagClick={() => toggleIsUrgent(original)}
                  isItemUrgent={isItemUrgent[original.id!]!}
                />
                <div className='cursor-pointer mt-5 mb-1'>
                  <ZenChecklistLabel
                    status={original.status!}
                    isRequired={original?.required!}
                  />
                </div>
              </div>
              {(isBroker || isAdmin) && (
                <div className='flex gap-4 items-center h-full w-1/2'>
                  {isNoOfDocsCountVisible && (
                    <div className='w-4 h-4 flex items-center justify-center'>
                      <div
                        className='h-4 w-auto flex items-center justify-center rounded-full bg-zen-dark-10'
                        data-testid='no-of-docs-count'
                      >
                        <p className='font-zen-body text-xs text-white px-2'>
                          {isV2ChecklistMemo
                            ? (original?.fileReferences?.references || [])
                                ?.length
                            : (original?.documents || [])?.length}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className='flex flex-col justify-end w-full'>
                    <div className='pt-5 self-end'>
                      <ZenChecklistItemRequiredToggle
                        checklistId={checklistId}
                        checklistItem={original}
                      />
                    </div>
                    <div className='cursor-pointer font-medium self-end text-xs text-right mt-2 mr-4'>
                      {original?.requiredFor ? (
                        capitalizeEnum(original?.requiredFor)
                      ) : (
                        <FontAwesomeIcon
                          icon={regular('dash')}
                          title='dash'
                          className='pl-4'
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        },
        sortType: (row1, row2) =>
          getPriorityWiseSortedData(
            row1.values.position + ' ' + row1.values.name,
            row2.values.position + ' ' + row2.values.name,
            [],
          ),
      },
    ];
    return checkListItems;
  }, [
    checklistId,
    handleChecklistItemClicked,
    toggleIsUrgent,
    isAdmin,
    isBroker,
    isItemUrgent,
    isV2ChecklistMemo,
  ]);

  const onViewTransactionClick = () => {
    history.push(
      `/transactions/${transactionDetailResponse.data?.id}/checklist`,
    );
  };
  const filterChecklist = useMemo(() => {
    const checklist = (checklistDetails?.items || [])?.filter(
      (item) =>
        !item?.hidden &&
        item?.name?.toLowerCase().includes(search?.toLowerCase() || ''),
    );
    if (!search) {
      return checklist.map((item, index) => ({ ...item, position: index + 1 }));
    }
    return checklist;
  }, [checklistDetails?.items, search]);

  const tableInstance = useTable(
    {
      columns,
      data: filterChecklist,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      initialState: {
        pageIndex: 0,
        pageSize: 1,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    tableInstance?.setPageSize(
      !!filterChecklist?.length ? filterChecklist?.length : 1,
    );
  }, [filterChecklist?.length, tableInstance]);

  return (
    <ZenRoute title='Power Audit'>
      <div className='p-2 bg-zen-dark-13 flex flex-row items-center justify-between sticky top-0 z-50'>
        <div className='text-white text-center text-lg w-full'>Power Audit</div>
        <IconButton
          onClick={onViewTransactionClick}
          leftIcon={<Close data-testid='close' />}
          buttonStyle='text-white'
          variant='none'
        />
      </div>
      <AuthorizationContainer asyncResponse={transactionDetailResponse}>
        <ResourceContainer
          isEmpty={!transactionDetailResponse.data}
          loading={transactionDetailResponse.loading}
          errorCode={transactionDetailResponse.error}
          resourceName='Transaction Detail'
        >
          <div className='grid grid-flow-col grid-cols-4 overflow-hidden'>
            <div className='col-span-1 border-r h-[95vh] scrollbar overflow-y-auto'>
              <div className='p-2'>
                <div className='mb-4 my-1'>
                  <ZenSearchBar
                    placeholder='Search checklist item'
                    onChange={setSearch}
                    value={search || ''}
                  />
                </div>
                <div>
                  <ZenResourceTable<ItemResponse>
                    {...tableInstance}
                    resourceName='Checklist'
                    emptyIconComponent={
                      <img
                        src={EmptyTray}
                        alt='EmptyTrayImage'
                        className='h-20'
                      />
                    }
                    hidePagination
                    totalCount={filterChecklist?.length || 0}
                    checklistId={checklistId}
                    dropboxId={dropboxId}
                  />
                </div>
              </div>
            </div>
            <div className='relative col-span-2 z-40'>
              <div className='h-[95vh] bg-[#F3F3F3] scrollbar overflow-y-auto'>
                {checklistDetails && pdfUrls.length !== 0 && !loadingPDF && (
                  <div className='p-4 bg-grey-100 border-b border-b-zen-dark-4 flex flex-row items-center sticky top-0 z-50'>
                    <p className='text-xl'>
                      {currentDoc?.documentName} (
                      {currentDoc?.checklistItemName})
                    </p>
                  </div>
                )}

                <AuthorizationContainer asyncResponse={checklistAsyncResponse}>
                  <ResourceContainer
                    isEmpty={pdfUrls.length === 0}
                    loading={loadingPDF}
                    resourceName='Document'
                  >
                    <PDFsPreview
                      pdfUrls={pdfUrls?.sort(
                        (a, b) => b?.createdAt! - a?.createdAt!,
                      )}
                      updateDoc={updateDoc}
                    />
                  </ResourceContainer>
                </AuthorizationContainer>
                {checklistDetails && pdfUrls.length !== 0 && !loadingPDF && (
                  <div className='p-4 bg-zen-light-gray-2 flex flex-row items-center justify-around absolute bottom-0 border-t border-t-zen-dark-4 left-0 right-0 z-50'>
                    <div className='w-1/5'>
                      {currentIndex > 0 && (
                        <ZenButton
                          label='Previous'
                          variant='primary-outline'
                          isFullWidth
                          onClick={moveToPreviousDocument}
                        />
                      )}
                    </div>
                    <div className='flex flex-row justify-center items-center flex-grow flex-shrink w-full max-w-sm mx-auto px-4 space-x-2'>
                      <p className='font-zen-body font-semibold text-sm text-zen-dark-9'>
                        {currentDoc?.checklistItemName}
                      </p>
                      {checklistDetails?.items?.[currentIndex]?.status !==
                        ItemResponseStatusEnum.Accepted && (
                        <div className='flex flex-row items-center space-x-2'>
                          <Hover
                            hoverComponent={
                              <p className='font-zen-body font-medium text-sm text-zen-dark-9 px-2'>
                                Accept
                              </p>
                            }
                            config={{ placement: 'top' }}
                          >
                            <IconButton
                              onClick={() => onSubmit(true)}
                              leftIcon={
                                <Check
                                  data-testid='Accept'
                                  className='text-green-600'
                                />
                              }
                              buttonStyle='bg-green-100 bg-opacity-10 border border-green-600 rounded-lg hover:bg-opacity-20'
                              variant='none'
                            />
                          </Hover>
                          <Hover
                            hoverComponent={
                              <p className='font-zen-body font-medium text-sm text-zen-dark-9 px-2'>
                                Request for Revision
                              </p>
                            }
                            config={{ placement: 'top' }}
                          >
                            <IconButton
                              onClick={() => setIsRevisionRequested(true)}
                              leftIcon={
                                <History
                                  data-testid='Request'
                                  className='text-zen-danger'
                                />
                              }
                              buttonStyle='bg-zen-danger-light bg-opacity-10 border border-zen-danger rounded-lg hover:bg-opacity-20'
                              variant='none'
                            />
                          </Hover>
                          <BrokerPowerAuditConfirmationModal
                            isOpen={isRevisionRequested}
                            onClose={() => setIsRevisionRequested(false)}
                            onSubmit={() => onSubmit(false)}
                            checklistId={currentDoc?.checklistItemId!}
                            transaction={transactionDetailResponse.data!}
                            checklistName={currentDoc?.checklistItemName!}
                          />
                        </div>
                      )}
                    </div>
                    <div className='w-1/5'>
                      <ZenButton
                        label='Next'
                        variant='primary'
                        isFullWidth
                        onClick={moveToNextDocument}
                        isDisabled={!hasNextDocument()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ChatWrapper className='col-span-1 border-l static h-[95vh] overflow-x-hidden'>
              {isLoading ? (
                <DefaultLoader />
              ) : (
                <ZenTabs
                  selected={activeTab}
                  onChange={setActiveTab}
                  size='sm'
                  tabs={[
                    {
                      name: 'Details',
                      TabComponent: (
                        <div className='w-full'>
                          <PowerAuditTransactionHeader
                            transaction={transactionDetailResponse.data!}
                            checklistName={checklistDetails?.name!}
                          />
                          <div className='p-3'>
                            {areAllChecklistsCompliant &&
                              (!showNonCompliantButton ? (
                                <ZenTransactionPowerAuditStatusInfo
                                  description='All required checklist items have been accepted (no revision requested or pending items).'
                                  title='Approved & Ready to be Marked Compliant'
                                  transaction={transactionDetailResponse.data!}
                                />
                              ) : (
                                <ZenButton
                                  LeftIconComponent={
                                    <SvgIcon
                                      component={CompliantIcon}
                                      viewBox='0 0 18 18'
                                      className='ml-1'
                                      fill='#05C3F9'
                                    />
                                  }
                                  onClick={() => setShowConfirmation(true)}
                                  label='Mark As Non-Compliant'
                                  variant='dark-outline'
                                  isFullWidth
                                />
                              ))}
                            {showConfirmation && (
                              <ZenSimpleConfirmationModal
                                isOpen={showConfirmation}
                                variant='info'
                                cancelButtonText='No'
                                size='medium'
                                hideIcon
                                customIcon={
                                  <SvgIcon
                                    component={CompliantIcon}
                                    viewBox='0 0 19 19'
                                    className='ml-1 mt-1'
                                    fill='#05C3F9'
                                  />
                                }
                                title='Mark Transaction as Compliant ?'
                                subtitle='You can always mark this transaction as Compliant in the future.'
                                confirmButtonText='Yes'
                                onConfirm={() => {
                                  setAsNonCompliant();
                                }}
                                onClose={() => setShowConfirmation(false)}
                                isSubmitting={isSubmitting}
                              />
                            )}
                            <div className='pt-4'>
                              <ZenButton
                                RightIconComponent={<ArrowForward />}
                                onClick={onViewTransactionClick}
                                label='View Transaction'
                                variant='primary-outline'
                                isFullWidth
                              />
                            </div>
                          </div>
                          <Contacts
                            transaction={transactionDetailResponse.data!}
                          />
                        </div>
                      ),
                    },
                    {
                      name: 'Comments',
                      TabComponent: !!currentChecklist && (
                        <div className='mt-5 px-4' aria-label='side-menu'>
                          <ChecklistItemCommentSection
                            checklistItem={currentChecklist}
                            containerType={RezenObjectTypeEnum.Transaction}
                            containerId={transactionId}
                            getMentions={getMentions}
                            callerGroupId={callerGroupId}
                            powerAudit
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              )}
            </ChatWrapper>
          </div>
        </ResourceContainer>
      </AuthorizationContainer>
    </ZenRoute>
  );
};
export default BrokerPowerAudit;
