import { FieldPath, FieldValues, useController } from 'react-hook-form';
import DnDInput, { DnDInputProps } from '../DnDInput';
import { HookFormControllerProps } from './types';

type HookFormDndInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = HookFormControllerProps<TFieldValues, TName> & Partial<DnDInputProps>;

const HookFormDndInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: HookFormDndInputProps<TFieldValues, TName>,
) => {
  const { field } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <DnDInput
      {...props}
      value={field.value}
      onChange={(value) => {
        field.onChange(value);
        props.onChange?.(value);
      }}
    />
  );
};

export default HookFormDndInput;
