import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CourseItem, LeoResponseMessageContext } from '../../../utils/LeoUtils';

type NeoLeoCoursesProps = {
  msgContext: LeoResponseMessageContext;
};

const NeoLeoCourses: React.FC<NeoLeoCoursesProps> = ({ msgContext }) => {
  // let courses: CourseItem[] = sampleCourses;
  let courses: CourseItem[] = [];
  if (msgContext && msgContext?.references?.courses?.length > 0) {
    courses = msgContext.references.courses.filter(
      (el) => !!el && !!Object.keys(el).length,
    );
  }

  if (!courses.length) {
    return null;
  }

  return (
    <div className='text-white font-inter flex flex-col gap-3'>
      <p className='font-normal text-sm'>Recommended Courses</p>
      {courses.map((course) => (
        <div className='border border-white rounded-md p-3' key={course.id}>
          <p className='text-base font-medium tracking-tight mb-3'>
            {course.title}
          </p>
          <p className='text-sm font-normal line-clamp-2 text-white text-opacity-80 mb-2'>
            {course.content}
          </p>
          <p className='font-semibold text-sm text-white text-opacity-30 mb-3'>
            Real Academy
          </p>
          <a
            href={course.url!}
            className='text-sm text-aqua font-medium align-middle mt-2'
          >
            Link to Course <FontAwesomeIcon icon={faArrowRight} />
          </a>
        </div>
      ))}
    </div>
  );
};

export default NeoLeoCourses;
