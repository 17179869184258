import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainSidebar from '../containers/MainSidebar';
import { RootState } from '../types';
import { AgentResponseAgentStatusEnum } from '../openapi/yenta';
import Logger from '../utils/Logger';
import UseBrokerQueue from '../hooks/useBrokerQueue';
import { isTransactionDetailRouteActive } from '../utils/TransactionUtils';
import MainFooter from '../containers/MainFooter';
import { getLoginURLWithRedirection } from '../utils/NavigationHelper';
import { cn } from '../utils/classUtils';
import BrokerQueueEmptyRoute from './BrokerQueueEmptyRoute';
import FutureMaintenanceAlert from './FutureMaintenanceAlert';
import BreakRoomIndex from './Kbar/BreakRoom/BreakRoomIndex';
import KBar from './Kbar/KBar';
import useShowLeoTrigger from './Zen/Leo/useShowLeoTrigger';

export interface AuthRouteProps extends RouteProps {
  hideFutureMaintenanceAlert?: boolean;
}

const AuthenticatedAdminRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  hideFutureMaintenanceAlert = false,
  ...rest
}) => {
  const {
    userDetail,
    isSuperAdmin,
    isAdmin,
    keymakerCurrentUser,
  } = useSelector((state: RootState) => state.auth);
  const { isBrokerQueueActive } = UseBrokerQueue();
  const { showNeoLeoPanel } = useShowLeoTrigger();

  const showBrokerQueueEmptyRoute =
    isBrokerQueueActive && !isTransactionDetailRouteActive();

  return (
    <KBar>
      <Route
        {...rest}
        render={(props) => {
          if (!userDetail) {
            Logger.debug(
              'AuthenticatedAdminRoute: no user detail, going to login',
            );
            return <Redirect to={getLoginURLWithRedirection()} />;
          }

          if (
            userDetail?.agentStatus === AgentResponseAgentStatusEnum.Candidate
          ) {
            Logger.debug(
              'AuthenticatedAdminRoute: user is candidate, going to application form',
            );
            return <Redirect to='/onboarding/application-form' />;
          }

          if (keymakerCurrentUser?.forceMfa && !keymakerCurrentUser?.mfaType) {
            Logger.debug(
              'AuthenticatedAdminRoute: user has not enabled two factor authentication, going to enable 2FA page',
            );
            return <Redirect to='/enable-2fa' />;
          }

          if (!isAdmin) {
            Logger.debug(
              'AuthenticatedAdminRoute: user is not admin, going to mobile app page',
            );

            if (isSuperAdmin) {
              return <Redirect to='/people' />;
            }

            return <Redirect to='/transactions' />;
          }

          return (
            <div
              className={cn(
                'flex min-h-screen',
                showNeoLeoPanel ? 'flex-row' : 'flex-col lg:flex-row',
              )}
            >
              <MainSidebar />
              <div
                id='main-page'
                className='flex-grow lg:max-h-screen scrollbar overflow-y-auto overflow-x-hidden'
              >
                <div className='min-h-[calc(100vh_-_50px)]'>
                  {!hideFutureMaintenanceAlert && <FutureMaintenanceAlert />}
                  {showBrokerQueueEmptyRoute ? (
                    <BrokerQueueEmptyRoute />
                  ) : (
                    /* @ts-ignore */
                    <Component {...props} />
                  )}
                </div>
                <MainFooter />
              </div>
            </div>
          );
        }}
      />
      <BreakRoomIndex />
    </KBar>
  );
};

export default AuthenticatedAdminRoute;
