import React from 'react';
import { displayDate } from '../../utils/DateUtils';
import { SessionHistory } from '../Zen/Leo/ChatHistory/LeoChatHistory';
import NeoHistoryCard from './NeoHistoryCard';

interface NeoLeoHistoryByDayCardProps {
  history: SessionHistory[];
  hideHistory(): void;
}

const NeoLeoHistoryByDayCard: React.FC<NeoLeoHistoryByDayCardProps> = ({
  history,
  hideHistory,
}) => {
  return (
    <div className='flex flex-col mb-5'>
      <p className='flex-grow text-regent-600 text-sm font-medium font-inter'>
        {displayDate(history[0].start_time)}
      </p>
      <div className='flex-grow'>
        {history.map((historyData) => (
          <NeoHistoryCard
            key={historyData.id}
            hideHistory={hideHistory}
            {...historyData}
          />
        ))}
      </div>
    </div>
  );
};

export default NeoLeoHistoryByDayCard;
