import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import {
  AgreementSigningResponse,
  AgreementAmendmentResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { fetchAllLatestAgreementsNotSigned } from '../slices/AgentSlice';
import { showApiErrorModal } from '../slices/ErrorSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';

export const useSignAgreementAmendment = (userId: string) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSign = useCallback(
    async (
      signFunction: () => Promise<
        AxiosResponse<AgreementSigningResponse | AgreementAmendmentResponse>
      >,
    ) => {
      setIsLoading(true);
      try {
        await signFunction();
        dispatch(fetchAllLatestAgreementsNotSigned());
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(
          showErrorToast(
            'Unable to sign the document. Please try again later.',
          ),
        );
        ErrorService.notifyIgnoreHandled('Error signing document', e, {
          user: { id: userId },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, userId],
  );

  return { handleSign, isLoading };
};
