import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModalForm from '../../hooks/useModalForm';
import {
  GroupMemberResponse,
  GroupMemberResponseRoleEnum,
  OfficeResponseVoiceCallPriorityOrderEnum,
  UpdateOfficeRequestVoiceCallPriorityOrderEnum,
  VoiceCallHoldTimeGroupEnum,
} from '../../openapi/yenta';
import { updateOffice } from '../../slices/OfficeSlice';
import { AppDispatch, RootState } from '../../types';
import { capitalizeEnum } from '../../utils/StringUtils';
import { Button } from '../commons/Button';
import HookFormDndInput from '../commons/hookFormInputs/HookFormDndInput';
import { HookFormSelectInput } from '../commons/hookFormInputs/HookFormSelectInput';
import { Modal } from '../commons/Modal';

enum HoldTimeSecsEnum {
  TEN = '10',
  FIFTEEN = '15',
  TWENTY = '20',
}

const DEFAULT_HOLD_TIME = 20;

const timesList = [
  { label: '10 Seconds', value: HoldTimeSecsEnum.TEN },
  { label: '15 Seconds', value: HoldTimeSecsEnum.FIFTEEN },
  { label: '20 Seconds', value: HoldTimeSecsEnum.TWENTY },
];

interface SetCallPriorityModalProps {
  onClose(): void;
  officeId: string;
  groups?: GroupMemberResponse[];
  opened: boolean;
}

interface IFormData {
  callPriority: OfficeResponseVoiceCallPriorityOrderEnum[];
  holdTime: HoldTimeSecsEnum;
}

const SetCallPriorityModal: React.FC<SetCallPriorityModalProps> = ({
  onClose,
  officeId,
  groups,
  opened,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const officeDetail = useSelector(
    (state: RootState) => state.office.officeDetail,
  );

  const holdTimeSecs =
    officeDetail?.voiceCallHoldTimeSecs?.[0]?.holdTimeSecs || DEFAULT_HOLD_TIME;

  const defaultCallPriorityOrder = useMemo(() => {
    const roles = groups?.map((group) => group.role!);
    if (!roles?.length) {
      return [OfficeResponseVoiceCallPriorityOrderEnum.DesignatedBroker];
    }
    const arr = [
      OfficeResponseVoiceCallPriorityOrderEnum.DesignatedBroker,
      OfficeResponseVoiceCallPriorityOrderEnum.ManagingBroker,
      OfficeResponseVoiceCallPriorityOrderEnum.RegionalBroker,
    ].filter((role) =>
      roles.includes((role as unknown) as GroupMemberResponseRoleEnum),
    );
    return sortBy(
      arr,
      (a, b) =>
        roles.indexOf((a as unknown) as GroupMemberResponseRoleEnum) -
        roles.indexOf((b as unknown) as GroupMemberResponseRoleEnum),
    );
  }, [groups]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useModalForm<IFormData>(
    {
      defaultValues: {
        callPriority:
          officeDetail?.voiceCallPriorityOrder ?? defaultCallPriorityOrder,
        holdTime: holdTimeSecs.toString() as HoldTimeSecsEnum,
      },
    },
    opened,
  );

  const onSubmit = async (formData: IFormData) => {
    const isSuccess = await dispatch(
      updateOffice(officeId, {
        voiceCallPriorityOrder: (formData.callPriority as unknown) as UpdateOfficeRequestVoiceCallPriorityOrderEnum[],
        voiceCallHoldTimeSecs: [
          {
            group: VoiceCallHoldTimeGroupEnum.BrokerTeam,
            holdTimeSecs: +formData.holdTime,
          },
        ],
      }),
    );
    if (isSuccess) {
      onClose();
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size='605px'
      aria-label='call-priority-modal'
    >
      <Modal.Header className='text-center bg-white border-b border-[#ccc]'>
        <span className='pl-6 text-xl'>Set Call Priority</span>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content className='p-6 pb-10'>
          <HookFormDndInput
            control={control}
            name='callPriority'
            formatValue={capitalizeEnum}
          />
          <div className='mt-6'>
            <HookFormSelectInput
              control={control}
              data={timesList}
              name='holdTime'
              classNames={{ label: 'mb-1 font-light font-inter text-lg' }}
              label='Select Maximum Waiting Time for Agent'
            />
          </div>
        </Modal.Content>
        <Modal.Footer className='border-t-0 bg-grey-100 rounded-b-xl'>
          <Button
            type='submit'
            className='w-full md:w-auto md:px-16'
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SetCallPriorityModal;
