import {
  faChevronLeft,
  faChevronRight,
  faPlay,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { cn } from '../../../../utils/classUtils';
import { SharranMediaItem } from '../../../../utils/LeoUtils';
import LeoVideoModal from './LeoVideoModal';

type NeoLeoEmbedVideoResponsesProps = {
  sharranVideos: SharranMediaItem[];
};

const NeoLeoEmbedVideoResponses: React.FC<NeoLeoEmbedVideoResponsesProps> = ({
  sharranVideos,
}) => {
  const [pageItems, setPageItems] = React.useState<SharranMediaItem[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [showingCount, setShowingCount] = React.useState<number>(1);
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [showVideoModal, setShowVideoModal] = React.useState<boolean>(false);

  const pageSize = 1;
  const totalCount = sharranVideos.length || 0;
  const lastPage = Math.ceil(totalCount / pageSize);

  const goToPage = (page: number) => {
    if (page >= 0 && page < lastPage) {
      setCurrentPage(page);
    }
  };

  const onPrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (currentPage > 0) goToPage(currentPage - 1);
  };

  const onNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (currentPage < lastPage - 1) goToPage(currentPage + 1);
  };

  useEffect(() => {
    if (totalCount) {
      const start = currentPage * pageSize;
      const end = start + pageSize;
      const slicedItems = sharranVideos.slice(start, end);
      setPageItems(slicedItems);
      setShowingCount(start + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, totalCount]);

  return (
    <div className='text-white font-inter'>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <p className='font-medium text-sm'>Video Recommendations</p>
          <div className='flex justify-between items-center gap-2'>
            <p className='text-sm font-normal'>
              (<span className='font-medium'>{showingCount}</span>/
              <span className='font-light'>{totalCount}</span>)
            </p>
            {totalCount > 1 && (
              <div className='flex justify-between items-center gap-2.5'>
                <button
                  className={cn(
                    currentPage === 0 ? 'text-grey-900' : 'text-white',
                    'disabled:cursor-not-allowed w-6 h-6 flex justify-center items-center cursor-pointer',
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    onPrevious(e);
                  }}
                  disabled={currentPage === 0}
                  aria-label='go-previous'
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                  className={cn(
                    currentPage === lastPage - 1
                      ? 'text-grey-900'
                      : 'text-white',
                    'disabled:cursor-not-allowed w-6 h-6 flex justify-center items-center cursor-pointer',
                  )}
                  disabled={currentPage === lastPage - 1}
                  onClick={(e) => {
                    e.preventDefault();
                    onNext(e);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          {pageItems.map((video) => (
            <div key={video.source_url} className='flex justify-between'>
              <div
                className='flex flex-row'
                onClick={() => {
                  if (!video.media_url) {
                    return;
                  }
                  setShowVideoModal(true);
                  setIsPlaying(true);
                }}
              >
                <div className='relative w-24 h-24 overflow-hidden flex-shrink-0 rounded-md'>
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className='h-full w-full object-cover'
                  />
                  {!!video.media_url && !isPlaying && (
                    <div className='absolute inset-0 flex items-center justify-center'>
                      <button className='bg-grey-900 rounded-full p-2.5 w-10 h-10 flex justify-center items-center'>
                        <FontAwesomeIcon
                          icon={faPlay}
                          className='text-white'
                          size='xs'
                        />
                      </button>
                    </div>
                  )}
                </div>
                <div className='flex flex-col gap-2 px-4 font-inter'>
                  <p className='text-sm font-normal line-clamp-2'>
                    {video.title}
                  </p>
                  <p className='text-xs text-grey-100 font-normal line-clamp-3'>
                    {video.snippet}
                  </p>
                  {/* {!!video.media_url && !!video.duration && (
                    <p className='text-xs font-normal opacity-60'>
                      {Math.floor(video.duration / 60)}:{video.duration % 60}{' '}
                      min
                    </p>
                  )} */}
                </div>
              </div>
              {totalCount - 1 > showingCount - 1 && (
                <div className='w-10 relative '>
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className='h-full w-full object-cover rounded-tl-md rounded-bl-md'
                  />
                </div>
              )}

              {showVideoModal && (
                <LeoVideoModal
                  title={video.title}
                  play={isPlaying}
                  setPlay={setIsPlaying}
                  videoUrl={video.media_url!}
                  onClose={() => setShowVideoModal(false)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NeoLeoEmbedVideoResponses;
