import { faClipboardCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Button } from '../../commons/Button';
import { cn } from '../../../utils/classUtils';

export interface NeoLeoNotificationType {
  title: string;
  primaryActionText?: string;
  showPrimaryAction?: boolean;
  primaryActionHandler?: () => void;
  secondaryActionText?: string;
  showSecondaryAction?: boolean;
  secondaryActionHandler?: () => void;
  showActionButtons?: boolean;
  isUrgent?: boolean;
  onNotificationCardPress(): void;
  onDismissNotification(): void;
}

const NeoLeoNotification: FC<NeoLeoNotificationType> = ({
  title,
  showActionButtons,
  isUrgent,
  primaryActionText = 'Accept',
  showPrimaryAction = false,
  secondaryActionText = 'Decline',
  showSecondaryAction = false,
  primaryActionHandler,
  secondaryActionHandler,
  onNotificationCardPress,
  onDismissNotification,
}) => {
  return (
    <div
      className='font-inter px-4 py-3 bg-white bg-opacity-[0.08] rounded-lg border border-regent-600 hover:border-aqua relative group'
      onClick={onNotificationCardPress}
    >
      <div className='flex gap-3 pr-8'>
        {isUrgent ? (
          <FontAwesomeIcon icon={faWarning} className='text-coral-red' />
        ) : (
          <FontAwesomeIcon icon={faClipboardCheck} className='text-white' />
        )}
        <div>
          {isUrgent && (
            <p className='font-semibold text-sm text-coral-red mb-0.5'>
              Urgent:
            </p>
          )}
          <p
            className={cn('font-normal text-sm text-white', {
              'group-hover:mr-3': !isUrgent && showActionButtons,
            })}
          >
            {title}
          </p>
        </div>
      </div>
      {showActionButtons && (
        <div className='flex items-center gap-3 mt-2.5'>
          {showPrimaryAction && (
            <Button
              color='blue'
              className='bg-aqua !border-0 py-1.5'
              onClick={(e) => {
                e.stopPropagation();
                primaryActionHandler?.();
              }}
              fullWidth
            >
              <p className='text-sm text-primary-navy font-medium'>
                {primaryActionText}
              </p>
            </Button>
          )}
          {showSecondaryAction && (
            <Button
              variant='outline'
              className='bg-transparent !border !border-white py-[5px]'
              onClick={(e) => {
                e.stopPropagation();
                secondaryActionHandler?.();
              }}
              fullWidth
            >
              <p className='text-sm text-white font-medium'>
                {secondaryActionText}
              </p>
            </Button>
          )}
        </div>
      )}
      <div
        className='bg-[#4A5172] h-6 w-6 flex justify-center items-center rounded-[4px] absolute top-2 right-2 invisible group-hover:visible cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          onDismissNotification?.();
        }}
      >
        <FontAwesomeIcon icon={faXmark} className='text-regent-300' />
      </div>
    </div>
  );
};

export default NeoLeoNotification;
