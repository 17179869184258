/* tslint:disable */
/* eslint-disable */
/**
 * Leo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.6.4-build.2024-09-11T22-15-58
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface APIHandlerMetadataWithContent
 */
export interface APIHandlerMetadataWithContent {
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'api_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'llm_hint'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'is_address_required'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'is_state_required'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'phone'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'mortgage_lead_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'loan_advisor_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'loan_advisor_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'boolean_response'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'transaction_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'states'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'subtask'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'failed_retries'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'question'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'status'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'bypass_llm'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'score'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIHandlerMetadataWithContent
     */
    'content'?: any;
}
/**
 * Extra details that will be returned to the user to interact with
 * @export
 * @interface APIResponseDetail
 */
export interface APIResponseDetail {
    /**
     * 
     * @type {any}
     * @memberof APIResponseDetail
     */
    'category'?: any;
    /**
     * 
     * @type {FollowUpAPITypes}
     * @memberof APIResponseDetail
     */
    'type': FollowUpAPITypes;
    /**
     * 
     * @type {any}
     * @memberof APIResponseDetail
     */
    'message': any;
    /**
     * 
     * @type {any}
     * @memberof APIResponseDetail
     */
    'items'?: any;
    /**
     * 
     * @type {any}
     * @memberof APIResponseDetail
     */
    'prompts'?: any;
}


/**
 * 
 * @export
 * @interface APIRetrieverFunction
 */
export interface APIRetrieverFunction {
}
/**
 * 
 * @export
 * @interface ActionType
 */
export interface ActionType {
}
/**
 * 
 * @export
 * @interface ApiSource
 */
export interface ApiSource {
    /**
     * 
     * @type {any}
     * @memberof ApiSource
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiSource
     */
    'api_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiSource
     */
    'question'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiSource
     */
    'content'?: any;
}
/**
 * 
 * @export
 * @interface BaseContextSource
 */
export interface BaseContextSource {
    /**
     * 
     * @type {any}
     * @memberof BaseContextSource
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof BaseContextSource
     */
    'content'?: any;
}
/**
 * 
 * @export
 * @interface BodyUpdateFaqEmbeddingIngestFaqPost
 */
export interface BodyUpdateFaqEmbeddingIngestFaqPost {
    /**
     * 
     * @type {any}
     * @memberof BodyUpdateFaqEmbeddingIngestFaqPost
     */
    'file'?: any;
}
/**
 * 
 * @export
 * @interface BodyUpdateKeywordsEmbeddingIngestKeywordsPost
 */
export interface BodyUpdateKeywordsEmbeddingIngestKeywordsPost {
    /**
     * 
     * @type {any}
     * @memberof BodyUpdateKeywordsEmbeddingIngestKeywordsPost
     */
    'file'?: any;
}
/**
 * 
 * @export
 * @interface BulkUpdateRequest
 */
export interface BulkUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof BulkUpdateRequest
     */
    'pair_ids': any;
    /**
     * 
     * @type {any}
     * @memberof BulkUpdateRequest
     */
    'updated_by': any;
    /**
     * 
     * @type {any}
     * @memberof BulkUpdateRequest
     */
    'assignee_id'?: any;
    /**
     * 
     * @type {ActionType}
     * @memberof BulkUpdateRequest
     */
    'action': ActionType;
}


/**
 * 
 * @export
 * @interface CAProvinceCode
 */
export interface CAProvinceCode {
}
/**
 * 
 * @export
 * @interface ChatCompletionResponse
 */
export interface ChatCompletionResponse {
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'completion': any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'bot_message_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'suggestions'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'note'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'details'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'references'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'create_ticket'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'context_metadata'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatCompletionResponse
     */
    'media'?: any;
}
/**
 * 
 * @export
 * @interface ChatFollowUp
 */
export interface ChatFollowUp {
    /**
     * 
     * @type {any}
     * @memberof ChatFollowUp
     */
    'type': any;
    /**
     * 
     * @type {any}
     * @memberof ChatFollowUp
     */
    'items': any;
}
/**
 * 
 * @export
 * @interface ChatFollowUpItem
 */
export interface ChatFollowUpItem {
    /**
     * 
     * @type {any}
     * @memberof ChatFollowUpItem
     */
    'id': any;
}
/**
 * 
 * @export
 * @interface ChatGenerateRequest
 */
export interface ChatGenerateRequest {
    /**
     * 
     * @type {any}
     * @memberof ChatGenerateRequest
     */
    'question': any;
    /**
     * 
     * @type {any}
     * @memberof ChatGenerateRequest
     */
    'session_id': any;
    /**
     * 
     * @type {any}
     * @memberof ChatGenerateRequest
     */
    'follow_ups'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatGenerateRequest
     */
    'save_to_db'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatGenerateRequest
     */
    'model_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatGenerateRequest
     */
    'helper_prompt'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatGenerateRequest
     */
    'source'?: any;
}
/**
 * 
 * @export
 * @interface ChatMessageTicket
 */
export interface ChatMessageTicket {
    /**
     * 
     * @type {any}
     * @memberof ChatMessageTicket
     */
    'message_id': any;
    /**
     * 
     * @type {any}
     * @memberof ChatMessageTicket
     */
    'session_id': any;
    /**
     * 
     * @type {any}
     * @memberof ChatMessageTicket
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface ChatResponseReferences
 */
export interface ChatResponseReferences {
    /**
     * 
     * @type {any}
     * @memberof ChatResponseReferences
     */
    'documents'?: any;
    /**
     * 
     * @type {any}
     * @memberof ChatResponseReferences
     */
    'courses'?: any;
}
/**
 * 
 * @export
 * @interface ChecklistItemError
 */
export interface ChecklistItemError {
    /**
     * 
     * @type {any}
     * @memberof ChecklistItemError
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ChecklistItemError
     */
    'files'?: any;
}
/**
 * 
 * @export
 * @interface ChecklistItemErrorsRequest
 */
export interface ChecklistItemErrorsRequest {
    /**
     * 
     * @type {any}
     * @memberof ChecklistItemErrorsRequest
     */
    'checklist_item_ids'?: any;
}
/**
 * 
 * @export
 * @interface ChecklistItemErrorsResponse
 */
export interface ChecklistItemErrorsResponse {
    /**
     * 
     * @type {any}
     * @memberof ChecklistItemErrorsResponse
     */
    'checklist_items'?: any;
}
/**
 * 
 * @export
 * @interface ChecklistItemFile
 */
export interface ChecklistItemFile {
    /**
     * 
     * @type {any}
     * @memberof ChecklistItemFile
     */
    'id': any;
    /**
     * 
     * @type {DocumentReviewStatus}
     * @memberof ChecklistItemFile
     */
    'status': DocumentReviewStatus;
    /**
     * 
     * @type {any}
     * @memberof ChecklistItemFile
     */
    'result'?: any;
}


/**
 * 
 * @export
 * @interface ContextMetadataSources
 */
export interface ContextMetadataSources {
    /**
     * 
     * @type {any}
     * @memberof ContextMetadataSources
     */
    'apis'?: any;
    /**
     * 
     * @type {any}
     * @memberof ContextMetadataSources
     */
    'faqs'?: any;
    /**
     * 
     * @type {any}
     * @memberof ContextMetadataSources
     */
    'docs'?: any;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
}
/**
 * 
 * @export
 * @interface DocSource
 */
export interface DocSource {
    /**
     * 
     * @type {any}
     * @memberof DocSource
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocSource
     */
    'type'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocSource
     */
    'url'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocSource
     */
    'content'?: any;
}
/**
 * 
 * @export
 * @interface DocumentReviewDetectedError
 */
export interface DocumentReviewDetectedError {
    /**
     * 
     * @type {any}
     * @memberof DocumentReviewDetectedError
     */
    'error_message': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentReviewDetectedError
     */
    'page_numbers': any;
}
/**
 * 
 * @export
 * @interface DocumentReviewResult
 */
export interface DocumentReviewResult {
    /**
     * 
     * @type {any}
     * @memberof DocumentReviewResult
     */
    'detected_errors'?: any;
}
/**
 * 
 * @export
 * @interface DocumentReviewStatus
 */
export interface DocumentReviewStatus {
}
/**
 * 
 * @export
 * @interface DocumentReviewTemplate
 */
export interface DocumentReviewTemplate {
    /**
     * 
     * @type {any}
     * @memberof DocumentReviewTemplate
     */
    'id': any;
    /**
     * 
     * @type {DocumentTemplate}
     * @memberof DocumentReviewTemplate
     */
    'template': DocumentTemplate;
    /**
     * 
     * @type {any}
     * @memberof DocumentReviewTemplate
     */
    'checklist_item_definition_id': any;
}
/**
 * 
 * @export
 * @interface DocumentReviewTemplateCreateRequest
 */
export interface DocumentReviewTemplateCreateRequest {
    /**
     * 
     * @type {any}
     * @memberof DocumentReviewTemplateCreateRequest
     */
    'content': any;
}
/**
 * 
 * @export
 * @interface DocumentTemplate
 */
export interface DocumentTemplate {
    /**
     * 
     * @type {DocumentTemplateMetadata}
     * @memberof DocumentTemplate
     */
    'metadata': DocumentTemplateMetadata;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplate
     */
    'pages': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplate
     */
    'validators'?: any;
}
/**
 * 
 * @export
 * @interface DocumentTemplateField
 */
export interface DocumentTemplateField {
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateField
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateField
     */
    'description': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateField
     */
    'enum'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateField
     */
    'validations'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateField
     */
    'type'?: DocumentTemplateFieldTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateField
     */
    'required'?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentTemplateFieldTypeEnum {
    String = 'string'
}

/**
 * 
 * @export
 * @interface DocumentTemplateFieldValidator
 */
export interface DocumentTemplateFieldValidator {
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateFieldValidator
     */
    'function': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateFieldValidator
     */
    'on_error': any;
}
/**
 * 
 * @export
 * @interface DocumentTemplateGlobalValidator
 */
export interface DocumentTemplateGlobalValidator {
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateGlobalValidator
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateGlobalValidator
     */
    'fields': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateGlobalValidator
     */
    'validations': any;
}
/**
 * 
 * @export
 * @interface DocumentTemplateMetadata
 */
export interface DocumentTemplateMetadata {
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateMetadata
     */
    'document_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateMetadata
     */
    'document_type'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateMetadata
     */
    'applicable_country'?: any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplateMetadata
     */
    'applicable_state'?: any;
}
/**
 * 
 * @export
 * @interface DocumentTemplatePage
 */
export interface DocumentTemplatePage {
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplatePage
     */
    'number': any;
    /**
     * 
     * @type {any}
     * @memberof DocumentTemplatePage
     */
    'fields': any;
}
/**
 * 
 * @export
 * @interface FaqSource
 */
export interface FaqSource {
    /**
     * 
     * @type {any}
     * @memberof FaqSource
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof FaqSource
     */
    'question'?: any;
    /**
     * 
     * @type {any}
     * @memberof FaqSource
     */
    'answer'?: any;
}
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {any}
     * @memberof Field
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof Field
     */
    'page': any;
}
/**
 * 
 * @export
 * @interface FollowUpAPITypes
 */
export interface FollowUpAPITypes {
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {any}
     * @memberof HTTPValidationError
     */
    'detail'?: any;
}
/**
 * 
 * @export
 * @interface ListResourceNotificationResponse
 */
export interface ListResourceNotificationResponse {
    /**
     * 
     * @type {any}
     * @memberof ListResourceNotificationResponse
     */
    'items'?: any;
    /**
     * 
     * @type {Pagination}
     * @memberof ListResourceNotificationResponse
     */
    'pagination': Pagination;
}
/**
 * 
 * @export
 * @interface MediaItem
 */
export interface MediaItem {
    /**
     * 
     * @type {MediaType}
     * @memberof MediaItem
     */
    'type': MediaType;
    /**
     * 
     * @type {any}
     * @memberof MediaItem
     */
    'source_url': any;
    /**
     * 
     * @type {any}
     * @memberof MediaItem
     */
    'media_url'?: any;
    /**
     * 
     * @type {any}
     * @memberof MediaItem
     */
    'thumbnail': any;
    /**
     * 
     * @type {any}
     * @memberof MediaItem
     */
    'mimetype'?: any;
    /**
     * 
     * @type {any}
     * @memberof MediaItem
     */
    'height'?: any;
    /**
     * 
     * @type {any}
     * @memberof MediaItem
     */
    'width'?: any;
}


/**
 * 
 * @export
 * @interface MediaType
 */
export interface MediaType {
}
/**
 * Schema for a chat message with ID and creation time.
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'session_id': any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'parent_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'latency'?: any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'tokens'?: any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'cost'?: any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'context_metadata'?: any;
    /**
     * 
     * @type {MessageSenderTypes}
     * @memberof Message
     */
    'sender': MessageSenderTypes;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'vote_type'?: any;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    'created_at'?: any;
}


/**
 * 
 * @export
 * @interface MessageCreate
 */
export interface MessageCreate {
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'session_id': any;
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'parent_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'latency'?: any;
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'tokens'?: any;
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'cost'?: any;
    /**
     * 
     * @type {any}
     * @memberof MessageCreate
     */
    'context_metadata'?: any;
    /**
     * 
     * @type {MessageSenderTypes}
     * @memberof MessageCreate
     */
    'sender': MessageSenderTypes;
}


/**
 * 
 * @export
 * @interface MessageSenderTypes
 */
export interface MessageSenderTypes {
}
/**
 * 
 * @export
 * @interface NotificationAction
 */
export interface NotificationAction {
    /**
     * 
     * @type {any}
     * @memberof NotificationAction
     */
    'text': any;
    /**
     * 
     * @type {NotificationActionType}
     * @memberof NotificationAction
     */
    'type'?: NotificationActionType;
    /**
     * 
     * @type {any}
     * @memberof NotificationAction
     */
    'url'?: any;
}


/**
 * 
 * @export
 * @interface NotificationActionRequest
 */
export interface NotificationActionRequest {
    /**
     * 
     * @type {any}
     * @memberof NotificationActionRequest
     */
    'text': any;
}
/**
 * 
 * @export
 * @interface NotificationActionType
 */
export interface NotificationActionType {
}
/**
 * 
 * @export
 * @interface NotificationCreate
 */
export interface NotificationCreate {
    /**
     * 
     * @type {any}
     * @memberof NotificationCreate
     */
    'title': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationCreate
     */
    'description': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationCreate
     */
    'user_id': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationCreate
     */
    'user_response'?: any;
    /**
     * 
     * @type {NotificationType}
     * @memberof NotificationCreate
     */
    'type'?: NotificationType;
    /**
     * 
     * @type {any}
     * @memberof NotificationCreate
     */
    'priority'?: any;
    /**
     * 
     * @type {any}
     * @memberof NotificationCreate
     */
    'actions'?: any;
    /**
     * 
     * @type {any}
     * @memberof NotificationCreate
     */
    'meta'?: any;
}


/**
 * 
 * @export
 * @interface NotificationMetadata
 */
export interface NotificationMetadata {
    /**
     * 
     * @type {any}
     * @memberof NotificationMetadata
     */
    'suggested_prompts'?: any;
}
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'id': any;
    /**
     * 
     * @type {NotificationType}
     * @memberof NotificationResponse
     */
    'type'?: NotificationType;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'title': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'description': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'priority': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'actions'?: any;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'user_response'?: any;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'suggested_prompts'?: any;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'created_at': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationResponse
     */
    'dismissed_at'?: any;
}


/**
 * 
 * @export
 * @interface NotificationType
 */
export interface NotificationType {
}
/**
 * 
 * @export
 * @interface PaginatedPairResponse
 */
export interface PaginatedPairResponse {
    /**
     * 
     * @type {any}
     * @memberof PaginatedPairResponse
     */
    'has_next': any;
    /**
     * 
     * @type {any}
     * @memberof PaginatedPairResponse
     */
    'total_count': any;
    /**
     * 
     * @type {any}
     * @memberof PaginatedPairResponse
     */
    'page': any;
    /**
     * 
     * @type {any}
     * @memberof PaginatedPairResponse
     */
    'data': any;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'total_count': any;
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'max_page': any;
}
/**
 * Base schema for a question answer pair entry.
 * @export
 * @interface PairBase
 */
export interface PairBase {
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'pair_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'question': any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'answer'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'user_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'assignee_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'message_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'created_at'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'updated_at': any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'updated_by'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'is_enabled'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'start_date'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'end_date'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'reason'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'country'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'state_province'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairBase
     */
    'deleted_at'?: any;
}
/**
 * 
 * @export
 * @interface PairRequest
 */
export interface PairRequest {
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'start_date'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'end_date'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'is_enabled': any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'assignee_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'question': any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'answer'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'country'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'state_province'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'user_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'reason'?: any;
    /**
     * 
     * @type {any}
     * @memberof PairRequest
     */
    'message_id'?: any;
}
/**
 * 
 * @export
 * @interface PathSpecificContext
 */
export interface PathSpecificContext {
}
/**
 * 
 * @export
 * @interface PromptLibraryItem
 */
export interface PromptLibraryItem {
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryItem
     */
    'title': any;
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryItem
     */
    'prompt': any;
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryItem
     */
    'category'?: any;
}
/**
 * 
 * @export
 * @interface PromptLibraryRequest
 */
export interface PromptLibraryRequest {
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryRequest
     */
    'source': any;
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryRequest
     */
    'limit'?: any;
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryRequest
     */
    'offset'?: any;
}
/**
 * 
 * @export
 * @interface PromptLibraryResponse
 */
export interface PromptLibraryResponse {
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryResponse
     */
    'items'?: any;
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryResponse
     */
    'total': any;
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryResponse
     */
    'limit': any;
    /**
     * 
     * @type {any}
     * @memberof PromptLibraryResponse
     */
    'offset': any;
}
/**
 * 
 * @export
 * @interface QueueCountResponse
 */
export interface QueueCountResponse {
    /**
     * 
     * @type {any}
     * @memberof QueueCountResponse
     */
    'work_queue_total': any;
    /**
     * 
     * @type {any}
     * @memberof QueueCountResponse
     */
    'assigned_total': any;
    /**
     * 
     * @type {any}
     * @memberof QueueCountResponse
     */
    'completed_total': any;
}
/**
 * 
 * @export
 * @interface Reason
 */
export interface Reason {
}
/**
 * Response context saved in db for debugging
 * @export
 * @interface ResponseContextMetadataInput
 */
export interface ResponseContextMetadataInput {
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'api_context'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'faq_context'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'zendesk_context'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'api_contexts'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'faq_contexts'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'zendesk_contexts'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'sources'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataInput
     */
    'hallucinated'?: any;
}
/**
 * Response context saved in db for debugging
 * @export
 * @interface ResponseContextMetadataOutput
 */
export interface ResponseContextMetadataOutput {
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'api_context'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'faq_context'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'zendesk_context'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'api_contexts'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'faq_contexts'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'zendesk_contexts'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'sources'?: any;
    /**
     * 
     * @type {any}
     * @memberof ResponseContextMetadataOutput
     */
    'hallucinated'?: any;
}
/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {any}
     * @memberof SearchResponse
     */
    'results': any;
}
/**
 * Schema for a chat session with ID and timestamps.
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {any}
     * @memberof Session
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof Session
     */
    'agent_id': any;
    /**
     * 
     * @type {any}
     * @memberof Session
     */
    'start_time'?: any;
    /**
     * 
     * @type {any}
     * @memberof Session
     */
    'system_message'?: any;
    /**
     * 
     * @type {any}
     * @memberof Session
     */
    'title'?: any;
}
/**
 * Schema for creating a new chat session.
 * @export
 * @interface SessionCreate
 */
export interface SessionCreate {
    /**
     * 
     * @type {any}
     * @memberof SessionCreate
     */
    'agent_id': any;
}
/**
 * 
 * @export
 * @interface SessionPromptRequest
 */
export interface SessionPromptRequest {
    /**
     * 
     * @type {any}
     * @memberof SessionPromptRequest
     */
    'page_context'?: any;
    /**
     * 
     * @type {any}
     * @memberof SessionPromptRequest
     */
    'transaction_id'?: any;
}
/**
 * 
 * @export
 * @interface SessionWithDisclaimer
 */
export interface SessionWithDisclaimer {
    /**
     * 
     * @type {any}
     * @memberof SessionWithDisclaimer
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof SessionWithDisclaimer
     */
    'agent_id': any;
    /**
     * 
     * @type {any}
     * @memberof SessionWithDisclaimer
     */
    'start_time'?: any;
    /**
     * 
     * @type {any}
     * @memberof SessionWithDisclaimer
     */
    'system_message'?: any;
    /**
     * 
     * @type {any}
     * @memberof SessionWithDisclaimer
     */
    'title'?: any;
    /**
     * 
     * @type {any}
     * @memberof SessionWithDisclaimer
     */
    'disclaimer'?: any;
}
/**
 * 
 * @export
 * @interface USStateCode
 */
export interface USStateCode {
}
/**
 * 
 * @export
 * @interface UpdatePairRequest
 */
export interface UpdatePairRequest {
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'start_date'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'end_date'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'is_enabled': any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'assignee_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'question': any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'answer'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'country'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'state_province'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdatePairRequest
     */
    'updated_by': any;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'loc': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'msg': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'type': any;
}
/**
 * 
 * @export
 * @interface VoteType
 */
export interface VoteType {
}

/**
 * ChatControllerApi - axios parameter creator
 * @export
 */
export const ChatControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate a response to a user\'s chat question.
         * @summary Chat Generate
         * @param {ChatGenerateRequest} chatGenerateRequest 
         * @param {any} [sessionId] 
         * @param {any} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenerateChatGeneratePost: async (chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatGenerateRequest' is not null or undefined
            assertParamExists('chatGenerateChatGeneratePost', 'chatGenerateRequest', chatGenerateRequest)
            const localVarPath = `/chat/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }

            if (question !== undefined) {
                localVarQueryParameter['question'] = question;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatGenerateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate a stream of responses to a user\'s chat question.
         * @summary Chat Generate Stream
         * @param {any} question 
         * @param {any} sessionId 
         * @param {any} [followUps] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenerateStreamChatGenerateStreamGet: async (question: any, sessionId: any, followUps?: any, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'question' is not null or undefined
            assertParamExists('chatGenerateStreamChatGenerateStreamGet', 'question', question)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('chatGenerateStreamChatGenerateStreamGet', 'sessionId', sessionId)
            const localVarPath = `/chat/generate_stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (question !== undefined) {
                localVarQueryParameter['question'] = question;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }

            if (followUps !== undefined) {
                localVarQueryParameter['follow_ups'] = followUps;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Chat Generate Stream Post
         * @param {ChatGenerateRequest} chatGenerateRequest 
         * @param {any} [sessionId] 
         * @param {any} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenerateStreamPostChatGenerateStreamPost: async (chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatGenerateRequest' is not null or undefined
            assertParamExists('chatGenerateStreamPostChatGenerateStreamPost', 'chatGenerateRequest', chatGenerateRequest)
            const localVarPath = `/chat/generate_stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }

            if (question !== undefined) {
                localVarQueryParameter['question'] = question;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatGenerateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check Is Agent New
         * @param {any} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        checkIsAgentNewChatIsNewAgentIdGet: async (agentId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('checkIsAgentNewChatIsNewAgentIdGet', 'agentId', agentId)
            const localVarPath = `/chat/is-new/{agent_id}`
                .replace(`{${"agent_id"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new session in the database.
         * @summary Create Chat Session
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChatSessionChatSessionPost: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new message.  Args:     message: The message to be created.     auth_result: The authentication result.     db: The database session.  Returns:     A message object with the created message.
         * @summary Create Message
         * @param {MessageCreate} messageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageChatMessagesPost: async (messageCreate: MessageCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageCreate' is not null or undefined
            assertParamExists('createMessageChatMessagesPost', 'messageCreate', messageCreate)
            const localVarPath = `/chat/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Open a ticket for the message and session id passed
         * @summary Create Ticket
         * @param {ChatMessageTicket} chatMessageTicket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketChatMessageTicketPost: async (chatMessageTicket: ChatMessageTicket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatMessageTicket' is not null or undefined
            assertParamExists('createTicketChatMessageTicketPost', 'chatMessageTicket', chatMessageTicket)
            const localVarPath = `/chat/message/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatMessageTicket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a session with the given session_id. Args:     session_id: The ID of the session to be deleted.     auth_result: The authentication result. Defaults to deps.verify_role_agent.     db: The database session. Defaults to deps.get_db_session. Returns:     A dictionary containing the success message.
         * @summary Delete Session
         * @param {any} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionChatSessionSessionIdDelete: async (sessionId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('deleteSessionChatSessionSessionIdDelete', 'sessionId', sessionId)
            const localVarPath = `/chat/session/{session_id}`
                .replace(`{${"session_id"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all sessions for a specific agent.  Args:     agent_id: The ID of the agent.     auth_result: The authentication result.     db: The database session.  Returns:     A list of sessions for the agent.
         * @summary Get All Agent Sessions
         * @param {any} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAgentSessionsChatSessionGet: async (agentId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAllAgentSessionsChatSessionGet', 'agentId', agentId)
            const localVarPath = `/chat/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agent_id'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Sessions By Agent Id
         * @param {any} agentId 
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSessionsByAgentIdChatSessionsGet: async (agentId: any, page?: any, limit?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAllSessionsByAgentIdChatSessionsGet', 'agentId', agentId)
            const localVarPath = `/chat/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agent_id'] = agentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves messages from the database for the specified session_id or agent_id.
         * @summary Get Messages
         * @param {any} sessionId 
         * @param {any} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesChatMessagesGet: async (sessionId: any, agentId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getMessagesChatMessagesGet', 'sessionId', sessionId)
            const localVarPath = `/chat/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }

            if (agentId !== undefined) {
                localVarQueryParameter['agent_id'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Prompt Suggestions
         * @param {PromptLibraryRequest} promptLibraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptSuggestionsChatPromptLibraryPost: async (promptLibraryRequest: PromptLibraryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptLibraryRequest' is not null or undefined
            assertParamExists('getPromptSuggestionsChatPromptLibraryPost', 'promptLibraryRequest', promptLibraryRequest)
            const localVarPath = `/chat/prompt-library`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptLibraryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Question Suggestions
         * @param {any} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionSuggestionsChatQuestionSuggestionsGet: async (q: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getQuestionSuggestionsChatQuestionSuggestionsGet', 'q', q)
            const localVarPath = `/chat/question_suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Session Prompts
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSessionPromptsChatSessionPromptsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/session-prompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of initial suggested prompts. There is a fixed/pinned list of prompts that always get displayed, and a list of rotating prompts that will be chosen randomly. All combined should not exceed the max_prompts number configured in settings.  Returns:     A list of prompts.
         * @summary Get Session Prompts New
         * @param {SessionPromptRequest} sessionPromptRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSessionPromptsNewChatSessionPromptsPost: async (sessionPromptRequest: SessionPromptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionPromptRequest' is not null or undefined
            assertParamExists('getSessionPromptsNewChatSessionPromptsPost', 'sessionPromptRequest', sessionPromptRequest)
            const localVarPath = `/chat/session-prompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionPromptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Record a vote for a message.
         * @summary Vote
         * @param {any} messageId 
         * @param {any} voteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteChatMessageMessageIdVoteTypePost: async (messageId: any, voteType: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('voteChatMessageMessageIdVoteTypePost', 'messageId', messageId)
            // verify required parameter 'voteType' is not null or undefined
            assertParamExists('voteChatMessageMessageIdVoteTypePost', 'voteType', voteType)
            const localVarPath = `/chat/message/{message_id}/{vote_type}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"vote_type"}}`, encodeURIComponent(String(voteType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatControllerApi - functional programming interface
 * @export
 */
export const ChatControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate a response to a user\'s chat question.
         * @summary Chat Generate
         * @param {ChatGenerateRequest} chatGenerateRequest 
         * @param {any} [sessionId] 
         * @param {any} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatGenerateChatGeneratePost(chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatCompletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatGenerateChatGeneratePost(chatGenerateRequest, sessionId, question, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate a stream of responses to a user\'s chat question.
         * @summary Chat Generate Stream
         * @param {any} question 
         * @param {any} sessionId 
         * @param {any} [followUps] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatGenerateStreamChatGenerateStreamGet(question: any, sessionId: any, followUps?: any, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatGenerateStreamChatGenerateStreamGet(question, sessionId, followUps, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Chat Generate Stream Post
         * @param {ChatGenerateRequest} chatGenerateRequest 
         * @param {any} [sessionId] 
         * @param {any} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatGenerateStreamPostChatGenerateStreamPost(chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatGenerateStreamPostChatGenerateStreamPost(chatGenerateRequest, sessionId, question, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check Is Agent New
         * @param {any} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async checkIsAgentNewChatIsNewAgentIdGet(agentId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIsAgentNewChatIsNewAgentIdGet(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new session in the database.
         * @summary Create Chat Session
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChatSessionChatSessionPost(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionWithDisclaimer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChatSessionChatSessionPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new message.  Args:     message: The message to be created.     auth_result: The authentication result.     db: The database session.  Returns:     A message object with the created message.
         * @summary Create Message
         * @param {MessageCreate} messageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessageChatMessagesPost(messageCreate: MessageCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessageChatMessagesPost(messageCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Open a ticket for the message and session id passed
         * @summary Create Ticket
         * @param {ChatMessageTicket} chatMessageTicket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicketChatMessageTicketPost(chatMessageTicket: ChatMessageTicket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicketChatMessageTicketPost(chatMessageTicket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a session with the given session_id. Args:     session_id: The ID of the session to be deleted.     auth_result: The authentication result. Defaults to deps.verify_role_agent.     db: The database session. Defaults to deps.get_db_session. Returns:     A dictionary containing the success message.
         * @summary Delete Session
         * @param {any} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSessionChatSessionSessionIdDelete(sessionId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSessionChatSessionSessionIdDelete(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all sessions for a specific agent.  Args:     agent_id: The ID of the agent.     auth_result: The authentication result.     db: The database session.  Returns:     A list of sessions for the agent.
         * @summary Get All Agent Sessions
         * @param {any} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllAgentSessionsChatSessionGet(agentId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAgentSessionsChatSessionGet(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get All Sessions By Agent Id
         * @param {any} agentId 
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSessionsByAgentIdChatSessionsGet(agentId: any, page?: any, limit?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSessionsByAgentIdChatSessionsGet(agentId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves messages from the database for the specified session_id or agent_id.
         * @summary Get Messages
         * @param {any} sessionId 
         * @param {any} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesChatMessagesGet(sessionId: any, agentId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesChatMessagesGet(sessionId, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Prompt Suggestions
         * @param {PromptLibraryRequest} promptLibraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromptSuggestionsChatPromptLibraryPost(promptLibraryRequest: PromptLibraryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptLibraryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromptSuggestionsChatPromptLibraryPost(promptLibraryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Question Suggestions
         * @param {any} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionSuggestionsChatQuestionSuggestionsGet(q: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionSuggestionsChatQuestionSuggestionsGet(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Session Prompts
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSessionPromptsChatSessionPromptsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionPromptsChatSessionPromptsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of initial suggested prompts. There is a fixed/pinned list of prompts that always get displayed, and a list of rotating prompts that will be chosen randomly. All combined should not exceed the max_prompts number configured in settings.  Returns:     A list of prompts.
         * @summary Get Session Prompts New
         * @param {SessionPromptRequest} sessionPromptRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSessionPromptsNewChatSessionPromptsPost(sessionPromptRequest: SessionPromptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionPromptsNewChatSessionPromptsPost(sessionPromptRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Record a vote for a message.
         * @summary Vote
         * @param {any} messageId 
         * @param {any} voteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voteChatMessageMessageIdVoteTypePost(messageId: any, voteType: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voteChatMessageMessageIdVoteTypePost(messageId, voteType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatControllerApi - factory interface
 * @export
 */
export const ChatControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatControllerApiFp(configuration)
    return {
        /**
         * Generate a response to a user\'s chat question.
         * @summary Chat Generate
         * @param {ChatGenerateRequest} chatGenerateRequest 
         * @param {any} [sessionId] 
         * @param {any} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenerateChatGeneratePost(chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options?: any): AxiosPromise<ChatCompletionResponse> {
            return localVarFp.chatGenerateChatGeneratePost(chatGenerateRequest, sessionId, question, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate a stream of responses to a user\'s chat question.
         * @summary Chat Generate Stream
         * @param {any} question 
         * @param {any} sessionId 
         * @param {any} [followUps] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenerateStreamChatGenerateStreamGet(question: any, sessionId: any, followUps?: any, body?: any, options?: any): AxiosPromise<void> {
            return localVarFp.chatGenerateStreamChatGenerateStreamGet(question, sessionId, followUps, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Chat Generate Stream Post
         * @param {ChatGenerateRequest} chatGenerateRequest 
         * @param {any} [sessionId] 
         * @param {any} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenerateStreamPostChatGenerateStreamPost(chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options?: any): AxiosPromise<any> {
            return localVarFp.chatGenerateStreamPostChatGenerateStreamPost(chatGenerateRequest, sessionId, question, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check Is Agent New
         * @param {any} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        checkIsAgentNewChatIsNewAgentIdGet(agentId: any, options?: any): AxiosPromise<any> {
            return localVarFp.checkIsAgentNewChatIsNewAgentIdGet(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new session in the database.
         * @summary Create Chat Session
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChatSessionChatSessionPost(body?: any, options?: any): AxiosPromise<SessionWithDisclaimer> {
            return localVarFp.createChatSessionChatSessionPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new message.  Args:     message: The message to be created.     auth_result: The authentication result.     db: The database session.  Returns:     A message object with the created message.
         * @summary Create Message
         * @param {MessageCreate} messageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageChatMessagesPost(messageCreate: MessageCreate, options?: any): AxiosPromise<Message> {
            return localVarFp.createMessageChatMessagesPost(messageCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Open a ticket for the message and session id passed
         * @summary Create Ticket
         * @param {ChatMessageTicket} chatMessageTicket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketChatMessageTicketPost(chatMessageTicket: ChatMessageTicket, options?: any): AxiosPromise<any> {
            return localVarFp.createTicketChatMessageTicketPost(chatMessageTicket, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a session with the given session_id. Args:     session_id: The ID of the session to be deleted.     auth_result: The authentication result. Defaults to deps.verify_role_agent.     db: The database session. Defaults to deps.get_db_session. Returns:     A dictionary containing the success message.
         * @summary Delete Session
         * @param {any} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionChatSessionSessionIdDelete(sessionId: any, options?: any): AxiosPromise<any> {
            return localVarFp.deleteSessionChatSessionSessionIdDelete(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all sessions for a specific agent.  Args:     agent_id: The ID of the agent.     auth_result: The authentication result.     db: The database session.  Returns:     A list of sessions for the agent.
         * @summary Get All Agent Sessions
         * @param {any} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllAgentSessionsChatSessionGet(agentId: any, options?: any): AxiosPromise<any> {
            return localVarFp.getAllAgentSessionsChatSessionGet(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Sessions By Agent Id
         * @param {any} agentId 
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSessionsByAgentIdChatSessionsGet(agentId: any, page?: any, limit?: any, options?: any): AxiosPromise<any> {
            return localVarFp.getAllSessionsByAgentIdChatSessionsGet(agentId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves messages from the database for the specified session_id or agent_id.
         * @summary Get Messages
         * @param {any} sessionId 
         * @param {any} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesChatMessagesGet(sessionId: any, agentId?: any, options?: any): AxiosPromise<any> {
            return localVarFp.getMessagesChatMessagesGet(sessionId, agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Prompt Suggestions
         * @param {PromptLibraryRequest} promptLibraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptSuggestionsChatPromptLibraryPost(promptLibraryRequest: PromptLibraryRequest, options?: any): AxiosPromise<PromptLibraryResponse> {
            return localVarFp.getPromptSuggestionsChatPromptLibraryPost(promptLibraryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Question Suggestions
         * @param {any} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionSuggestionsChatQuestionSuggestionsGet(q: any, options?: any): AxiosPromise<any> {
            return localVarFp.getQuestionSuggestionsChatQuestionSuggestionsGet(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Session Prompts
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSessionPromptsChatSessionPromptsGet(options?: any): AxiosPromise<any> {
            return localVarFp.getSessionPromptsChatSessionPromptsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of initial suggested prompts. There is a fixed/pinned list of prompts that always get displayed, and a list of rotating prompts that will be chosen randomly. All combined should not exceed the max_prompts number configured in settings.  Returns:     A list of prompts.
         * @summary Get Session Prompts New
         * @param {SessionPromptRequest} sessionPromptRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSessionPromptsNewChatSessionPromptsPost(sessionPromptRequest: SessionPromptRequest, options?: any): AxiosPromise<any> {
            return localVarFp.getSessionPromptsNewChatSessionPromptsPost(sessionPromptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Record a vote for a message.
         * @summary Vote
         * @param {any} messageId 
         * @param {any} voteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteChatMessageMessageIdVoteTypePost(messageId: any, voteType: any, options?: any): AxiosPromise<Message> {
            return localVarFp.voteChatMessageMessageIdVoteTypePost(messageId, voteType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatControllerApi - object-oriented interface
 * @export
 * @class ChatControllerApi
 * @extends {BaseAPI}
 */
export class ChatControllerApi extends BaseAPI {
    /**
     * Generate a response to a user\'s chat question.
     * @summary Chat Generate
     * @param {ChatGenerateRequest} chatGenerateRequest 
     * @param {any} [sessionId] 
     * @param {any} [question] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public chatGenerateChatGeneratePost(chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).chatGenerateChatGeneratePost(chatGenerateRequest, sessionId, question, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate a stream of responses to a user\'s chat question.
     * @summary Chat Generate Stream
     * @param {any} question 
     * @param {any} sessionId 
     * @param {any} [followUps] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public chatGenerateStreamChatGenerateStreamGet(question: any, sessionId: any, followUps?: any, body?: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).chatGenerateStreamChatGenerateStreamGet(question, sessionId, followUps, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Chat Generate Stream Post
     * @param {ChatGenerateRequest} chatGenerateRequest 
     * @param {any} [sessionId] 
     * @param {any} [question] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public chatGenerateStreamPostChatGenerateStreamPost(chatGenerateRequest: ChatGenerateRequest, sessionId?: any, question?: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).chatGenerateStreamPostChatGenerateStreamPost(chatGenerateRequest, sessionId, question, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check Is Agent New
     * @param {any} agentId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public checkIsAgentNewChatIsNewAgentIdGet(agentId: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).checkIsAgentNewChatIsNewAgentIdGet(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new session in the database.
     * @summary Create Chat Session
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public createChatSessionChatSessionPost(body?: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).createChatSessionChatSessionPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new message.  Args:     message: The message to be created.     auth_result: The authentication result.     db: The database session.  Returns:     A message object with the created message.
     * @summary Create Message
     * @param {MessageCreate} messageCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public createMessageChatMessagesPost(messageCreate: MessageCreate, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).createMessageChatMessagesPost(messageCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Open a ticket for the message and session id passed
     * @summary Create Ticket
     * @param {ChatMessageTicket} chatMessageTicket 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public createTicketChatMessageTicketPost(chatMessageTicket: ChatMessageTicket, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).createTicketChatMessageTicketPost(chatMessageTicket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a session with the given session_id. Args:     session_id: The ID of the session to be deleted.     auth_result: The authentication result. Defaults to deps.verify_role_agent.     db: The database session. Defaults to deps.get_db_session. Returns:     A dictionary containing the success message.
     * @summary Delete Session
     * @param {any} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public deleteSessionChatSessionSessionIdDelete(sessionId: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).deleteSessionChatSessionSessionIdDelete(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all sessions for a specific agent.  Args:     agent_id: The ID of the agent.     auth_result: The authentication result.     db: The database session.  Returns:     A list of sessions for the agent.
     * @summary Get All Agent Sessions
     * @param {any} agentId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public getAllAgentSessionsChatSessionGet(agentId: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).getAllAgentSessionsChatSessionGet(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Sessions By Agent Id
     * @param {any} agentId 
     * @param {any} [page] Page number, defaults to 1.
     * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public getAllSessionsByAgentIdChatSessionsGet(agentId: any, page?: any, limit?: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).getAllSessionsByAgentIdChatSessionsGet(agentId, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves messages from the database for the specified session_id or agent_id.
     * @summary Get Messages
     * @param {any} sessionId 
     * @param {any} [agentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public getMessagesChatMessagesGet(sessionId: any, agentId?: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).getMessagesChatMessagesGet(sessionId, agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Prompt Suggestions
     * @param {PromptLibraryRequest} promptLibraryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public getPromptSuggestionsChatPromptLibraryPost(promptLibraryRequest: PromptLibraryRequest, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).getPromptSuggestionsChatPromptLibraryPost(promptLibraryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Question Suggestions
     * @param {any} q 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public getQuestionSuggestionsChatQuestionSuggestionsGet(q: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).getQuestionSuggestionsChatQuestionSuggestionsGet(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Session Prompts
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public getSessionPromptsChatSessionPromptsGet(options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).getSessionPromptsChatSessionPromptsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of initial suggested prompts. There is a fixed/pinned list of prompts that always get displayed, and a list of rotating prompts that will be chosen randomly. All combined should not exceed the max_prompts number configured in settings.  Returns:     A list of prompts.
     * @summary Get Session Prompts New
     * @param {SessionPromptRequest} sessionPromptRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public getSessionPromptsNewChatSessionPromptsPost(sessionPromptRequest: SessionPromptRequest, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).getSessionPromptsNewChatSessionPromptsPost(sessionPromptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Record a vote for a message.
     * @summary Vote
     * @param {any} messageId 
     * @param {any} voteType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public voteChatMessageMessageIdVoteTypePost(messageId: any, voteType: any, options?: AxiosRequestConfig) {
        return ChatControllerApiFp(this.configuration).voteChatMessageMessageIdVoteTypePost(messageId, voteType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<any> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentReviewApi - axios parameter creator
 * @export
 */
export const DocumentReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checklist Items Errors
         * @param {ChecklistItemErrorsRequest} checklistItemErrorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost: async (checklistItemErrorsRequest: ChecklistItemErrorsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistItemErrorsRequest' is not null or undefined
            assertParamExists('checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost', 'checklistItemErrorsRequest', checklistItemErrorsRequest)
            const localVarPath = `/document-review/checklist-items/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistItemErrorsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost: async (itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefinitionId' is not null or undefined
            assertParamExists('createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost', 'itemDefinitionId', itemDefinitionId)
            // verify required parameter 'documentReviewTemplateCreateRequest' is not null or undefined
            assertParamExists('createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost', 'documentReviewTemplateCreateRequest', documentReviewTemplateCreateRequest)
            const localVarPath = `/document-review/templates/checklists/{item_definition_id}/create`
                .replace(`{${"item_definition_id"}}`, encodeURIComponent(String(itemDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentReviewTemplateCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete: async (itemDefinitionId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefinitionId' is not null or undefined
            assertParamExists('deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete', 'itemDefinitionId', itemDefinitionId)
            const localVarPath = `/document-review/templates/checklists/{item_definition_id}`
                .replace(`{${"item_definition_id"}}`, encodeURIComponent(String(itemDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get template by checklist item definition id
         * @param {any} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet: async (itemDefinitionId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefinitionId' is not null or undefined
            assertParamExists('getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet', 'itemDefinitionId', itemDefinitionId)
            const localVarPath = `/document-review/templates/checklists/{item_definition_id}`
                .replace(`{${"item_definition_id"}}`, encodeURIComponent(String(itemDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch: async (itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDefinitionId' is not null or undefined
            assertParamExists('updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch', 'itemDefinitionId', itemDefinitionId)
            // verify required parameter 'documentReviewTemplateCreateRequest' is not null or undefined
            assertParamExists('updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch', 'documentReviewTemplateCreateRequest', documentReviewTemplateCreateRequest)
            const localVarPath = `/document-review/templates/checklists/{item_definition_id}`
                .replace(`{${"item_definition_id"}}`, encodeURIComponent(String(itemDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentReviewTemplateCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentReviewApi - functional programming interface
 * @export
 */
export const DocumentReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Checklist Items Errors
         * @param {ChecklistItemErrorsRequest} checklistItemErrorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost(checklistItemErrorsRequest: ChecklistItemErrorsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistItemErrorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost(checklistItemErrorsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost(itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost(itemDefinitionId, documentReviewTemplateCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete(itemDefinitionId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete(itemDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get template by checklist item definition id
         * @param {any} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet(itemDefinitionId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReviewTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet(itemDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch(itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch(itemDefinitionId, documentReviewTemplateCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentReviewApi - factory interface
 * @export
 */
export const DocumentReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentReviewApiFp(configuration)
    return {
        /**
         * 
         * @summary Checklist Items Errors
         * @param {ChecklistItemErrorsRequest} checklistItemErrorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost(checklistItemErrorsRequest: ChecklistItemErrorsRequest, options?: any): AxiosPromise<ChecklistItemErrorsResponse> {
            return localVarFp.checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost(checklistItemErrorsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost(itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost(itemDefinitionId, documentReviewTemplateCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete(itemDefinitionId: any, options?: any): AxiosPromise<void> {
            return localVarFp.deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete(itemDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get template by checklist item definition id
         * @param {any} itemDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet(itemDefinitionId: any, options?: any): AxiosPromise<DocumentReviewTemplate> {
            return localVarFp.getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet(itemDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item Definition Validation Template
         * @param {any} itemDefinitionId 
         * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch(itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch(itemDefinitionId, documentReviewTemplateCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentReviewApi - object-oriented interface
 * @export
 * @class DocumentReviewApi
 * @extends {BaseAPI}
 */
export class DocumentReviewApi extends BaseAPI {
    /**
     * 
     * @summary Checklist Items Errors
     * @param {ChecklistItemErrorsRequest} checklistItemErrorsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost(checklistItemErrorsRequest: ChecklistItemErrorsRequest, options?: AxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost(checklistItemErrorsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Item Definition Validation Template
     * @param {any} itemDefinitionId 
     * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost(itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options?: AxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).createItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdCreatePost(itemDefinitionId, documentReviewTemplateCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Item Definition Validation Template
     * @param {any} itemDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete(itemDefinitionId: any, options?: AxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).deleteItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdDelete(itemDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get template by checklist item definition id
     * @param {any} itemDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet(itemDefinitionId: any, options?: AxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).getItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdGet(itemDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item Definition Validation Template
     * @param {any} itemDefinitionId 
     * @param {DocumentReviewTemplateCreateRequest} documentReviewTemplateCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch(itemDefinitionId: any, documentReviewTemplateCreateRequest: DocumentReviewTemplateCreateRequest, options?: AxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).updateItemDefinitionValidationTemplateDocumentReviewTemplatesChecklistsItemDefinitionIdPatch(itemDefinitionId, documentReviewTemplateCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DownloadApi - axios parameter creator
 * @export
 */
export const DownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Poster
         * @param {any} imageUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPosterDownloadImageImageUrlGet: async (imageUrl: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageUrl' is not null or undefined
            assertParamExists('downloadPosterDownloadImageImageUrlGet', 'imageUrl', imageUrl)
            const localVarPath = `/download/image/{image_url}`
                .replace(`{${"image_url"}}`, encodeURIComponent(String(imageUrl)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadApi - functional programming interface
 * @export
 */
export const DownloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DownloadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download Poster
         * @param {any} imageUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPosterDownloadImageImageUrlGet(imageUrl: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPosterDownloadImageImageUrlGet(imageUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DownloadApi - factory interface
 * @export
 */
export const DownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DownloadApiFp(configuration)
    return {
        /**
         * 
         * @summary Download Poster
         * @param {any} imageUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPosterDownloadImageImageUrlGet(imageUrl: any, options?: any): AxiosPromise<any> {
            return localVarFp.downloadPosterDownloadImageImageUrlGet(imageUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadApi - object-oriented interface
 * @export
 * @class DownloadApi
 * @extends {BaseAPI}
 */
export class DownloadApi extends BaseAPI {
    /**
     * 
     * @summary Download Poster
     * @param {any} imageUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadPosterDownloadImageImageUrlGet(imageUrl: any, options?: AxiosRequestConfig) {
        return DownloadApiFp(this.configuration).downloadPosterDownloadImageImageUrlGet(imageUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmbeddingControllerApi - axios parameter creator
 * @export
 */
export const EmbeddingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Inserts courses documents into the database.  Args:     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
         * @summary Insert Courses Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCoursesDocumentsEmbeddingIngestCoursesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedding/ingest_courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Inserts Sharran documents into the database.  Args:     background_tasks: The background tasks manager.     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
         * @summary Insert Sharran Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSharranDocumentsEmbeddingIngestSharranPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedding/ingest_sharran`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Inserts Zendesk documents into the database.  Args:     reindex: if true: delete all existing docs and re index     background_tasks: The background tasks manager.     db: The asynchronous database session.  Returns:     A dictionary containing the status of the process and the number of retrieved documents.
         * @summary Insert Zendesk Documents
         * @param {any} [reindex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertZendeskDocumentsEmbeddingIngestZendeskPost: async (reindex?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedding/ingest_zendesk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reindex !== undefined) {
                localVarQueryParameter['reindex'] = reindex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a csv file where the column named \"Question\" will be embedded and all the other columns will be added as metadata
         * @summary Update Faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqEmbeddingIngestFaqPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedding/ingest_faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a csv file where the column named \"Reference\" will be embedded and all the other columns will be added as metadata
         * @summary Update Keywords
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKeywordsEmbeddingIngestKeywordsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedding/ingest_keywords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmbeddingControllerApi - functional programming interface
 * @export
 */
export const EmbeddingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmbeddingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Inserts courses documents into the database.  Args:     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
         * @summary Insert Courses Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertCoursesDocumentsEmbeddingIngestCoursesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertCoursesDocumentsEmbeddingIngestCoursesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Inserts Sharran documents into the database.  Args:     background_tasks: The background tasks manager.     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
         * @summary Insert Sharran Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSharranDocumentsEmbeddingIngestSharranPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertSharranDocumentsEmbeddingIngestSharranPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Inserts Zendesk documents into the database.  Args:     reindex: if true: delete all existing docs and re index     background_tasks: The background tasks manager.     db: The asynchronous database session.  Returns:     A dictionary containing the status of the process and the number of retrieved documents.
         * @summary Insert Zendesk Documents
         * @param {any} [reindex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertZendeskDocumentsEmbeddingIngestZendeskPost(reindex?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertZendeskDocumentsEmbeddingIngestZendeskPost(reindex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a csv file where the column named \"Question\" will be embedded and all the other columns will be added as metadata
         * @summary Update Faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFaqEmbeddingIngestFaqPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFaqEmbeddingIngestFaqPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a csv file where the column named \"Reference\" will be embedded and all the other columns will be added as metadata
         * @summary Update Keywords
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKeywordsEmbeddingIngestKeywordsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKeywordsEmbeddingIngestKeywordsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmbeddingControllerApi - factory interface
 * @export
 */
export const EmbeddingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmbeddingControllerApiFp(configuration)
    return {
        /**
         * Inserts courses documents into the database.  Args:     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
         * @summary Insert Courses Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertCoursesDocumentsEmbeddingIngestCoursesPost(options?: any): AxiosPromise<any> {
            return localVarFp.insertCoursesDocumentsEmbeddingIngestCoursesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Inserts Sharran documents into the database.  Args:     background_tasks: The background tasks manager.     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
         * @summary Insert Sharran Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSharranDocumentsEmbeddingIngestSharranPost(options?: any): AxiosPromise<any> {
            return localVarFp.insertSharranDocumentsEmbeddingIngestSharranPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Inserts Zendesk documents into the database.  Args:     reindex: if true: delete all existing docs and re index     background_tasks: The background tasks manager.     db: The asynchronous database session.  Returns:     A dictionary containing the status of the process and the number of retrieved documents.
         * @summary Insert Zendesk Documents
         * @param {any} [reindex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertZendeskDocumentsEmbeddingIngestZendeskPost(reindex?: any, options?: any): AxiosPromise<any> {
            return localVarFp.insertZendeskDocumentsEmbeddingIngestZendeskPost(reindex, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a csv file where the column named \"Question\" will be embedded and all the other columns will be added as metadata
         * @summary Update Faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqEmbeddingIngestFaqPost(options?: any): AxiosPromise<any> {
            return localVarFp.updateFaqEmbeddingIngestFaqPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a csv file where the column named \"Reference\" will be embedded and all the other columns will be added as metadata
         * @summary Update Keywords
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKeywordsEmbeddingIngestKeywordsPost(options?: any): AxiosPromise<any> {
            return localVarFp.updateKeywordsEmbeddingIngestKeywordsPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmbeddingControllerApi - object-oriented interface
 * @export
 * @class EmbeddingControllerApi
 * @extends {BaseAPI}
 */
export class EmbeddingControllerApi extends BaseAPI {
    /**
     * Inserts courses documents into the database.  Args:     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
     * @summary Insert Courses Documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingControllerApi
     */
    public insertCoursesDocumentsEmbeddingIngestCoursesPost(options?: AxiosRequestConfig) {
        return EmbeddingControllerApiFp(this.configuration).insertCoursesDocumentsEmbeddingIngestCoursesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Inserts Sharran documents into the database.  Args:     background_tasks: The background tasks manager.     db: The async session object for the database.  Returns:     A dictionary containing the number of processed documents and the number of documents added to the database.
     * @summary Insert Sharran Documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingControllerApi
     */
    public insertSharranDocumentsEmbeddingIngestSharranPost(options?: AxiosRequestConfig) {
        return EmbeddingControllerApiFp(this.configuration).insertSharranDocumentsEmbeddingIngestSharranPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Inserts Zendesk documents into the database.  Args:     reindex: if true: delete all existing docs and re index     background_tasks: The background tasks manager.     db: The asynchronous database session.  Returns:     A dictionary containing the status of the process and the number of retrieved documents.
     * @summary Insert Zendesk Documents
     * @param {any} [reindex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingControllerApi
     */
    public insertZendeskDocumentsEmbeddingIngestZendeskPost(reindex?: any, options?: AxiosRequestConfig) {
        return EmbeddingControllerApiFp(this.configuration).insertZendeskDocumentsEmbeddingIngestZendeskPost(reindex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a csv file where the column named \"Question\" will be embedded and all the other columns will be added as metadata
     * @summary Update Faq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingControllerApi
     */
    public updateFaqEmbeddingIngestFaqPost(options?: AxiosRequestConfig) {
        return EmbeddingControllerApiFp(this.configuration).updateFaqEmbeddingIngestFaqPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a csv file where the column named \"Reference\" will be embedded and all the other columns will be added as metadata
     * @summary Update Keywords
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingControllerApi
     */
    public updateKeywordsEmbeddingIngestKeywordsPost(options?: AxiosRequestConfig) {
        return EmbeddingControllerApiFp(this.configuration).updateKeywordsEmbeddingIngestKeywordsPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeoQuestionManagerApi - axios parameter creator
 * @export
 */
export const LeoQuestionManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Bulk update question answer pair
         * @summary Bulk Update
         * @param {BulkUpdateRequest} bulkUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateLqmQapairBulkPatch: async (bulkUpdateRequest: BulkUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUpdateRequest' is not null or undefined
            assertParamExists('bulkUpdateLqmQapairBulkPatch', 'bulkUpdateRequest', bulkUpdateRequest)
            const localVarPath = `/lqm/qapair/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new question answer pair
         * @summary Create Qa Pair
         * @param {PairRequest} pairRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQaPairLqmQapairPost: async (pairRequest: PairRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairRequest' is not null or undefined
            assertParamExists('createQaPairLqmQapairPost', 'pairRequest', pairRequest)
            const localVarPath = `/lqm/qapair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete existing question answer pair
         * @summary Delete Qa Pair
         * @param {any} pairId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQaPairLqmQapairPairIdDeletePatch: async (pairId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairId' is not null or undefined
            assertParamExists('deleteQaPairLqmQapairPairIdDeletePatch', 'pairId', pairId)
            const localVarPath = `/lqm/qapair/{pair_id}/delete`
                .replace(`{${"pair_id"}}`, encodeURIComponent(String(pairId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get UUIds of  experts
         * @summary Get Experts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertsLqmQapairExpertsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lqm/qapair/experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get question answer pair queue ( Assigned, Completed, Work_queue)
         * @summary Get Queue
         * @param {any} isCompleted True if completed, False if assigned
         * @param {any} workQueue True if work queue, False if Assigned/Completed
         * @param {any} [page] Page number
         * @param {any} [limit] Number of records per page
         * @param {any} [start] Start date
         * @param {any} [end] End date
         * @param {any} [assigneeId] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueLqmQapairQueueGet: async (isCompleted: any, workQueue: any, page?: any, limit?: any, start?: any, end?: any, assigneeId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isCompleted' is not null or undefined
            assertParamExists('getQueueLqmQapairQueueGet', 'isCompleted', isCompleted)
            // verify required parameter 'workQueue' is not null or undefined
            assertParamExists('getQueueLqmQapairQueueGet', 'workQueue', workQueue)
            const localVarPath = `/lqm/qapair/queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isCompleted !== undefined) {
                localVarQueryParameter['is_completed'] = isCompleted;
            }

            if (workQueue !== undefined) {
                localVarQueryParameter['work_queue'] = workQueue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (assigneeId !== undefined) {
                localVarQueryParameter['assignee_id'] = assigneeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get question answer pair counts
         * @summary Get Total Counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCountsLqmQapairCountsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lqm/qapair/counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search question answer pairs based on keywords, queue and user
         * @summary Search
         * @param {any} isCompleted True if completed, False if assigned
         * @param {any} workQueue True if work queue, False if Assigned/Completed
         * @param {any} searchString Search terms, either multiple words or a phrase in double quotes
         * @param {any} [page] Page number
         * @param {any} [limit] Number of records per page
         * @param {any} [assigneeId] User ID
         * @param {any} [start] Start date
         * @param {any} [end] End date
         * @param {any} [country] Number of records to return
         * @param {any} [stateProvince] List of states/provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLqmQapairSearchGet: async (isCompleted: any, workQueue: any, searchString: any, page?: any, limit?: any, assigneeId?: any, start?: any, end?: any, country?: any, stateProvince?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isCompleted' is not null or undefined
            assertParamExists('searchLqmQapairSearchGet', 'isCompleted', isCompleted)
            // verify required parameter 'workQueue' is not null or undefined
            assertParamExists('searchLqmQapairSearchGet', 'workQueue', workQueue)
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('searchLqmQapairSearchGet', 'searchString', searchString)
            const localVarPath = `/lqm/qapair/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isCompleted !== undefined) {
                localVarQueryParameter['is_completed'] = isCompleted;
            }

            if (workQueue !== undefined) {
                localVarQueryParameter['work_queue'] = workQueue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['search_string'] = searchString;
            }

            if (assigneeId !== undefined) {
                localVarQueryParameter['assignee_id'] = assigneeId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (stateProvince !== undefined) {
                localVarQueryParameter['state_province'] = stateProvince;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get question answer pair assignment
         * @summary Update Assignment
         * @param {any} pairId 
         * @param {any} [assigneeId] Assignee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentLqmQapairPairIdAssignmentPatch: async (pairId: any, assigneeId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairId' is not null or undefined
            assertParamExists('updateAssignmentLqmQapairPairIdAssignmentPatch', 'pairId', pairId)
            const localVarPath = `/lqm/qapair/{pair_id}/assignment`
                .replace(`{${"pair_id"}}`, encodeURIComponent(String(pairId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (assigneeId !== undefined) {
                localVarQueryParameter['assignee_id'] = assigneeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update existing question answer pair
         * @summary Update Qa Pair
         * @param {any} pairId 
         * @param {UpdatePairRequest} updatePairRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQaPairLqmQapairPairIdPost: async (pairId: any, updatePairRequest: UpdatePairRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairId' is not null or undefined
            assertParamExists('updateQaPairLqmQapairPairIdPost', 'pairId', pairId)
            // verify required parameter 'updatePairRequest' is not null or undefined
            assertParamExists('updateQaPairLqmQapairPairIdPost', 'updatePairRequest', updatePairRequest)
            const localVarPath = `/lqm/qapair/{pair_id}`
                .replace(`{${"pair_id"}}`, encodeURIComponent(String(pairId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePairRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeoQuestionManagerApi - functional programming interface
 * @export
 */
export const LeoQuestionManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeoQuestionManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * Bulk update question answer pair
         * @summary Bulk Update
         * @param {BulkUpdateRequest} bulkUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateLqmQapairBulkPatch(bulkUpdateRequest: BulkUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateLqmQapairBulkPatch(bulkUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new question answer pair
         * @summary Create Qa Pair
         * @param {PairRequest} pairRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQaPairLqmQapairPost(pairRequest: PairRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQaPairLqmQapairPost(pairRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete existing question answer pair
         * @summary Delete Qa Pair
         * @param {any} pairId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQaPairLqmQapairPairIdDeletePatch(pairId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQaPairLqmQapairPairIdDeletePatch(pairId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get UUIds of  experts
         * @summary Get Experts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpertsLqmQapairExpertsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpertsLqmQapairExpertsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get question answer pair queue ( Assigned, Completed, Work_queue)
         * @summary Get Queue
         * @param {any} isCompleted True if completed, False if assigned
         * @param {any} workQueue True if work queue, False if Assigned/Completed
         * @param {any} [page] Page number
         * @param {any} [limit] Number of records per page
         * @param {any} [start] Start date
         * @param {any} [end] End date
         * @param {any} [assigneeId] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQueueLqmQapairQueueGet(isCompleted: any, workQueue: any, page?: any, limit?: any, start?: any, end?: any, assigneeId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPairResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQueueLqmQapairQueueGet(isCompleted, workQueue, page, limit, start, end, assigneeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get question answer pair counts
         * @summary Get Total Counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalCountsLqmQapairCountsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalCountsLqmQapairCountsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search question answer pairs based on keywords, queue and user
         * @summary Search
         * @param {any} isCompleted True if completed, False if assigned
         * @param {any} workQueue True if work queue, False if Assigned/Completed
         * @param {any} searchString Search terms, either multiple words or a phrase in double quotes
         * @param {any} [page] Page number
         * @param {any} [limit] Number of records per page
         * @param {any} [assigneeId] User ID
         * @param {any} [start] Start date
         * @param {any} [end] End date
         * @param {any} [country] Number of records to return
         * @param {any} [stateProvince] List of states/provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchLqmQapairSearchGet(isCompleted: any, workQueue: any, searchString: any, page?: any, limit?: any, assigneeId?: any, start?: any, end?: any, country?: any, stateProvince?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPairResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchLqmQapairSearchGet(isCompleted, workQueue, searchString, page, limit, assigneeId, start, end, country, stateProvince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get question answer pair assignment
         * @summary Update Assignment
         * @param {any} pairId 
         * @param {any} [assigneeId] Assignee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignmentLqmQapairPairIdAssignmentPatch(pairId: any, assigneeId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignmentLqmQapairPairIdAssignmentPatch(pairId, assigneeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update existing question answer pair
         * @summary Update Qa Pair
         * @param {any} pairId 
         * @param {UpdatePairRequest} updatePairRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQaPairLqmQapairPairIdPost(pairId: any, updatePairRequest: UpdatePairRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQaPairLqmQapairPairIdPost(pairId, updatePairRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeoQuestionManagerApi - factory interface
 * @export
 */
export const LeoQuestionManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeoQuestionManagerApiFp(configuration)
    return {
        /**
         * Bulk update question answer pair
         * @summary Bulk Update
         * @param {BulkUpdateRequest} bulkUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateLqmQapairBulkPatch(bulkUpdateRequest: BulkUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bulkUpdateLqmQapairBulkPatch(bulkUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new question answer pair
         * @summary Create Qa Pair
         * @param {PairRequest} pairRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQaPairLqmQapairPost(pairRequest: PairRequest, options?: any): AxiosPromise<any> {
            return localVarFp.createQaPairLqmQapairPost(pairRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete existing question answer pair
         * @summary Delete Qa Pair
         * @param {any} pairId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQaPairLqmQapairPairIdDeletePatch(pairId: any, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQaPairLqmQapairPairIdDeletePatch(pairId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get UUIds of  experts
         * @summary Get Experts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertsLqmQapairExpertsGet(options?: any): AxiosPromise<any> {
            return localVarFp.getExpertsLqmQapairExpertsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get question answer pair queue ( Assigned, Completed, Work_queue)
         * @summary Get Queue
         * @param {any} isCompleted True if completed, False if assigned
         * @param {any} workQueue True if work queue, False if Assigned/Completed
         * @param {any} [page] Page number
         * @param {any} [limit] Number of records per page
         * @param {any} [start] Start date
         * @param {any} [end] End date
         * @param {any} [assigneeId] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueLqmQapairQueueGet(isCompleted: any, workQueue: any, page?: any, limit?: any, start?: any, end?: any, assigneeId?: any, options?: any): AxiosPromise<PaginatedPairResponse> {
            return localVarFp.getQueueLqmQapairQueueGet(isCompleted, workQueue, page, limit, start, end, assigneeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get question answer pair counts
         * @summary Get Total Counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCountsLqmQapairCountsGet(options?: any): AxiosPromise<QueueCountResponse> {
            return localVarFp.getTotalCountsLqmQapairCountsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Search question answer pairs based on keywords, queue and user
         * @summary Search
         * @param {any} isCompleted True if completed, False if assigned
         * @param {any} workQueue True if work queue, False if Assigned/Completed
         * @param {any} searchString Search terms, either multiple words or a phrase in double quotes
         * @param {any} [page] Page number
         * @param {any} [limit] Number of records per page
         * @param {any} [assigneeId] User ID
         * @param {any} [start] Start date
         * @param {any} [end] End date
         * @param {any} [country] Number of records to return
         * @param {any} [stateProvince] List of states/provinces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLqmQapairSearchGet(isCompleted: any, workQueue: any, searchString: any, page?: any, limit?: any, assigneeId?: any, start?: any, end?: any, country?: any, stateProvince?: any, options?: any): AxiosPromise<PaginatedPairResponse> {
            return localVarFp.searchLqmQapairSearchGet(isCompleted, workQueue, searchString, page, limit, assigneeId, start, end, country, stateProvince, options).then((request) => request(axios, basePath));
        },
        /**
         * Get question answer pair assignment
         * @summary Update Assignment
         * @param {any} pairId 
         * @param {any} [assigneeId] Assignee ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentLqmQapairPairIdAssignmentPatch(pairId: any, assigneeId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.updateAssignmentLqmQapairPairIdAssignmentPatch(pairId, assigneeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update existing question answer pair
         * @summary Update Qa Pair
         * @param {any} pairId 
         * @param {UpdatePairRequest} updatePairRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQaPairLqmQapairPairIdPost(pairId: any, updatePairRequest: UpdatePairRequest, options?: any): AxiosPromise<any> {
            return localVarFp.updateQaPairLqmQapairPairIdPost(pairId, updatePairRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeoQuestionManagerApi - object-oriented interface
 * @export
 * @class LeoQuestionManagerApi
 * @extends {BaseAPI}
 */
export class LeoQuestionManagerApi extends BaseAPI {
    /**
     * Bulk update question answer pair
     * @summary Bulk Update
     * @param {BulkUpdateRequest} bulkUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public bulkUpdateLqmQapairBulkPatch(bulkUpdateRequest: BulkUpdateRequest, options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).bulkUpdateLqmQapairBulkPatch(bulkUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new question answer pair
     * @summary Create Qa Pair
     * @param {PairRequest} pairRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public createQaPairLqmQapairPost(pairRequest: PairRequest, options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).createQaPairLqmQapairPost(pairRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete existing question answer pair
     * @summary Delete Qa Pair
     * @param {any} pairId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public deleteQaPairLqmQapairPairIdDeletePatch(pairId: any, options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).deleteQaPairLqmQapairPairIdDeletePatch(pairId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get UUIds of  experts
     * @summary Get Experts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public getExpertsLqmQapairExpertsGet(options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).getExpertsLqmQapairExpertsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get question answer pair queue ( Assigned, Completed, Work_queue)
     * @summary Get Queue
     * @param {any} isCompleted True if completed, False if assigned
     * @param {any} workQueue True if work queue, False if Assigned/Completed
     * @param {any} [page] Page number
     * @param {any} [limit] Number of records per page
     * @param {any} [start] Start date
     * @param {any} [end] End date
     * @param {any} [assigneeId] User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public getQueueLqmQapairQueueGet(isCompleted: any, workQueue: any, page?: any, limit?: any, start?: any, end?: any, assigneeId?: any, options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).getQueueLqmQapairQueueGet(isCompleted, workQueue, page, limit, start, end, assigneeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get question answer pair counts
     * @summary Get Total Counts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public getTotalCountsLqmQapairCountsGet(options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).getTotalCountsLqmQapairCountsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search question answer pairs based on keywords, queue and user
     * @summary Search
     * @param {any} isCompleted True if completed, False if assigned
     * @param {any} workQueue True if work queue, False if Assigned/Completed
     * @param {any} searchString Search terms, either multiple words or a phrase in double quotes
     * @param {any} [page] Page number
     * @param {any} [limit] Number of records per page
     * @param {any} [assigneeId] User ID
     * @param {any} [start] Start date
     * @param {any} [end] End date
     * @param {any} [country] Number of records to return
     * @param {any} [stateProvince] List of states/provinces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public searchLqmQapairSearchGet(isCompleted: any, workQueue: any, searchString: any, page?: any, limit?: any, assigneeId?: any, start?: any, end?: any, country?: any, stateProvince?: any, options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).searchLqmQapairSearchGet(isCompleted, workQueue, searchString, page, limit, assigneeId, start, end, country, stateProvince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get question answer pair assignment
     * @summary Update Assignment
     * @param {any} pairId 
     * @param {any} [assigneeId] Assignee ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public updateAssignmentLqmQapairPairIdAssignmentPatch(pairId: any, assigneeId?: any, options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).updateAssignmentLqmQapairPairIdAssignmentPatch(pairId, assigneeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update existing question answer pair
     * @summary Update Qa Pair
     * @param {any} pairId 
     * @param {UpdatePairRequest} updatePairRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeoQuestionManagerApi
     */
    public updateQaPairLqmQapairPairIdPost(pairId: any, updatePairRequest: UpdatePairRequest, options?: AxiosRequestConfig) {
        return LeoQuestionManagerApiFp(this.configuration).updateQaPairLqmQapairPairIdPost(pairId, updatePairRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Action
         * @param {any} notificationId 
         * @param {NotificationActionRequest} notificationActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionNotificationsNotificationIdActionPost: async (notificationId: any, notificationActionRequest: NotificationActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('actionNotificationsNotificationIdActionPost', 'notificationId', notificationId)
            // verify required parameter 'notificationActionRequest' is not null or undefined
            assertParamExists('actionNotificationsNotificationIdActionPost', 'notificationActionRequest', notificationActionRequest)
            const localVarPath = `/notifications/{notification_id}/action`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Notification
         * @param {NotificationCreate} notificationCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationNotificationsCreatePost: async (notificationCreate: NotificationCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationCreate' is not null or undefined
            assertParamExists('createNotificationNotificationsCreatePost', 'notificationCreate', notificationCreate)
            const localVarPath = `/notifications/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dismiss
         * @param {any} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissNotificationsNotificationIdDismissPost: async (notificationId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('dismissNotificationsNotificationIdDismissPost', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notification_id}/dismiss`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notification
         * @param {any} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationNotificationsNotificationIdGet: async (notificationId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('getNotificationNotificationsNotificationIdGet', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notification_id}`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Notifications
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotificationsNotificationsGet: async (page?: any, limit?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Action
         * @param {any} notificationId 
         * @param {NotificationActionRequest} notificationActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionNotificationsNotificationIdActionPost(notificationId: any, notificationActionRequest: NotificationActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionNotificationsNotificationIdActionPost(notificationId, notificationActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Notification
         * @param {NotificationCreate} notificationCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotificationNotificationsCreatePost(notificationCreate: NotificationCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotificationNotificationsCreatePost(notificationCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dismiss
         * @param {any} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dismissNotificationsNotificationIdDismissPost(notificationId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dismissNotificationsNotificationIdDismissPost(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Notification
         * @param {any} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationNotificationsNotificationIdGet(notificationId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationNotificationsNotificationIdGet(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Notifications
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotificationsNotificationsGet(page?: any, limit?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResourceNotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotificationsNotificationsGet(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Action
         * @param {any} notificationId 
         * @param {NotificationActionRequest} notificationActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionNotificationsNotificationIdActionPost(notificationId: any, notificationActionRequest: NotificationActionRequest, options?: any): AxiosPromise<any> {
            return localVarFp.actionNotificationsNotificationIdActionPost(notificationId, notificationActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Notification
         * @param {NotificationCreate} notificationCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationNotificationsCreatePost(notificationCreate: NotificationCreate, options?: any): AxiosPromise<NotificationResponse> {
            return localVarFp.createNotificationNotificationsCreatePost(notificationCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dismiss
         * @param {any} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissNotificationsNotificationIdDismissPost(notificationId: any, options?: any): AxiosPromise<void> {
            return localVarFp.dismissNotificationsNotificationIdDismissPost(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Notification
         * @param {any} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationNotificationsNotificationIdGet(notificationId: any, options?: any): AxiosPromise<NotificationResponse> {
            return localVarFp.getNotificationNotificationsNotificationIdGet(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Notifications
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotificationsNotificationsGet(page?: any, limit?: any, options?: any): AxiosPromise<ListResourceNotificationResponse> {
            return localVarFp.listNotificationsNotificationsGet(page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Action
     * @param {any} notificationId 
     * @param {NotificationActionRequest} notificationActionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public actionNotificationsNotificationIdActionPost(notificationId: any, notificationActionRequest: NotificationActionRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).actionNotificationsNotificationIdActionPost(notificationId, notificationActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Notification
     * @param {NotificationCreate} notificationCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createNotificationNotificationsCreatePost(notificationCreate: NotificationCreate, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createNotificationNotificationsCreatePost(notificationCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dismiss
     * @param {any} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public dismissNotificationsNotificationIdDismissPost(notificationId: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).dismissNotificationsNotificationIdDismissPost(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Notification
     * @param {any} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotificationNotificationsNotificationIdGet(notificationId: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotificationNotificationsNotificationIdGet(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Notifications
     * @param {any} [page] Page number, defaults to 1.
     * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public listNotificationsNotificationsGet(page?: any, limit?: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).listNotificationsNotificationsGet(page, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search
         * @param {any} q 
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSearchGet: async (q: any, page?: any, limit?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('searchSearchGet', 'q', q)
            const localVarPath = `/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search
         * @param {any} q 
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSearchGet(q: any, page?: any, limit?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSearchGet(q, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Search
         * @param {any} q 
         * @param {any} [page] Page number, defaults to 1.
         * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSearchGet(q: any, page?: any, limit?: any, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.searchSearchGet(q, page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary Search
     * @param {any} q 
     * @param {any} [page] Page number, defaults to 1.
     * @param {any} [limit] Size of a page, defaults to 10. Maximum is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchSearchGet(q: any, page?: any, limit?: any, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchSearchGet(q, page, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


