import NeoLeoAvatar from '../../assets/img/leo/neo-leo.png';

type NeoLeoTriggerProps = {
  neoLeoToggle(): void;
};

const NeoLeoTrigger: React.FC<NeoLeoTriggerProps> = ({ neoLeoToggle }) => {
  return (
    <button
      onClick={neoLeoToggle}
      className='mt-5 w-full flex justify-center items-center cursor-pointer'
    >
      <img className='w-9 h-9' src={NeoLeoAvatar} alt='neo-leo-avatar' />
    </button>
  );
};
export default NeoLeoTrigger;
