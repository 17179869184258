import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch } from 'react-redux';
import { TransactionResponse } from '../../../openapi/arrakis';
import { AgentControllerApi } from '../../../openapi/yenta';
import ErrorService from '../../../services/ErrorService';
import { showErrorToast } from '../../../slices/ToastNotificationSlice';
import { setTransactionTeamId } from '../../../slices/TransactionSlice';
import {
  AppDispatch,
  AsyncSelectOptionReactElement,
  ISelectOption,
} from '../../../types';
import { getYentaConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import ZenControlledSelectInput from '../Input/ZenControlledSelectInput';
import ZenSidebarModalForm from '../ZenSidebarModalForm';

interface ZenSetTeamIdModalProps {
  isOpen: boolean;
  onClose(): void;
  transaction: TransactionResponse;
}

interface FormData {
  teamId: AsyncSelectOptionReactElement;
}

const ZenSetTeamIdModal: React.FC<ZenSetTeamIdModalProps> = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const dispatch: AppDispatch = useDispatch();
  const [showTeams, setShowTeams] = useState<ISelectOption[]>([]);

  const onSubmit = async (data: FormData) => {
    await dispatch(setTransactionTeamId(transaction?.id!, data.teamId.value));
    onClose();
  };

  const filterOwnerTeams = useCallback(async () => {
    if (!transaction?.transactionOwner?.id) return;
    setShowTeams([]);
    try {
      const { data } = await new AgentControllerApi(
        getYentaConfiguration(),
      ).getAgentById(transaction?.transactionOwner?.id!);
      const teamsOptions = data?.flexTeamMemberships?.map((team) => ({
        value: team?.teamId!,
        label: team?.teamName!,
      }));
      setShowTeams(teamsOptions || []);
    } catch (e) {
      ErrorService.notify('Unable to fetch teams', e, {
        transaction: { transactionOwnerId: transaction?.transactionOwner?.id },
      });
      dispatch(
        showErrorToast(
          'We had a problem fetching the teams',
          'Please try again in a few moments.',
        ),
      );
    }
  }, [transaction?.transactionOwner?.id]);

  useEffect(() => {
    filterOwnerTeams();
  }, [filterOwnerTeams]);

  return (
    <ZenSidebarModalForm
      title='Set Team ID'
      subtitle='Set the Team ID for this transaction'
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionTitle='Save'
      isSubmitting={isSubmitting}
    >
      <ZenControlledSelectInput<FormData, 'teamId'>
        options={showTeams}
        name='teamId'
        label='Team'
        isRequired
        rules={{ required: 'Required' }}
        control={control}
        placeholder='Select Team'
        shouldUnregister={false}
      />
    </ZenSidebarModalForm>
  );
};

export default ZenSetTeamIdModal;
