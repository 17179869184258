import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../types';

export const useAgreements = () => {
  const {
    agentDetail: { allLatestAgreementsNotSigned },
  } = useSelector((state: RootState) => state);

  const [agreement, amendment, signedAgreement] = useMemo(() => {
    if (!allLatestAgreementsNotSigned?.length) return [null, null, null];

    const firstUnsignedAgreement = allLatestAgreementsNotSigned[0];

    if (!firstUnsignedAgreement.signedAt) {
      return [firstUnsignedAgreement, null, null];
    }

    const unsignedAmendment = firstUnsignedAgreement.amendments?.find(
      (amendment) => !amendment.signedAt,
    );

    return unsignedAmendment
      ? [null, unsignedAmendment, firstUnsignedAgreement]
      : [null, null, null];
  }, [allLatestAgreementsNotSigned]);

  return { agreement, amendment, signedAgreement };
};
