import {
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../openapi/arrakis';
import { EnumMap, FAQType } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import {
  getCommissionDocumentName,
  getCommissionDocumentsName,
  renderTransactionStatusByCountry,
} from '../../../utils/TransactionHelper';
import ZenSidebarModal from '../../Zen/ZenSidebarModal';

interface ZenTransactionHelpSidebarModalProps {
  transaction: TransactionResponse;
  isOpen: boolean;
  onClose(): void;
}

const ZenTransactionHelpSidebar: React.FC<ZenTransactionHelpSidebarModalProps> = ({
  transaction,
  isOpen,
  onClose,
}) => {
  const transactionStatusQuestionMap: EnumMap<
    TransactionLifecycleStateValueStateEnum,
    FAQType[]
  > = {
    [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: [
      {
        question: 'What is happening?',
        answer:
          'Real transaction team has approved this transaction. We are now waiting for payment from the commission payer.',
      },
      {
        question: 'What do I need to do?',
        answer:
          'Payment by wire may take up to 48 hours; payment by check may take up to 7 business days. Please confirm that the payment has been made. If payment is confirmed, you may contact Real support team to check on the status',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]: [
      {
        question: 'What is happening?',
        answer: `You have approved the ${getCommissionDocumentName(
          transaction.country!,
        ).toLowerCase()} and it has been sent to all assigned recipients.`,
      },
      {
        question: 'What do I need to do?',
        answer:
          'We are waiting on closing. Once closed, please upload your closing documents.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]: [
      {
        question: 'What is happening?',
        answer: `The ${getCommissionDocumentName(
          transaction.country!,
        ).toLowerCase()} has been generated and is awaiting your review.`,
      },
      {
        question: 'What do I need to do?',
        answer: `In Edit Transaction, click on View ${getCommissionDocumentsName(
          transaction.country!,
        )} or Invoices. Please review the latest ${getCommissionDocumentName(
          transaction.country!,
        )}. If there are errors, please make the necessary edits. If the ${getCommissionDocumentName(
          transaction.country!,
        ).toLowerCase()} is correct, please go ahead and approve it.`,
      },
    ],
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: [
      {
        question: 'What is happening?',
        answer: `The ${getCommissionDocumentName(
          transaction.country!,
        ).toLowerCase()} has been sent. We now are awaiting the closing.`,
      },
      {
        question: 'What do I need to do?',
        answer:
          'Once the transaction closes, make sure you have uploaded all of your required documents.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]: [
      {
        question: 'What is happening?',
        answer: `Your broker has approved your transaction. We are now generating your ${getCommissionDocumentName(
          transaction.country!,
        ).toLowerCase()}.`,
      },
      {
        question: 'What do I need to do?',
        answer: `Nothing right now. We will send you an email once your ${getCommissionDocumentName(
          transaction.country!,
        ).toLowerCase()} is generated.`,
      },
    ],
    [TransactionLifecycleStateValueStateEnum.Settled]: [
      {
        question: 'What is happening?',
        answer:
          'The transaction is closed, the payments have been received from the commission payer, and the commissions have been sent!',
      },
      {
        question: 'What do I need to do?',
        answer: "We're all done! You have received your payment.",
      },
    ],
    [TransactionLifecycleStateValueStateEnum.CalculateLedger]: [
      {
        question: 'What is happening?',
        answer: "We've received the transaction, but something is not right.",
      },
      {
        question: 'What do I need to do?',
        answer:
          'Please check any warnings or errors and make the necessary corrections.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: [
      {
        question: 'What is happening?',
        answer: 'Your closing documents have been reviewed and approved.',
      },
      {
        question: 'What do I need to do?',
        answer:
          'Hang tight. We are waiting on payment from the commission payer.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.Closed]: [
      {
        question: 'What is happening?',
        answer: 'Your closing documents have been reviewed and approved.',
      },
      {
        question: 'What do I need to do?',
        answer:
          'Hang tight. We are waiting on payment from the commission payer.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.CommissionValidated]: [
      {
        question: 'What is happening?',
        answer:
          'All of the participants and referrals have been set and the commissions have been signed off by you.',
      },
      {
        question: 'What do I need to do?',
        answer:
          "You're all set! We are waiting on the broker to approve the commissions. Please feel free to follow-up with your broker.",
      },
    ],
    [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]: [
      {
        question: 'What is happening?',
        answer: 'We need to make sure that commission calculation is correct.',
      },
      {
        question: 'What do I need to do?',
        answer:
          'Please verify that all the participants have been added, including referrals and transaction coordinators if applicable, and confirm the commissions are all correct.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.New]: [
      {
        question: 'What is happening?',
        answer: "We've received the transaction, but something is not right.",
      },
      {
        question: 'What do I need to do?',
        answer:
          'Please check any warnings or errors and make the necessary corrections.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: [
      {
        question: 'What is happening?',
        answer:
          'The transaction is closed, the payments have been received from the commission payer, and the commissions have been sent!',
      },
      {
        question: 'What do I need to do?',
        answer:
          "We're all done! You should be receiving your payment through your normal payment provider.",
      },
    ],
    [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: [
      {
        question: 'What is happening?',
        answer:
          'The transaction is closed, the payments have been received from the commission payer, and the commissions have been sent!',
      },
      {
        question: 'What do I need to do?',
        answer:
          'We are about to send your payment via your normal payment provider. Stay tuned!',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.Terminated]: [
      {
        question: 'What is happening?',
        answer: 'This transaction is terminated and is no longer valid.',
      },
      {
        question: 'What do I need to do?',
        answer:
          'If you would like to restart this transaction, please create a new transaction.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.ListingActive]: [
      { question: 'What is happening?', answer: 'This listing is active!' },
      {
        question: 'What do I need to do?',
        answer:
          'Please upload all of the required documents to the checklist. Once your listing is under contract, click Mark as In Contract.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.ListingClosed]: [
      {
        question: 'What is happening?',
        answer:
          'The listing and associated transaction are closed, the payments have been received from the commission payer, and the commissions have been sent!',
      },
      {
        question: 'What do I need to do?',
        answer: "We're all done! You have received your payment.",
      },
    ],
    [TransactionLifecycleStateValueStateEnum.ListingInContract]: [
      {
        question: 'What is happening?',
        answer: 'This listing is in contract!',
      },
      {
        question: 'What do I need to do?',
        answer:
          'Please upload outstanding required documents for the listing checklist and click view associated transaction to upload transaction documents and approve your commission.',
      },
    ],
    [TransactionLifecycleStateValueStateEnum.TerminationRequested]: [
      {
        question: 'What is happening?',
        answer:
          'Your transaction is in the process of being terminated, the broker team will review your transaction and approve when all requirements have been met.',
      },
      {
        question: 'What do I need to do?',
        answer:
          'Upload all termination documents for the broker team to review.',
      },
    ],
  };

  return (
    <ZenSidebarModal title='Help' isOpen={isOpen} onClose={onClose}>
      {!!transaction.lifecycleState?.state && (
        <div className='p-8'>
          <p className='font-zen-body font-semibold text-zen-dark-9 text-lg'>
            {renderTransactionStatusByCountry(
              transaction.lifecycleState?.state!,
              transaction.country!,
              transaction.transactionType,
            )}
          </p>
          {safeEnumMapGet(
            transactionStatusQuestionMap,
            transaction.lifecycleState?.state!,
            [],
          )?.map((item) => (
            <div className='font-zen-body mt-9' key={item?.question}>
              <p className='font-bold text-base text-zen-dark-9 mb-2'>
                {item?.question}
              </p>
              <p className='font-normal text-base text-zen-dark-9'>
                {item?.answer}
              </p>
            </div>
          ))}
        </div>
      )}
    </ZenSidebarModal>
  );
};

export default ZenTransactionHelpSidebar;
