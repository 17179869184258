import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT } from '../../../../../constants/LeoConstants';
import { ListingPosterItem } from '../../../../../utils/LeoUtils';
import { cn } from '../../../../../utils/classUtils';
import { useScreenSize } from '../../../../WeathManagement/utils';
import LeoSocialShareablesModal from '../Modal/LeoSocialShareableModal';
import LeoSocialShareabllesSmallModal from '../Modal/LeoSocialShareabllesSmallModal';

type NeoLeoSocialShareablesProps = {
  shareables: ListingPosterItem[];
};

const NeoLeoSocialShareables: React.FC<NeoLeoSocialShareablesProps> = ({
  shareables,
}) => {
  const { width } = useScreenSize();
  const [
    activeShareableIndex,
    setActiveShareableIndex,
  ] = React.useState<number>(0);
  const [showShareablesModal, setShowShareablesModal] = React.useState<boolean>(
    false,
  );
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const pageSize = DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT;
  const totalCount = shareables ? shareables.length : 0;
  const lastPage = Math.ceil(totalCount / pageSize);

  const goToPage = (page: number) => setCurrentPage(page);

  const onPrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    goToPage(currentPage - 1);
  };

  const onNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    goToPage(currentPage + 1);
  };

  return (
    <>
      <div>
        {/* <p className='fint-inter text-sm font-normal text-white mb-2'>
            Your Social Posts Options
          </p> */}
        <div className='flex flex-row justify-between items-center mb-2'>
          <p className='fint-inter text-sm font-medium text-white'>
            Social Media Posters
          </p>
          {totalCount > pageSize && (
            <div className='flex justify-between items-center'>
              <button
                className={cn(
                  currentPage === 0 ? 'text-gray-400' : 'text-white',
                  'disabled:cursor-not-allowed flex justify-center items-center cursor-pointer',
                )}
                onClick={(e) => {
                  e.preventDefault();
                  onPrevious(e);
                }}
                disabled={currentPage === 0}
                aria-label='go-previous'
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className='px-1 text-sm'
                />
              </button>

              <button
                className={cn(
                  currentPage === lastPage - 1 ? 'text-gray-400' : 'text-white',
                  'disabled:cursor-not-allowed flex justify-center items-center cursor-pointer',
                )}
                disabled={currentPage === lastPage - 1}
                onClick={(e) => {
                  e.preventDefault();
                  onNext(e);
                }}
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className='px-1 text-sm'
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='flex gap-1 flex-wrap items-center'>
        {shareables
          .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
          .map(({ posterLink, listingDetails: { id } }, ind) => (
            <div key={id} className='w-32 h-32 rounded-lg'>
              <img
                id='poster-image'
                src={posterLink}
                className='w-full h-full rounded-lg cursor-pointer'
                alt='poster'
                onClick={() => {
                  setActiveShareableIndex(ind);
                  setShowShareablesModal(true);
                }}
              />
            </div>
          ))}
      </div>

      <div>
        {showShareablesModal &&
          (width < 768 ? (
            <LeoSocialShareabllesSmallModal
              closeModal={() => {
                setShowShareablesModal(false);
              }}
              shareable={shareables[activeShareableIndex]}
            />
          ) : (
            <LeoSocialShareablesModal
              closeModal={() => {
                setShowShareablesModal(false);
              }}
              shareables={shareables}
              activeShareableIndex={activeShareableIndex}
            />
          ))}
      </div>
    </>
  );
};

export default NeoLeoSocialShareables;
