import React from 'react';
import AgentAgreementSignatureFormModal from '../../Agent/ICADocumentsV2/AgentAgrementSignatureFormModal';

export interface AgentAgreementSigningModalProps {
  isOpen: boolean;
  onClose(): void;
}

const AgentAgreementSigningModal: React.FC<AgentAgreementSigningModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
      title='zen-modal'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full lg:p-0'>
        <div className='rounded-xl z-10 relative' aria-label='simple-modal'>
          <AgentAgreementSignatureFormModal onClose={onClose} />
        </div>
      </div>
    </div>
  );
};

export default AgentAgreementSigningModal;
