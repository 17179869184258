import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserAvailabilityResponse } from '../../../../openapi/yenta';
import { AppDispatch, RootState } from '../../../../types';
import { formatDateRangeWithOrdinal } from '../../../../utils/RoarUtils';
import { Button } from '../../../commons/Button';
import { Modal } from '../../../commons/Modal';
import { useUpdateDoNotDisturb } from '../../../../query/roar/useRoar';
import { saveLoggedInAgentDetails } from '../../../../slices/AgentSlice';

type RemoveOutOfOfficeProps = {
  agentId: string;
  onClose(): void;
  opened: boolean;
};

const RemoveOutOfOffice: React.FC<RemoveOutOfOfficeProps> = ({
  agentId,
  onClose,
  opened,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const agentDetail = useSelector(
    (state: RootState) => state.agentDetail.detailResponse.data,
  );
  const userAvailability = (agentDetail?.availability ||
    {}) as UserAvailabilityResponse;
  const outOfOffice = userAvailability.outOfOffice?.[0]!;

  const { mutate, isLoading } = useUpdateDoNotDisturb(agentId);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size='540px'
      aria-label='remove-ooo-modal'
    >
      <Modal.Header className='bg-white border-b border-[#CCC] text-center text-xl'>
        <span>Delete Time Off?</span>
      </Modal.Header>
      <Modal.Content className='px-4 md:p-6'>
        <p className='text-center font-normal'>
          This will remove the following scheduled time off:
        </p>
        <p className='text-center font-semibold'>
          {formatDateRangeWithOrdinal(outOfOffice)}
        </p>
      </Modal.Content>
      <Modal.Footer className='border-t-0 p-6'>
        <Button
          variant='outline'
          color='black'
          classNames={{
            root: 'flex-1 md:flex-initial md:px-14',
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          classNames={{
            root: 'flex-1 md:flex-initial md:px-14',
          }}
          onClick={() =>
            mutate(
              { ...userAvailability, outOfOffice: [] },
              {
                onSuccess: (data) => {
                  dispatch(
                    saveLoggedInAgentDetails({
                      ...agentDetail,
                      availability: data.availability,
                    }),
                  );
                  onClose();
                },
              },
            )
          }
          loading={isLoading}
          disabled={isLoading}
          color='red'
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveOutOfOffice;
