import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../utils/classUtils';

interface FontAwesomeIconWithWrapperProps extends FontAwesomeIconProps {
  className?: string;
}

const FontAwesomeIconWithWrapper: React.FC<FontAwesomeIconWithWrapperProps> = ({
  className,
  ...props
}) => {
  return (
    <div className={cn('flex items-center justify-center', className)}>
      <FontAwesomeIcon {...props} />
    </div>
  );
};

export default FontAwesomeIconWithWrapper;
