import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { TransactionControllerApi } from '../../openapi/arrakis';
import ErrorService from '../../services/ErrorService';
import { RootState, EmailSupportType, ISelectOption } from '../../types';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { openEmailClientForSupportEmail } from '../../utils/SupportUtils';
import { getWebAppLink } from '../../utils/UrlUtils';
import Button from '../Button';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';

interface MyTransactionSupportIndexProps {
  navigateBack(): void;
}

interface FormData {
  transactionId: ISelectOption;
}

const MyTransactionSupport: React.FC<MyTransactionSupportIndexProps> = ({
  navigateBack,
}) => {
  const userDetail = useSelector((state: RootState) => state.auth.userDetail);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const onSubmit = (data: FormData) => {
    const [address, code] = data.transactionId.label.split(' - ');
    const id = data.transactionId.value;

    const body = `\n\n-------\n\n${address}\n${code}\n\n${getWebAppLink(
      `/transactions/${id}`,
    )}\n\n
    `;

    return openEmailClientForSupportEmail(
      EmailSupportType.MY_TRANSACTION,
      userDetail!,
      encodeURIComponent(body),
    );
  };

  return (
    <div className='space-y-2 m-4 -mt-10 bg-white shadow-md rounded'>
      <div className='p-4'>
        <div className='flex space-x-2 mb-4'>
          <FontAwesomeIcon
            icon={faArrowLeft}
            size='lg'
            onClick={navigateBack}
          />
          <p className='text-lg'>Transactions Support</p>
        </div>
        <ZenControlledAsyncSelectInput<FormData, 'transactionId'>
          name='transactionId'
          control={control}
          label='Which transaction do you have questions about?'
          menuPosition='fixed'
          placeholder='Choose a transaction'
          fetchData={async (search, page) => {
            let options: ISelectOption[] = [];

            try {
              const [
                { data: openTransactions },
                { data: closedTransactions },
              ] = await Promise.all([
                // Fetch open transactions
                new TransactionControllerApi(
                  getArrakisConfiguration(),
                ).getTransactionsByStateGroupPaginated(
                  userDetail?.id!,
                  'OPEN',
                  page || 0,
                  10,
                  search,
                  'CREATED_AT',
                  'DESC',
                ),
                // Fetch closed transactions
                new TransactionControllerApi(
                  getArrakisConfiguration(),
                ).getTransactionsByStateGroupPaginated(
                  userDetail?.id!,
                  'CLOSED',
                  page || 0,
                  10,
                  search,
                  'CREATED_AT',
                  'DESC',
                ),
              ]);

              const allTransactions = [
                ...(openTransactions?.transactions || []),
                ...(closedTransactions?.transactions || []),
              ];

              options = allTransactions.map((transaction) => ({
                label: `${transaction.address?.oneLine} - ${transaction.code}`,
                value: transaction.id!,
              }));
            } catch (e) {
              ErrorService.notify('Unable to fetch transactions', e, {
                data: { search, page },
              });
            }

            return options;
          }}
          rules={{
            required: 'Please select a transaction',
          }}
          isRequired
        />
        <div className='mt-40'>
          <Button
            label='Email Support'
            type='primary'
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default MyTransactionSupport;
