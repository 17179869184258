import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import ZenAgentDetailHeader from '../../components/Agent/V2/ZenAgentDetailHeader';
import ZenAgentDetailActionBar from '../../components/Agent/ZenAgentDetailActionBar';
import ZenTaxEquity from '../../components/Agent/ZenTaxEquity';
import { ZenTaxEquityAwards } from '../../components/Agent/ZenTaxEquityAwards';
import { ZenTaxEquityMatch } from '../../components/Agent/ZenTaxEquityMatch';
import ZenTaxNetCommission from '../../components/Agent/ZenTaxNetCommission';
import ZenTaxRevShare from '../../components/Agent/ZenTaxRevShare';
import AuthorizationContainer from '../../components/auth/AuthorizationContainer';
import DetailPageLoader from '../../components/DetailPageLoader';
import GeminiAgentDetailHeader from '../../components/Gemini/Agent/GeminiAgentDetailHeader';
import PageLayout from '../../components/PageLayout';
import ResourceContainer from '../../components/ResourceContainer';
import ZenOfficeHours from '../../components/Roar/OfficeHours/ZenOfficeHours';
import RouteByRole from '../../components/RouteByRole';
import ZenInstantPaymentResourceContainer from '../../components/Zen/Containers/ZenInstantPaymentResourceContainer';
import ZenConfirmationModal from '../../components/Zen/Modal/ZenConfirmationModal';
import ZenAgentActivity from '../../components/Zen/RoadToSuccess/ZenAgentActivity';
import ZenAgentJourney from '../../components/Zen/RoadToSuccess/ZenAgentJourney';
import ZenRouterTabs, { ZenTab } from '../../components/Zen/Tab/ZenRouterTabs';
import ZenBasicInformationForm from '../../forms/ZenBasicInformationForm';
import ZenProfileEditForm from '../../forms/ZenProfileEditForm';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { CacheControllerApi } from '../../openapi/arrakis';
import { ItemResponseStatusEnum } from '../../openapi/sherlock';
import { AgentResponse } from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import SessionStorageService from '../../services/SessionStorageService';
import {
  fetchAgentDetail,
  fetchAgentFiles,
  fetchAgentPerformance,
  fetchAgentUpline,
  fetchCurrentRevShareOverview,
  fetchEquity,
  fetchIncomeTotals,
  fetchNationalIdentifications,
  fetchYearlyIncomePerformanceOverview,
  getAgentPayableInfo,
} from '../../slices/AgentSlice';
import {
  changeAgentOnboardingCompleteModalStatus,
  fetchJourney,
  saveJourney,
} from '../../slices/CheckListSlice';
import { showApiErrorModal } from '../../slices/ErrorSlice';
import {
  fetchAllAgentJVInvitations,
  fetchAllJVInvitations,
} from '../../slices/JointVentureSlice';
import { fetchZenFrontLineForAgent } from '../../slices/NetworkSlice';
import { getTaxDocuments } from '../../slices/TaxSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import { fetchTransactionsCountGroupByLifecycleState } from '../../slices/TransactionSlice';
import {
  AppDispatch,
  FeatureFlagTypeEnum,
  KBarActionEnum,
  RootState,
  SessionStorageKeyEnum,
  UserType,
  YesNoType,
} from '../../types';
import { isAgentRejectedOrCandidate } from '../../utils/AgentHelper';
import { getArrakisConfiguration } from '../../utils/OpenapiConfigurationUtils';
import { showSingleCheckPreference } from '../../utils/SingleCheckUtils';
import AgentDetailPaymentSettingsRoute from '../AgentDetailPaymentSettingsRoute';
import AgentDetailSecuritySettingsRoute from '../AgentDetailSecuritySettingsRoute';
import AgentWebsiteOnboardingRoute from '../AgentWebsiteOnboardingRoute';
import AllDebtsRoute from '../AllDebtsRoute';
import Route404 from '../Route404';
import WebhooksTableRoute from '../WebhookTableRoute';
import ZenAgentDetailCreditsRoute from '../ZenAgentDetailCreditsRoute';
import ZenAgentDetailEquityRoute from '../ZenAgentDetailEquityRoute';
import ZenAgentDetailListingsRoute from '../ZenAgentDetailListingsRoute';
import ZenAgentDetailRevShareContributors from '../ZenAgentDetailRevShareContributors';
import ZenAgentDetailRevShareInsights from '../ZenAgentDetailRevShareInsights';
import ZenAgentDetailRevSharePayments from '../ZenAgentDetailRevSharePayments';
import ZenAgentDetailRevSharePendingPayment from '../ZenAgentDetailRevSharePendingPayment';
import ZenAgentDetailTransactionsRoute from '../ZenAgentDetailTransactionsRoute';
import ZenAgentDetailWebsiteRoute from '../ZenAgentDetailWebsiteRoute';
import ZenAgentDetailFilesRoute from './ZenAgentDetailFilesRoute';
import ZenAgentDetailOutgoingPaymentsRoute from './ZenAgentDetailOutgoingPayments';
import ZenAgentDetailPaymentsRoute from './ZenAgentDetailPaymentsRoute';
import ZenAgentOverviewRoute from './ZenAgentOverviewRoute';
import ZenAgentProfileRoute from './ZenAgentProfileRoute';

type Match = {
  id: string;
};

const ZenAgentDetailRoute: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { id: agentId } = useParams() as Match;
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(true);
  const [
    openEditAgentDetailsSideModal,
    setOpenEditAgentDetailsSideModal,
  ] = useState<boolean>(false);

  const {
    agentDetail: {
      detailResponse: agentDetailResponse,
      performanceResponse: { data: performance },
      agentUplineInfoById,
    },
    transaction: {
      transactionsCountByLifecycleGroup,
      listingCountByLifecycleGroup,
    },
    jointVenture: { invitedJointVentures },
    auth: { isBroker, isAdmin, userDetail, isSuperAdmin },
    revenue: { contributors },
    checklist: { journey, isAgentOnboardingCompleteModalShown },
    kBar: { sidebarToOpen },
  } = useSelector((state: RootState) => state);

  const agentNotRejectedOrCandidate = useMemo(
    () => !isAgentRejectedOrCandidate(agentDetailResponse.data?.agentStatus!),
    [agentDetailResponse.data?.agentStatus],
  );

  const [openProfileUpdateForm, setOpenProfileUpdateForm] = useState<boolean>(
    false,
  );
  const isAgentUplineRecordAvailable = useRef(!!agentUplineInfoById[agentId])
    .current;
  const isRealTitleEnabled = useFeatureFlag(FeatureFlagTypeEnum.REAL_TITLE);
  const isMSDynamicsOnboardingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING,
  );
  const isRoarEnabled = useFeatureFlag(FeatureFlagTypeEnum.ROAR);

  const isOnboardingChecklistTabVisible =
    !!journey?.items?.length &&
    journey?.items?.some?.(
      (item) => item?.status !== ItemResponseStatusEnum.Accepted,
    );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const agentResponse = await dispatch(fetchAgentDetail(agentId));
    if (
      !agentResponse ||
      isAgentRejectedOrCandidate(agentResponse?.agentStatus!)
    ) {
      return;
    }
    let requests = [
      agentResponse,
      dispatch(fetchZenFrontLineForAgent(agentId)),
      dispatch(fetchIncomeTotals(agentId)),
      dispatch(fetchYearlyIncomePerformanceOverview(agentId)),
      dispatch(fetchAgentPerformance(agentId)),
      dispatch(fetchCurrentRevShareOverview(agentId)),
      dispatch(fetchEquity(agentId)),
      dispatch(getTaxDocuments(agentId)),
      dispatch(getAgentPayableInfo(agentId)),
      dispatch(
        fetchTransactionsCountGroupByLifecycleState(agentId, 'TRANSACTION'),
      ),
      dispatch(fetchTransactionsCountGroupByLifecycleState(agentId, 'LISTING')),
    ];
    if (isAdmin || userDetail?.id === agentId) {
      requests.push(dispatch(fetchNationalIdentifications(agentId)));
    }
    if (isAdmin || isBroker) {
      requests.push(dispatch(fetchAgentFiles(agentId)));
    }
    if (!isAgentUplineRecordAvailable) {
      requests.push(dispatch(fetchAgentUpline(agentId)));
    }
    if (isRealTitleEnabled && !isBroker && isAdmin) {
      requests.push(dispatch(fetchAllAgentJVInvitations(agentId)));
    }
    if (isRealTitleEnabled && userDetail?.id === agentId) {
      requests.push(dispatch(fetchAllJVInvitations()));
    }
    return Promise.all(requests);
  }, [
    agentId,
    dispatch,
    isAdmin,
    isAgentUplineRecordAvailable,
    isBroker,
    isRealTitleEnabled,
    userDetail?.id,
  ]);

  const handleClearCache = async () => {
    try {
      await new CacheControllerApi(getArrakisConfiguration()).flushAgentCache(
        agentId,
      );
      dispatch(showSuccessToast('Successfully cleared the cache.'));
      fetchData().finally(() => {
        setLoading(false);
      });
    } catch (e) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to clear the cache', e, {
        agent: { id: agentId },
      });
      dispatch(
        showErrorToast(
          'We had a problem clearing the cache',
          'Please try again in a few moments.',
        ),
      );
    }
  };

  useEffect(() => {
    fetchData()
      .then(async (res) => {
        const agentRes = res?.[0] as AgentResponse | undefined;
        const sessionValue = SessionStorageService.getItem(
          SessionStorageKeyEnum.STOP_PAGE_REDIRECTS,
        );
        const stopPageRedirects = sessionValue === YesNoType.YES;
        if (!!agentRes?.onboardingChecklistId) {
          const data = await dispatch(
            fetchJourney(agentRes?.onboardingChecklistId),
          );
          const isTabVisible =
            !!data?.items?.length &&
            data?.items?.some?.(
              (item) => item?.status !== ItemResponseStatusEnum.Accepted,
            );
          // if the user is an admin, or the stop page redirects flag is false,
          // and the tab is visible, then auto redirect to the onboarding checklist tab
          if ((isAdmin || !stopPageRedirects) && isTabVisible) {
            history.push(`/people/${agentId}/onboarding-checklist`);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      // clear the journey state
      dispatch(saveJourney(null));
    };
  }, [isAdmin, agentId, dispatch, fetchData, history]);

  const path = [
    { title: 'Home', url: '/' },
    {
      title: isSuperAdmin || isAdmin || isBroker ? 'People' : 'Profile',
      url:
        isSuperAdmin || isAdmin || isBroker
          ? '/people'
          : `/people/${userDetail?.id}`,
    },
  ];

  if (agentDetailResponse) {
    path.push({
      title: agentDetailResponse?.data?.fullName!,
      url: `/people/${agentDetailResponse?.data?.id}`,
    });

    if (location.pathname.includes('transactions')) {
      path.push({
        title: 'Transactions',
        url: `/people/${agentDetailResponse?.data?.id}/transactions`,
      });
    }

    if (location.pathname.includes('webhooks')) {
      path.push(
        {
          title: 'Security',
          url: `/people/${agentDetailResponse?.data?.id}/security-settings`,
        },
        {
          title: 'Webhooks',
          url: `/people/${agentDetailResponse?.data?.id}/webhooks`,
        },
      );
    }

    if (location.pathname.includes('listings')) {
      path.push({
        title: 'Listings',
        url: `/people/${agentDetailResponse?.data?.id}/listings`,
      });
    }

    if (location.pathname.includes('credits')) {
      path.push({
        title: 'Credits',
        url: `/people/${agentDetailResponse?.data?.id}/credits`,
      });
    }

    if (location.pathname.includes('/instant-payments')) {
      path.push({
        title: 'Instant Payments',
        url: `/people/${agentDetailResponse?.data?.id}/instant-payments`,
      });
    }

    if (location.pathname.includes('/revshare')) {
      if (location.pathname.endsWith('/insights')) {
        path.push({
          title: 'Revenue Share Insights',
          url: `/people/${agentDetailResponse?.data?.id}/revshare/insights`,
        });
      } else {
        path.push({
          title: 'Rev. Share Payments',
          url: `/people/${agentDetailResponse?.data?.id}/revshare/payments`,
        });
      }
    }

    if (location.pathname.includes('/revshare/pending-payments')) {
      path.push({
        title: 'Pending',
        url: `/people/${agentDetailResponse?.data?.id}/revshare/pending-payments`,
      });
    } else if (
      location.pathname.includes('/revshare/payments') &&
      !location.pathname.endsWith('/revshare/payments') &&
      !location.pathname.endsWith('/debt-repayment') &&
      contributors?.createdAt
    ) {
      path.push({
        title: `${DateTime.fromMillis(contributors.createdAt).toLocaleString(
          DateTime.DATE_MED,
        )}`,
        url: `/people/${agentDetailResponse?.data?.id}/revshare/payments/${contributors.outgoingPaymentId}`,
      });
    }

    if (
      location.pathname.endsWith('/debt-repayment') &&
      contributors?.createdAt
    ) {
      path.push({
        title: 'Rev. Share Debt Repayments',
        url: `/people/${agentDetailResponse?.data?.id}/revshare/payments/${contributors.outgoingPaymentId}/debt-repayment`,
      });
    }

    if (location.pathname.includes('equity')) {
      path.push({
        title: 'Equity',
        url: `/people/${agentDetailResponse?.data?.id}/equity`,
      });
    }

    if (location.pathname.includes('invoices')) {
      path.push({
        title: 'Invoices',
        url: `/people/${agentDetailResponse?.data?.id}/invoices`,
      });
    }

    if (location.pathname.includes('security-settings')) {
      path.push({
        title: 'Security Settings',
        url: `/people/${agentDetailResponse?.data?.id}/security-settings`,
      });
    }

    if (location.pathname.includes('payment-settings')) {
      path.push({
        title: 'Payment Settings',
        url: `/people/${agentDetailResponse?.data?.id}/payment-settings`,
      });
    }

    if (location.pathname.includes('outgoing-payments')) {
      path.push({
        title: 'Outgoing Payments',
        url: `/people/${agentDetailResponse?.data?.id}/outgoing-payments`,
      });
    }

    if (location.pathname.includes('agent-documents')) {
      path.push({
        title: 'Agent Documents',
        url: `/people/${agentDetailResponse?.data?.id}/agent-documents`,
      });
    }

    if (location.pathname.includes('agent-website')) {
      path.push({
        title: 'Agent Website',
        url: `/people/${agentDetailResponse?.data?.id}/agent-website`,
      });
    }

    if (location.pathname.includes('all-debts')) {
      path.push({
        title: 'Debts',
        url: `/people/${agentDetailResponse?.data?.id}/all-debts`,
      });
    }

    if (
      location.pathname.includes('/onboarding-checklist') &&
      isOnboardingChecklistTabVisible
    ) {
      path.push({
        title: 'Onboarding Checklist',
        url: `/people/${agentDetailResponse?.data?.id}/onboarding-checklist`,
      });
    }
  }

  const isSingleCheckAllowed = useMemo(
    () => showSingleCheckPreference(agentDetailResponse.data),
    [agentDetailResponse.data],
  );

  const profileTabs: ZenTab[] = useMemo(() => {
    let tabs = [
      {
        label: 'Overview',
        TabComponent: () => <ZenAgentOverviewRoute agentId={agentId} />,
        path: `/people/${agentId}`,
        exact: true,
      },
      {
        label: 'Profile',
        path: `/people/${agentId}/profile`,
        TabComponent: () => (
          <ZenAgentProfileRoute
            agentId={agentId}
            setOpenEditAgentDetailsSideModal={setOpenEditAgentDetailsSideModal}
          />
        ),
        exact: true,
      },
    ];

    if (agentNotRejectedOrCandidate) {
      tabs.push({
        label: 'Outgoing Payments',
        path: `/people/${agentId}/outgoing-payments`,
        TabComponent: () => (
          <ZenAgentDetailOutgoingPaymentsRoute agentId={agentId} />
        ),
        exact: true,
      });
    }

    if (
      agentNotRejectedOrCandidate &&
      (isMSDynamicsOnboardingEnabled || isSingleCheckAllowed)
    ) {
      tabs.splice(2, 0, {
        label: 'Payment Settings',
        path: `/people/${agentId}/payment-settings`,
        TabComponent: () => (
          <AgentDetailPaymentSettingsRoute agentId={agentId} />
        ),
        exact: true,
      });
    }
    if (agentNotRejectedOrCandidate && (isAdmin || isBroker)) {
      tabs = [
        ...tabs,
        {
          label: 'Payment Invoices',
          path: `/people/${agentId}/payments`,
          TabComponent: () => <ZenAgentDetailPaymentsRoute agentId={agentId} />,
          exact: true,
        },
      ];
    }

    if (agentNotRejectedOrCandidate && isAdmin) {
      tabs.push({
        label: 'Instant Payments',
        path: `/people/${agentId}/instant-payments`,
        TabComponent: () => (
          <ZenInstantPaymentResourceContainer agentId={agentId} />
        ),
        exact: true,
      });
    }

    if (
      agentNotRejectedOrCandidate &&
      (isOnboardingChecklistTabVisible || isAgentOnboardingCompleteModalShown)
    ) {
      tabs?.unshift({
        label: 'Onboarding Checklist',
        TabComponent: () => <ZenAgentJourney />,
        path: `/people/${agentId}/onboarding-checklist`,
        exact: true,
      });
    }

    if (isAdmin) {
      tabs.push({
        label: 'Activity',
        TabComponent: () => <ZenAgentActivity agentId={agentId} />,
        path: `/people/${agentId}/activity`,
        exact: true,
      });
    }

    if (
      isRoarEnabled &&
      ((agentId === userDetail?.id && isBroker) || isAdmin)
    ) {
      tabs.push({
        label: 'Office Hours',
        TabComponent: () => <ZenOfficeHours agentId={agentId} />,
        path: `/people/${agentId}/office-hours`,
        exact: true,
      });
    }

    return tabs;
  }, [
    agentId,
    agentNotRejectedOrCandidate,
    isMSDynamicsOnboardingEnabled,
    isSingleCheckAllowed,
    isAdmin,
    isBroker,
    isOnboardingChecklistTabVisible,
    isAgentOnboardingCompleteModalShown,
    userDetail?.id,
    isRoarEnabled,
  ]);

  const tabsPathRegex = `(${profileTabs.map((tab) => tab.path).join('|')})`;
  const hideHeader =
    location.pathname.endsWith('/revshare/insights') ||
    location.pathname.endsWith(
      `/people/${agentDetailResponse?.data?.id}/webhooks`,
    );

  useEffect(() => {
    if (sidebarToOpen === KBarActionEnum.NOTIFICATION_SETTINGS) {
      setOpenEditAgentDetailsSideModal(true);
    }
  }, [sidebarToOpen]);

  const geminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );

  const HeaderComponent = geminiFeatureFlagEnabled
    ? GeminiAgentDetailHeader
    : ZenAgentDetailHeader;

  return (
    <AuthorizationContainer asyncResponse={agentDetailResponse}>
      <ResourceContainer
        loading={loading || agentDetailResponse.loading}
        LoaderComponent={<DetailPageLoader />}
        errorCode={agentDetailResponse.error}
        isEmpty={!agentDetailResponse?.data}
        resourceName='agent'
      >
        {!!agentDetailResponse?.data && (
          <PageLayout path={path}>
            {!hideHeader && (
              <div className='flex flex-row px-5 bg-white my-4'>
                <HeaderComponent
                  agentId={agentId}
                  detail={agentDetailResponse?.data!}
                  performance={performance!}
                  invitedJointVentures={invitedJointVentures.data!}
                  transactionOverview={[
                    {
                      title: transactionsCountByLifecycleGroup?.OPEN || 0,
                      subtitle: 'active',
                    },
                    {
                      title: transactionsCountByLifecycleGroup?.CLOSED || 0,
                      subtitle: 'closed',
                    },
                    {
                      title: transactionsCountByLifecycleGroup?.TERMINATED || 0,
                      subtitle: 'terminated',
                    },
                  ]}
                  listingOverview={[
                    {
                      title: listingCountByLifecycleGroup?.OPEN || 0,
                      subtitle: 'active',
                    },
                    {
                      title: listingCountByLifecycleGroup?.CLOSED || 0,
                      subtitle: 'closed',
                    },
                    {
                      title: listingCountByLifecycleGroup?.TERMINATED || 0,
                      subtitle: 'terminated',
                    },
                  ]}
                />
              </div>
            )}
            <Switch>
              {isAdmin && (
                <Route path={`${match.path}/credits`} exact>
                  <ZenAgentDetailCreditsRoute
                    agent={agentDetailResponse?.data!}
                  />
                </Route>
              )}

              <Route
                path={`${match.path}/security-settings`}
                component={AgentDetailSecuritySettingsRoute}
                exact
              />
              <Route
                path={`${match.path}/webhooks`}
                component={WebhooksTableRoute}
                exact
              />
              <Route
                path={`${match.path}/transactions/:type(closed|terminated)?`}
                component={ZenAgentDetailTransactionsRoute}
                exact
              />
              <Route
                path={`${match.path}/listings/:type(closed|terminated)?`}
                component={ZenAgentDetailListingsRoute}
                exact
              />

              <Route
                path={`${match.path}/revshare/pending-payments`}
                component={ZenAgentDetailRevSharePendingPayment}
                exact
              />
              <Route
                path={`${match.path}/revshare/payments/:outgoingPaymentId/:type(debt-repayment)?`}
                component={ZenAgentDetailRevShareContributors}
                exact
              />
              <Route
                path={`${match.path}/revshare/payments`}
                component={ZenAgentDetailRevSharePayments}
                exact
              />
              <Route
                path={`${match.path}/revshare/insights`}
                component={ZenAgentDetailRevShareInsights}
                exact
              />
              <Route
                path={`${match.path}/equity`}
                component={ZenAgentDetailEquityRoute}
                exact
              />
              <Route
                path={`${match.path}/tax-documents/:year/net-commissions`}
                component={ZenTaxNetCommission}
                exact
              />
              <Route
                path={`${match.path}/tax-documents/:year/rev-share`}
                component={ZenTaxRevShare}
                exact
              />
              <Route
                path={`${match.path}/tax-documents/:year/equity`}
                component={ZenTaxEquity}
                exact
              />
              <Route
                path={`${match.path}/tax-documents/:year/equity-match`}
                component={ZenTaxEquityMatch}
                exact
              />
              <Route
                path={`${match.path}/tax-documents/:year/equity-awards`}
                component={ZenTaxEquityAwards}
                exact
              />
              <RouteByRole
                path={`${match.path}/agent-documents`}
                config={{
                  [UserType.ADMIN]: ZenAgentDetailFilesRoute,
                  [UserType.BROKER]: ZenAgentDetailFilesRoute,
                }}
                exact
              />
              <Route
                path={`${match.path}/agent-website`}
                component={ZenAgentDetailWebsiteRoute}
                exact
              />
              <Route
                path={`${match.path}/agent-website-onboarding`}
                component={AgentWebsiteOnboardingRoute}
                exact
              />
              {agentNotRejectedOrCandidate && (
                <RouteByRole
                  path={`${match.path}/all-debts`}
                  component={AllDebtsRoute}
                  exact
                />
              )}
              <Route path={match.path}>
                {!hideHeader && isAdmin && (
                  <div className='flex flex-row px-5 space-x-4'>
                    <ZenAgentDetailActionBar
                      agentInfo={agentDetailResponse?.data!}
                      handleClearCache={handleClearCache}
                    />
                  </div>
                )}
                <Switch>
                  <Route path={tabsPathRegex} exact>
                    <ZenRouterTabs tabs={profileTabs} />
                  </Route>
                  <Route path={`${match.path}/*`} component={Route404} />
                </Switch>
              </Route>
            </Switch>
            {!!agentDetailResponse?.data! && openEditAgentDetailsSideModal && (
              <ZenBasicInformationForm
                isOpen={openEditAgentDetailsSideModal}
                onClose={() => setOpenEditAgentDetailsSideModal(false)}
                agent={agentDetailResponse?.data!}
              />
            )}

            {/* <ProfileEditForm
            isOpen={openProfileUpdateForm}
            onClose={() => setOpenProfileUpdateForm(false)}
            agent={agentDetailResponse!}
          /> */}
          </PageLayout>
        )}
        {!!agentDetailResponse?.data && openProfileUpdateForm && (
          <ZenProfileEditForm
            isOpen={openProfileUpdateForm}
            onClose={() => setOpenProfileUpdateForm(false)}
            agent={agentDetailResponse?.data!}
          />
        )}
        <ZenConfirmationModal
          isOpen={isAgentOnboardingCompleteModalShown}
          size='default'
          hideIcon
          title='Congratulations!'
          subtitle='You have completed your onboarding checklist! You now have everything you need to have a successful journey here at Real. We look forward to seeing what you accomplish!'
          confirmButtonText='Ok'
          hideCancelButton
          onConfirm={async () => {
            await dispatch(changeAgentOnboardingCompleteModalStatus(false));
            history.push(`/people/${agentId}`);
          }}
          onClose={() => undefined}
          isSubmitting={false}
        />
      </ResourceContainer>
    </AuthorizationContainer>
  );
};

export default ZenAgentDetailRoute;
