import { get } from 'lodash';
import { Controller, UseControllerOptions } from 'react-hook-form-v6';
import { FieldValues } from 'react-hook-form-v6/dist/types';
import { FieldErrors } from 'react-hook-form-v6/dist/types/errors';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { AdministrativeAreaRequestCountryEnum } from '../openapi/yenta';
import { FormFieldTooltipIndexProps } from '../types';
import { getCountryFlagCode } from '../utils/StringUtils';
import FormErrorMessage from './FormErrorMessage';

export interface ControlledPhoneNumberInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends UseControllerOptions<TFieldValues>,
    FormFieldTooltipIndexProps {
  errors: FieldErrors<TFieldValues>;
  type?: 'number';
  label?: string;
  readOnly?: boolean;
  placeholder?: string;
  defaultCountryCode?: AdministrativeAreaRequestCountryEnum;
}

const ControlledPhoneNumberInput: React.FC<ControlledPhoneNumberInputProps> = ({
  errors,
  control,
  name,
  label,
  defaultValue,
  rules,
  type = 'text',
  readOnly,
  placeholder,
  tooltipIndex,
  defaultCountryCode,
}) => {
  const error = get(errors, name);

  return (
    <div className='space-y-1 w-full'>
      {label && (
        <label className='inline-block' htmlFor={name}>
          {label}
        </label>
      )}
      <Controller
        id={name}
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue || ''}
        render={({ name, value, onChange, onBlur }) => (
          <PhoneInput
            onlyCountries={['us', 'ca']}
            value={value}
            country={getCountryFlagCode(defaultCountryCode)}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            inputProps={{
              readOnly,
              name,
              type,
              'data-tooltip-index': tooltipIndex,
              autoFocus: true,
            }}
            inputStyle={{ width: '100%' }}
            jumpCursorToEnd={false}
          />
        )}
      />
      {error && <FormErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledPhoneNumberInput;
