import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { BreadCrumbsPath } from '../../types';
import { cn } from '../../utils/classUtils';

export interface BreadCrumbsProps {
  path: BreadCrumbsPath[];
}

const GeminiBreadCrumbsWithUrls: React.FC<BreadCrumbsProps> = ({ path }) => {
  const pathLength = path.length;
  return (
    <div className='flex flex-row flex-nowrap scrollbar overflow-x-auto'>
      {path.map((link, index) => {
        return (
          <div
            key={link.title}
            className='flex flex-row items-center text-center'
          >
            <Link
              to={link.url}
              className={cn(
                'whitespace-nowrap max-w-2 truncate lg:max-w-none text-primary-dark',
                pathLength - 1 !== index && 'text-gray-400',
              )}
            >
              {link.title}
            </Link>
            {pathLength - 1 !== index && (
              <FontAwesomeIcon
                icon={faChevronRight}
                className='text-gray-400 text-sm mx-1'
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GeminiBreadCrumbsWithUrls;
