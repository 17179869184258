import React, { useEffect, useState } from 'react';
import { HiOutlineDocumentText } from 'react-icons/hi';
import {
  MdDelete,
  MdOutlineModeEditOutline,
  MdOutlineRemoveRedEye,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import DepositCheck from '../../../../assets/icons/deposit_check.png';
import { ReactComponent as RealInfo } from '../../../../assets/icons/realInfo.svg';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useNavigateIfNotCurrentRoute } from '../../../../hooks/useNavigateIfNotCurrentRoute';
import {
  FeaturesResponseEligibleEnum,
  TransactionLifecycleStateValueStateEnum,
  TransactionResponse,
} from '../../../../openapi/arrakis';
import { openLeo } from '../../../../slices/LeoSlice';
import {
  recalculateCommissionModal,
  setActiveTransition,
} from '../../../../slices/QuickActionSlice';
import { recalculateCommission } from '../../../../slices/TransactionSlice';
import {
  AppDispatch,
  FeatureFlagTypeEnum,
  KBarActionEnum,
  RootState,
} from '../../../../types';
import { isShowDepositEnabled } from '../../../../utils/CheckDepositsHelper';
import { getTransactionRealTitleName } from '../../../../utils/RealTitleUtils';
import {
  getActionableTransitions,
  getTerminatedTransition,
  isAgentUSInternalReferralTransaction,
  isCanadaTransaction,
  isFTNISettlementIdAvailable,
  isLeaseTransaction,
  isSaleTransaction,
  isTransactionClosed,
  isTransactionReadOnly,
} from '../../../../utils/TransactionHelper';
import BrokerOnly from '../../../auth/BrokerOnly';
import FeatureFlagEnabledOnly from '../../../FeatureFlagEnabledOnly';
import MultipleAgentCallonTransaction from '../../../Roar/MultipleAgentCallonTransaction';
import useShowLeoTrigger from '../../Leo/useShowLeoTrigger';
import TerminateTransactionConfirmationModal from '../../Modal/TerminateTransactionConfirmationModal';
import ZenSimpleConfirmationModal from '../../Modal/ZenSimpleConfirmationModal';
import ZenButton from '../../ZenButton';
import ZenTransactionQuickActionSidebarModal from '../ZenTransactionQuickActionSidebarModal';
import NewZenMarkAsCompliantButton from './NewZenMarkAsCompliantButton';
import ZenMarkAsCompliantButton from './ZenMarkAsCompliantButton';

interface ZenTransactionDetailSecondaryHeaderProps {
  transaction: TransactionResponse;
}

const ZenTransactionDetailSecondaryHeader: React.FC<ZenTransactionDetailSecondaryHeaderProps> = ({
  transaction,
}) => {
  const navigateIfNotCurrentRoute = useNavigateIfNotCurrentRoute();
  const dispatch: AppDispatch = useDispatch();
  const { showLeoTrigger, agentId } = useShowLeoTrigger();
  const [
    isQuickActionSidebarOpen,
    setIsQuickActionSidebarOpen,
  ] = useState<boolean>(false);
  const [
    showTerminationTransactionModal,
    setShowTerminationTransactionModal,
  ] = useState(false);
  const {
    auth: { isAdmin, isBroker, userDetail },
    quickAction: { showRecalculateCommissionModal },
    office: { officeDetailById },
    checkDeposits: { checkDepositsList, checkDepositsListLoading },
    transaction: { features, loadingTransactionDetail },
    kBar: { sidebarToOpen },
  } = useSelector((state: RootState) => state);
  const isAgent = !(isAdmin || isBroker);
  const transitionInfo = getActionableTransitions(transaction, isAdmin);
  const officeDetails = officeDetailById[transaction.office?.id!];
  const isFTNICheckDepositShow =
    isShowDepositEnabled(transaction.lifecycleState?.state!) &&
    isFTNISettlementIdAvailable(officeDetails) &&
    !checkDepositsListLoading;
  const history = useHistory();
  const isNewPowerAuditEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.NEW_POWER_AUDIT_IMPROVEMENTS,
  );

  const preActions = () => {
    navigateIfNotCurrentRoute(`/transactions/${transaction.id}/detail`);
  };

  const terminateTransition = getTerminatedTransition(transaction);

  const terminationRequestedTransition = transitionInfo.secondaryTransitions.find(
    (item) =>
      item.state ===
      TransactionLifecycleStateValueStateEnum.TerminationRequested,
  );
  const isTransactionTerminated =
    transaction?.lifecycleState?.state ===
      TransactionLifecycleStateValueStateEnum.Terminated ||
    transaction?.terminated;

  const isUsingRealTitle = transaction.usingTitle;

  const isEligibleForTitle = !!features?.eligible?.includes(
    FeaturesResponseEligibleEnum.Title,
  );

  const showViewCommissionDocuments =
    !isCanadaTransaction(transaction) &&
    !isAgentUSInternalReferralTransaction(transaction, isAgent) &&
    isSaleTransaction(transaction.transactionType!);

  useEffect(() => {
    if (sidebarToOpen === KBarActionEnum.EDIT_TRANSACTION) {
      setIsQuickActionSidebarOpen(true);
    }
  }, [sidebarToOpen]);

  return (
    <>
      <div className='p-4 flex flex-wrap gap-4 justify-between'>
        <div
          className='flex flex-wrap items-center gap-4'
          id='transaction-edit'
        >
          {(!isTransactionReadOnly(transaction, userDetail!) || isAdmin) && (
            <ZenButton
              label='Edit Transaction'
              LeftIconComponent={
                <MdOutlineModeEditOutline className='h-5 w-5' />
              }
              variant='secondary-gray-outline'
              onClick={() => setIsQuickActionSidebarOpen(true)}
              isDisabled={isTransactionTerminated}
            />
          )}
          {!isCanadaTransaction(transaction) &&
            isLeaseTransaction(transaction.transactionType!) && (
              <ZenButton
                label='View Invoices'
                LeftIconComponent={
                  <HiOutlineDocumentText className='h-5 w-5' />
                }
                variant='secondary-gray-outline'
                onClick={() =>
                  history.push(`/transactions/${transaction.id}/invoices`)
                }
              />
            )}
          {showViewCommissionDocuments && (
            <ZenButton
              label='View Commission Documents'
              LeftIconComponent={<HiOutlineDocumentText className='h-5 w-5' />}
              variant='secondary-gray-outline'
              onClick={() =>
                history.push(`/transactions/${transaction.id}/cda`)
              }
            />
          )}
          {isCanadaTransaction(transaction) && (
            <ZenButton
              label='View Trade Record Sheets'
              LeftIconComponent={<HiOutlineDocumentText className='h-5 w-5' />}
              variant='secondary-gray-outline'
              onClick={() =>
                history.push(
                  `/transactions/${transaction.id}/trade-record-sheets`,
                )
              }
            />
          )}
          {!isNewPowerAuditEnabled ? (
            <ZenMarkAsCompliantButton transaction={transaction} />
          ) : (
            <NewZenMarkAsCompliantButton transaction={transaction} />
          )}

          {transaction.listingTransaction?.id && (
            <Link
              to={`/listings/${transaction.listingTransaction?.id}`}
              target='_blank'
            >
              <ZenButton
                label='View Listing'
                variant='secondary-gray-outline'
                LeftIconComponent={
                  <MdOutlineRemoveRedEye className='h-5 w-5' />
                }
              />
            </Link>
          )}
          {showLeoTrigger &&
            !transaction.listing &&
            isTransactionClosed(transaction) && (
              <ZenButton
                label="Where's My Money?"
                onClick={async () => {
                  await dispatch(
                    openLeo(
                      agentId!,
                      {
                        prompt: "Where's My Money?",
                        title: "Where's My Money?",
                        category: '',
                      },
                      [
                        {
                          type: 'transaction_story',
                          items: [
                            {
                              id: transaction.id!,
                            },
                          ],
                        },
                      ],
                    ),
                  );
                }}
                variant='primary'
              />
            )}
        </div>
        <div className='flex flex-wrap items-center gap-4'>
          {isFTNICheckDepositShow && !checkDepositsList?.length && (
            <ZenButton
              label="Deposit Real's Check"
              LeftIconComponent={
                <img
                  src={DepositCheck}
                  alt='deposit_check'
                  className='h-5 w-5'
                />
              }
              variant='primary'
              onClick={() =>
                history.push(`/transactions/${transaction.id}/deposit-check`)
              }
            />
          )}
          {!!checkDepositsList?.length && (
            <ZenButton
              label='View Deposit History'
              LeftIconComponent={
                <img
                  src={DepositCheck}
                  alt='deposit_check'
                  className='h-5 w-5'
                />
              }
              variant='primary'
              onClick={() =>
                history.push(
                  `/transactions/${transaction.id}/view-check-deposit`,
                )
              }
            />
          )}

          {terminateTransition && (
            <BrokerOnly>
              <ZenButton
                label='Terminate'
                LeftIconComponent={
                  <MdDelete title='delete' className='text-white' size={18} />
                }
                onClick={() => {
                  preActions();
                  dispatch(setActiveTransition(terminateTransition));
                }}
                variant='danger'
              />
            </BrokerOnly>
          )}

          {terminationRequestedTransition && (
            <BrokerOnly>
              <ZenButton
                label='Request Termination'
                LeftIconComponent={
                  <MdDelete title='delete' className='text-white' size={18} />
                }
                onClick={() => {
                  setShowTerminationTransactionModal(true);
                }}
                variant='danger'
              />
            </BrokerOnly>
          )}

          {isEligibleForTitle && isUsingRealTitle && (
            <div className='flex space-x-1.5 items-center'>
              <RealInfo />
              <div className='text-sm text-primary-blue font-zen-body font-medium'>
                {`Closing With ${getTransactionRealTitleName(
                  transaction.address?.state,
                )}`}
              </div>
            </div>
          )}
          <FeatureFlagEnabledOnly flag={FeatureFlagTypeEnum.ROAR}>
            <MultipleAgentCallonTransaction transaction={transaction} />
          </FeatureFlagEnabledOnly>
        </div>
      </div>
      <ZenTransactionQuickActionSidebarModal
        isOpen={isQuickActionSidebarOpen}
        onClose={() => setIsQuickActionSidebarOpen(false)}
        transaction={transaction!}
        secondaryTransitions={transitionInfo.secondaryTransitions}
        setShowTerminationTransactionModal={setShowTerminationTransactionModal}
      />
      <TerminateTransactionConfirmationModal
        transaction={transaction!}
        onClose={() => setIsQuickActionSidebarOpen(false)}
        secondaryTransitions={transitionInfo.secondaryTransitions}
        setShowTerminationTransactionModal={setShowTerminationTransactionModal}
        showTerminationTransactionModal={showTerminationTransactionModal}
      />

      <ZenSimpleConfirmationModal
        variant='warning'
        size='large'
        title="You're about to recalculate the commission."
        subtitle='You can force a recalculation if you believe there is an error or inconsistency in the commission splits. This will transition the transaction back to the Needs Commission Validation status.'
        isOpen={showRecalculateCommissionModal}
        onClose={() => dispatch(recalculateCommissionModal(false))}
        isSubmitting={loadingTransactionDetail}
        onConfirm={async () => {
          await dispatch(recalculateCommission(transaction.id!));
          dispatch(recalculateCommissionModal(false));
        }}
      />
    </>
  );
};

export default ZenTransactionDetailSecondaryHeader;
