import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SectionActions } from '../Hooks/useNeoLeoKBarActions';

interface NeoLeoKBarProps {
  sectionActions: SectionActions[];
}

const NeoLeoKBar: React.FC<NeoLeoKBarProps> = ({ sectionActions }) => {
  if (sectionActions.length === 0) {
    return null;
  }

  return (
    <div>
      {sectionActions.map(({ name, actions }) => (
        <div key={name} className='pb-4'>
          <p className='font-inter-medium text-regent-600 mb-2'>
            {name?.toUpperCase()}
          </p>
          {actions.map((action) => (
            <div
              key={action.id}
              className='flex items-center justify-between cursor-pointer mb-2'
              onMouseDown={() => action.perform()}
            >
              <div className='flex items-center gap-x-3'>
                <div className='flex justify-center items-center w-8 h-6 bg-white bg-opacity-10 rounded-sm'>
                  <p className='font-inter-regular text-sm text-white pt-0.5'>
                    {action.name?.charAt(0)}
                  </p>
                </div>
                <div>
                  <p className='font-inter-light text-sm text-white pt-0.5'>
                    {action.name}
                  </p>
                </div>
              </div>
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className='text-aqua'
                fontSize={14}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NeoLeoKBar;
