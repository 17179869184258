import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import TextareaAutosize from 'react-textarea-autosize';
import {
  MULTILINE_INPUT_MAX_ROWS,
  MULTILINE_INPUT_MIN_ROWS,
} from '../../../constants/LeoConstants';
import { FormFieldTooltipIndexProps } from '../../../types';
import { cn } from '../../../utils/classUtils';

export interface LeoControlledTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  isRequired?: boolean;
  helperText?: string;
  maxLength?: number;
  disabled?: boolean;
  onSubmit?(): void;
  rightIcon?: React.ReactElement;
}

const LeoControlledTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  noBorder,
  placeholder,
  hideErrorMessage = false,
  tooltipIndex,
  shouldUnregister = true,
  helperText,
  isRequired = false,
  maxLength,
  disabled = false,
  onSubmit,
  rightIcon,
  ...rest
}: LeoControlledTextInputProps<TFieldValues, TName>) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({ field: { onChange, value, name, onBlur, ref } }) => (
        <div
          className={cn(
            'relative flex items-stretch rounded-3xl overflow-hidden',
            value ? 'text-white' : 'text-regent-600',
            {
              'bg-gradient-to-r from-aqua via-zen-purple to-legacy-blue': isActive,
              'bg-white bg-opacity-10': !isActive,
            },
          )}
        >
          <div
            className={cn(
              'flex items-center w-full bg-regent-900 rounded-3xl m-px',
              {
                'bg-primary-navy': isActive,
              },
            )}
          >
            <TextareaAutosize
              id={name}
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={(e) => {
                onChange(e.target.value?.trim());
                setIsActive(false);
                onBlur();
              }}
              onFocus={() => setIsActive(true)}
              inputMode='text'
              name={name}
              placeholder={placeholder}
              className='appearance-none bg-transparent w-full resize-none rounded-3xl border-0 focus:ring-0 font-zen-body font-normal text-base'
              wrap='soft'
              minRows={MULTILINE_INPUT_MIN_ROWS}
              maxRows={MULTILINE_INPUT_MAX_ROWS}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && onSubmit) {
                  onSubmit();
                }
              }}
              maxLength={maxLength}
              data-tooltip-index={tooltipIndex}
              disabled={disabled}
            />
            <div className='px-3'>{rightIcon}</div>
          </div>
        </div>
      )}
    />
  );
};
export default LeoControlledTextInput;
