import { faBoxArchive } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowDownToBracket,
  faEllipsis,
  faEye,
  faPen,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { FileApi, FileResponse } from '../../../openapi/dropbox';
import {
  ChecklistApi,
  ItemApi,
  ItemResponse,
  ItemResponseStatusEnum,
} from '../../../openapi/sherlock';
import ErrorService from '../../../services/ErrorService';
import {
  downloadChecklistDocument,
  fetchChecklistItemById,
  removeFileReferencesFromChecklistItem,
} from '../../../slices/CheckListSlice';
import { downloadDropboxFileByVersion } from '../../../slices/DropboxSlice';
import { showApiErrorModal } from '../../../slices/ErrorSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import {
  getChecklistsWithFileReferences,
  isV2Checklist,
} from '../../../utils/ChecklistUtils';
import {
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../../../utils/OpenapiConfigurationUtils';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenDropdown from '../ZenDropdown';

type ColorType = 'primary' | 'danger' | 'warning' | 'success';

interface Item {
  icon?: React.ReactElement;
  text: string;
  colorType?: ColorType;
  onClick(): void;
  divider?: boolean;
  disabled?: boolean;
}

export interface ZenChecklistItemDocumentDropdownProps {
  checklistItem: ItemResponse;
  checklistId: string;
  checklistDocument: FileResponse;
  setIsShowingDocumentVersion(): void;
}

const ZenChecklistItemDocumentDropdown: React.FC<ZenChecklistItemDocumentDropdownProps> = ({
  checklistItem,
  checklistId,
  checklistDocument,
  setIsShowingDocumentVersion,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    auth: { isAdmin, isBroker },
    checklist: { checklistsById },
  } = useSelector((state: RootState) => state);
  const isChecklistV2 = isV2Checklist(checklistsById[checklistId]?.data);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<FileResponse | null>(
    null,
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDownloadFile = async () => {
    if (isChecklistV2) {
      await dispatch(
        downloadDropboxFileByVersion(
          checklistDocument?.id!,
          checklistDocument?.currentVersion?.id!,
        ),
      );
    } else {
      await dispatch(
        downloadChecklistDocument(checklistDocument.currentVersion?.id!),
      );
    }
  };

  const isArchiveFeatureFlageEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.ARCHIVE_CHECKLIST_DROPBOX_FILES,
  );

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const fileReferences = [
        {
          fileId: checklistDocument.id!,
          filename: checklistDocument.filename!,
        },
      ];
      const checklistItems = checklistsById![checklistId]?.data?.items || [];
      const attachedChecklists = getChecklistsWithFileReferences(
        checklistItems,
        checklistDocument.id!,
      );

      if (attachedChecklists.length < 2) {
        await new FileApi(getDropboxConfiguration()).moveFileToTrash(
          checklistDocument.id!,
        );
      }

      await new ItemApi(getSherlockConfiguration()).removeFileReferences(
        checklistItem?.id!,
        {
          references: fileReferences,
        },
      );

      await dispatch(fetchChecklistItemById(checklistId, checklistItem?.id!));

      dispatch(
        showSuccessToast(
          `${pluralize(
            'Document',
            fileToBeDeleted?.length,
          )} moved to archive successfully.`,
        ),
      );
    } catch (error) {
      ErrorService.notify('Unable to archive the file', error, {
        files: fileToBeDeleted!,
      });
      dispatch(
        showErrorToast(
          'We had a problem archiving the documents',
          'Please try again in a few moments.',
        ),
      );
    } finally {
      setIsDeleting(false);
      setFileToBeDeleted([]);
    }
  };

  const handleDeleteDocument = async () => {
    if (isChecklistV2) {
      const fileReferences = [
        {
          fileId: checklistDocument.id!,
          filename: checklistDocument.filename!,
        },
      ];
      const data = await dispatch(
        removeFileReferencesFromChecklistItem(
          checklistId,
          checklistItem.id!,
          fileReferences,
        ),
      );
      if (data) {
        await dispatch(fetchChecklistItemById(checklistId, checklistItem?.id!));
      }
    } else {
      try {
        await new ChecklistApi(getSherlockConfiguration()).deleteDocument(
          checklistDocument.id!,
        );
        await dispatch(fetchChecklistItemById(checklistId, checklistItem?.id!));
        dispatch(showSuccessToast('Document deleted successfully'));
      } catch (e) {
        dispatch(showApiErrorModal(e));
        dispatch(showErrorToast('Unable to delete the file'));
        ErrorService.notify('Unable to delete the checklist document', e, {
          checklistDocument: { ...checklistDocument },
        });
      }
    }
  };

  const items: Item[] = [
    {
      icon: <FontAwesomeIcon icon={faPen} size='sm' />,
      text: 'Edit/Replace document',
      onClick: () => setIsShowingDocumentVersion(),
      disabled: checklistItem?.status === ItemResponseStatusEnum.Accepted,
    },
    {
      icon: <FontAwesomeIcon icon={faArrowDownToBracket} size='sm' />,
      text: 'Download file',
      onClick: () => handleDownloadFile(),
    },
    {
      icon: <FontAwesomeIcon icon={faEye} size='sm' />,
      text: 'View version history',
      onClick: () => setIsShowingDocumentVersion(),
    },
  ];

  if ((isAdmin || isBroker) && !isArchiveFeatureFlageEnabled) {
    items.push({
      icon: <FontAwesomeIcon icon={faTrashCan} size='sm' />,
      text: 'Delete file',
      onClick: () => handleDeleteDocument(),
      divider: true,
      colorType: 'danger',
    });
  }

  return (
    <div className='flex flex-row items-center justify-between'>
      {!!isArchiveFeatureFlageEnabled && (
        <FontAwesomeIcon
          icon={faBoxArchive}
          color='#F84C6C'
          className='mr-3'
          title='Archive Document'
          onClick={() => setFileToBeDeleted(checklistDocument)}
        />
      )}
      <ZenDropdown
        containerClassName='z-50'
        items={items}
        icon={
          <FontAwesomeIcon
            icon={faEllipsis}
            aria-label='file-option'
            className='flex justify-center items-center rounded-full ml-1.5'
            title='file-option'
          />
        }
        noBorder
      />
      <ZenConfirmationModal
        isOpen={!!fileToBeDeleted}
        variant='danger'
        title={`Are you sure you want to archive: ${fileToBeDeleted?.filename!}?`}
        confirmButtonText='Archive'
        hideIcon
        subtitle='Archiving this file will remove all previous versions as well.'
        onClose={() => setFileToBeDeleted(null)}
        onConfirm={() => {
          handleDelete();
        }}
        isSubmitting={isDeleting}
        isDisabled={isDeleting}
      />
    </div>
  );
};

export default ZenChecklistItemDocumentDropdown;
