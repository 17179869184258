import { DatePickerType } from '@mantine/dates';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { DatePicker as BaseDatePicker, DatePickerProps } from '../DatePicker';
import { ErrorComponent } from '../ErrorComponent';
import { HookFormDatePickerControllerProps } from './types';
import { getDatePickerInputErrors } from './utils';

type HookFormDatePickerInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Type extends DatePickerType = 'default'
> = HookFormDatePickerControllerProps<TFieldValues, TName> &
  DatePickerProps<Type> & {
    fromDateKey?: string;
    toDateKey?: string;
  };

export const HookFormDatePickerInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  Type extends DatePickerType = 'default'
>({
  fromDateKey = 'from',
  toDateKey = 'to',
  ...props
}: HookFormDatePickerInputProps<TFieldValues, TName, Type>) => {
  const {
    formState: { defaultValues },
    field,
    fieldState,
  } = useController({
    name: props.name,
    control: props.control,
  });

  const errors = getDatePickerInputErrors(fieldState.error, props.type);

  return (
    <BaseDatePicker<Type>
      {...props}
      defaultValue={defaultValues?.[props.name]}
      onChange={(val) => {
        props.onChange?.(val);
        field.onChange(val);
      }}
      onBlur={(e) => {
        field.onBlur();
        props?.onBlur?.(e);
      }}
      error={errors ? <ErrorComponent errors={errors} /> : undefined}
    />
  );
};
