import { groupBy, map } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { displayDate, isToday } from '../../../utils/DateUtils';
import { scrollToBottom } from '../../../utils/DOMUtils';
import DefaultLoader from '../../DefaultLoader';
import { SENDER_RECEIVER_ENUM } from '../../Zen/Leo/Chat/LeoChatIndex';
import NeoLeoChatBubble from './NeoLeoResponse';
import { NeoLeoResponseLoadingSkeleton } from './NeoLeoResponseSkeleton';
import NeoLeoUserChatBubble from './NeoLeoUserChatBubble';

interface NeoLeoChatProps {
  isShowingHistory: boolean;
}

const NeoLeoChat: React.FC<NeoLeoChatProps> = ({ isShowingHistory }) => {
  const {
    currentChat,
    isChatLoading,
    msgContext,
    isLeoThinking,
    isLeoGenerating,
  } = useSelector((state: RootState) => state.leo);
  const currentMessage = currentChat[currentChat.length - 1]
    ? currentChat[currentChat.length - 1].message
    : '';

  useEffect(() => {
    if (!isChatLoading) {
      scrollToBottom(document?.getElementById('leo-chat-box')!);
    }
  }, [currentMessage, msgContext, isChatLoading]);

  const chatGroupedByDate = groupBy(currentChat, (el) =>
    displayDate(el.created_at!!),
  );

  const getGroupedByDateHeader = (createdAt: string) => {
    const dateString = displayDate(createdAt);
    if (isToday(createdAt)) {
      return `Today ${dateString}`;
    }
    return dateString;
  };

  return (
    <div
      className='h-full flex-grow px-2 pb-4 overflow-y-auto scrollbar-hide'
      id='leo-chat-box'
    >
      {isChatLoading ? (
        <DefaultLoader />
      ) : (
        map(chatGroupedByDate, (chatByDate) => (
          <div key={chatByDate[0].created_at!}>
            <div className='line-with-words-center px-8 my-3 after:border-b before:border-b after:border-b-zen-dark-6 before:border-b-zen-dark-6'>
              <p className='text-leo-response-action text-xs px-2.5'>
                {getGroupedByDateHeader(chatByDate[0].created_at!)}
              </p>
            </div>
            {(chatByDate || []).map((msgRes, ind) =>
              msgRes.sender === SENDER_RECEIVER_ENUM.SYSTEM ? (
                <NeoLeoChatBubble
                  {...msgRes}
                  isShowingHistory={isShowingHistory}
                  isDisclaimer={ind === 0}
                />
              ) : (
                <NeoLeoUserChatBubble text={msgRes.message!} />
              ),
            )}
            {isLeoThinking && !isLeoGenerating && (
              <NeoLeoResponseLoadingSkeleton className='ml-2' />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default NeoLeoChat;
