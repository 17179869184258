import {
  faEnvelope,
  faGlobe,
  faLocationDot,
  faPhone,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  CHECK_THE_MAP_LINK,
  CHECK_THE_MAP_LINK_ESCROW,
} from '../../constants/RealTitleConstants';
import { cn } from '../../utils/classUtils';
import {
  RealTitleOperationStatesEnum,
  getRealTitleContactInfo,
  getStateImageSrc,
} from '../../utils/RealTitleUtils';
import { STATE_OR_PROVINCE_ABBREVIATIONS } from '../../utils/StateUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import { urlWithoutProtocol } from '../../utils/UrlUtils';

const styles = 'flex items-start gap-3 font-light';
const states = Object.values(RealTitleOperationStatesEnum).sort((a, b) =>
  a.localeCompare(b),
);

const RealClosingServicesServicesSection: React.FC = () => {
  const [selectedState, setSelectedState] = useState(
    RealTitleOperationStatesEnum.Arizona,
  );
  const contactInfo = getRealTitleContactInfo(selectedState);

  return (
    <div data-testid='real-closing-services-services-section'>
      <h1 className='font-poppins text-[40px] font-semibold leading-[48px] text-center'>
        <span>Get</span>
        <span className='text-rezen-blue-600'> In Touch</span>
      </h1>
      <div className='overflow-x-auto py-4 mb-3 sm:mb-6'>
        <div className='flex gap-2 w-fit mx-auto'>
          {states.map((state) => (
            <button
              key={state}
              title={capitalizeEnum(state)}
              className={cn(
                'w-fit px-4 py-1 rounded-[10px] border text-gray-500 border-gray-400 cursor-pointer transition-colors font-inter',
                {
                  'bg-primary-navy text-white border-primary-navy':
                    state === selectedState,
                },
              )}
              onClick={() => setSelectedState(state)}
            >
              {STATE_OR_PROVINCE_ABBREVIATIONS[state]}
            </button>
          ))}
        </div>
      </div>
      <div className='flex flex-col md:flex-row gap-6 justify-center items-center md:gap-16 md:px-8 lg:px-12'>
        <div
          className='rounded-2xl p-6 md:pr-14 w-full md:max-w-[362px] h-fit border border-gray-200'
          data-testid='contact-info'
        >
          <h1 className='text-2xl mb-5 font-semibold font-poppins text-primary-navy'>
            {capitalizeEnum(selectedState)}
          </h1>
          <div className='flex flex-col gap-4'>
            <div className={styles}>
              <FontAwesomeIcon
                icon={faLocationDot}
                size='lg'
                className='mt-px'
              />
              <p className='font-inter leading-5'>
                {contactInfo?.oneLineAddress}
              </p>
            </div>
            <div className={styles}>
              <FontAwesomeIcon icon={faPhone} size='lg' />
              <p className='font-inter leading-5'>{contactInfo?.phoneNumber}</p>
            </div>
            <div className={styles}>
              <FontAwesomeIcon icon={faEnvelope} size='lg' />
              <p className='font-inter leading-5'>
                {contactInfo?.emailAddress}
              </p>
            </div>
            <div className={`${styles} text-rezen-blue-600`}>
              <FontAwesomeIcon icon={faGlobe} size='lg' className='mt-px' />
              <a
                href={
                  contactInfo?.isEscrow
                    ? CHECK_THE_MAP_LINK_ESCROW
                    : CHECK_THE_MAP_LINK
                }
                target='_blank'
                rel='noopener noreferrer'
                className='font-inter'
              >
                {urlWithoutProtocol(
                  contactInfo?.isEscrow
                    ? CHECK_THE_MAP_LINK_ESCROW
                    : CHECK_THE_MAP_LINK,
                )}
              </a>
            </div>
          </div>
        </div>
        <img
          src={getStateImageSrc(selectedState)}
          alt='state'
          className='w-full max-w-[343px] sm:w-auto sm:max-w-none sm:h-[316px] md:h-[384px] px-6'
        />
      </div>
    </div>
  );
};

export default RealClosingServicesServicesSection;
