import {
  faArrowLeftLong,
  faCheck,
  faChevronDown,
  faMagnifyingGlass,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../constants/TableConstants';
import {
  BankActivityControllerApi,
  BankActivityDto,
  TransactionResponse,
} from '../../../openapi/arrakis';
import ErrorService from '../../../services/ErrorService';
import {
  updatePendingCount,
  updateReconciledCount,
} from '../../../slices/BankSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../slices/ToastNotificationSlice';
import {
  AsyncSelectOption,
  IPaginateReq,
  ISelectOption,
  RootState,
} from '../../../types';
import { getArrakisConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { getSearchableTransactions } from '../../../utils/TransactionUtils';
import ZenControlledAsyncSelectInput from '../Input/ZenControlledAsyncSelectInput';
import ZenConfirmationModal from '../Modal/ZenConfirmationModal';
import ZenButton from '../ZenButton';
import ZenSidebarModal from '../ZenSidebarModal';
import ZenControlledTransactionMatchCheckboxInput from './ZenControlledTransactionMatchCheckboxInput';
import ZenReconcileTransactionSidebarModalHeader from './ZenReconcileTransactionSidebarModalHeader';

interface FormData {
  transaction: ISelectOption;
  transactionAddress: string;
}

interface ZenReconcilePendingTransactionSidebarModalProps {
  isOpen: boolean;
  onClose(): void;
  selectedTransaction: null | BankActivityDto;
}

const ZenReconcilePendingTransactionSidebarModal: React.FC<ZenReconcilePendingTransactionSidebarModalProps> = ({
  isOpen,
  onClose,
  selectedTransaction,
}) => {
  const { control, handleSubmit, watch } = useForm<FormData>();
  const [manual, setManual] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { pendingCount, reconciledCount } = useSelector(
    (state: RootState) => state.bank,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] = useState<BankActivityDto>({});
  const [isModalOpen, setModalOpen] = useState(false);
  const handleCheckBoxChange = (activity: BankActivityDto) => {
    if (selectedActivity.id !== activity.id) {
      setSelectedActivity(activity);
    } else {
      setSelectedActivity({});
    }
  };
  const searchSelectedTransaction = watch('transaction');
  const onSubmit = async (value: any) => {
    if (manual) {
      try {
        setLoading(true);
        await new BankActivityControllerApi(
          getArrakisConfiguration(),
        ).manuallyReconcileActivity(selectedTransaction?.id!, {
          transactionId: value.transaction.value,
        });
        setLoading(false);
        onClose();
        dispatch(
          showSuccessToast(
            'New match for the given activity is created successfully.',
          ),
        );
        dispatch(updatePendingCount(pendingCount - 1));
        dispatch(updateReconciledCount(reconciledCount + 1));
      } catch (e) {
        ErrorService.notify(
          'Error creating new match for a given activity.',
          e,
        );
        dispatch(
          showErrorToast(
            'We had a problem creating new match for a given activity.',
            'Please try again in a few moments.',
          ),
        );
        setLoading(false);
        setModalOpen(false);
      }
    } else {
      try {
        setLoading(true);
        await new BankActivityControllerApi(
          getArrakisConfiguration(),
        ).confirmMatch(selectedActivity?.id!);
        setLoading(false);
        onClose();
        dispatch(
          showSuccessToast(
            'Successfully updated possible match to actual match.',
          ),
        );
        dispatch(updatePendingCount(pendingCount - 1));
        dispatch(updateReconciledCount(reconciledCount + 1));
      } catch (e) {
        ErrorService.notify('Error changing possible match to actual match', e);
        dispatch(
          showErrorToast(
            'We had a problem changing possible match to actual match',
            'Please try again in a few moments.',
          ),
        );
        setLoading(false);
        setModalOpen(false);
      }
    }
  };

  return (
    <ZenSidebarModal
      title='Reconciled Transaction'
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <ZenReconcileTransactionSidebarModalHeader
        selectedTransaction={selectedTransaction}
      />

      <div className='p-5 mb-20'>
        {manual ? (
          <div>
            <div className='font-zen-body font-semibold text-lg text-zen-dark mb-5'>
              Reconcile Manually
            </div>

            <ZenControlledAsyncSelectInput<FormData, 'transaction'>
              control={control}
              name='transaction'
              placeholder='Search by address or code'
              label='Search for Transaction Address'
              startAdornment={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='text-blue-500 ml-3 mr-2'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='text-gray-500 mx-4.5'
                />
              }
              selectClassName='border-r'
              fetchData={async (search, page) => {
                const request: IPaginateReq<TransactionResponse> = {
                  page: page ?? 0,
                  pageSize: DEFAULT_PAGE_SIZE,
                  search: search,
                  filter: {},
                };
                try {
                  const searchResponse = await getSearchableTransactions(
                    request,
                  );

                  const options: AsyncSelectOption[] = (
                    searchResponse?.data || []
                  ).map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.address?.oneLine} - ${resp.code}`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify('Unable to search transactions', e, {
                    filter: {
                      term: search,
                      pagination: {
                        page: page ?? 0,
                        pageSize: DEFAULT_PAGE_SIZE,
                      },
                    },
                  });
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for an transaction. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              rules={{
                required: 'Select transaction to mark it as reconciled.',
              }}
            />
          </div>
        ) : (
          <div>
            <p className='font-zen-body font-semibold text-base text-zen-dark-6'>
              Possible Matches ({selectedTransaction?.possibleMatches?.length})
            </p>
            {selectedTransaction?.possibleMatches &&
              selectedTransaction?.possibleMatches?.length > 0 && (
                <div className='mt-5'>
                  <ZenControlledTransactionMatchCheckboxInput<
                    FormData,
                    'transactionAddress'
                  >
                    name='transactionAddress'
                    control={control}
                    options={selectedTransaction?.possibleMatches}
                    handleCheckBoxChange={handleCheckBoxChange}
                    selectedActivity={selectedActivity}
                  />
                </div>
              )}
          </div>
        )}
      </div>

      <div className='px-6 py-4 bg-white border-t border-zen-light-gray-2 bottom-0 space-x-5 flex flex-row justify-between items-center left-0 right-0 absolute w-full'>
        {manual ? (
          <button
            type='button'
            onClick={() => setManual(false)}
            className='flex items-center space-x-1.5 text-base text-zen-dark font-medium'
          >
            <FontAwesomeIcon icon={faArrowLeftLong} />
            <span>Go Back</span>
          </button>
        ) : (
          <button
            type='button'
            onClick={() => setManual(true)}
            className='flex items-center space-x-1.5 text-base text-zen-dark font-medium'
          >
            <FontAwesomeIcon icon={faPencil} />
            <span>Reconcile Manually</span>
          </button>
        )}
        <ZenButton
          label='Mark as Reconciled'
          LeftIconComponent={<FontAwesomeIcon icon={faCheck} />}
          variant='primary'
          isDisabled={
            loading ||
            (!manual && Object.keys(selectedActivity).length === 0) ||
            (manual && !searchSelectedTransaction)
          }
          isSubmitting={loading}
          onClick={() => setModalOpen(true)}
        />
      </div>
      <ZenConfirmationModal
        title='Mark as Reconciled'
        subtitle='Are you sure you want to mark this transaction as reconciled?'
        isOpen={isModalOpen}
        isSubmitting={loading}
        onClose={() => setModalOpen(false)}
        onConfirm={handleSubmit(onSubmit)}
        variant='primary'
        confirmButtonText='Yes'
      />
    </ZenSidebarModal>
  );
};

export default ZenReconcilePendingTransactionSidebarModal;
