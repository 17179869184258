import { faStop, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import WaveSurfer from 'wavesurfer-v7';
import RecordPlugin from 'wavesurfer-v7/dist/plugins/record';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AppDispatch } from '../../types';
import { formatTime } from '../../utils/DateUtils';

type RecordMemoProps = {
  onRecordingFinish(recordingData: { path: string; duration: number }): void;
  onCancel(): void;
};

const RecordMemo: React.FC<RecordMemoProps> = ({
  onRecordingFinish,
  onCancel,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [record, setRecord] = useState<RecordPlugin>();
  const [time, setTime] = useState(0);
  const durationRef = useRef(0);
  const cancelledRef = useRef(false);

  useEffect(() => {
    const waveForm = WaveSurfer.create({
      barWidth: 2,
      barRadius: 2,
      barGap: 3,
      cursorWidth: 1,
      container: '#record',
      backend: 'WebAudio',
      fillParent: true,
      progressColor: '#FF0332',
      waveColor: '#F84C6C66',
      cursorColor: 'transparent',
      hideScrollbar: true,
      height: 20,
    });

    // Initialize the Record plugin
    setRecord(
      waveForm.registerPlugin<RecordPlugin>(
        RecordPlugin.create({
          scrollingWaveform: true,
          renderRecordedAudio: false,
        }),
      ),
    );

    return () => {
      waveForm?.destroy();
    };
  }, []);

  useEffect(() => {
    record?.on('record-end', (blob: Blob) => {
      if (!cancelledRef.current) {
        const path = URL.createObjectURL(blob);
        onRecordingFinish({
          path: path,
          duration: durationRef.current,
        });
      }
    });

    record?.on('record-progress', (ms) => {
      const seconds = ms * 0.001;
      setTime(seconds);
      durationRef.current = seconds;
    });

    record?.startRecording().catch(() => {
      dispatch(
        showErrorToast(
          'Permission denied to record audio. Please allow microphone access and try again.',
        ),
      );
      onCancel();
    });

    return () => {
      record?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const stopRecord = () => {
    if (record?.isRecording()) {
      record?.stopRecording();
    }
  };

  const handleCancel = () => {
    cancelledRef.current = true;
    record?.destroy();
    onCancel();
  };

  return (
    <div className='h-10 flex items-center space-x-3'>
      <button
        className='bg-regent-300 py-2 px-3 rounded-full w-8 h-8 flex items-center justify-center'
        onClick={handleCancel}
      >
        <FontAwesomeIcon
          icon={faXmark}
          className='text-primary-dark'
          fontSize={14}
        />
      </button>
      <div className='flex items-center flex-1 space-x-3 bg-[#FEF2F580] rounded-full p-2'>
        <div id='record' className='flex-1 mt-1' />
        <p className='ml-2 text-sm font-inter text-red-600'>
          {formatTime(time)}
        </p>
        <button
          onClick={stopRecord}
          type='button'
          className='bg-red-100 rounded-full p-1 w-8 h-8'
          aria-label='stop'
        >
          <FontAwesomeIcon
            icon={faStop}
            fontSize={14}
            className='text-red-600'
          />
        </button>
      </div>
    </div>
  );
};

export default RecordMemo;
