import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsService from '../../services/AnalyticsService';
import { saveShowIntro, chatReply } from '../../slices/LeoSlice';
import { RootState, AnalyticsEventEnum } from '../../types';
import { ContextualPrompt, FollowUp } from '../../utils/LeoUtils';
import NeoLeoPrompt from '../Zen/Leo/Chat/NeoLeoPrompt';
import useShowLeoTrigger from '../Zen/Leo/useShowLeoTrigger';

interface NeoLeoIntroPromptsProps {}

const NeoLeoIntroPrompts: React.FC<NeoLeoIntroPromptsProps> = () => {
  const dispatch = useDispatch();
  const {
    leo: { session, isLeoThinking, currentChat },
  } = useSelector((state: RootState) => state);
  const { simbaFollowUpItem, prompts: defaultPrompts } = useShowLeoTrigger();

  const getFollowUps = (category: string | null): FollowUp[] | undefined => {
    if (!!category && category !== 'none') {
      // for default prompts i.e. simba
      return [
        {
          type: category,
          items: simbaFollowUpItem ? [simbaFollowUpItem] : [],
        },
      ];
    }
    return undefined;
  };

  const onPromptSelect = async (
    selectedPrompt: ContextualPrompt,
    handlerType: string | null = null,
  ) => {
    dispatch(saveShowIntro(false));
    const updateSessionTitle =
      currentChat.length === 0 ||
      (currentChat.length === 1 &&
        currentChat[0].message === session?.disclaimer);

    await dispatch(
      chatReply(
        session?.id!,
        selectedPrompt,
        updateSessionTitle,
        getFollowUps(handlerType),
      ),
    );
    AnalyticsService.instance().logEvent(AnalyticsEventEnum.LEO_PROMPT_CLICK, {
      prompt,
    });
  };

  const prompts = defaultPrompts;
  const PromptWrapThreshold = prompts.length / 3;

  return (
    <div className='text-white overflow-x-scroll scrollbar-hide'>
      <div className='flex flex-row w-max'>
        {prompts.slice(0, PromptWrapThreshold).map((prompt) => (
          <NeoLeoPrompt
            key={prompt.prompt}
            prompt={prompt.title}
            disabled={!session?.id || isLeoThinking}
            onSelect={() => onPromptSelect(prompt, prompt.category)}
          />
        ))}
      </div>
      {prompts.length > PromptWrapThreshold && (
        <div>
          <div className='basis-full' />
          <div className='flex flex-row w-max'>
            {prompts
              .slice(PromptWrapThreshold, PromptWrapThreshold * 2)
              .map((prompt) => (
                <NeoLeoPrompt
                  key={prompt.title}
                  prompt={prompt.prompt}
                  disabled={!session?.id || isLeoThinking}
                  onSelect={() => onPromptSelect(prompt, prompt.category)}
                />
              ))}
          </div>
        </div>
      )}
      {prompts.length > PromptWrapThreshold * 2 && (
        <div>
          <div className='basis-full' />
          <div className='flex flex-row w-max'>
            {prompts
              .slice(PromptWrapThreshold * 2, PromptWrapThreshold * 3)
              .map((prompt) => (
                <NeoLeoPrompt
                  key={prompt.title}
                  prompt={prompt.prompt}
                  disabled={!session?.id || isLeoThinking}
                  onSelect={() => onPromptSelect(prompt, prompt.category)}
                />
              ))}
          </div>
        </div>
      )}
      {prompts.length > PromptWrapThreshold * 3 && (
        <div>
          <div className='basis-full' />
          <div className='flex flex-row w-max'>
            {prompts
              .slice(PromptWrapThreshold * 3, PromptWrapThreshold * 4)
              .map((prompt) => (
                <NeoLeoPrompt
                  key={prompt.title}
                  prompt={prompt.prompt}
                  disabled={!session?.id || isLeoThinking}
                  onSelect={() => onPromptSelect(prompt, prompt.category)}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NeoLeoIntroPrompts;
