import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ZenNavRealLogo from '../assets/img/new-rezen-white-logo.svg';
import { useGemini } from '../hooks/useGemini';
import { RootState } from '../types';
import { cn } from '../utils/classUtils';
import ZenLoanOfficerMenu from './Menu/BrokerQueue/ZenLoanOfficerMenu';
import ZenAdminMenu from './Menu/ZenMenu/ZenAdminMenu';
import ZenBrokerMenu from './Menu/ZenMenu/ZenBrokerMenu';
import ZenRealTitleOfficerMenu from './Menu/ZenMenu/ZenRealTitleOfficerMenu';
import ZenSuperAdminMenu from './Menu/ZenMenu/ZenSuperAdminMenu';
import ZenUserMenu from './Menu/ZenMenu/ZenUserMenu';
import NeoLeoIndex from './NeoLeo/NeoLeoIndex';
import NeoLeoTrigger from './NeoLeo/NeoLeoTrigger';
import ZenAvatar from './Zen/ZenAvatar';
import ZenLaunchNotes from './ZenLaunchNotes';
import { ZenMainMenuDemoModeBadge } from './ZenMainMenuDemoModeBadge';
import ZenSideBarItem from './ZenSideBarItem';

interface SideBarProps {}

const NeoMainMenu: React.FC<SideBarProps> = () => {
  const { auth } = useSelector((state: RootState) => state);
  const {
    userDetail,
    isAdmin,
    isBroker,
    isSuperAdmin,
    isRealTitleOfficer,
    isAnnouncer,
    isMortgageUserRole,
  } = auth;

  const isGeminiEnabled = useGemini();

  const [openNeoLeoPanel, setOpenNeoLeoPanel] = React.useState<boolean>(false);

  const handleNeoLeoToggle = () => {
    setOpenNeoLeoPanel((prev) => !prev);
  };

  return (
    <div aria-label='mainMenu'>
      <div className={cn('left-0 top-0 bottom-0 static right-0 z-50')}>
        <div
          className={cn(
            'h-full-window flex flex-row justify-between overflow-y-auto',
          )}
        >
          <div
            className={cn(
              'flex flex-col justify-between scrollbar overflow-y-auto',
              isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
              'w-20',
            )}
          >
            <div className='px-2 py-4 flex flex-col justify-between flex-grow'>
              <div>
                <Link to='/'>
                  <img
                    src={ZenNavRealLogo}
                    alt='real-logo'
                    className='h-4 ml-2 mb-4'
                  />
                </Link>
                {!isSuperAdmin && !isMortgageUserRole && !isRealTitleOfficer && (
                  <div className={cn('p-4')}>
                    <ZenLaunchNotes isCollapsed />
                  </div>
                )}
                <ZenMainMenuDemoModeBadge isCollapsed />
                {isSuperAdmin ? (
                  <ZenSuperAdminMenu isCollapsed />
                ) : isAdmin ? (
                  <ZenAdminMenu isCollapsed />
                ) : isBroker ? (
                  <ZenBrokerMenu isCollapsed />
                ) : isMortgageUserRole ? (
                  <ZenLoanOfficerMenu isCollapsed />
                ) : isRealTitleOfficer ? (
                  <ZenRealTitleOfficerMenu isCollapsed />
                ) : (
                  <ZenUserMenu isCollapsed />
                )}

                {isAnnouncer && (
                  <ZenSideBarItem
                    icon={<FontAwesomeIcon icon={regular('bullhorn')} />}
                    label='Announcement'
                    linkTo='/announcement'
                    collapsed
                  />
                )}
                <NeoLeoTrigger neoLeoToggle={handleNeoLeoToggle} />
              </div>
            </div>
            <div
              className={cn(
                'flex items-center justify-center bottom-0 px-2 py-2 sticky border-t border-zen-gray-6 w-full',
                isGeminiEnabled ? 'bg-primary-navy' : 'bg-zen-dark-21',
              )}
            >
              <Link
                to={
                  isMortgageUserRole ? '/' : `/people/${userDetail?.id}/profile`
                }
                title='profile-avatar'
              >
                <ZenAvatar
                  imageUrl={userDetail?.avatar}
                  name={userDetail?.fullName!}
                  size='md'
                />
              </Link>
            </div>
          </div>
          {openNeoLeoPanel && (
            <div
              className={cn(
                'h-full-window relative w-[357px] animate-slide-in-left overflow-hidden',
                'bg-white p-4 pr-0',
              )}
            >
              <div
                className={cn(
                  'flex flex-col justify-between bg-primary-navy rounded-[20px] h-full overflow-hidden',
                )}
              >
                <NeoLeoIndex />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NeoMainMenu;
