import { Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns, faUser } from '@fortawesome/pro-regular-svg-icons';
import { PaymentParticipantValue } from '../../../openapi/arrakis';
import { participantRoleDisplayName } from '../../../utils/TransactionHelper';
import PhoneNumberCell from '../../table/Cells/PhoneNumberCell';
import ZenFixedDataTable from '../../Zen/Table/ZenFixedDataTable';
import ZenTransactionSummaryCard from './ZenTransactionSummaryCard';

interface TransactionPaymentParticipantsProps {
  paymentParticipants: PaymentParticipantValue[];
}
const TransactionPaymentParticipantsSummary: React.FC<TransactionPaymentParticipantsProps> = ({
  paymentParticipants = [],
}) => {
  const columns: Array<Column<PaymentParticipantValue>> = [
    {
      Header: 'Name',
      accessor: 'fullName',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-8 font-normal'>
          {value || 'N/A'}
        </div>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <p className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {participantRoleDisplayName(value!)}
        </p>
      ),
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          <PhoneNumberCell phoneNumber={value} />
        </div>
      ),
    },
    {
      Header: 'Company',
      accessor: 'company',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {value || 'N/A'}
        </div>
      ),
    },
    {
      Header: 'Email',
      accessor: 'emailAddress',
      headerContentClassName: '!text-xs !text-zen-dark-15',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className='word-break font-zen-body text-xs text-zen-dark-9 font-normal'>
          {value || 'N/A'}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ZenTransactionSummaryCard
        titleIconPlacement='left'
        titleAdjacentComponent={
          <FontAwesomeIcon icon={faUser} className='mr-2 mb-1 h-3' />
        }
        title='Payment Participants'
      >
        <ZenFixedDataTable<PaymentParticipantValue>
          columns={columns}
          headerVariant='light'
          data={paymentParticipants || []}
          resourceName='Payment Participants'
          hidePagination
          hidePageSize
          hideFilters
          pageSize={paymentParticipants.length}
          customEmptyComponent={
            <div className='w-full h-full p-5 flex flex-col items-center justify-center'>
              <div>
                <FontAwesomeIcon
                  icon={faTableColumns}
                  className='p-2'
                  size='2xl'
                />
              </div>
              <div className='text-xs !font-zen-body !text-primary-dark !font-primary text-center'>
                There are no payment participants to display.
              </div>
            </div>
          }
        />
      </ZenTransactionSummaryCard>
    </div>
  );
};

export default TransactionPaymentParticipantsSummary;
