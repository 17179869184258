import { DateTime } from 'luxon';
import React from 'react';
import {
  AgreementAmendmentResponse,
  AgreementResponse,
} from '../../../openapi/yenta';

interface AgreementHeaderProps {
  unsignedDoc: AgreementAmendmentResponse | AgreementResponse | null;
}

const AgreementHeader: React.FC<AgreementHeaderProps> = ({ unsignedDoc }) => {
  return (
    <div className='text-center my-8 mx-2 lg:mx-0 font-zen-body text-zen-dark-9'>
      <p className='text-[22px] leading-[22px] font-medium mb-4'>
        {unsignedDoc?.name}
      </p>
      <p className='text-base'>
        {`We kindly ask you to review and put your digital signature on the 
              updated version. Starting ${DateTime.fromMillis(
                unsignedDoc?.requiredAt ?? 0,
              ).toFormat('MM/dd/yyyy')}, signing the updated ${
          unsignedDoc?.name
        } is mandatory.`}
      </p>
    </div>
  );
};

export default AgreementHeader;
