import { faSparkles } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type NeoLeoPromptProps = {
  prompt: string;
  onSelect(): void;
  disabled?: boolean;
};

const NeoLeoPrompt: React.FC<NeoLeoPromptProps> = ({
  prompt,
  onSelect,
  disabled,
}) => {
  return (
    <div
      className='w-fit m-1 py-1.5 pl-2 pr-4 bg-white bg-opacity-10 text-white hover:text-aqua rounded-full flex items-center gap-1 ring-[0.5px] hover:ring-1 ring-aqua cursor-pointer disabled:cursor-not-allowed disabled:opacity-50'
      onClick={() => (!disabled ? onSelect() : null)}
    >
      <FontAwesomeIcon icon={faSparkles} className='text-aqua mr-1' />
      <p className='font-inter font-light text-sm'>{prompt}</p>
    </div>
  );
};

export default NeoLeoPrompt;
