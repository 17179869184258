import {
  AgreementDefinitionAmendmentResponseStateEnum,
  AgreementDefinitionWithAmendmentsResponseStateEnum,
} from '../../../openapi/yenta';
import { capitalizeEnum } from '../../../utils/StringUtils';
import { cn } from '../../../utils/classUtils';

export interface AgreementDetailsStatusButtonProps {
  status:
    | AgreementDefinitionWithAmendmentsResponseStateEnum
    | AgreementDefinitionAmendmentResponseStateEnum;
}

export const statusToButtonVariantMap: {
  [type in AgreementDefinitionWithAmendmentsResponseStateEnum]: string;
} = {
  CREATED: 'bg-rezen-light-blue-100 text-regent-900',
  ACTIVE: 'bg-green-100 text-green-600',
  ARCHIVED: 'bg-grey-200 text-grey-600',
};

const AgreementDetailsStatusButton: React.FC<AgreementDetailsStatusButtonProps> = ({
  status,
}) => {
  const statusValue =
    status === AgreementDefinitionWithAmendmentsResponseStateEnum.Active
      ? 'Activated'
      : status;
  return (
    <button
      className={cn(
        'px-2.5 py-1 w-[174px] block rounded-[28px] h-[33px] text-sm font-medium',
        statusToButtonVariantMap[status],
      )}
    >
      {capitalizeEnum(statusValue)}
    </button>
  );
};

export default AgreementDetailsStatusButton;
