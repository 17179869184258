import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import LeoOrb from '../../assets/img/leo/leoOrb.png';
import NeoLeoNotificationsContainer from './Notifications/NeoLeoNotificationsContainer';
import NeoLeoIntroPrompts from './NeoLeoIntroPrompts';

const NeoLeoIntro: FC = () => {
  const { userDetail } = useSelector((state: RootState) => state.auth);

  const [showAllNotifications, setShowAllNotifications] = useState(false);

  if (showAllNotifications) {
    return (
      <div className='font-inter bg-primary-navy w-full h-full overflow-hidden flex flex-col scrollbar-hide'>
        <div className='h-full overflow-auto scrollbar-hide pb-4 px-4'>
          <NeoLeoNotificationsContainer
            showAllNotifications={showAllNotifications}
            setShowAllNotifications={setShowAllNotifications}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='font-inter bg-primary-navy w-full h-full overflow-hidden flex flex-col scrollbar-hide'>
      <div className='h-full flex flex-col flex-grow overflow-auto scrollbar-hide pb-4'>
        <div className='px-6 mb-10'>
          <img
            src={LeoOrb}
            alt='neo leo'
            className='w-[162px] h-auto mt-8 mb-6'
          />
          <p className='font-poppins text-white font-normal text-3xl mb-1'>
            Hi {userDetail?.firstName}
          </p>
          <p className='font-poppins text-[#828CB3] font-normal text-3xl'>
            How can I help you today?
          </p>
        </div>
        <div className='px-4'>
          <NeoLeoNotificationsContainer
            showAllNotifications={showAllNotifications}
            setShowAllNotifications={setShowAllNotifications}
          />
        </div>
        <div className='pb-6 pt-3 pr-4 flex flex-col justify-end flex-grow'>
          <p className='pl-4 font-inter text-white font-medium text-sm text-regent-600 mb-2'>
            Trending Topics
          </p>
          <div className='pl-3 '>
            <NeoLeoIntroPrompts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeoLeoIntro;
