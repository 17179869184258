import { Switch } from 'react-router-dom';
import AuthenticatedAdminRoute from '../components/AuthenticatedAdminRoute';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import ApplicationFee from '../components/onboarding/ApplicationFee';
import ApplicationForm from '../components/onboarding/ApplicationForm';
import ApplicationICASigning from '../components/onboarding/ApplicationICASigning';
import ApplicationICASigningSuccess from '../components/onboarding/ApplicationICASigningSuccess';
import ApplicationICASigningV2 from '../components/onboarding/ApplicationICASigningV2';
import ApplicationJoinBoard from '../components/onboarding/ApplicationJoinBoard';
import ApplicationLOISigning from '../components/onboarding/ApplicationLOISigning';
import ApplicationLOISigningSuccess from '../components/onboarding/ApplicationLOISigningSuccess';
import ApplicationTransferLicense from '../components/onboarding/ApplicationTransferLicense';
import ApplicationFeeSuccess from '../components/onboarding/application-fee/ApplicationFeeSuccess';
import ApplicationFormSuccess from '../components/onboarding/application-form/ApplicationFormSuccess';
import ApplicationSubmittedSuccess from '../components/onboarding/join-board/ApplicationSubmittedSuccess';
import ZenWelcomeOnboardingForm from '../components/onboarding/welcomeOnboarding/ZenWelcomeOnboardingForm';
import ErrorRoutes from './ErrorRoutes';
import ZenOnboardingIndexRoute from './ZenOnboardingIndexRoute';

export interface OnboardingRoutesProps {}

const OnboardingRoutes: React.FC<OnboardingRoutesProps> = () => {
  return (
    <Switch>
      <AuthenticatedAdminRoute
        path='/onboarding'
        component={ZenOnboardingIndexRoute}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-form'
        component={ApplicationForm}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-form/success'
        component={ApplicationFormSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-transfer-license'
        component={ApplicationTransferLicense}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-join-board'
        component={ApplicationJoinBoard}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-fee'
        component={ApplicationFee}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-fee/success'
        component={ApplicationFeeSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-ica-signing'
        component={ApplicationICASigning}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-ica-signing-v2'
        component={ApplicationICASigningV2}
        exact
      />

      <AuthenticatedRoute
        path='/onboarding/application-ica-signing/success'
        component={ApplicationICASigningSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-submitted/success'
        component={ApplicationSubmittedSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-loi-signing'
        component={ApplicationLOISigning}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/application-loi-signing/success'
        component={ApplicationLOISigningSuccess}
        exact
      />
      <AuthenticatedRoute
        path='/onboarding/welcome-onboard'
        component={ZenWelcomeOnboardingForm}
        exact
      />
      <ErrorRoutes />
    </Switch>
  );
};

export default OnboardingRoutes;
