import { ContextualPrompt } from '../utils/LeoUtils';

export const DEFAULT_CHAT_PROMPTS: ContextualPrompt[] = [
  {
    prompt: 'What can Leo do?',
    title: 'What can Leo do?',
    category: 'none',
  },
  {
    prompt: 'When does my license expire?',
    title: 'When does my license expire?',
    category: 'none',
  },
  {
    prompt: 'What is my open GCI?',
    title: 'What is my open GCI?',
    category: 'none',
  },
  {
    prompt: 'What’s the status of my last transaction?',
    title: 'What’s the status of my last transaction?',
    category: 'none',
  },
  {
    prompt: 'How many people in my network?',
    title: 'How many people in my network?',
    category: 'none',
  },
];

export const MULTILINE_INPUT_MIN_ROWS = 1;
export const MULTILINE_INPUT_MAX_ROWS = 3;

export const START_TOKEN = '[START]';
export const DONE_TOKEN = '[DONE]';

export const DEFAULT_SHOWN_ITEMS_COUNT = 3;
export const DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT = 4;
export const DEFAULT_SHOWN_PROPERTY_POSTERS_COUNT_ON_MOBILE = 4;
