import { chain } from 'lodash';
import { useMemo } from 'react';
import useDefaultActions from '../../Kbar/actions/useDefaultActions';
import useLeoActions from '../../Kbar/actions/useLeoActions';
import useListingActions from '../../Kbar/actions/useListingActions';
import useSearchTransactionsListingsActions from '../../Kbar/actions/useSearchTransactionsListingsActions';
import useThemeActions from '../../Kbar/actions/useThemeActions';
import useTransactionActions from '../../Kbar/actions/useTransactionActions';

export interface Action {
  id: string;
  name: string;
  shortcut: string[];
  keywords: string;
  section: string;
  perform: () => void;
}

export interface SectionActions {
  name: string;
  actions: Action[];
}

const useNeoLeoKBarActions = (askLeoText?: string): SectionActions[] => {
  const { defaultActions } = useDefaultActions();
  const { transactionActions } = useTransactionActions();
  const { listingActions } = useListingActions();
  const { themeActions } = useThemeActions();
  const leoActions = useLeoActions();
  const searchResultsActions = useSearchTransactionsListingsActions();

  // Combine all actions into a single array
  const allActions = useMemo(
    () => [
      ...defaultActions,
      ...transactionActions,
      ...listingActions,
      ...searchResultsActions,
      ...leoActions,
      ...themeActions,
    ],
    [
      defaultActions,
      leoActions,
      listingActions,
      searchResultsActions,
      themeActions,
      transactionActions,
    ],
  );

  // Filter actions based on the search text
  const getFilteredActions = (actions: any, searchText: string | undefined) => {
    if (!searchText) {
      return [];
    }

    const filteredActions = actions.filter((action: any) =>
      action.keywords.toLowerCase().includes(searchText.toLowerCase()),
    );

    return chain(filteredActions)
      .groupBy('section')
      .map((actions, name) => ({ name, actions }))
      .value();
  };

  // Memoized filtered actions based on search text
  const filteredActions: SectionActions[] = useMemo(() => {
    return getFilteredActions(allActions, askLeoText);
  }, [allActions, askLeoText]);

  return filteredActions;
};

export default useNeoLeoKBarActions;
