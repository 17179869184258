import React from 'react';
import { ContextualPrompt } from '../../../utils/LeoUtils';
import useNeoLeoKBarActions from '../Hooks/useNeoLeoKBarActions';
import useNeoLeoSearchSuggestions from '../Hooks/useNeoLeoSearchSuggestions';
import NeoLeoKBar from './NeoLeoKBar';
import NeoLeoSuggestions from './NeoLeoSuggestions';

interface NeoLeoPopperProps {
  askLeoText?: string;
  onSubmit: (prompt: ContextualPrompt) => void;
}

const NeoLeoPopper: React.FC<NeoLeoPopperProps> = ({
  askLeoText,
  onSubmit,
}) => {
  const sectionActions = useNeoLeoKBarActions(askLeoText);
  const suggestions = useNeoLeoSearchSuggestions(askLeoText);

  if (!askLeoText) {
    return null;
  }

  if (sectionActions.length === 0 && suggestions.length === 0) {
    return null;
  }

  return (
    <div className='absolute bottom-16 w-full bg-regent-900 border border-white/[.16] rounded-lg max-h-[350px] overflow-y-auto p-4 neo-leo-kbar-box-shadow'>
      <NeoLeoKBar sectionActions={sectionActions} />
      <NeoLeoSuggestions suggestions={suggestions} onSubmit={onSubmit} />
    </div>
  );
};

export default NeoLeoPopper;
