import { useEffect, useRef } from 'react';
import { FieldValues, useForm, UseFormProps } from 'react-hook-form';

/**
This hook should be used in conjunction with the Modal component to ensure that the form's default values are not stale when the modal opens.
@template TFieldValues - The type of form field values.
@template TContext - The type of form context.
@param {UseFormProps<TFieldValues, TContext>} props - The form props.
@param {boolean} opened - Indicates whether the modal is opened or closed.
**/
const useModalForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(
  props: UseFormProps<TFieldValues, TContext>,
  opened: boolean,
) => {
  const formMethods = useForm(props);
  const defaultValues = useRef(props.defaultValues);

  useEffect(() => {
    defaultValues.current = props.defaultValues;
  }, [props.defaultValues]);

  useEffect(() => {
    // reset default values of form on open
    if (opened) {
      formMethods.reset(defaultValues.current as TFieldValues);
    }
  }, [opened, formMethods, defaultValues]);

  return formMethods;
};

export default useModalForm;
