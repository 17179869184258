import { arrayMove } from '@dnd-kit/sortable';
import {
  ActionCreatorWithPayload,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  AgentCreditControllerApi,
  AgentDebtControllerApi,
  AgentDebtCreateRequest,
  AgentDebtResponse,
  AgentDebtsResponse,
  AgentDebtUpdateRequest,
  AgentLiteResponse,
  AgentTeamCapInfoResponse,
  AgentUpdatePermanentlyProducing,
  AllTransactionsResponse,
  AgentControllerApi as ArrakisAgentControllerApi,
  AgentResponse as ArrakisAgentResponse,
  CommissionAdvanceListPagedResponse,
  CommissionAdvanceResponse,
  CommissionAdvanceResponseAdvanceStatusEnum,
  CommissionAdvancesControllerApi,
  CreateAndDisburseReferralRequest,
  CreateAndDisburseReferralResponse,
  CreateCommissionAdvanceRequest,
  DebtInvoiceRequest,
  DebtPrecedence,
  EquityControllerApi,
  EquityOverviewResponse,
  GenericSearchResponseCreditResponse,
  IncomeControllerApi,
  IncomeOverviewResponse,
  IncomeTotalsResponse,
  PublicTransactionControllerApi,
  PublicTransactionResponse,
  RevShareControllerApi,
  RevShareOverviewResponse,
  UpdateCommissionAdvanceRequest,
} from '../openapi/arrakis';
import {
  GlobalNotificationPreferencesDto,
  UserControllerApi as HermesUserController,
  UpdateNotificationPreferencesDto,
} from '../openapi/hermes';
import {
  DocumentControllerApi,
  DocumentTimezoneResponse,
} from '../openapi/signature-api';
import {
  AgentControllerApi,
  AgentDivisionRequest,
  AgentDocumentControllerApi,
  AgentDocumentsResponse,
  AgentNationalIdsResponse,
  AgentResponse,
  AgentUpdateBusinessEmailRequest,
  AgentUpdateRequest,
  AgentWebsiteControllerApi,
  AgentWebsiteResponse,
  AgreementCreationResponse,
  AgreementDefinitionControllerApi,
  AgreementDefinitionResponse,
  AgreementDefinitionWithAmendmentsResponse,
  AgreementResponse,
  AgreementResponseAgreementTypeEnum,
  BusinessEntityValue,
  CreateICAAgreementDefinition,
  CreateOrUpdateAgentWebsiteRequest,
  CreateWillBeneficiaryRequest,
  GenericSearchResponseAgreementDefinitionResponse,
  IcaControllerApi,
  PatchAgreementAmendmentDefinitionRequest,
  PayableInfoResponse,
  PaymentDetailsControllerApi,
  PostAgreementAmendmentRequest,
  SetNationalIdentficationsRequest,
  SponsorTreeResponse,
  UpdateAgentSingleCheckPreferenceRequest,
  UpdateWillBeneficiaryRequest,
  UserAgreementControllerApi,
  WillBeneficiaryAndTypeResponse,
  WillBeneficiaryResponse,
  WillBeneficiaryTrustRequest,
  WillBeneficiaryTrustResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import {
  AgentDetailState,
  AppDispatch,
  AppThunk,
  AsyncResponse,
  CommissionAgentSortByType,
  EnumMap,
  ErrorCode,
  IPaginateReq,
} from '../types';
import { showAndReportErrors } from '../utils/ErrorUtils';
import {
  getArrakisConfiguration,
  getHermesConfiguration,
  getSignatureApiConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import {
  AgentCreditSortByTypeEnum,
  AgentCreditSortDirectionTypeEnum,
} from '../utils/TableUtils';
import { saveUserDetail } from './AuthSlice';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { fetchUserByIds } from './UserIdsSlice';
import { performAsyncRequest } from './utils/SliceUtil';

interface SortDebtItemPayload {
  oldIndex: number;
  newIndex: number;
  debtId: string;
}

export const initialState: AgentDetailState = {
  detailResponse: { loading: false, name: 'agentDetail' },
  priorAgentDetailResponse: { loading: false, name: 'priorAgentDetail' },
  commissionAdvancesResponse: {
    loading: false,
    name: 'commissionAdvancesResponse',
  },
  performanceResponse: { loading: false, name: 'performanceResponse' },
  incomeOverviewResponse: { loading: false, name: 'incomeOverview' },
  incomeChart: undefined,
  equityResponse: { loading: false, name: 'equity' },
  revShareOverview: undefined,
  allTransactionsLoading: false,
  allTransactions: undefined,
  fetchAllTransactionsErrorCode: null,
  nationalIdentificationsResponse: {
    loading: false,
    name: 'nationalIdentifications',
  },
  loadingAgentWebsite: false,
  agentWebsite: undefined,
  notificationSetting: null,
  agentLiteById: {},
  agentFilesResponse: { loading: false, name: 'recent agent files' },
  agentUplineInfoById: {},
  paginatedAgentDebtsResponse: { loading: false, name: 'agent debts' },
  debtCreateInvoiceLoading: {},
  publicTransactionResponseById: {},
  publicTransactionResponseByIdErrorCode: null,
  publicTransactionResponseByIdLoading: false,
  willBeneficiaryAndTypeResponse: {
    loading: false,
    name: 'WillBeneficiaryAndType',
  },
  willableRevshareAgreementResponse: undefined,
  signAgentAgreement: false,
  agentAgreementLoading: false,
  agentAgreementDefinitionResponse: undefined,
  agentAgreementDefinitionWithAmendmentsResponse: {
    loading: false,
    name: 'agentAgreementDefinitionWithAmendmentsResponse',
  },
  agentAgreementsResponse: undefined,
  agentNonSignedAgreementsResponse: undefined,
  allLatestAgreementsNotSigned: undefined,
  agentPayableInfo: { loading: false, name: 'payable info' },
  agentCredits: { loading: false, name: 'agent credits' },
  activeAgreementDefinitionsResponse: {
    loading: false,
    name: 'activeAgreementDefinitionsResponse',
  },
  createdAgreementDefinitionsResponse: {
    loading: false,
    name: 'createdAgreementDefinitionsResponse',
  },
  archivedAgreementDefinitionsResponse: {
    loading: false,
    name: 'archivedAgreementDefinitionsResponse',
  },
};

const AgentSlice = createSlice({
  name: 'agentSlice',
  initialState,
  reducers: {
    saveDetail(state, action: PayloadAction<AsyncResponse<AgentResponse>>) {
      state.detailResponse = action.payload;
    },
    saveAgreementDefinitionWithAmendmentsResponse(
      state,
      action: PayloadAction<AgreementDefinitionWithAmendmentsResponse>,
    ) {
      state.agentAgreementDefinitionWithAmendmentsResponse.data =
        action.payload;
      state.agentAgreementDefinitionWithAmendmentsResponse.loading = false;
    },
    savePriorDetail(
      state,
      action: PayloadAction<AsyncResponse<AgentResponse>>,
    ) {
      state.priorAgentDetailResponse = action.payload;
    },
    updateDetail(state, action: PayloadAction<AsyncResponse<AgentResponse>>) {
      state.detailResponse = action.payload;
    },
    saveCommissionAdvances(
      state,
      action: PayloadAction<AsyncResponse<CommissionAdvanceListPagedResponse>>,
    ) {
      state.commissionAdvancesResponse = action.payload;
    },
    addCommissionAdvances(
      state,
      action: PayloadAction<CommissionAdvanceResponse>,
    ) {
      state.commissionAdvancesResponse.data?.commissionAdvances?.push(
        action.payload,
      );
    },
    updateCommissionAdvances(
      state,
      action: PayloadAction<CommissionAdvanceResponse>,
    ) {
      let commissionAdvancesIndex = state.commissionAdvancesResponse.data?.commissionAdvances?.findIndex(
        (c) => c.id === action.payload.id,
      );

      if (commissionAdvancesIndex !== -1) {
        state.commissionAdvancesResponse.data!.commissionAdvances![
          commissionAdvancesIndex!
        ] = action.payload;
      }
    },
    saveIncomeOverview(
      state,
      action: PayloadAction<AsyncResponse<IncomeOverviewResponse>>,
    ) {
      state.incomeOverviewResponse = action.payload;
    },
    saveIncomeChart(state, action: PayloadAction<IncomeTotalsResponse>) {
      state.incomeChart = action.payload;
    },
    savePerformance(
      state,
      action: PayloadAction<AsyncResponse<ArrakisAgentResponse>>,
    ) {
      state.performanceResponse = action.payload;
    },
    addEquity(
      state,
      action: PayloadAction<AsyncResponse<EquityOverviewResponse>>,
    ) {
      state.equityResponse = action.payload;
    },
    saveCurrentRevShareRevenue(
      state,
      action: PayloadAction<RevShareOverviewResponse>,
    ) {
      state.revShareOverview = action.payload;
    },
    changeAllTransactionsLoading(state, action: PayloadAction<boolean>) {
      state.allTransactionsLoading = action.payload;
    },
    saveAllTransactions(state, action: PayloadAction<AllTransactionsResponse>) {
      state.allTransactions = action.payload;
      state.fetchAllTransactionsErrorCode = null;
    },
    errorFetchingAllTransactions(state, action: PayloadAction<ErrorCode>) {
      state.fetchAllTransactionsErrorCode = action.payload;
    },
    saveNationalIdentificationsResponse(
      state,
      action: PayloadAction<AsyncResponse<AgentNationalIdsResponse>>,
    ) {
      state.nationalIdentificationsResponse = action.payload;
    },
    saveAgentFilesResponse(
      state,
      action: PayloadAction<AsyncResponse<AgentDocumentsResponse>>,
    ) {
      state.agentFilesResponse = action.payload;
    },
    changeLoadingAgentWebsiteDetails(state, action: PayloadAction<boolean>) {
      state.loadingAgentWebsite = action.payload;
    },
    saveAgentWebsiteDetails(
      state,
      action: PayloadAction<AgentWebsiteResponse>,
    ) {
      state.agentWebsite = action.payload;
    },
    saveNotificationSetting(
      state,
      action: PayloadAction<GlobalNotificationPreferencesDto>,
    ) {
      state.notificationSetting = action.payload;
    },
    saveAgentLite(state, action: PayloadAction<AgentLiteResponse>) {
      state.agentLiteById[action.payload.id!] = action.payload;
    },
    saveAgentUplineTree(
      state,
      action: PayloadAction<AsyncResponse<SponsorTreeResponse, { id: string }>>,
    ) {
      state.agentUplineInfoById[action.payload.additionalProps?.id!] =
        action.payload;
    },
    savePaginatedAgentDebtResponse(
      state,
      action: PayloadAction<AsyncResponse<AgentDebtsResponse>>,
    ) {
      state.paginatedAgentDebtsResponse = {
        ...action.payload,
        data: {
          ...action.payload.data,
          agentDebts: !action.payload.data?.agentDebts
            ? state.paginatedAgentDebtsResponse.data?.agentDebts || []
            : action.payload.data.agentDebts || [],
        },
      };
    },
    changeDebtItemPosition(state, action: PayloadAction<SortDebtItemPayload>) {
      state.paginatedAgentDebtsResponse.data!.agentDebts! = arrayMove(
        state.paginatedAgentDebtsResponse.data?.agentDebts!,
        action.payload.oldIndex,
        action.payload.newIndex,
      );

      state.paginatedAgentDebtsResponse.data!.agentDebts?.forEach(
        (debt, index) => {
          debt.precedence = index;
        },
      );
    },
    resetPaginatedAgentDebtResponse(state) {
      state.paginatedAgentDebtsResponse = {
        loading: false,
        name: 'agent debts',
        data: undefined,
        error: undefined,
      };
    },
    changeCreateInvoiceLoading(
      state,
      action: PayloadAction<{ id?: string; loading?: boolean }>,
    ) {
      if (action.payload?.id) {
        state.debtCreateInvoiceLoading[action.payload.id] =
          action.payload.loading;
      } else {
        state.debtCreateInvoiceLoading = {};
      }
    },
    changePublicTransactionResponseByIdLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.publicTransactionResponseByIdLoading = action.payload;
    },
    savePublicTransactionResponse(
      state,
      action: PayloadAction<Array<PublicTransactionResponse>>,
    ) {
      action.payload.forEach((transaction) => {
        state.publicTransactionResponseById[transaction?.id!] = transaction;
      });
      state.publicTransactionResponseByIdErrorCode = null;
    },
    errorFetchingPublicTransactionResponseById(
      state,
      action: PayloadAction<ErrorCode>,
    ) {
      state.publicTransactionResponseByIdErrorCode = action.payload;
    },
    saveAgentWillBeneficiaryAndTypeResponse(
      state,
      action: PayloadAction<AsyncResponse<WillBeneficiaryAndTypeResponse>>,
    ) {
      state.willBeneficiaryAndTypeResponse = action.payload;
    },
    saveWillableRevshareAgreementResponse(
      state,
      action: PayloadAction<AgreementResponse>,
    ) {
      state.willableRevshareAgreementResponse = action.payload;
    },
    saveSignAgentAgreement(state, action: PayloadAction<boolean>) {
      state.signAgentAgreement = action.payload;
    },
    saveAgentAgreementDefinitionResponse(
      state,
      action: PayloadAction<AgreementDefinitionResponse[]>,
    ) {
      state.agentAgreementDefinitionResponse = action.payload;
    },
    saveAgentAgreementsResponse(
      state,
      action: PayloadAction<AgreementResponse[]>,
    ) {
      state.agentAgreementsResponse = action.payload;
    },
    saveAgentNonSignedAgreementsResponse(
      state,
      action: PayloadAction<AgreementResponse | undefined>,
    ) {
      state.agentNonSignedAgreementsResponse = action.payload;
    },
    saveAllLatestAgreementsNotSigned(
      state,
      action: PayloadAction<AgreementResponse[]>,
    ) {
      state.allLatestAgreementsNotSigned = action.payload;
    },
    saveAgentAgreementLoading(state, action: PayloadAction<boolean>) {
      state.agentAgreementLoading = action.payload;
    },
    changeAgreementDetailsLoading(state, action: PayloadAction<boolean>) {
      state.agentAgreementDefinitionWithAmendmentsResponse.loading =
        action.payload;
    },
    savePayableInfo(
      state,
      action: PayloadAction<AsyncResponse<PayableInfoResponse>>,
    ) {
      state.agentPayableInfo = action.payload;
    },
    saveAgentCredits(
      state,
      action: PayloadAction<AsyncResponse<GenericSearchResponseCreditResponse>>,
    ) {
      state.agentCredits = action.payload;
    },
    saveActiveAgreementAmendmentDefinitions(
      state,
      action: PayloadAction<GenericSearchResponseAgreementDefinitionResponse>,
    ) {
      state.activeAgreementDefinitionsResponse.data = action.payload;
      state.activeAgreementDefinitionsResponse.loading = false;
    },
    changeActiveAgreementAmendmentDefinitionsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.activeAgreementDefinitionsResponse.loading = action.payload;
    },
    saveCreatedAgreementAmendmentDefinitions(
      state,
      action: PayloadAction<GenericSearchResponseAgreementDefinitionResponse>,
    ) {
      state.createdAgreementDefinitionsResponse.data = action.payload;
      state.createdAgreementDefinitionsResponse.loading = false;
    },
    changeCreatedAgreementAmendmentDefinitionsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.createdAgreementDefinitionsResponse.loading = action.payload;
    },
    saveArchivedAgreementAmendmentDefinitions(
      state,
      action: PayloadAction<GenericSearchResponseAgreementDefinitionResponse>,
    ) {
      state.archivedAgreementDefinitionsResponse.data = action.payload;
      state.archivedAgreementDefinitionsResponse.loading = false;
    },
    changeArchivedAgreementAmendmentDefinitionsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.archivedAgreementDefinitionsResponse.loading = action.payload;
    },
  },
});

export const {
  saveDetail,
  savePriorDetail,
  updateDetail,
  saveCommissionAdvances,
  addCommissionAdvances,
  updateCommissionAdvances,
  saveIncomeOverview,
  saveIncomeChart,
  savePerformance,
  addEquity,
  saveCurrentRevShareRevenue,
  changeAllTransactionsLoading,
  saveAllTransactions,
  errorFetchingAllTransactions,
  saveNationalIdentificationsResponse,
  changeLoadingAgentWebsiteDetails,
  saveAgentWebsiteDetails,
  saveNotificationSetting,
  saveAgentFilesResponse,
  saveAgentLite,
  saveAgentUplineTree,
  savePaginatedAgentDebtResponse,
  changeDebtItemPosition,
  resetPaginatedAgentDebtResponse,
  changeCreateInvoiceLoading,
  changePublicTransactionResponseByIdLoading,
  savePublicTransactionResponse,
  errorFetchingPublicTransactionResponseById,
  saveAgentWillBeneficiaryAndTypeResponse,
  saveWillableRevshareAgreementResponse,
  saveSignAgentAgreement,
  saveAgentAgreementDefinitionResponse,
  saveAgentAgreementsResponse,
  saveAgentNonSignedAgreementsResponse,
  saveAgentAgreementLoading,
  changeAgreementDetailsLoading,
  savePayableInfo,
  saveAgentCredits,
  saveAgreementDefinitionWithAmendmentsResponse,
  changeActiveAgreementAmendmentDefinitionsLoading,
  saveActiveAgreementAmendmentDefinitions,
  changeCreatedAgreementAmendmentDefinitionsLoading,
  saveCreatedAgreementAmendmentDefinitions,
  changeArchivedAgreementAmendmentDefinitionsLoading,
  saveArchivedAgreementAmendmentDefinitions,
  saveAllLatestAgreementsNotSigned,
} = AgentSlice.actions;

export const saveLoggedInAgentDetails = (details: AgentResponse): AppThunk => (
  dispatch,
  getState,
) => {
  const authUserId = getState().auth.userDetail?.id;
  if (authUserId && authUserId === details.id) {
    dispatch(saveUserDetail(details));
  }
  dispatch(
    updateDetail({
      loading: false,
      name: 'agent details',
      data: details as AgentResponse,
    }),
  );
};

export const fetchAgentDetail = (
  id: string,
  loading: boolean = true,
): AppThunk<Promise<AgentResponse | undefined>> => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).getAgentById(id);
  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'agentDetail',
    saveDetail,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
      changeLoading: loading,
    },
  );

  if (!!res) {
    return res?.data;
  }

  return undefined;
};

export const fetchPriorAgentDetail = (
  id: string,
  loading: boolean = true,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).getAgentById(id);
  await performAsyncRequest(
    dispatch as AppDispatch,
    'priorAgentDetail',
    savePriorDetail,
    fetch,
    {
      skipAuthDatadog: true,
      changeLoading: loading,
    },
  );
};

export const updateAgentDetail = (
  id: string,
  values: AgentUpdateRequest,
  changeLoading?: boolean,
): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).updateAgentById(id, values);

  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'updateDetail',
    updateDetail,
    fetch,
    {
      skipAuthDatadog: true,
      changeLoading: changeLoading,
    },
  );

  if (res?.data?.id === getState().auth.userDetail?.id) {
    dispatch(saveUserDetail(res?.data!));
  }
  dispatch(showSuccessToast('Your profile was successfully updated.'));

  return !!res;
};

export const updateAgentBusinessEmail = (
  id: string,
  agentUpdateBusinessEmailRequest: AgentUpdateBusinessEmailRequest,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).updateBusinessEmailAddress(
      id,
      agentUpdateBusinessEmailRequest,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'updateDetail',
    updateDetail,
    fetch,
    {
      skipAuthDatadog: true,
      errorMessage: 'There was an issue while saving your business email.',
    },
  );
};

export const togglePermanentlyProducing = (
  id: string,
  req: AgentUpdatePermanentlyProducing,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      await getArrakisConfiguration(),
    ).togglePermanentlyProducing(id, req);
    if (data?.permanentlyProducing) {
      dispatch(
        showSuccessToast('Marked agent as permanently producing successfully.'),
      );
    } else {
      dispatch(
        showSuccessToast(
          'UnMarked agent as permanently producing successfully.',
        ),
      );
    }
  } catch (e) {
    ErrorService.notify('Unable to toggle agent as permanently producing', e, {
      agentId: { id },
    });
    dispatch(
      showErrorToast(
        'We had a problem toggling agent as permanently producing',
        'Please try again in a few moments.',
      ),
    );
  }
};

const SortByTypeEnum: EnumMap<any, CommissionAgentSortByType> = {
  advanceStatus: ['STATUS'],
  amount: ['AMOUNT'],
  companyName: ['COMPANY_NAME'],
  referenceNumber: ['REFERENCE_NUMBER'],
  createdAt: ['CREATED_AT'],
};

const SortDirectionTypeEnum: EnumMap<any, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const fetchAgentCommissionAdvances = (
  id: string,
  request: IPaginateReq<CommissionAdvanceResponse>,
): AppThunk<Promise<CommissionAdvanceListPagedResponse | undefined>> => async (
  dispatch,
) => {
  const sortKey = Object.keys(request.sortBy || {})[0];
  const sortType = Object.values(request.sortBy || {})[0];

  const fetch = () =>
    new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getCommissionAdvances1(
      id,
      request.page,
      request.pageSize,
      request?.filter?.advanceStatus
        ? [
            request?.filter
              ?.advanceStatus as CommissionAdvanceResponseAdvanceStatusEnum,
          ]
        : undefined,
      SortByTypeEnum[sortKey!],
      SortDirectionTypeEnum[sortType!],
    );

  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'commission advances',
    saveCommissionAdvances,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );

  if (!!res) {
    return res?.data;
  }

  return undefined;
};

export const addAgentCommissionAdvances = (
  id: string,
  values: CreateCommissionAdvanceRequest,
  agreement: File,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).createCommissionAdvance(id, values);

    const { data: finalData } = await new CommissionAdvancesControllerApi(
      getArrakisConfiguration(),
    ).updateCommissionAdvanceAttachment(data.id!, agreement);

    dispatch(addCommissionAdvances(finalData));
    dispatch(showSuccessToast('Commission Advance created successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to save new commission advance', e, {
      commissionAdvance: { ...values },
    });
    dispatch(
      showErrorToast(
        'We had a problem adding new commission advance',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const updateAgentCommissionAdvances = (
  commissionAdvanceId: string,
  values: UpdateCommissionAdvanceRequest,
  agreement: File,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CommissionAdvancesControllerApi(
      getArrakisConfiguration(),
    ).updateCommissionAdvance(commissionAdvanceId, values);

    if (agreement) {
      const { data: agreementData } = await new CommissionAdvancesControllerApi(
        getArrakisConfiguration(),
      ).updateCommissionAdvanceAttachment(commissionAdvanceId, agreement);

      dispatch(updateCommissionAdvances(agreementData));
    } else {
      dispatch(updateCommissionAdvances(data));
    }

    dispatch(showSuccessToast('Commission Advance updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update commission advance', e, {
      commissionAdvance: { ...values },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating commission advance',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchYearlyIncomePerformanceOverview = (
  yentaId: string,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new IncomeControllerApi(
      getArrakisConfiguration(),
    ).getYearlyIncomePerformanceOverview(yentaId);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'yearly income performance',
    saveIncomeOverview,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const fetchIncomeTotals = (yentaId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new IncomeControllerApi(
      await getArrakisConfiguration(),
    ).getIncomeTotals(yentaId);
    dispatch(saveIncomeChart(data));
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreNotFound(
        'Unable to fetch agent income details',
        e,
      );
      dispatch(
        showErrorToast(
          'We had a problem fetching the details',
          'Please try again in a few moments.',
        ),
      );
    }
  }
};

export const fetchAgentLite = (yentaId: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getAgentLite(yentaId);

    dispatch(saveAgentLite(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch agent lite', e, {
      agent: { id: yentaId },
    });
    dispatch(
      showErrorToast(
        'We had a problem fetching agent personal deal information',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchAgentPerformance = (yentaId: string): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new ArrakisAgentControllerApi(getArrakisConfiguration()).getAgentByYentaId(
      yentaId,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'agent performance',
    savePerformance,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const getAgentPayableInfo = (id: string): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new PaymentDetailsControllerApi(getYentaConfiguration()).getPayableInfo(id);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'payable info',
    savePayableInfo,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const downloadCommissionAdvancesDoc = (
  commissionId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new CommissionAdvancesControllerApi(
      getArrakisConfiguration(),
    ).getPreSignedUrl1(commissionId);
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch commission advances document', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching commission advances document',
        'Please try again in a few moments.',
      ),
    );
    return undefined;
  }
};

export const fetchDriverLicense = (
  id: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).getAgentDriverLicenseImage(id);
    return data?.driverLicenseImageUrl;
  } catch (e) {
    dispatch(showErrorToast('Unable to fetch the driver license image'));
    ErrorService.notify('Unable to fetch the driver license image', e, {
      agent: { id },
    });
    return undefined;
  }
};

export const uploadDriverLicense = (
  id: string,
  image: any,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new AgentControllerApi(
      getYentaConfiguration(),
    ).updateAgentDriverLicenseImage(id, image);
  } catch (e) {
    dispatch(showErrorToast('Unable to upload driver license image'));
    ErrorService.notify('Unable to upload driver license image', e, {
      agent: { id },
    });
  }
};

export const fetchEquity = (yentaId: string): AppThunk => async (dispatch) => {
  const fetch = () =>
    new EquityControllerApi(
      getArrakisConfiguration(),
    ).getCurrentRevSharePerformanceOverview1(yentaId);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'equity',
    addEquity,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const fetchCurrentRevShareOverview = (
  yentaId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new RevShareControllerApi(
      getArrakisConfiguration(),
    ).getCurrentRevSharePerformanceOverview(yentaId);
    dispatch(saveCurrentRevShareRevenue(data));
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreNotFound(
        'Unable to fetch agent revenue details',
        e,
      );
      dispatch(
        showErrorToast(
          'We had a problem fetching the details',
          'Please try again in a few moments.',
        ),
      );
    }
  }
};

export const fetchNationalIdentifications = (
  yentaId: string,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).getNationalIdentifications(
      yentaId,
    );
  performAsyncRequest(
    dispatch as AppDispatch,
    'nationalIdentifications',
    saveNationalIdentificationsResponse,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const updateNationalIdentifications = (
  yentaId: string,
  identifications: SetNationalIdentficationsRequest,
): AppThunk => async (dispatch) => {
  // try {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).setNationalIdentifications(
      yentaId,
      identifications,
    );
  performAsyncRequest(
    dispatch as AppDispatch,
    'nationalIdentifications',
    saveNationalIdentificationsResponse,
    fetch,
    { skipAuthDatadog: true },
  );
  dispatch(showSuccessToast('Tax IDs saved successfully'));
  // } catch (e) {
  //   ErrorService.notify('Unable to update national identification', e, {
  //     nationalIds: { identifications },
  //   });
  // }
};

export const updateBusinessEntity = (
  yentaId: string,
  businessEntity: BusinessEntityValue,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).updatePaidViaBusinessEntity(
      yentaId,
      businessEntity,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'update business entity',
    updateDetail,
    fetch,
    {
      errorMessage: 'We were unable to update your business entity information',
    },
  );

  dispatch(
    showSuccessToast('Your business entity info was successfully updated.'),
  );
};

export const fetchAgentFiles = (agentId: string): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new AgentDocumentControllerApi(getYentaConfiguration()).getDocuments(
      agentId,
    );

  await performAsyncRequest(
    dispatch as AppDispatch,
    'recent agent files',
    saveAgentFilesResponse,
    fetch,
    {
      errorMetadata: { agent: { id: agentId } },
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
    },
  );
};

export const getAgentFileLink = (
  agentId: string,
  documentId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new AgentDocumentControllerApi(
      getYentaConfiguration(),
    ).getLink(agentId, documentId);
    window.open(data);
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem getting an agent file link',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Error getting agent file link', e, {
      agent: { id: agentId },
      docId: { id: documentId },
    });
  }
};

export const deleteAgentFile = (
  agentId: string,
  documentId: string,
): AppThunk => async (dispatch) => {
  try {
    await new AgentDocumentControllerApi(
      getYentaConfiguration(),
    ).deleteDocument(agentId, documentId);
    dispatch(fetchAgentFiles(agentId));
    dispatch(showSuccessToast('Agent File deleted successfully'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem deleting an agent file link',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Error deleting agent file', e, {
      agent: { id: agentId },
      docId: { id: documentId },
    });
  }
};

export const fetchAgentWebsiteDetails = (
  agentIdOrSlug: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingAgentWebsiteDetails(true));
  try {
    const { data } = await new AgentWebsiteControllerApi(
      getYentaConfiguration(),
    ).getWebsiteByAgentIdOrSlug(agentIdOrSlug);
    dispatch(saveAgentWebsiteDetails(data));
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to fetch agent website details', e, {
        Agent: { id: agentIdOrSlug },
      });
      dispatch(
        showErrorToast(
          'We had a problem fetching agent website details',
          'Please try again in a few moments.',
        ),
      );
    }
  } finally {
    dispatch(changeLoadingAgentWebsiteDetails(false));
  }
};

export const updateAgentWebsiteDetails = (
  reqObject: CreateOrUpdateAgentWebsiteRequest,
  websiteId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new AgentWebsiteControllerApi(
      getYentaConfiguration(),
    ).updateWebsiteById(websiteId, reqObject);
    dispatch(saveAgentWebsiteDetails(data));
    dispatch(showSuccessToast('Agent Website is updated successfully.'));
  } catch (e) {
    ErrorService.notify('Unable to update agent website details', e, {
      AgentWebsite: { id: websiteId },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating agent website details',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const clearBusinessEntity = (agentId: string): AppThunk => async (
  dispatch,
) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).clearPaidViaBusinessEntity(
      agentId,
    );
  await performAsyncRequest(
    dispatch as AppDispatch,
    'updateDetail',
    updateDetail,
    fetch,
  );
  dispatch(showSuccessToast('Cleared Business Entity details'));
};

export const createReferralAndDisburse = (
  id: string,
  createAndDisburseReferralRequest: CreateAndDisburseReferralRequest,
): AppThunk<Promise<CreateAndDisburseReferralResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).createAndDisburseReferral(id, createAndDisburseReferralRequest);

    const referralReceivingAgentId = data?.referral?.receivingAgent?.id;

    if (referralReceivingAgentId) {
      dispatch(fetchUserByIds([referralReceivingAgentId]));
    }

    dispatch(showSuccessToast('Successfully created new referral'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create a referral', e, {
      referral: { yentaId: id, createAndDisburseReferralRequest },
    });
    dispatch(
      showErrorToast(
        'We had a problem creating a new referral',
        'Please try again in a few moments.',
      ),
    );
    return undefined;
  }
};

export const getGlobalNotificationStatus = (
  realUsername: string,
): AppThunk<Promise<GlobalNotificationPreferencesDto | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new HermesUserController(
      getHermesConfiguration(),
    ).getNotificationPreferences(realUsername);
    dispatch(saveNotificationSetting(data));
    return data;
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(
        showErrorToast(
          'We could not get your notification setting',
          'Please try again in a few moments.',
        ),
      );

      ErrorService.notify('Unable to fetch users notification setting', e, {
        agent: { realUsername },
      });
    }
    return undefined;
  }
};

export const updateGlobalNotificationSetting = (
  realUsername: string,
  notifications: UpdateNotificationPreferencesDto,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new HermesUserController(
      await getHermesConfiguration(),
    ).updateGlobalNotificationPreferences(realUsername, notifications);
    dispatch(saveNotificationSetting(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to update users notification setting',
      e,
      {
        agent: { realUsername, notifications },
      },
    );
    dispatch(
      showErrorToast(
        'Unable to update the notification setting.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const onboardAgentToPaymentSystem = (
  agentId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new AgentControllerApi(
      getYentaConfiguration(),
    ).onboardToPaymentSystem(agentId);
    return true;
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notify('Unable to onboard to payment system', e, {
        agent: { id: agentId },
      });
    }
    return false;
  }
};

export const fetchAgentUpline = (
  agentId: string,
  loading = true,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).getSponsorTree(agentId);

  await performAsyncRequest<SponsorTreeResponse, { id: string }>(
    dispatch as AppDispatch,
    'agent upline tree',
    saveAgentUplineTree,
    fetch,
    {
      skipAuthDatadog: true,
      ignoreStatusCodesDatadog: [404],
      errorMetadata: { agent: { id: agentId } },
      changeLoading: loading,
      additionalProps: { id: agentId },
    },
  );
};

export const fetchAgentWillBeneficiary = (
  agentId: string,
  changeLoading: boolean = true,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(
      getYentaConfiguration(),
    ).getWillBeneficiaryAndTypeForAgent(agentId);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'WillBeneficiaryAndType',
    saveAgentWillBeneficiaryAndTypeResponse,
    fetch,
    {
      changeLoading,
      skipAuthDatadog: true,
      errorMetadata: { agent: { agentId } },
    },
  );
};

export const createAgentIndividualWillBeneficiary = (
  agentId: string,
  req: CreateWillBeneficiaryRequest,
): AppThunk<Promise<WillBeneficiaryResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).createWillBeneficiaryForAgent(agentId, req);
    dispatch(fetchAgentWillBeneficiary(agentId));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We had a problem creating the beneficiary',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Error in creating individual will beneficiary',
      e,
      {
        agent: { agentId },
        req: { req },
      },
    );
    return undefined;
  }
};

export const updateAgentIndividualWillBeneficiary = (
  agentId: string,
  req: UpdateWillBeneficiaryRequest,
): AppThunk<Promise<WillBeneficiaryResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).updateWillBeneficiaryForAgent(agentId, req);
    dispatch(fetchAgentWillBeneficiary(agentId));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We had a problem updating the beneficiary',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Error in updating individual will beneficiary',
      e,
      {
        agent: { agentId },
        req: { req },
      },
    );
    return undefined;
  }
};

export const createAgentTrustWillBeneficiary = (
  agentId: string,
  req: WillBeneficiaryTrustRequest,
): AppThunk<Promise<WillBeneficiaryTrustResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).createWillBeneficiaryTrustForAgent(agentId, req);
    dispatch(fetchAgentWillBeneficiary(agentId));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We had a problem creating the beneficiary',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Error in creating trust will beneficiary',
      e,
      {
        agent: { agentId },
        req: { req },
      },
    );
    return undefined;
  }
};

export const updateAgentTrustWillBeneficiary = (
  agentId: string,
  req: WillBeneficiaryTrustRequest,
): AppThunk<Promise<WillBeneficiaryTrustResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).updateWillBeneficiaryTrustForAgent(agentId, req);
    dispatch(fetchAgentWillBeneficiary(agentId));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We had a problem updating the beneficiary',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Error in updating trust will beneficiary',
      e,
      {
        agent: { agentId },
        req: { req },
      },
    );
    return undefined;
  }
};

export const getPaginatedAgentDebts = (
  id: string,
  debtStatuses?: Array<'ACTIVE' | 'PAID' | 'CANCELLED'>,
  debtRecoveryTypes?: Array<
    'INVOICE' | 'COMMISSION' | 'REVSHARE' | 'ALL_PAYMENTS'
  >,
  pageNumber?: number,
  pageSize?: number,
  sortBy?: 'PRECEDENCE' | 'CREATED_AT' | 'UPDATED_AT',
  sortDirection?: 'ASC' | 'DESC',
): AppThunk<Promise<undefined | AxiosResponse<AgentDebtsResponse>>> => async (
  dispatch,
) => {
  const fetch = () =>
    new AgentDebtControllerApi(getArrakisConfiguration()).getAgentDebts(
      id,
      debtStatuses,
      debtRecoveryTypes,
      pageNumber,
      pageSize,
      sortBy,
      sortDirection,
    );

  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'agent debts',
    savePaginatedAgentDebtResponse,
    fetch,
    {
      skipAuthDatadog: true,
      errorMetadata: { agent: { id } },
    },
  );

  if (!res) {
    return undefined;
  }

  return res;
};

export const cancelAgentDebt = (debtId: string): AppThunk => async (
  dispatch,
) => {
  try {
    await new AgentDebtControllerApi(getArrakisConfiguration()).cancelDebt(
      debtId,
    );
    dispatch(showSuccessToast('Debt is voided successfully.'));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem voiding agent debt',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to void agent debt', e, {
      debt: { id: debtId },
    });
  }
};

export const changeDebtPosition = (
  agentId: string,
  debtId: string,
  oldIndex: number,
  newIndex: number,
  precedenceList: DebtPrecedence[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(changeDebtItemPosition({ oldIndex, newIndex, debtId }));
    await new AgentDebtControllerApi(
      getArrakisConfiguration(),
    ).updateAgentDebtPrecedence({
      agentId,
      debtPrecedenceList: precedenceList,
    });
    dispatch(showSuccessToast('Debt Order updated successfully.'));
  } catch (e) {
    dispatch(showErrorToast('Unable to update agent debt'));
    ErrorService.notify('Unable to update agent debt', e, {
      debt: { agentId, id: debtId, precedenceList },
    });
  }
};

export const createAgentDebt = (
  req: AgentDebtCreateRequest,
): AppThunk<Promise<AgentDebtResponse | undefined>> => async (dispatch) => {
  try {
    const { data } = await new AgentDebtControllerApi(
      getArrakisConfiguration(),
    ).createAgentDebt(req);
    dispatch(showSuccessToast('Debt created successfully.'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(showErrorToast('Unable to create agent debt'));
    ErrorService.notify('Unable to create debt', e, {
      debt: { req },
    });
    return undefined;
  }
};

export const updateAgentDebt = (
  debtId: string,
  req: AgentDebtUpdateRequest,
): AppThunk<Promise<AgentDebtResponse | undefined>> => async (dispatch) => {
  try {
    const { data } = await new AgentDebtControllerApi(
      getArrakisConfiguration(),
    ).updateAgentDebt(debtId, req);
    dispatch(showSuccessToast('Debt updated successfully.'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We had a problem updating agent debt',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to update agent debt', e, {
      debt: { req, debtId },
    });
    return undefined;
  }
};

export const createAgentDebtInvoice = (
  debtId: string,
  agentId: string,
  status: string[],
  req: DebtInvoiceRequest,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(changeCreateInvoiceLoading({ id: debtId, loading: true }));
  try {
    await new AgentDebtControllerApi(getArrakisConfiguration()).createInvoice(
      debtId,
      req,
    );
    await dispatch(
      getPaginatedAgentDebts(
        agentId,
        status as Array<'ACTIVE' | 'PAID' | 'CANCELLED'>,
        undefined,
        0,
        1000,
        undefined,
        undefined,
      ),
    );
    dispatch(showSuccessToast('Invoice created successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We had a problem creating invoice',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to create invoice for agent debt', e, {
      debt: { req, debtId },
    });
  } finally {
    dispatch(changeCreateInvoiceLoading({ id: debtId, loading: false }));
  }
};

export const fetchPublicTransactionInfo = (
  transactionIds: string[],
): AppThunk => async (dispatch) => {
  dispatch(changePublicTransactionResponseByIdLoading(true));
  try {
    const { data } = await new PublicTransactionControllerApi(
      getArrakisConfiguration(),
    ).getPublicTransactionResponse({ transactionIds });
    dispatch(savePublicTransactionResponse(data.transactions || []));
  } catch (e) {
    dispatch(
      errorFetchingPublicTransactionResponseById(ErrorService.getErrorCode(e)),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to fetch public transaction info',
      e,
      {
        transaction: { transactionIds },
      },
    );
  } finally {
    dispatch(changePublicTransactionResponseByIdLoading(false));
  }
};

export const fetchAgentTeamCapInfo = (
  teamId: string,
): AppThunk<Promise<AgentTeamCapInfoResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new ArrakisAgentControllerApi(
      getArrakisConfiguration(),
    ).getAgentTeamCapInfo(teamId);
    return data;
  } catch (e) {
    if (showAndReportErrors(e?.response)) {
      dispatch(showApiErrorModal(e));
      ErrorService.notifyIgnoreNotFound(
        'Unable to fetch agent team cap details',
        e,
      );
      dispatch(
        showErrorToast(
          'We had a problem fetching the details',
          'Please try again in a few moments.',
        ),
      );
    }
    return undefined;
  }
};

export const updatePriorAgent = (
  agentId: string,
  priorAgentId: string | undefined | null,
  isDelete: boolean = false,
): AppThunk => async (dispatch) => {
  try {
    await new AgentControllerApi(
      getYentaConfiguration(),
    ).updateAgentByIdAdmin1(agentId, { priorUserId: priorAgentId as any });
    await dispatch(fetchAgentDetail(agentId, false));
    if (isDelete) {
      dispatch(
        savePriorDetail({
          loading: false,
          name: 'priorAgentDetail',
          data: undefined,
          error: null,
        }),
      );
      dispatch(showSuccessToast('Prior agent deleted successfully.'));
    } else {
      dispatch(showSuccessToast('Prior agent updated successfully.'));
    }
  } catch (e) {
    dispatch(showApiErrorModal(e));
    if (isDelete) {
      ErrorService.notify('Unable to delete prior agent', e, {
        agent: { id: agentId, priorAgentId },
      });
    } else {
      ErrorService.notify('Unable to update prior agent', e, {
        agent: { id: agentId, priorAgentId },
      });
    }
  }
};

export const stockOpIn = (id: string): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    await new AgentControllerApi(getYentaConfiguration()).optInToEquityPlan(id);
    dispatch(showSuccessToast('Profile has been enabled for stock option-in.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(showErrorToast('Unable to update the stock option to opt-in'));
    ErrorService.notify('Unable to update the stock option to opt-in', e, {
      agent: { id },
    });
  }
};

export const stockOpOut = (id: string): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    await new AgentControllerApi(getYentaConfiguration()).optOutOfEquityPlan(
      id,
    );
    dispatch(
      showSuccessToast('Profile has been enabled for stock option-out.'),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(showErrorToast('Unable to update the stock option to opt-out'));
    ErrorService.notify('Unable to update the stock option to opt-out', e, {
      agent: { id },
    });
  }
};

export const generateWillableRevshareAgreement = (
  userId: string,
): AppThunk => async (dispatch) => {
  try {
    const timezone = await dispatch(getSignatureDocumentTimezone());
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).generateAgreementForWillableRevshare(userId, {
      displayContext: {
        timeZone: timezone?.timezone!,
      },
    });
    await dispatch(saveWillableRevshareAgreementResponse(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'Unable to generate agreement for Willable Revenue Share at this moment.',
        'Please try again later.',
      ),
    );
    ErrorService.notifyIgnoreHandled(
      'Failed to generate willable revshare agreement',
      e,
      { agent: { userId } },
    );
  }
};

export const getSignatureDocumentTimezone = (): AppThunk<
  Promise<DocumentTimezoneResponse | undefined>
> => async (dispatch) => {
  try {
    const { data } = await new DocumentControllerApi(
      getSignatureApiConfiguration(),
    ).getTimezone();
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'Unable to get timezone for signing the agreement at this moment',
        'Please try again later.',
      ),
    );
    ErrorService.notifyIgnoreHandled(
      'Failed to get signature document timezone',
      e,
    );
    return undefined;
  }
};

export const fetchAgreementDefinitionsByPlanAndType = (
  userId: string,
  commissionPlanId: string,
  agreementType: AgreementResponseAgreementTypeEnum,
): AppThunk => async (dispatch) => {
  dispatch(saveAgentAgreementLoading(true));
  try {
    const { data } = await new AgreementDefinitionControllerApi(
      getYentaConfiguration(),
    ).getAgreementDefinitionByPlanAndType(commissionPlanId, agreementType);
    dispatch(saveAgentAgreementDefinitionResponse(data));
    if (data?.length) {
      await dispatch(
        fetchAgreementsByUserAndAgreementTypeAndVersionDesc(
          userId,
          agreementType,
          true,
        ),
      );
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'Unable to fetch agreement definitions by plan and type at this moment.',
        'Please try again later.',
      ),
    );
    ErrorService.notify(
      'Failed to fetch agreement definitions by plan and type',
      e,
      {
        data: { commissionPlanId, agreementType },
      },
    );
  } finally {
    dispatch(saveAgentAgreementLoading(false));
  }
};

export const generateAgentAgreement = (
  userId: string,
  agreementDefinitionId: string,
  agreementType: AgreementResponseAgreementTypeEnum,
): AppThunk<Promise<AgreementCreationResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).generateAgreement({
      userId,
      agreementDefinitionId,
    });
    await dispatch(
      fetchLatestAgreementVersionNotSigned(
        userId,
        agreementType,
        agreementDefinitionId,
      ),
    );
    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'Unable to generate agreement at this moment.',
        'Please try again later.',
      ),
    );
    ErrorService.notify('Failed to generate agreement', e, {
      data: { userId, agreementDefinitionId },
    });
    return undefined;
  }
};

export const fetchAllLatestAgreementsNotSigned = (): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).getAllLatestMandatoryAgreementsNotSigned();
    if (data.length) {
      await dispatch(saveSignAgentAgreement(true));
      await dispatch(saveAllLatestAgreementsNotSigned(data));
    } else {
      await dispatch(saveSignAgentAgreement(false));
      await dispatch(saveAllLatestAgreementsNotSigned([]));
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'Unable to fetch all latest non signed agreements at this moment.',
        'Please try again later.',
      ),
    );
    ErrorService.notify('Failed to fetch all latest non signed agreements', e);
  }
};

export const fetchLatestAgreementVersionNotSigned = (
  userId: string,
  agreementType: AgreementResponseAgreementTypeEnum,
  agreementDefinitionId?: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).getLatestAgreementVersionNotSigned(userId, agreementType);
    dispatch(saveAgentNonSignedAgreementsResponse(data));
    if (data) {
      await dispatch(saveSignAgentAgreement(true));
    } else if (!data && agreementDefinitionId) {
      await dispatch(
        generateAgentAgreement(userId, agreementDefinitionId!, agreementType),
      );
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'Unable to fetch latest non signed agreement at this moment.',
        'Please try again later.',
      ),
    );
    ErrorService.notify('Failed to fetch latest non signed agreement', e, {
      data: { userId, agreementType },
    });
  }
};

export const fetchAgreementsByUserAndAgreementTypeAndVersionDesc = (
  userId: string,
  agreementType: AgreementResponseAgreementTypeEnum,
  signed: boolean,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new UserAgreementControllerApi(
      getYentaConfiguration(),
    ).getAgreementsByUserAndAgreementTypeAndVersionDesc(
      userId,
      agreementType,
      signed,
    );
    dispatch(saveAgentAgreementsResponse(data));
  } catch (e) {
    dispatch(
      showErrorToast(
        'Unable to fetch agent agreements at this moment.',
        'Please try again later.',
      ),
    );
    ErrorService.notify('Unable to fetch agent agreements', e, {
      data: { userId, agreementType, signed },
    });
  }
};

export const fetchLatestICAAgreement = (userId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(saveAgentAgreementLoading(true));
  try {
    const { data } = await new IcaControllerApi(
      getYentaConfiguration(),
    ).getLatestICAAgreement(userId);
    if (data && !data.signedAt) {
      await dispatch(saveSignAgentAgreement(true));
      await dispatch(saveAgentNonSignedAgreementsResponse(data));
    }
  } catch (e) {
    ErrorService.notify('Unable to fetch latest non signed ICA agreement', e, {
      data: { userId },
    });
  } finally {
    dispatch(saveAgentAgreementLoading(false));
  }
};

export const detachDivisionsFromAgent = (
  userId: string,
  divisionId: string,
): AppThunk => async (dispatch) => {
  try {
    await new AgentControllerApi(
      getYentaConfiguration(),
    ).detachDivisionDesignation(userId, divisionId);

    await dispatch(fetchAgentDetail(userId, false));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to detach divisions', e, {
      division: { divisionId },
    });
    dispatch(
      showErrorToast(
        'We had a problem detaching division for this user',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const attachDivisionsToAgent = (
  userId: string,
  divisionIds: AgentDivisionRequest[],
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).attachDivisionDesignations(userId, { divisions: divisionIds });

    dispatch(saveDetail({ name: 'detail', loading: false, data }));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to attach divisions', e);
    dispatch(
      showErrorToast(
        'We had a problem attaching divisions to this agent',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const updateSingleCheckPreference = (
  id: string,
  req: UpdateAgentSingleCheckPreferenceRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new AgentControllerApi(
      getYentaConfiguration(),
    ).updateSingleCheckPreference(id, req);
    dispatch(updateDetail({ name: 'detail', loading: false, data }));
    dispatch(
      showSuccessToast('Successfully updated the single check preference'),
    );
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update single check preference', e);
    dispatch(
      showErrorToast(
        'We had a problem updating the single check preference',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};
export const fetchAgentCreditsForOverview = (
  agentId: string,
  pageNumber = 0,
  pageSize = 10,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new AgentCreditControllerApi(getArrakisConfiguration()).searchAgentCredits(
      AgentCreditSortByTypeEnum.IssuedOn,
      AgentCreditSortDirectionTypeEnum.Desc,
      pageNumber,
      pageSize,
      agentId,
    );

  return await performAsyncRequest(
    dispatch as AppDispatch,
    'agent credits',
    saveAgentCredits,
    fetch,
    {
      skipAuthDatadog: true,
    },
  );
};

const fetchAgreementDefinitions = (
  status: 'ACTIVE' | 'CREATED' | 'ARCHIVED',
  pageNumber: number = 0,
  pageSize: number = 10,
  loadingAction: ActionCreatorWithPayload<boolean, string>,
  saveAction: ActionCreatorWithPayload<
    GenericSearchResponseAgreementDefinitionResponse,
    string
  >,
  sortDirection?: 'ASC' | 'DESC' | undefined,
  sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>,
): AppThunk<
  Promise<GenericSearchResponseAgreementDefinitionResponse | undefined>
> => async (dispatch) => {
  dispatch(loadingAction(true));
  try {
    const { data } = await new AgreementDefinitionControllerApi(
      getYentaConfiguration(),
    ).getAgreementDefinitions(
      status,
      pageNumber,
      pageSize,
      sortDirection,
      sortBy,
    );
    dispatch(saveAction(data));
    return data;
  } catch (e) {
    ErrorService.notify('Unable to get agreements', e);
    return undefined;
  } finally {
    dispatch(loadingAction(false));
  }
};

export const getActiveAgreementDefinitions = (
  pageNumber: number,
  pageSize: number,
  sortDirection?: 'ASC' | 'DESC' | undefined,
  sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>,
) =>
  fetchAgreementDefinitions(
    'ACTIVE',
    pageNumber,
    pageSize,
    changeActiveAgreementAmendmentDefinitionsLoading,
    saveActiveAgreementAmendmentDefinitions,
    sortDirection,
    sortBy,
  );

export const getArchivedAgreementDefinitions = (
  pageNumber: number,
  pageSize: number,
  sortDirection?: 'ASC' | 'DESC' | undefined,
  sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>,
) =>
  fetchAgreementDefinitions(
    'ARCHIVED',
    pageNumber,
    pageSize,
    changeArchivedAgreementAmendmentDefinitionsLoading,
    saveArchivedAgreementAmendmentDefinitions,
    sortDirection,
    sortBy,
  );

export const getCreatedAgreementDefinitions = (
  pageNumber: number,
  pageSize: number,
  sortDirection?: 'ASC' | 'DESC' | undefined,
  sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>,
) =>
  fetchAgreementDefinitions(
    'CREATED',
    pageNumber,
    pageSize,
    changeCreatedAgreementAmendmentDefinitionsLoading,
    saveCreatedAgreementAmendmentDefinitions,
    sortDirection,
    sortBy,
  );

export const createAgreementDefinitions = ({
  req,
}: {
  req: CreateICAAgreementDefinition;
}): AppThunk => async (dispatch) => {
  try {
    await new AgreementDefinitionControllerApi(
      getYentaConfiguration(),
    ).postAgreementDefinition(req);
    dispatch(showSuccessToast('Agreement created successfully'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create agreements', e);
    dispatch(
      showErrorToast(
        'We had a problem creating the agreements',
        'Please try again in a few moments.',
      ),
    );
    return;
  }
};

export const updateAgreementDefinition = ({
  agreementDefinitionId,
  req,
}: {
  agreementDefinitionId: string;
  req: PatchAgreementAmendmentDefinitionRequest;
}): AppThunk => async (dispatch) => {
  try {
    await new AgreementDefinitionControllerApi(
      getYentaConfiguration(),
    ).updateAgreementDefinitionState(agreementDefinitionId, req);
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to get agreements', e);
    dispatch(
      showErrorToast(
        'We had a problem fetching the agreements',
        'Please try again in a few moments.',
      ),
    );
    return;
  }
};

export const fetchAgentAgreementDefinitionWithAmendmentsResponse = (
  id: string,
  loading: boolean = true,
): AppThunk<
  Promise<AgreementDefinitionWithAmendmentsResponse | undefined>
> => async (dispatch) => {
  dispatch(changeAgreementDetailsLoading(loading));
  try {
    const { data } = await new AgreementDefinitionControllerApi(
      getYentaConfiguration(),
    ).getAgreementDefinition(id);
    dispatch(saveAgreementDefinitionWithAmendmentsResponse(data));
    return data;
  } catch (e) {
    ErrorService.notify('Unable to get agreement details', e);
    return undefined;
  }
};

export const updateAgreementDefinitionWithAmendment = ({
  agreementDefinitionId,
  agreementDefinitionAmendmentId,
  req,
}: {
  agreementDefinitionId: string;
  agreementDefinitionAmendmentId: string;
  req: PatchAgreementAmendmentDefinitionRequest;
}): AppThunk => async (dispatch) => {
  try {
    await new AgreementDefinitionControllerApi(
      getYentaConfiguration(),
    ).updateAgreementAmendmentDefinitionState(
      agreementDefinitionId,
      agreementDefinitionAmendmentId,
      req,
    );
    dispatch(
      fetchAgentAgreementDefinitionWithAmendmentsResponse(
        agreementDefinitionId,
      ),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update amendment', e);
    dispatch(
      showErrorToast(
        'We had a problem updating the amendment',
        'Please try again in a few moments.',
      ),
    );
    return;
  }
};

export const createAgreementAmendmentDefinition = (
  agreementDefinitionId: string,
  req: PostAgreementAmendmentRequest,
): AppThunk => async (dispatch) => {
  try {
    await new AgreementDefinitionControllerApi(
      getYentaConfiguration(),
    ).createAgreementAmendmentDefinition(agreementDefinitionId, req);
    dispatch(showSuccessToast('Amendment added successfully'));
    dispatch(
      fetchAgentAgreementDefinitionWithAmendmentsResponse(
        agreementDefinitionId,
      ),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create amendment', e);
    dispatch(
      showErrorToast(
        'We had a problem creating the amendment',
        'Please try again in a few moments.',
      ),
    );
    return;
  }
};

export default AgentSlice.reducer;
