import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  PriceAndDateInfoRequest,
  PriceAndDateInfoRequestDealTypeEnum,
  PriceAndDateInfoRequestPropertyTypeEnum,
  PriceAndDateInfoRequestRepresentationTypeEnum,
  TransactionResponseTransactionTypeEnum,
} from '../../../openapi/arrakis';
import {
  AddressRequestCountryEnum,
  MoneyValueCurrencyEnum,
} from '../../../openapi/yenta';
import { savePriceDateInfos } from '../../../slices/TransactionBuilderSlice';
import { AppDispatch, FeatureFlagTypeEnum, RootState } from '../../../types';
import { isAmountValid, numberWithCommas } from '../../../utils/CurrencyUtils';
import {
  getTransactionDealTypes,
  isAlbertaTransaction,
} from '../../../utils/TransactionBuilderHelper';
import {
  getEnumOptions,
  getTransactionCountry,
  isLeaseOptionVisible,
} from '../../../utils/TransactionHelper';
import {
  MONEY_NON_ZERO_VALUE_VALIDATIONS,
  MONEY_VALIDATIONS,
  PERCENT_VALIDATION,
} from '../../../utils/Validations';
import { cn } from '../../../utils/classUtils';
import { StepByStepComponent } from '../../StepByStep/StepByStepContainer';
import ZenControlledCurrencyInput from '../../Zen/Input/ZenControlledCurrencyInput';
import ZenControlledDatePickerInput from '../../Zen/Input/ZenControlledDatePickerInput';
import ZenControlledFormattedMoneyInput from '../../Zen/Input/ZenControlledFormattedMoneyInput';
import ZenControlledPercentageInput from '../../Zen/Input/ZenControlledPercentageInput';
import ZenControlledRadioInput from '../../Zen/Input/ZenControlledRadioInput';
import ZenControlledToggleInput from '../../Zen/Input/ZenControlledToggleInput';
import ZenButton from '../../Zen/ZenButton';
import withCreateListingProgress from '../CreateListing/withCreateListingProgress';
import ZenControlledSelectInput from '../../Zen/Input/ZenControlledSelectInput';
import {
  Match,
  ZenCreateListingFormState,
  ZenCreateListingStepName,
} from './ZenCreateListingSteps';

const ZenTransactionStepTwo: StepByStepComponent<
  ZenCreateListingFormState,
  ZenCreateListingStepName
> = ({
  form: {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  },
  onPrevious,
  onNext,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { transactionBuilderId } = useParams<Match>();
  const {
    auth: { userDetail },
    transactionBuilder: { transactionBuilder },
  } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isSalesCommissionDollar,
    isListingCommissionDollar,
    transactionType,
    listingExpirationDate,
    listingDate,
    firmDate,
    listingCommission,
    commission,
    price,
    address,
    location,
    propertyType,
  ] = watch([
    'commission.isDollar',
    'listingCommission.isDollar',
    'transactionType',
    'listingExpirationDate',
    'listingDate',
    'firmDate',
    'listingCommission',
    'commission',
    'price',
    'address',
    'location',
    'propertyType',
  ]);
  const isCanadaTransaction =
    getTransactionCountry(address, location) ===
    AddressRequestCountryEnum.Canada;

  const isLeaseAllowed = isLeaseOptionVisible(
    userDetail?.offices!,
    transactionBuilder?.address?.state!,
  );

  useEffect(() => {
    if (!isLeaseAllowed) {
      setValue('transactionType', TransactionResponseTransactionTypeEnum.Sale);
    }
  }, [setValue, isLeaseAllowed]);

  useEffect(() => {
    if (isAlbertaTransaction(transactionBuilder?.address?.state)) {
      setValue('transactionType', TransactionResponseTransactionTypeEnum.Sale);
    }
  }, [setValue, transactionBuilder?.address?.state]);

  const getAmountOrPercentage = (percent: any, amt: any, isDollar: boolean) => {
    const percentage = watch(percent) || 0;
    const price = watch('price.amount') || 0;
    const amount = watch(amt) || 0;
    if (!isDollar)
      return numberWithCommas(((percentage * price) / 100).toFixed(2));
    return numberWithCommas(Math.abs(amount).toFixed(2));
  };

  const handleTypeAndPriceStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      const priceDateRequest: PriceAndDateInfoRequest = {
        dealType: (transactionType as unknown) as PriceAndDateInfoRequestDealTypeEnum,
        listingExpirationDate: (listingExpirationDate as unknown) as string,
        listingDate: (listingDate as unknown) as string,
        listingCommission: {
          commissionAmount: isListingCommissionDollar
            ? {
                amount: +listingCommission.money?.amount! || 0,
                currency: price.currency,
              }
            : undefined,
          commissionPercent: !isListingCommissionDollar
            ? listingCommission.percent?.value! || 0
            : undefined,
          percentEnabled: !listingCommission.isDollar,
        },
        saleCommission: {
          commissionAmount: isSalesCommissionDollar
            ? {
                amount: +commission.money?.amount! || 0,
                currency: price.currency,
              }
            : undefined,
          commissionPercent: !isSalesCommissionDollar
            ? commission.percent?.value! || 0
            : undefined,
          percentEnabled: !commission.isDollar,
        },
        salePrice: {
          amount: +price?.amount! || 0,
          currency: price?.currency,
        },
        representationType:
          PriceAndDateInfoRequestRepresentationTypeEnum.Seller,
        ...(isCanadaTransaction && {
          firmDate: (firmDate as unknown) as string,
        }),
        propertyType: propertyType?.value as PriceAndDateInfoRequestPropertyTypeEnum,
      };
      setLoading(true);
      const res = await dispatch(
        savePriceDateInfos(transactionBuilderId!, priceDateRequest),
      );
      setLoading(false);
      if (res) {
        onNext();
      }
    }
  };

  const {
    listingCommission: listingError,
    commission: saleCommissionError,
  } = errors;

  useEffect(() => {
    setValue('price', {
      amount: transactionBuilder?.salePrice?.amount!,
      currency: isCanadaTransaction
        ? MoneyValueCurrencyEnum.Cad
        : MoneyValueCurrencyEnum.Usd,
    });
  }, [setValue, transactionBuilder?.salePrice?.amount, isCanadaTransaction]);

  const isChecklistDynamicsPropertyTypesEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.CHECKLIST_DYNAMICS_PROPERTY_TYPES,
  );

  return (
    <div className='w-full flex flex-col flex-grow mt-10 relative'>
      <div className='w-full max-w-3xl mx-auto flex-grow'>
        <div>
          <p className='text-xl font-primary-medium mb-4'>
            Listing Type, Price & Commission
          </p>
          <div className='space-y-4 mb-5'>
            <div className='flex flex-row space-x-4'>
              <ZenControlledRadioInput<
                ZenCreateListingFormState,
                'transactionType'
              >
                name='transactionType'
                control={control}
                label='Deal Type'
                inlineOptions
                shouldUnregister={false}
                options={getTransactionDealTypes(
                  transactionBuilder,
                  isLeaseAllowed,
                )}
                rules={{
                  required: 'Please select a deal type',
                }}
                isRequired
              />
              <ZenControlledFormattedMoneyInput<
                ZenCreateListingFormState,
                'price'
              >
                control={control}
                label='List Price'
                name='price'
                shouldUnregister={false}
                placeholder='E.g. 1,744,000'
                rules={MONEY_NON_ZERO_VALUE_VALIDATIONS}
                isRequired
                currencyReadOnly
              />
            </div>

            {isChecklistDynamicsPropertyTypesEnabled && (
              <div className='mt-5'>
                <ZenControlledSelectInput<
                  ZenCreateListingFormState,
                  'propertyType'
                >
                  control={control}
                  label='Property Type'
                  name='propertyType'
                  customClassName='w-full'
                  options={getEnumOptions(
                    PriceAndDateInfoRequestPropertyTypeEnum,
                  )}
                  shouldUnregister={false}
                  rules={{
                    required: 'Please select property type',
                  }}
                  isRequired
                />
              </div>
            )}
            {!isCanadaTransaction && (
              <section>
                <div>
                  <p>
                    Listing Commission{' '}
                    <span className='text-zen-danger'>*</span>
                  </p>
                  <div className='flex flex-row items-center space-x-5'>
                    <div>
                      <ZenControlledToggleInput<
                        ZenCreateListingFormState,
                        'listingCommission.isDollar'
                      >
                        label=''
                        name='listingCommission.isDollar'
                        control={control}
                        shouldUnregister={false}
                        defaultValue={false}
                        rightIcon='$'
                        leftIcon='%'
                        hideErrorMessage
                      />
                    </div>
                    <div
                      className={cn(
                        'mt-1 rounded shadow-sm',
                        isListingCommissionDollar ? 'hidden' : 'flex',
                      )}
                    >
                      <span className='text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-100 text-gray-500 sm:text-sm'>
                        %
                      </span>
                      <span className='w-20 rounded-none rounded-r sm:text-sm border border-l-0'>
                        <ZenControlledPercentageInput<
                          ZenCreateListingFormState,
                          'listingCommission.percent.value'
                        >
                          control={control}
                          name='listingCommission.percent.value'
                          shouldUnregister={false}
                          noBorder
                          placeholder='Percentage'
                          rules={{
                            required: !isListingCommissionDollar
                              ? 'Listing commission is required'
                              : undefined,
                            pattern: !isListingCommissionDollar
                              ? { ...PERCENT_VALIDATION?.pattern! }
                              : undefined,
                          }}
                          hideErrorMessage
                        />
                      </span>
                    </div>
                    <div
                      className={cn(
                        'mt-1 rounded shadow-sm',
                        isListingCommissionDollar ? 'flex' : 'hidden',
                      )}
                    >
                      <span className='text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-100 text-gray-500 sm:text-sm'>
                        $
                      </span>
                      <span className='w-20 rounded-none rounded-r text-sm border border-l-0'>
                        <ZenControlledCurrencyInput<
                          ZenCreateListingFormState,
                          'listingCommission.money.amount'
                        >
                          control={control}
                          name='listingCommission.money.amount'
                          shouldUnregister={false}
                          noBorder
                          defaultValue={0}
                          rules={{
                            required: isListingCommissionDollar
                              ? 'Listing commission is required'
                              : undefined,
                            ...MONEY_VALIDATIONS,
                            validate: (val) => {
                              if (
                                isListingCommissionDollar &&
                                !!val &&
                                !isAmountValid(val, price?.amount)
                              ) {
                                return 'Please enter valid amount';
                              }
                              return undefined;
                            },
                          }}
                          placeholder='Amount'
                          hideErrorMessage
                        />
                      </span>
                    </div>
                    <div className='mt-1 flex rounded ml-4'>
                      <p>
                        $
                        <span className='text-gray-800 ml-2'>
                          {getAmountOrPercentage(
                            'listingCommission.percent.value',
                            'listingCommission.money.amount',
                            isListingCommissionDollar!,
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className='text-zen-danger text-sm pt-1'>
                    {!isListingCommissionDollar
                      ? listingError?.percent?.value?.message
                      : listingError?.money?.amount?.message}
                  </p>
                </div>
                <div>
                  <p>
                    Sale Commission <span className='text-zen-danger'>*</span>
                  </p>
                  <div className='flex flex-row items-center space-x-5'>
                    <div>
                      <ZenControlledToggleInput<
                        ZenCreateListingFormState,
                        'commission.isDollar'
                      >
                        label=''
                        name='commission.isDollar'
                        control={control}
                        shouldUnregister={false}
                        defaultValue={false}
                        rightIcon='$'
                        leftIcon='%'
                        hideErrorMessage
                      />
                    </div>
                    <div
                      className={cn(
                        'mt-1 rounded shadow-sm',
                        isSalesCommissionDollar ? 'hidden' : 'flex',
                      )}
                    >
                      <span className='text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-100 text-gray-500 sm:text-sm'>
                        %
                      </span>
                      <span className='w-20 rounded-none rounded-r sm:text-sm border border-l-0'>
                        <ZenControlledPercentageInput<
                          ZenCreateListingFormState,
                          'commission.percent.value'
                        >
                          control={control}
                          name='commission.percent.value'
                          shouldUnregister={false}
                          noBorder
                          placeholder='Percentage'
                          rules={{
                            required: !isSalesCommissionDollar
                              ? 'Sale commission is required'
                              : undefined,
                            pattern: !isSalesCommissionDollar
                              ? { ...PERCENT_VALIDATION?.pattern! }
                              : undefined,
                          }}
                          hideErrorMessage
                        />
                      </span>
                    </div>
                    <div
                      className={cn(
                        'mt-1 rounded shadow-sm',
                        isSalesCommissionDollar ? 'flex' : 'hidden',
                      )}
                    >
                      <span className='text-sm inline-flex items-center px-1.5 md:px-2 rounded-l border border-r-0 bg-gray-100 text-gray-500 sm:text-sm'>
                        $
                      </span>
                      <span className='w-20 rounded-none rounded-r text-sm border border-l-0'>
                        <ZenControlledCurrencyInput<
                          ZenCreateListingFormState,
                          'commission.money.amount'
                        >
                          control={control}
                          name='commission.money.amount'
                          shouldUnregister={false}
                          noBorder
                          defaultValue={0}
                          rules={{
                            required: isSalesCommissionDollar
                              ? 'Sale commission is required'
                              : undefined,
                            ...MONEY_VALIDATIONS,
                            validate: (val) => {
                              if (
                                isSalesCommissionDollar &&
                                !!val &&
                                !isAmountValid(val, price?.amount)
                              ) {
                                return 'Please enter valid amount';
                              }
                              return undefined;
                            },
                          }}
                          placeholder='Amount'
                          hideErrorMessage
                        />
                      </span>
                    </div>
                    <div className='mt-1 flex rounded ml-4'>
                      <p>
                        $
                        <span className='text-gray-800 ml-2'>
                          {getAmountOrPercentage(
                            'commission.percent.value',
                            'commission.money.amount',
                            isSalesCommissionDollar!,
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className='text-zen-danger text-sm pt-1'>
                    {!isSalesCommissionDollar
                      ? saleCommissionError?.percent?.value?.message
                      : saleCommissionError?.money?.amount?.message}
                  </p>
                </div>
              </section>
            )}
          </div>
        </div>
        <div className='my-10'>
          <p className='text-xl font-primary-medium mb-4'>Key Dates</p>
          <div className='flex flex-row space-x-4'>
            <ZenControlledDatePickerInput<
              ZenCreateListingFormState,
              'listingDate'
            >
              control={control}
              label='Listing Start Date'
              name='listingDate'
              shouldUnregister={false}
              placeholder='MM/DD/YYYY'
              rules={{
                required: 'Listing Start Date is Required',
              }}
              isRequired
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  title='calendar'
                  className='text-gray-700 text-xl'
                />
              }
              onChangeSpy={(date) => {
                const startDateInISO: Date = (DateTime.fromJSDate(
                  date,
                ).toISODate() as unknown) as Date;

                if (startDateInISO > listingExpirationDate) {
                  setValue(
                    'listingExpirationDate',
                    (undefined as unknown) as Date,
                  );
                }
              }}
            />
            <ZenControlledDatePickerInput<
              ZenCreateListingFormState,
              'listingExpirationDate'
            >
              control={control}
              label='Expiration Date'
              subLabel='(date listing agreement expires)'
              name='listingExpirationDate'
              shouldUnregister={false}
              placeholder='MM/DD/YYYY'
              datePickerConfig={{
                minDate: listingDate
                  ? DateTime.fromISO(
                      (listingDate as unknown) as string,
                    ).toJSDate()
                  : undefined,
              }}
              rules={{
                required: 'Expiration Date is Required',
              }}
              isRequired
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  title='calendar'
                  className='text-gray-700 text-xl'
                />
              }
            />
          </div>
        </div>
      </div>
      <div className='sticky w-full bottom-0 z-0 bg-white'>
        <div className='w-full mx-auto max-w-2xl'>
          <div className='grid grid-cols-2 gap-8 py-6 md:py-8 shadow-top-sm'>
            <ZenButton
              isFullWidth
              variant='secondary-gray-outline'
              type='button'
              label='Previous'
              onClick={onPrevious}
            />
            <ZenButton
              isFullWidth
              isSubmitting={loading}
              type='submit'
              variant='primary'
              isDisabled={loading}
              label='Next'
              onClick={handleTypeAndPriceStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCreateListingProgress(ZenTransactionStepTwo);
