import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import {
  deleteSessionHistory,
  fetchSessionChat,
  saveShowIntro,
} from '../../slices/LeoSlice';
import { RootState } from '../../types';
import { cn } from '../../utils/classUtils';
import DefaultLoader from '../DefaultLoader';
import { SessionHistory } from './NeoLeoHistoryIndex';

interface NeoHistoryCardProps extends SessionHistory {
  hideHistory(): void;
}

const NeoHistoryCard: React.FC<NeoHistoryCardProps> = ({
  id,
  title,
  agent_id,
  hideHistory,
}) => {
  const dispatch = useDispatch();
  const { session: activeSession, deletingSessionId } = useSelector(
    (state: RootState) => state.leo,
  );
  const isSessionActive = activeSession?.id === id;
  const isSessionDeleting = deletingSessionId === id;

  const [isHovered, setIsHovered] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);

  return (
    <button
      type='button'
      className='bg-white bg-opacity-5 rounded-md flex justify-between items-center gap-2.5 py-3.5 px-4 mt-3 border border-regent-600 hover:border-aqua w-full'
      onClick={() => {
        dispatch(saveShowIntro(false));
        hideHistory();
        dispatch(fetchSessionChat(id, agent_id));
      }}
      disabled={!!isSessionDeleting}
      title='session-history-card'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='flex flex-grow'>
        <p className='text-sm text-left text-white font-inter font-normal line-clamp-2'>
          {title}
        </p>
      </div>
      <div
        className={cn(
          'flex justify-center items-center w-6 h-6 p-2 bg-[#4A5172] rounded-md opacity-0',
          {
            'opacity-0': isSessionActive && isHovered,
            'opacity-100': !isSessionActive && isHovered,
          },
        )}
        onClick={(e) => {
          e.stopPropagation();
          setEnableDelete((prev) => !prev);
        }}
        onMouseLeave={() => setEnableDelete(false)}
      >
        <Popover
          isOpen={enableDelete}
          positions={['bottom', 'left']}
          content={
            <>
              {!isSessionActive && (
                <button
                  className='bg-primary-navy text-white border border-[rgba(255,255,255,0.16)] shadow-[-9px_-17px_32px_0px_rgba(0,0,0,0.43)] py-2.5 pr-5 pl-4 rounded-lg flex justify-center items-center gap-2.5'
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(deleteSessionHistory(id));
                  }}
                >
                  <span className='w-6 h-6 p-2 flex justify-center items-center bg-white bg-opacity-10 rounded-md'>
                    {isSessionDeleting ? (
                      <DefaultLoader noPadding iconSize='small' />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTrash}
                        className='text-[#9B9FB1]'
                      />
                    )}
                  </span>
                  <p className='font-inter text-sm font-light'>Delete</p>
                </button>
              )}
            </>
          }
        >
          <FontAwesomeIcon icon={faEllipsis} className='text-white' />
        </Popover>
      </div>
    </button>
  );
};

export default NeoHistoryCard;
