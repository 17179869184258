import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveLoggedInAgentDetails } from '../../../slices/AgentSlice';
import { AppDispatch, RootState } from '../../../types';
import { isOutOfOffice } from '../../../utils/RoarUtils';
import { UUID_REGEX } from '../../../utils/StringUtils';
import ZenCustomSwitch from '../../Zen/Input/ZenCustomSwitch';
import { useUpdateDoNotDisturb } from '../../../query/roar/useRoar';
import { UserAvailabilityResponse } from '../../../openapi/yenta';

type DoNotDisturbBannerProps = {};

const DoNotDisturbBanner: React.FC<DoNotDisturbBannerProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const userDetails = useSelector((state: RootState) => state.auth.userDetail);
  const agentId = userDetails?.id!;
  const userAvailability = (userDetails?.availability ||
    {}) as UserAvailabilityResponse;
  const officeHoursRouteRegExp = new RegExp(
    `/people/${UUID_REGEX}/office-hours`,
  );
  const isOfficeHoursRouteActive = officeHoursRouteRegExp.test(
    history.location.pathname,
  );
  const isOutOfOfficeActive = isOutOfOffice(userDetails);

  const { mutate, isLoading } = useUpdateDoNotDisturb(agentId);

  if (
    isOfficeHoursRouteActive ||
    !userDetails?.availability?.doNotDisturb ||
    isOutOfOfficeActive
  ) {
    return null;
  }

  return (
    <div className='flex items-center m-4 py-3 px-4 md:px-6 md:py-3 border border-yellow-100 bg-yellow-100 rounded-[10px] justify-between space-x-3 transition duration-300'>
      <div className='flex items-center space-x-3'>
        <FontAwesomeIcon icon={faInfoCircle} className='text-regent-900' />
        <p className='text-base font-inter font-medium text-primary-navy'>
          Your Do Not Disturb is currently on. You will not receive any ROARs.
        </p>
      </div>
      <ZenCustomSwitch
        toggle
        disabled={isLoading}
        onClick={() =>
          mutate(
            {
              ...userAvailability,
              doNotDisturb: false,
            },
            {
              onSuccess: (data) => {
                dispatch(
                  saveLoggedInAgentDetails({
                    ...userDetails,
                    availability: data.availability,
                  }),
                );
              },
            },
          )
        }
        testId='do-not-disturb'
      />
    </div>
  );
};

export default DoNotDisturbBanner;
