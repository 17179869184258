import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ZenAgentFileRowProps {
  title: string;
  date: string;
  label: string[];
  rightActionText?: String;
  onRightActionClick?(): void;
}

const ZenAgentFileRow: React.FC<ZenAgentFileRowProps> = ({
  title,
  date,
  label,
  rightActionText,
  onRightActionClick,
}) => {
  return (
    <div className='px-4 py-3'>
      <div className='flex flex-row justify-between'>
        <div className='text-sm'>{title}</div>
        {!!rightActionText && (
          <button
            onClick={onRightActionClick}
            className='text-sm font-primary-regular text-primary-blue focus:outline-none hover:opacity-70 flex flex-row items-center justify-center space-x-1'
          >
            <FontAwesomeIcon
              icon={faArrowDownToBracket}
              className='text-primary-blue'
            />
            <p className='mt-1 text-primary-blue'>{rightActionText}</p>
          </button>
        )}
      </div>
      <div className='pb-px text-sm text-gray-600'>
        Uploaded <span>{date}</span>
      </div>
      <div className='flex flex-row space-x-1 text-xs text-gray-400 mt-1'>
        {label?.map((item) => {
          return (
            <div key={item} className='px-1 bg-gray-100'>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ZenAgentFileRow;
