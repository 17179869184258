import { DateTime } from 'luxon';
import { AgreementResponse } from '../openapi/yenta';

export const hasPastAgreementRequiredDate = (requiredAt: number) => {
  if (requiredAt) {
    return DateTime.now().toISODate() >=
      DateTime.fromMillis(requiredAt).toISODate()
      ? true
      : false;
  }
  return false;
};

export const hasPastAmendmentRequiredDate = (agreements: AgreementResponse) => {
  for (let amendment of agreements?.amendments!) {
    if (hasPastAgreementRequiredDate(amendment?.requiredAt!)) {
      return true;
    }
  }

  return false;
};

export const hasCrossedRequiredDate = (agreements: AgreementResponse[]) => {
  if (agreements?.length > 0)
    for (let agreement of agreements!) {
      if (hasPastAgreementRequiredDate(agreement?.requiredAt!)) {
        return true;
      }
      if (hasPastAmendmentRequiredDate(agreement)) {
        return true;
      }
    }
  return false;
};
