import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AsyncResponse, DropboxState } from '../types';
import {
  DropboxApi,
  DropboxResponse,
  FileApi,
  FileResponse,
  GlobalDropboxApi,
} from '../openapi/dropbox';
import ErrorService from '../services/ErrorService';
import {
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { ChecklistApi } from '../openapi/sherlock';
import { performFetch } from '../utils/FetchUtil';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { showApiErrorModal } from './ErrorSlice';

export const initialState: DropboxState = {
  dropboxById: {},
  loadingById: {},
  deletingById: {},
  updatingFileById: {},
  splitPdfUrl: { loading: false, name: 'splitPdfUrl' },
  globalDropbox: {},
};

const DropboxSlice = createSlice({
  name: 'dropbox',
  initialState,
  reducers: {
    saveDropbox(state, action: PayloadAction<DropboxResponse>) {
      state.dropboxById[action.payload.id!] = action.payload;
    },
    changeLoading(
      state,
      action: PayloadAction<{ id: string; loading: boolean }>,
    ) {
      const { id, loading } = action.payload;
      state.loadingById[id!] = loading;
    },
    saveFileToDropbox(
      state,
      action: PayloadAction<{ dropboxId: string; file: FileResponse }>,
    ) {
      const { file, dropboxId } = action.payload;
      state.dropboxById?.[dropboxId]?.files?.push(file);
    },
    changeDeleting(
      state,
      action: PayloadAction<{ id: string; isDeleting: boolean }>,
    ) {
      const { id, isDeleting } = action.payload;
      state.deletingById[id!] = isDeleting;
    },
    removeFile(
      state,
      action: PayloadAction<{ dropboxId: string; fileId: string }>,
    ) {
      const { dropboxId, fileId } = action.payload;

      state.dropboxById[dropboxId]!.trashFiles = (
        state.dropboxById[dropboxId]!.trashFiles || []
      ).filter((file) => file.id !== fileId);
    },
    changeUpdating(
      state,
      action: PayloadAction<{ id: string; isUpdating: boolean }>,
    ) {
      const { id, isUpdating } = action.payload;
      state.updatingFileById[id!] = isUpdating;
    },
    saveFile(
      state,
      action: PayloadAction<{ dropboxId: string; file: FileResponse }>,
    ) {
      const { dropboxId, file } = action.payload;

      const fileIndex = state.dropboxById[dropboxId]!.files!.findIndex(
        (dropboxFile) => dropboxFile.id === file.id,
      );

      if (fileIndex !== -1) {
        state.dropboxById[dropboxId]!.files![fileIndex] = file;
      }
    },
    saveSplitPdfUrl(
      state,
      action: PayloadAction<AsyncResponse<string | undefined>>,
    ) {
      state.splitPdfUrl = action.payload;
    },
    saveGlobalDropbox(state, action: PayloadAction<DropboxResponse>) {
      state.globalDropbox = action.payload;
    },
  },
});

export const {
  saveDropbox,
  changeLoading,
  saveFileToDropbox,
  changeDeleting,
  removeFile,
  saveFile,
  changeUpdating,
  saveSplitPdfUrl,
  saveGlobalDropbox,
} = DropboxSlice.actions;

export const fetchDropbox = (id: string): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const {
    dropbox: { dropboxById },
  } = getState();

  if (!dropboxById[id]) {
    dispatch(changeLoading({ id, loading: true }));
  }

  try {
    const { data } = await new DropboxApi(
      getDropboxConfiguration(),
    ).getDropboxById(id);

    dispatch(saveDropbox(data));
  } catch (e) {
    ErrorService.notify('Unable to fetch dropbox', e, { dropbox: { id } });
    dispatch(
      showErrorToast(
        'Something went wrong while fetching the File Cabinet',
        'Please try again after sometime.',
      ),
    );
  } finally {
    if (!dropboxById[id]) {
      dispatch(changeLoading({ id, loading: false }));
    }
  }
};

export const uploadFile = (
  dropboxId: string,
  uploadedBy: string,
  file: File,
): AppThunk<Promise<FileResponse | undefined>> => async (dispatch) => {
  try {
    const { data } = await new DropboxApi(getDropboxConfiguration()).uploadFile(
      dropboxId,
      file,
      uploadedBy,
    );

    dispatch(saveFileToDropbox({ dropboxId, file: data }));
    dispatch(showSuccessToast('Document uploaded successfully'));
    return data;
  } catch (e) {
    ErrorService.notify('Unable to upload a file to dropbox', e, {
      dropbox: { id: dropboxId, uploadedBy },
    });
    dispatch(
      showErrorToast(
        'Something went wrong while uploading the files to the File Cabinet',
        'Please try again after sometime.',
      ),
    );
    return undefined;
  }
};

export const deleteFile = (
  dropboxId: string,
  fileId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  dispatch(changeDeleting({ isDeleting: true, id: fileId }));
  try {
    await new FileApi(getDropboxConfiguration()).deleteFile(fileId);

    dispatch(removeFile({ fileId, dropboxId }));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to delete a file', e, {
      file: { id: fileId },
    });

    return false;
  } finally {
    dispatch(changeDeleting({ isDeleting: false, id: fileId }));
  }

  return true;
};

export const renameFile = (
  dropboxId: string,
  fileId: string,
  filename?: string,
  description?: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  dispatch(changeUpdating({ isUpdating: true, id: fileId }));
  try {
    const { data } = await new FileApi(
      getDropboxConfiguration(),
    ).updateFile(fileId, { filename, description });

    dispatch(saveFile({ file: data, dropboxId }));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to rename a file', e, {
      file: { id: fileId },
    });

    return false;
  } finally {
    dispatch(changeUpdating({ isUpdating: false, id: fileId }));
  }

  return true;
};

export const uploadFileNewVersion = (
  dropboxId: string,
  fileId: string,
  file: File,
  filename: string,
  description?: string,
  uploadedBy?: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new FileApi(getDropboxConfiguration()).addFileVersion(
      fileId,
      file,
      uploadedBy,
      filename,
      description,
    );
    dispatch(fetchDropbox(dropboxId));
    return true;
  } catch (e) {
    dispatch(showErrorToast('Unable to upload new version of the document'));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to upload new version of the document',
      e,
      {
        file: {
          dropboxId,
          fileId,
        },
      },
    );
    return false;
  }
};

export const downloadDropboxFileAndUploadToChecklistItem = (
  file: FileResponse,
  checklistItemId: string,
  transactionId: string,
  authUserId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data: fileUrl } = await new FileApi(
      getDropboxConfiguration(),
    ).getFileVersionUrl(file.id!, file.currentVersion?.id!);

    const fetchedFileData = await performFetch(fileUrl);
    const fileBlob = await fetchedFileData.blob();
    const fileToUpload = new File([fileBlob], file.filename!);

    await new ChecklistApi(getSherlockConfiguration()).uploadNewDocument(
      checklistItemId,
      file.filename!,
      '',
      authUserId,
      fileToUpload,
      transactionId,
    );
  } catch (e) {
    dispatch(
      showErrorToast(
        `Unable to upload ${file.filename} to checklist item`,
        'Something went wrong. Please try again in a few moments.',
      ),
    );

    ErrorService.notify(
      'Unable to download & upload a dropbox file to checklist item',
      e,
      {
        file,
        data: { checklistItemId, transactionId, authUserId },
      },
    );

    return false;
  }

  return true;
};

export const downloadDropboxFileAndUploadAsNewVersion = (
  newfile: FileResponse,
  fileToUpdateId: string,
  dropboxId: string,
  authUserId: string,
  name: string,
  description: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data: fileUrl } = await new FileApi(
      getDropboxConfiguration(),
    ).getFileVersionUrl(newfile.id!, newfile.currentVersion?.id!);

    const fetchedFileData = await performFetch(fileUrl);
    const fileBlob = await fetchedFileData.blob();
    const fileToUpload = new File([fileBlob], newfile.filename!);

    await dispatch(
      uploadFileNewVersion(
        dropboxId!,
        fileToUpdateId!,
        fileToUpload,
        name,
        description,
        authUserId!,
      ),
    );
  } catch (e) {
    dispatch(
      showErrorToast(
        `Unable to upload a dropbox file to checklist item as new version`,
        'Something went wrong. Please try again in a few moments.',
      ),
    );

    ErrorService.notifyIgnoreHandled(
      'Unable to download & upload a dropbox file to checklist item as new version',
      e,
      {
        newfile,
        data: { dropboxId, fileToUpdateId },
      },
    );

    return false;
  }

  return true;
};

export const downloadDropboxFileByVersion = (
  fileId: string,
  versionId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data: fileUrl } = await new FileApi(
      getDropboxConfiguration(),
    ).getFileVersionUrl(fileId!, versionId!);

    const newTab = window.open(fileUrl, '_blank');
    if (newTab) {
      newTab?.focus?.();
    } else {
      dispatch(
        showErrorToast(
          'Pops are blocked by your browser. Please allow popups for this site.',
        ),
      );
    }
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled('Unable to download a file', e, {
      file: {
        fileId,
        versionId,
      },
    });
  }
};

export const fetchGlobalDropbox = (): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    const { data } = await new GlobalDropboxApi(
      getDropboxConfiguration(),
    ).getGlobalDropbox();
    dispatch(saveGlobalDropbox(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled('Unable to fetch global dropbox', e);
  }
};

export const downloadAndUploadFilesToDropbox = (
  files: FileResponse[],
  dropboxId: string,
  userId: string,
): AppThunk<Promise<(FileResponse | undefined)[] | undefined>> => async (
  dispatch,
) => {
  try {
    const data = await Promise.all(
      files?.map(async (file) => {
        const { data: fileUrl } = await new FileApi(
          getDropboxConfiguration(),
        ).getFileVersionUrl(file?.id!, file?.currentVersion?.id!);

        const fetchedFileData = await performFetch(fileUrl);
        const fileBlob = await fetchedFileData.blob();
        const fileToUpload = new File([fileBlob], file.filename!);

        const fileResponse = await dispatch(
          uploadFile(dropboxId, userId, fileToUpload),
        );

        return fileResponse;
      }),
    );

    return data;
  } catch (e) {
    dispatch(
      showErrorToast(
        'Something went wrong while uploading the files to the File Cabinet',
        'Please try again after sometime.',
      ),
    );
    ErrorService.notify(
      'Unable to download & upload the files to the dropbox',
      e,
      {
        files,
        data: { dropboxId, userId },
      },
    );

    return undefined;
  }
};

export default DropboxSlice.reducer;
