import { DateTime } from 'luxon';
import { ReactDatePickerProps } from 'react-datepicker';
import { GooglePlaceLocationType } from '../../components/ControlledGoogleAutocompleteSearchInputV7';
import { FieldObject } from '../../openapi/atlantis';
import { getAddressFromAddressComponent } from '../TransactionHelper';
import { Validation } from './MortgageApplicationFormUtils';

export type FieldValidationType = {
  type: string;
  value: string;
};

export const getInputValidations = (field: FieldObject): Validation => {
  let validations: Validation = {};

  if (field.required) {
    validations.required = 'Required';
  }

  for (let validation of field.validations || []) {
    if (validation.type === 'regex') {
      validations.pattern = {
        //@ts-ignore
        value: new RegExp(validation.value),
        //@ts-ignore
        message: validation.message,
      };
    }
  }

  return validations;
};

export const getDatePickerConfig = (
  field: FieldObject,
): Omit<
  ReactDatePickerProps,
  'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
> => {
  let config: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  > = {};

  field.validations?.forEach((validation: any) => {
    const date = DateTime.fromISO(validation.effectiveValue);

    if (date.isValid) {
      if (validation.type === 'minDate') {
        config.minDate = date.toJSDate();
      }

      if (validation.type === 'maxDate') {
        config.maxDate = date.toJSDate();
      }
    }
  });

  return config;
};

export const getGoogleAutoCompleteInputValidations = (
  field: FieldObject,
): Validation => {
  return {
    validate: (value: GooglePlaceLocationType | undefined) => {
      if (field.required && !value?.formatted_address) {
        return 'Required';
      }

      if (value?.place_id) {
        const address = getAddressFromAddressComponent(
          value?.address_components!,
        );

        // Get the list of address component validations from the field validations
        const requiredAddressFieldValidation = [
          ...((field?.validations || []) as FieldValidationType[]),
        ].filter((validation) => validation.type === 'addressComponent');

        // Check if any required address component is missing or invalid
        const areFieldValidationsInvalid = requiredAddressFieldValidation
          .filter((v) => address.hasOwnProperty(v.value))
          .some((v) => !address[v.value as keyof typeof address]);

        if (areFieldValidationsInvalid) {
          return 'Please enter a valid address';
        }
      }

      // Return undefined if all validations pass
      return undefined;
    },
  };
};
