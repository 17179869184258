import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { SearchApi } from '../../../openapi/leo';
import ErrorService from '../../../services/ErrorService';
import { getLeoConfiguration } from '../../../utils/OpenapiConfigurationUtils';

interface NeoLeoSearchResult {
  category?: string;
  category_title?: string;
  items: NeoLeoSearchSuggestion[];
}

export interface NeoLeoSearchSuggestion {
  title?: string;
  description?: string;
  url?: string;
  prompt?: string;
  action_type?: string;
}

const useNeoLeoSearchSuggestions = (
  askLeoText?: string,
): NeoLeoSearchSuggestion[] => {
  const [suggestions, setSuggestions] = useState<NeoLeoSearchSuggestion[]>([]);

  const fetchSearchSuggestions = useCallback(async () => {
    try {
      const { data } = await new SearchApi(
        getLeoConfiguration(),
      ).searchSearchGet(askLeoText);

      const filteredSuggestions = [...(data?.results || [])]
        .filter((r: NeoLeoSearchResult) => r?.category === 'PROMPT_SUGGESTIONS')
        .flatMap((r: NeoLeoSearchResult) => r?.items);

      setSuggestions(filteredSuggestions);
    } catch (error) {
      ErrorService.notifyIgnoreAuthErrors(
        'Error fetching search suggestions',
        error,
      );
    }
  }, [askLeoText]);

  // Debounce the fetch function to limit API calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSearchSuggestions = useCallback(
    debounce(fetchSearchSuggestions, 500),
    [fetchSearchSuggestions],
  );
  useEffect(() => {
    if (askLeoText) {
      debouncedFetchSearchSuggestions();
    }
  }, [askLeoText, debouncedFetchSearchSuggestions]);

  return suggestions;
};

export default useNeoLeoSearchSuggestions;
