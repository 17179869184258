import { faPen, faPhoneArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import {
  faCheck,
  faInfoCircle,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import {
  ParticipantValue,
  PaymentParticipantValue,
} from '../../openapi/arrakis';
import {
  CreateVoiceCallRequest,
  CreateVoiceCallRequestContainerTypeEnum,
  CreateVoiceCallRequestDirectionEnum,
  CreateVoiceCallRequestTargetTypeEnum,
} from '../../openapi/yada';
import { AppDispatch, RootState } from '../../types';
import { saveUserDetail } from '../../slices/AuthSlice';
import { getFullName } from '../../utils/AgentHelper';
import { cn } from '../../utils/classUtils';
import {
  useCreateVoiceCall,
  useOptInSms,
  usePatchVoiceCall,
  useSmsDialNumber,
} from '../../query/roar/useRoar';
import { formatPhoneNumber } from '../../utils/StringUtils';
import { PHONE_NUMBER_VALIDATIONS } from '../../utils/Validations';
import BrokerOnly from '../auth/BrokerOnly';
import Avatar from '../Avatar';
import ZenControlledPhoneNumberInput from '../Zen/Input/ZenControlledPhoneNumberInput';
import ZenSimpleModal from '../Zen/Modal/ZenSimpleModal';
import ZenButton from '../Zen/ZenButton';
import CallNumberLoader from './CallNumberLoader';
import CopyCallNumber from './CopyCallNumber';
import OptInModal from './OptinModal';
import SuccessModal from './SuccessModal';

interface CallModalProps {
  onClose(): void;
  participant?: ParticipantValue | PaymentParticipantValue | undefined;
}

interface IFormData {
  phoneNumber: string;
}

const CallModal: React.FC<CallModalProps> = ({ onClose, participant }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isEditing, setIsEditing] = useState(false);
  const [showOptInModal, setShowOptInModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [phoneNo, setPhoneNo] = useState(participant?.phoneNumber);

  const {
    transaction: { commentsMentionUsers, transactionDetailResponse },
    auth: { userDetail, isBroker },
    userIds: { agentById },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const phoneNumber = agentById[participant?.yentaId!]?.phoneNumber;
    if (phoneNumber) {
      setPhoneNo(phoneNumber);
    }
  }, [participant]);
  const transactionId = transactionDetailResponse.data?.id!;

  const { control, handleSubmit, getValues, setValue } = useForm<IFormData>({
    defaultValues: {
      phoneNumber: participant?.phoneNumber,
    },
  });
  const phoneNumber = getValues('phoneNumber');
  const optedIntoSms = userDetail?.optedIntoSms;
  const groupId = commentsMentionUsers?.mentionableGroups?.find(
    (v) => v.groupName === 'Broker Team',
  )?.groupId!;
  const authUserId = userDetail?.id!;

  const createVoiceCallPayload: CreateVoiceCallRequest = isBroker
    ? {
        containerId: transactionId,
        targetId: participant?.yentaId!,
        containerType: CreateVoiceCallRequestContainerTypeEnum.Transaction,
        direction: CreateVoiceCallRequestDirectionEnum.Outbound,
        targetType: CreateVoiceCallRequestTargetTypeEnum.User,
        callerGroupId: groupId,
      }
    : {
        containerId: transactionId,
        targetId: groupId,
        containerType: CreateVoiceCallRequestContainerTypeEnum.Transaction,
        direction: CreateVoiceCallRequestDirectionEnum.Inbound,
        targetType: CreateVoiceCallRequestTargetTypeEnum.Group,
      };

  const { data: originalVoiceCall, isLoading } = useCreateVoiceCall(
    participant?.id!,
    createVoiceCallPayload,
  );

  const {
    mutate: smsDialNumber,
    isLoading: isSendingSms,
    isSuccess: isSmsSent,
  } = useSmsDialNumber();

  const { mutate: optIntoSms, isLoading: isOptingIntoSms } = useOptInSms(
    authUserId,
  );
  const {
    mutate: patchVoiceCall,
    isLoading: isUpdatingPhoneNumber,
    data: updatedVoiceCall,
    isSuccess: isSucessPatchVoiceCall,
  } = usePatchVoiceCall(participant?.id!);

  const voiceCallResponse = updatedVoiceCall || originalVoiceCall;
  const voiceCallId = voiceCallResponse?.id;

  const handleTextMeThisNumber = () => {
    if (optedIntoSms) {
      // @ts-expect-error
      smsDialNumber(voiceCallId);
    } else {
      setShowOptInModal(true);
    }
  };

  const handleOptIntoSms = () => {
    optIntoSms(authUserId!, {
      onSuccess: (userResponse) => {
        if (userResponse.optedIntoSms) {
          dispatch(
            saveUserDetail({
              ...userDetail,
              optedIntoSms: userResponse.optedIntoSms,
            }),
          );
          setShowOptInModal(false);
          setShowSuccessModal(true);
          // @ts-expect-error
          smsDialNumber(voiceCallId);
        }
      },
    });
  };

  const onSubmit = (values: IFormData) => {
    if (isUpdatingPhoneNumber) {
      return;
    }
    patchVoiceCall(
      {
        // @ts-expect-error
        id: voiceCallId,
        phoneNumber: values.phoneNumber,
      },
      {
        onSettled: () => {
          setIsEditing(false);
        },
      },
    );
  };

  const participantName = isBroker ? getFullName(participant) : 'Broker Team';

  useEffect(() => {
    if (isSucessPatchVoiceCall) {
      setPhoneNo(phoneNumber);
    }
  }, [isSucessPatchVoiceCall, phoneNumber]);

  return (
    <>
      <ZenSimpleModal
        size='large'
        hideHeader
        isOpen
        overflowY={false}
        onClose={onClose}
      >
        <div className='relative pt-8 pb-4 px-8 font-inter '>
          <div
            onClick={onClose}
            className='absolute bg-zen-dark-10 h-6 w-6 text-white rounded-full right-2 top-2 flex justify-center items-center'
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
          <div className='flex items-center flex-col'>
            <div className='w-full gap-1 flex items-center flex-col'>
              <div
                className={cn(
                  'relative',
                  !isBroker &&
                    'bg-[#DBE9FF] w-10 h-10 rounded-full flex items-center justify-center',
                )}
              >
                <FontAwesomeIcon
                  icon={faPhoneArrowUpRight}
                  className='p-1 border absolute -right-1 -top-1 bg-white text-rezen-blue-600 rounded-full'
                  fontSize={9}
                />
                {isBroker ? (
                  <Avatar
                    name={participantName!}
                    size='sm'
                    imageUrl={agentById[participant?.yentaId!]?.avatar}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faUsers}
                    size='sm'
                    className='text-primary-blue'
                  />
                )}
              </div>
              <div className='text-gray-600 text-sm font-normal'>
                {participantName}
              </div>
            </div>
            <div className='w-full font-medium bg-regent-100 rounded-lg py-2.5 my-4'>
              {!isLoading ? (
                <div className='flex items-start'>
                  <div className='flex-1 flex flex-col items-center'>
                    <div className='pt-2 text-base text-primary-dark'>
                      {voiceCallResponse?.virtualNumber
                        ? // @ts-expect-error
                          formatPhoneNumber(voiceCallResponse.virtualNumber)
                        : 'N/A'}
                    </div>
                    <div className='pb-2 pt-1 text-base flex'>
                      <span className='text-grey-500'>Call Code:</span>{' '}
                      <span className='text-primary-dark block ml-1'>
                        {voiceCallResponse?.callCode
                          ? voiceCallResponse.callCode
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                  {!!voiceCallResponse?.virtualNumber && (
                    <CopyCallNumber
                      label='Copy'
                      value={voiceCallResponse?.dialNumber}
                    />
                  )}
                </div>
              ) : (
                <CallNumberLoader />
              )}
            </div>
            <ZenButton
              variant='primary-outline'
              label={isSmsSent ? 'Text message sent' : 'Text Me This Number'}
              isFullWidth
              isDisabled={
                isSendingSms || isSmsSent || isEmpty(voiceCallResponse)
              }
              disabledClassName='disabled:bg-transparent disabled:text-zen-dark-6'
              onClick={handleTextMeThisNumber}
              isSubmitting={isSendingSms}
            />
            <div className='w-full relative bg-regent-100 rounded-md my-4 flex justify-center space-x-3 p-3'>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className='mt-1 text-rezen-blue-600'
              />
              <div className='text-grey-600 text-sm font-light'>
                {!isSmsSent
                  ? 'A text will be sent to your business phone number with the details above to make the call.'
                  : `This number was texted to your business phone number 
              ${formatPhoneNumber(userDetail?.phoneNumber)}`}
              </div>
            </div>
            <BrokerOnly>
              <div className='h-[1px] w-full bg-grey-200 mb-2' />
              <div className='flex flex-wrap justify-between w-full'>
                <div className='mb-1 w-full text-grey-600 font-normal font-inter'>
                  Agent&apos;s Phone Number
                </div>
                {isEditing ? (
                  <div className='flex w-full'>
                    <ZenControlledPhoneNumberInput<IFormData, 'phoneNumber'>
                      control={control}
                      name='phoneNumber'
                      placeholder='+1 (702) 123-4567'
                      rules={{
                        required: 'Please enter your phone number',
                        ...PHONE_NUMBER_VALIDATIONS,
                      }}
                      shouldUnregister={false}
                    />
                    <FontAwesomeIcon
                      onClick={() => {
                        setValue('phoneNumber', phoneNo!);
                        setIsEditing(false);
                      }}
                      icon={faXmark}
                      title='cancel'
                      className={cn(
                        'py-1 mt-2 px-2 text-error',
                        isUpdatingPhoneNumber && 'text-grey-300',
                      )}
                      fontSize={14}
                    />
                    <FontAwesomeIcon
                      onClick={handleSubmit(onSubmit)}
                      icon={faCheck}
                      title='submit'
                      className={cn(
                        'py-1 mt-2 px-2 text-green-600',
                        isUpdatingPhoneNumber && 'text-grey-300',
                      )}
                      fontSize={14}
                    />
                  </div>
                ) : (
                  <>
                    <div className=' w-6/12 text-grey-600 font-light'>
                      {formatPhoneNumber(phoneNo) || 'N/A'}
                    </div>
                    <button
                      className='cursor-pointer text-rezen-blue-600'
                      onClick={() => setIsEditing(true)}
                      disabled={!voiceCallId}
                    >
                      <FontAwesomeIcon icon={faPen} fontSize={14} /> Edit
                    </button>
                  </>
                )}
              </div>
            </BrokerOnly>
          </div>
        </div>
      </ZenSimpleModal>

      {showOptInModal && (
        <OptInModal
          onClose={() => {
            setShowOptInModal(false);
          }}
          onOptIn={handleOptIntoSms}
          disabled={isOptingIntoSms}
        />
      )}
      {showSuccessModal && (
        <SuccessModal onClose={() => setShowSuccessModal(false)} />
      )}
    </>
  );
};

export default CallModal;
