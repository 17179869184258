import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy, map } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import { displayDate } from '../../utils/DateUtils';
import NeoLeoHistoryByDay from './NeoLeoHistoryByDayCard';

type NeoLeoHistoryIndexProps = {
  hideHistory(): void;
};

export interface SessionHistory {
  id: string;
  agent_id: string;
  title?: string;
  system_message: string;
  start_time: string;
  end_time?: string;
}

const NeoLeoHistoryIndex: React.FC<NeoLeoHistoryIndexProps> = ({
  hideHistory,
}) => {
  const { chatHistory } = useSelector((state: RootState) => state.leo);
  const chatHistoryLength = chatHistory?.length;
  const sortedChatHistory = [...(chatHistory || [])]
    .sort((a, b) => {
      const aTime = DateTime.fromISO(a.start_time).toMillis();
      const bTime = DateTime.fromISO(b.start_time).toMillis();
      if (aTime < bTime) {
        return 1;
      } else if (aTime > bTime) {
        return -1;
      }
      return 0;
    })
    .map((el, ind) => ({
      ...el,
      title: el.title ?? `Session ${chatHistoryLength! - ind}`,
    }));
  const historyGroupedByDay = groupBy(sortedChatHistory, (el) =>
    displayDate(el.start_time),
  );
  return (
    <div className='h-full pt-2'>
      <div
        className='flex items-center text-aqua px-4 pt-2 pb-3 cursor-pointer'
        onClick={hideHistory}
      >
        <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
        <p className='text-sm font-normal font-inter'>Go Back</p>
      </div>
      <div className='px-3 mb-3 overflow-y-scroll h-[calc(100vh-140px)] scrollbar-hide'>
        {!!chatHistoryLength && (
          <div>
            {map(historyGroupedByDay, (history) => (
              <NeoLeoHistoryByDay
                key={history.map((h) => h.id).join(',')}
                history={history}
                hideHistory={hideHistory}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NeoLeoHistoryIndex;
