/* tslint:disable */
/* eslint-disable */
/**
 * Wallet API
 * Wallet Main Application
 *
 * The version of the OpenAPI document: 4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': AddressCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'stateOrProvince': AddressStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipOrPostalCode': string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressCountryEnum {
    UnitedStates = 'UNITED_STATES',
    Canada = 'CANADA'
}
/**
    * @export
    * @enum {string}
    */
export enum AddressStateOrProvinceEnum {
    Alabama = 'ALABAMA',
    Alaska = 'ALASKA',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    California = 'CALIFORNIA',
    Colorado = 'COLORADO',
    Connecticut = 'CONNECTICUT',
    Delaware = 'DELAWARE',
    DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
    Florida = 'FLORIDA',
    Georgia = 'GEORGIA',
    Hawaii = 'HAWAII',
    Idaho = 'IDAHO',
    Illinois = 'ILLINOIS',
    Indiana = 'INDIANA',
    Iowa = 'IOWA',
    Kansas = 'KANSAS',
    Kentucky = 'KENTUCKY',
    Louisiana = 'LOUISIANA',
    Maine = 'MAINE',
    Maryland = 'MARYLAND',
    Massachusetts = 'MASSACHUSETTS',
    Michigan = 'MICHIGAN',
    Minnesota = 'MINNESOTA',
    Mississippi = 'MISSISSIPPI',
    Missouri = 'MISSOURI',
    Montana = 'MONTANA',
    Nebraska = 'NEBRASKA',
    Nevada = 'NEVADA',
    NewHampshire = 'NEW_HAMPSHIRE',
    NewJersey = 'NEW_JERSEY',
    NewMexico = 'NEW_MEXICO',
    NewYork = 'NEW_YORK',
    NorthCarolina = 'NORTH_CAROLINA',
    NorthDakota = 'NORTH_DAKOTA',
    Ohio = 'OHIO',
    Oklahoma = 'OKLAHOMA',
    Oregon = 'OREGON',
    Pennsylvania = 'PENNSYLVANIA',
    RhodeIsland = 'RHODE_ISLAND',
    SouthCarolina = 'SOUTH_CAROLINA',
    SouthDakota = 'SOUTH_DAKOTA',
    Tennessee = 'TENNESSEE',
    Texas = 'TEXAS',
    Utah = 'UTAH',
    Vermont = 'VERMONT',
    Virginia = 'VIRGINIA',
    Washington = 'WASHINGTON',
    WestVirginia = 'WEST_VIRGINIA',
    Wisconsin = 'WISCONSIN',
    Wyoming = 'WYOMING',
    PuertoRico = 'PUERTO_RICO',
    Alberta = 'ALBERTA',
    BritishColumbia = 'BRITISH_COLUMBIA',
    Manitoba = 'MANITOBA',
    NewBrunswick = 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia = 'NOVA_SCOTIA',
    Ontario = 'ONTARIO',
    PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
    Quebec = 'QUEBEC',
    Saskatchewan = 'SASKATCHEWAN'
}

/**
 * 
 * @export
 * @interface ApplicantDetails
 */
export interface ApplicantDetails {
    /**
     * 
     * @type {string}
     * @memberof ApplicantDetails
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantDetails
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantDetails
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {Address}
     * @memberof ApplicantDetails
     */
    'billingAddress': Address;
    /**
     * 
     * @type {Address}
     * @memberof ApplicantDetails
     */
    'shippingAddress': Address;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof ApplicantDetails
     */
    'phoneNumber': PhoneNumber;
    /**
     * 
     * @type {EmailAddress}
     * @memberof ApplicantDetails
     */
    'emailAddress': EmailAddress;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof ApplicantDetails
     */
    'nationalIdentifications': Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof ApplicantDetails
     */
    'website'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationDto
 */
export interface ApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'bankingPartnerApplicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'accountApplicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'partner'?: ApplicationDtoPartnerEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'externalApplicationFormId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'formUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'userApplicationType'?: ApplicationDtoUserApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'signatureDocumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'status'?: ApplicationDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'cardDesign'?: ApplicationDtoCardDesignEnum;
    /**
     * 
     * @type {Address}
     * @memberof ApplicationDto
     */
    'shippingAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDto
     */
    'type'?: ApplicationDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationDtoPartnerEnum {
    Unit = 'UNIT'
}
/**
    * @export
    * @enum {string}
    */
export enum ApplicationDtoUserApplicationTypeEnum {
    Individual = 'INDIVIDUAL',
    SoleProprietor = 'SOLE_PROPRIETOR',
    Business = 'BUSINESS'
}
/**
    * @export
    * @enum {string}
    */
export enum ApplicationDtoStatusEnum {
    AppFormSent = 'APP_FORM_SENT',
    Submitted = 'SUBMITTED',
    PartnershipAwaitingDocuments = 'PARTNERSHIP_AWAITING_DOCUMENTS',
    PartnershipPendingReview = 'PARTNERSHIP_PENDING_REVIEW',
    Approved = 'APPROVED',
    Denied = 'DENIED',
    Canceled = 'CANCELED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApplicationDtoCardDesignEnum {
    Default = 'REAL_DEFAULT',
    Black = 'REAL_BLACK',
    White = 'REAL_WHITE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApplicationDtoTypeEnum {
    Debit = 'DEBIT',
    Credit = 'CREDIT'
}

/**
 * 
 * @export
 * @interface BankAccountDto
 */
export interface BankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof BankAccountDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDto
     */
    'type'?: BankAccountDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDto
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDto
     */
    'accountApplicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDto
     */
    'status'?: BankAccountDtoStatusEnum;
    /**
     * 
     * @type {Array<CardDto>}
     * @memberof BankAccountDto
     */
    'cards'?: Array<CardDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum BankAccountDtoTypeEnum {
    Debit = 'DEBIT',
    Credit = 'CREDIT'
}
/**
    * @export
    * @enum {string}
    */
export enum BankAccountDtoStatusEnum {
    Open = 'OPEN',
    Frozen = 'FROZEN',
    Closed = 'CLOSED'
}

/**
 * 
 * @export
 * @interface BankingPartnerProduct
 */
export interface BankingPartnerProduct {
    /**
     * 
     * @type {string}
     * @memberof BankingPartnerProduct
     */
    'partner': BankingPartnerProductPartnerEnum;
    /**
     * 
     * @type {string}
     * @memberof BankingPartnerProduct
     */
    'id': string;
}

/**
    * @export
    * @enum {string}
    */
export enum BankingPartnerProductPartnerEnum {
    Unit = 'UNIT'
}

/**
 * 
 * @export
 * @interface BankingPartnershipDto
 */
export interface BankingPartnershipDto {
    /**
     * 
     * @type {string}
     * @memberof BankingPartnershipDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<BankAccountDto>}
     * @memberof BankingPartnershipDto
     */
    'accounts'?: Array<BankAccountDto>;
    /**
     * 
     * @type {string}
     * @memberof BankingPartnershipDto
     */
    'bankingPartnerApplicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankingPartnershipDto
     */
    'partner'?: BankingPartnershipDtoPartnerEnum;
    /**
     * 
     * @type {string}
     * @memberof BankingPartnershipDto
     */
    'customerId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BankingPartnershipDtoPartnerEnum {
    Unit = 'UNIT'
}

/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface CardDto
 */
export interface CardDto {
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    'type'?: CardDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    'status'?: CardDtoStatusEnum;
    /**
     * 
     * @type {CardDtoExpirationDate}
     * @memberof CardDto
     */
    'expirationDate'?: CardDtoExpirationDate;
    /**
     * 
     * @type {CardDtoExpirationDate}
     * @memberof CardDto
     */
    'reissuedCardExpirationDate'?: CardDtoExpirationDate;
    /**
     * 
     * @type {boolean}
     * @memberof CardDto
     */
    'pinNeeded'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CardDto
     */
    'chargeAutoApprovalLimit'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CardDto
     */
    'chargeAutoApprovalDailyLimit'?: MoneyValue;
}

/**
    * @export
    * @enum {string}
    */
export enum CardDtoTypeEnum {
    Debit = 'DEBIT',
    Credit = 'CREDIT',
    Charge = 'CHARGE'
}
/**
    * @export
    * @enum {string}
    */
export enum CardDtoStatusEnum {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
    Frozen = 'FROZEN',
    SuspectedOfFraud = 'SUSPECTED_OF_FRAUD',
    Expired = 'EXPIRED',
    Lost = 'LOST',
    Stolen = 'STOLEN',
    Closed = 'CLOSED'
}

/**
 * 
 * @export
 * @interface CardDtoExpirationDate
 */
export interface CardDtoExpirationDate {
    /**
     * 
     * @type {number}
     * @memberof CardDtoExpirationDate
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof CardDtoExpirationDate
     */
    'month'?: CardDtoExpirationDateMonthEnum;
    /**
     * 
     * @type {number}
     * @memberof CardDtoExpirationDate
     */
    'monthValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CardDtoExpirationDate
     */
    'leapYear'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CardDtoExpirationDateMonthEnum {
    January = 'JANUARY',
    February = 'FEBRUARY',
    March = 'MARCH',
    April = 'APRIL',
    May = 'MAY',
    June = 'JUNE',
    July = 'JULY',
    August = 'AUGUST',
    September = 'SEPTEMBER',
    October = 'OCTOBER',
    November = 'NOVEMBER',
    December = 'DECEMBER'
}

/**
 * 
 * @export
 * @interface ChargeAuthorizationRequestDto
 */
export interface ChargeAuthorizationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChargeAuthorizationRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof ChargeAuthorizationRequestDto
     */
    'amount'?: MoneyValue;
    /**
     * 
     * @type {PayeeDetails}
     * @memberof ChargeAuthorizationRequestDto
     */
    'payee'?: PayeeDetails;
    /**
     * 
     * @type {string}
     * @memberof ChargeAuthorizationRequestDto
     */
    'requestType'?: ChargeAuthorizationRequestDtoRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChargeAuthorizationRequestDto
     */
    'status'?: ChargeAuthorizationRequestDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ChargeAuthorizationRequestDto
     */
    'declineReason'?: ChargeAuthorizationRequestDtoDeclineReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ChargeAuthorizationRequestDto
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChargeAuthorizationRequestDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ChargeAuthorizationRequestDtoRequestTypeEnum {
    Purchase = 'PURCHASE',
    CardTransaction = 'CARD_TRANSACTION',
    Atm = 'ATM'
}
/**
    * @export
    * @enum {string}
    */
export enum ChargeAuthorizationRequestDtoStatusEnum {
    Pending = 'PENDING',
    UserReviewRequested = 'USER_REVIEW_REQUESTED',
    ApprovedByUser = 'APPROVED_BY_USER',
    DeclinedByUser = 'DECLINED_BY_USER',
    Approved = 'APPROVED',
    Declined = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum ChargeAuthorizationRequestDtoDeclineReasonEnum {
    AccountClosed = 'ACCOUNT_CLOSED',
    ChargeExceedsAllowedAmount = 'CHARGE_EXCEEDS_ALLOWED_AMOUNT',
    DoNotHonor = 'DO_NOT_HONOR',
    InsufficientFunds = 'INSUFFICIENT_FUNDS',
    InvalidMerchant = 'INVALID_MERCHANT',
    ReferToCardIssuer = 'REFER_TO_CARD_ISSUER',
    RestrictedCard = 'RESTRICTED_CARD',
    TransactionNotPermittedToCardHolder = 'TRANSACTION_NOT_PERMITTED_TO_CARD_HOLDER'
}

/**
 * 
 * @export
 * @interface CreateCashAdvance
 */
export interface CreateCashAdvance {
    /**
     * 
     * @type {string}
     * @memberof CreateCashAdvance
     */
    'lendingAccountId': string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCashAdvance
     */
    'amount': MoneyValue;
}
/**
 * 
 * @export
 * @interface CreateCreditAccountApplication
 */
export interface CreateCreditAccountApplication {
    /**
     * 
     * @type {string}
     * @memberof CreateCreditAccountApplication
     */
    'bankingPartner': CreateCreditAccountApplicationBankingPartnerEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditAccountApplication
     */
    'userApplicationType': CreateCreditAccountApplicationUserApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditAccountApplication
     */
    'signatureDocumentId': string;
    /**
     * 
     * @type {ApplicantDetails}
     * @memberof CreateCreditAccountApplication
     */
    'applicantDetails': ApplicantDetails;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditAccountApplication
     */
    'cardDesign': CreateCreditAccountApplicationCardDesignEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditAccountApplication
     */
    'creditOfferingId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateCreditAccountApplicationBankingPartnerEnum {
    Unit = 'UNIT'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateCreditAccountApplicationUserApplicationTypeEnum {
    Individual = 'INDIVIDUAL',
    SoleProprietor = 'SOLE_PROPRIETOR',
    Business = 'BUSINESS'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateCreditAccountApplicationCardDesignEnum {
    Default = 'REAL_DEFAULT',
    Black = 'REAL_BLACK',
    White = 'REAL_WHITE'
}

/**
 * 
 * @export
 * @interface CreateCustomerToken
 */
export interface CreateCustomerToken {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCustomerToken
     */
    'scopes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerToken
     */
    'bankingPartnershipId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCustomerToken
     */
    'upgradableScopes'?: Array<string>;
    /**
     * 
     * @type {Mfa}
     * @memberof CreateCustomerToken
     */
    'mfa'?: Mfa;
}
/**
 * 
 * @export
 * @interface CreateDebitAccountApplication
 */
export interface CreateDebitAccountApplication {
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAccountApplication
     */
    'bankingPartner': CreateDebitAccountApplicationBankingPartnerEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAccountApplication
     */
    'userApplicationType': CreateDebitAccountApplicationUserApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAccountApplication
     */
    'signatureDocumentId': string;
    /**
     * 
     * @type {ApplicantDetails}
     * @memberof CreateDebitAccountApplication
     */
    'applicantDetails': ApplicantDetails;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAccountApplication
     */
    'cardDesign': CreateDebitAccountApplicationCardDesignEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDebitAccountApplication
     */
    'debitOfferingId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateDebitAccountApplicationBankingPartnerEnum {
    Unit = 'UNIT'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateDebitAccountApplicationUserApplicationTypeEnum {
    Individual = 'INDIVIDUAL',
    SoleProprietor = 'SOLE_PROPRIETOR',
    Business = 'BUSINESS'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateDebitAccountApplicationCardDesignEnum {
    Default = 'REAL_DEFAULT',
    Black = 'REAL_BLACK',
    White = 'REAL_WHITE'
}

/**
 * 
 * @export
 * @interface CreateInternalRepayment
 */
export interface CreateInternalRepayment {
    /**
     * 
     * @type {string}
     * @memberof CreateInternalRepayment
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInternalRepayment
     */
    'accountId': string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateInternalRepayment
     */
    'amount': MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof CreateInternalRepayment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInternalRepayment
     */
    'sourceType': CreateInternalRepaymentSourceTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateInternalRepaymentSourceTypeEnum {
    Check = 'CHECK',
    RevShare = 'REV_SHARE',
    BankTransfer = 'BANK_TRANSFER'
}

/**
 * 
 * @export
 * @interface CreateMfaToken
 */
export interface CreateMfaToken {
    /**
     * 
     * @type {string}
     * @memberof CreateMfaToken
     */
    'mfaChannel': CreateMfaTokenMfaChannelEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateMfaToken
     */
    'bankingPartnershipId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateMfaTokenMfaChannelEnum {
    Sms = 'SMS',
    Call = 'CALL'
}

/**
 * 
 * @export
 * @interface CreateRealLendingAccount
 */
export interface CreateRealLendingAccount {
    /**
     * 
     * @type {string}
     * @memberof CreateRealLendingAccount
     */
    'creditOfferingId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRealLendingAccount
     */
    'signatureDocumentId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRealLendingAccount
     */
    'dueDay'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRealLendingAccount
     */
    'accountName'?: string;
}
/**
 * 
 * @export
 * @interface CreateWallet
 */
export interface CreateWallet {
    /**
     * 
     * @type {string}
     * @memberof CreateWallet
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface CreditApplicationDto
 */
export interface CreditApplicationDto extends ApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof CreditApplicationDto
     */
    'creditOfferingId'?: string;
}


/**
 * 
 * @export
 * @interface CreditApplicationDtoAllOf
 */
export interface CreditApplicationDtoAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreditApplicationDtoAllOf
     */
    'creditOfferingId'?: string;
}
/**
 * 
 * @export
 * @interface CreditOfferingDto
 */
export interface CreditOfferingDto {
    /**
     * 
     * @type {string}
     * @memberof CreditOfferingDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOfferingDto
     */
    'creditCategoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOfferingDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditOfferingDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditOfferingDto
     */
    'apr'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditOfferingDto
     */
    'minCreditLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditOfferingDto
     */
    'maxCreditLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditOfferingDto
     */
    'minPaymentAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditOfferingDto
     */
    'penaltyApr'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditOfferingDto
     */
    'minPurchaseAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditOfferingDto
     */
    'gracePeriodDays'?: number;
    /**
     * 
     * @type {Array<FeeDto>}
     * @memberof CreditOfferingDto
     */
    'fees'?: Array<FeeDto>;
    /**
     * 
     * @type {string}
     * @memberof CreditOfferingDto
     */
    'agreementTemplateId'?: string;
    /**
     * 
     * @type {BankingPartnerProduct}
     * @memberof CreditOfferingDto
     */
    'bankingPartnerProduct'?: BankingPartnerProduct;
    /**
     * 
     * @type {string}
     * @memberof CreditOfferingDto
     */
    'status'?: CreditOfferingDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreditOfferingDtoStatusEnum {
    Active = 'ACTIVE',
    Suspended = 'SUSPENDED',
    Deleted = 'DELETED'
}

/**
 * 
 * @export
 * @interface CustomerTokenDto
 */
export interface CustomerTokenDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerTokenDto
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface DebitApplicationDto
 */
export interface DebitApplicationDto extends ApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof DebitApplicationDto
     */
    'debitOfferingId'?: string;
}


/**
 * 
 * @export
 * @interface DebitApplicationDtoAllOf
 */
export interface DebitApplicationDtoAllOf {
    /**
     * 
     * @type {string}
     * @memberof DebitApplicationDtoAllOf
     */
    'debitOfferingId'?: string;
}
/**
 * 
 * @export
 * @interface DebitOfferingDto
 */
export interface DebitOfferingDto {
    /**
     * 
     * @type {string}
     * @memberof DebitOfferingDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DebitOfferingDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DebitOfferingDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof DebitOfferingDto
     */
    'apy'?: number;
    /**
     * 
     * @type {Array<FeeDto>}
     * @memberof DebitOfferingDto
     */
    'fees'?: Array<FeeDto>;
    /**
     * 
     * @type {string}
     * @memberof DebitOfferingDto
     */
    'agreementTemplateId'?: string;
    /**
     * 
     * @type {BankingPartnerProduct}
     * @memberof DebitOfferingDto
     */
    'bankingPartnerProduct'?: BankingPartnerProduct;
    /**
     * 
     * @type {string}
     * @memberof DebitOfferingDto
     */
    'status'?: DebitOfferingDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DebitOfferingDtoStatusEnum {
    Active = 'ACTIVE',
    Deleted = 'DELETED'
}

/**
 * 
 * @export
 * @interface EligibleCreditOfferingDto
 */
export interface EligibleCreditOfferingDto {
    /**
     * 
     * @type {CreditOfferingDto}
     * @memberof EligibleCreditOfferingDto
     */
    'creditOffering'?: CreditOfferingDto;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EligibleCreditOfferingDto
     */
    'creditLimit'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface EligibleDebitOfferingDto
 */
export interface EligibleDebitOfferingDto {
    /**
     * 
     * @type {DebitOfferingDto}
     * @memberof EligibleDebitOfferingDto
     */
    'debitOffering'?: DebitOfferingDto;
}
/**
 * 
 * @export
 * @interface EligibleOfferingsDto
 */
export interface EligibleOfferingsDto {
    /**
     * 
     * @type {Array<EligibleCreditOfferingDto>}
     * @memberof EligibleOfferingsDto
     */
    'eligibleCreditOfferings'?: Array<EligibleCreditOfferingDto>;
    /**
     * 
     * @type {Array<EligibleDebitOfferingDto>}
     * @memberof EligibleOfferingsDto
     */
    'eligibleDebitOfferings'?: Array<EligibleDebitOfferingDto>;
}
/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface FeeDto
 */
export interface FeeDto {
    /**
     * 
     * @type {string}
     * @memberof FeeDto
     */
    'type'?: FeeDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeeDto
     */
    'reason'?: FeeDtoReasonEnum;
    /**
     * 
     * @type {number}
     * @memberof FeeDto
     */
    'rate'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum FeeDtoTypeEnum {
    Fixed = 'FIXED',
    Percentage = 'PERCENTAGE'
}
/**
    * @export
    * @enum {string}
    */
export enum FeeDtoReasonEnum {
    LatePayment = 'LATE_PAYMENT'
}

/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface ImportShareworksRequest
 */
export interface ImportShareworksRequest {
    /**
     * 
     * @type {File}
     * @memberof ImportShareworksRequest
     */
    'shareworksFile': File;
}
/**
 * 
 * @export
 * @interface ImportShareworksStockResult
 */
export interface ImportShareworksStockResult {
    /**
     * 
     * @type {number}
     * @memberof ImportShareworksStockResult
     */
    'fileLineCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportShareworksStockResult
     */
    'matchedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportShareworksStockResult
     */
    'updatedCount'?: number;
}
/**
 * 
 * @export
 * @interface InternalAccountDto
 */
export interface InternalAccountDto {
    /**
     * 
     * @type {string}
     * @memberof InternalAccountDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalAccountDto
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalAccountDto
     */
    'status'?: InternalAccountDtoStatusEnum;
    /**
     * 
     * @type {CreditOfferingDto}
     * @memberof InternalAccountDto
     */
    'creditOffering'?: CreditOfferingDto;
    /**
     * 
     * @type {string}
     * @memberof InternalAccountDto
     */
    'type'?: InternalAccountDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InternalAccountDtoStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Frozen = 'FROZEN',
    Closed = 'CLOSED'
}
/**
    * @export
    * @enum {string}
    */
export enum InternalAccountDtoTypeEnum {
    RealLending = 'REAL_LENDING'
}

/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface LedgerItemDto
 */
export interface LedgerItemDto {
    /**
     * 
     * @type {string}
     * @memberof LedgerItemDto
     */
    'walletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemDto
     */
    'paymentId'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof LedgerItemDto
     */
    'amount'?: MoneyValue;
    /**
     * 
     * @type {PaymentSource}
     * @memberof LedgerItemDto
     */
    'paymentSource'?: PaymentSource;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemDto
     */
    'type'?: LedgerItemDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemDto
     */
    'paymentSystem'?: LedgerItemDtoPaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof LedgerItemDto
     */
    'bankName'?: string;
    /**
     * 
     * @type {number}
     * @memberof LedgerItemDto
     */
    'createdAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LedgerItemDtoTypeEnum {
    Credit = 'CREDIT',
    Debit = 'DEBIT'
}
/**
    * @export
    * @enum {string}
    */
export enum LedgerItemDtoPaymentSystemEnum {
    Tipalti = 'TIPALTI',
    MicrosoftDynamics = 'MICROSOFT_DYNAMICS',
    MicrosoftDynamicsPaymentJournal = 'MICROSOFT_DYNAMICS_PAYMENT_JOURNAL',
    Wallet = 'WALLET'
}

/**
 * 
 * @export
 * @interface MarkCashAdvanceAsPosted
 */
export interface MarkCashAdvanceAsPosted {
    /**
     * 
     * @type {string}
     * @memberof MarkCashAdvanceAsPosted
     */
    'cashAdvanceReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof MarkCashAdvanceAsPosted
     */
    'paymentId': string;
}
/**
 * 
 * @export
 * @interface Mfa
 */
export interface Mfa {
    /**
     * 
     * @type {string}
     * @memberof Mfa
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof Mfa
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface MfaTokenDto
 */
export interface MfaTokenDto {
    /**
     * 
     * @type {string}
     * @memberof MfaTokenDto
     */
    'mfaToken'?: string;
}
/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    'currency'?: MoneyValueCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MoneyValueCurrencyEnum {
    Usd = 'USD',
    Cad = 'CAD'
}

/**
 * 
 * @export
 * @interface NationalIdentification
 */
export interface NationalIdentification {
    /**
     * 
     * @type {string}
     * @memberof NationalIdentification
     */
    'type'?: NationalIdentificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NationalIdentification
     */
    'id'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NationalIdentificationTypeEnum {
    Ssn = 'SSN',
    Ein = 'EIN',
    BAndOId = 'B_AND_O_ID',
    GrtId = 'GRT_ID',
    GetId = 'GET_ID',
    LaCbtId = 'LA_CBT_ID',
    CbjId = 'CBJ_ID',
    Bn = 'BN',
    GstId = 'GST_ID',
    PstId = 'PST_ID',
    HstId = 'HST_ID',
    QstId = 'QST_ID',
    Sin = 'SIN',
    Fnin = 'FNIN'
}

/**
 * 
 * @export
 * @interface PagedChargeAuthorizationRequestDto
 */
export interface PagedChargeAuthorizationRequestDto {
    /**
     * 
     * @type {number}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'sortDirection'?: PagedChargeAuthorizationRequestDtoSortDirectionEnum;
    /**
     * 
     * @type {Array<ChargeAuthorizationRequestDto>}
     * @memberof PagedChargeAuthorizationRequestDto
     */
    'chargeAuthorizationRequests'?: Array<ChargeAuthorizationRequestDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PagedChargeAuthorizationRequestDtoSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface PagedLedgerItemDto
 */
export interface PagedLedgerItemDto {
    /**
     * 
     * @type {number}
     * @memberof PagedLedgerItemDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedLedgerItemDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedLedgerItemDto
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagedLedgerItemDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedLedgerItemDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PagedLedgerItemDto
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PagedLedgerItemDto
     */
    'sortDirection'?: PagedLedgerItemDtoSortDirectionEnum;
    /**
     * 
     * @type {Array<LedgerItemDto>}
     * @memberof PagedLedgerItemDto
     */
    'ledgerItems'?: Array<LedgerItemDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PagedLedgerItemDtoSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface PayeeDetails
 */
export interface PayeeDetails {
    /**
     * 
     * @type {string}
     * @memberof PayeeDetails
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PayeeDetails
     */
    'mcc'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetails
     */
    'location'?: string;
}
/**
 * 
 * @export
 * @interface PaymentSource
 */
export interface PaymentSource {
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    'type': PaymentSourceTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentSourceTypeEnum {
    Commission = 'COMMISSION',
    RevenueShare = 'REVENUE_SHARE',
    CommissionAdvanceRepayment = 'COMMISSION_ADVANCE_REPAYMENT',
    DepositRelease = 'DEPOSIT_RELEASE',
    InstantPayment = 'INSTANT_PAYMENT',
    InstantCommission = 'INSTANT_COMMISSION'
}

/**
 * 
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface RealLendingAccountDto
 */
export interface RealLendingAccountDto extends InternalAccountDto {
    /**
     * 
     * @type {MoneyValue}
     * @memberof RealLendingAccountDto
     */
    'offeredAmount'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RealLendingAccountDto
     */
    'dueDay'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RealLendingAccountDto
     */
    'gracePeriodActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealLendingAccountDto
     */
    'penaltyAprActive'?: boolean;
}


/**
 * 
 * @export
 * @interface RealLendingAccountDtoAllOf
 */
export interface RealLendingAccountDtoAllOf {
    /**
     * 
     * @type {MoneyValue}
     * @memberof RealLendingAccountDtoAllOf
     */
    'offeredAmount'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RealLendingAccountDtoAllOf
     */
    'dueDay'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RealLendingAccountDtoAllOf
     */
    'gracePeriodActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealLendingAccountDtoAllOf
     */
    'penaltyAprActive'?: boolean;
}
/**
 * 
 * @export
 * @interface RealStockDto
 */
export interface RealStockDto {
    /**
     * 
     * @type {string}
     * @memberof RealStockDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealStockDto
     */
    'provider'?: RealStockDtoProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof RealStockDto
     */
    'externalId'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealStockDto
     */
    'vestedShares'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealStockDto
     */
    'unvestedShares'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealStockDto
     */
    'asOfDate'?: string;
    /**
     * 
     * @type {StockValue}
     * @memberof RealStockDto
     */
    'stockValue'?: StockValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RealStockDto
     */
    'vestedShareValue'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RealStockDto
     */
    'unvestedShareValue'?: MoneyValue;
}

/**
    * @export
    * @enum {string}
    */
export enum RealStockDtoProviderEnum {
    Shareworks = 'SHAREWORKS'
}

/**
 * 
 * @export
 * @interface SendCashAdvancePayment
 */
export interface SendCashAdvancePayment {
    /**
     * 
     * @type {string}
     * @memberof SendCashAdvancePayment
     */
    'cashAdvanceReferenceId': string;
}
/**
 * 
 * @export
 * @interface StockValue
 */
export interface StockValue {
    /**
     * 
     * @type {string}
     * @memberof StockValue
     */
    'symbol': string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof StockValue
     */
    'price': MoneyValue;
}
/**
 * 
 * @export
 * @interface TokenScope
 */
export interface TokenScope {
    /**
     * 
     * @type {string}
     * @memberof TokenScope
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TokenScope
     */
    'sensitive'?: boolean;
}
/**
 * 
 * @export
 * @interface TokenScopesDto
 */
export interface TokenScopesDto {
    /**
     * 
     * @type {Array<TokenScope>}
     * @memberof TokenScopesDto
     */
    'scopes'?: Array<TokenScope>;
}
/**
 * 
 * @export
 * @interface UpdateCardPreferences
 */
export interface UpdateCardPreferences {
    /**
     * 
     * @type {MoneyValue}
     * @memberof UpdateCardPreferences
     */
    'chargeAutoApprovalLimit'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof UpdateCardPreferences
     */
    'chargeAutoApprovalDailyLimit'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateFailedMessageStatusBatchStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface WalletDto
 */
export interface WalletDto {
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<BankingPartnershipDto>}
     * @memberof WalletDto
     */
    'bankingPartnerships'?: Array<BankingPartnershipDto>;
    /**
     * 
     * @type {Array<WalletDtoTerminalApplicationsInner>}
     * @memberof WalletDto
     */
    'terminalApplications'?: Array<WalletDtoTerminalApplicationsInner>;
    /**
     * 
     * @type {Array<WalletDtoTerminalApplicationsInner>}
     * @memberof WalletDto
     */
    'pendingApplications'?: Array<WalletDtoTerminalApplicationsInner>;
    /**
     * 
     * @type {Array<WalletDtoInternalAccountsInner>}
     * @memberof WalletDto
     */
    'internalAccounts'?: Array<WalletDtoInternalAccountsInner>;
    /**
     * 
     * @type {Array<RealStockDto>}
     * @memberof WalletDto
     */
    'realStocks'?: Array<RealStockDto>;
}
/**
 * @type WalletDtoInternalAccountsInner
 * @export
 */
export type WalletDtoInternalAccountsInner = RealLendingAccountDto;

/**
 * @type WalletDtoTerminalApplicationsInner
 * @export
 */
export type WalletDtoTerminalApplicationsInner = CreditApplicationDto | DebitApplicationDto;

/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}

/**
 * BankAccountApplicationsApi - axios parameter creator
 * @export
 */
export const BankAccountApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a credit account application for the current user
         * @param {CreateCreditAccountApplication} createCreditAccountApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditAccountApplication: async (createCreditAccountApplication: CreateCreditAccountApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCreditAccountApplication' is not null or undefined
            assertParamExists('createCreditAccountApplication', 'createCreditAccountApplication', createCreditAccountApplication)
            const localVarPath = `/api/v1/wallets/my/bank-account-applications/credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCreditAccountApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a debit account application for the current user
         * @param {CreateDebitAccountApplication} createDebitAccountApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDebitAccountApplication: async (createDebitAccountApplication: CreateDebitAccountApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDebitAccountApplication' is not null or undefined
            assertParamExists('createDebitAccountApplication', 'createDebitAccountApplication', createDebitAccountApplication)
            const localVarPath = `/api/v1/wallets/my/bank-account-applications/debit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDebitAccountApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankAccountApplicationsApi - functional programming interface
 * @export
 */
export const BankAccountApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankAccountApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a credit account application for the current user
         * @param {CreateCreditAccountApplication} createCreditAccountApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCreditAccountApplication(createCreditAccountApplication: CreateCreditAccountApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCreditAccountApplication(createCreditAccountApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a debit account application for the current user
         * @param {CreateDebitAccountApplication} createDebitAccountApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDebitAccountApplication(createDebitAccountApplication: CreateDebitAccountApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDebitAccountApplication(createDebitAccountApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankAccountApplicationsApi - factory interface
 * @export
 */
export const BankAccountApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankAccountApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a credit account application for the current user
         * @param {CreateCreditAccountApplication} createCreditAccountApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditAccountApplication(createCreditAccountApplication: CreateCreditAccountApplication, options?: any): AxiosPromise<void> {
            return localVarFp.createCreditAccountApplication(createCreditAccountApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a debit account application for the current user
         * @param {CreateDebitAccountApplication} createDebitAccountApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDebitAccountApplication(createDebitAccountApplication: CreateDebitAccountApplication, options?: any): AxiosPromise<void> {
            return localVarFp.createDebitAccountApplication(createDebitAccountApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BankAccountApplicationsApi - object-oriented interface
 * @export
 * @class BankAccountApplicationsApi
 * @extends {BaseAPI}
 */
export class BankAccountApplicationsApi extends BaseAPI {
    /**
     * 
     * @summary Create a credit account application for the current user
     * @param {CreateCreditAccountApplication} createCreditAccountApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApplicationsApi
     */
    public createCreditAccountApplication(createCreditAccountApplication: CreateCreditAccountApplication, options?: AxiosRequestConfig) {
        return BankAccountApplicationsApiFp(this.configuration).createCreditAccountApplication(createCreditAccountApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a debit account application for the current user
     * @param {CreateDebitAccountApplication} createDebitAccountApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApplicationsApi
     */
    public createDebitAccountApplication(createDebitAccountApplication: CreateDebitAccountApplication, options?: AxiosRequestConfig) {
        return BankAccountApplicationsApiFp(this.configuration).createDebitAccountApplication(createDebitAccountApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CardsApi - axios parameter creator
 * @export
 */
export const CardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update a card\'s preferences for the current user
         * @param {string} id 
         * @param {UpdateCardPreferences} updateCardPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardPreferences: async (id: string, updateCardPreferences: UpdateCardPreferences, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCardPreferences', 'id', id)
            // verify required parameter 'updateCardPreferences' is not null or undefined
            assertParamExists('updateCardPreferences', 'updateCardPreferences', updateCardPreferences)
            const localVarPath = `/api/v1/my/cards/{id}/preferences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCardPreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardsApi - functional programming interface
 * @export
 */
export const CardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update a card\'s preferences for the current user
         * @param {string} id 
         * @param {UpdateCardPreferences} updateCardPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCardPreferences(id: string, updateCardPreferences: UpdateCardPreferences, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCardPreferences(id, updateCardPreferences, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CardsApi - factory interface
 * @export
 */
export const CardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Update a card\'s preferences for the current user
         * @param {string} id 
         * @param {UpdateCardPreferences} updateCardPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardPreferences(id: string, updateCardPreferences: UpdateCardPreferences, options?: any): AxiosPromise<void> {
            return localVarFp.updateCardPreferences(id, updateCardPreferences, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardsApi - object-oriented interface
 * @export
 * @class CardsApi
 * @extends {BaseAPI}
 */
export class CardsApi extends BaseAPI {
    /**
     * 
     * @summary Update a card\'s preferences for the current user
     * @param {string} id 
     * @param {UpdateCardPreferences} updateCardPreferences 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public updateCardPreferences(id: string, updateCardPreferences: UpdateCardPreferences, options?: AxiosRequestConfig) {
        return CardsApiFp(this.configuration).updateCardPreferences(id, updateCardPreferences, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChargeAuthorizationRequestsApi - axios parameter creator
 * @export
 */
export const ChargeAuthorizationRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve a charge authorization request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeAuthorizationRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveChargeAuthorizationRequest', 'id', id)
            const localVarPath = `/api/v1/wallets/my/charge-authorization-requests/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deny a charge authorization request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        denyChargeAuthorizationRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('denyChargeAuthorizationRequest', 'id', id)
            const localVarPath = `/api/v1/wallets/my/charge-authorization-requests/{id}/deny`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a charge authorization request by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChargeAuthorizationRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChargeAuthorizationRequest', 'id', id)
            const localVarPath = `/api/v1/wallets/my/charge-authorization-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search my charge authorization requests
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [payeeName] 
         * @param {Array<string>} [cardIds] 
         * @param {Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>} [requestTypes] 
         * @param {Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMyChargeAuthorizationRequests: async (pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>, payeeName?: string, cardIds?: Array<string>, requestTypes?: Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>, statuses?: Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('searchMyChargeAuthorizationRequests', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchMyChargeAuthorizationRequests', 'pageSize', pageSize)
            const localVarPath = `/api/v1/wallets/my/charge-authorization-requests/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (payeeName !== undefined) {
                localVarQueryParameter['payeeName'] = payeeName;
            }

            if (cardIds) {
                localVarQueryParameter['cardIds'] = Array.from(cardIds);
            }

            if (requestTypes) {
                localVarQueryParameter['requestTypes'] = Array.from(requestTypes);
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChargeAuthorizationRequestsApi - functional programming interface
 * @export
 */
export const ChargeAuthorizationRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChargeAuthorizationRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve a charge authorization request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveChargeAuthorizationRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveChargeAuthorizationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deny a charge authorization request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async denyChargeAuthorizationRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.denyChargeAuthorizationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a charge authorization request by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChargeAuthorizationRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargeAuthorizationRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChargeAuthorizationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search my charge authorization requests
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [payeeName] 
         * @param {Array<string>} [cardIds] 
         * @param {Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>} [requestTypes] 
         * @param {Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMyChargeAuthorizationRequests(pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>, payeeName?: string, cardIds?: Array<string>, requestTypes?: Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>, statuses?: Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedChargeAuthorizationRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMyChargeAuthorizationRequests(pageNumber, pageSize, sortDirection, sortBy, payeeName, cardIds, requestTypes, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChargeAuthorizationRequestsApi - factory interface
 * @export
 */
export const ChargeAuthorizationRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChargeAuthorizationRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve a charge authorization request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeAuthorizationRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveChargeAuthorizationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deny a charge authorization request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        denyChargeAuthorizationRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.denyChargeAuthorizationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a charge authorization request by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChargeAuthorizationRequest(id: string, options?: any): AxiosPromise<ChargeAuthorizationRequestDto> {
            return localVarFp.getChargeAuthorizationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search my charge authorization requests
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [payeeName] 
         * @param {Array<string>} [cardIds] 
         * @param {Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>} [requestTypes] 
         * @param {Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMyChargeAuthorizationRequests(pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>, payeeName?: string, cardIds?: Array<string>, requestTypes?: Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>, statuses?: Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>, options?: any): AxiosPromise<PagedChargeAuthorizationRequestDto> {
            return localVarFp.searchMyChargeAuthorizationRequests(pageNumber, pageSize, sortDirection, sortBy, payeeName, cardIds, requestTypes, statuses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChargeAuthorizationRequestsApi - object-oriented interface
 * @export
 * @class ChargeAuthorizationRequestsApi
 * @extends {BaseAPI}
 */
export class ChargeAuthorizationRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Approve a charge authorization request
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargeAuthorizationRequestsApi
     */
    public approveChargeAuthorizationRequest(id: string, options?: AxiosRequestConfig) {
        return ChargeAuthorizationRequestsApiFp(this.configuration).approveChargeAuthorizationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deny a charge authorization request
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargeAuthorizationRequestsApi
     */
    public denyChargeAuthorizationRequest(id: string, options?: AxiosRequestConfig) {
        return ChargeAuthorizationRequestsApiFp(this.configuration).denyChargeAuthorizationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a charge authorization request by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargeAuthorizationRequestsApi
     */
    public getChargeAuthorizationRequest(id: string, options?: AxiosRequestConfig) {
        return ChargeAuthorizationRequestsApiFp(this.configuration).getChargeAuthorizationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search my charge authorization requests
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>} [sortBy] 
     * @param {string} [payeeName] 
     * @param {Array<string>} [cardIds] 
     * @param {Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>} [requestTypes] 
     * @param {Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>} [statuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargeAuthorizationRequestsApi
     */
    public searchMyChargeAuthorizationRequests(pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'PAYEE_NAME' | 'CREATED_AT'>, payeeName?: string, cardIds?: Array<string>, requestTypes?: Array<'PURCHASE' | 'CARD_TRANSACTION' | 'ATM'>, statuses?: Array<'PENDING' | 'USER_REVIEW_REQUESTED' | 'APPROVED_BY_USER' | 'DECLINED_BY_USER' | 'APPROVED' | 'DECLINED'>, options?: AxiosRequestConfig) {
        return ChargeAuthorizationRequestsApiFp(this.configuration).searchMyChargeAuthorizationRequests(pageNumber, pageSize, sortDirection, sortBy, payeeName, cardIds, requestTypes, statuses, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerTokenApi - axios parameter creator
 * @export
 */
export const CustomerTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a MFA banking token
         * @param {CreateMfaToken} createMfaToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMfaToken: async (createMfaToken: CreateMfaToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMfaToken' is not null or undefined
            assertParamExists('createMfaToken', 'createMfaToken', createMfaToken)
            const localVarPath = `/api/v1/customer-tokens/mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMfaToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a read-only banking token for the customer
         * @param {CreateCustomerToken} createCustomerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReadOnlyToken: async (createCustomerToken: CreateCustomerToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomerToken' is not null or undefined
            assertParamExists('createReadOnlyToken', 'createCustomerToken', createCustomerToken)
            const localVarPath = `/api/v1/customer-tokens/read-only`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a sensitive banking token for the customer
         * @param {CreateCustomerToken} createCustomerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSensitiveToken: async (createCustomerToken: CreateCustomerToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomerToken' is not null or undefined
            assertParamExists('createSensitiveToken', 'createCustomerToken', createCustomerToken)
            const localVarPath = `/api/v1/customer-tokens/sensitive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get token scopes
         * @param {string} bankingPartnershipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenScopes: async (bankingPartnershipId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankingPartnershipId' is not null or undefined
            assertParamExists('getTokenScopes', 'bankingPartnershipId', bankingPartnershipId)
            const localVarPath = `/api/v1/customer-tokens/{bankingPartnershipId}/scopes`
                .replace(`{${"bankingPartnershipId"}}`, encodeURIComponent(String(bankingPartnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerTokenApi - functional programming interface
 * @export
 */
export const CustomerTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a MFA banking token
         * @param {CreateMfaToken} createMfaToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMfaToken(createMfaToken: CreateMfaToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMfaToken(createMfaToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a read-only banking token for the customer
         * @param {CreateCustomerToken} createCustomerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReadOnlyToken(createCustomerToken: CreateCustomerToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReadOnlyToken(createCustomerToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a sensitive banking token for the customer
         * @param {CreateCustomerToken} createCustomerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSensitiveToken(createCustomerToken: CreateCustomerToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSensitiveToken(createCustomerToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get token scopes
         * @param {string} bankingPartnershipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenScopes(bankingPartnershipId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenScopesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenScopes(bankingPartnershipId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerTokenApi - factory interface
 * @export
 */
export const CustomerTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a MFA banking token
         * @param {CreateMfaToken} createMfaToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMfaToken(createMfaToken: CreateMfaToken, options?: any): AxiosPromise<MfaTokenDto> {
            return localVarFp.createMfaToken(createMfaToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a read-only banking token for the customer
         * @param {CreateCustomerToken} createCustomerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReadOnlyToken(createCustomerToken: CreateCustomerToken, options?: any): AxiosPromise<CustomerTokenDto> {
            return localVarFp.createReadOnlyToken(createCustomerToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a sensitive banking token for the customer
         * @param {CreateCustomerToken} createCustomerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSensitiveToken(createCustomerToken: CreateCustomerToken, options?: any): AxiosPromise<CustomerTokenDto> {
            return localVarFp.createSensitiveToken(createCustomerToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get token scopes
         * @param {string} bankingPartnershipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenScopes(bankingPartnershipId: string, options?: any): AxiosPromise<TokenScopesDto> {
            return localVarFp.getTokenScopes(bankingPartnershipId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerTokenApi - object-oriented interface
 * @export
 * @class CustomerTokenApi
 * @extends {BaseAPI}
 */
export class CustomerTokenApi extends BaseAPI {
    /**
     * 
     * @summary Create a MFA banking token
     * @param {CreateMfaToken} createMfaToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTokenApi
     */
    public createMfaToken(createMfaToken: CreateMfaToken, options?: AxiosRequestConfig) {
        return CustomerTokenApiFp(this.configuration).createMfaToken(createMfaToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a read-only banking token for the customer
     * @param {CreateCustomerToken} createCustomerToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTokenApi
     */
    public createReadOnlyToken(createCustomerToken: CreateCustomerToken, options?: AxiosRequestConfig) {
        return CustomerTokenApiFp(this.configuration).createReadOnlyToken(createCustomerToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a sensitive banking token for the customer
     * @param {CreateCustomerToken} createCustomerToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTokenApi
     */
    public createSensitiveToken(createCustomerToken: CreateCustomerToken, options?: AxiosRequestConfig) {
        return CustomerTokenApiFp(this.configuration).createSensitiveToken(createCustomerToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get token scopes
     * @param {string} bankingPartnershipId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerTokenApi
     */
    public getTokenScopes(bankingPartnershipId: string, options?: AxiosRequestConfig) {
        return CustomerTokenApiFp(this.configuration).getTokenScopes(bankingPartnershipId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalAccountApplicationsApi - axios parameter creator
 * @export
 */
export const InternalAccountApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRealLendingAccount} createRealLendingAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForRealLendingAccount: async (createRealLendingAccount: CreateRealLendingAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRealLendingAccount' is not null or undefined
            assertParamExists('applyForRealLendingAccount', 'createRealLendingAccount', createRealLendingAccount)
            const localVarPath = `/api/v1/wallets/my/internal-account-applications/real-lending-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRealLendingAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalAccountApplicationsApi - functional programming interface
 * @export
 */
export const InternalAccountApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalAccountApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRealLendingAccount} createRealLendingAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyForRealLendingAccount(createRealLendingAccount: CreateRealLendingAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyForRealLendingAccount(createRealLendingAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalAccountApplicationsApi - factory interface
 * @export
 */
export const InternalAccountApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalAccountApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRealLendingAccount} createRealLendingAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForRealLendingAccount(createRealLendingAccount: CreateRealLendingAccount, options?: any): AxiosPromise<void> {
            return localVarFp.applyForRealLendingAccount(createRealLendingAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalAccountApplicationsApi - object-oriented interface
 * @export
 * @class InternalAccountApplicationsApi
 * @extends {BaseAPI}
 */
export class InternalAccountApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {CreateRealLendingAccount} createRealLendingAccount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAccountApplicationsApi
     */
    public applyForRealLendingAccount(createRealLendingAccount: CreateRealLendingAccount, options?: AxiosRequestConfig) {
        return InternalAccountApplicationsApiFp(this.configuration).applyForRealLendingAccount(createRealLendingAccount, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryConsumerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryConsumerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus1', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/consumer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/consumer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage1', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages1', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus1', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryConsumerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage1(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages1(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - factory interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryConsumerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryConsumerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryConsumerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryConsumerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LedgerApi - axios parameter creator
 * @export
 */
export const LedgerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search my ledger items
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [dateOnOrAfter] 
         * @param {string} [dateOnOrBefore] 
         * @param {string} [description] 
         * @param {'CREDIT' | 'DEBIT'} [type] 
         * @param {Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>} [paymentSourceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMyLedgerItems: async (pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>, dateOnOrAfter?: string, dateOnOrBefore?: string, description?: string, type?: 'CREDIT' | 'DEBIT', paymentSourceTypes?: Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('searchMyLedgerItems', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchMyLedgerItems', 'pageSize', pageSize)
            const localVarPath = `/api/v1/wallets/my/ledger/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (dateOnOrAfter !== undefined) {
                localVarQueryParameter['dateOnOrAfter'] = (dateOnOrAfter as any instanceof Date) ?
                    (dateOnOrAfter as any).toISOString().substr(0,10) :
                    dateOnOrAfter;
            }

            if (dateOnOrBefore !== undefined) {
                localVarQueryParameter['dateOnOrBefore'] = (dateOnOrBefore as any instanceof Date) ?
                    (dateOnOrBefore as any).toISOString().substr(0,10) :
                    dateOnOrBefore;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (paymentSourceTypes) {
                localVarQueryParameter['paymentSourceTypes'] = Array.from(paymentSourceTypes);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LedgerApi - functional programming interface
 * @export
 */
export const LedgerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LedgerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search my ledger items
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [dateOnOrAfter] 
         * @param {string} [dateOnOrBefore] 
         * @param {string} [description] 
         * @param {'CREDIT' | 'DEBIT'} [type] 
         * @param {Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>} [paymentSourceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMyLedgerItems(pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>, dateOnOrAfter?: string, dateOnOrBefore?: string, description?: string, type?: 'CREDIT' | 'DEBIT', paymentSourceTypes?: Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedLedgerItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMyLedgerItems(pageNumber, pageSize, sortDirection, sortBy, dateOnOrAfter, dateOnOrBefore, description, type, paymentSourceTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LedgerApi - factory interface
 * @export
 */
export const LedgerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LedgerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search my ledger items
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [dateOnOrAfter] 
         * @param {string} [dateOnOrBefore] 
         * @param {string} [description] 
         * @param {'CREDIT' | 'DEBIT'} [type] 
         * @param {Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>} [paymentSourceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMyLedgerItems(pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>, dateOnOrAfter?: string, dateOnOrBefore?: string, description?: string, type?: 'CREDIT' | 'DEBIT', paymentSourceTypes?: Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>, options?: any): AxiosPromise<PagedLedgerItemDto> {
            return localVarFp.searchMyLedgerItems(pageNumber, pageSize, sortDirection, sortBy, dateOnOrAfter, dateOnOrBefore, description, type, paymentSourceTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LedgerApi - object-oriented interface
 * @export
 * @class LedgerApi
 * @extends {BaseAPI}
 */
export class LedgerApi extends BaseAPI {
    /**
     * 
     * @summary Search my ledger items
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>} [sortBy] 
     * @param {string} [dateOnOrAfter] 
     * @param {string} [dateOnOrBefore] 
     * @param {string} [description] 
     * @param {'CREDIT' | 'DEBIT'} [type] 
     * @param {Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>} [paymentSourceTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LedgerApi
     */
    public searchMyLedgerItems(pageNumber: number, pageSize: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'AMOUNT' | 'PAYMENT_SOURCE_TYPE' | 'DESCRIPTION' | 'TYPE' | 'PAYMENT_SYSTEM' | 'BANK_NAME' | 'CREATED_AT'>, dateOnOrAfter?: string, dateOnOrBefore?: string, description?: string, type?: 'CREDIT' | 'DEBIT', paymentSourceTypes?: Array<'COMMISSION' | 'REVENUE_SHARE' | 'COMMISSION_ADVANCE_REPAYMENT' | 'DEPOSIT_RELEASE' | 'INSTANT_PAYMENT' | 'INSTANT_COMMISSION'>, options?: AxiosRequestConfig) {
        return LedgerApiFp(this.configuration).searchMyLedgerItems(pageNumber, pageSize, sortDirection, sortBy, dateOnOrAfter, dateOnOrBefore, description, type, paymentSourceTypes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LendingAccountApi - axios parameter creator
 * @export
 */
export const LendingAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get cash advance from lending account
         * @param {CreateCashAdvance} createCashAdvance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashAdvance: async (createCashAdvance: CreateCashAdvance, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCashAdvance' is not null or undefined
            assertParamExists('createCashAdvance', 'createCashAdvance', createCashAdvance)
            const localVarPath = `/api/v1/lending-accounts/cash-advance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCashAdvance, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark cash advance as posted
         * @param {MarkCashAdvanceAsPosted} markCashAdvanceAsPosted 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCashAdvanceAsPosted: async (markCashAdvanceAsPosted: MarkCashAdvanceAsPosted, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'markCashAdvanceAsPosted' is not null or undefined
            assertParamExists('markCashAdvanceAsPosted', 'markCashAdvanceAsPosted', markCashAdvanceAsPosted)
            const localVarPath = `/api/v1/lending-accounts/mark-cash-advance-as-posted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markCashAdvanceAsPosted, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send cash advance payment
         * @param {SendCashAdvancePayment} sendCashAdvancePayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCashAdvancePayment: async (sendCashAdvancePayment: SendCashAdvancePayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendCashAdvancePayment' is not null or undefined
            assertParamExists('sendCashAdvancePayment', 'sendCashAdvancePayment', sendCashAdvancePayment)
            const localVarPath = `/api/v1/lending-accounts/send-cash-advance-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendCashAdvancePayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LendingAccountApi - functional programming interface
 * @export
 */
export const LendingAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LendingAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get cash advance from lending account
         * @param {CreateCashAdvance} createCashAdvance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCashAdvance(createCashAdvance: CreateCashAdvance, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCashAdvance(createCashAdvance, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark cash advance as posted
         * @param {MarkCashAdvanceAsPosted} markCashAdvanceAsPosted 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markCashAdvanceAsPosted(markCashAdvanceAsPosted: MarkCashAdvanceAsPosted, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markCashAdvanceAsPosted(markCashAdvanceAsPosted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send cash advance payment
         * @param {SendCashAdvancePayment} sendCashAdvancePayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCashAdvancePayment(sendCashAdvancePayment: SendCashAdvancePayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCashAdvancePayment(sendCashAdvancePayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LendingAccountApi - factory interface
 * @export
 */
export const LendingAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LendingAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Get cash advance from lending account
         * @param {CreateCashAdvance} createCashAdvance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashAdvance(createCashAdvance: CreateCashAdvance, options?: any): AxiosPromise<void> {
            return localVarFp.createCashAdvance(createCashAdvance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark cash advance as posted
         * @param {MarkCashAdvanceAsPosted} markCashAdvanceAsPosted 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCashAdvanceAsPosted(markCashAdvanceAsPosted: MarkCashAdvanceAsPosted, options?: any): AxiosPromise<void> {
            return localVarFp.markCashAdvanceAsPosted(markCashAdvanceAsPosted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send cash advance payment
         * @param {SendCashAdvancePayment} sendCashAdvancePayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCashAdvancePayment(sendCashAdvancePayment: SendCashAdvancePayment, options?: any): AxiosPromise<void> {
            return localVarFp.sendCashAdvancePayment(sendCashAdvancePayment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LendingAccountApi - object-oriented interface
 * @export
 * @class LendingAccountApi
 * @extends {BaseAPI}
 */
export class LendingAccountApi extends BaseAPI {
    /**
     * 
     * @summary Get cash advance from lending account
     * @param {CreateCashAdvance} createCashAdvance 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LendingAccountApi
     */
    public createCashAdvance(createCashAdvance: CreateCashAdvance, options?: AxiosRequestConfig) {
        return LendingAccountApiFp(this.configuration).createCashAdvance(createCashAdvance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark cash advance as posted
     * @param {MarkCashAdvanceAsPosted} markCashAdvanceAsPosted 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LendingAccountApi
     */
    public markCashAdvanceAsPosted(markCashAdvanceAsPosted: MarkCashAdvanceAsPosted, options?: AxiosRequestConfig) {
        return LendingAccountApiFp(this.configuration).markCashAdvanceAsPosted(markCashAdvanceAsPosted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send cash advance payment
     * @param {SendCashAdvancePayment} sendCashAdvancePayment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LendingAccountApi
     */
    public sendCashAdvancePayment(sendCashAdvancePayment: SendCashAdvancePayment, options?: AxiosRequestConfig) {
        return LendingAccountApiFp(this.configuration).sendCashAdvancePayment(sendCashAdvancePayment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferingApi - axios parameter creator
 * @export
 */
export const OfferingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get offerings for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEligibleOfferings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offerings/my-offerings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferingApi - functional programming interface
 * @export
 */
export const OfferingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get offerings for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEligibleOfferings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EligibleOfferingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEligibleOfferings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferingApi - factory interface
 * @export
 */
export const OfferingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get offerings for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEligibleOfferings(options?: any): AxiosPromise<EligibleOfferingsDto> {
            return localVarFp.getEligibleOfferings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferingApi - object-oriented interface
 * @export
 * @class OfferingApi
 * @extends {BaseAPI}
 */
export class OfferingApi extends BaseAPI {
    /**
     * 
     * @summary Get offerings for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferingApi
     */
    public getEligibleOfferings(options?: AxiosRequestConfig) {
        return OfferingApiFp(this.configuration).getEligibleOfferings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RepaymentsApi - axios parameter creator
 * @export
 */
export const RepaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a repayment for the specified user and internal account
         * @param {CreateInternalRepayment} createInternalRepayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalRepayment: async (createInternalRepayment: CreateInternalRepayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInternalRepayment' is not null or undefined
            assertParamExists('createInternalRepayment', 'createInternalRepayment', createInternalRepayment)
            const localVarPath = `/api/v1/wallets/repayments/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInternalRepayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepaymentsApi - functional programming interface
 * @export
 */
export const RepaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a repayment for the specified user and internal account
         * @param {CreateInternalRepayment} createInternalRepayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalRepayment(createInternalRepayment: CreateInternalRepayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalRepayment(createInternalRepayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepaymentsApi - factory interface
 * @export
 */
export const RepaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepaymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a repayment for the specified user and internal account
         * @param {CreateInternalRepayment} createInternalRepayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalRepayment(createInternalRepayment: CreateInternalRepayment, options?: any): AxiosPromise<void> {
            return localVarFp.createInternalRepayment(createInternalRepayment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepaymentsApi - object-oriented interface
 * @export
 * @class RepaymentsApi
 * @extends {BaseAPI}
 */
export class RepaymentsApi extends BaseAPI {
    /**
     * 
     * @summary Create a repayment for the specified user and internal account
     * @param {CreateInternalRepayment} createInternalRepayment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepaymentsApi
     */
    public createInternalRepayment(createInternalRepayment: CreateInternalRepayment, options?: AxiosRequestConfig) {
        return RepaymentsApiFp(this.configuration).createInternalRepayment(createInternalRepayment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StocksApi - axios parameter creator
 * @export
 */
export const StocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Import a Shareworks CSV file
         * @param {string} asOfDate 
         * @param {ImportShareworksRequest} [importShareworksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importShareworks: async (asOfDate: string, importShareworksRequest?: ImportShareworksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'asOfDate' is not null or undefined
            assertParamExists('importShareworks', 'asOfDate', asOfDate)
            const localVarPath = `/api/v1/wallets/stocks/import-shareworks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (asOfDate !== undefined) {
                localVarQueryParameter['asOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString().substr(0,10) :
                    asOfDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importShareworksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StocksApi - functional programming interface
 * @export
 */
export const StocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Import a Shareworks CSV file
         * @param {string} asOfDate 
         * @param {ImportShareworksRequest} [importShareworksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importShareworks(asOfDate: string, importShareworksRequest?: ImportShareworksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportShareworksStockResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importShareworks(asOfDate, importShareworksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StocksApi - factory interface
 * @export
 */
export const StocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StocksApiFp(configuration)
    return {
        /**
         * 
         * @summary Import a Shareworks CSV file
         * @param {string} asOfDate 
         * @param {ImportShareworksRequest} [importShareworksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importShareworks(asOfDate: string, importShareworksRequest?: ImportShareworksRequest, options?: any): AxiosPromise<ImportShareworksStockResult> {
            return localVarFp.importShareworks(asOfDate, importShareworksRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StocksApi - object-oriented interface
 * @export
 * @class StocksApi
 * @extends {BaseAPI}
 */
export class StocksApi extends BaseAPI {
    /**
     * 
     * @summary Import a Shareworks CSV file
     * @param {string} asOfDate 
     * @param {ImportShareworksRequest} [importShareworksRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StocksApi
     */
    public importShareworks(asOfDate: string, importShareworksRequest?: ImportShareworksRequest, options?: AxiosRequestConfig) {
        return StocksApiFp(this.configuration).importShareworks(asOfDate, importShareworksRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates my wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMyWallet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wallets/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates wallet for the specified user
         * @param {CreateWallet} createWallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletForUser: async (createWallet: CreateWallet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWallet' is not null or undefined
            assertParamExists('createWalletForUser', 'createWallet', createWallet)
            const localVarPath = `/api/v1/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWallet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get my wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWallet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wallets/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get wallet by the specified id
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletById: async (walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('getWalletById', 'walletId', walletId)
            const localVarPath = `/api/v1/wallets/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get wallet by the specified user id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getWalletByUserId', 'userId', userId)
            const localVarPath = `/api/v1/wallets/by-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates my wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMyWallet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMyWallet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates wallet for the specified user
         * @param {CreateWallet} createWallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWalletForUser(createWallet: CreateWallet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWalletForUser(createWallet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get my wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyWallet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyWallet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get wallet by the specified id
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletById(walletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletById(walletId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get wallet by the specified user id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates my wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMyWallet(options?: any): AxiosPromise<void> {
            return localVarFp.createMyWallet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates wallet for the specified user
         * @param {CreateWallet} createWallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletForUser(createWallet: CreateWallet, options?: any): AxiosPromise<void> {
            return localVarFp.createWalletForUser(createWallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get my wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWallet(options?: any): AxiosPromise<WalletDto> {
            return localVarFp.getMyWallet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get wallet by the specified id
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletById(walletId: string, options?: any): AxiosPromise<WalletDto> {
            return localVarFp.getWalletById(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get wallet by the specified user id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletByUserId(userId: string, options?: any): AxiosPromise<WalletDto> {
            return localVarFp.getWalletByUserId(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * 
     * @summary Creates my wallet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public createMyWallet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).createMyWallet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates wallet for the specified user
     * @param {CreateWallet} createWallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public createWalletForUser(createWallet: CreateWallet, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).createWalletForUser(createWallet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get my wallet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getMyWallet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getMyWallet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get wallet by the specified id
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWalletById(walletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWalletById(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get wallet by the specified user id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWalletByUserId(userId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWalletByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


