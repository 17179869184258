import {
  faFlag as faFlagRegular,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DefaultLoader from '../../DefaultLoader';
import ResourceContainer from '../../ResourceContainer';

interface ZenChecklistFileNameCellProps {
  name: string;
  labels: string[];
  urgent: boolean;
  onClick?(): void;
  isItemUrgent: boolean;
  onFlagClick(): void;
}

const ZenChecklistFileNameCell: React.FC<ZenChecklistFileNameCellProps> = ({
  name,
  labels,
  urgent,
  onClick,
  isItemUrgent,
  onFlagClick,
}) => {
  return (
    <div className='flex flex-col'>
      <div className='group cursor-pointer'>
        <div
          className='font-zen-body font-normal text-sm text-dark capitalize inline mr-2'
          onClick={onClick}
          data-testid='checklistName'
        >
          {name}
        </div>
        <div className='inline-block align-text-top'>
          <ResourceContainer
            loading={isItemUrgent}
            isEmpty={false}
            resourceName='download-item'
            LoaderComponent={<DefaultLoader noPadding iconSize='small' />}
          >
            {urgent ? (
              <FontAwesomeIcon
                icon={faFlag}
                className='text-zen-danger'
                onClick={onFlagClick}
                data-testid='flagged'
              />
            ) : (
              <div className='invisible group-hover:visible'>
                <FontAwesomeIcon
                  icon={faFlagRegular}
                  className='text-zen-dark-5'
                  onClick={onFlagClick}
                  data-testid='unflagged'
                />
              </div>
            )}
          </ResourceContainer>
        </div>
      </div>

      {!!labels?.length && (
        <div className='pt-1.5 cursor-default'>
          {!!labels &&
            labels.map((label) => (
              <div
                className='font-zen-body text-sm text-zen-dark-6 pr-2.5 inline-flex items-center'
                key={label}
                data-testid='labelName'
              >
                <FontAwesomeIcon
                  icon={faTag}
                  className='text-zen-dark-6 mr-1 -mb-0.5'
                />
                {label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ZenChecklistFileNameCell;
