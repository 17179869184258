import {
  faCheckDouble,
  faCopy,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/pro-light-svg-icons';
import {
  faThumbsDown as faSolidThumbsDown,
  faThumbsUp as faSolidThumbsUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AnalyticsService from '../../../services/AnalyticsService';
import { updateMessageVote } from '../../../slices/LeoSlice';
import { AnalyticsEventEnum } from '../../../types';
import { VoteType } from '../../../utils/LeoUtils';
import { CurrentChat } from '../../Zen/Leo/Chat/LeoChatIndex';
import NeoLeoAvatar from '../../../assets/img/leo/neo-leo.png';

interface NeoLeoChatResponseActionsProps extends CurrentChat {}

enum RESPONSE_ACTIONS_ENUM {
  // REGENERATE = 'Regenerate',
  UPVOTE = 'Upvote',
  DOWNVOTE = 'Downvote',
  COPY = 'Copy',
}

interface RESPONSE_ACTIONS_ENUM_AND_LOADING_MAP {
  // [RESPONSE_ACTIONS_ENUM.REGENERATE]: boolean;
  [RESPONSE_ACTIONS_ENUM.UPVOTE]: boolean;
  [RESPONSE_ACTIONS_ENUM.DOWNVOTE]: boolean;
  [RESPONSE_ACTIONS_ENUM.COPY]: boolean;
}

const DEFAULT_RESPONSE_ACTIONS_LOADING_MAP_STATE = {
  // [RESPONSE_ACTIONS_ENUM.REGENERATE]: false,
  [RESPONSE_ACTIONS_ENUM.UPVOTE]: false,
  [RESPONSE_ACTIONS_ENUM.DOWNVOTE]: false,
  [RESPONSE_ACTIONS_ENUM.COPY]: false,
};

const NeoLeoChatResponseActions: React.FC<NeoLeoChatResponseActionsProps> = (
  msgRes,
) => {
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const [
    responseActionsLoadingMap,
    setResponseActionsLoadingMap,
  ] = useState<RESPONSE_ACTIONS_ENUM_AND_LOADING_MAP>(
    DEFAULT_RESPONSE_ACTIONS_LOADING_MAP_STATE,
  );

  const onCopyText = (text: string) => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(text);
    else {
      let textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const setActionLoading = (
    actionType: RESPONSE_ACTIONS_ENUM,
    loading: boolean,
  ) => {
    setResponseActionsLoadingMap({
      ...responseActionsLoadingMap,
      [actionType]: loading,
    });
  };

  const actions = [
    // {
    //   actionType: RESPONSE_ACTIONS_ENUM.REGENERATE,
    //   icon: faRotate,
    //   onClick: () => {},
    // },
    {
      actionType: RESPONSE_ACTIONS_ENUM.UPVOTE,
      icon: msgRes.vote_type === VoteType.Upvote ? faSolidThumbsUp : faThumbsUp,
      onClick: async () => {
        setActionLoading(RESPONSE_ACTIONS_ENUM.UPVOTE, true);
        await dispatch(updateMessageVote(msgRes, VoteType.Upvote));
        setActionLoading(RESPONSE_ACTIONS_ENUM.UPVOTE, false);
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.LEO_RESPONSE_THUMBS_UP_CLICK,
        );
      },
    },
    {
      actionType: RESPONSE_ACTIONS_ENUM.DOWNVOTE,
      icon:
        msgRes.vote_type === VoteType.Downvote
          ? faSolidThumbsDown
          : faThumbsDown,
      onClick: async () => {
        setActionLoading(RESPONSE_ACTIONS_ENUM.DOWNVOTE, true);
        await dispatch(updateMessageVote(msgRes, VoteType.Downvote));
        setActionLoading(RESPONSE_ACTIONS_ENUM.DOWNVOTE, false);
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.LEO_RESPONSE_THUMBS_DOWN_CLICK,
        );
      },
    },
    {
      actionType: RESPONSE_ACTIONS_ENUM.COPY,
      icon: faCopy,
      onClick: () => {
        onCopyText(msgRes.message!);
        AnalyticsService.instance().logEvent(
          AnalyticsEventEnum.LEO_RESPONSE_COPY_CLICK,
        );
      },
    },
  ];

  return (
    <div className='w-full flex flex-row mt-1'>
      <div className='relative'>
        <div className='h-[28px] flex flex-row items-center'>
          <img
            className='w-[26px] h-[26px] mr-3'
            src={NeoLeoAvatar}
            alt='neo-leo-avatar'
          />
          {actions.map(({ icon, actionType, onClick }) => (
            <button
              type='button'
              className='appearance-none flex flex-row items-center w-6 mr-2.5'
              key={actionType}
              onClick={onClick}
              disabled={responseActionsLoadingMap[actionType]}
              title={actionType}
            >
              <FontAwesomeIcon
                icon={icon}
                className='text-base hover:text-[17px] text-leo-response-action hover:text-white'
              />
            </button>
          ))}
        </div>
        {isCopied && (
          <div className='absolute w-full -top-[25px] flex flex-row justify-end items-center'>
            <div className='rounded-lg bg-[#282932] flex flex-row items-center px-1.5 py-1'>
              <FontAwesomeIcon
                icon={faCheckDouble}
                className='text-[#A0A0A0] text-sm'
              />
              <p className='text-xs text-[#A0A0A0] ml-1'>Copied</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NeoLeoChatResponseActions;
