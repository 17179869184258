import { useSelector } from 'react-redux';
import {
  CreateVoiceCallRequest,
  Speaker,
  VoiceCallControllerApi,
  VoiceCallDto,
  VoiceCallLiteDto,
  VoiceRecordingControllerApi,
} from '../../openapi/yada';
import {
  UserAvailabilityRequest,
  UserControllerApi,
} from '../../openapi/yenta';
import {
  getYadaConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { queryKeys } from '../base/queryKeys';
import { useBaseMutation } from '../base/useBaseMutation';
import { QueryOptions, useSimpleQuery } from '../base/useSimpleQuery';
import { RootState } from '../../types';

export const useCreateVoiceCall = (
  participantId: string,
  payload: CreateVoiceCallRequest,
) => {
  return useSimpleQuery<VoiceCallDto>({
    queryKey: queryKeys.roar.voiceCall(participantId).queryKey,
    queryFn: async () => {
      const { data } = await new VoiceCallControllerApi(
        getYadaConfiguration(),
      ).createVoiceCall(payload);
      return data;
    },
    options: {
      logErrorMessage: 'Failed to create the voice call',
      toastErrorMessage:
        'Failed to get the phone number. Please try again later.',
      cacheTime: 0,
    },
  });
};

export const useSmsDialNumber = () => {
  const {
    auth: { userDetail },
  } = useSelector((state: RootState) => state);
  const authUserId = userDetail?.id;

  return useBaseMutation({
    queryKey: queryKeys.roar.sendSms(authUserId!).queryKey,
    mutationFn: (voiceCallId: string) =>
      new VoiceCallControllerApi(getYadaConfiguration()).smsDialNumber(
        voiceCallId,
      ),
    errorMessage: 'Failed to send the phone number. Please try again later.',
  });
};

export const useOptInSms = (authUserId: string) => {
  return useBaseMutation({
    queryKey: queryKeys.roar.officeHours(authUserId!).queryKey,
    mutationFn: async (userId: string) => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).patchUser(userId, { optedIntoSms: true });

      return data;
    },
    errorMessage: 'Failed to opt-in for SMS',
  });
};

export const useUpdateDoNotDisturb = (agentId: string) => {
  return useBaseMutation({
    queryKey: queryKeys.roar.officeHours(agentId).queryKey,
    mutationFn: async (userAvailability: UserAvailabilityRequest) => {
      const { data } = await new UserControllerApi(
        getYentaConfiguration(),
      ).patchUser(agentId, {
        userAvailability,
      });
      return data;
    },
    successMessage: 'Settings Saved',
    errorMessage: 'Failed to save settings',
  });
};

export const usePatchVoiceCall = (participantId: string) => {
  return useBaseMutation({
    queryKey: queryKeys.roar.voiceCall(participantId).queryKey,
    mutationFn: async ({
      id,
      phoneNumber,
    }: {
      id: string;
      phoneNumber: string;
    }) => {
      const { data } = await new VoiceCallControllerApi(
        getYadaConfiguration(),
      ).patchVoiceCall(id, {
        targetPhone: { value: phoneNumber },
      });
      return data;
    },
    errorMessage: 'Failed to update the phone number. Please try again later.',
  });
};

export const useVoiceCallWithUnidentifiedSpeakers = (
  pathname: string,
  options?: QueryOptions<VoiceCallLiteDto>,
) => {
  return useSimpleQuery({
    queryKey: queryKeys.roar.speakers(pathname).queryKey,
    queryFn: async () => {
      const { data } = await new VoiceCallControllerApi(
        getYadaConfiguration(),
      ).getVoiceCallWithUnidentifiedSpeakers();

      return data;
    },
    options: {
      ...options,
      logErrorMessage: 'Failed to fetch unidentified speakers information',
      toastErrorMessage: 'Unable to get unidentified speakers information',
      skipShowToastOnError: true,
      cacheTime: 0.5 * 60 * 1000, // 30 seconds
    },
  });
};

export const useUpdateSpeakers = () => {
  return useBaseMutation({
    queryKey: queryKeys.roar.updateSpeakers.queryKey,
    mutationFn: async ({
      id,
      speakers,
    }: {
      id: string;
      speakers: Speaker[];
    }) => {
      await new VoiceRecordingControllerApi(
        getYadaConfiguration(),
      ).updateSpeakers(id, { speakers });
      return speakers;
    },
  });
};
