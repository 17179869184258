import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import ChargeCard from '../../assets/img/wallet/charge-card.svg';
import {
  useFetchEligibleOfferings,
  useWallet,
} from '../../query/wallet/useWallet';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';
import ZenPageLayout from '../Zen/ZenPageLayout';
import ZenResourceContainer from '../Zen/ZenResourceContainer';
import WalletAgreementModal from './Agreement/WalletAgreementModal';
import WalletDebitOfferingCard from './WalletDebitOfferingCard';

interface WalletOfferingsProps {}

const WalletOfferings: React.FC<WalletOfferingsProps> = () => {
  const [offerId, setOfferId] = useState<string>();

  const { data, isLoading, errorCode } = useFetchEligibleOfferings();
  const { data: wallet } = useWallet();

  // 2nd !isEmpty --> if this component is rendered and banking partnership is not empty,
  // then either card or account is not created yet.
  const isApplicationPending =
    !isEmpty(wallet?.pendingApplications) ||
    !isEmpty(wallet?.bankingPartnerships);

  if (data && isEmpty(data?.eligibleDebitOfferings)) {
    return <p className='m-4 font-inter'>No Offers</p>;
  }

  return (
    <div>
      <ZenPageLayout
        path={[
          { title: 'Home', url: '/' },
          {
            title: 'Real Wallet',
            url: '/wallet',
          },
          {
            title: 'Apply for Business Checking Account',
            url: '/wallet/offerings',
          },
        ]}
        textClassNames='font-inter'
      >
        <ZenResourceContainer
          loading={isLoading}
          isEmpty={isEmpty(data)}
          resourceName='offering'
          errorCode={errorCode}
        >
          <div className='flex flex-col items-center p-10 xl:pt-20'>
            <div>
              <div className='flex flex-col xl:flex-row justify-center gap-6'>
                {/* Pending Applications */}
                {isApplicationPending &&
                  wallet?.pendingApplications?.map((application) => {
                    return (
                      <WalletDebitOfferingCard
                        key={application.accountApplicationId}
                        application={application}
                      />
                    );
                  })}

                {/* Debit Card */}
                {!isApplicationPending &&
                  data?.eligibleDebitOfferings?.map(({ debitOffering }) => {
                    const offerId = debitOffering?.id;
                    return (
                      <WalletDebitOfferingCard
                        key={offerId}
                        onSignUpClick={() => {
                          setOfferId(offerId);
                          AnalyticsService.instance().logEvent(
                            AnalyticsEventEnum.WALLET_SIGN_UP_FOR_DEBIT_CLICKED,
                          );
                        }}
                      />
                    );
                  })}
              </div>

              {/* Charge Card */}
              {!isApplicationPending &&
                data?.eligibleCreditOfferings?.map(({ creditOffering }) => {
                  const offerId = creditOffering?.id;
                  return (
                    <img
                      key={offerId}
                      src={ChargeCard}
                      alt='charge-card'
                      className='w-fit'
                    />
                  );
                })}
            </div>
          </div>
        </ZenResourceContainer>

        {offerId && (
          <WalletAgreementModal
            offerId={offerId}
            onClose={() => setOfferId(undefined)}
          />
        )}
      </ZenPageLayout>
    </div>
  );
};

export default WalletOfferings;
