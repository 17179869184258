import {
  faChevronDown,
  faMagnifyingGlass,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { useDispatch, useSelector } from 'react-redux';
import useAgentsInfo from '../../hooks/useAgentsInfo';
import { AgentInfo, UserResponseAgentStatusEnum } from '../../openapi/yenta';
import ErrorService from '../../services/ErrorService';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { AsyncSelectOption, RootState } from '../../types';
import { searchForAgents } from '../../utils/TableUtils';
import ZenControlledAsyncSelectInput from '../Zen/Input/ZenControlledAsyncSelectInput';
import ZenSidebarModalActionFooterV2 from '../Zen/Modal/ZenSidebarModalActionFooterV2';
import ZenButton from '../Zen/ZenButton';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import ZenUserPill from '../Zen/ZenUserPill';

interface ZenAddOverrideAgentProps {
  isOpen: boolean;
  onClose(addOverrideAgentsIds: string[] | undefined): void;
  overrideAgents: AgentInfo[] | undefined;
}

interface FormData {
  agentId: AsyncSelectOption;
  weight: number | undefined;
}

const ZenAddOverrideAgent: React.FC<ZenAddOverrideAgentProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormData>();
  const {
    userIds: { agentById },
    experiment: { experimentDetail },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [overrideAgentsIds, setOverrideAgentsIds] = useState<string[]>();
  const group = experimentDetail?.groups?.[0];
  const existingOverrideIds = group?.overrideIds || [];

  const agentId = watch('agentId');

  useEffect(() => {
    if (agentId?.value) {
      setOverrideAgentsIds([...(overrideAgentsIds || []), agentId.value]);
      setValue('agentId', {} as AsyncSelectOption);
    }
  }, [agentId, overrideAgentsIds, setValue]);

  useAgentsInfo(overrideAgentsIds || []);

  const onSubmit = async () => {
    onClose(overrideAgentsIds || []);
  };

  return (
    <ZenSidebarModal
      isOpen={isOpen}
      onClose={() => onClose(undefined)}
      title='Add Agents to Experiment Group'
    >
      <form
        className='flex flex-col justify-between min-h-full'
        onSubmit={handleSubmit(onSubmit)}
        title='edit-experiment-group-form'
      >
        <div className='p-5'>
          <div className='mt-3'>
            <ZenControlledAsyncSelectInput<FormData, 'agentId'>
              control={control}
              name='agentId'
              placeholder='Search by name or email'
              label='Search Agent'
              fetchData={async (search, page) => {
                try {
                  const { data } = await searchForAgents({
                    page,
                    search,
                    filterBy: {
                      agentStatus: [UserResponseAgentStatusEnum.Active],
                    },
                  });

                  const options: AsyncSelectOption[] = data.map((resp) => ({
                    value: `${resp.id}`,
                    label: `${resp.firstName} ${resp.lastName} - ${resp.emailAddress}`,
                  }));

                  return options;
                } catch (e) {
                  ErrorService.notify(
                    'Unable to search request registered agents in add team member form',
                    e,
                    {
                      search: {
                        term: search,
                        page,
                      },
                    },
                  );
                  dispatch(
                    showErrorToast(
                      'An unexpected error occurred.',
                      'We were unable to search for registered agents. Please try again in a few moments or contact support.',
                    ),
                  );
                }

                return [];
              }}
              isRequired
              filterOption={(option) => {
                return (
                  !overrideAgentsIds?.includes(option?.value) &&
                  !existingOverrideIds.includes(option?.value)
                );
              }}
              startAdornment={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='text-primary-blue mx-3 mt-1'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='text-zen-dark mx-3'
                />
              }
            />
          </div>

          {overrideAgentsIds?.length === 0 && (
            <div className='border-b border-zen-dark-4 py-5'>
              <p className='flex justify-center text-zen-dark-6 pt-2'>
                No agents have been added yet.
              </p>
            </div>
          )}
          <div className='py-5'>
            {overrideAgentsIds?.map((overideAgentId) => {
              const agentInfo = agentById[overideAgentId];

              if (!agentInfo) {
                return null;
              }

              return (
                <div
                  className='py-2 flex flex-row justify-between items-center border-b border-zen-dark-4'
                  key={agentInfo?.id}
                >
                  <div className='flex items-center space-x-4'>
                    <ZenUserPill
                      name={`${agentInfo?.firstName} ${agentInfo?.lastName}`}
                      imageUrl={
                        agentInfo?.avatar !== 'null'
                          ? agentInfo?.avatar
                          : undefined
                      }
                      backgroundVariant='no-background'
                      size='md'
                    />
                    <div className='text-zen-dark-9 text-md'>
                      {agentInfo.emailAddress}
                    </div>
                  </div>
                  <div className='cursor-pointer'>
                    <ZenButton
                      variant='danger-link'
                      LeftIconComponent={
                        <FontAwesomeIcon
                          icon={faTrash}
                          title='delete'
                          className='text-zen-danger'
                        />
                      }
                      onClick={async () => {
                        setOverrideAgentsIds(
                          overrideAgentsIds.filter((originalOverideAgentId) => {
                            return originalOverideAgentId !== overideAgentId;
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ZenSidebarModalActionFooterV2
          onClose={() => onClose(undefined)}
          isSubmitting={isSubmitting}
          submitButtonText='Save'
        />
      </form>
    </ZenSidebarModal>
  );
};

export default ZenAddOverrideAgent;
