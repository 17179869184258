import { createAction, Priority } from 'kbar';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigateIfNotCurrentRoute } from '../../../hooks/useNavigateIfNotCurrentRoute';
import { TransactionLifecycleStateValueStateEnum } from '../../../openapi/arrakis';
import { openSidebar } from '../../../slices/KBarSlice';
import { toggleAddCommissionParticipant } from '../../../slices/QuickActionSlice';
import { KBarActionEnum, ParticipantType, RootState } from '../../../types';
import { isTransactionReadOnly } from '../../../utils/TransactionHelper';
import { isListingNotActionable } from '../../../utils/TransactionUtils';
import useKbarRedirect from '../useKbarRedirect';

const useListingActions = () => {
  const dispatch = useDispatch();
  const navigateIfNotCurrentRoute = useNavigateIfNotCurrentRoute();
  const location = useLocation();
  const { redirectTo } = useKbarRedirect();
  const {
    transaction: {
      transactionDetailResponse: { data },
    },
    auth: { userDetail, isAdmin, isBroker },
  } = useSelector((state: RootState) => state);
  const isListingDetailRoute = useMemo(
    () => location.pathname.startsWith(`/listings/${data?.id}`),
    [data, location.pathname],
  );

  const showListingActions = useMemo(
    () => isListingDetailRoute && data && data?.listing,
    [data, isListingDetailRoute],
  );

  const preActions = () => {
    navigateIfNotCurrentRoute(`/listings/${data?.id}/detail`);
  };

  const addParticipant = () => {
    preActions();
    dispatch(openSidebar(KBarActionEnum.EDIT_LISTING));
    dispatch(
      toggleAddCommissionParticipant({
        agentType: ParticipantType.EXTERNAL_ENTITY,
        isOpen: true,
      }),
    );
  };

  const canListingBeEdited = useMemo(
    () =>
      data &&
      !isBroker &&
      data.lifecycleState?.state !==
        TransactionLifecycleStateValueStateEnum.ListingClosed &&
      (!isTransactionReadOnly(data!, userDetail!) || isAdmin),
    [data, userDetail, isAdmin, isBroker],
  );

  const canListingBeTerminated = useMemo(() => {
    if (!data) {
      return false;
    }
    return data.listing && !data.terminated && !isListingNotActionable(data);
  }, [data]);

  const canListingBeMarkedInContract = useMemo(() => {
    if (!data) {
      return false;
    }
    return data.listing && !data.terminated && !isListingNotActionable(data);
  }, [data]);

  const markInContractActions = canListingBeMarkedInContract
    ? [
        {
          id: 'inContract',
          name: 'In Contract',
          shortcut: ['l', 'n'],
          keywords: 'In Contract',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () =>
            dispatch(openSidebar(KBarActionEnum.MARK_LISTING_IN_CONTRACT)),
        },
        {
          id: 'markAsInContract',
          name: 'Mark as In Contract',
          shortcut: ['l', 'n'],
          keywords: 'Mark In Contract',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () =>
            dispatch(openSidebar(KBarActionEnum.MARK_LISTING_IN_CONTRACT)),
        },
      ]
    : [];

  const editListingActions = canListingBeEdited
    ? [
        {
          id: 'editListing',
          name: 'Edit Listing',
          shortcut: ['l', 'e'],
          keywords: 'Edit Listing',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => dispatch(openSidebar(KBarActionEnum.EDIT_LISTING)),
        },
        {
          id: 'addParticipant',
          name: 'Add Participant',
          shortcut: ['l', 'q'],
          keywords: 'Add Participant',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: addParticipant,
        },
      ]
    : [];

  const terminateListingAction = canListingBeTerminated
    ? [
        {
          id: 'terminateListing',
          name: 'Terminate Listing',
          shortcut: ['l', 'x'],
          keywords: 'Terminate Listing',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () =>
            dispatch(openSidebar(KBarActionEnum.TERMINATE_TRANSACTION)),
        },
      ]
    : [];

  const listingActions = showListingActions
    ? [
        ...markInContractActions,
        ...editListingActions,
        ...terminateListingAction,
        {
          id: 'details',
          name: 'Details',
          shortcut: ['l', 'o'],
          keywords: 'Details',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/listings/${data?.id}/detail`),
        },
        {
          id: 'checklist',
          name: 'Checklist',
          shortcut: ['l', 'h'],
          keywords: 'checklist',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/listings/${data?.id}/checklist`),
        },
        {
          id: 'comments',
          name: 'Comments',
          shortcut: ['l', 'm'],
          keywords: 'comments',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/listings/${data?.id}/comments`),
        },
        {
          id: 'fileCabinet',
          name: 'File Cabinet',
          shortcut: ['l', 'f'],
          keywords: 'file cabinet',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/listings/${data?.id}/file-cabinet`),
        },
        {
          id: 'uploadTransactionDocument',
          name: 'Upload Listing Document',
          shortcut: ['l', 'f'],
          keywords: 'Upload Listing Document',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/listings/${data?.id}/file-cabinet`),
        },
        {
          id: 'uploadTransactionFile',
          name: 'Upload Listing File',
          shortcut: ['l', 'f'],
          keywords: 'Upload Listing File',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/listings/${data?.id}/file-cabinet`),
        },
        {
          id: 'activity',
          name: 'Activity',
          shortcut: ['l', 'a'],
          keywords: 'Activity',
          section: `Listing: ${data?.address?.oneLine}`,
          perform: () => redirectTo(`/listings/${data?.id}/activity`),
        },
      ]
    : [];

  const listingKBarActions = listingActions.map((action) =>
    createAction({
      ...action,
      section: {
        name: action.section,
        priority: isListingDetailRoute ? Priority.HIGH : Priority.LOW,
      },
    }),
  );

  return { listingActions, listingKBarActions };
};

export default useListingActions;
