import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import Select, {
  ControlProps,
  GroupTypeBase,
  OptionProps,
  OptionTypeBase,
  Styles,
  ValueType,
  components,
} from 'react-select';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { FeatureFlagTypeEnum } from '../../../types';
import { cn } from '../../../utils/classUtils';
import { CustomSelectOptions } from './ZenControlledSelectInput';
import ZenFormErrorMessage from './ZenFormErrorMessage';

interface ZenControlledMultiSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  options: Array<CustomSelectOptions>;
  placeholderLabel?: string;
  styles?: Partial<Styles<any, boolean, GroupTypeBase<any>>>;
  disabled?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  readOnly?: boolean;
  customClassName?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  closeMenuOnSelect?: boolean;
  isRequired?: boolean;
  onChangeSpy?(value: ValueType<OptionTypeBase, true>): void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
}

const Option = (
  props: OptionProps<
    CustomSelectOptions,
    true,
    GroupTypeBase<CustomSelectOptions>
  >,
) => {
  return (
    <components.Option {...props}>
      {props.data.LabelComponent ?? props.data.label}
    </components.Option>
  );
};

const ZenControlledMultiSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  labelClassName,
  placeholder = 'Select',
  options,
  shouldUnregister = false,
  placeholderLabel,
  disabled = false,
  readOnly,
  styles,
  startAdornment,
  endAdornment,
  customClassName = '',
  menuPlacement = 'auto',
  closeMenuOnSelect = true,
  isSearchable = false,
  isClearable = false,
  isRequired,
  onChangeSpy,
  ...rest
}: ZenControlledMultiSelectInputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);
  const Control = ({
    children,
    ...props
  }: ControlProps<CustomSelectOptions, true>) => {
    return (
      <components.Control {...props}>
        {!!placeholderLabel && (
          <span className='px-2 font-zen-body font-medium'>
            {placeholderLabel}
          </span>
        )}
        {children}
      </components.Control>
    );
  };
  const isGeminiDesignEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error, invalid },
      }) => (
        <div
          className={cn(
            'space-y-1 w-full',
            isGeminiDesignEnabled && 'font-inter',
          )}
        >
          {label && (
            <label
              className={cn(
                labelClassName ?? isGeminiDesignEnabled
                  ? 'inline-block'
                  : 'inline-block font-zen-body font-semibold',

                invalid ? 'text-zen-danger' : 'text-zen-dark-9',
              )}
              htmlFor={name}
            >
              {label}
              {isRequired && <span className='text-zen-danger'>*</span>}
            </label>
          )}
          <div
            className={cn(
              'flex flex-row flex-grow items-center border rounded-lg  pr-2',
              invalid && '!border-zen-danger',
              !isEmpty(value) && 'text-zen-dark-9',
              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
              customClassName,
              isGeminiDesignEnabled ? 'px-0.5 py-1' : 'p-0.5',
            )}
          >
            <div className='flex flex-row items-center flex-grow'>
              {startAdornment && <div>{startAdornment}</div>}
              <div className='w-full'>
                <Select<CustomSelectOptions, true>
                  styles={styles}
                  isSearchable={isSearchable}
                  options={options}
                  placeholder={placeholder}
                  name={name}
                  components={{
                    Control,
                    Option,
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    IndicatorsContainer: (props) =>
                      endAdornment ? (
                        <div className='flex fle-row items-center h-10'>
                          {isClearable && (
                            //@ts-ignore
                            <components.ClearIndicator {...props} />
                          )}
                          <div
                            className={cn(
                              'h-10 border-l pt-2 pl-2',
                              focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                              invalid && '!border-zen-danger',
                            )}
                          >
                            {endAdornment}
                          </div>
                        </div>
                      ) : (
                        <div className='flex fle-row items-center h-10'>
                          {isClearable && (
                            //@ts-ignore
                            <components.ClearIndicator {...props} />
                          )}
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className='text-primary-dark mx-3'
                            size='sm'
                          />
                        </div>
                      ),
                  }}
                  isMulti
                  value={value}
                  isDisabled={disabled || readOnly}
                  defaultValue={value}
                  onChange={(value) => {
                    onChange(value);
                    if (onChangeSpy) {
                      onChangeSpy(value);
                    }
                  }}
                  onFocus={() => setFocus(true)}
                  onBlur={() => {
                    setFocus(false);
                    onBlur();
                  }}
                  closeMenuOnSelect={closeMenuOnSelect}
                  isClearable={isClearable}
                  ref={ref}
                  className='react-select-container font-inter font-normal'
                  classNamePrefix='react-select-noborder'
                  inputId={name}
                  menuPlacement={menuPlacement}
                />
              </div>
            </div>
          </div>
          {error && <ZenFormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ZenControlledMultiSelectInput;
